import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Button, Table, Pagination, Modal, Row, Col, Layout, Alert, Input, Card, Select, Tabs, Tooltip, Radio, Form, InputNumber, 
				TimePicker, DatePicker, Popconfirm, Popover} from 'antd';
import Icon from '@ant-design/icons';
import { ArrowRightOutlined, ArrowLeftOutlined, CloseOutlined, FrownOutlined, HomeFilled, FilePdfFilled, LinkOutlined  } from '@ant-design/icons';
import * as etiquetasCasillas from '../../../constants/EtiquetasCasillas';
//import iconoRegistrarSeguimiento from '../../../assets/img/iconoSeguimientoRegistro.png';
//import iconoCerrarSeguimiento from '../../../assets/img/iconoSeguimientoCerrar.png';
import iconoMostrar from '../../../assets/img/icono_mostrar_gafete.png';
import iconoEditar from '../../../assets/img/icono-editar.png';
import iconoEliminar from '../../../assets/img/icono_eliminar.png'
import iconoQuestion from '../../../assets/img/icono_question.png';
import iconoFiltro from '../../../assets/img/icono_filter.png';
import iconoSiguiente from '../../../assets/img/icono_next.png';
import iconoAnterior from '../../../assets/img/icono_back.png';
import errorModal from '../../../assets/img/errorModal.png';
import iconoBasura from '../../../assets/img/icono_basura.png';
import '../../../css/consultaCasillas/consultaCasillas.css';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';
import 'moment/locale/es';

const { Option } = Select;

const selectEstatus = [
	{estatus: 0, nombreEstatus: "Sin generar", color: "#D4D5DB"},
	{estatus: 1, nombreEstatus: "En proceso", color: "#EBD83F"},
	{estatus: 2, nombreEstatus: "Concluido", color: "#D3097F"}
];

const ciculoSvg = () => (
  <svg width="15" height="15" fill="currentColor" viewBox="0 0 1024 1024">
    <path d="M 620, 400 m -390, 0 a 390,390 0 1,0 780,0 a 390,390 0 1,0 -780,0" />
  </svg>
);
const IconoCirculo = props => <Icon component={ciculoSvg} {...props} />;

const urlPortalSvg = () => (
	<svg  width="15.33" height="20.039" viewBox="0 0 17.969 17.97" fill="currentColor">
		<g transform="translate(-1098 -445)"><g transform="translate(1098 445)"><g transform="translate(0 0)"><path class="a" d="M7650.333,749.59c-.024.17-.044.341-.073.51a3.874,3.874,0,0,1-.923,1.948c-.355.4-.746.772-1.126,1.151q-.889.89-1.788,1.773a19.549,19.549,0,0,1-1.564,1.483,4.961,4.961,0,0,1-1.762.932,3.3,3.3,0,0,1-3.023-.614,3.673,3.673,0,0,1-.477-.432,1.11,1.11,0,0,1,1.6-1.536,1.653,1.653,0,0,0,.665.445,1.317,1.317,0,0,0,.92-.1,3.648,3.648,0,0,0,1.066-.762c.742-.729,1.48-1.464,2.22-2.2.474-.47.939-.947,1.422-1.407a1.992,1.992,0,0,0-.2-3.049,2.236,2.236,0,0,0-1.052-.48,1.53,1.53,0,0,0-1.349.463c-.377.373-.75.75-1.13,1.119a1.112,1.112,0,0,1-1.584-1.561c.4-.405.806-.814,1.22-1.209a3.725,3.725,0,0,1,2.111-1.028l.259-.038h.469c.163.023.328.039.49.07a4.37,4.37,0,0,1,1.983.944,4.2,4.2,0,0,1,1.536,2.473c.042.2.063.41.092.615Z" transform="translate(-7632.364 -745)"/><path class="a" d="M7282.875,1042.8c.026-.18.045-.36.078-.539a3.67,3.67,0,0,1,.81-1.771c.418-.484.881-.93,1.333-1.384q1.171-1.175,2.355-2.339a6.5,6.5,0,0,1,2.19-1.5,3.593,3.593,0,0,1,2.819-.031,3.416,3.416,0,0,1,1.107.766,1.114,1.114,0,0,1,0,1.558,1.1,1.1,0,0,1-1.548.033,1.39,1.39,0,0,0-1.7-.211,4.275,4.275,0,0,0-1.182.852q-1.724,1.7-3.437,3.408a1.973,1.973,0,0,0,.283,3.053,2.028,2.028,0,0,0,2.533-.207c.316-.3.622-.619.938-.925a1.113,1.113,0,1,1,1.561,1.587c-.375.371-.74.752-1.134,1.1a4.1,4.1,0,0,1-2.173.978c-.118.017-.237.027-.356.041h-.449c-.029-.006-.057-.013-.085-.017-.234-.035-.472-.051-.7-.107a4.157,4.157,0,0,1-2.465-1.666,3.949,3.949,0,0,1-.7-1.675c-.026-.166-.047-.333-.07-.5Z" transform="translate(-7282.875 -1029.305)"/></g></g></g>
	</svg>

  );
  const IconoUrlPortal = props => <Icon component={urlPortalSvg} {...props} />;

const registroSegSvg = () => (
  <svg width="15.33" height="20.04" viewBox="0 0 15.853 20.04" fill="currentColor">
		<g transform="translate(-5319.572 -2408.722)"><path class="a" d="M5334.718,2408.722h-14.439a.707.707,0,0,0-.707.707v10.457a.707.707,0,0,0,.707.707h2.141l3.976,3.378-4.174,3.546a.707.707,0,1,0,.915,1.077l4.351-3.7,4.35,3.7a.707.707,0,0,0,.916-1.077l-4.174-3.546,3.977-3.378h2.162a.707.707,0,0,0,.707-.707v-10.457A.707.707,0,0,0,5334.718,2408.722Zm-13.732,1.414h5.821v9.043h-5.821Zm6.5,12.906-2.884-2.45h5.769Zm6.523-3.864h-5.79v-9.043h5.79Z"/></g>
	</svg>
);
const IconoRegistrarSeguimiento = props => <Icon component={registroSegSvg} {...props} />;

const cerrarSegSvg = () => (
  <svg width="15.33" height="20.039" viewBox="0 0 16.33 20.039" fill="currentColor">
		<g transform="translate(-2519.441 -2838.965)"><g transform="translate(2519.441 2838.965)"><g transform="translate(0)"><path class="a" d="M2525.96,2858.672h-5.824a.7.7,0,0,1-.695-.695V2839.66a.7.7,0,0,1,.695-.695h13.894a.694.694,0,0,1,.694.695v7.317a.695.695,0,1,1-1.389,0v-6.623h-12.5v16.928h5.13a.695.695,0,0,1,0,1.389Z" transform="translate(-2519.441 -2838.965)"/></g><g transform="translate(3.553 4.24)"><path class="a" d="M2552.954,2870.869h-7.243a.695.695,0,0,1,0-1.389h7.243a.695.695,0,1,1,0,1.389Z" transform="translate(-2545.016 -2869.48)"/></g><g transform="translate(3.553 9.203)"><path class="a" d="M2548.688,2906.6h-2.977a.695.695,0,0,1,0-1.389h2.977a.695.695,0,1,1,0,1.389Z" transform="translate(-2545.016 -2905.206)"/></g><g transform="translate(3.553 14.197)"><path class="a" d="M2546.965,2942.537h-1.254a.695.695,0,1,1,0-1.389h1.254a.695.695,0,1,1,0,1.389Z" transform="translate(-2545.016 -2941.148)"/></g></g><g transform="translate(2527.862 2848.709)"><path class="a" d="M2587.411,2909.359a1.343,1.343,0,0,0-.8-.263,1.358,1.358,0,0,0-1.09.551l-.445.6h0l-1.172,1.593a.353.353,0,0,0-.054.11l-3.408,4.734a.359.359,0,0,0-.065.159l-.32,2.123a.366.366,0,0,0,.521.385l1.87-.9a.365.365,0,0,0,.138-.116l.449-.622a.406.406,0,0,0,.03-.034.374.374,0,0,0,.041-.064l4.594-6.369A1.354,1.354,0,0,0,2587.411,2909.359Zm-.8.47a.619.619,0,0,1,.5.989l-.223.309-1-.736.227-.31A.615.615,0,0,1,2586.611,2909.829Zm-3.912,7.1-.974-.768,2.571-3.571,1,.737Zm2.753-5.945.994.732-.738,1-.984-.724Zm-4.568,7.411.2-1.349.208-.29.974.768-.225.311Z" transform="translate(-2580.053 -2909.096)"/></g></g>
	</svg>
);
const IconoCerrarSeguimiento = props => <Icon component={cerrarSegSvg} {...props} />;

const VSeguimientoCasilla = (props) => {

	const [data, setData] = useState(props.items);

	const [searchFolioCasilla, setSearchFolioCasilla] = useState("");
	const [searchSeccionCasilla, setSearchSeccionCasilla] = useState("");
	const [searchZoreCasilla, setSearchZoreCasilla] = useState("");
	const [searchEstatusSeguimiento, setSearchEstatusSeguimiento] = useState();

	const [form] = Form.useForm();

	const [valCE, setValCE] = useState();
	const [valTI, setValTI] = useState();
	const [valT, setValT] = useState();

	const [tiposCasillas, setTiposCasillas] = useState([]);

	const columns = [
		{
			title: (
				<div style={{ textAlign: 'center' }}>
					<div>
						<Tooltip title={etiquetasCasillas.etiqueta_tooltip_filtros}>
							<img style={{ width: 17, height: 17 }} src={iconoQuestion} />
						</Tooltip>
					</div>
					<br />
				</div>),
			dataIndex: 'info',
			key: 'info',
		},
		{
			title: (
				<div style={{ textAlign: 'center' }}>
					<div>Folio </div>
					<br />
					<Input
						value={searchFolioCasilla}
						onChange={({ target: { value } }) => setSearchFolioCasilla(value)}
						placeholder='Folio'
						suffix={<img style={{ width: 12, height: 12 }} src={iconoFiltro} />}
						onKeyPress={(e) => { if (e.key === 'Enter') { props.searchFolio(searchFolioCasilla) } }}
					/>
				</div>
			),
			align: 'center',
			dataIndex: 'folio',
			key: 'folio',
		},
		{
			title: (
				<div style={{ textAlign: 'center' }}>
					<div>N° de responsables</div><br/>
				</div>
			),
			align: 'center',
			dataIndex: 'numeroResponsables',
			key: 'numeroResponsables',
		},
		{
			title: (
				<div style={{ textAlign: 'center' }}>
					<div>Tipo de casilla</div>
					<br/>
					<Select defaultValue="Seleccionar" onChange={props.searchTipoCasilla} style={{width: 200}}>
						<Option value="">Tipo de casilla</Option>
						{tiposCasillas.map((value, index) => {
							return (
								<Option key={value.idTipoCasilla} value={value.idTipoCasilla}>
									<tr key={index} >
										{value.tipoCasilla}
									</tr>
								</Option>
							)
						})}
					</Select>
				</div>
			),
			align: 'center',
			dataIndex: 'tipoCasilla',
			key: 'tipoCasilla',
		},
		{
			title: (
				<div style={{ textAlign: 'center' }}>
					<div>N° de ZOR</div>
					<br />
					<Input
						value={searchZoreCasilla}
						onChange={({ target: { value } }) => setSearchZoreCasilla(value)}
						placeholder='N° de ZOR'
						suffix={<img style={{ width: 12, height: 12 }} src={iconoFiltro} />}
						onKeyPress={(e) => { if (e.key === 'Enter') { props.searchZore(searchZoreCasilla) } }}
					/>
				</div>
			),
			align: 'center',
			dataIndex: 'numeroZore',
			key: 'numeroZore',
		},
		{
			title: (
				<div style={{ textAlign: 'center' }}>
					<div>Sección electoral</div>
					<br />
					<Input
						value={searchSeccionCasilla}
						onChange={({ target: { value } }) => setSearchSeccionCasilla(value)}
						placeholder='Sección electoral'
						suffix={<img style={{ width: 12, height: 12 }} src={iconoFiltro} />}
						onKeyPress={(e) => { if (e.key === 'Enter') { props.searchSeccion(searchSeccionCasilla) } }}
					/>
				</div>
			),
			align: 'center',
			dataIndex: 'seccion',
			key: 'seccion',
		},
		{
			title: (
				<div style={{ textAlign: 'center' }}>
					<div>Estatus</div>
					<br />
					<Select defaultValue="Seleccionar" onChange={props.searchEstatusSeguimiento} style={{width: 140}}>
						<Option value="">Estatus</Option>
						{selectEstatus.map((value, index) => {
							return (
								<Option key={index} value={value.estatus}>
										{value.nombreEstatus}
										&nbsp;
										<IconoCirculo style={{color: value.color, fontSize: '15px' }}/>
								</Option>
							)
						})}
					</Select>
				</div>
			),
			align: 'center',
			dataIndex: 'estatusSeguimiento',
			key: 'estatusSeguimiento',
			render: (estatusSeguimiento) => {
				let color = estatusSeguimiento === 0 ? "#D4D5DB" : (estatusSeguimiento === 1 ? "#EBD83F" : "#D3097F");
					return (
						<IconoCirculo style={{color: color, fontSize: '30px' }}/>
					);
				}
		},
		{
			title: (
				<div style={{ textAlign: 'center' }}>
					<div style={{width: 80}}>Acciones</div><br/>
				</div>
			),
			align: 'center',
			dataIndex: 'acciones',
			key: 'acciones',
			render: (valor, record) =>(
			<> 
				{record.idTipoCasilla != 4 ? 
					
							<Tooltip title={etiquetasCasillas.etiqueta_tooltip_genera_url}>
								<a id="ver-url" onClick={() =>{props.generarUtlPortal(record)}}>
								<IconoUrlPortal className='icono'/>
								</a> 
							</Tooltip>
					:
					""
				}
				&nbsp;&nbsp;
				{record.fechaHoraInicio == null ? 
					<Tooltip title={etiquetasCasillas.etiqueta_tooltip_registrar_casilla}>
						<a id="ver-voluntario" onClick={() =>{props.enviarARegistrarInstalacion(record)}}>
							<IconoRegistrarSeguimiento className='icono'/>
						</a>
					</Tooltip>
					:
					<Tooltip title={etiquetasCasillas.etiqueta_tooltip_registrar_casilla}>
						<a className='iconoDisabled'>
							<IconoRegistrarSeguimiento className='iconoDisabled'/>
						</a>
					</Tooltip>
				}
				&nbsp;&nbsp;
				{record.fechaHoraInicio !== null && record.fechaHoraTermino === null ? 
					<Tooltip title={etiquetasCasillas.etiqueta_tooltip_cierre}>
						<a id="ver-voluntario" onClick={() =>{props.enviarARegistrarInstalacion(record)}} disabled={props.fechaHoraTermino != null}>
							<IconoCerrarSeguimiento className='icono' />
						</a>
					</Tooltip>
					:
					<Tooltip title={etiquetasCasillas.etiqueta_tooltip_cierre}>
						<a className='iconoDisabled'>
							<IconoCerrarSeguimiento className='iconoDisabled'/>
						</a>
					</Tooltip>
				}
				&nbsp;&nbsp;
				{(record.fechaHoraInicio !== null && record.fechaHoraTermino !== null) ? 
					<Tooltip title={etiquetasCasillas.etiqueta_tooltip_acta}>
						<a id="ver-voluntario" onClick={() =>{props.handleDescargaActa(record)}}>
							<FilePdfFilled className='icono' style={{fontSize:'15px'}}/>
						</a>
					</Tooltip>
				:
					<Tooltip title={etiquetasCasillas.etiqueta_tooltip_acta}>
						<a className='iconoDisabled'>
							<FilePdfFilled className='iconoDisabled'/>
						</a>
					</Tooltip>
				}
			</>)
		},
		{
			title: (
				<div style={{ textAlign: 'center' }}>
					<div>
						<Tooltip title={etiquetasCasillas.etiqueta_tooltip_acciones_seguimiento}>
							<img style={{ width: 17, height: 17 }} src={iconoQuestion} />
						</Tooltip>
					</div>
					<br />
				</div>),
			dataIndex: 'info',
			key: 'info',
		},
	];

	useEffect(() => {
		//loadProfile();
		setTiposCasillas(props.tiposCasillas);
		setData(props.data);
	}, [props]);

	return (
		<>
			<Layout className='validar_modulo consulta'>
				<div /* style={{ display: props.modifica ? "none" : "block" }} */ id="casillasConsultaTabla">
					<div id="seccion1">
						<Row>
							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								<h1 id="titulo-componente">Seguimiento de casilla</h1>
							</Col>
						</Row>
					</div>
					<div className='div_estatus_seguimiento'>
						{selectEstatus.map((value, index) => (
							<span key={index}> <IconoCirculo style={{color: value.color, fontSize: '15px'}}/>&nbsp;{value.nombreEstatus}</span>
						))}
					</div>
					<Card title={<span className="tituloTablaCasillas">Lista de casillas registradas</span>} bordered={true} style={{ marginBottom: '24px', textAlign: 'center' }} size="small">
						<div id="tabla-consulta-casillas">
							<Table columns={columns} dataSource={data} pagination={false} scroll={{x: true}} size="small" className="tabla-casillas" />
							<br />
						</div>
					</Card>
					<br />
					<div id="paginado">
						<Row justify="center">
							<Col xs={24} sm={24} md={{ span: 1, offset: 2 }} lg={{ span: 1, offset: 2 }} xl={{ span: 1, offset: 2 }}>
								<HomeFilled onClick={props.regresarPrimeraPagina} />
							</Col>
							<Col xs={24} sm={24} md={6} lg={6} xl={6}>

								<Pagination 
									onChange={props.paginado}
									total={props.total}
									defaultPageSize={5}
									loading={props.cargando}
									size="small"
									showSizeChanger={false}
								/>

							</Col>
						</Row>
					</div>
				</div>
			</Layout>
		</>
	)
}

export default VSeguimientoCasilla
