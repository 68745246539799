import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { notification, Row, Col } from 'antd';
import CTemplate from '../../homeInterno/CTemplate';
import AuthenticatedComponent from '../../../components/comunes/AuthenticatedComponent';
import VSeguimientoCasilla from '../../../components/casillas/seguimiento/VSeguimientoCasilla';
import { createStructuredSelector } from 'reselect';
import { selectEstado, selectDistrito } from '../../../redux/menu/menu-selectors';
import { obtenerEstatusModulo } from '../../../redux/menu/menu-utils';
import { selectCurrentUser } from '../../../redux/user/user-selectors';
import { selectCurrentCasilla } from '../../../redux/casillas/casilla-selectors';
import { setCurrentCasilla, setCurrentCasillaId} from '../../../redux/casillas/casilla-actions';
import {PATH_INSTALACION_CASILLA} from '../../../constants/routers/paths';
import iconoDescargaGafete from '../../../assets/img/icono_descarga_gafete.png';
import * as etiquetas from '../../../constants/EtiquetasGenerales';
import * as etiquetasCasillas from '../../../constants/EtiquetasCasillas';
import * as info from '../../../constants/InfoSistema';

class CSeguimientoCasilla extends React.Component {

	constructor(props) {
		super(props);
		//console.log(props);
		this.state = {
			rolPermitido: false,
			cargando: false,
			tiposCasillas: [],
			searchFolio: null,
			searchTipoCasilla: null,
			searchZore: null,
			searchSeccion: null,
			searchEstatusSeguimiento: null,
			folioUrl: null,
			tipoCasillaUrl: null,
			seccionUrl: null,
			idCasillaUrl: null
		}

		/* console.info("el usuario con el rol ", this.props.user.rolUsuario,
			"está bloqueado ", !etiquetasCasillas.roles_permitidos_casillas[this.props.user.rolUsuario]); */
	}

	componentDidMount() {
		console.log('Entra en componentDidMount');
		this.consultarEstatusModulo();
		this.consultarPermisoUsuario();
		this.consultaTipoCasillas();
	}

	async componentDidUpdate(prevProps) {
		if (this.props.estado !== prevProps.estado || this.props.distrito !== prevProps.distrito) {
			this.enviarConsulta();
			this.consultaTipoCasillas();
		}
	}

	consultarEstatusModulo() {
		var idProcesoElectoral = this.props.user.idProcesoElectoral;
		var idDetalleProceso = this.props.user.idDetalle;
		var idEstado = this.props.estado.idEstado;
		var idDistrito = this.props.distrito.idDistrito;
		var idSistema = info.ID_SISTEMA;
		var idModulo = this.props.location.state !== undefined ? this.props.location.state.idModulo : 0;
		var grupoSistema = this.props.user.rolUsuario;

		obtenerEstatusModulo(idProcesoElectoral, idDetalleProceso, idSistema, idEstado, idDistrito, idModulo, grupoSistema)
			.then(respuesta => {
				console.info("El estatus del módulo Seguimiento de casillas es: ", respuesta);

				if (respuesta === "A") {
					console.info("El módulo Seguimiento de casillas está abierto ");
					this.enviarConsulta();
				} else {
					console.info("El módulo Seguimiento de casillas está cerrado ");
				}
			})
	}

	consultarPermisoUsuario = () => {
		this.setState({ rolPermitido: etiquetasCasillas.roles_permitidos_casillas.includes(this.props.user.rolUsuario) });
	}

	consultaTipoCasillas = () => {
		axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + 'ws/tipo_casilla', {}, {
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.props.user ? this.props.user.tknJWT : null
			}
		}).then(res => {
			this.setState({ tiposCasillas: res.data,
											cargando: false,
										});
		}).catch(error => {
			console.error("Error al obtener la consulta de Casillas", error);
		});
	}

	salirsinGuardar = () => {
     this.enviarConsulta();
    this.consultaTipoCasillas();
    //this.state.mostrarSinGuardar = false;
    //this.state.modifica = false;
	}
	searchFolio = (valor) => {
		if (valor != null) {
			this.state.searchFolio = valor.toUpperCase();
		} else {
			this.state.searchFolio = null;
		}
		this.enviarConsulta();
	}
	searchTipoCasilla = (valor) => {
		if (valor != null) {
			this.state.searchTipoCasilla = valor;
			if (valor === 1 || valor === 2) {
				this.state.activarZonaEscolar = true;
			} else {
				this.state.activarZonaEscolar = false;
			}
		} else {
			this.state.searchTipoCasilla = null;
		}
		this.enviarConsulta();
	}
	searchZore = (valor) => {
		if (valor != null) {
			this.state.searchZore = parseInt(valor, 10);
		} else {
			this.state.searchZore = null;
		}
		this.enviarConsulta();
	}
	searchSeccion = (valor) => {
		if (valor != null) {
			this.state.searchSeccion = parseInt(valor, 10);
		} else {
			this.state.searchSeccion = null;
		}
		this.enviarConsulta();
	}
	searchEstatusSeguimiento = (valor) => {
		if (valor != null) {
			this.state.searchEstatusSeguimiento = parseInt(valor, 10);
		} else {
			this.state.searchEstatusSeguimiento = null;
		}
		this.enviarConsulta();
	}

	enviarConsulta = (inicio = 1, fin = 5) => {
		var dataJson = {
			idDetalleProceso: info.ID_DETALLE_PROCESO,
			idProcesoElectoral: info.ID_PROCESO_ELECTORAL,
			idEstado: this.props.estado.idEstado,
			idDistrito: this.props.distrito.idDistrito,
			inicio: inicio,
			fin: fin,
			municipio: this.state.searchMunicipio,
			folio: this.state.searchFolio,
			idTipoCasilla: this.state.searchTipoCasilla,
			seccion: this.state.searchSeccion,
			numeroZr: this.state.searchZore,
			estatusSeguimiento: this.state.searchEstatusSeguimiento,
			moduloCasilla: 1
		}
		axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + 'ws/consulta_casilla', dataJson, {
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.props.user ? this.props.user.tknJWT : null
			}
		}).then(res => {
			const casilla = res.data.consultaCasilla;
			this.setState({ casillas: casilla,
											total: res.data.totalCasillas,
											mensajeAlerta: null,
											cargando: false,
											mostrarAlerta: { display: 'none' },
										});
		}).catch(error => {
			console.error("Error al obtener la consulta de Casillas", error);
		});
	}

	/* mostrarModal = (record) => {
		console.log("record", record);
		if (record.nombreFoto !== "") {
			this.obtenerArchivosSubidos(record);
		}
		this.setState({ mostrar: true,
										voluntariosSeleccionadoModal: record
									});
	};

	ocultarModal = () => {
		this.setState({ mostrar: false,
										imagenUrlFoto: ""
									});
	}; */

	abrirNotificacion = (mensaje) => {
		notification.open({
			description: <Row >
				<Col xs={5} sm={4} md={5} lg={5} xl={5} id="div-icono-exito">
					<img src={iconoDescargaGafete} id="icono-descarga-exito" />
				</Col>
				<Col xs={19} sm={19} md={19} lg={19} xl={19} id="msj-exito">
					{mensaje}
				</Col>
			</Row>,
			placement: "bottomRight",
			duration: 5
		});
	}

	paginado = pagina =>{
		console.log("la pagina es", pagina);
		var paginaAnterior = parseInt(pagina,10) - 1 === 0 ? 1 : parseInt(pagina,10) - 1;
		this.setState({ actual : pagina, 
										cargando: true,
										casillas: []
									});
		var inicio = parseInt(pagina,10) === 1 ? 1 : (paginaAnterior * 5) + 1;
		var fin = parseInt(pagina,10) * 5;

		this.enviarConsulta(inicio, fin);
	}
	regresarPrimeraPagina = () => {
		this.setState({ paginaActual: 1 });
		this.enviarConsulta();
	}

	enviarARegistrarInstalacion = (casilla) => {
		this.props.setCurrentCasilla(casilla);
    this.props.history.push(PATH_INSTALACION_CASILLA);
	}
	
	jsonActa = (casilla) => {
		var listaCasillas = [casilla];

		var json = {
			"idProcesoElectoral": casilla.idProcesoElectoral,
			"idDetalleProceso": casilla.idDetalleProceso,
			"idEstado": this.props.estado.idEstado,
			"idDistrito": casilla.idDistrito,
			"folio": casilla.folio,
			"listaCasillas": listaCasillas
		};
		return json;
	}

	handleDescargaActa = (casillaSeleccionada) => {
		console.log("Entra descarga acta cierre...");
		var tken = { 'Authorization': this.props.user ? this.props.user.tknJWT : null,
								 'Accept': 'application/json',
                 'Content-Type': 'application/json'
								};
		const infoPost = this.jsonActa(casillaSeleccionada);

    if(infoPost.fechaHoraTermino !== null || infoPost.fechaHoraTermino !== undefined) {
			this.setState({cargando:true});
			
      if(infoPost.listaCasillas.length > 0) {
        axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + "ws/descargarActaCierre", infoPost, {
					responseType: 'blob',
					"Access-Control-Allow-Headers" : "Content-Type",
								"Access-Control-Allow-Origin": "http://localhost:3000",
								"Access-Control-Allow-Methods": "OPTIONS,POST,GET",
					transformResponse: [ data => {
						let blob = new window.Blob([data,tken], {type: "application/pdf"});
						var link = document.createElement('a');
						link.href = window.URL.createObjectURL(blob);
						link.setAttribute('download', `ActaCierre_${infoPost.folio}.pdf`);
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
					}]
				}).then(res => {
            this.abrirNotificacion(etiquetasCasillas.etiqueta_mensaje_descarga_acta);
            console.info("respuesta...",res);
            this.setState({ cargando:false });
        }).catch(error => {
            console.error("Error",error);
        });
      }else{
        this.setState({ cargando:false });
        console.error("Se debe concluir con el cierre de instalación de la casilla para poder crear el acta de cierre");
      }
    } else {
        console.info("el rol ", this.props.user.rolUsuario, " no tiene los permisos para generar el acta de cierre");
    }
	}

	generarUtlPortal = (datos) => {
		console.log("***************************************************");
		console.log(datos);
		this.state.folioUrl= datos.folio;
		this.state.tipoCasillaUrl= datos.tipoCasilla;
		this.state.seccionUrl= datos.seccion;
		var CryptoJS = require("crypto-js");
		var key = "inC2H19lkVbAPfakxcrtTREdd0FloLyw"; 
		var iv  = "gqLOHUioQ0QjhuvI";

		var text = datos.idCasilla.toString();
		var key = CryptoJS.enc.Latin1.parse(key);
		var iv  = CryptoJS.enc.Latin1.parse(iv);
		
		var encrypted = CryptoJS.AES.encrypt(text, key, {iv: iv});
		this.state.idCasillaUrl= encrypted.toString();
		console.log(this.state.idCasillaUrl);

		this.obtenerUrlPdf();
			
	}
 /**
  * Obtiene el pdf Url idCasilla
  * 
  */
  obtenerUrlPdf = (value) =>{
    console.log("LLEGUE AL METODO DE OBTENER PDFURL*******"); 

    var postData = {
		folioUrl: this.state.folioUrl,
		tipoCasillaUrl: this.state.tipoCasillaUrl,
		seccionUrl: this.state.seccionUrl,
		idCasillaUrl: this.state.idCasillaUrl
    };  
    console.log("DATOS ENVIADOS-------");
          console.log(postData); 
    console.log(this.props.user);
    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL +
      "ws/descargarUrlIdCasilla", postData,
          {	  responseType: 'arraybuffer',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.user ? this.props.user.tknJWT : null
              }
          }
       ).then(response => {    
        console.log("EXITOSO");
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', 'URL.pdf'); //or any other extension
		document.body.appendChild(link);
		link.click(); 
		this.abrirNotificacion(etiquetasCasillas.etiqueta_mensaje_descarga_url);
       

      }).catch(error => {
        console.error("Error al obtener el pdf",error);
    });
  }
	render () {
		return (
			<>
				<AuthenticatedComponent>
					<CTemplate //bloqueado={!roles_permitidos.includes(this.props.user.rolUsuario)}
						contenido={
							<>
								<VSeguimientoCasilla
									tiposCasillas={this.state.tiposCasillas}
									data={this.state.casillas}
									total = {this.state.total}
          				actual = {this.state.actual}
									paginado = {this.paginado}
									regresarPrimeraPagina={this.regresarPrimeraPagina}

									searchFolio={this.searchFolio}
									searchTipoCasilla={this.searchTipoCasilla}
									searchZore={this.searchZore}
									searchSeccion={this.searchSeccion}
									searchEstatusSeguimiento={this.searchEstatusSeguimiento}

									enviarARegistrarInstalacion={this.enviarARegistrarInstalacion}
									handleDescargaActa = {this.handleDescargaActa}

									salirSinGuardar = {this.salirsinGuardar}
									generarUtlPortal = {this.generarUtlPortal}
								/>
							</>
						}
						guia={false} />
				</AuthenticatedComponent>
			</>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	estado: selectEstado,
	distrito: selectDistrito,
	user: selectCurrentUser,
	casilla: selectCurrentCasilla
});

const mapDispatchToProps = dispatch => ({
	setCurrentCasilla: casilla => dispatch(setCurrentCasilla(casilla)),
	setCurrentCasillaId: casillaId => dispatch(setCurrentCasillaId(casillaId))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CSeguimientoCasilla));
