import React, {useState, useEffect} from 'react';
import {Button, Table,Row, Col, Input, Layout , Form, Modal, Pagination, Card, Select, Tooltip,Alert,Tabs,Radio, Popconfirm, message} from 'antd';
import { TeamOutlined, EditOutlined, EyeOutlined, PlusCircleOutlined, CloseCircleOutlined, 
  SearchOutlined, QuestionCircleFilled, FilterOutlined,  FrownOutlined, HomeFilled} from '@ant-design/icons';
import * as etiquetasAsigZore from '../../../constants/EtiquetasAsigZores';
import 'antd/dist/antd.css';
import "../../../css/asignacionCasillas/asignacionCasillas.css";
import "../../../css/asignacionCasillas/asignacionZore.css";
import editAsignacionZore from '../../../assets/img/editAsignacionZore.svg';
import asignarAsignacionZore from '../../../assets/img/asignarAsignacionZore.svg';
import advertenciaZore from '../../../assets/img/advertenciaZore.svg';
import eliminacionZore from '../../../assets/img/eliminacionZore.svg';
import iconoHome from '../../../assets/img/iconoHome.svg';
import iconoAsignaZorePlus from '../../../assets/img/iconoAsignaZorePlus.svg';
import iconoAsignaZoreCancel from '../../../assets/img/iconoAsignaZoreCancel.svg';
import iconoFiltro from '../../../assets/img/icono_filter.png';
import errorModal from '../../../assets/img/errorModal.png';
import asignaZores from '../../../assets/img/asignaZores.svg';
import modificarAsignacionZore from '../../../assets/img/modificarAsignacionZore.svg';
import iconoMostrar from '../../../assets/img/icono_mostrar_gafete.png';
import iconoEditar from '../../../assets/img/icono-editar.png';
import iconoEliminar from '../../../assets/img/icono_eliminar.png'
import iconoBasura from '../../../assets/img/icono_basura.png';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';
import 'moment/locale/es';

const { Option } = Select;
const icon = <CloseCircleOutlined />;

const VZoreVoluntarios = (props) =>{
  const [formAsignacion] = Form.useForm();
  const [searZore, setSearchZore] = useState("");
  const [searSeccion, setSearchSeccion] = useState("");
  const [searNumeroZore, setSearchNumeroZore] = useState("");
  const [searNombreVoluntario, setSearchNombreVoluntario] = useState("");
  const [searFolioVol, setSearchFolioVol] = useState("");
  
  const handleSearchClickCasillas= ()=>{setSearchZore("");}
  const handleSearchClickSeccion = ()=>{setSearchSeccion("");}
  const handleSearchClickFolioVol= ()=>{setSearchNumeroZore("");}
  const handleSearchClickVoluntario = ()=>{setSearchNombreVoluntario("");}
  const handleSearchClickFolioVoluntario = ()=>{setSearchFolioVol("");}

const columns = [
    {
      title:(
        <div style={{ textAlign: 'center' }}>
            <div>
              <Tooltip title={etiquetasAsigZore.etiqueta_tooltip_filtros_resultado}>
                <QuestionCircleFilled/>
              </Tooltip>
            </div>
            <br />
          </div>),
      dataIndex:'info',
      key:'info'
    },
    {
      title: (
        <div style={{ textAlign: 'center' }}>
            <div>Número de ZOR </div>
            <br />
            <Input 
              className="InputFilter"
              value={searZore} 
              onChange={({target:{ value}}) => setSearchZore(value)}
              placeholder="Número de ZOR" style={{width: "80px"}}
              suffix = {<img style={{width:12 , height:12}} src={iconoFiltro}/>}
              onKeyPress={(ev) => {if (ev.key === 'Enter') {props.busquedaNumeroZore(searZore)}}}   
            />
        </div>
      ),
      align: 'center',
      dataIndex: 'numeroZonaResponsabilidad',
      key: 'numeroZonaResponsabilidad'
    },
    {
      title: (
        <div style={{ textAlign: 'center' }}>
            <div>{etiquetasAsigZore.tipo_zore}</div>
            <br />
            <Select defaultValue="Seleccionar" onChange={props.busquedaTipoZore} >
                <Option value="">Seleccionar</Option>
                  { props.infoTiposZona.map((value, index) =>{
                        return(                                          
                            <Option value={value.idTipoZona} >                                
                              <tr key={index} >                                                                                        
                                  {value.tipoZona}                                            
                              </tr>
                            </Option>
                        )
                  })}                                                                          
            </Select>
        </div>
      ),
      align: 'center',
      dataIndex: 'tipoZore',
      key: 'tipoZore',
    },
    {
      title: (
        <div style={{ textAlign: 'center' }}>
            <div>MSPEN asignados</div>
            <br/>
        </div>
      ),
      align: 'center',
      dataIndex: 'tieneVoluntario',
      key: 'tieneVoluntario'
    },
    {
      title: (
        <div style={{ textAlign: 'center' }}>
            <div>Acciones</div>
              <Tooltip title={etiquetasAsigZore.etiqueta_tooltip_filtros_acciones}>
                <QuestionCircleFilled/>
              </Tooltip>
            <br/>
        </div>
      ),
      align: 'center',
      dataIndex: 'acciones',
      key: 'acciones',
      render: (fila, record) => 
      record.tieneVoluntario!==0 ? (<>&nbsp;
                               <Tooltip title={etiquetasAsigZore.etiqueta_tooltip_editar}><a onClick={()=>{props.obtenerVoluntariosAsig(record)}}><img style={{width:13 , height:15}} src={editAsignacionZore} id="edit-icon"/></a></Tooltip>&nbsp;
                               <Tooltip title={etiquetasAsigZore.etiqueta_tooltip_eliminar}><a onClick={()=>{props.revisarEstatus(record)}}><img style={{width:16 , height:18}} src={iconoEliminar} id="deleted-icon"/></a></Tooltip>&nbsp;</>):
                               (<><Tooltip title={etiquetasAsigZore.etiqueta_tooltip_asignar}><a onClick={()=>{props.obtenerVoluntariosAsig(record)}}><img style={{width:13 , height:15}} src={asignarAsignacionZore} id="edit-icon"/></a></Tooltip>&nbsp;</>)                            
    },
  ];


        return(          
            <Layout className='validar_modulo'>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Alert icon={icon}
                   message={props.mensajeAlerta}
                   type={props.tipoAlerta} showIcon
                   style ={props.mostrarAlerta}/> 
              </Col>
            </Row>
            <div className="content" >
              <div style={{display: props.ocultarCasillas ? "none" : "block", "width":"85%"}}>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <span className="tituloTable">
                      {etiquetasAsigZore.titulo_componente}
                      <Tooltip title={etiquetasAsigZore.etiqueta_tooltip_resultado}>
                        <QuestionCircleFilled/>
                      </Tooltip>
                    </span>
                  </Col>
                </Row>
                <Row style={{marginTop:"30px"}}>
                  <Col span={24}>
                  <div style={{ textAlign: 'center' }}>
                  <span className="encabezadoTabla">
                     Listado de zona de responsabilidad registradas
                  </span>
                  </div>
                 </Col>
                </Row>
                <Row style={{marginTop:"30px"}}>
                  <Col span={24}>
                    <Table columns={columns} dataSource={props.infoZonasResponsabilidadCasilla} pagination={false} size="large"/>
                    <div id="pagina" style={{textAlign:"center","width":"85%"}} >
                      <Row justify="center">
                        <Col md={{span:1, offset:4}} lg={{span:1, offset:4}} xl={{span:1, offset:4}}>
                            <HomeFilled style={{color:"#7C5BA2"}} onClick={props.regresarPrimeraPagina} />
                         </Col>
                         <Col md={8} lg={8} xl={8}>
                         
                            <Pagination current={props.actual}
                                        onChange={props.paginado} 
                                        total={props.paginas}
                                        defaultPageSize={5}
                                        loading={props.cargando}
                                        size="small"
                                        showSizeChanger={false} />
                        </Col>
                      </Row>
                    </div>    
                  </Col>
                </Row>                                          
              </div>
              <div style={{display: props.ocultarVoluntarios ? "none" : "block"}}>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                   {props.infoVoluntariosAsignados.length>0 ? <span className="tituloTable">
                      {etiquetasAsigZore.titulo_componente_modifica}
                    </span> : <span className="tituloTable">
                      {etiquetasAsigZore.titulo_componente}
                    </span>
                    }
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                        <span className ="subTitulo">
                          a {etiquetasAsigZore.zore+ " "+props.numeroZonaResponsabilidad}
                        </span>
                  </Col>
                </Row>
                <Row>
                  <div>    
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      <table className="tableStyleVoluntarios">
                        <thead>
                                <tr>
                                <th className="tituloTableVoluntarios">
                                {etiquetasAsigZore.resultados_voluntarios}
                                <Tooltip title={etiquetasAsigZore.etiqueta_tooltip_resultado_voluntarios}>
                                  <QuestionCircleFilled/>
                                </Tooltip>
                                </th>
                                </tr>
                        </thead>
                      </table>
                      <table className="tableStyleVoluntarios">
                        <thead>
                          <tr>
                                  <th scope="col">{etiquetasAsigZore.folio}
                                  <br></br>
                                      <Input 
                                        className="InputFilter"
                                        value={searFolioVol} 
                                        onChange={({target:{ value}}) => setSearchFolioVol(value)}
                                        placeholder="Cargo" style={{width: "80px"}}
                                        onKeyPress={(ev) => {if (ev.key === 'Enter') {props.busquedaFolioVol(searFolioVol)}}}   
                                        /> 
                                    </th>
                                    <th scope="col">{etiquetasAsigZore.tieneVoluntario}
                                    <br></br>
                                      <Input 
                                            className="InputFilter"
                                            value={searNombreVoluntario} 
                                            onChange={({target:{ value}}) => setSearchNombreVoluntario(value)}
                                            placeholder="Usuario MSPEN  " style={{width: "100px"}}
                                            onKeyPress={(ev) => {if (ev.key === 'Enter') {props.busquedaVoluntario(searNombreVoluntario)}}}   
                                      />                                       
                                       </th>
                                    <th scope="col">{etiquetasAsigZore.acciones}</th>
                          </tr>
                        </thead>
                        <tbody>
                                    {!props.infVountarioZores.length ? "No hay registros" : props.infVountarioZores.map((value, index) =>{
                                        return(                                    
                                          <tr key={index} >
                                            <td>{value.folio}</td>
                                            <td>{value.nombreVoluntario}</td>
                                            <td onClick={()=>props.asignarCasillas(value)} style={{textAlign: "center"}}><img src={iconoAsignaZorePlus} id="printer-icon"/></td>                                    
                                          </tr>                                    
                                        )
                                    })}                 
                        </tbody>
                      </table>
                      <div id="pagina">
                        <Row >
                          <Col md={{span:1, offset:4}} lg={{span:1, offset:4}} xl={{span:1, offset:4}}>
                            <HomeFilled style={{color:"#7C5BA2"}} onClick={props.regresarPrimeraPagina} />
                          </Col>
                          <Col md={10} lg={10} xl={10}>
                            <Pagination current={props.actualVoluntarios}
                                                    onChange={props.paginadoVoluntarios} 
                                                    total={props.paginasVoluntarios}
                                                    loading={props.cargando}
                                                    defaultPageSize={5}
                                                    size="medium"
                                                    showSizeChanger={false} />
                          </Col>
                        </Row>
                      </div>
                    </Col>                            
                  </div>
                  <div className="btonSeparacion">
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      <table className="tableStyleVoluntarios">
                        <thead>
                                  <tr>
                                  <th scope="col" className="tituloTableVoluntarios">
                                    {etiquetasAsigZore.vountarios_asignados}
                                  </th>
                                  </tr>
                        </thead>
                      </table>
                      <table className="tableStyleVoluntarios">
                        <thead>
                                    <tr>
                                    <th scope="col">{etiquetasAsigZore.folio} &nbsp;&nbsp;&nbsp;&nbsp;</th>
                                    <th scope="col">{etiquetasAsigZore.vountario_asignado} &nbsp;&nbsp;&nbsp;&nbsp;</th>                                    
                                    <th scope="col">{etiquetasAsigZore.acciones}</th>
                                    </tr>
                        </thead>
                        <tbody>                
                                    {!props.infoVoluntariosAsignados.length ? "No hay registros" : props.infoVoluntariosAsignados.map((value, index) =>{
                                        return(
                                            <tr key={index} >
                                            <td>{value.folio}</td>
                                            <td>{value.nombreVoluntario}</td>                                     
                                            <td onClick={()=>props.eliminarVoluntario(value)} style={{textAlign: "center"}}><Tooltip title='Quitar'><img src={iconoAsignaZoreCancel} id="printer-icon"/></Tooltip></td>                                    
                                          </tr>                                    
                                        )
                                      })                             
                                    }                          
                        </tbody>
                      </table>
                    </Col>
                  </div>
                </Row> 
                <div className="content" >
                             <button type="button" className="btonGuardar" 
                              onClick={props.guardarDatos} 
                               disabled={props.banderaBotonGuardar} >
                              <span>{etiquetasAsigZore.guardar}</span>
                            </button>
                </div>      
            </div>
            <Modal visible={props.isModalVisibleDatosGuradados}
                        title={<img src={asignaZores} id="img-modal"/>}
                        width={335}
                        onCancel={props.handleCancel2}
                        footer={[null]}
                        centered
                        className="modal-Voluntarios">
                          <div id="folio-casilla"> 
                            <p className="titulo-modal">{etiquetasAsigZore.mensaje_casilla_modificada}</p>  
                            <p className="notificacion-modal">{etiquetasAsigZore.mensaje_voluntarios_asignados}</p>
                            <Button type="button" id="guardar-zor" style={{ background: "#D4217F", borderColor: "#ffffff" }} onClick={props.handleCancel2}>Aceptar</Button>
                          </div>           
            </Modal>
            <Modal visible={props.isModalVisibleDatosGuradados2}
                        title={<img src={modificarAsignacionZore} id="img-modal"/>}
                        width={335}
                        onCancel={props.handleCancel2}
                        footer={[null]}
                        centered
                        className="modal-Voluntarios">
                          <div id="folio-casilla"> 
                            <p className="titulo-modal">{etiquetasAsigZore.mensaje_casilla_modificada}</p>  
                            <p className="notificacion-modal">{etiquetasAsigZore.mensaje_voluntarios_asignados}</p>
                            <Button type="button" id="guardar-zor" style={{ background: "#D4217F", borderColor: "#ffffff" }} onClick={props.handleCancel2}>Aceptar</Button>
                          </div>           
            </Modal>
            <Modal title="Acción realizada" 
            visible={props.mensajeVolEliminado}
            footer={[null]}
            width={335}
            title={<img src={eliminacionZore} id="img-modal"/>}>
                            <Row id="modal-body">
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                  <p className="titulo-modal">
                                  {etiquetasAsigZore.mensaje_eliminado_asignados}                                  
                                  </p>   
                                  <p className="notificacion-modal">{etiquetasAsigZore.mensaje_eliminado_asignados_sub}</p>                  
                                  <Button type="button" id="guardar-zor" style={{ background: "#D4217F", borderColor: "#ffffff" }} onClick={props.handleCancel2}>Aceptar</Button>
                              </Col>
                            </Row>                            
            </Modal>
            <Modal title="Acción realizada" 
            visible={props.mensajeAdvertencia}
            onCancel={props.handleCancel} 
            onOk={props.procesoEliminacionCasilla}
            width={335}
            title={<img src={advertenciaZore} id="img-modal"/>}>
              <Row id="modal-body">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div id="folio-casilla">
                    {etiquetasAsigZore.mensajeAdvertencia}                                  
                  </div> 
                    <p className="notificacion-modal" style={{textAlign:"center", marginTop:"15px"}}>{etiquetasAsigZore.mensajeAdvertencia_sub}</p>       
                </Col>
              </Row>                            
            </Modal>
            <Modal title="Acción realizada" visible={props.mensajeVolMaximo} onCancel={props.handleCancel} width={480}>
                            <Row id="modal-body">
                              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <div id="folio-casilla">
                                     {etiquetasAsigZore.maximo_de_voluntarios}                                 
                                  </div>                           
                              </Col>
                            </Row>                            
            </Modal>
      </div>
    </Layout>                 
        );
      }

export default VZoreVoluntarios;

