import React, { useState, useEffect }from 'react';
import {Row, Col, Select, Typography, Form, Input,Radio,
          Checkbox, Button, DatePicker,Tooltip,Alert, Modal} from 'antd';
import { ArrowRightOutlined, ArrowLeftOutlined,QuestionCircleFilled} from '@ant-design/icons';
import moment from 'moment';
import {connect} from 'react-redux';
import * as formatos from '../../../constants/Formatos';
import * as etiquetas from '../../../constants/EtiquetasVoluntarios';
import {valueUpperCase} from '../../../utils/funciones.js';
import imagenAyudaClaveE from '../../../assets/img/ayuda_clave_elector.jpg';

const opcionesTipoVol = [
  {
    idTipo:"I",
    valorTipo:"Interno INE"
  },
  {
    idTipo:"O",
    valorTipo:"Interno OPL"
  },
  {
    idTipo:"E",
    valorTipo:"Externo"
  }
];
const { Option } = Select;

const VDatosPersonales  = (props) => {
const [datosPersonalesForm] = Form.useForm();

const onFinish = (values: any) => {
      console.log('Received values of form: ', values);
      if(props.claveEncontrada > 0 ){
         datosPersonalesForm.setFieldsValue({ "claveElector": undefined });
      }else{
        props.revisaCampoPersonales(values);
      }
  };

const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

const obtenerEdad = (fecha) =>{
    let edad = fecha ?
       moment().diff(moment(fecha, formatos.FORMATO_FECHA_MOMENT), 'years') : 0;

    datosPersonalesForm.setFieldsValue({"edad":edad});
  }

const onTipoVoluntarioChange = (value) => {
     datosPersonalesForm.setFieldsValue({ "tipoVoluntario": value });
     return;
  };

const onGeneroChange = (tipoGenero) => {
   console.log("en el genero",tipoGenero);
    datosPersonalesForm.setFieldsValue({ "genero": tipoGenero.target.value });
    return;
};

const onLenguaChange = (hablaLengua) => {
  var lengua = hablaLengua.target.value;
   datosPersonalesForm.setFieldsValue({ "hablaIndigena": lengua });
   props.hablaLenguaIndigena(hablaLengua);
   return;
};

const cambiarCurpAmayus = async() =>{
  console.log("la curp", datosPersonalesForm.getFieldValue("curp"));
  var curp = datosPersonalesForm.getFieldValue("curp");
  if(curp !== props.curp){
    var curpMayus =  curp.toUpperCase();
    datosPersonalesForm.setFieldsValue({ "curp": curpMayus});
  }
}

const buscarClaveElector = async (clave) =>{
  clave = datosPersonalesForm.getFieldValue("claveElector");
  if(clave !== undefined){
    var claveElector  = clave.toUpperCase();
    console.log("claveElector", claveElector,"--",props.claveElector);
    datosPersonalesForm.setFieldsValue({ "claveElector": claveElector});
    if(claveElector !== props.claveElector){
      await props.buscarClaveElector(claveElector);
    }else if (claveElector === props.claveElector) {
      props.refrescarAdvertencia();
    }
  }

}

useEffect(() => {
  console.log("es edición",props.claveElectorBuscada);
  if(props.editar){
    datosPersonalesForm.setFieldsValue({
      'claveElector' : props.claveElectorBuscada !== "" ?
                      props.claveElectorBuscada : props.claveElector,
      'nombre' : props.nombre,
      'apellidoP' : props.apellidoP, 'apellidoM' : props.apellidoM,
      'fechaNacimiento' :
        moment(props.fechaNacimiento,formatos.FORMATO_FECHA_MOMENT),
      'edad' : props.edad,
      'genero' : props.genero !== null ? props.genero : "",
      'correo' : props.correo,
      'telefono' : props.telefono !== null ? props.telefono : "",
      'celular' : props.celular !== null ? props.celular : "",
      'hablaIndigena' : props.hablaIndigena !== null ? props.hablaIndigena : "N",
      'queLengua' : props.queLengua !== null ? props.queLengua : "",
      'tipoVoluntario'  : props.tipoVoluntario,
      'experiencia': props.experiencia, 'curp': props.curp
      });
  }
});

function disabledDate(value) {
       // Can not select days before today and today
       var fechaFin = moment('31/10/2003',formatos.FORMATO_FECHA_MOMENT);
       var fechaInicio = moment('01/01/1900',formatos.FORMATO_FECHA_MOMENT);
       if (fechaInicio > value){
           return true;
       }else if(value > moment().endOf('day')){
           return true;
       }
   return fechaFin < value;
}

const acomodarTexto = (texto) =>{
  var textoDividido = texto.split('.');
  return textoDividido[0]+". \r\n"+textoDividido[1]+". \r\n"+textoDividido[2];
}


  return(
    <>

      <Form
        form={datosPersonalesForm}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout = "vertical"
        scrollToFirstError ={true}
        validateMessages={etiquetas.defaultValidateMessages}
      >

    <Row gutter={16}>
    <Tooltip title={acomodarTexto(etiquetas.ayuda_tipo_voluntario)}>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Form.Item
          label={etiquetas.datos_personales_lbl_tipoVoluntario}
          name="tipoVoluntario"
          rules={[{
                    required: true,
                }]}
            className = "formularioInput">
            <Select defaultValue ="E"
                onChange={onTipoVoluntarioChange}
                defaultValue={props.tipoVoluntario}>
              {
                opcionesTipoVol &&
                opcionesTipoVol.map((tipo, index) => (
                    <Option key={index} value={tipo.idTipo} selected>
                      {tipo.valorTipo}
                    </Option>
                ))
              }
            </Select>
        </Form.Item>
      </Col>
      </Tooltip>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <span id="span-ayuda-claveE">
          <QuestionCircleFilled id="ayuda-claveE"
          onMouseOver={()=>document.getElementById("img-ayuda").style.display = 'block'}
          onMouseOut={()=>document.getElementById("img-ayuda").style.display = 'none'}
          />
        </span>
        <Form.Item
          label={etiquetas.datos_personales_lbl_claveElector}
          name="claveElector"
          rules={[{
                    min: 13,
                    max: 18,
                    pattern: formatos.REGEX_FORMATO_CLAVE_ELECTOR_FUAR,
                    required: true
                }]}
          className = "formularioInput">
          <Input onBlur={buscarClaveElector} />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Form.Item
          label={etiquetas.datos_personales_lbl_curp}
          name="curp"
          rules={[{
                    len: 18,
                    pattern: formatos.REGEX_FORMATO_CURP,
                    required: true
                }]}
          className = "formularioInput">
          <Input  onBlur={cambiarCurpAmayus}/>
        </Form.Item>
      </Col>
    </Row>
    <Row>
    <Col xs={24} sm={24} md={24} lg={24} xl={24}>

        <img src={imagenAyudaClaveE} id="img-ayuda"/>

    </Col></Row>
    <Row gutter={16}>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Form.Item
          label={etiquetas.datos_personales_lbl_nombre}
          name="nombre"
          rules={[{
                    max: 40,
                    pattern: formatos.REGEX_FORMATO_NOMBRES,
                    required: true
                }]}
          className = "formularioInput">
          <Input maxLength={40 }/>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Form.Item
          label={etiquetas.datos_personales_lbl_apellidoPaterno}
          name="apellidoP"
          rules={[{
                    max: 50,
                    pattern: formatos.REGEX_FORMATO_NOMBRES,
                    required: props.apellidoUnoObli
                }]}
          className = "formularioInput">
          <Input maxLength={50}/>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Form.Item
          label={etiquetas.datos_personales_lbl_apellidoMaterno}
          name="apellidoM"
          rules={[{
                    max: 50,
                    pattern: formatos.REGEX_FORMATO_NOMBRES,
                    required: props.apellidoDosObli,
                }]}
          className = "formularioInput">
          <Input maxLength={50}/>
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col xs={24} sm={24} md={6} lg={6} xl={6}>
        <Form.Item
          label={etiquetas.datos_personales_lbl_fechaNacimiento}
          name="fechaNacimiento"
          rules={[{
                    required: true,
                }]}
          className = "formularioInput">
          <DatePicker
            format={formatos.FORMATO_FECHA_MOMENT}
            showToday={true}
            disabledDate={disabledDate}
            minDate = "01/01/1950"
            maxDate = {moment().format(formatos.FORMATO_FECHA_MOMENT)}
            onChange = {obtenerEdad}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={6} lg={6} xl={6}>
        <Form.Item
          label={etiquetas.datos_personales_lbl_edad}
          name="edad"
          rules={[{
                    len: 2,
                    pattern: formatos.REGEX_FORMATO_EDAD,
                    required: true
                }]}
          className = "formularioInput">
          <Input maxLength={2} disabled={true}/>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Form.Item
          label={etiquetas.datos_personales_lbl_genero}
          name="genero"
          rules={[{
                    required: true
                }]}
          className = "formularioInput">
          <Radio.Group id="campo-genero" onChange={onGeneroChange} >
            <Radio value="F">{etiquetas.datos_personales_lbl_genero_f}</Radio>
            <Radio value="M">{etiquetas.datos_personales_lbl_genero_m}</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Form.Item
          label={etiquetas.datos_personales_lbl_correoElectronico}
          name="correo"
          rules={[{
                    max: 150,
                    pattern: formatos.REGEX_FORMATO_CORREO,
                    required: true
                }]}
          className = "formularioInput">
          <Input/>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Form.Item
          label={etiquetas.datos_personales_lbl_celular}
          name="celular"
          rules={[{
                    len: 10,
                    pattern: formatos.REGEX_FORMATO_TELEFONO,
                    required: props.celularObli
                }]}
          className = "formularioInput">
          <Input maxLength={10}/>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Form.Item
          label={etiquetas.datos_personales_lbl_telefono }
          name="telefono"
          rules={[{
                    len: 10,
                    pattern: formatos.REGEX_FORMATO_TELEFONO,
                }]}
          className = "formularioInput">
          <Input maxLength={10}/>
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Form.Item
          label={etiquetas.datos_personales_lbl_experiencia}
          name="experiencia"
          rules={[{
                    required: true
                }]}
          className = "formularioInput">
          <Radio.Group id="campo-experiencia">
            <Radio value="S">Si</Radio>
            <Radio value="N">No</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={8} lg={6} xl={6}>
        <Form.Item
          label={etiquetas.datos_personales_lbl_hablaLenguaIndigena}
          name="hablaIndigena"
          rules={[{
                    required: true
                }]}
          className = "formularioInput">
          <Radio.Group>
            <Radio value="S">Si</Radio>
            <Radio value="N">No</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={8} lg={6} xl={6}>
        <Form.Item
          label={etiquetas.datos_personales_lbl_cualLenguaIndigena}
          name="queLengua"
          disabled={!props.lenguaIndigena}
          rules={[{
                    required:
                     datosPersonalesForm.setFieldsValue("hablaIndigena") === "S"
                     ? true :false,
                     pattern: formatos.REGEX_FORMATO_NOMBRES,
                     len:100
                }]}
          className = "formularioInput">
          <Input/>
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col xs={2} sm={2} md={2} lg={2} xl={2}>
        <Form.Item
          name="avisoPrivacidad"
          valuePropName="checked"
          rules={[{
                    required: true, message:etiquetas.error_aviso_privacidad
                }]}>
            <Checkbox>

           </Checkbox>
        </Form.Item>
      </Col>
      <Col xs={22} sm={22} md={22} lg={22} xl={22}>
      <span id="texto-check">
         La persona declara que participa de manera voluntaria, que ha
         leído el <a onClick={props.abrirAvisoPrivacidad}> aviso de privacidad </a>
         de sus datos personales y acepta ser contactada vía correo
         electrónico para algún seguimiento o notificación de información
          sobre el proceso en que se encuentra participando.
          Asimismo, que conoce que en su momento habrá de firmar una
          declaración de responsabilidad respecto a la custodia,
          el cuidado y buen uso de un dispositivo móvil propiedad del
          Instituto Nacional Electoral.
       </span>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Form.Item>
          <Button type="primary"
            className="botonDerRegistro"
            htmlType="submit"
            shape="circle"
            icon={<ArrowRightOutlined id="flechaDer"/>}
            disabled={!props.mostrarAdvertencia }/>
        </Form.Item>
      </Col>
    </Row>
  </Form>
  <Modal
    visible={props.mostrarAvisoModal}
    onCancel={props.cerrarAviso}
    className="modalAvisoPrivacidad"
    centered={true}
    footer={null}>
    <Row>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <p className="titulo">Aviso de privacidad simplificado</p>
        <p className="subtitulo">Listado de personas voluntarias de casilla</p>
        <p className="subtitulo">en la Consulta Infantil y Juvenil</p>
      </Col>
    </Row>
    <Row>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <p className="pregunta">
          ¿Quién es el responsable de tus datos personales?
        </p>
        <p className="respuestaPregunta">
          El Instituto Nacional Electoral (INE), a través de la Dirección
          Ejecutiva de Capacitación Electoral y Educación Cívica (DECEyEC).
        </p>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <p className="pregunta">
          ¿Para qué finalidad o finalidades utilizamos tus datos personales?
        </p>
        <p className="respuestaPregunta">
          Los datos personales que se recaben en el sistema de listados de
          personas voluntarias de casillas en la Consulta Infantil y Juvenil,
          se utilizarán de manera concreta para registro, control, seguimiento,
          impresión de formatos y gafetes; la capacitación; la entrega-recepción
          de dispositivos móviles propiedad del Instituto Nacional Electoral
          que se utilizarán en dichas casillas y la correspondiente declaración
          de responsabilidad. Todo ello en relación con las actividades
          inherentes a la integración y operación de las casillas de la Consulta
          Infantil y Juvenil 2021.
        </p>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <p className="pregunta">
          ¿A quién transferiremos los datos personales?
        </p>
        <p className="respuestaPregunta">
          No realizaremos transferencias de datos personales, salvo aquellas que
           sean necesarias para atender requerimientos de información de
           autoridades competentes, que estén debidamente fundados y motivados.
        </p>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <p className="pregunta">
          ¿Cómo y en dónde puedes manifestar la negativa al tratamiento de tus datos?
        </p>
        <p className="respuestaPregunta">
          Para manifestar la negativa al tratamiento de tus datos podrás ejercer
          los derechos de cancelación y oposición de datos personales, ante la
          Unidad Técnica de Transparencia y protección de Datos Personales
          (UT) del INE, ubicada en Viaducto Tlalpan #100, Edif. "C", 1er. Piso,
          Col. Arenal Tepepan, Tlalpan, C.P. 14610, Ciudad de México, de 9:00 a
          18:00 horas, de lunes a viernes en días hábiles, o bien, a través de
          la Plataforma Nacional de Transparencia en &nbsp;
          <a href="http://www.plataformadetransparencia.org.mx/" target="_blank">
          http://www.plataformadetransparencia.org.mx/</a>.
        </p>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <p className="pregunta">
          ¿Dónde puedes consultar el aviso de privacidad integral?
        </p>
        <p className="respuestaPregunta">
          El aviso de privacidad integral se puede consultar en el siguiente
          sitio:&nbsp;
          <a href="https://www.ine.mx/transparencia/listado-bases-datos-personales/"
           target="_blank">
           https://www.ine.mx/transparencia/listado-bases-datos-personales/</a>,
          &nbsp;en el apartado correspondiente a la DECEyEC.
        </p>
      </Col>
    </Row>
  </Modal>
</>
  );
}

export default VDatosPersonales;
