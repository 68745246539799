import React from 'react';
import {Grid} from '@material-ui/core'
import { useState } from "react";
import {Button, Row, Col, Input, Layout , Form, Modal, Pagination, Popover, Select, Tooltip, DatePicker, TimePicker} from 'antd';
import { TeamOutlined, EditOutlined, EyeOutlined, PlusCircleOutlined, CloseCircleOutlined, 
  SearchOutlined, QuestionCircleFilled, FilterOutlined, HomeFilled } from '@ant-design/icons';
import moment from 'moment';
import * as etiquetasAsigVoluntarios from '../../../constants/EtiquetasSeguimientoVoluntarios';
import 'antd/dist/antd.css';


import imgAdvertencia from '../../../assets/img/imgAdvertencia.png';
import imgObservaciones from '../../../assets/img/imgObservaciones.png';
import "../../../css/seguimientoVoluntarios/seguimientoVoluntarios.css";
import imgVirtual from '../../../assets/img/img_virtual.png';
import imgPresencial from '../../../assets/img/img_presencial.png';
import imgReasignada from '../../../assets/img/img_reasignada.png';

import iconoMomentaneo from '../../../assets/img/icono_asignar_sede.svg';
import icono_anadir from '../../../assets/img/icono_tache.svg';
import icono_rosa from '../../../assets/img/iconoRosa.svg';
import icono_borrar from '../../../assets/img/icono_borrar.svg';
import icono_mas from '../../../assets/img/icono_mas.svg';
import icono_ocultar from '../../../assets/img/icono_ocultar.svg';





const { Option } = Select;





const VSeguimientoVoluntarios = (props) =>{
  const [formAsignacion] = Form.useForm();
  const [searFolio, setSearchFolio] = useState("");
  const [searVoluntario, setSearchVoluntario] = useState("");
  const [searFolioVol, setSearchFolioVol] = useState("");
  const [searClave, setSearchClave] = useState("");
  const [searchText, setSearchText] = useState("");
  
const { TextArea } = Input;
  const handleSearchClick = (searchText)=>{
    props.handleOk2(searchText)
    setSearchText("");
  }

  function confirm() {
    console.log("ENTRO")
  }

  
       return(
        <Grid container>         
        <Grid item xs={12}>          
            <Layout className='validar_modulo'>              
              <Form name="seguimientoForm">                         
                <div  id="icon-pregunta">
                  <span className="tituloCasillas">
                     {etiquetasAsigVoluntarios.titulo_componente}
                  </span>           
                </div>  
                  
                    <br></br>
                    <br></br>                    
                     <Grid item xs={12} className="paginationStyle">                                  
                      <div> 
                                   
                      <Grid item xs={12}> 
                      <div>
                      <div id="icon-pregunta" className="table2Style"> 
                                {etiquetasAsigVoluntarios.titulatio}
                               <Tooltip  title={etiquetasAsigVoluntarios.toltip2}>
                                    <QuestionCircleFilled />
                                </Tooltip>                              
                           
                            </div>

                            <table id="icon-pregunta"  className="tableStyle"> 
                              <thead className="tableStyle1"> 
                                                                                     
                                        <th style={{ width: "18%" }}>{etiquetasAsigVoluntarios.folio}
                                        <br/>
                                        <div className="icon-preguntaTabla">                                            
                                          <Input 
                                              value={searFolio} 
                                              onChange={({target:{ value}}) => setSearchFolio(value)}
                                              placeholder={etiquetasAsigVoluntarios.folio} style={{width: "60%"}}
                                              onKeyPress={(ev) => {if (ev.key === 'Enter') {props.busquedaFolioVol(searFolio)}}}                                            
                                           /> 
                                        </div>
                                        </th>

                                        <th style={{ width: "15%" }}>{etiquetasAsigVoluntarios.voluntario}
                                        <br/>
                                        <Input 
                                          value={searVoluntario} 
                                          onChange={({target:{ value}}) => setSearchVoluntario(value)}
                                          placeholder={etiquetasAsigVoluntarios.voluntario} style={{width: "100%"}}
                                          onKeyPress={(ev) => {if (ev.key === 'Enter') {props.busquedaVoluntario(searVoluntario)}}}   
                                        />                                        
                                        </th>

                                        <th style={{ width: "20%" }}>{etiquetasAsigVoluntarios.municipio}
                                        <br/>
                                        <Select  defaultValue={etiquetasAsigVoluntarios.selecciona}  style={{ width: "100%", backgroundImage: icono_anadir.svg}} onChange={props.busquedaMunicipio} >                                  
                                            <Option value="">{etiquetasAsigVoluntarios.selecciona} </Option>
                                            { props.infoMunicipios.map((value, index) =>{
                                              return(                                          
                                                <Option value={value.nombreMunicipio} >                                
                                                <tr key={index} >                                                                                        
                                                  {value.nombreMunicipio}                                            
                                                  </tr>
                                                  </Option>
                                                    )
                                                })}                                                                          
                                          </Select>
                                        </th>

                                        <th style={{ width: "20%" }}>{etiquetasAsigVoluntarios.clave}
                                        <br/>
                                        <Input 
                                              value={searClave} 
                                              onChange={({target:{ value}}) => setSearchClave(value)}
                                              placeholder={etiquetasAsigVoluntarios.clave} style={{width: "60%"}}
                                              onKeyPress={(ev) => {if (ev.key === 'Enter') {props.busquedaClaveElector(searClave)}}}                                            
                                           /> 
                                        </th>                                        
                                        <th style={{ width: "15%" }}>{etiquetasAsigVoluntarios.registro}<div> <br/></div></th>                                       
                                        <th >{etiquetasAsigVoluntarios.acciones} &nbsp;                                    
                                        
                                        <div><br/></div></th>  
                                  </thead>
                              <tbody>  
                                                                        
                              {props.infoVoluntarios.map((value, index) =>{
                                  return(                                    
                                    <tr key={index}>
                                      <td >{value.folio}</td>
                                      <td>{value.nombreVoluntario}</td>
                                      <td>{value.nombreMunicipio}</td>
                                      <td>{value.claveElector}</td>
                                      <td>{value.fechaRegistro}</td>
                                      <td onClick={()=>
                                        props.estatusModulo ? 
                                        props.vistaModalDescartar(value): ""}>
                                      {value.estatus === 4 ? <p><Tooltip title={etiquetasAsigVoluntarios.reasignar}>
                                      <img src={icono_anadir} /></Tooltip> </p> : <p>
                                        <Tooltip title={etiquetasAsigVoluntarios.descartar}><img src={icono_rosa} /></Tooltip> </p>}</td>
                                        <td  onClick={()=>
                                        value.estatus != 4  ? 
                                        props.recuperaDatos(value): ""}>
                                        {value.estatus === 4 ? <p><Tooltip title={etiquetasAsigVoluntarios.asignar}>
                                            <img src={iconoMomentaneo} /></Tooltip> </p> :
                                            
                                          <Popover placement="bottomLeft"                                       
                                          title={etiquetasAsigVoluntarios.asignarCapacitacion}                                           
                                          content={
                                            <div Grid item xs={12}  style={{height:"300px", width:"300px", textAlign: "center"}}>
                                              
                                                    <th style={{textAlign:"left"}}>{etiquetasAsigVoluntarios.tipoSede}
                                                      <br/>
                                                      <Select  disabled={props.banderaTipoSede} 
                                                      defaultValue= {value.tipoSede != null ? value.tipoSede : etiquetasAsigVoluntarios.selecciona}                                                      
                                                      style={{ width: "300px", backgroundImage: icono_anadir.svg}} onChange={props.seleccionarSede} >                                  
                                                          <Option value="">{etiquetasAsigVoluntarios.selecciona} </Option>
                                                          { props.tipoSedeSelect.map((value, index) =>{
                                                            return(                                          
                                                              <Option value={value.tipoSede} >                                
                                                              <tr key={index} >                                                                                        
                                                                {value.tipoSede}                                            
                                                                </tr>
                                                                </Option>
                                                                  )
                                                              })}                                                                          
                                                        </Select>
                                                    </th><br/>
                                                    
                                                    <th style={{textAlign:"left"}} >{etiquetasAsigVoluntarios.sede}
                                                      <br/>
                                                      <Select  disabled={props.banderaSede}                                                      
                                                      defaultValue={value.lugar !=null ? value.lugar : etiquetasAsigVoluntarios.selecciona}                                                       
                                                        style={{ width: "300px", backgroundImage: icono_anadir.svg}} onChange={props.guardaSede} >                                  
                                                          <Option  style={{ width: "300px" }} value="">{etiquetasAsigVoluntarios.selecciona} </Option>
                                                          { props.sedesSelect.map((value, index) =>{
                                                            return(                                          
                                                              <Option value={value.idSede} >                                
                                                              <tr key={index} >                                                                                        
                                                                {value.lugar}                                            
                                                                </tr>
                                                                </Option>
                                                                  )
                                                              })}                                                                          
                                                        </Select>
                                                    </th><br/>

                                                     <div style={{float: "left"}} >
                                                     <th style={{textAlign:"left"}}>
                                                     {etiquetasAsigVoluntarios.fecha} <br/>
                                                     <DatePicker onChange={props.fechaNueva}  defaultValue={value.fecha != null ? 
                                                                         moment(value.fecha):""
                                                                      }/>
                                                     </th>
                                                     </div>
                                                     
                                                     <div style={{float:"right"}} >
                                                     <th style={{textAlign:"left"}}>
                                                     {etiquetasAsigVoluntarios.hora}<br/>
                                                     <TimePicker format="hh:mm"  onChange={props.horaNueva} defaultValue={value.hora != null ?
                                                       moment(value.hora, 'hh:mm'):""} />
                                                     </th>
                                                    </div>                                                                                                              
                                                    <Button type="button" id="guardar-seleccionados2" 
                                                        onClick={props.guardarDatos} 
                                                        disabled={props.banderaBotonGuardar} >
                                                        <span>{etiquetasAsigVoluntarios.guardar}</span>
                                                      </Button>  
                                                                                       
                                            </div>          
                                            } 
                                          trigger="click">
                                          <p><Tooltip title={etiquetasAsigVoluntarios.asignar}>
                                            <img src={iconoMomentaneo} /></Tooltip> </p> 
                                          </Popover> }                                 
                                         </td>                                    
                                    </tr>
                                  )
                              })}
                              </tbody>                                            
                          </table> 
                          </div>
                          </Grid>                         
                          <Grid item xs={12}> 
                          <div id="icon-asignar" className="paginationStyle">
                           <HomeFilled onClick={props.obtenerVoluntarios} />                        
                          <Pagination 
                          size="small" 
                          showSizeChanger={false} 
                          total={props.paginas} 
                          page={props.paginado} 
                          onChange={props.paginacionCasillas} />                              
                     
                          </div>
                          </Grid>                                              
                      </div>
                      </Grid>              

                        <Modal title=""
                            visible={props.banderaModalDescartar} 
                            footer={null}
                            onCancel={props.handleCancel}
                            width={335}
                            className="modalCasillas"
                            centered
                             >
                              <div >
                                <br></br>
                                {props.estatus != 4 ?                                
                                <div className="div_modal">                                
                                  <div id='asg_img'>
                                    <img src={imgAdvertencia} alt=""/>
                                  </div>
                                  <label id="texto_modal3">
                                    {etiquetasAsigVoluntarios.descartarPersona} </label>

                                    <label id="texto_modal3">
                                    {etiquetasAsigVoluntarios.pregunta} </label>
                                    
                                    
                                    <div className="content" >

                                    <Button type="button" id="btn_aceptar1" 
                                          onClick={props.handleCancel}                                          
                                          >
                                          <span>{etiquetasAsigVoluntarios.cancelar}</span>
                                        </Button> &nbsp; &nbsp; &nbsp;

                                        <Button type="button" id="btn_aceptar" 
                                          onClick={props.handleOk}                                         
                                          >
                                          <span>{etiquetasAsigVoluntarios.aceptar}</span>
                                        </Button>
                                   </div>   
                               </div> 
                                :
                              <div className="div_modal">
                                
                                <div id='asg_img'>
                                  <img src={imgReasignada} alt=""/>
                                </div>

                                <label id="texto_modal">
                                  {etiquetasAsigVoluntarios.reasignarPersona} </label>
                                <label id="texto_modal2">
                                  {etiquetasAsigVoluntarios.reasignarPersona2} </label>

                                  <label id="texto_modal3">
                                  {etiquetasAsigVoluntarios.mensaje_sede_presencial3} </label>
                                
                                  <label id="texto_modal31">                                      
                                    {etiquetasAsigVoluntarios.mensaje_sede_presencial4} </label>                                  
                                  
                                  <div className="content" >                                 

                                      <Button type="button" id="btn_aceptar2" 
                                        onClick={props.actualizaVoluntarios}                                         
                                        >
                                        <span>{etiquetasAsigVoluntarios.boton_reasignar}</span>
                                      </Button>
                                 </div>   
                             </div>  }                                                     
                              </div>                            
                          </Modal>



                          <Modal title=""
                            visible={props.banderaModalObservaciones} 
                            footer={null}
                            onCancel={props.handleCancel2}
                            width={335}
                            className="modalCasillas"
                            centered
                             >
                              <div >
                                <br></br>                     
                                <div className="div_modal">                                
                                  <div id='asg_img1'>
                                    <img src={imgObservaciones} alt=""/>
                                  </div>
                                  <label id="texto_modal4">
                                    {etiquetasAsigVoluntarios.motivo} </label>
                                    <div style={{padding: "15px", marginTop: "-40px"}}>
                                    <TextArea rows={4} cols={1}
                                    maxlength="250" 
                                    value={searchText} 
                                    onChange={({target:{ value}}) => setSearchText(value)}
                                    className="search-box-input"
                                    showSizeChanger                        
                                    
                                    />
                                    <label id="text250">
                                    250 caracteres restantes 
                                    </label>
                                   
                                      </div>                                  
                                    <div  style={{marginRight: "100px"}}>
                                     <Button type="button" id="btn_aceptar" 
                                          /*onClick={handleSearchClick(searchText)}*/
                                         onClick={() =>handleSearchClick(searchText)}                            
                                         disabled={!searchText.length || !props.banderaUserCau}                                        
                                          >
                                          <span>{etiquetasAsigVoluntarios.enviar}</span>
                                        </Button>
                                        </div>
                                   </div>                                                                                                                
                              </div>                            
                          </Modal>  

                         
                          <Modal title=""
                            visible={props.banderaFinal} 
                            footer={null}
                            onCancel={props.metodoFinal}
                            width={335}
                            className="modalCasillas"
                            centered
                             >
                              <div >
                                <br></br>
                                {props.banderaPresencial ? 
                                <div className="div_modal">
                                
                                  <div id='asg_img'>
                                    <img src={imgPresencial} alt=""/>
                                  </div>

                                  <label id="texto_modal">
                                    {etiquetasAsigVoluntarios.mensaje_sede_presencial}</label>
                                    
                                    <label id="texto_modal2">
                                    {etiquetasAsigVoluntarios.mensaje_sede_presencial2}</label>
                                    

                                  <label id="texto_modal3">
                                    {etiquetasAsigVoluntarios.mensaje_sede_presencial3} </label>
                                    <label id="texto_modal31">                                      
                                    {etiquetasAsigVoluntarios.mensaje_sede_presencial4} </label>
                                   
                                   
                                    <div className="content" >                                        
                                        <Button type="button" id="btn_aceptar" 
                                            onClick={props.metodoFinal}                                         
                                            >
                                            <span>{etiquetasAsigVoluntarios.registro_nuevo}</span>
                                        </Button>
                                   </div>   
                              </div>
                                : 
                              <div className="div_modal">
                                
                                <div id='asg_img'>
                                  <img  src={imgVirtual} alt=""/>                                 
                                </div>
                                

                                <label id="texto_modal">
                                  {etiquetasAsigVoluntarios.mensaje_sede_virtual}</label>
                                  
                                  <label id="texto_modal2">
                                  {etiquetasAsigVoluntarios.mensaje_sede_presencial2}</label>
                                  

                                <label id="texto_modal3">
                                  {etiquetasAsigVoluntarios.mensaje_sede_presencial3} </label>
                                
                                  <label id="texto_modal31">                                      
                                    {etiquetasAsigVoluntarios.mensaje_sede_presencial4} </label>

                                  <div className="content" >
                                     <Button type="button" id="btn_aceptar" 
                                        onClick={props.metodoFinal}                                         
                                        >
                                        <span>{etiquetasAsigVoluntarios.registro_nuevo}</span>
                                      </Button>
                                 </div>   
                            </div>                                
                                }                                  
                              </div>                            
                          </Modal> 






                      <div>
                </div>
              
           </Form>
           
         </Layout>  
         </Grid>
           </Grid>               
        );
      }

export default VSeguimientoVoluntarios;

