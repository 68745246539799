import React from 'react';

const VLabel = ({ color, label, value }) => {
    return(
        <div className='home-label-container'>
            <span className="hlc-square"
                style={{backgroundColor:`${color}`}}/>
            <span className='hlc-label'>{label}</span>
            { value ? <span className='hlc-value'>{value.toLocaleString('en-US')}</span> : "" }
        </div>
    );
}

export default VLabel;