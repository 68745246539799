import { createSelector } from 'reselect';

const selectCasilla = (state) => state.casilla;

export const selectCurrentCasilla = createSelector(
  [selectCasilla],
  (casilla) => casilla.currentCasilla
);

export const selectCurrentCasillaRol = createSelector(
  [selectCasilla],
  (casilla) => casilla.CasillaId
);
