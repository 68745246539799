import React from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import axios from 'axios';
import CTemplate from '../homeInterno/CTemplate';
import AuthenticatedComponent from "../../components/comunes/AuthenticatedComponent";
import { createStructuredSelector } from 'reselect';
import { selectCurrentVoluntario, selectCurrentVoluntarioId }
                            from '../../redux/voluntario/voluntario-selectors';
import { setCurrentVoluntario, setCurrentVoluntarioId}
                               from '../../redux/voluntario/voluntario-actions';
import { selectEstado, selectDistrito} from '../../redux/menu/menu-selectors';
import { selectCurrentUser} from '../../redux/user/user-selectors';
import * as etiquetas from '../../constants/EtiquetasGenerales';
import * as etiquetasVoluntarios from '../../constants/EtiquetasVoluntarios';
import * as info from '../../constants/InfoSistema';
import VConsultaVoluntarios from '../../components/voluntarios/registro/VConsultaVoluntarios';
import { PATH_REGISTRO_VOLUNTARIO, PATH_HOME_INT } from '../../constants/routers/paths';
import {obtenerEstatusModulo} from '../../redux/menu/menu-utils';

import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { Input, Button, Space } from 'antd';

var filtroMunicipio : null;
var filtroNombre : null;
var filtroClaveElector : null;

class CConsultaVoluntarios extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      listaVoluntarios : null,
      paginaActual: 1,
      cargando : false,
      paginaActual : 1,
      total : 0,
      searchText: '',
      searchedColumn: '',
      mostrarModal : false,
      cargarIneFrente : null,
      cargarIneReverso : null,
      cargarComprobante : null,
      cargarFoto : null,
      listaMunicipios : null
    }
    this.refFolioInput = React.createRef();
    this.refNombreInput = React.createRef();
    this.refMuniciInput = React.createRef();
    this.refClaveInput = React.createRef();
  }

  componentDidMount(){
    this.consultarEstatusModulo();
    this.consultarPermisoUsuario();

  }

  consultarEstatusModulo(){
    var idProcesoElectoral = this.props.user.idProcesoElectoral;
    var idDetalleProceso = this.props.user.idDetalle;
    var idEstado = this.props.estado.idEstado;
    var idDistrito = this.props.distrito.idDistrito;
    var idSistema = info.ID_SISTEMA;
    var idModulo = this.props.location.state !== undefined ?
                    this.props.location.state.idModulo : 0;
    var grupoSistema = this.props.user.rolUsuario;

    obtenerEstatusModulo(idProcesoElectoral, idDetalleProceso,idSistema,
      idEstado, idDistrito, idModulo, grupoSistema).then(respuesta =>{
      console.info("El estatus del módulo registro voluntarios es: ",respuesta);
      if(respuesta === "A"){
        console.info("El Módulo registro voluntarios está abierto ");
        this.obtenerVoluntarios();
        this.obtenerListaMunicipios();
      }else{
        console.info("El Módulo registro voluntarios está cerrado ");
        this.props.history.push(PATH_HOME_INT);
      }
    })
  }

  consultarPermisoUsuario = () =>{
    this.setState({
      tienePermisos :
      etiquetasVoluntarios.roles_consulta.includes(this.props.user.rolUsuario)
    })
  }

  mostrarInformacion = (voluntario) =>{
    console.log("abriendo modal para el voluntario...",voluntario.idVoluntario);
    this.setState({cargando:true});
    this.buscarVoluntarioAeditar(voluntario);

  }

  cerrarModal = () =>{
    console.log("cerrando modal del voluntario... ",this.props.voluntario);
    this.props.setCurrentVoluntario(null);
    this.setState({
      mostrarModal :false,
      cargarIneFrente : null,
      cargarIneReverso : null,
      cargarComprobante : null,
      cargarFoto : null
    });
  }

  buscarVoluntarioAeditar = (voluntario) =>{
    var infoPost = {
      idProcesoElectoral :this.props.user.idProcesoElectoral ,
      idDetalleProceso :this.props.user.idDetalle,
      idEstado : this.props.estado.idEstado,
      idDistrito : this.props.distrito.idDistrito,
      idVoluntario : voluntario !== null  ? voluntario.idVoluntario : null
    }
    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL+
      "ws/buscarVoluntario", infoPost,
          {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.user ? this.props.user.tknJWT : null
              }
          }
       ).then(response => {
         console.info("voluntario obtenido",response,voluntario);
         this.acomodarInfoVoluntario(
           response.data.consultaVoluntario,voluntario);

    }).catch(error => {
        console.error("Error al obtener el voluntario a editar",error);
    });
  }

  acomodarInfoVoluntario = (infoVoluntario,voluntarioTabla) =>{
    if(infoVoluntario !== null){
      var voluntario = {
        municipio : voluntarioTabla.municipio,
        tipoVoluntario: this.obtenerTipoVoluntario(infoVoluntario.tipoVoluntario),
        claveElector: infoVoluntario.claveElector,
        nombre: infoVoluntario.nombre,
        apellidoP : infoVoluntario.apellidoPaterno,
        apellidoM : infoVoluntario.apellidoMaterno,
        fechaNacimiento: this.obtenerFechaFormateada(infoVoluntario.fechaNacimiento),
        edad: infoVoluntario.edad,
        genero: this.obtenerGenero(infoVoluntario.genero),
        correo: infoVoluntario.correoElectronico,
        telefono: infoVoluntario.telefonoCasa,
        celular: infoVoluntario.telefonoMovil,
        hablaIndigena: infoVoluntario.hablaLenguaIndigena === "S" ? "Si" : "No",
        queLengua: infoVoluntario.cualLenguaIndigena,
        calle: infoVoluntario.calle,          numeroInt: infoVoluntario.numeroInt,
        numeroExt: infoVoluntario.numeroExt,  colonia: infoVoluntario.colonial,
        cp: this.zfill(infoVoluntario.codigoPostal,5),
        idVoluntario : infoVoluntario.idVoluntario,
        folio : infoVoluntario.folio,
        curp:infoVoluntario.curp, experiencia : infoVoluntario.experiencia
      };
      this.obtenerArchivosSubidos(infoVoluntario.idVoluntario);
      this.props.setCurrentVoluntario(voluntario);
      this.setState({
        mostrarModal :true,
        cargando:false
      });
    }

  }

  zfill = (number, width) =>{
      var numberOutput = Math.abs(number); /* Valor absoluto del número */
      var length = number.toString().length; /* Largo del número */
      var zero = "0"; /* String de cero */

      if (width <= length) {
          if (number < 0) {
               return ("-" + numberOutput.toString());
          } else {
               return numberOutput.toString();
          }
      } else {
          if (number < 0) {
              return ("-" + (zero.repeat(width - length)) + numberOutput.toString());
          } else {
              return ((zero.repeat(width - length)) + numberOutput.toString());
          }
      }
  }

  obtenerGenero = (genero)=>{
    switch (genero) {
      case "F":
        return "Femenino";
      break;
      case "M":
        return "Masculino";
      break;
      case "I":
        return "No me identifico con ninguno de los dos";
      break;
      default:
        return "";
    }
  }

  obtenerTipoVoluntario = (tipoVoluntario)=>{
    switch (tipoVoluntario) {
      case "I":
        return "Interno";
      break;
      case "E":
        return "Externo";
      break;
      case "O":
        return "OPL";
      break;
      default:
        return "";
    }
  }

  obtenerFechaFormateada = (fechaRegistrada) =>{
    if(fechaRegistrada !== null){
      var fecha = fechaRegistrada.split("T");
      var diaMesAno = fecha[0].split("-");
      var fechaFormada = diaMesAno[2]+"/"+diaMesAno[1]+"/"+diaMesAno[0];
      return fechaFormada;
    }
  }

  obtenerListaMunicipios = () => {
    var infoPost = {
      idEstado: this.props.estado.idEstado
    }
    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL+
      "ws/obtMunicipios", infoPost,
          {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.user ? this.props.user.tknJWT : null
              }
          }
       ).then(response => {
         console.info("obteniendo municipios",response);
         this.setState({
           listaMunicipios:response.data.infoMunicipios
         });
    }).catch(error => {
        console.error("Error al obtener la lista de estados",error);
    });
  }

  obtenerArchivosSubidos = (idVoluntario) =>{
    const infoPost = {
      idProcesoElectoral: this.props.user.idProcesoElectoral,
      idDetalleProceso: this.props.user.idDetalle,
      idEstado: this.props.estado.idEstado,
      idDistrito: this.props.distrito.idDistrito,
      idVoluntario: idVoluntario
    }
    console.log("infoPost para otener archivos del voluntario",infoPost);
    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL+
      "ws/obtenerArchivosVoluntario", infoPost, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': this.props.user ? this.props.user.tknJWT : null
          }
      }
       ).then(response => {
         console.info("Archivos en gluster",response)
         if(response.data.archivosVoluntario !== null){
           this.acomodarArchivo(response.data.archivosVoluntario);
         }
    }).catch(error => {
        console.error("Error al obtener los archivos del aspirante",error);
    });
  }

  acomodarArchivo = (archivosVoluntario) =>{
    console.log("archivosVoluntarios",archivosVoluntario);
    const url = process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL+
                                                            "ws/obtenerArchivo";
    var tipoArchivo = "";

    archivosVoluntario.forEach((archivo) => {
      console.log("archivo",archivo);
      var infoPost = {
        idProcesoElectoral: this.props.user.idProcesoElectoral,
        idDetalleProceso: this.props.user.idDetalle,
        idEstado: this.props.estado.idEstado,
        idDistrito: this.props.distrito.idDistrito,
        folio: archivo.folio,
        src : archivo.nombreArchivo,
        claveElectorSiap : archivo.fuarSiap
      }

      if (archivo.nombreArchivo.endsWith('.jpeg') ||
          archivo.nombreArchivo.endsWith('.jpg')){
          tipoArchivo = 'image/jpeg';
      }else if (archivo.nombreArchivo.endsWith('.png')){
          tipoArchivo= 'image/png';
      } else {
        tipoArchivo= 'application/pdf';
      }

      this.obtenerArchivo(infoPost,tipoArchivo,archivo.idTipoDocumento);
    });

  }

  obtenerArchivo = (infoPost,tipoArchivo,idTipoDocumento) =>{
    var tken = {
      'Authorization': this.props.user ? this.props.user.tknJWT : null
    }
    console.log("obteniendi archivos",infoPost, tipoArchivo);
    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL+
      "ws/obtenerArchivo", infoPost, {
            responseType: 'blob',
            transformResponse: [ data => {
                let blob = new window.Blob([data], {type: tipoArchivo});
                this.srcURLObject = window.URL.createObjectURL(blob);
                return this.srcURLObject;
            }],
        }).then(rawData => {
          console.log("idTipoDocumento",idTipoDocumento,rawData);
          switch (idTipoDocumento) {
            case 1:
              this.setState({cargarIneFrente: rawData});
            break;
            case 2:
              this.setState({cargarIneReverso: rawData});
            break;
            case 3:
              this.setState({cargarComprobante: rawData});
            break;
            case 4:
              this.setState({cargarFoto: rawData});
            break;
          }
            console.log("rawData ",this.state);
        });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.estado !== prevProps.estado
      || this.props.distrito !== prevProps.distrito) {
      this.regresarPrimeraPagina();
      this.obtenerListaMunicipios();
    }
  }

  enviarAmodificar = (registro) => {
    this.props.setCurrentVoluntario(registro);
    this.props.history.push(PATH_REGISTRO_VOLUNTARIO);
  }

  obtenerVoluntarios = (inicio = 1, fin =5) =>{
     let infoPost = {
        idProcesoElectoral : this.props.user.idProcesoElectoral,
        idDetalleProceso : this.props.user.idDetalle,
        idEstado: this.props.estado.idEstado,
        idDistrito: this.props.distrito.idDistrito,
        inicio:  inicio,
        fin:  fin,
        folio : this.refFolioInput.current.state.value,
      	nombreApellido : filtroNombre !== undefined && filtroNombre !== null ?
                                              filtroNombre.toUpperCase() : null,
      	idMunicipio : filtroMunicipio,
        claveElector :filtroClaveElector !== undefined && filtroClaveElector !== null
            ? filtroClaveElector : null
      }
      console.log("la info a buscar",infoPost);
      axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL +
        "ws/obtenerVoluntariosRegistrados", infoPost,
            {
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': this.props.user ? this.props.user.tknJWT : null
                }
            }
         ).then(response => {
           console.log("obteniendo voluntarios",response);
           this.setState({
             listaVoluntarios:response.data.listaVoluntarios,
             total: response.data.totalVoluntarios,
             cargando : false,
             cargaBusquedaVolun : false
           });
           this.refFolioInput.current.state.value = null;
           this.refNombreInput.current.state.value = null;
           this.refClaveInput.current.state.value = null;
        }).catch(error => {
          console.error("Error al obtener la lista de voluntarios",error);
          this.setState({
            listaVoluntarios:null,
            total: 0,
            cargando : false,
            cargaBusquedaVolun : false
          });
      });
  }

  obtenerVoluntariosPorfolio=()=>{
    filtroMunicipio = null;
    filtroNombre = null;
    filtroClaveElector = null;
    console.info("El valor del filtro es",
              this.refFolioInput.current.state.value);
    if(this.refFolioInput.current.state.value === null){
      this.regresarPrimeraPagina();
    }else{
      this.obtenerVoluntarios();
    }
  }

  obtenerVoluntariosPorNombre=(valor)=>{
    filtroMunicipio = null;
    filtroClaveElector = null;
    console.info("El valor del filtro es",this.refNombreInput.current.state.value);
    if(this.refNombreInput.current.state.value === null){
      this.regresarPrimeraPagina();
    }else{
      filtroNombre = this.refNombreInput.current.state.value;
      this.obtenerVoluntarios();
    }
  }

  obtenerVoluntariosPorMunicio=(valor)=>{
    filtroNombre = null;
    filtroClaveElector = null;
    console.info("El valor del filtro municipio es",valor,
      this.refMuniciInput);
      filtroMunicipio = valor
    this.obtenerVoluntarios();
  }

  obtenerVoluntariosPorClave=(valor)=>{
    filtroMunicipio = null;
    filtroNombre = null;
    console.info("El valor del filtro es",
              this.refClaveInput.current.state.value);
    if(this.refClaveInput.current.state.value === null){
      this.regresarPrimeraPagina();
    }else{
      filtroClaveElector = this.refClaveInput.current.state.value;
      this.obtenerVoluntarios();
    }
  }

  regresarPrimeraPagina = () =>{
    this.setState({
      paginaActual : 1,
    });
    this.paginado(1);
  }

  paginado = pagina =>{
    var paginaAnterior = parseInt(pagina,10) - 1 === 0 ? 1 :
                                                        parseInt(pagina,10) - 1;
    this.setState({
      paginaActual : pagina,
      cargando: true,
    });

    var inicio = parseInt(pagina,10) === 1 ? 1 : (paginaAnterior * 5)+1;
    var fin = parseInt(pagina,10) * 5;

    this.obtenerVoluntarios(inicio,fin);
  }

  render(){
    return(
      <>
        <AuthenticatedComponent  >
          <CTemplate
            bloqueado = {!etiquetasVoluntarios.roles_consulta.includes(
                                                    this.props.user.rolUsuario)}
            contenido = {
              <VConsultaVoluntarios
                regresarPrimeraPagina = {this.regresarPrimeraPagina}
                paginaActual = {this.state.paginaActual}
                paginado = {this.paginado}
                total = {this.state.total}
                cargando={this.state.cargando}
                listaVoluntarios = {this.state.listaVoluntarios}
                obtenerVoluntarios ={this.obtenerVoluntarios}
                enviarAmodificar = {this.enviarAmodificar}
                getColumnSearchProps = {this.getColumnSearchProps}
                mostrarInformacion = {this.mostrarInformacion}
                cerrarModal = {this.cerrarModal}
                mostrarModal = {this.state.mostrarModal}
                cargarIneFrente = {this.state.cargarIneFrente}
                cargarIneReverso = {this.state.cargarIneReverso}
                cargarComprobante = {this.state.cargarComprobante}
                cargarFoto = {this.state.cargarFoto}
                listaMunicipios = {this.state.listaMunicipios}
                obtenerVoluntariosPorfolio = {this.obtenerVoluntariosPorfolio}
                obtenerVoluntariosPorNombre = {this.obtenerVoluntariosPorNombre}
                obtenerVoluntariosPorMunicio = {this.obtenerVoluntariosPorMunicio}
                obtenerVoluntariosPorClave = {this.obtenerVoluntariosPorClave}
                refFolioInput = {this.refFolioInput}
                refNombreInput = {this.refNombreInput}
                refMuniciInput = {this.refMuniciInput}
                refClaveInput = {this.refClaveInput}
              />
            }
            guia={false}/>

          </AuthenticatedComponent>
      </>
    );
  }

}

const mapStateToProps = createStructuredSelector({
    estado: selectEstado,
    distrito: selectDistrito,
    user: selectCurrentUser,
    voluntario : selectCurrentVoluntario
});

const mapDispatchToProps = dispatch => ({
	setCurrentVoluntario: voluntario => dispatch(setCurrentVoluntario(voluntario)),
	setCurrentVoluntarioId: voluntarioId => dispatch(setCurrentVoluntarioId(voluntarioId))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CConsultaVoluntarios));
