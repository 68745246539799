import CasillaActionTypes from './casilla-types';

export const setCurrentCasilla = (casilla) => ({
  type: CasillaActionTypes.SET_CURRENT_CASILLA,
  payload: casilla,
});

export const setCurrentCasillaId = (casillaId) => ({
  type: CasillaActionTypes.SET_CURRENT_CASILLA_ID,
  payload: casillaId,
});

export const casillaLogout = () => ({
  type: CasillaActionTypes.CASILLA_LOGOUT
});
