
export const login_btn_inicioSesion = "Inicia sesión"

export const login_accion_iniciarSesion = "Iniciar sesión"
export const login_accion_cierraSesion = "Cerrar sesión"

export const login_lbl_usuario      = "Usuario"
export const login_lbl_constrasenia = "Contraseña"
export const login_lbl_registrate   = "Registrate ahora"

export const login_msg_constrasenia = "Utiliza tu Clave de Elector o tu SIAP. Si eres trabajador del INE, ingresa con tu usuario y contraseña institucional"
export const login_lbl_sesionActiva = "El usuario cuenta con una sesión activa, volverlo a intentar después de 30 minutos."
