import React, {useState, useEffect} from 'react';
import {Layout, Row, Col, Form, Card, InputNumber, DatePicker, Button, Modal, Input, Select, Tooltip, Table, Pagination} from 'antd';
import {HomeFilled} from '@ant-design/icons';
import convocatoriaModificaImg from '../../assets/img/modificaConvocatoria.png';
import * as etiquetasConvocatorias from '../../constants/EtiquetasConvocatorias';
import iconoQuestion from '../../assets/img/icono_question.png';
import iconoFiltro from '../../assets/img/icono_filter.png';
import iconoMostrar from '../../assets/img/icono_mostrar_gafete.png';
import iconoEditar from '../../assets/img/icono-editar.png';
import '../../css/convocatorias/convocatorias.css';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment, { isMoment } from 'moment';
import 'moment/locale/es';


const { Option } = Select;

const VConvocatoriasConsulta = (props) =>{

  const { TextArea } = Input;

  const [searchNumeroConvocatoria, setSearchNumeroConvocatoria] = useState("");

  const [fechaI, setFechaI] = useState();
  const [fechaT, setFechaT] = useState();

  const [convocatoria, setConvocatoria] = useState();
  const [fechaIncorrecta, setFechaIncorrecta] = useState(false);
  const [numCartel, setNumCartel] = useState();
  const [fvalObservar, setFvalObservar] = useState(false);
  const [valres, setValres] = useState(500);

  const [observacion, setObservacion] = useState();

  const [form] = Form.useForm();


  const loadProfile = () =>{

    var fechaInicio = props.fechaInicio;
    var fechaTermino = props.fechaTermino;
    var numeroCarteles = props.numeroCarteles;
    var observaciones = props.observaciones;
    
    var fechaTI = moment(fechaInicio, 'DD/MMMM/YYYY');
    var fechaTT = moment(fechaTermino, 'DD/MMMM/YYYY');

    setFechaI(fechaInicio);
    setFechaT(fechaTermino);
    setNumCartel(numeroCarteles);
    setObservacion(observaciones);

    if(fechaInicio !== null && fechaTermino !== null){
      form.setFieldsValue({"fechaInicio" : fechaTI });
      form.setFieldsValue({"fechaTermino" : fechaTT});
    }
    form.setFieldsValue({"cantidadCarteles" : numeroCarteles});
    form.setFieldsValue({"observaciones" : observaciones});
  }

  useEffect(()=>{
    loadProfile();
    revisarObservacion();
  },[convocatoria, observacion, props.numeroConvocatoria]);


  const columns = [
    {
      title:(
        <div style={{ textAlign: 'center' }}>
            <div>
              <Tooltip title={etiquetasConvocatorias.etiqueta_tooltip_tabla_consulta}>
                <img style={{width:17 , height:17}} src={iconoQuestion}/>
              </Tooltip>
            </div>
            <br />
          </div>),
      dataIndex:'info',
      key:'info'
    },
    {
      title: (
        <div style={{ textAlign: 'center', width:"180px"}}>
            <span>N° de convocatoria </span>
            <br />
            <Input
                  value={searchNumeroConvocatoria}
                  onChange={({target:{ value}}) => setSearchNumeroConvocatoria(value)}
                  placeholder='N° de convocatoria'  
                  suffix = {<img style={{width:12 , height:12}} src={iconoFiltro}/>}
                  onKeyPress={(e) => {if (e.key === 'Enter') {props.searchNumeroConvocatoria(searchNumeroConvocatoria)}}}   
                  />
        </div>
      ),
      align: 'center',
      dataIndex: 'numeroConvocatoria',
      key: 'numeroConvocatoria',
      width:180
    },
    {
      title: (
        <div style={{ textAlign: 'center' }}>
            <div>Fecha de inicio</div>
            <br />
        </div>
      ),
      align: 'center',
      dataIndex: 'fechaRegistroI',
      key: 'fechaRegistroI',
    },
    {
      title: (
        <div style={{ textAlign: 'center'}}>
            <div>Fecha de término</div>
            <br />
        </div>
      ),
      align: 'center',
      dataIndex: 'fechaRegistroF',
      key: 'fechaRegistroF',
    },
    {
      title: (
        <div style={{ textAlign: 'center' }}>
            <div>Observaciones</div>
            <br />
        </div>
      ),
      align: 'center',
      dataIndex: 'observaciones',
      key: 'observaciones',
      render: (fila, record) => <><span>{record.observaciones === null ? "No": "Sí"}</span></>
    },
    {
      title: (
        <div style={{ textAlign: 'center' }}>
            <div>Acciones</div>
            <br/>
        </div>
      ),
      align: 'center',
      dataIndex: 'acciones',
      key: 'acciones',
      render: (fila, record) =>  
              props.estatusModulo ? (<><Tooltip title={etiquetasConvocatorias.etiqueta_tooltip_consultar}><a onClick={()=>{props.mostrarModal(record)}}><img style={{width:23 , height:14 }} src={iconoMostrar} id="eyecc-icon"/></a></Tooltip>&nbsp;
                                        <Tooltip title={etiquetasConvocatorias.etiqueta_tooltip_modificar}><a onClick={()=> (props.rolPermitido === false) ? '' : props.modificaConvocatoria(record)}><img style={{width:13 , height:15}} src={iconoEditar} id="edit-icon"/></a></Tooltip>&nbsp;</>):
                                    (<><Tooltip title={etiquetasConvocatorias.etiqueta_tooltip_consultar}><a><img style={{width:23 , height:14 }} src={iconoMostrar} id="eyecc-icon"/></a></Tooltip>&nbsp;
                                        <Tooltip title={etiquetasConvocatorias.etiqueta_tooltip_modificar}><a><img style={{width:13 , height:15}} src={iconoEditar} id="edit-icon"/></a></Tooltip>&nbsp;</>)

                                
    }
  ];

  function disabledDate (current) {
    return (current && current > moment(new Date('2022-01-01')).endOf('day')) || (current && current < moment(new Date('2021-01-01')).endOf('month'));
}


  const revisarObservacion = (e) => {

    const valor = observacion;
    const reg = /[!¡¿°´@#$%^&*_+\-=\[\]{}"\\|<>\/?]+/;
    
    if(valor !== undefined){
        if(valor !== null && valor.length > 0){
          setValres(500 - valor.length)}
    

      /*if(reg.test(valor)){
       setFvalObservar(true);
      }else if(valor !== null && !isNaN(valor) && valor.length >=500){
       setFvalObservar(true);
      }else{
       setFvalObservar(false); 
      }*/
    }

  }



    return(
      <>
        <Layout className='validar_modulo'>

          <div style={{display: props.modificar ? "none" : "block"}}>
            <div id="seccion1">
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <h1 id="titulo-componente">Consultar y modificar</h1>
                </Col>
              </Row>
              </div>
                <Card title={<span className="tituloTablaConvocatorias">{etiquetasConvocatorias.etiqueta_titulo_tabla_consultar}</span>} bordered={true} style={{ marginBottom: '24px', textAlign: 'center'}}  size="small">
                  <div id="tabla-consulta-casillas" style={{alignContent:"center"}}>
                      <Table dataSource={props.convocatorias} pagination={false} columns={columns} size="small" className="tabla-convocatoria"/>
                      <br/>
                  </div>
                </Card>
                <br/>
                <div id="paginado">
                <Row justify="center">
                  <Col xs={24} sm={24} md={{span:1, offset:4}} lg={{span:1, offset:4}} xl={{span:1, offset:4}}>
                    <HomeFilled onClick={props.regresarPrimeraPagina} />
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                 
                    <Pagination current={props.actual}
                                onChange={props.paginado} 
                                total={props.total}
                                defaultPageSize={5}
                                loading={props.cargando}
                                size="small"
                                showSizeChanger={false}/>
                  </Col>
                </Row>
              </div>
            </div>
            <div style={{display: props.modificar ? "block" : "none"}}>
                <Row>
                    <Col offset={1} sm={22} md={22} lg={22}>
                      <Form form ={form} layout="vertical">
                        <Card bordered={false}> 
                          <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                              <h1 id="titulo-componente">Modificar información<br/></h1>
                            </Col>
                          </Row> 
                          <Row>                                             
                            <Col sm={24} md={6} lg={6}>
                                <span className="convocatoriaConsulta">No. de convocatoria: &nbsp;{props.numeroConvocatoria}</span>
                            </Col>
                          </Row>
                        </Card>


                        <Card bordered={false}>
                        <div>
                            <label>Periodo de difusión de la convocatoria.&nbsp;<Tooltip title={etiquetasConvocatorias.etiqueta_tooltip_tabla_periodo}>
                                                                            <img style={{width:17 , height:17}} src={iconoQuestion}/>
                                                                          </Tooltip>
                            </label>
                        </div>
                        <br/>
                        <Row>
                            <Col sm={24} md={6} lg={6}>
                                <Form.Item form = {form} label="Fecha de inicio" name="fechaInicio" rules={[{required: true, message: 'Dato requerido'}]}
                                            validateStatus={props.estatusFecha ? "error" : null} help={props.estatusFecha ? "Dato incorrecto" : null}>
                                    <DatePicker style={{width: "175px"}} 
                                                onChange={(date, dateString) =>{ setFechaI(dateString)
                                                                                 props.onChangeFI(dateString)}}
                                                format={'DD/MMMM/YYYY'} locale={locale} value={fechaI} disabledDate={disabledDate}/>
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={6} lg={6}>
                                <Form.Item form = {form} label="Fecha de término" name="fechaTermino" rules={[{required: true, message: 'Dato requerido'}]}
                                           validateStatus={props.estatusFecha ? "error" : null} help={props.estatusFecha ? "Dato incorrecto" : null}>
                                    <DatePicker style={{width: "175px"}} 
                                                onChange={(date, dateString) =>{ setFechaT(dateString)
                                                                                 props.onChangeFT(dateString)}}
                                                format={'DD/MMMM/YYYY'} locale={locale} value={fechaT} disabledDate={disabledDate}/>
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={6} lg={6}>
                                <Form.Item form = {form} label="Cantidad de carteles" name="cantidadCarteles" rules={[{required: true, message: 'Dato requerido'}]}>
                                    <InputNumber name = "cantidadCarteles"
                                                 onChange = {(e)=>{ setNumCartel(Math.round(e))
                                                                    props.changeC(Math.round(e))}}
                                                 style={{width: "146px"}}
                                                 min={0} max={9999}
                                                 precision={0}></InputNumber>
                                </Form.Item>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col sm={24} md={24} lg={24}>
                                <Form.Item form = {form} label="Observaciones" name="observaciones">
                                    <TextArea name = "observaciones"
                                              onChange = {(e)=>{ setObservacion(e.target.value)
                                                                 props.cambioText(e)
                                                                 revisarObservacion(e)}}
                                              rows={5}
                                              />
                                </Form.Item>
                            </Col>
                        </Row>
                            <Col offset={20} sm={24} md={4} lg={4}>
                                <span>{valres}&nbsp;caracteres restantes</span>
                            </Col>
                        <Row >
                        </Row>
                        <br/>
                        <br/>
                        <div className="div-boton-consulta">
                        <Row>
                            <Col offset={10} sm={6} md={6} lg={6}>
                              <Tooltip title={(fechaI !== undefined) && (fechaI !== '') &&
                                                                    (fechaT !== undefined) && (fechaT !== '') && 
                                                                    (numCartel !== undefined) && (numCartel !== '') && (numCartel > 0)  && (fvalObservar !== true ) && (props.estatusFecha === false) && (props.rolCau === false) ? null: etiquetasConvocatorias.etiqueta_tooltip_boton_guardar}>
                                <Button htmlType="submit" disabled={(fechaI !== undefined) && (fechaI !== '') &&
                                                                    (fechaT !== undefined) && (fechaT !== '') && 
                                                                    (numCartel !== undefined) && (numCartel !== '') && (numCartel > 0)  && (fvalObservar !== true ) && (props.estatusFecha === false) && (props.rolCau === false) && (props.rolPermitido === true) ? false : true } style={{width:"118px", borderColor: "#ffffff", borderRadius: "10px"}} onClick={props.enviarModificacion}>Guardar</Button>
                              </Tooltip>
                            </Col>
                        </Row>
                        </div>
                      </Card>
                      </Form>
                    </Col>
                </Row>
                </div>
                <Modal visible={props.mostrarConsulta}
                       onCancel={props.ocultarModal}
                       centered
                       width={1000}
                       className="Modal-consultaConvocatoria"
                       bodyStyle={{height:620, background:'#ffffff'}}
                       footer={false}>
                                {
                      props.convocatoriaSeleccionadaModal != null ?
                      <>
                        <Row id="modal-header">
                          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <h1>&nbsp;&nbsp;Consultar información<br/>
                                <span className="consultaConvocatoriaStyle">&nbsp;&nbsp;No. de convocatoria:&nbsp;{props.convocatoriaSeleccionadaModal.numeroConvocatoria}</span>
                            </h1>
                          </Col>
                        </Row>
                        <Card bordered={false}>
                          <Row gutter={8}>
                            <Col sm={24} md={24} lg={24}>
                              <div id="periiodo">
                                <label>Periodo de difusión de la convocatoria.&nbsp;</label>
                              </div>
                            </Col>
                          </Row>
                          <br/>
                          <Row gutter={8}>
                            <Col sm={24} md={8} lg={8}>
                              <div id="fechaInicio">
                                  <label> Fecha de inicio:&nbsp;</label><br/>{props.convocatoriaSeleccionadaModal.fechaRegistroI}
                              </div>
                            </Col>
                            <Col sm={24} md={8} lg={8}>
                              <div id="fechaTermino">
                                  <label> Fecha de término:&nbsp;</label><br/>{props.convocatoriaSeleccionadaModal.fechaRegistroF}
                              </div>
                            </Col>
                            <Col sm={24} md={8} lg={8}>
                              <div id="cantidadCarteles">
                                  <label> Cantidad de carteles:&nbsp;</label><br/>{props.convocatoriaSeleccionadaModal.cantidadCarteles}
                              </div>
                            </Col>
                          </Row>
                          <br/>
                          <br/>
                          <Row gutter={8}>
                            <Col sm={24} md={24} lg={24}>
                              <div id="observaciones">
                                  <label> Observaciones:&nbsp;</label><br/>{props.convocatoriaSeleccionadaModal.observaciones}
                              </div>
                            </Col>
                          </Row>
                        </Card>
                  </>
                : ""
                }
                </Modal>
                <Modal
                  className="modalModifica"
                  visible={props.abrirModificaExito}
                  title={<img  src={convocatoriaModificaImg} id="img-modal"/>}
                  onCancel={props.onChangeRoute}
                  centered
                  width={327}
                  footer={[null]}>{
                  <>
                      <div>
                        <span className="titulo-exito">Convocatoria modificada</span>
                      </div>
                      <div>
                        <span className="notificacion-exito">La información se actualizó con éxito</span>
                      </div>
                      <br/>
                      <Row>
                        <Col offset={8} span={6}>
                          <Button type="primary" htmlType="submit" style={{ background: "#D4217F", borderColor: "#ffffff" }} onClick={props.onChangeRoute}>Aceptar</Button>
                        </Col>
                      </Row>
                      <br/>
                  </>
                }
                </Modal>
        </Layout>
      
      </>
    )
}

export default VConvocatoriasConsulta;