import React from 'react';
import {Row, Col, Form, Input,Button} from 'antd';
import { ArrowRightOutlined, ArrowLeftOutlined,QuestionCircleFilled} from '@ant-design/icons';
import * as etiquetas from '../../../../constants/EtiquetasVoluntarios';

const VDatosDConsulta = (props) =>{

  return(
    <div>
    <Row gutter={16}>
      <Col xs={24} sm={24} md={6} lg={6} xl={6}>
        <Form.Item
           label = {etiquetas.datos_direccion_lbl_calle}
           disabled={true} className = "formularioInput"
           rules={[{
             required: false
            }]}>
            <label>{props.voluntarioSeleccionado.calle}</label>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={5} lg={5} xl={5}>
        <Form.Item
          label = {etiquetas.datos_direccion_lbl_numero_exterior}
          disabled={true} className = "formularioInput"
          rules={[{
                    required: false,
                }]}>
          <label>{props.voluntarioSeleccionado.numeroInt}</label>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={5} lg={5} xl={5}>
        <Form.Item
          label={etiquetas.datos_direccion_lbl_numero_interior}
          disabled={true} className = "formularioInput"
          rules={[{
                    required: false
                }]}>
        <label>{props.voluntarioSeleccionado.numeroExt}</label>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={5} lg={5} xl={5}>
        <Form.Item
          label={etiquetas.datos_direccion_lbl_colonia}
          disabled={true} className = "formularioInput"
          rules={[{
                    required: false
                }]}>
          <label>{props.voluntarioSeleccionado.colonia}</label>
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col xs={24} sm={24} md={5} lg={5} xl={5}>
        <Form.Item
          label={etiquetas.datos_direccion_lbl_municipio}
          name="municipio" className = "formularioInput"
          rules={[{
            required: false,
          }]}>
          <label>{props.voluntarioSeleccionado.municipio}</label>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={5} lg={5} xl={5}>
        <Form.Item
          label={etiquetas.datos_direccion_lbl_cp}
          disabled={true} className = "formularioInput"
          rules={[{
                    required: false,
                }]}>
          <label>{props.voluntarioSeleccionado.cp}</label>
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Button type="primary" className="botonIzq"
            onClick={()=>props.navegar("1")}
            shape="circle"
            icon={<ArrowLeftOutlined id="flechaDer"/>}/>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <Form.Item>
          <Button type="primary" className="botonDer"
            onClick={()=>props.navegar("3")}
            shape="circle"
            icon={<ArrowRightOutlined id="flechaDer"/>}/>
        </Form.Item>
      </Col>
    </Row>
    </div>
  );
}

export default VDatosDConsulta;
