import React from 'react';
import { ConfigProvider, Layout, Row, Col } from 'antd';
import esES from 'antd/es/locale/es_ES';
import BlockLoader from '../../components/comunes/Loader.js';
import '../../css/homeExterno/template_externo.css';
import VHeader from '../../components/comunes/VHeader.jsx';
import VIniciaSesion from '../../components/login/VIniciaSesion.jsx';

const { Content } = Layout;

class CTemplateExt extends React.Component {

	constructor(props){
		super(props);
		this.state = ({
      		loginEsVisible: false
		});
	}

	mostrarLogin = () => {
		this.setState({
			loginEsVisible: true
		});
	}

	ocultarLogin = () => {
		this.setState({
			loginEsVisible: false
		});
	}

	render() {
		const bloquear = this.props.bloqueado || this.props.cargando;

		return (
			<ConfigProvider locale={esES}>
				<Layout className={`layout template_externo ${this.props.isHome?'home':''}`}
						style={{'overflowX': 'hidden'}} >
					<Row>
						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<VHeader ocultarLogin = {this.ocultarLogin}
									loginEsVisible = {this.state.loginEsVisible}/>
						</Col>
						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<VIniciaSesion mostrarLogin = {this.mostrarLogin} />
						</Col>
					</Row>
					<Content className={`${this.props.contenidoExpandido? 'contenido_expandido' : ''}
										${this.props.isHome?'home':''}`}>
						<BlockLoader blocking={bloquear && !this.props.bloquearTodo}>
							{this.props.contenido}
						</BlockLoader>
					</Content>
				</Layout>
			</ConfigProvider>
		);
	}
}

CTemplateExt.defaultProps = {
	bloqueado: false,
	cargando: false,
	enlaces: true,
	contenidoExpandido: false,
}

export default CTemplateExt;
