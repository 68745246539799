import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser, selectCurrentUserRol } from '../../redux/user/user-selectors';
import { setCurrentUser, setCurrentUserRol, setCurrentUserNivel, userLogout } from '../../redux/user/user-actions';
import { selectEstado, selectDistrito } from '../../redux/menu/menu-selectors';
import { updateListaEstados, updateEstado, updateDistrito, updateListaDistritos, updateMenu } from '../../redux/menu/menu-actions';
import { obtenerMenuLateral, obtenerEstados, obtenerDistritos } from '../../redux/menu/menu-utils';
import { obtenerFormatoDistrito } from '../../utils/funciones.js';
import { change } from '../../utils/funciones.js';
import VLogin from '../../components/login/VLogin';
import * as infoSistema from '../../constants/InfoSistema';
import * as etiquetas from '../../constants/EtiquetasGenerales';
import { PATH_HOME_INT, PATH_LOGIN } from '../../constants/routers/paths';

class CLogin extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			peticion: {},
			isVerified: false,
			mensajeError: '',
			username: undefined,
			password: undefined,
			cargando: false
		}

		this.change = change.bind(this);
	}

	obtenerNivelUsuarioI = (estado, idDistrito, rolUsuario, userRol) => {
		const distrito = obtenerFormatoDistrito(idDistrito);

		var nivelU = "";
		var nivel = rolUsuario.split(".")[2].split("_");

		if (nivel.length === 2) {
			nivelU = nivel[1] + " " + distrito + " "+ estado;
		}else{
			switch (userRol) {
				case 'JL':
					nivelU = userRol + " " + distrito + " "+ estado;
				break;
				case 'JD':
					nivelU = userRol + "E " + distrito + " " + estado;
				break;
				case 'OC':
					nivelU = "DECEyEC";
				break;
				default:
					nivelU = ""
			}
		}

		this.props.setCurrentUserNivel(nivelU);
	}

	/**
	* Función que se dispara cuando el formulario se
	* lleno correctamente.
	**/
	onFinish = async (values: any) => {
		this.setState({
			username: values["usuario"].trim(),
			password: values["password"].trim(),
			cargando: true
		});

		const token = await window.grecaptcha.execute(infoSistema.SECRET_CAPTCHA_KEY,
													{action: 'submit'});

		var values = {
			"usuario": this.state.username,
			"password": this.state.password,
			"idSistema": infoSistema.ID_SISTEMA,
			"recaptchaResponse": token,
			"versionAplicacion": infoSistema.VERSION_SISTEMA
		}

		try {
			const response = await axios.post(process.env.REACT_APP_API_URL
				+ etiquetas.WS_CENTRAL
				+ 'ws/validateUser',
				values);
			const datos = response.data.datosUsuario;
			const entidad = response.data.code;

			if (datos && datos.code !== 400) {
				this.props.setCurrentUser(datos);
				const {rolUsuario, idEstado, estado, idDistrito, distrito} = datos;
				const userRol = rolUsuario.substr(-2,2);

				this.props.setCurrentUserRol(userRol);
				this.props.updateEstado({
											idEstado: idEstado,
											nombreEstado: estado
										});
				this.props.updateDistrito({
											idDistrito: idDistrito,
											nombreDistrito: distrito
										});
				this.props.updateListaEstados(await obtenerEstados(idEstado));

				if(this.props.estado.idEstado > 0){
					this.props.updateListaDistritos(await obtenerDistritos(idEstado,
																		idDistrito));
				} else{
					this.props.updateListaDistritos([]);
				}

				this.props.updateMenu(await obtenerMenuLateral(infoSistema.ID_PROCESO_ELECTORAL,
															infoSistema.ID_DETALLE_PROCESO,
															infoSistema.ID_SISTEMA,
															idEstado,
															idDistrito,
															rolUsuario));

				if(datos.tipoUsuario === 'I'){
					this.obtenerNivelUsuarioI(estado, idDistrito, rolUsuario, userRol);
					this.props.history.push(PATH_HOME_INT);
				}

			} else {
				this.props.userLogout();
				this.props.history.push(PATH_LOGIN);
				console.log("entidad",response.data,entidad.code === 400);
				//if(entidad.code === 400){
					this.setState({
						mensajeError: response.data.message
					})
				//}
			}

		}catch(error){
			console.log(error);
		}

		this.setState({
			cargando: false
		});
	}

	/**
	* Función que se dispara cuando hay un error en el formulario.
	**/
	onFinishFailed = (errorInfo: any) => {
		console.info("Error en formulario, ver la parte de errores.");
		console.error('Failed:', errorInfo);
	};

	render() {
		return (
			<VLogin cargando = {this.state.cargando}
				onFinish = {this.onFinish}
				onFinishFailed = {this.onFinishFailed}
				mensajeError = {this.state.mensajeError}/>
		);
	}
}

const mapStateToProps = createStructuredSelector({
    user: selectCurrentUser,
	userRol: selectCurrentUserRol,
	estado: selectEstado,
	distrito: selectDistrito
});

const mapDispatchToProps = dispatch => ({
	setCurrentUser: user => dispatch(setCurrentUser(user)),
	setCurrentUserRol: userRol => dispatch(setCurrentUserRol(userRol)),
	setCurrentUserNivel: userNivel => dispatch(setCurrentUserNivel(userNivel)),
	userLogout: () => dispatch(userLogout()),
	updateListaEstados: listaEstados => dispatch(updateListaEstados(listaEstados)),
	updateEstado: estado => dispatch(updateEstado(estado)),
	updateDistrito: distrito => dispatch(updateDistrito(distrito)),
	updateListaDistritos: listaDistritos => dispatch(updateListaDistritos(listaDistritos)),
	updateMenu: subMenus => dispatch(updateMenu(subMenus))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CLogin));
