var location = undefined;

export function visitas() {
    const api = process.env.REACT_APP_API_URL;
    const prodApi = process.env.REACT_APP_PROD_URL;

    if (api !== prodApi)
        return;

    if (location === window.location.href)
        return;

    location = window.location.href;

    window.dataLayer = window.dataLayer || [];
    function gtag(){
        window.dataLayer.push(arguments);
    }

    gtag('js', new Date());
    gtag('config', 'UA-200736836-4');
}