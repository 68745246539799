import React, {Component} from 'react';
import axios from 'axios';
import {connect} from 'react-redux';
import VConsultaSedes from '../../components/sedes/VConsultaSedes';
import {advertencia_edo_dto} from '../../constants/EtiquetasCasillas';
import CTemplate from '../../containers/homeInterno/CTemplate';
import AuthenticatedComponent from "../../components/comunes/AuthenticatedComponent";
import * as info from '../../constants/InfoSistema';
import * as etiquetas from '../../constants/EtiquetasGenerales';
import { createStructuredSelector } from 'reselect';
import { selectEstado, selectDistrito} from '../../redux/menu/menu-selectors';
import { selectCurrentUser, selectCurrentUserRol} from '../../redux/user/user-selectors';
import { obtenerEstatusModulo } from '../../redux/menu/menu-utils';



let msjError = "";
const jwt = localStorage.getItem('jwt-token');

const sedes = [
  {
    idSede: "P",
    nombreTipo: "Presencial"
  },
  {
    idSede: "V",
    nombreTipo: "Virtual"
  },
];

class CConsultaSedes extends Component{

  constructor(props){

    super(props);
      this.state = {
        sedes: [],
        total: 0,
        actual: 1,
        mostrar: false,
        sedeSeleccionadaModal:null,
        cargando:false,
        tipoAlerta : "warning",
        mensajeAlerta : "",
        mostrarAlerta : {display:'none'},
        searchIdSede: null,
        searchTipoSede: null,
        tipSede:null,
        infoSede: null,
        activeTab: "1",
        modalVoluntarios: false,
        estatus: false,
        modifica: false,
        idParticipacion:0,
        abrirModalExitoModifica: false,
        infoSede:sedes,
        usuario: null,
        fechaHora: null,
        ipUsuario: null,
        idSede: null,
        codigoPostal: null,
        colonia: null,
        domicilioSede:null,
        extension: null,
        idDistrito: null,
        idMunicipioDom: null,
        lugar: null,
        link: null,
        referencia: null,
        seccion: null,
        telefonoSede: null,
        tipoSede: null,
        observaciones: null,
        numExterior: null,
        numInterior: null,
        municipioDom: null,
        sedeTipo: null,
        usuarioCau: false,
        tiposSedes: null,
        nombreCapacitador: null,
        cargo_Capacitador: null
  };
}

componentDidMount(){

    //console.log('Entra en componentDidMount');
    //this.consultarSede();
    this.consultarEstatusModulo();
}

consultarEstatusModulo = async () => {

  var idDetalleProceso = this.props.user.idDetalle;
  var idProcesoElectoral = this.props.user.idProcesoElectoral;
  var idEstado = this.props.estado.idEstado;
  var idDistrito = this.props.distrito.idDistrito;
  var idSistema = info.ID_SISTEMA;
  var idModulo = this.props.location.state !== undefined ?
                 this.props.location.state.idModulo : 0;
  var grupoSistema = this.props.user.rolUsuario;


  var index = grupoSistema.search("CAU.OC");

  if(index >= 0){
    this.setState({
      usuarioCau:true
    }, ()=>console.log(true))
  }else{
    console.log("tiene otro tipo de rol")
  }

obtenerEstatusModulo(idProcesoElectoral, idDetalleProceso,idSistema,
  idEstado, idDistrito, idModulo, grupoSistema).then(respuesta =>{
  console.info("El estatus del módulo Consulta es: ",respuesta);
  if(respuesta === "A"){
    console.info("El Módulo Consulta está abierto ");
    this.consultarSede();
    this.setState({
      estatus:true
    }, ()=>console.log(true))
  }else{
    console.info("El Módulo Consulta está cerrado ");
  }
})


}

mostrarModal = (record) => {
  console.log(record,"record")
  this.setState({
    mostrar: true,
    sedeSeleccionadaModal: record
  })
};


ocultarModal = () => {
  this.setState({
    mostrar: false
  })
};


searchIdSede = (valor) => {
  if(valor != null){
    this.state.searchIdSede = valor.toUpperCase();
  }else{
    this.state.searchIdSede = null;
  }
  this.consultarSede();
}

searchTipoSede = (valor) => {
  if(valor != null){
    this.state.searchTipoSede = valor;
  }else{
    this.state.searchTipoSede = null;
  }
  this.consultarSede();
}

consultarSede = (inicio=1,fin=5) => {

  var dataJson = {
        idDetalleProceso:info.ID_DETALLE_PROCESO,
        idProcesoElectoral:info.ID_PROCESO_ELECTORAL,
        idEstado: this.props.estado.idEstado,
        idDistrito: this.props.distrito.idDistrito,
        inicio: inicio,
        fin: fin,
        tipoSede: this.state.searchTipoSede,
        idSede: this.state.searchIdSede
    }
  
    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + 'ws/consulta_sedes', dataJson,
    {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': this.props.user ? this.props.user.tknJWT : null
        }
    })
    .then(res => {
      const sede = res.data.consultaSedes;

      console.log(sede)
      this.setState({sedes: sede,
                    tipoSede : this.state.searchTipoSede,
                    idSede : this.state.searchIdSede,
                    total: res.data.totalSede,
                    mensajeAlerta : null,
                    cargando : false,
                    mostrarAlerta : {display: 'none'},
                  });

  }).catch(error => {
      console.error("Error al obtener la consulta sedes",error);
  });
}


modificaSedes = (datosSedes) => {
 
  if(datosSedes.tipoSede === 'PRESENCIAL'){
    this.state.sedeTipo = 'P';
  }else if(datosSedes.tipoSede === 'VIRTUAL'){
    this.state.sedeTipo = 'V';
  }
  const dataJson = {
    idDetalleProceso:info.ID_DETALLE_PROCESO,
    idProcesoElectoral:info.ID_PROCESO_ELECTORAL,
    idEstado: this.props.estado.idEstado,
    idDistrito: this.props.distrito.idDistrito,
    idSede: datosSedes.idSede,
    tipoSede : this.state.sedeTipo
  }

    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + 'ws/buscarSedesModificar', dataJson,
    {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': this.props.user ? this.props.user.tknJWT : null
        }
    })
    .then(res => {
        this.setState({
          sedeModificar: res.data,
          usuario: res.data.usuario,
          fechaHora: res.data.fechaHora,
          ipUsuario: res.data.ipUsuario,
          idSede: res.data.idSede,
          codigoPostal: res.data.codigoPostal,
          colonia: res.data.colonia,
          domicilioSede: res.data.domicilioSede,
          extension: res.data.extension,
          idDistrito: res.data.idDistrito,
          idMunicipioDom: res.data.idMunicipioDom,
          lugar: res.data.lugar,
          referencia: res.data.referencia,
          seccion: res.data.seccion,
          telefonoSede: res.data.telefonoSede,
          tipSede: res.data.tipoSede,
          observaciones: res.data.observaciones,
          numExterior: res.data.numExterior,
          numInterior: res.data.numInterior,
          municipioDom: res.data.municipioDom,
          nombreCapacitador: res.data.nombreCapacitador,
          cargo_Capacitador: res.data.cargo_Capacitador,
          modifica: true,
          mensajeAlerta : null,
          cargando : false,
          mostrarAlerta : {display: 'none'},
        })
  }).catch(error => {
      console.error("Error al obtener las sedes a modificar",error);
  });
}

enviarModificacion = () => {
  
  
  let sede = {
    usuario: this.state.usuario,
    fechaHora: this.state.fechaHora,
    ipUsuario: this.state.ipUsuario,
    idSede: this.state.idSede,
    codigoPostal: this.state.codigoPostal,
    colonia: this.state.colonia,
    domicilioSede: this.state.domicilioSede,
    extension: this.state.extension,
    idDistrito: this.state.idDistrito,
    idMunicipioDom: this.state.idMunicipioDom,
    lugar: this.state.lugar,
    referencia: this.state.referencia,
    seccion: this.state.seccion,
    telefonoSede: this.state.telefonoSede,
    tipoSede: this.state.tipSede,
    observaciones: this.state.observaciones,
    numExterior: this.state.numExterior,
    numInterior: this.state.numInterior,
    municipioDom: this.state.municipioDom,
    nombreCapacitador: this.state.nombreCapacitador,
    cargo_Capacitador: this.state.cargo_Capacitador
  }

  axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + 'ws/modificaSede', sede,
    {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': this.props.user ? this.props.user.tknJWT : null
        }
    })

  .then(_response => {
      this.setState({abrirModalExitoModifica: true})
  }).catch(error => {
      this.setState({cargando: false});
      console.error("Error al guardar la casilla "+
          "enviarServidor()",error);
      if (error.response){
          if (error.response.status === 409){
              msjError = error.response.data.message;
          } else if (error.response.data && error.response.data.causa
              && error.response.data.causa.includes("Error de validación")){
              msjError = error.response.data.message;
          } else {
              msjError = etiquetas.gral_error_cau;
          }
      } else {
          msjError = etiquetas.gral_error_cau;
      }
      this.setState({
        msjError : msjError,
        abrirModalError : true
      });
  });
  }

paginado = pagina =>{
  console.log("la pagina es", pagina);
  var paginaAnterior = parseInt(pagina,10) - 1 === 0 ? 1 :
                                                      parseInt(pagina,10) - 1;
  this.setState({
    actual : pagina,
    cargando: true,
    sedes: []
  });

  var inicio = parseInt(pagina,10) === 1 ? 1 : (paginaAnterior * 5)+1;
  var fin = parseInt(pagina,10) * 5;

  this.consultarSede(inicio,fin);
}

changeTab = activeKey => {
  console.log(activeKey);
  this.setState({
    activeTab: activeKey
  });
};

changeTabModifica = activeKey => {
  console.log(activeKey);
  this.setState({
    activeTabModifica: activeKey
  });
};

regresarPrimeraPagina = () =>{
  this.setState({
    paginaActual : 1,
    actual: 1
  });
  window.location.reload();
  this.consultarSede();
}

cambioText = (e) =>{
  this.setState({
      [e.target.name]: e.target.value
  })
}


onChangeRoute =()=>{
  console.log("Entre a cambio de ruta")
  this.setState({abrirModalExitoModifica: false});
  window.location.reload();
}

onChangeRouteModulo =()=>{
  console.log("Entre a cambio de ruta")
  this.setState({abrirModalExitoRegistro: false});
  this.props.history.push('/modulo/3');
}

render(){
  return(

    <AuthenticatedComponent>
      <CTemplate contenido = {
        
        <VConsultaSedes 
          consultarSede={this.consultarSede}
          mostrar={this.state.mostrar}
          data = {this.state.sedes}
          total = {this.state.total}
          actual = {this.state.actual}
          paginado = {this.paginado}
          cargando = {this.state.cargando}
          mostrarModal = {this.mostrarModal}
          ocultarModal = {this.ocultarModal}
          sedeSeleccionadaModal = {this.state.sedeSeleccionadaModal}          
          tipoAlerta = {this.state.tipoAlerta}
          mensajeAlerta = {this.state.mensajeAlerta}
          mostrarAlerta = {this.state.mostrarAlerta}
          searchIdSede = {this.searchIdSede}
          searchTipoSede = {this.searchTipoSede}
          tipSede={this.state.tipSede}
          changeTab={this.changeTab}
          activeTab = {this.state.activeTab}
          regresarPrimeraPagina={this.regresarPrimeraPagina}
          estatusModulo = {this.state.estatus}
          modificaSedes = {this.modificaSedes}
          modifica = {this.state.modifica}
          casillaModificar = {this.state.casillaModificar}
          cambioText ={this.cambioText}
          changeTabModifica = {this.changeTabModifica}
          activeTabModifica = {this.state.activeTabModifica}
          handleDelete = {this.handleDelete}
          fila = {this.state.key}
          enviarModificacion={this.enviarModificacion}
          abrirModalExitoModifica = {this.state.abrirModalExitoModifica}
          onChangeRouteModifica = {this.onChangeRoute}
          infoSede={this.state.infoSede}
          sedeModificar={this.state.sedeModificar}
          usuario={this.state.usuario}
          fechaHora= {this.state.fechaHora}
          ipUsuario={this.state.ipUsuario}
          idSede= {this.state.idSede}
          codigoPostal={this.state.codigoPostal}
          colonia={this.state.colonia}
          domicilioSede={this.state.domicilioSede}
          extension={this.state.extension}
          idDistrito={this.state.idDistrito}
          idMunicipioDom={this.state.idMunicipioDom}
          lugar={this.state.lugar}
          referencia={this.state.referencia}
          seccion={this.state.seccion}
          telefonoSede={this.state.telefonoSede}
          tipSede={this.state.tipSede}
          observaciones={this.state.observaciones}
          numExterior={this.state.numExterior}
          numInterior={this.state.numInterior}
          municipioDom={this.state.municipioDom}
          nombreCapacitador={this.state.nombreCapacitador}
          cargo_Capacitador={this.state.cargo_Capacitador}
          rolCau = {this.state.usuarioCau}
          onChangeRouteModulo={this.onChangeRouteModulo}
        /> 
      }
      guia = {false}/>
    </AuthenticatedComponent>
  )
}


}

const mapStateToProps = createStructuredSelector({
  estado: selectEstado,
  distrito: selectDistrito,
  user: selectCurrentUser
});

export default connect(mapStateToProps)(CConsultaSedes);