import VoluntarioActionTypes from './voluntario-types';

export const setCurrentVoluntario = (voluntario) => ({
  type: VoluntarioActionTypes.SET_CURRENT_VOLUNTARIO,
  payload: voluntario,
});

export const setCurrentVoluntarioId = (voluntarioId) => ({
  type: VoluntarioActionTypes.SET_CURRENT_VOLUNTARIO_ID,
  payload: voluntarioId,
});

export const voluntarioLogout = () => ({
  type: VoluntarioActionTypes.VOLUNTARIO_LOGOUT
});
