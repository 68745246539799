
export const id_tipo_documento = 4;

export const titulo_componente = "Gafetes";
export const etiqueta_campo_busqueda = "Ingresa nombre o folio de la persona voluntaria";
export const titulo_tabla_seleccionados = "Personas voluntarias seleccionadas";
export const titulo_tabla_voluntarios = "Resultado de personas voluntarias";
export const btn_imprimir = "Imprmir";
export const btn_descargar = "Descargar";
export const advertencia_edo_dto = "Selecciona estado y distrito para continuar.";
export const advertencia_voluntario = "Debe elegir un voluntario para continuar";
export const etiqueta_nombre = "Nombre del voluntario:";
export const etiqueta_folio = "Folio:";
export const etiqueta_vigencia = "Vigencia";
export const etiqueta_fecha_fin = "Fecha de inicio:";
export const etiqueta_fecha_inicio = "Fecha de fin:";
export const etiqueta_tooltip_resultado = "Selecciona los folios o nombres de los gafetes que se van a generar.";
export const etiqueta_tooltip_consultar = "Consultar";
export const etiqueta_tooltip_quitar = "Quitar";
export const etiqueta_gafetes_descargados = "Los gafetes se descargaron con éxito";


export const PATH_VOLUNTARIO = "/voluntarioCertificado";

//roles que solo pueden generar el gafete
export const roles_captura = [
  "CIJ.CAPTURA_VCEYEC.JD",
  "CIJ.CAPTURA_VCEYEC.JL",
  "CIJ.CAU.OC",
  "CIJ.ADMIN.OC",
  "CIJ.CAPTURA.OC",
  "CIJ.CAPTURA.JL",
  "CIJ.CAPTURA.JD",
  "CIJ.CONSULTA_JOSA.JD"
];
//roles que pueden ingresar al módulo pero no generar los gafetes
export const roles_permitidos = [
  "CIJ.ADMIN.OC",
  "CIJ.CAPTURA.OC",
  "CIJ.CONSULTA.OC",
  "CIJ.CAU.OC",
  "CIJ.CAPTURA.JL",
  "CIJ.CONSULTA.JL",
  "CIJ.CAPTURA.JD",
  "CIJ.CONSULTA.JD",
  "CIJ.CAPTURA_VE.JL",
  "CIJ.CAPTURA_VE.JD",
  "CIJ.CAPTURA_VERFE.JL",
  "CIJ.CAPTURA_VERFE.JD",
  "CIJ.CAPTURA_VOE.JL",
  "CIJ.CAPTURA_VOE.JD",
  "CIJ.CAPTURA_VS.JL",
  "CIJ.CAPTURA_VS.JD",
  "CIJ.CAPTURA_VCEYEC.JL",
  "CIJ.CAPTURA_VCEYEC.JD",
  "CIJ.CAPTURA_EXT_OPL.JL",
  "CIJ.CONSULTA_EXT_OPL.JL",
  "CIJ.CAPTURA_EXT_OPL.JD",
  "CIJ.CONSULTA_EXT_OPL.JD",
  "CIJ.CONSULTA_JOSA.JD"
];
