import React from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import CTemplate from '../homeInterno/CTemplate';
import AuthenticatedComponent from "../../components/comunes/AuthenticatedComponent";
import VVoluntario from "../../components/voluntarios/VVoluntario";
import { createStructuredSelector } from 'reselect';
import { selectEstado, selectDistrito} from '../../redux/menu/menu-selectors';
import { selectCurrentUser} from '../../redux/user/user-selectors';
import { selectCurrentVoluntario} from '../../redux/voluntario/voluntario-selectors';
import { setCurrentVoluntario} from '../../redux/voluntario/voluntario-actions';
import * as formatos from '../../constants/Formatos';
import * as etiquetas from '../../constants/EtiquetasGenerales';
import * as etiquetasVoluntarios from '../../constants/EtiquetasVoluntarios';
import * as info from '../../constants/InfoSistema';
import {notification,Row,Col} from 'antd';
import {AlertOutlined} from '@ant-design/icons';
import {obtenerEstatusModulo} from '../../redux/menu/menu-utils';
import { PATH_HOME_INT} from '../../constants/routers/paths';


class CVoluntarios extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      formularioPersonal : new FormData(),
      lenguaIndigena : true,
      telefonoObli : true,
      celularObli : true,
      apellidoUnoObli : true,
      apellidoDosObli : true,
      listaEstados:[],
      listaMunicipios:[],
      tipoVoluntario: "", claveElector: null,
      nombre: null,         apellidoP : null,
      apellidoM : null,     fechaNacimiento: null,
      edad: null,           genero: "",
      correo: null,         telefono: null,
      celular: null,        hablaIndigena: null,
      queLengua: null,      avisoPrivacidad: null,
      calle: null,          numeroInt: null,
      numeroExt: null,      colonia: null,
      estado: null,         municipio: null,
      cp: null, pestanaActiva: "1",
      idVoluntario : null, claveEncontrada: "",
      idParticipacion : null,
      seccion : null,       idConvocatoria : null,
      folio : null,         estatusRegistro : null,
      folioAcceso : null,   idSede : null,
      observaciones : null, fechaHoraCapacitacion : null,
      editar: false, fechaRegistro : null,
      tienePermisos : false,
      mostrarAdvertencia:false, experiencia:"",curp:"",
      msjAlerta : "", claveElectorBuscada:"", mostrarAvisoModal:false
    }

  }

  componentDidMount(){
    this.consultarEstatusModulo();
    this.consultarPermisoUsuario();
    this.comprobarConvocatoria();

    console.info("idVoluntario",this.props.voluntario);
    if(this.props.voluntario !== null){
      console.log("voya buscar los voluntarios",this.props.voluntario);
      this.buscarVoluntarioAeditar();
    }
    this.obtenerMunicipio();
  }

  cerrarAviso = () =>{
    this.setState({
      mostrarAvisoModal :false
    })
  }

  abrirAvisoPrivacidad = () =>{
    this.setState({
      mostrarAvisoModal:true
    })
  }

  consultarEstatusModulo(){
    var idProcesoElectoral = this.props.user.idProcesoElectoral;
    var idDetalleProceso = this.props.user.idDetalle;
    var idEstado = this.props.estado.idEstado;
    var idDistrito = this.props.distrito.idDistrito;
    var idSistema = info.ID_SISTEMA;
    var idModulo = this.props.location.state !== undefined ?
                    this.props.location.state.idModulo : 0;
    var grupoSistema = this.props.user.rolUsuario;

    obtenerEstatusModulo(idProcesoElectoral, idDetalleProceso,idSistema,
      idEstado, idDistrito, idModulo, grupoSistema).then(respuesta =>{
      console.info("El estatus del módulo registro voluntarios es: ",respuesta);
      if(respuesta === "A"){
        console.info("El Módulo registro voluntarios está abierto ");
      }else{
        console.info("El Módulo registro voluntarios está cerrado ");
        this.props.history.push(PATH_HOME_INT);
      }
    })
  }

  consultarPermisoUsuario = () =>{
    this.setState({
      tienePermisos :
      etiquetasVoluntarios.roles_captura_modifica.includes(this.props.user.rolUsuario)
    })
  }

  async componentDidUpdate(prevProps) {
    if(this.props.voluntario === null){
      if (this.props.estado !== prevProps.estado
        || this.props.distrito !== prevProps.distrito) {
        this.obtenerMunicipio();
        this.comprobarConvocatoria();
      }
    }
  }

  componentWillUnmount(){
    this.props.setCurrentVoluntario(null);
  }

  buscarVoluntarioAeditar = () =>{
    var infoPost = {
      idProcesoElectoral :this.props.user.idProcesoElectoral ,
      idDetalleProceso :this.props.user.idDetalle,
      idEstado : this.props.estado.idEstado,
      idDistrito : this.props.distrito.idDistrito,
      idVoluntario : this.props.voluntario !== null  ?
                                      this.props.voluntario.idVoluntario : null
    }
    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL+
      "ws/buscarVoluntario", infoPost,
          {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.user ? this.props.user.tknJWT : null
              }
          }
       ).then(response => {
         console.info("voluntario obtenido",response);
         this.acomodarInfoVoluntario(response.data.consultaVoluntario);

    }).catch(error => {
        console.error("Error al obtener el voluntario a editar",error);
    });
  }

  acomodarInfoVoluntario = (infoVoluntario) =>{
    console.log("acomodando los voluntarios");
    if(infoVoluntario !== null){
      this.setState({
        tipoVoluntario: infoVoluntario.tipoVoluntario,
        claveElector: infoVoluntario.claveElector,
        nombre: infoVoluntario.nombre,
        apellidoP : infoVoluntario.apellidoPaterno,
        apellidoM : infoVoluntario.apellidoMaterno,
        curp : infoVoluntario.curp,
        experiencia : infoVoluntario.experiencia,
        fechaNacimiento: this.obtenerFechaFormateada(infoVoluntario.fechaNacimiento),
        edad: infoVoluntario.edad,       genero: infoVoluntario.genero,
        correo: infoVoluntario.correoElectronico,
        telefono: infoVoluntario.telefonoCasa,
        celular: infoVoluntario.telefonoMovil,
        hablaIndigena: infoVoluntario.hablaLenguaIndigena,
        queLengua: infoVoluntario.cualLenguaIndigena,
        calle: infoVoluntario.calle,          numeroInt: infoVoluntario.numeroInt,
        numeroExt: infoVoluntario.numeroExt,  colonia: infoVoluntario.colonial,
        municipio: infoVoluntario.idMunicipio, estado:infoVoluntario.idEstado,
        cp: this.zfill(infoVoluntario.codigoPostal,5),
        idVoluntario : infoVoluntario.idVoluntario,
        idParticipacion : infoVoluntario.idParticipacion,
        seccion : infoVoluntario.seccion,
        idConvocatoria : infoVoluntario.idConvocatoria,
        folio : infoVoluntario.folio,
        estatusRegistro : infoVoluntario.estatusRegistro,
        folioAcceso : infoVoluntario.folioAcceso,
        idSede : infoVoluntario.idSede,
        observaciones : infoVoluntario.observaciones,
        fechaHoraCapacitacion :this.obtenerFechaFormateada(
                                          infoVoluntario.fechaHoraCapacitacion),
        editar : true,
        fechaRegistro :this.obtenerFechaFormateada(
                                          infoVoluntario.fechaRegistro),
      });
    }

  }

  zfill = (number, width) =>{
      var numberOutput = Math.abs(number); /* Valor absoluto del número */
      var length = number.toString().length; /* Largo del número */
      var zero = "0"; /* String de cero */

      if (width <= length) {
          if (number < 0) {
               return ("-" + numberOutput.toString());
          } else {
               return numberOutput.toString();
          }
      } else {
          if (number < 0) {
              return ("-" + (zero.repeat(width - length)) + numberOutput.toString());
          } else {
              return ((zero.repeat(width - length)) + numberOutput.toString());
          }
      }
  }

  obtenerFechaFormateada = (fechaRegistrada) =>{
    if(fechaRegistrada !== null){
      var fecha = fechaRegistrada.split("T");
      var diaMesAno = fecha[0].split("-");
      var fechaFormada = diaMesAno[2]+"/"+diaMesAno[1]+"/"+diaMesAno[0];
      return fechaFormada;
    }
  }

  buscarClaveElector = (claveElectorSiap) => {
    console.info("empezando a buscar clave",claveElectorSiap);
    var infoPost = {
      idProcesoElectoral :this.props.user.idProcesoElectoral ,
      idDetalleProceso :this.props.user.idDetalle,
      idEstado : this.props.estado.idEstado,
      idDistrito : this.props.distrito.idDistrito,
      claveElector : claveElectorSiap
    }
    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL+
      "ws/buscarClaveElector", infoPost,
          {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.user ? this.props.user.tknJWT : null
              }
          }
       ).then(response => {
         console.info("se encontró clave de elector",response);
         this.setState({
            claveEncontrada :parseInt(response.data.claveElector,10),
            mostrarAdvertencia : parseInt(response.data.claveElector,10) === 1 ?
            false : true,
            msjAlerta :etiquetasVoluntarios.error_clave_repetida,
            claveElectorBuscada :claveElectorSiap
          });
         return parseInt(response.data.claveElector,10);
    }).catch(error => {
        console.error("Error al buscar la clave de elector",error);
    });
  }

  comprobarConvocatoria = () => {
    var infoPost = {
      idProcesoElectoral :this.props.user.idProcesoElectoral ,
      idDetalleProceso :this.props.user.idDetalle,
      idEstado : this.props.estado.idEstado,
      idDistrito : this.props.distrito.idDistrito
    }
    console.info("info para bsucar convocatoria: ", infoPost);
    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL+
      "ws/buscarConvocatoria", infoPost,
          {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.user ? this.props.user.tknJWT : null,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST,OPTIONS",
                "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
              }
          }
       ).then(response => {
         console.info("la convocatoria es...",response);
         console.info("la convocatoria es...",response.data.idConvocatoria !== null ||
           response.data.idConvocatoria > 0 ? true :false);
         this.setState({
            mostrarAdvertencia : response.data.idConvocatoria !== null ||
              response.data.idConvocatoria > 0 ? true :false,
            msjAlerta : etiquetasVoluntarios.error_no_convocatoria
          });
    }).catch(error => {
      this.setState({
         mostrarAdvertencia : true ,
         msjAlerta : etiquetasVoluntarios.error_no_convocatoria
       });
        console.error("Error al buscar una convocatoria",error);
    });
  }

  obtenerMunicipio = () => {
    var infoPost = {
      idEstado: this.props.estado.idEstado
    }
    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL+
      "ws/obtMunicipios", infoPost,
          {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.user ? this.props.user.tknJWT : null
              }
          }
       ).then(response => {
         console.info("obteniendo municipios",response);
         this.setState({
           listaMunicipios:response.data.infoMunicipios
         });
    }).catch(error => {
        console.error("Error al obtener la lista de estados",error);
    });
  }
  refrescarAdvertencia = () =>{
    this.setState({
      mostrarAdvertencia:true
    })
  }

  cabioDePestana = (pestana) => {}

  revisaCampoPersonales = (form) =>{
    console.log("DatosPersonles",form);
    this.setState({
      pestanaActiva : "2",
      tipoVoluntario : form["tipoVoluntario"],
      claveElector : form["claveElector"],
      nombre : form["nombre"],
      apellidoP : form["apellidoP"],
      apellidoM : form["apellidoM"],
      fechaNacimiento : form["fechaNacimiento"],
      edad : form["edad"],
      genero : form["genero"],
      correo : form["correo"],
      telefono : form["telefono"],
      celular : form["celular"],
      hablaIndigena : form["hablaIndigena"],
      queLengua : form["queLengua"],
      experiencia : form["experiencia"],
      curp : form["curp"]
    });
  }

  revisaCampoDomicilio = (form) =>{
    console.log("datosDomicilio",form);
    this.setState({
      pestanaActiva : "3",
      calle : form["calle"],
      numeroInt : form["numeroInt"],
      numeroExt : form["numeroExt"],
      colonia : form["colonia"],
      estado : this.props.estado.idEstado,
      municipio : form["municipio"],
      cp : form["cp"],
    });
  }

  hablaLenguaIndigena = (valor) => {
    this.setState({
      lenguaIndigena: valor === "S" ? true : false
    })
  }

  telefenoEsObligatorio = (valor) => {
    this.setState({
      telefonoObli: valor.target.value !== "" ? false : true
    })
  }

  celularEsObligatorio = (valor) => {
    this.setState({
      celularObli: valor.target.value !== "" ? false : true
    })
  }

  apellidoUnoEsObligatorio = (valor) => {
    this.setState({
      apellidoUnoObli: valor.target.value !== "" ? false : true
    })
  }

  apellidoDosEsObligatorio = (valor) => {
    this.setState({
      apellidoDosObli: valor.target.value !== "" ? false : true
    })
  }

  regresar = (pestana) =>{
    this.setState({
      pestanaActiva : pestana
    })
  }

  render(){
    return(
      <>
        <AuthenticatedComponent  >
          <CTemplate
            bloqueado = {!etiquetasVoluntarios.roles_captura_modifica.includes(
                                                    this.props.user.rolUsuario)}
            contenido = {
              <VVoluntario
                revisaCampoDomicilio = {this.revisaCampoDomicilio}
                lenguaIndigena ={this.state.lenguaIndigena}
                hablaLenguaIndigena = {this.hablaLenguaIndigena}
                revisaCampoPersonales = {this.revisaCampoPersonales}
                listaEstados = {this.state.listaEstados}
                listaMunicipios = {this.state.listaMunicipios}
                telefonoObli = {this.state.telefonoObli}
                celularObli = {this.state.celularObli}
                apellidoUnoObli = {this.state.apellidoUnoObli}
                apellidoDosObli = {this.state.apellidoDosObli}
                telefenoEsObligatorio = {this.telefenoEsObligatorio}
                celularEsObligatorio = {this.celularEsObligatorio}
                apellidoUnoEsObligatorio = {this.apellidoUnoEsObligatorio}
                apellidoDosEsObligatorio = {this.apellidoDosEsObligatorio}
                datosPersonales = {this.state.datosPersonales}
                datosDomicilio = {this.state.datosDomicilio}
                fechaNacimiento = {this.state.fechaNacimiento}
                edad = {this.state.edad}
                tipoVoluntario = {this.state.tipoVoluntario}
                claveElector = {this.state.claveElector}
                curp = {this.state.curp}
                experiencia = {this.state.experiencia}
                nombre = {this.state.nombre}
                apellidoP  = {this.state.apellidoP}
                apellidoM  = {this.state.apellidoM}
                genero = {this.state.genero}
                correo = {this.state.correo}
                telefono = {this.state.telefono}
                celular = {this.state.celular}
                hablaIndigena = {this.state.hablaIndigena}
                queLengua = {this.state.queLengua}
                avisoPrivacidad = {this.state.avisoPrivacidad}
                calle = {this.state.calle}
                numeroInt = {this.state.numeroInt}
                numeroExt = {this.state.numeroExt}
                colonia = {this.state.colonia}
                estado = {this.state.estado}
                municipio = {this.state.municipio}
                cp = {this.state.cp}
                pestanaActiva = {this.state.pestanaActiva}
                cambioPestana = {this.cambioPestana}
                idVoluntario = {this.state.idVoluntario}
                pestanaActiva = {this.state.pestanaActiva}
                regresar = {this.regresar}
                buscarClaveElector = {this.buscarClaveElector}
                claveEncontrada = {this.state.claveEncontrada}
                editar = {this.state.editar}
                idParticipacion = {this.state.idParticipacion}
                seccion = {this.state.seccion}
                idConvocatoria = {this.state.idConvocatoria}
                folio = {this.state.folio}
                estatusRegistro = {this.state.estatusRegistro}
                folioAcceso = {this.state.folioAcceso}
                idSede = {this.state.idSede}
                observaciones = {this.state.observaciones}
                fechaHoraCapacitacion = {this.state.fechaHoraCapacitacion}
                fechaRegistro ={this.state.fechaRegistro}
                mostrarAdvertencia = {this.state.mostrarAdvertencia}
                msjAlerta={this.state.msjAlerta}
                claveElectorBuscada = {this.state.claveElectorBuscada}
                cerrarAviso = {this.cerrarAviso}
                abrirAvisoPrivacidad = {this.abrirAvisoPrivacidad}
                mostrarAvisoModal = {this.state.mostrarAvisoModal}
                refrescarAdvertencia = {this.refrescarAdvertencia}
              />
            }
              guia={false}/>

            </AuthenticatedComponent>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
    estado: selectEstado,
    distrito: selectDistrito,
    user: selectCurrentUser,
    voluntario : selectCurrentVoluntario
});

const mapDispatchToProps = dispatch => ({
  setCurrentVoluntario: voluntario => dispatch(setCurrentVoluntario(voluntario)),
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(CVoluntarios));
