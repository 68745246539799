import React, {useState, useEffect} from 'react';
import { Button, Table, Pagination, Modal, Row, Col, Layout, Alert, Input, Card, Select, Tabs, Tooltip, Form, Divider} from 'antd';
import { ArrowRightOutlined, ArrowLeftOutlined} from '@ant-design/icons';
import { FrownOutlined, HomeFilled} from '@ant-design/icons';
import * as etiquetasCasillas from '../../constants/EtiquetasCasillas';
import iconoMostrar from '../../assets/img/icono_mostrar_gafete.png';
import iconoEditar from '../../assets/img/icono-editar.png';
import iconoQuestion from '../../assets/img/icono_question.png';
import iconoFiltro from '../../assets/img/icono_filter.png';
import sedesModificaImg from '../../assets/img/sedesModificar.png'
import '../../css/consultaCasillas/consultaCasillas.css';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';
import 'moment/locale/es';
import * as etiquetaSede from '../../constants/etiquetasSedes';

const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;

const icon = <FrownOutlined />;

const VCapturaSedes = (props) =>{

  const [searchSede, setSearchSede] = useState("");
  const [form] = Form.useForm();

  
  const [tipoSede, setTipSede] = useState();
  const [lugar, setLugarSede] = useState();
  const [nombreCapacitador, setNombre] = useState();
  const [cargo_Capacitador, setCargo] = useState();
  const [domicilioSede, setCalle] = useState();
  const [numExterior, setnumeroE] = useState();
  const [numInterior, setnumeroI] = useState();
  const [colonia, setColonia] = useState();
  const [seccion, setSeccion] = useState();
  const [municipioDom, setmunicipio] = useState();
  const [codigoPostal, setCodigoPo] = useState();
  const [extension, setExt] = useState();
  const [telefonoSede, settelefono] = useState();
  const [referencia, setReferencia] = useState();
  const [observaciones, setObser] = useState();


  const [fvalLugar, setValLugar] = useState(false);
  const [fvalNombre, setValNombre] = useState(false);
  const [fvalCargo, setValCargo] = useState(false);
  const [fvalCalle, setValCalle] = useState(false);
  const [fvalNum, setValNumExt] = useState(false);
  const [fvalNumInt, setValNumInt] = useState(false);
  const [fvalColonia, setValColonia] = useState(false);
  const [fvalCp, setValCodigoP] = useState(false);
  const [fvalTel, setValTelefono] = useState(false);
  const [fvalExt, setValExtension] = useState(false);
  const [fvalReferencia, setValReferencia] = useState(false);
  const [fvalObserv, setValObserv] = useState(false);
  const [valres, setValres] = useState(500);
  const [valresReferencia, setValresReferencia] = useState(500);

  const loadProfile = () =>{

    //setInfo(props.sedeModificar);
    var idSede = props.idSede;
    var codigoPostal = props.codigoPostal;
    var colonia = props.colonia;
    var idDistrito = props.idDistrito;
    var idMunicipioDom = props.idMunicipioDom;
    var domicilioSede = props.domicilioSede;
    var lugar = props.lugar;
    var nombreCapacitador = props.nombreCapacitador;
    var cargo_Capacitador = props.cargo_Capacitador;
    var referencia = props.referencia;
    var seccion = props.seccion;
    var telefonoSede = props.telefonoSede;
    var tipSede = props.tipSede;
    var observaciones = props.observaciones;
    var municipioDom = props.municipioDom;
    var extension = props.extension;
    var numExterior = props.numExterior;
    var numInterior = props.numInterior;

    //setSearchIdSede(idSede);
    setTipSede(tipSede);
    setLugarSede(lugar);
    setNombre(nombreCapacitador);
    setCargo(cargo_Capacitador);
    setnumeroE(numExterior);
    setnumeroI(numInterior);
    setCalle(domicilioSede);
    setColonia(colonia);
    setSeccion(seccion);
    setmunicipio(municipioDom);
    setCodigoPo(codigoPostal);
    setExt(extension);
    settelefono(telefonoSede);
    setReferencia(referencia);
    setObser(observaciones);

    form.setFieldsValue({"idSede" : idSede});
    form.setFieldsValue({"tipoSede" : tipSede}); 
    form.setFieldsValue({"lugar" : lugar});  
    form.setFieldsValue({"nombreCapacitador" : nombreCapacitador});   
    form.setFieldsValue({"cargo_Capacitador" : cargo_Capacitador});
    form.setFieldsValue({"domicilioSede" : domicilioSede});
    form.setFieldsValue({"colonia" : colonia});
    form.setFieldsValue({"numExterior" : numExterior});
    form.setFieldsValue({"numInterior" : numInterior});
    form.setFieldsValue({"municipio" : municipioDom});
    form.setFieldsValue({"seccion" : seccion});
    form.setFieldsValue({"codigoPostal" : codigoPostal});
    form.setFieldsValue({"extension" : extension});
    form.setFieldsValue({"telefonoSede" : telefonoSede});
    form.setFieldsValue({"referencia" : referencia});
    form.setFieldsValue({"observaciones" : observaciones});
  }

  useEffect(() => {
    loadProfile();
    nomCapacitador();
    cargoCapacitador();
    Lugar();
    Calle();
    numeroExterior();
    numeroInterior();
    Colonia();
    codigoPosta();  
    telefon();
    exten();
    Referencia();
    Observ();
    
}, [props.sedeModificar, nombreCapacitador, cargo_Capacitador, lugar, domicilioSede, numExterior, numInterior, codigoPostal, colonia, referencia, telefonoSede, extension, observaciones, fvalNombre, fvalColonia, fvalReferencia, fvalLugar,
    fvalCalle, fvalNum, fvalCargo, fvalNumInt, fvalCp, fvalTel, fvalExt, fvalObserv
]);

const nomCapacitador = (e) => {

    const valor = nombreCapacitador;
    const reg = /[!¡¿°´@#$%^&*()_+\-=\[\]{}"\\|<>\/?]+/;
    
    if(valor !== null){
        if(reg.test(valor)){
            setValNombre(true);
        }else if(!isNaN(valor) && valor.length >=250){
            setValNombre(true);
        }else{
            setValNombre(false);
        }
    }else{
        if(reg.test(valor)){
            setValNombre(true);
        }else if(!isNaN(valor) ){
            setValNombre(true);
        }else{
            setValNombre(false);
        }
    }
    

}

const cargoCapacitador = (e) => {

    const valor = cargo_Capacitador;
    const reg = /[!¡¿°´@#$%^&*()_+\-=\[\]{}"\\|<>\/?]+/;
          
    if(valor  !== null ){
        if(reg.test(valor)){
            setValCargo(true);
        }else if(!isNaN(valor) && valor.length >=250){
            setValCargo(true);
        }else{
            setValCargo(false);
        }
    }else{
        if(reg.test(valor)){
            setValCargo(true);
        }else if(!isNaN(valor)){
            setValCargo(true);
        }else{
            setValCargo(false);
        }
    }

}
const Lugar = (e) => {

    const valor = lugar;
    const reg = /[!¡¿´@#$%^&*_+=\\[\]{};"\\|<>\/?]+/;
    

    if(valor !== null){
        if(valor!== undefined){

            setValres(500 - valor.length)
        }
    
        if(reg.test(valor)){
            setValLugar(true);
        }else if(!isNaN(valor) && valor.length >=500){
            setValLugar(true);
        }else{
            setValLugar(false); 
        }
    }else{
    
        if(reg.test(valor)){
            setValLugar(true);
        }else if(!isNaN(valor)){
            setValLugar(true);
        }else{
            setValLugar(false); 
        }
    }
    
}

const Calle = (e) => {

    const valor = domicilioSede;
    const reg = /[!¡¿´@#$%^&*_+=\\[\]{};"\\|<>\/?]+/;

    if(valor !== null){
        if(reg.test(valor)){
            setValCalle(true);
        }else if(!isNaN(valor) && valor.length >=50){
            setValCalle(true);
        }else{
            setValCalle(false);
        }
    }else{
        if(reg.test(valor)){
            setValCalle(true);
        }else if(!isNaN(valor)){
            setValCalle(true);
        }else{
            setValCalle(false);
        }
    }
    

}
const numeroExterior = (e) => {

    const valor = numExterior;
    const reg = /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i;

    if(valor !== null){
        if(reg.test(valor)){
            setValNumExt(true);
        }else if(!isNaN(valor) && valor.length <=5){
            setValNumExt(true);
        }else{
            setValNumExt(false);
        }
    }else{
        if(reg.test(valor)){
            setValNumExt(true);
        }else if(!isNaN(valor)){
            setValNumExt(true);
        }else{
            setValNumExt(false);
        }
    }
    
}

const numeroInterior = (e) => {


    const valor = numInterior;
    const reg = /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i;

    if(valor !== null){
        if(reg.test(valor)){
            setValNumInt(true);
        }else if(!isNaN(valor) && (valor.length >=1 || valor.length <=5)){
            setValNumInt(true);
        }else{
            setValNumInt(false);
        }
    }else{
        if(reg.test(valor)){
            setValNumInt(true);
        }else if(!isNaN(valor)){
            setValNumInt(true);
        }else{
            setValNumInt(false);
        }
    }

    

}

const Colonia = (e) => {

    const valor = colonia;
    const reg = /[!¡¿°´@#$%^&*()_+\-=\[\]{};"\\|<>\/?]+/;

    if(valor !== null){
        if(reg.test(valor)){
            setValColonia(true);
        }else if(!isNaN(valor) && valor.length >=50){
            setValColonia(true);
        }else{
            setValColonia(false);
        }
    }else{
        if(reg.test(valor)){
            setValColonia(true);
        }else if(!isNaN(valor)){
            setValColonia(true);
        }else{
            setValColonia(false);
        }
    }

}

const codigoPosta = (e) => {


    const valor = codigoPostal;
    const reg = /^\d*$/i;
    const reg2 = /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i;

    if(valor !== null){
        if(!isNaN(valor) && reg.test(valor) && valor.length === 5 && reg2.test(valor)){
            setValCodigoP(true);
        }else if(!isNaN(valor) && (valor.length >= 1 || valor.length < 5)){
            setValCodigoP(false);
        }else{
            setValCodigoP(false);
        }
    }else{  
        if(!isNaN(valor) && reg.test(valor) && reg2.test(valor)){
            setValCodigoP(true);
        }else if(!isNaN(valor)){
            setValCodigoP(false);
        }else{
            setValCodigoP(false);
        }
    }
}

const telefon = (e) => {

    const valor = telefonoSede;
    const reg = /^\d*$/i;
    const reg2 = /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i;

    if(valor !== null){
            
        if(!isNaN(valor) && reg.test(valor) && valor.length === 10 && reg2.test(valor)){
            setValTelefono(true);
        }else if (!isNaN(valor && (valor.length >= 1 || valor.length < 8))){
            setValTelefono(false);
        }
    }else{
        if(!isNaN(valor) && reg.test(valor) && reg2.test(valor)){
            setValTelefono(true);
        }else if (!isNaN(valor && (valor.length >= 1 || valor.length < 8))){
            setValTelefono(false);
        }
    } 
 
}

const exten = (e) => {

    const valor = extension;
    const reg = /^\d*$/i;
    const reg2 = /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i;

    if(valor !== null){
        if(valor === undefined){
            setValExtension(true);
        }
        
        else if(reg.test(valor) && (valor.length <= 0 || valor.length >= 4) && reg2.test(valor)){
            setValExtension(true);
        }else{
            setValExtension(false);
        }
    }else{

        if(valor === undefined){
            setValExtension(true);
        }
        else if(reg.test(valor) && (valor.length <= 0 || valor.length >= 4)&& reg2.test(valor)){
            setValExtension(true);
        }else{
            setValExtension(false);
        }
    }
}

const Referencia = (e) => {

    const valor = referencia;
    const reg = /[!¡¿°´@#$%^&*()_+\-=\[\]{}"\\|<>\/?]+/;

    if(valor !== null){
        if(valor!== undefined){
            setValresReferencia(500 - valor.length)
        }
    
        if(reg.test(valor)){
            setValReferencia(true);
        }else if(!isNaN(valor) && valor.length >=500){
            setValReferencia(true);
        }else{
            setValReferencia(false);
        }
    }else{
        if(reg.test(valor)){
            setValReferencia(true);
        }else if(!isNaN(valor)){
            setValReferencia(true);
        }else{
            setValReferencia(false);
        }
    }

}

const Observ = (e) => {

    const valor = observaciones;
    const reg = /[!¡¿°´@#$%^&*()_+\-=\[\]{}"\\|<>\/?]+/;

    if(valor !== null){
        if(reg.test(valor)){
            setValObserv(true);
        }else if(!isNaN(valor) && valor.length >=500){
            setValObserv(true);
        }else{
            setValObserv(false);
        }
    }else{
        if(reg.test(valor)){
            setValObserv(true);
        }else if(!isNaN(valor)){
            setValObserv(true);
        }else{
            setValObserv(false);
        }
    }
}

  const columns = [
    {
      title:(
        <div style={{ textAlign: 'center' }}>
            <div>
              <Tooltip title={etiquetaSede.etiqueta_tooltip_filtros}>
                <img style={{width:17 , height:17}} src={iconoQuestion}/>
              </Tooltip>
            </div>
            <br />
          </div>),
      dataIndex:'info',
      width: 40,
      key:'info'
    },
    {
        title: (
          <div style={{ textAlign: 'center' }}>
              <div>N° de sede</div>
              <br />
              <Input
                    value={searchSede}
                    onChange={({target:{ value}}) => setSearchSede(value)}
                    placeholder='N° de sede'  
                    suffix = {<img style={{width:12 , height:12}} src={iconoFiltro}/>}
                    onKeyPress={(e) => {if (e.key === 'Enter') {props.searchIdSede(searchSede)}}}   
                    />
          </div>
        ),
        align: 'center',
        dataIndex: 'idSede',

        key: 'idSede'
    },
    {
      title: "Dirección/URL",
      align: 'center',
      dataIndex: "lugar",
      key: 'lugar',
    },
    {
      title:"Municipio/alcaldía",
      align: 'center',
      dataIndex: 'municipioDom',
      key: 'municipioDom',
    },
    {
        title: (
            <div style={{ textAlign: 'center' }}>
                <div>Tipo de sede</div>
                <br />
                <Select placeholder='Seleccione...'  onChange={props.searchTipoSede} >
                    <Option value="">Tipo de sede</Option>
                {
                    props.infoSede &&
                    props.infoSede.map((sedes, index) => (
                    <Option key={index} value={sedes.idSede}>
                        {sedes.nombreTipo}
                    </Option>
                    ))
                }                                                          
                </Select>
            </div>
          ),
      align: 'center',
      dataIndex: 'tipoSede',
      key: 'tipoSede',
    },
    {
        title:"Registro",
        align: 'center',
        dataIndex: 'fecha',
        key: 'fecha ',
      },
    {
      title: (
        <div style={{ textAlign: 'center' }}>
            <div>Acciones</div>
            <br/>
        </div>
      ),
      align: 'center',
      dataIndex: 'acciones',
      key: 'acciones',
      render: (fila, record) => 
      props.estatusModulo ? (<><Tooltip title={etiquetasCasillas.etiqueta_tooltip_consultar}><a onClick={()=>{props.mostrarModal(record)}}><img style={{width:23 , height:14 }} src={iconoMostrar} id="eyecc-icon"/></a></Tooltip>&nbsp;
                               <Tooltip title={etiquetasCasillas.etiqueta_tooltip_editar}><a onClick={()=> props.rolCau === true ? '' : props.modificaSedes(record)}><img style={{width:13 , height:15}} src={iconoEditar} id="edit-icon"/></a></Tooltip>&nbsp;</>):
                               (<><Tooltip title={etiquetasCasillas.etiqueta_tooltip_consultar}><a><img style={{width:23 , height:14 }} src={iconoMostrar} id="eyecc-icon"/></a></Tooltip>&nbsp;
                               <Tooltip title={etiquetasCasillas.etiqueta_tooltip_editar}><a><img style={{width:13 , height:15}} src={iconoEditar} id="edit-icon"/></a></Tooltip>&nbsp;</>)
                                
    },
  ];

  return(

          <>
          <Layout className='validar_modulo consultaCasillas'>
            <Alert icon={icon}
                 message={props.mensajeAlerta}
                 type={props.tipoAlerta} showIcon
                 style ={props.mostrarAlerta}/>
            <div style={{display: props.modifica ? "none" : "block"}}>
              <div id="seccion1">
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <h1 id="titulo-componente">Consultar y modificar</h1>
                  </Col>
                </Row>
              </div>
                <Card bordered={true} style={{ marginBottom: '24px', textAlign: 'center'}}  size="small">
                    <h4>
                        <b>Listado de sedes     </b>  
                        <Tooltip title={etiquetaSede.etiqueta_tooltip_listado_sedes}>
                            <img style={{width:17 , height:17}} src={iconoQuestion}/>
                        </Tooltip>
                    </h4>    
                  <div id="tabla-consulta-casillas">
                      <Table dataSource={props.data} pagination={false} columns={columns} scroll={{ y: 240 }}  size="middle" />
                      
                  </div>
                </Card>
                <br/>
                <br/>
                <div id="pagina">
                <Row justify="center"> 
                <Col xs={24} sm={24} md={{span:1, offset:2}} lg={{span:1, offset:2}} xl={{span:1, offset:2}}>
                    <HomeFilled onClick={props.regresarPrimeraPagina} style={{color: '#7C5D9F'}}/>
                  </Col>                
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>                
                    <Pagination current={props.actual}
                                onChange={props.paginado} 
                                total={props.total}
                                defaultPageSize={5}
                                loading={props.cargando}
                                size="small"
                                showSizeChanger={false}/>
                  </Col>
                </Row>
              </div>
            </div>
{/****************************************************Modificar Informacion**********************************************************************/}
          <div  style={{display:props.modifica ? "block" : "none"}}>
            <div id="seccion1">
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div >
                      <h1 id="titulo-componente">Modificar registro</h1>
                  </div>
                </Col>
              </Row>
              </div>
              <Tabs centered = {true} defaultActiveKey="1" activeKey={props.activeTabModifica} onChange={props.changeTabModifica} size="large" className="tab-modal">
                <TabPane  tab="" key="1">
                  <div>
                    <h4>Captura los datos requeridos. Los datos con (<b><font color="red">*</font></b>) son obligatorios.</h4>
                  </div>
                <br/>
                <Form form = {form}>
                    <Card bordered={false}>
                      <Row gutter={[16,16]}>
                          <Col xs={12} sm={12} md={12} lg={4}>
                              <div  >
                                  <h3>Tipo de sede</h3>
                              </div>
                              <div>
                                  <Form.Item name="tipoSede" >
                                        <Input 
                                              defaultValue={tipoSede}
                                              name = "tipoSede"
                                              disabled
                                              onChange = {(e) => {setTipSede(e.target.value)
                                                                  props.cambioText(e)}}
                                              ></Input>
                                  </Form.Item> 

                              </div>
                          </Col>
                          <Col xs={12} sm={12} md={12} lg={7} >
                              <div style={{display:(props.tipSede === 'VIRTUAL') ? 'none': 'block'}}>
                                  <div>
                                      <h3><b><font color="red">*</font></b>Lugar de la sede</h3>
                                  </div>

                                  <div>
                                  <Form.Item form = {form} name="lugar" rules={[{max:250, pattern: /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i, message: 'Caracteres no permitidos (/:*?”<>).'},{required: true, message: 'Dato requerido'}]} >
                                          <Input 
                                              defaultValue={lugar}
                                              name = "lugar"
                                              onChange = {(e) => {setLugarSede(e.target.value)
                                                                  props.cambioText(e)
                                                                  Lugar(e)
                                                                }}
                                              maxLength={250}
                                              rules={[{
                                                  max: 250
                                                  , pattern: etiquetaSede.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                                  }]}></Input>
                                      </Form.Item>
                                  </div>
                              </div>
                              <div style={{display:(props.tipSede === 'PRESENCIAL') ? 'none': 'block'}}>
                                  <div>
                                      <h3><b><font color="red">*</font></b>URL de acceso</h3>
                                  </div>
                                  <div>
                                  <Form.Item form = {form} name="lugar" rules={[{required: true, message: 'Dato requerido'},{max:2500}]}>
                                          <Input 
                                              defaultValue={lugar}
                                              name = "lugar"
                                              onChange = {(e) => {setLugarSede(e.target.value)
                                                                  props.cambioText(e)
                                                                  
                                                                }}
                                              maxLength={2500}
                                              rules={[{
                                                  max: 2500
                                                  , pattern: etiquetaSede.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                                  }]}></Input>
                                      </Form.Item>
                                  </div>
                              </div>
                          </Col> 

                          <Col xs={12} sm={12} md={12} lg={6} >
                            <div>
                                <div>
                                    <h3><b><font color="red">*</font></b>Persona capacitadora</h3>
                                </div>                                
                                <div >
                                    <Form.Item form = {form} name="nombreCapacitador" rules={[{max:250, pattern: /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i, message: 'Caracteres no permitidos (/:*?”<>).'},{required: true, message: 'Dato requerido'}]}>
                                        <Input 
                                            name = "nombreCapacitador"
                                            defaultValue={nombreCapacitador}
                                            onChange = {(e) => {setNombre(e.target.value)
                                                                props.cambioText(e)
                                                                nomCapacitador(e)
                                                            }}
                                            maxLength={250}
                                            rules={[{
                                                max: 250
                                                , pattern: etiquetaSede.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                                }]}></Input>
                                    </Form.Item>
                                </div>
                            </div>
                        </Col> 
                        <Col xs={12} sm={12} md={12} lg={6} >
                            <div>
                                <div>
                                    <h3><b><font color="red">*</font></b>Cargo de la cersona capacitadora</h3>
                                </div>                                
                                <div >
                                    <Form.Item form = {form} name="cargo_Capacitador" rules={[{max:250, pattern: /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i, message: 'Caracteres no permitidos (/:*?”<>).'},{required: true, message: 'Dato requerido'}]}>
                                        <Input 
                                            name = "cargo_Capacitador"
                                            defaultValue={cargo_Capacitador}
                                            onChange = {(e) => {setCargo(e.target.value)
                                                                props.cambioText(e)
                                                                nomCapacitador(e)
                                                            }}
                                            maxLength={250}
                                            rules={[{
                                                max: 250
                                                , pattern: etiquetaSede.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                                }]}></Input>
                                    </Form.Item>
                                </div>
                            </div>
                        </Col>                                 
                      </Row>
                      <Row>
                          <div style={{display:(props.tipSede === 'VIRTUAL') ? 'none': 'block'}}>
                              <Col xs={24} sm={24} md={24} lg={24} >
                                  <div className='compomente_Domi'>
                                      <h1 id="titulo-componente">Domicilio</h1>
                                  </div>
                                  <div>
                                    <Divider id='divide'/>            
                                  </div> 
                              </Col>
                          </div>                  
                      </Row>
                      <Row gutter={[16,16]}>                   
                          <Col xs={24} sm={12} md={9} lg={12} >
                              <div style={{display:(props.tipSede === 'VIRTUAL') ? 'none': 'block'}}>
                                  <div>
                                      <h3><b><font color="red">*</font></b>Calle</h3>
                                  </div>
                                  <div>
                                      <Form.Item form = {form} name="domicilioSede" rules={[{required: true, message: 'Dato requerido'}, { pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Caracteres no permitidos (/:*?”<>).'}]}>
                                          <Input 
                                              defaultValue={domicilioSede}
                                              name = "domicilioSede"
                                              onChange = {(e) => {  setCalle(e.target.value)
                                                                    props.cambioText(e)
                                                                    Calle(e)
                                                                }}
                                              maxLength={80}
                                              rules={[{
                                                  max: 80
                                                  , pattern: etiquetaSede.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                                  }]}></Input>
                                      </Form.Item>
                                  </div>
                              </div>
                          </Col>           
                          <Col xs={24} sm={4} md={5} lg={4} >
                              <div style={{display:(props.tipSede === 'VIRTUAL') ? 'none': 'block'}}>
                                  <div>
                                      <h3><b><font color="red">*</font></b>Número exterior</h3>
                                  </div>
                                  <div>
                                      <Form.Item form = {form} name="numExterior" rules={[{required: true, message: 'Dato requerido'},{ pattern:  /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Caracteres no permitidos (/:*?”<>).'}]}>
                                          <Input 
                                              defaultValue={numExterior}
                                              name = "numExterior"
                                              maxLength={5}
                                              onChange = {(e) => {setnumeroE(e.target.value)
                                                                  props.cambioText(e)
                                                                  numeroExterior(e)
                                                                  }}
                                                maxLength={5}
                                              ></Input >
                                      </Form.Item>
                                  </div>
                              </div>
                          </Col>           
                          <Col xs={24} sm={4} md={5} lg={4}>
                              <div style={{display:(props.tipSede === 'VIRTUAL') ? 'none': 'block'}}>
                                  <div>
                                      <h3>Número interior</h3>
                                  </div>
                                  <div>
                                      <Form.Item form = {form} name="numInterior" rules={[{ pattern:  /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Caracteres no permitidos (/:*?”<>).'}]}>
                                          <Input 
                                                defaultValue={numInterior}
                                                name = "numInterior"
                                                onChange = {(e) => { setnumeroI(e.target.value)
                                                                    props.cambioText(e)
                                                                    numeroInterior(e)
                                                                }}
                                                maxLength={5}
                                              ></Input>
                                      </Form.Item>
                                  </div>
                              </div>
                          </Col>           
                          <Col xs={24} sm={4} md={5} lg={4}>
                              <div style={{display:(props.tipSede === 'VIRTUAL') ? 'none': 'block'}}>
                                  <div>
                                      <h3><b><font color="red">*</font></b>Colonia</h3>
                                  </div>
                                  <div>
                                      <Form.Item form = {form} name="colonia" rules={[{required: true, message: 'Dato requerido'},{ pattern: /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i, message: 'Caracteres no permitidos (/:*?”<>).'}]}>
                                          <Input
                                                defaultValue={colonia} 
                                                name = "colonia"
                                                onChange = {(e) => {    setColonia(e.target.value)
                                                                        props.cambioText(e)
                                                                        Colonia(e)
                                                                    }}
                                                maxLength={50}
                                                rules={[{
                                                  max: 50
                                                  , pattern: etiquetaSede.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                                  }]}></Input>
                                      </Form.Item>
                                  </div>
                              </div>
                          </Col>            
                      </Row>

                      <Row gutter={[16,16]}>
                          <Col xs={24} sm={12} md={12} lg={6}>
                              <div style={{display:(props.tipSede === 'VIRTUAL') ? 'none': 'block'}}>
                                  <div>
                                      <h3>Municipio/alcaldía</h3>
                                  </div>
                                  <div>
                                      <Form.Item form = {form} name="municipio" rules={[{required: true, message: 'Dato requerido'}]}>   
                                          {/* <Select placeholder="Seleccionar..."  onChange={(e) =>{ props.selectMunicipio(e)
                                                                                                  //clearSelected()
                                                                                                  }} value={municipio}>
                                              {
                                                  props.municipios &&
                                                  props.municipios.map((municipios, index) => (
                                                  <Option key={index} value={municipios.idMunicipio}>
                                                      {municipios.nombreMunicipio}
                                                  </Option>
                                                  ))
                                              }
                                          </Select> */}
                                          <Input 
                                              defaultValue={municipioDom}
                                              name = "municipio"
                                              disabled
                                              onChange = {(e) => {setmunicipio(e.target.value)
                                                                  props.cambioText(e)}}
                                             
                                              disabled
                                              ></Input>
                                      </Form.Item>
                                  </div>
                              </div>
                          </Col>           
                          <Col xs={24} sm={12} md={12} lg={6}>
                              <div style={{display:(props.tipSede === 'VIRTUAL') ? 'none': 'block'}}>
                                  <div>
                                      <h3>Sección electoral</h3>
                                  </div>
                                  <div>
                                      <Form.Item form = {form} name="seccion" rules={[{required: true, message: 'Dato requerido'}]}>
                                         
                                         <Input 
                                              defaultValue={seccion}
                                              disabled
                                              name = "seccion"
                                              onChange = {(e) => {setSeccion(e.target.value)
                                                                  props.cambioText(e)}}
                                        ></Input>

                                      </Form.Item>
                                  </div>
                              </div>
                          </Col>           
                          <Col xs={24} sm={12} md={12} lg={6}>
                              <div style={{display:(props.tipSede === 'VIRTUAL') ? 'none': 'block'}}>
                                  <div>
                                      <h3><b><font color="red">*</font></b>Código Postal</h3>
                                  </div>
                                  <div>
                                      <Form.Item name="codigoPostal" rules={[{required: true, message: 'Dato requerido'}, { pattern: /^\d*[1-9]+\d*$/, message: 'Solo números'}, { pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Caracteres no permitidos (/:*?”<>).'}]}
                                                                        validateStatus={(codigoPostal === undefined || codigoPostal === null) ? null: (codigoPostal.toString().length === 5) ? null:'error'} help={(codigoPostal === undefined || codigoPostal === null) ? null: (codigoPostal.toString().length === 5) ? null:'Formato incorrecto'}
                                                                        >
                                          <Input 
                                              defaultValue={codigoPostal}
                                              name = "codigoPostal"
                                              
                                              onChange = {(e) => {  setCodigoPo(e.target.value)
                                                                    props.cambioText(e)
                                                                    codigoPosta(e)
                                                  }}
                                              maxLength={5}
                                              rules={[{
                                                  len: 5,
                                                  pattern: /^\d{5,5}$/i}]}>
                                              </Input>
                                      </Form.Item>
                                  </div>
                              </div>
                          </Col>           
                      </Row>
                      <Row gutter={[16,16]}>
                          <Col xs={24} sm={12} md={12} lg={6}>
                          <div style={{display:(props.tipSede === 'VIRTUAL') ? 'none': 'block'}}>
                                  <div>
                                      <h3><b><font color="red">*</font></b>Teléfono</h3>
                                  </div>
                                  <div>
                                      <Form.Item form = {form} name="telefonoSede" rules={[{required: true, message: 'Dato requerido'},{ pattern: /^\d*[1-9]+\d*$/, message: 'Solo números'}, { pattern: /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i, message: ''}]}
                                                                        validateStatus={(telefonoSede === undefined || telefonoSede === '' || telefonoSede === null) ? null : telefonoSede.toString().length === 10 ? null:'error'} help={(telefonoSede === undefined || telefonoSede === '' || telefonoSede === null) ? null : telefonoSede.toString().length === 10 ? null:'Formato incorrecto'}>
                                                                        
                                          <Input  
                                          defaultValue={telefonoSede}
                                              name = "telefonoSede"
                                              onChange = {(e) => {  settelefono(e.target.value)
                                                                    props.cambioText(e)
                                                                    telefon(e)
                                                                }}
                                                  maxLength={10}
                                                  rules={[{
                                                      min: 8
                                                      , max: 10
                                                      , pattern: etiquetaSede.REGEX_FORMATO_TELEFONO
                                                  }]}
                                              ></Input>
                                      </Form.Item>
                                  </div>
                              </div>                              
                          </Col>           
                          <Col xs={24} sm={12} md={12} lg={6}>
                            <div style={{display:(props.tipSede === 'VIRTUAL') ? 'none': 'block'}}>
                                  <div>
                                      <h3>Extensión</h3>
                                  </div>
                                  <div>
                                      <Form.Item form = {form} name="extension" rules={[{ pattern: /^\d*[0-9]+\d*$/, message: 'Solo números'},{ pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Caracteres no permitidos (/:*?”<>).'}]}>
                                          <Input 
                                            defaultValue={extension}
                                              name = "extension"
                                              onChange = {(e) => {  setExt(e.target.value)
                                                                    props.cambioText(e)
                                                                    exten(e)
                                                                }}
                                             maxLength={4}
                                              ></Input>
                                      </Form.Item>
                                  </div>
                              </div>
                          </Col>           
                          <Col xs={24} sm={12} md={12} lg={12}>
                              <div style={{display:(props.tipSede === 'VIRTUAL') ? 'none': 'block'}}>
                                  <div>
                                      <h3><b><font color="red">*</font></b>Referencia de la sede</h3>
                                  </div>
                                  <div>
                                      <Form.Item form = {form} name="referencia" rules={[{required: true, message: 'Dato requerido'}]}>
                                          <TextArea 
                                              autoSize
                                              defaultValue={referencia}
                                              name = "referencia"
                                              onChange = {(e) => {  setReferencia(e.target.value)
                                                                    props.cambioText(e)
                                                                    //Referencia(e)
                                                                }}
                                              maxLength={250}
                                              showCount
                                              rules={[{
                                                  max: 250
                                                  , pattern: etiquetaSede.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                                  }]}></TextArea>
                                      </Form.Item>
                                  </div>
                              </div>
                          </Col>           
                      </Row>
                      <Row>
                          <Col xs={24} sm={12} md={12} lg={12}>
                              <div style={{display:(props.tipSede === 'PRESENCIAL') ? 'none': 'block'}}>
                                  <div>
                                      <h3><b><font color="red">*</font></b>Observaciones de la sede</h3>
                                  </div>
                                  <div>
                                      <Form.Item form = {form} name="observaciones" rules={[{required: true, message: 'Dato requerido'}]}>
                                          <TextArea 
                                          defaultValue={observaciones}
                                              name = "observaciones"            
                                              onChange = {(e) => {  setObser(e.target.value)
                                                                    props.cambioText(e)
                                                                    //Observ(e)
                                                                }}
                                              showCount
                                              maxLength={250}
                                              rules={[{
                                                  max: 250
                                                  , pattern: etiquetaSede.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                                  }]}></TextArea>
                                      </Form.Item>
                                  </div>
                              </div>
                          </Col>
                      </Row>               
                  </Card>
                <Form.Item>
                <Row gutter={2}>
                <Col offset={10} sm={10} md={10} lg={10} xl={10}>
                <Tooltip title={(tipoSede === "VIRTUAL") ? 
                        ((lugar !== undefined) && (lugar !== '')// && (fvalLugar !== true)
                    && (nombreCapacitador !== undefined) && (nombreCapacitador !== '') && (fvalNombre !== true) 
                    && (cargo_Capacitador !== undefined) && (cargo_Capacitador !== '') && (fvalCargo !== true)
                    && (observaciones !== undefined) && (observaciones !== '') ///&& (fvalObserv !== true)
                    &&(props.rolCau === false) ? null: etiquetaSede.etiqueta_boton_guardar):
                        (tipoSede === "PRESENCIAL") ? 
                      ((lugar !== undefined) && (lugar !== '') && (fvalLugar !== true)
                    && (nombreCapacitador !== undefined) && (nombreCapacitador !== '') && (fvalNombre !== true)
                    && (cargo_Capacitador !== undefined) && (cargo_Capacitador !== '') && (fvalCargo !== true)
                    && (domicilioSede !== undefined) && (domicilioSede !== '') && (fvalCalle !== true)
                    && (numExterior !== undefined) && (numExterior !== '') && (fvalNum ===true)  && (fvalNumInt === true)
                    && (colonia !== undefined) && (colonia !== '') && (fvalColonia !== true)
                    && (municipioDom !== undefined)
                    && (seccion !== undefined)
                    && (codigoPostal !== undefined) && (codigoPostal !== '') && (fvalCp === true) 
                    && (telefonoSede !== undefined) && (telefonoSede !== '') 
                    && (referencia !== undefined) && (referencia !== '') //&& (fvalReferencia !== true)
                    &&(props.rolCau === false)? null: etiquetaSede.etiqueta_boton_guardar):
                    ((lugar !== undefined) && (lugar !== '') && (fvalLugar !== true)
                    && (nombreCapacitador !== undefined) && (nombreCapacitador !== '') && (fvalNombre !== true)
                    && (cargo_Capacitador !== undefined) && (cargo_Capacitador !== '') && (fvalCargo !== true)
                    && (domicilioSede !== undefined) && (domicilioSede !== '') && (fvalCalle !== true)
                    && (numExterior !== undefined) && (numExterior !== '') && (fvalNum ===true)  && (fvalNumInt === true)
                    && (colonia !== undefined) && (colonia !== '') && (fvalColonia !== true)
                    && (municipioDom !== undefined)
                    && (seccion !== undefined)
                    && (codigoPostal !== undefined) && (codigoPostal !== '') && (fvalCp === true) 
                    && (telefonoSede !== undefined) && (telefonoSede !== '') 
                    && (referencia !== undefined) && (referencia !== '') //&& (fvalReferencia !== true)
                    &&(props.rolCau === false) ? null: etiquetaSede.etiqueta_boton_guardar)}>
                    <Button type="primary" htmlType="submit" 
                    //style={{background: "#D4217F", borderColor: "#ffffff", borderRadius: "40px" }}    
                    disabled={(tipoSede === "VIRTUAL") ? 
                        ((lugar !== undefined) && (lugar !== '')// && (fvalLugar !== true)
                    && (nombreCapacitador !== undefined) && (nombreCapacitador !== '') && (fvalNombre !== true)
                    && (cargo_Capacitador !== undefined) && (cargo_Capacitador !== '') && (fvalCargo !== true) 
                    && (observaciones !== undefined) && (observaciones !== '') ///&& (fvalObserv !== true)
                    &&(props.rolCau === false) ? false : true):
                        (tipoSede === "PRESENCIAL") ? 
                      ((lugar !== undefined) && (lugar !== '') && (fvalLugar !== true)
                    && (nombreCapacitador !== undefined) && (nombreCapacitador !== '') && (fvalNombre !== true)
                    && (cargo_Capacitador !== undefined) && (cargo_Capacitador !== '') && (fvalCargo !== true)
                    && (domicilioSede !== undefined) && (domicilioSede !== '') && (fvalCalle !== true)
                    && (numExterior !== undefined) && (numExterior !== '') && (fvalNum ===true)  && (fvalNumInt === true)
                    && (colonia !== undefined) && (colonia !== '') && (fvalColonia !== true)
                    && (municipioDom !== undefined)
                    && (seccion !== undefined)
                    && (codigoPostal !== undefined) && (codigoPostal !== '') && (fvalCp === true) 
                    && (telefonoSede !== undefined) && (telefonoSede !== '') 
                    && (referencia !== undefined) && (referencia !== '') //&& (fvalReferencia !== true)
                    &&(props.rolCau === false) ? false : true):
                    ((lugar !== undefined) && (lugar !== '') && (fvalLugar !== true)
                    && (nombreCapacitador !== undefined) && (nombreCapacitador !== '') && (fvalNombre !== true)
                    && (cargo_Capacitador !== undefined) && (cargo_Capacitador !== '') && (fvalCargo !== true)
                    && (domicilioSede !== undefined) && (domicilioSede !== '') && (fvalCalle !== true)
                    && (numExterior !== undefined) && (numExterior !== '') && (fvalNum ===true)  && (fvalNumInt === true)
                    && (colonia !== undefined) && (colonia !== '') && (fvalColonia !== true)
                    && (municipioDom !== undefined)
                    && (seccion !== undefined)
                    && (codigoPostal !== undefined) && (codigoPostal !== '') && (fvalCp === true) 
                    && (telefonoSede !== undefined) && (telefonoSede !== '') 
                    && (referencia !== undefined) && (referencia !== '') //&& (fvalReferencia !== true)
                    &&(props.rolCau === false) ? false : true)}    

                    onClick={props.enviarModificacion}>Guardar</Button>
                </Tooltip>
                </Col>
                </Row>
                </Form.Item>    
                </Form> 
                </TabPane>

            </Tabs>

            </div>
{/*************************************************************************************************************************** */}
{/************************************************************Consultar Informacion****************************************************************** */}            
            <Modal visible={props.mostrar}
                   onCancel={props.ocultarModal}
                   cancelText="Cancelar"
                   centered
                   width={1000}
                   className="Modal-consulta"
                   bodyStyle={{height:620, background:'#ffffff'}}
                   footer={false}>
            {
            props.sedeSeleccionadaModal != null ?
            <>
              <Row id="modal-header">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <h1>&nbsp;&nbsp;Consultar registro<br/>
                      &nbsp;&nbsp;Sede&nbsp;{props.sedeSeleccionadaModal.idSede}
                  </h1>
                </Col>
              </Row>
              <Tabs centered = {true} defaultActiveKey="1" activeKey={props.activeTab} onChange={props.changeTab} size="large" className="tab-modal">
                    <TabPane disabled key="1">
                    <Card bordered={false} style={{width:960, height:400, padding:20}}>
                          <Row gutter={[16,16]}>
                              <Col xs={12} sm={12} md={12} lg={4}>
                                  <div id="idTipoSede">
                                      <h3>Tipo de sede</h3>

                                      {props.sedeSeleccionadaModal.tipoSede} 
                                  </div>
                              </Col>
                              <Col xs={12} sm={12} md={12} lg={6} >
                                  <div id="lugarSede" style={{display:(props.sedeSeleccionadaModal.tipoSede === 'VIRTUAL') ? 'none': 'block'}}>
                                      <div>
                                          <h3>Lugar de la sede</h3>
                                        {props.sedeSeleccionadaModal.lugar}                                   
                                      </div>
                                  </div>
                                  <div id="link" style={{display:(props.sedeSeleccionadaModal.tipoSede === 'PRESENCIAL') ? 'none': 'block'}}>  
                                      <h3>URL de acceso</h3>
                                        {props.sedeSeleccionadaModal.lugar}
                                 </div>
                              </Col> 
                              <Col xs={12} sm={12} md={12} lg={6} >
                                  <div>
                                      <div>
                                          <h3>Persona capacitadora</h3>
                                        {props.sedeSeleccionadaModal.nombreCapacitador}                                   
                                      </div>
                                  </div>
                              </Col> 
                              <Col xs={12} sm={12} md={12} lg={8} >
                                  <div>
                                      <div>
                                          <h3>Carga de la persona capacitadora</h3>
                                        {props.sedeSeleccionadaModal.cargo_Capacitador}                                   
                                      </div>
                                  </div>
                              </Col>                          
                          </Row>
                          <Row>
                              <div id="tit-Dom" style={{display:(props.sedeSeleccionadaModal.tipoSede === 'VIRTUAL') ? 'none': 'block'}}>
                                  <Col xs={24} sm={24} md={24} lg={24} >
                                      <div className='compomente_Domi'>
                                          <h1 id="titulo-componente">Domicilio</h1>
                                      </div>
                                      <div>
                                        <Divider id='divide'/>            
                                      </div> 
                                  </Col>
                              </div>                  
                          </Row>
                          <Row gutter={[16,16]}>                   
                              <Col xs={24} sm={12} md={9} lg={12} >
                                  <div id='calle' style={{display:(props.sedeSeleccionadaModal.tipoSede === 'VIRTUAL') ? 'none': 'block'}}>
                                          <h3>Calle</h3>
                                                {props.sedeSeleccionadaModal.domicilioSede}
         
                                  </div>
                              </Col>           
                              <Col xs={24} sm={4} md={5} lg={4} >
                                  <div id='idNumE'style={{display:(props.sedeSeleccionadaModal.tipoSede === 'VIRTUAL') ? 'none': 'block'}}>
                                          <h3>Número exterior</h3>
                                                {props.sedeSeleccionadaModal.numExterior}
                                  </div>
                              </Col>           
                              <Col xs={24} sm={4} md={5} lg={4}>
                                  <div id='idNumI'style={{display:(props.sedeSeleccionadaModal.tipoSede === 'VIRTUAL') ? 'none': 'block'}}>
                                          <h3 >Número interior</h3>
                                                {props.sedeSeleccionadaModal.numInterior}
                                  </div>
                              </Col>           
                              <Col xs={24} sm={4} md={5} lg={4}>
                                  <div id='idCol' style={{display:(props.sedeSeleccionadaModal.tipoSede === 'VIRTUAL') ? 'none': 'block'}}>
                                      
                                          <h3>Colonia</h3>
                                          {props.sedeSeleccionadaModal.colonia}
                                  </div>
                              </Col>            
                          </Row>

                          <Row gutter={[16,16]}>
                              <Col xs={24} sm={12} md={12} lg={6}>
                                  <div id='idSeccion' style={{display:(props.sedeSeleccionadaModal.tipoSede === 'VIRTUAL') ? 'none': 'block'}}>
                                      
                                          <h3>Municipio/alcaldía</h3>
                                          {props.sedeSeleccionadaModal.municipioDom}
                                  </div>
                              </Col>           
                              <Col xs={24} sm={12} md={12} lg={6}>
                                  <div id='idMuni' style={{display:(props.sedeSeleccionadaModal.tipoSede === 'VIRTUAL') ? 'none': 'block'}}>                                 
                                          <h3>Sección electoral</h3> 
                                          {props.sedeSeleccionadaModal.seccion}                                 
                                  </div>
                              </Col>           
                              <Col xs={24} sm={12} md={12} lg={6}>
                                  <div id='idCod' style={{display:(props.sedeSeleccionadaModal.tipoSede === 'VIRTUAL') ? 'none': 'block'}}>
                                          <h3>Código Postal</h3> 
                                          {props.sedeSeleccionadaModal.codigoPostal}                                     
                                  </div>
                              </Col>           
                          </Row>
                          <Row gutter={[16,16]}>
                              <Col xs={24} sm={12} md={12} lg={6}>
                                  <div id='idTel' style={{display:(props.sedeSeleccionadaModal.tipoSede === 'VIRTUAL') ? 'none': 'block'}}>
                                          <h3>Teléfono</h3>
                                          {props.sedeSeleccionadaModal.telefonoSede}
                                  </div>
                              </Col> 
                              <Col xs={24} sm={12} md={12} lg={6}>
                                  <div id='idExt' style={{display:(props.sedeSeleccionadaModal.tipoSede === 'VIRTUAL') ? 'none': 'block'}}>
                                          <h3>Extensión</h3>
                                          {props.sedeSeleccionadaModal.extension}
                                  </div>
                              </Col>                                                  
                              <Col xs={24} sm={12} md={12} lg={12}>
                                  <div id='idRef' style={{display:(props.sedeSeleccionadaModal.tipoSede === 'VIRTUAL') ? 'none': 'block'}}>
                                          <h3>Referencia de la sede</h3>
                                          {props.sedeSeleccionadaModal.referencia}
                                  </div>
                              </Col>           
                          </Row>
                          <Row>
                              <Col xs={24} sm={12} md={12} lg={12}>
                                  <div id='idObs' style={{display:(props.sedeSeleccionadaModal.tipoSede === 'PRESENCIAL') ? 'none': 'block'}}>
                                          <h3>Observaciones de la sede</h3>
                                          {props.sedeSeleccionadaModal.observaciones}
                                  </div>
                              </Col>
                          </Row>               
                      </Card>
                        <br/>
                        
                    </TabPane>                    
              </Tabs>             
            </>
          : ""
          }
          </Modal>
{/***************************************************************************************************************************** */}          
          <Modal
            className="modalModifica"
            visible={props.abrirModalExitoModifica}
            title={<img  src={sedesModificaImg} id="img-exito-modal"/>}
            onCancel={props.onChangeRouteModulo}
            centered
            width={327}
            footer={[null]}>{
              <>
                <div>
                  <span className="titulo-exito">Sede modificada</span>
                </div>
                <div>
                  <span className="notificacion-exito">La información se actualizó con éxito</span>
                </div>
                <br/>
                <Row>
                  <Col offset={8} span={6}>
                    <Button type="primary" htmlType="submit" style={{ background: "#D4217F", borderColor: "#ffffff" }} onClick={props.onChangeRouteModifica}>Aceptar</Button>
                  </Col>
                </Row>
                <br/>
              </>
            }
                </Modal>
          </Layout>
          </>
    )
}

export default VCapturaSedes
