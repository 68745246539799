import React from 'react';
import { VictoryPie } from 'victory';
import VChartLabel from './VChartLabel';

const VChartParticipantesEdad = ({ total, labelDato, value1, porcentaje, color, x, y, radius, outerRadius, labelStart }) => {
    return(
            <g>
                <circle cx={x} cy={y} r={outerRadius+20} fill={color}/>
                <circle cx={x} cy={y} r={outerRadius} fill='#FFFFFF'/>
                <VictoryPie
                    standalone={false}
                    radius={radius}
                    origin={{ x: x, y: y }}
                    startAngle={0}
                    endAngle={-360}
                    innerRadius={0} 
                    cornerRadius={0}
                    colorScale={[color, '#FFFFFF']}
                    data={[{ x: 1, y: value1}, 
                        { x: 2, y: total - value1}]}
                    labelComponent={<VChartLabel x={x}
                                        y={y}
                                        color={color}
                                        porcentaje={Math.round(porcentaje)}
                                        value1={value1}/>}
                    />
                <text x={x} 
                    y={labelStart?(y-outerRadius-23):(y+outerRadius+35)} 
                    textAnchor="middle"
                    style={{fontSize: 14,
                            fontFamily: 'OpenSans-Bold',
                            fill: 'var(--chart-text-second)'}}>
                    {labelDato}
                </text>
            </g>
    );
};

export default VChartParticipantesEdad;
