import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from "react-router-dom";
import { selectSubMenus } from '../../redux/menu/menu-selectors';
import  { PATH_MODULO } from '../../constants/routers/paths';
import '../../css/header/menu.scss';

const VMenu = ({ subMenus, match }) => {

  return(
    <div className='menu-container'>
      {
        subMenus?
        Object.entries(subMenus).map(([key, value])  => {
          const modulos = value.modulos ? Object.values(value.modulos) : [];
          const pathname = modulos.length === 1 ?
                          modulos[0].urlModulo
                          : `${PATH_MODULO}/${value.idSubMenu}`;
          const idModulo = modulos.length === 1 ?
                          modulos[0].idModulo
                          : 0;
          const selectedUrl = modulos.reduce((acc, value) => {
                                return acc || match.url === value.urlModulo;
                              }, false); 
          const selectedModulo = match.params.idSubMenu == value.idSubMenu;

          return(
              <Link to={{
                      pathname: pathname,
                      state: {
                          idModulo: idModulo,
                      },
                      }} 
                    className={`mc-item
                                ${selectedModulo || selectedUrl?'mc-item-selected':''} 
                                ${!value.isEnabled ?'mc-item-disabled':''}`}
                    key={key} >
                <img className='mc-item-img' 
                    alt='Imagen del menú'
                    src={value.image}/>
                <span className='mc-item-txt'>{value.nombreSubMenu}</span>
              </Link>
          );
        })
        :''
      }
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  subMenus: selectSubMenus
});

export default withRouter(connect(mapStateToProps)(VMenu));
