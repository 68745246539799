import axios from 'axios';
import * as etiquetas from '../../constants/EtiquetasGenerales';
import * as etiquetasMenu from '../../constants/EtiquetasMenu';
import EXTRA_DATA_MENU from './menu-data'; 

export const obtenerMenuLateral = async(idProceso, idDetalle, idSistema,
                                        idEstado, idDistrito, grupoSistema) => {
    try{
        const response = await axios.post(process.env.REACT_APP_API_URL 
                                        + etiquetas.WS_CENTRAL 
                                        + etiquetasMenu.URL_MENU_LATERAL,
                                        {
                                            idProceso,
                                            idDetalle,
                                            idSistema,
                                            idEstado, 
                                            idDistrito,
                                            grupoSistema
                                        });
        if(response.data != null 
            && response.data.code === 200
            && response.data.data !== null) {

            const menu = response.data.data
                        .filter(menu => menu.idMenu === etiquetasMenu.ID_MENU_PORTAL_INTERNO)
                        [0];
            const subMenus = menu.subMenus.map(subMenu => {
                const { image, isEnabled, extraDataModulo } = EXTRA_DATA_MENU[subMenu.idSubMenu];
                
                const modulos = subMenu.modulos.map(modulo => {
                    const { image, isEnabled } = extraDataModulo[modulo.idModulo];
                    return {
                        ...modulo,
                        image,
                        isEnabled
                    }
                }).reduce((accumulator, modulo) => {
                    accumulator[modulo.idModulo] = modulo;
                    return accumulator;
                }, {});

                return {
                    ...subMenu,
                    modulos,
                    image,
                    isEnabled
                }
            }).reduce((accumulator, subMenu) => {
                accumulator[subMenu.idSubMenu] = subMenu;
                return accumulator;
            }, {});

            return subMenus;
        }
    }catch(error){
        console.error("Error al cargar los datos de menú: ", error);
    }      

    return {};                                      
};

export const obtenerEstatusModulo = async(idProceso, idDetalle, idSistema,
                                    idEstado, idDistrito, idModulo, grupoSistema) => {
    try{
        const response = await axios.post(process.env.REACT_APP_API_URL 
                                        + etiquetas.WS_CENTRAL 
                                        + etiquetasMenu.URL_ESTATUS_MODULO,
                                        {
                                            idProceso,
                                            idDetalle,
                                            idSistema,
                                            idEstado, 
                                            idDistrito,
                                            idModulo,
                                            grupoSistema
                                        });
        if(response.data != null 
            && response.data.code === 200
            && response.data.data !== null) {
           return response.data.data;
        }
    }catch(error){
        console.error("Error al obtener el estatus del módulo: ", error);
    }      

    return "A";                                       
};

export const obtenerEstados = async(idEstado) => {

    try{
        const response = await axios.post(process.env.REACT_APP_API_URL 
                                        + etiquetas.WS_CENTRAL 
                                        + etiquetasMenu.URL_OBTENER_ESTADOS,
                                        {
                                           idEstado
                                        });
        if(response.data != null 
            && response.data.code === 200
            && response.data.infoEstados !== null) {
           return response.data.infoEstados;
        }
    }catch(error){
        console.error("Error al obtener la lista de estados: ", error);
    }      

    return [];
};

export const obtenerDistritos = async(idEstado, idDistrito) => {

    try{
        const response = await axios.post(process.env.REACT_APP_API_URL 
                                        + etiquetas.WS_CENTRAL 
                                        + etiquetasMenu.URL_OBTENER_DISTRITOS,
                                        {
                                           idEstado,
                                           idDistrito
                                        });
        if(response.data != null 
            && response.data.code === 200
            && response.data.infoDistritos !== null) {
           return response.data.infoDistritos;
        }
    }catch(error){
        console.error("Error al obtener la lista de distritos: ", error);
    }      

    return [];
};



