import React, { useState, useEffect } from 'react';
import { Button, Modal, Row, Col, Layout, Form, Input, Select, Radio, TimePicker, DatePicker } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import * as etiquetasCasillas from '../../../constants/EtiquetasCasillas';
import BlockLoader from '../../comunes/Loader.js';
import exitoSeguimiento from '../../../assets/img/exitoSeguimiento.png';
import iconoQuestion from '../../../assets/img/icono_question.png';
import errorModal from '../../../assets/img/errorModal.png';
import '../../../css/consultaCasillas/consultaCasillas.css';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';
import 'moment/locale/es';

const VInstalacionCasilla = (props) => {

	const [form] = Form.useForm();
	const { Item } = Form;
	const { TextArea } = Input;

	const siNoOptions = [{ value: 'S', respuesta: 'Sí' },
											 { value: 'N', respuesta: 'No' }
											];
	const horizontalFormItemLayout = {
		wrapperCol: {
			sm: { span: 10, offset: 2, },
			lg: { span: 24, offset: 0, },
		}
	};

	const [abrirNuevaUbicacion, setAbrirNuevaUbicacion] = useState(false);
	const [fechaActa, setFechaActa] = useState();
	const [fechaInicio, setFechaInicio] = useState(undefined);
	const [horaInicio, setHoraInicio] = useState(undefined);
	const [instalacionCorrecta, setInstalacionCorrecta] = useState();
	const [disabledCausaInst, setDisabledCausaInst] = useState(true);
	const [causaInst, setCausaInst] = useState(false);
	const [fechaTermino, setFechaTermino] = useState(undefined);
	const [horaTermino, setHoraTermino] = useState(undefined);
	const [incidenciaCierre, setIncidenciaCierre] = useState(undefined);
	const [cualIncidencia, setCualIncidencia] = useState(false);
	const [datosCasilla, setDatosCasilla] = useState(props.datosCasilla);
	const [lugarCasilla, setLugarCasilla] = useState();
	const [cierreCasilla, setCierreCasilla] = useState(false);
	const [abrirModalExitoRegistro, setAbrirModalExitoRegistro] = useState();
	const [mensajeCasillaRegistrada, setMensajeCasillaRegistrada] = useState();
	const [casillasItinerantes, setCasillasItinerantes] = useState(false);
	//const [, set] = useState();

	useEffect(() => {
		var datosC = props.datosCasilla;
		//console.log(props.datosCasilla);
		setDatosCasilla(datosC);

		if(datosC.nuevaUbicacion === null) {
			var lugarCasilla = datosC.calle + ' No. ' + datosC.numero + ' Col. ' + datosC.colonia + ', C.P. ' + datosC.codigoPostal + ', ' + datosC.cabeceraDistritalFederal + ', ' + props.nombreEstado + '.';
			setLugarCasilla(lugarCasilla); 
		} else {
			setLugarCasilla(datosC.nuevaUbicacion); 
		}

		if (datosC.fechaHoraInicio !== null && (datosC.fechaHoraTermino === null || datosC.fechaHoraTermino === undefined)) {
			setCierreCasilla(true);
			
			form.setFieldsValue({ nombreResponsable: datosCasilla.nombreResponsable	});
			const fechaGeneracionActa = moment(datosCasilla.fechaGeneracionActa).format("YYYY/MM/DD");
			setFechaActa(moment(fechaGeneracionActa));
			//setFechaActa(datosCasilla.fechaGeneracionActa);
		} 
		
		if(props.puedeDescargarActa) {
			setMensajeCasillaRegistrada("Cierre");
		} else {
			setMensajeCasillaRegistrada("Instalación");
		}
		setAbrirModalExitoRegistro(props.abrirModalExitoRegistro);

	}, [props]);

	const onClikCambiarDir = () => {
		setAbrirNuevaUbicacion(true);
	};

	const disabledDate = (d) => {
		const minDate = moment('2021-11-01');
		const maxDate = moment('2021-11-30');
		if (d == null) {
			return null;
		}
		return (
			(minDate != null && d.isBefore(minDate) && !d.isSame(minDate, 'day')) ||
			(maxDate != null && d.isAfter(maxDate) && !d.isSame(maxDate, 'day'))
		);
	};

	const onChangeInstalacionCorrecta = (e) => {
		let inst = e.target.value;
		inst === 'N' ? setDisabledCausaInst(false) : setDisabledCausaInst(true);
		inst === 'N' ? setCausaInst(true) : setCausaInst(false);
	};

	const onChangeIncidenciaCierre = (e) => {
		let incidencia = e.target.value;
		incidencia === 'S' ? setCualIncidencia(true) : setCualIncidencia(false);
	};

	const onFinish = (values) => {
		//console.log(values);
		const datos = {};
		datos.idParticipacion = datosCasilla.idParticipacion;
		datos.idCasilla = datosCasilla.idCasilla;
		datos.idTipoCasilla = datosCasilla.idTipoCasilla;
		datos.tieneInternet = 'N';

		if (values.fechaInicio !== null & values.fechaTermino === undefined) {

			var fechaInicio = values.fechaInicio.format(etiquetasCasillas.FORMATO_FECHA_GUION_MOMENT);
			var horaInicio = values.horaInicio.format(etiquetasCasillas.FORMATO_HORA_MOMENT);
			var fechaHoraInicio = moment(fechaInicio + ' ' + horaInicio).format(etiquetasCasillas.FORMATO_FECHA_GUION_COMPLETA_MOMENT);

			datos.nombreResponsable = values.nombreResponsable;
			datos.fechaGeneracionActa = values.fechaActa;
			datos.fechaHoraInicio = fechaHoraInicio;
			datos.instalacionCorrecta = values.instalacionCorrecta;
			datos.causaInstalacion = values.causaInstalacion;
			datos.nuevaUbicacion = values.nuevoLugar !== null ? values.nuevoLugar : null;

		}

		if (values.fechaInicio !== null & values.fechaTermino !== undefined) {

			var fechaTermino = values.fechaTermino.format(etiquetasCasillas.FORMATO_FECHA_GUION_MOMENT);
			var horaTermino = values.horaTermino.format(etiquetasCasillas.FORMATO_HORA_MOMENT);

			datos.fechaHoraTermino = moment(fechaTermino + ' ' + horaTermino).format(etiquetasCasillas.FORMATO_FECHA_GUION_COMPLETA_MOMENT);
			datos.incidenciaCierre = values.incidenciaCierre;
			datos.descripcionIncidencia = values.cualIncidencia;
			datos.solucionIncidencia = values.solucionIncidencia;
			
			datosCasilla.fechaHoraTermino = moment(fechaTermino, etiquetasCasillas.FORMATO_FECHA_MOMENT).format(etiquetasCasillas.FORMATO_FECHA_GUION_MOMENT) + 'T' + horaTermino;
			datosCasilla.incidenciaCierre = datos.incidenciaCierre;
			datosCasilla.descripcionIncidencia = datos.descripcionIncidencia;
			datosCasilla.solucionIncidencia = datos.solucionIncidencia;
		}

		props.datosGuardar(datos);
	};

	const handleDescargaPDF = () => {
		if((datosCasilla !== null || datosCasilla !== undefined) && (datosCasilla.fechaHoraTermino !== null || datosCasilla.fechaHoraTermino !== undefined)) {
					props.handleDescargaActa(datosCasilla);
		}
	};

	return (
		<>
			{/* <BlockLoader blocking={props.cargando} styleClass={'bloq_sobre_todo'}> */}
			<Layout className='validar_modulo consulta'>
				<div id="casillasConsultaTabla">
					<div id="seccion1">
						<Row>
							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								<span id="titulo-componente">Registro de instalación<br /></span>
								<span className="casillaConsulta">{datosCasilla.folio}</span>
							</Col>
						</Row>
						<hr id="lineaDivision" />
					</div>
					<div style={{ padding: '5px 0px' }}>
						<h5>Captura los datos requeridos. Los datos con (<b><font color="red">*</font></b>) son obligatorios.</h5>
					</div>
					<div>
						<Form form={form} layout="vertical" size="middle"
							scrollToFirstError
							onFinish={onFinish}
							initialValues={{
								horaInicio: horaInicio ? moment(horaInicio, etiquetasCasillas.FORMATO_HORA_MOMENT) : moment('00:00', 'HH:mm'),
							}}
						>
							<Row>
								<Col sm={24} md={10} lg={10} xl={10}>
									<Item label={etiquetasCasillas.datos_instalacion_lbl_responsable}
										name="nombreResponsable"
										required={true}
										rules={[{ required: true, pattern: etiquetasCasillas.REGEX_FORMATO_NOMBRES, message: etiquetasCasillas.advertencia_responsable }]}
									>
										<Input placeholder="Ingresa" size='middle' />
									</Item>
								</Col>
								<Col sm={0} md={2} lg={2} xl={2} />
								<Col sm={24} md={10} lg={10} xl={10} id="date-div">
									<Item label={etiquetasCasillas.datos_instalacion_lbl_fecha_acta}
										name="fechaActa"
										required={!fechaActa}
										rules={[{ required: !fechaActa, message: etiquetasCasillas.advertencia_fecha_acta }]}
									> 
										<DatePicker format={etiquetasCasillas.FORMATO_FECHA_MOMENT} size='middle'
											disabledDate={disabledDate}
											defaultValue={datosCasilla.fechaGeneracionActa ? moment(moment(datosCasilla.fechaGeneracionActa).format("YYYY/MM/DD")): undefined}
											disabled={fechaActa !== undefined}
											getPopupContainer={() => document.getElementById("date-div")}
											style={{ width: "175px", borderRadius: '5px' }}
										/>
									</Item>
								</Col>
							</Row>
							<div style={{ display: !casillasItinerantes ? "block" : "none" }}>
								<Row>
									<span id="titulo-componente" style={{ marginBottom: '-5px' }}>Información de casilla </span>
								</Row>
								<hr />
								<Row>
									<h4>{etiquetasCasillas.datos_ubicacion_lbl_lugar}:</h4>
								</Row>
								<Row>
									{lugarCasilla} &nbsp;
									{datosCasilla.fechaHoraInicio == null && datosCasilla.fechaHoraTermino == null ?
										<a onClick={onClikCambiarDir}>Cambiar dirección</a>
										: null
									}
								</Row>
								<br /><br />
								<div style={{ display: abrirNuevaUbicacion ? "block" : "none" }}>
									<Row>
										<span id="titulo-componente" style={{ marginBottom: '-5px' }}>Nueva ubicación </span>
									</Row>
									<hr />
									<Item label={etiquetasCasillas.datos_ubicacion_lbl_lugar}
										name="nuevoLugar"
										required={abrirNuevaUbicacion}
										rules={[{ required: abrirNuevaUbicacion, message: etiquetasCasillas.advertencia_ingresar_lugar }]}
									>
										<Input placeholder="Lugar"
											style={{ width: '500px' }}
										/>
									</Item>
								</div>
								{datosCasilla.fechaHoraInicio == null && datosCasilla.fechaHoraTermino == null ?
									<>
										<Row>
											<span id="titulo-componente" style={{ marginBottom: '-5px' }}>Fecha y hora de operación </span>
										</Row>
										<hr />
										<Row>
											<Col sm={24} md={12} lg={12} xl={5} id="datePicker-div">
												<Item label={etiquetasCasillas.datos_horarios_lbl_fecha_instalacion}
													name="fechaInicio"
													required={!fechaInicio}
													rules={[{ required: !fechaInicio, message: etiquetasCasillas.advertencia_fecha + 'inicio' }]}
												>
													<DatePicker format={etiquetasCasillas.FORMATO_FECHA_MOMENT}
														disabledDate={disabledDate}
														getPopupContainer={() => document.getElementById("datePicker-div")}
														style={{ width: '100%', borderRadius: '5px' }}
													/>
												</Item>
											</Col>
											<Col sm={0} md={12} lg={12} xl={1} />
											<Col sm={24} md={12} lg={12} xl={5} id="time-div">
												<Item label={etiquetasCasillas.datos_horarios_lbl_horario_instalacion}
													name="horaInicio"
													required={!horaInicio}
													rules={[{ required: !horaInicio, message: etiquetasCasillas.advertencia_hora + 'inicio' }]}
												>
													<TimePicker placeholder={etiquetasCasillas.FORMATO_HORA_MOMENT}
														format={etiquetasCasillas.FORMATO_HORA_MOMENT}
														style={{ width: '100%', borderRadius: '5px' }}
														getPopupContainer={() => document.getElementById("time-div")} />
												</Item>
											</Col>
											<Col sm={0} md={12} lg={12} xl={12} />
										</Row>
										<Row>
											<Col sm={24} md={12} lg={12} xl={12}>
												<Item label={etiquetasCasillas.datos_instalacion_lbl_casilla_instalada} {...horizontalFormItemLayout}
													name="instalacionCorrecta"
													required={!instalacionCorrecta}
													rules={[{ required: !instalacionCorrecta, message: etiquetasCasillas.advertencia_seleccionar_opcion }]}
												>
													<Radio.Group onChange={onChangeInstalacionCorrecta} defaultValue={instalacionCorrecta}>
														{siNoOptions.map((radio, index) => (
															<Radio key={index} value={radio.value}> {radio.respuesta}</Radio>
														))}
													</Radio.Group>
												</Item>
											</Col>
											<Col sm={24} md={12} lg={12} xl={12}>
												<Item label={etiquetasCasillas.datos_instalacion_lbl_causa_instalacion}
													name="causaInstalacion"
													required={causaInst}
													rules={[{ required: causaInst, message: etiquetasCasillas.advertencia_seleccionar_opcion }]}
												>
													<TextArea /* placeholder={etiquetasCasillas.datos_instalacion_lbl_causa_instalacion} */
														disabled={disabledCausaInst}
														autoSize={{ minRows: 2, maxRows: 8 }} maxLength={etiquetasCasillas.caracteres_Restantes}
														showCount
													/>
												</Item>
											</Col>
										</Row>
									</>
									:
									<>
										<Row>
											<span id="titulo-componente" style={{ marginBottom: '-5px' }}>Fecha y hora de operación </span>
										</Row>
										<hr />
										<Row>
											<Col sm={24} md={12} lg={8} xl={8} id="datePicker-div">
												{etiquetasCasillas.datos_horarios_lbl_fecha_instalacion}
											</Col>
											<Col sm={24} md={12} lg={8} xl={8} id="datePicker-div">
												{etiquetasCasillas.datos_horarios_lbl_horario_instalacion}
											</Col>
										</Row>
										<Row>
											<Col sm={24} md={12} lg={8} xl={8} id="datePicker-div">
												{moment(datosCasilla.fechaHoraInicio).format(etiquetasCasillas.FORMATO_FECHA_MOMENT)}
											</Col>
											<Col sm={24} md={12} lg={8} xl={8} id="datePicker-div">
												{moment(datosCasilla.fechaHoraInicio).format(etiquetasCasillas.FORMATO_HORA_MOMENT)}
											</Col>
										</Row>
										<br />
										<Row>
											<Col sm={24} md={12} lg={12} xl={12}>
												{etiquetasCasillas.datos_instalacion_lbl_casilla_instalada}
												<br />
												{datosCasilla.instalacionCorrecta === 'S' ? 'Sí' : 'No'}
											</Col>
											<Col sm={24} md={12} lg={12} xl={12}>
												{etiquetasCasillas.datos_instalacion_lbl_causa_instalacion}
												<br />
												{datosCasilla.causaInstalacion}
											</Col>
										</Row>
									</>
								}
								{/**Div para el Cierre de Casilla**/}
								<div style={{ display: cierreCasilla ? "block" : "none" }}>
									<Row>
										<span id="titulo-componente" style={{ marginBottom: '-5px' }}>Cierre de casilla </span>
									</Row>
									<hr />
									<Row>
										<Col sm={24} md={12} lg={12} xl={5} id="datePicker-div">
											<Item label={etiquetasCasillas.datos_horarios_lbl_fecha_cierre}
												name="fechaTermino"
												required={fechaTermino === undefined && cierreCasilla === true}
												rules={[{ required: fechaTermino === undefined && cierreCasilla === true, message: etiquetasCasillas.advertencia_fecha + 'cierre' }]}
											>
												<DatePicker format={etiquetasCasillas.FORMATO_FECHA_MOMENT}
													disabledDate={disabledDate}
													getPopupContainer={() => document.getElementById("datePicker-div")}
													style={{ width: '100%', borderRadius: '5px' }}
												/>
											</Item>
										</Col>
										<Col sm={0} md={12} lg={12} xl={1} />
										<Col sm={24} md={12} lg={12} xl={5} id="time-div">
											<Item label={etiquetasCasillas.datos_horarios_lbl_horarios_cierre}
												name="horaTermino"
												required={horaTermino === undefined && cierreCasilla === true}
												rules={[{ required: horaTermino === undefined && cierreCasilla === true, message: etiquetasCasillas.advertencia_hora + 'cierre' }]}
											>
												<TimePicker placeholder={etiquetasCasillas.FORMATO_HORA_MOMENT}
													format={etiquetasCasillas.FORMATO_HORA_MOMENT}
													style={{ width: '100%', borderRadius: '5px' }}
													getPopupContainer={() => document.getElementById("time-div")}
												/>
											</Item>
										</Col>
										<Col sm={0} md={12} lg={12} xl={12} />
									</Row>
									<Row>
										<Col sm={24} md={12} lg={12} xl={12}>
											<Row style={{ marginBottom: '-30px' }}>
												<Item label={etiquetasCasillas.datos_instalacion_lbl_incidencia_cierre} {...horizontalFormItemLayout}
													name="incidenciaCierre"
													required={incidenciaCierre === undefined && cierreCasilla === true}
													rules={[{ required: incidenciaCierre === undefined && cierreCasilla === true, message: etiquetasCasillas.advertencia_seleccionar_opcion }]}
												>
													<Radio.Group onChange={onChangeIncidenciaCierre} defaultValue={incidenciaCierre}>
														{siNoOptions.map((radio, index) => (
															<Radio key={index} value={radio.value}> {radio.respuesta}</Radio>
														))}
													</Radio.Group>
												</Item>
											</Row>
											<Row>
												<Item label={etiquetasCasillas.datos_instalacion_lbl_incidencia_cual}
													name="cualIncidencia"
													required={cualIncidencia && cierreCasilla}
													disabled={!cualIncidencia && !cierreCasilla}
													rules={[{ required: cualIncidencia && !cierreCasilla, message: etiquetasCasillas.advertencia_ingresar_cual }]}
												>
													<Input /* placeholder={etiquetasCasillas.datos_instalacion_lbl_incidencia_cual} */
														disabled={!cualIncidencia}
														style={{ width: '250px' }}
													/>
												</Item>
											</Row>
										</Col>
										<Col sm={24} md={12} lg={12} xl={12}>
											<Item label={etiquetasCasillas.datos_instalacion_lbl_incidencia_solucion}
												name="solucionIncidencia"
											>
												<TextArea /* placeholder={etiquetasCasillas.datos_instalacion_lbl_incidencia_solucion} */
													autoSize={{ minRows: 4, maxRows: 8 }} maxLength={etiquetasCasillas.caracteres_Restantes}
													disabled={!cualIncidencia}
													showCount
												/>
											</Item>
										</Col>
									</Row>
								</div>
							</div>

							<Row justify="center">
								<Item wrapperCol={{ span: 12, offset: 6 }}>
									<Button type="primary"
										className="btnGuardar"
										htmlType="submit">
										Guardar
									</Button>
								</Item>
							</Row>

						</Form>
					</div>
				</div>
				<Modal visible={abrirModalExitoRegistro}
							width={330}
							height={470}
							//onCancel={props.handleCancelModalExito}
							footer={null}
							closable={false}
							centered
				>
					<div id="folio-casilla">
						<img src={exitoSeguimiento} width={230} height={230} />
						<span className="modal_descripcion_accion">{mensajeCasillaRegistrada + etiquetasCasillas.etiqueta_mensaje_casilla_registrada}</span>
						<br />
						<span className="modal_informacion_extra"> {etiquetasCasillas.etiqueta_mensaje_exito_guardado}</span>
						<br />
						<Button //type="primary" 
										className="btnBorder"
										id="btnRegistroNuevo"
										onClick={props.irBandejaSeguimiento}>
							Registro nuevo
						</Button>
						&nbsp;
						{ mensajeCasillaRegistrada === "Cierre" ?
							<Button type="primary" className="btnGuardar"
											onClick={handleDescargaPDF}>
								Descargar
							</Button>
							: null}
					</div>
				</Modal>
			</Layout>
			{/* </BlockLoader> */}
		</>
	)
}

export default VInstalacionCasilla
