import React from 'react';
import * as info from '../../constants/InfoSistema';
import logoINE from '../../assets/img/logo_ine_blanco.png';
import '../../css/footer/footer.scss';

const VFooter = () =>{
  return(
      <div className='footer-container'>
        <img className='fc-image' 
            src={logoINE} 
            alt="Icono INE"/>
        <span className='fc-text'>{info.INE_COPYRIGHT}</span>
        <span className='fc-text'>{info.UNICOM}</span>
        <span className='fc-text'>{info.VERSION_SISTEMA_FOOTER}</span>
        <span className='fc-text-v'>{info.HORA_FECHA_VERSION}</span>
      </div>
  );
}

export default VFooter;