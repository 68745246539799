
export const REGEX_FORMATO_CLAVE_ELECTOR_FUAR       = /^([A-Z]{5,6}\d{8}[H|M]\d{3})$|^([0-9]{13})$/i;
export const REGEX_FORMATO_CLAVE_ELECTOR            = /^([A-Z]{5,6}\d{8}[H|M]\d{3})$/i;
export const REGEX_FORMATO_SIAP                     = /^([0-9]{13})$/i;
export const REGEX_FORMATO_NOMBRES                  = /^[A-ZÁÉÍÓÚÜÑ\'\°\.\-\/\s]*$/i;
export const REGEX_FORMATO_RFC                      = /^[A-Z]{3,4}[0-9]{6}([A-Z0-9]{3})*$/i;
export const REGEX_FORMATO_CURP                     = /^[A-Z]{4}[0-9]{6}[H|M][A-Z]{5}[A-Z0-9][0-9]$/i;
export const REGEX_FORMATO_CORREO                   = /^[_A-Z0-9-\+]+(\.[_A-Z0-9-]+)*@[A-Z0-9-]+(\.[A-Z0-9]+)*(\.[A-Z]{2,})$/i;
export const REGEX_FORMATO_TELEFONO                 = /^\d{8,10}$/i;
export const REGEX_FORMATO_NO_CARACTERES_ESPECIALES = /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i;
export const REGEX_FORMATO_CP                       = /^\d{5,5}$/i;
export const REGEX_FORMATO_EDAD                       = /^\d{1,2}$/i;
export const REGEX_FORMATO_NUMERO_DIRECCION         = /^[^\|\\\u00B4\"\<\>\@\&\=\(\)?!¡¿+*:;_-]*$/i;

export const REGEX_FORMATO_SOLO_DIGITOS             = /^\d*$/i;
export const FORMATO_FECHA_MOMENT                  = 'DD/MM/YYYY';
export const FORMATO_HORA_MOMENT                   = 'HH:mm:ss';
