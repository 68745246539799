import vistas from '../constants/routers/views';
import * as rutas from '../constants/routers/paths';

const HOME = {
  component : vistas.CHome,
  path: rutas.PATH_LOGIN,
  isPrivate: false
};

const HOME_INT = {
  component : vistas.CHomeInt,
  path: rutas.PATH_HOME_INT,
  isPrivate: true
};

const LOGOUT = {
  component : vistas.CLogout,
  path: rutas.PATH_LOGOUT,
  isPrivate: false
};

const REGISTRO_CASILLA = {
  component : vistas.CRegistroCasillas,
  path: rutas.PATH_REGISTRO_CASILLA,
  isPrivate: true
};

const CONSULTA_CASILLA = {
  component : vistas.CConsultaCasilla,
  path: rutas.PATH_CONSULTA_CASILLA,
  isPrivate: true
};

const SEGUIMIENTO_CASILLA = {
  component : vistas.CSeguimientoCasilla,
  path: rutas.PATH_SEGUIMIENTO_CASILLA,
  isPrivate: true
};

const INSTALACION_CASILLA = {
  component : vistas.CInstalacionCasilla,
  path: rutas.PATH_SEGUIMIENTO_CASILLA,
  isPrivate: true
};

const REGISTRO_VOLUNTARIO = {
  component : vistas.CVoluntarios,
  path: rutas.PATH_REGISTRO_VOLUNTARIO,
  isPrivate: true
};

const CONSULTA_VOLUNTARIO = {
  component : vistas.CConsultaVoluntarios,
  path: rutas.PATH_CONSULTA_VOLUNTARIO,
  isPrivate: true
};

const SEGUIMIENTO_VOLUNTARIO = {
  component : vistas.CSeguimientoVoluntarios,
  path: rutas.PATH_SEGUIMIENTO_VOLUNTARIO,
  isPrivate: true
};

const ASIGNACION_ZORE = {
  component : vistas.CZoresVoluntarios,
  path: rutas.PATH_ASIGNACION_ZORE,
  isPrivate: true
};

const ASIGNACION_CASILLA = {
  component : vistas.CAsignacionVoluntarios,
  path: rutas.PATH_ASIGNACION_CASILLA,
  isPrivate: true
};

const GAFETES = {
  component : vistas.CGafete,
  path: rutas.PATH_GAFETES,
  isPrivate: true
};

const RESULTADOS = {
  component : vistas.CPublicador,
  path: rutas.PATH_RESULTADOS,
  isPrivate: true
};

const CAPTURA_SEDES = {
  component : vistas.CCapturaSede,
  path: rutas.PATH_CAPTURA_SEDES,
  isPrivate: true
};

const CONSULTA_SEDES = {
  component : vistas.CConsultaSedes,
  path: rutas.PATH_CONSULTA_SEDES,
  isPrivate: true
};

const REGISTRO_CONVOCATORIA ={
  component : vistas.CConvocatorias,
  path: rutas.PATH_REGISTRO_CONVOCATORIA,
  isPrivate: true

};

const CONSULTA_CONVOCATORIA ={
  component : vistas.CConvocatoriasConsulta,
  path: rutas.PATH_CONSULTA_CONVOCATORIA,
  isPrivate: true

};
const CONSULTA_ASISTENCIA={
  component : vistas.CAsistenciaCapacitacion,
  path: rutas.PATH_CONSULTA_ASISTENCIA,
  isPrivate: true
};

const CONSULTA_RESULTADOS ={
  component : vistas.CResultados,
  path: rutas.PATH_RESULTADOS_CIJ,
  isPrivate: true
};

const BITACORA_JSON = {
  component : vistas.CBitacoraJson,
  path: rutas.PATH_BITACORA_JSON,
  isPrivate: true
};

export default[
  HOME,
  HOME_INT,
  LOGOUT,
  REGISTRO_CASILLA,
  CONSULTA_CASILLA,
  SEGUIMIENTO_CASILLA,
  INSTALACION_CASILLA,
  REGISTRO_VOLUNTARIO,
  CONSULTA_VOLUNTARIO,
  SEGUIMIENTO_VOLUNTARIO,
  ASIGNACION_ZORE,
  ASIGNACION_CASILLA,
  GAFETES,
  RESULTADOS,
  CAPTURA_SEDES,
  CONSULTA_SEDES,
  RESULTADOS,
  REGISTRO_CONVOCATORIA,
  CONSULTA_CONVOCATORIA,
  CONSULTA_ASISTENCIA,
  CONSULTA_RESULTADOS,
  BITACORA_JSON,
]
