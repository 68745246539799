import React from 'react';

const VCard = ({ gridArea, title, subtitle, children }) => {
    return(
        <div className='home-card-container' 
            style={{gridArea:`${gridArea}`}}>
                <div className='hcdc-header'>
                    <span className='hcdc-title'>{title}</span>
                    { subtitle ? <span className='hcdc-subtitle'>{subtitle}</span> : "" }
                </div>
            {children}
        </div>
    );
}

export default VCard;