import React from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import CTemplate from '../../homeInterno/CTemplate';
import AuthenticatedComponent from "../../../components/comunes/AuthenticatedComponent";
import * as etiquetas from '../../../constants/EtiquetasGenerales';
import * as info from '../../../constants/InfoSistema';
import VRegistroCasillas from '../../../components/casillas/registro/VRegistroCasillas';
import { createStructuredSelector } from 'reselect';
import { selectEstado, selectDistrito} from '../../../redux/menu/menu-selectors';
import { selectCurrentUser} from '../../../redux/user/user-selectors';
import { obtenerEstatusModulo } from '../../../redux/menu/menu-utils';
import { roles_permitidos_casillas } from '../../../constants/EtiquetasCasillas';


let msjError = "";
const jwt = localStorage.getItem('jwt-token');

class CRegistroCasillas extends React.Component{
  constructor(props) {
    super(props);

    this.newTabIndex = 0;
    

    this.state = {

        porcentaje: 0,
        casillas: [],
        infoCasillas: null,
        disableAceptar: true,
        cargado: true,
        modifica: false,    /* bandera que dice se esta accediendo desde el modifica*/
        cargando: false,
        rolConsulta: false,
        msjError : "",
        abrirModalError : false,
        abrirModalExitoRegistro: false,
        abrirModalExitoModifica: false,
        estatusModulo: false,
        lstMunicipios: [],
        lstSecciones: [],
        lstTipoCasilla: [],
        lstAsignarZore: [],
        selectMunicipio: null,
        selectSeccion: null,
        selectTipoCasilla: null,
        selectZore: null,
        items: [],
        fechaFI: '',
        horaHI: '',
        fechaFC: '',
        horaHC: '',
        key:0,
        activeTab: "1",
        abrirModalExitoRegistro: false,
        calle: "",
        num:"",
        cp: "",
        colonia: "",
        ubicacion:"",
        referencia:"",
        nombreContacto:"",
        telefonoC:"",
        computadoraEscritorio:"",
        telefonoInteligente:"",
        tablet:"",
        tieneInternet: "",
        totalDispositivos: "",
        suma: 0,
        activarBoton: true,
        activarBoton2: true,
        botonGuardar: true,
        cambioMunicipio: false,
        activarZonaEscolar:false,
        lstTurnosEscuelas:[],
        lstNivelesEducativos:[],
        selectTurnoEscuela:null,
        selectNivelEducativo:null,
        ubicacionEscolar:"",
        claveEscuela:"",
        nombreContactoEscuela:"",
        telefonoCEscuela:"",
        referenciaEscolar:"",
        mostrarSinGuardar:false,
        usuarioCau: false,
        fechasHorasIn: false,
        casillaItinerante:[],
        rolPermitido:false,
    };

  }

  componentDidMount(){

    this.consultarEstatusModulo();
    this.consultarPermisoUsuario();

  }

  async componentDidUpdate(prevProps) {
    if (this.props.estado !== prevProps.estado
    || this.props.distrito !== prevProps.distrito) {

      console.log("entre a recargar pagina")

      console.log("estado",this.props.estado.idEstado);
      console.log("distrito",this.props.distrito.idDistrito);

        this.getMunicipios();
        this.getTipoCasilla();
        this.getTurnosEscuelas();
        this.getNivelesEducativos();

        if(this.props.estado.idEstado !== 0 && this.props.distrito.idDistrito !== 0){
            window.location.reload();
        }

    }
  }

 

  consultarEstatusModulo (){

    var idDetalleProceso = this.props.user.idDetalle;
    var idProcesoElectoral = this.props.user.idProcesoElectoral;
    var idEstado = this.props.estado.idEstado;
    var idDistrito = this.props.distrito.idDistrito;
    var idSistema = info.ID_SISTEMA;
    var idModulo = this.props.location.state !== undefined ?
                   this.props.location.state.idModulo : 0;
    var grupoSistema = this.props.user.rolUsuario;

    var index = grupoSistema.search("CAU.OC");

    if(index >= 0){
      this.setState({
        usuarioCau:true
      }, ()=>console.log(true))
    }else{
      console.log("tiene otro tipo de rol")
    }



      obtenerEstatusModulo(idProcesoElectoral, idDetalleProceso,idSistema,
        idEstado, idDistrito, idModulo, grupoSistema).then(respuesta =>{
        console.info("El estatus del módulo Consulta es: ",respuesta);
        if(respuesta === "A"){
        console.info("Modulo registra casillas esta abierto");
        this.setState({
          estatusModulo: true
        });
        this.getMunicipios();
        this.getTipoCasilla();
        this.getTurnosEscuelas();
        this.getNivelesEducativos();

      }else{
        console.info("El Módulo Consulta está cerrado ");
      }
    })

  }

  consultarPermisoUsuario = () =>{
    this.setState({
      rolPermitido : roles_permitidos_casillas.includes(this.props.user.rolUsuario)
    }, ()=>console.log("rolpermitido", this.state.rolPermitido));
  }

  selectMuncipios = (valor) => {
    if(valor != null){

      this.state.selectMunicipio = valor;
      this.getSecciones();

    }else{
      this.state.selectMunicipio = null;
    }

  }

  selectSeccion = (valor) => {
      console.log("Entro para asignar seccion");
    if(valor != null){
      this.state.selectSeccion = valor;
      this.getAsignarZores();
    }else{
      this.state.selectSeccion = null;
    }

  }

selectTipoCasilla = (valor) => {
    console.log("Entro para asignar tipo casilla");
  if(valor != null){
    this.state.selectTipoCasilla = valor;

    if(valor === 1 || valor === 2){
      this.state.activarZonaEscolar = true;
    }else{
      this.state.activarZonaEscolar = false;
    }

    if(this.state.selectTipoCasilla === 2){
        console.log("Entre a setear campos")
        this.state.calle = "";
        this.state.num = "";
        this.state.cp = "";
        this.state.colonia = "";
    }

  }else{
    this.state.selectTipoCasilla = null;
  }

}

selectZore = (valor) => {
    console.log("Entro para asignar zores");
  if(valor != null){
    this.state.selectZore = valor;

  }else{
    this.state.selectZore = null;
  }
}

selectTurnoEscuela = (valor) => {
  console.log("Entro para Turno Escuela");
  if(valor != null){
    this.state.selectTurnoEscuela = valor;

  }else{
    this.state.selectTurnoEscuela = null;
  }
}


selectNivelEducativo = (valor) => {
  console.log("Entro para asignar zores");
if(valor != null){
  this.state.selectNivelEducativo = valor;

}else{
  this.state.selectNivelEducativo = null;
}
}

ocultarModalSinGuardar = () => {
  this.setState({
    mostrarSinGuardar: false
  })
};

salirsinGuardar = () => {

  this.state.mostrarSinGuardar = false;
  window.location.reload();

}


  enviarServidor = () => {

    let casilla = {};

    if(this.state.selectTipoCasilla === 1 || this.state.selectTipoCasilla === 2 || this.state.selectTipoCasilla === 3){

    casilla = {
        idDetalleProceso: info.ID_DETALLE_PROCESO,
        idProcesoElectoral: info.ID_PROCESO_ELECTORAL,
        idEstado: this.props.estado.idEstado,
        idDistrito: this.props.distrito.idDistrito,
        usuario: this.props.user.username,
        idMunicipio: this.state.selectMunicipio,
        seccion: this.state.selectSeccion,
        idTipoCasilla: this.state.selectTipoCasilla,
        idZonaResponsabilidad: this.state.selectZore,
        calle: this.state.calle,
        numero: this.state.num,
        cp: this.state.cp,
        colonia: this.state.colonia,
        ubicacion: this.state.ubicacionEscolar,
        referencia: this.state.referenciaEscolar,
        nombreContacto: this.state.nombreContactoEscuela,
        telefonoC: this.state.telefonoCEscuela,
        computadoraEscritorio: this.state.computadoraEscritorio === "" ? 0 : this.state.computadoraEscritorio,
        telefonoInteligente: this.state.telefonoInteligente === "" ? 0 : this.state.telefonoInteligente,
        tablet: this.state.tablet === "" ? 0 : this.state.tablet,
        tieneInternet: this.state.tieneInternet,
        claveEscuela: this.state.claveEscuela,
        idTurnoEscuela: this.state.selectTurnoEscuela,
        idNivelEducativo: this.state.selectNivelEducativo,
        fechasHorasCasilla: this.state.items,
      };

    }else if (this.state.selectTipoCasilla === 4 || this.state.selectTipoCasilla === 5 ){

      casilla = {
        idDetalleProceso: info.ID_DETALLE_PROCESO,
        idProcesoElectoral: info.ID_PROCESO_ELECTORAL,
        idEstado: this.props.estado.idEstado,
        idDistrito: this.props.distrito.idDistrito,
        usuario: this.props.user.username,
        idMunicipio: this.state.selectMunicipio,
        seccion: this.state.selectSeccion,
        idTipoCasilla: this.state.selectTipoCasilla,
        idZonaResponsabilidad: this.state.selectZore,
        calle: this.state.calle,
        numero: this.state.num,
        cp: this.state.cp,
        colonia: this.state.colonia,
        ubicacion: this.state.ubicacion,
        referencia: this.state.referencia,
        nombreContacto: this.state.nombreContacto,
        telefonoC: this.state.telefonoC,
        computadoraEscritorio: this.state.computadoraEscritorio === "" ? 0 : this.state.computadoraEscritorio,
        telefonoInteligente: this.state.telefonoInteligente === "" ? 0 : this.state.telefonoInteligente,
        tablet: this.state.tablet === "" ? 0 : this.state.tablet,
        tieneInternet: this.state.tieneInternet,
        casillaItinerante: this.state.casillaItinerante,
        fechasHorasCasilla: this.state.items,
      };

    }else{

      casilla = {
        idDetalleProceso: info.ID_DETALLE_PROCESO,
        idProcesoElectoral: info.ID_PROCESO_ELECTORAL,
        idEstado: this.props.estado.idEstado,
        idDistrito: this.props.distrito.idDistrito,
        usuario: this.props.user.username,
        idMunicipio: this.state.selectMunicipio,
        seccion: this.state.selectSeccion,
        idTipoCasilla: this.state.selectTipoCasilla,
        idZonaResponsabilidad: this.state.selectZore,
        calle: this.state.calle,
        numero: this.state.num,
        cp: this.state.cp,
        colonia: this.state.colonia,
        ubicacion: this.state.ubicacion,
        referencia: this.state.referencia,
        nombreContacto: this.state.nombreContacto,
        telefonoC: this.state.telefonoC,
        computadoraEscritorio: this.state.computadoraEscritorio === "" ? 0 : this.state.computadoraEscritorio,
        telefonoInteligente: this.state.telefonoInteligente === "" ? 0 : this.state.telefonoInteligente,
        tablet: this.state.tablet === "" ? 0 : this.state.tablet,
        tieneInternet: this.state.tieneInternet,
        fechasHorasCasilla: this.state.items,
      };
    }
     
    //console.log(casilla);

    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': this.props.user ? this.props.user.tknJWT : null
    };

    if(this.state.modifica ===  false){
        console.log(casilla);
    }

    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL +
            "ws/registra_casilla"
        , casilla
        , {
            headers
        }
    ).then(_response => {
        this.setState({abrirModalExitoRegistro: true})
    }).catch(error => {
        this.setState({cargando: false});
        console.error("Error al guardar la casilla "+
            "enviarServidor()",error);
        if (error.response){
            if (error.response.status === 409){
                msjError = error.response.data.message;
            } else if (error.response.data && error.response.data.causa
                && error.response.data.causa.includes("Error de validación")){
                msjError = error.response.data.message;
            } else {
                msjError = etiquetas.gral_error_cau;
            }
        } else {
            msjError = etiquetas.gral_error_cau;
        }
        this.setState({
          msjError : msjError,
          abrirModalError : true
        });
    });
    }


getMunicipios = () => {

    var postData = {
        idEstado: this.props.estado.idEstado,
        idDistrito: this.props.distrito.idDistrito,
    }


    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + 'ws/municipios'
        , postData,
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.user ? this.props.user.tknJWT : null
            }
        }
    )
    .then(res => {
        const municipios = res.data.infoMunicipios;
        if(res.data.code === 200){
        this.setState({lstMunicipios: municipios,
                       mensajeAlerta : null,
                       cargando : false,
                       mostrarAlerta : {display: 'none'},
                    });}

    }).catch(error => {
        console.error("Error al obtener los municipios de Casillas",error);
    });
}

getSecciones = () => {
    const Json = {
        idEstado:parseInt(this.props.estado.idEstado),
        idDistrito: parseInt(this.props.distrito.idDistrito),
        idMunicipio: this.state.selectMunicipio
    };

    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + 'ws/secciones'
      , Json,
      {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': this.props.user ? this.props.user.tknJWT : null
        }
      }
   ).then(res => {
    const secciones = res.data.infoSecciones;
    this.setState({lstSecciones: secciones,
                   mensajeAlerta : null,
                   cargando : false,
                   mostrarAlerta : {display: 'none'},
                });

    }).catch(error => {
    console.error("Error al obtener las secciones de Casillas",error);
    });

}

getTipoCasilla = () => {

    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + 'ws/tipo_casilla'
    , {},
    {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': this.props.user ? this.props.user.tknJWT : null
        }
    }
  ).then(res => {
    const tipoCasilla = res.data;
    this.setState({lstTipoCasilla: tipoCasilla,
                   mensajeAlerta : null,
                   cargando : false,
                   mostrarAlerta : {display: 'none'},
                });

    }).catch(error => {
    console.error("Error al obtener los tipos de casilla en registro",error);
    });

}

getAsignarZores = () =>{

    console.log("Entro en asiganar Zores");
      const Json = {
          idEstado:parseInt(this.props.estado.idEstado),
          idEstadoUsuario: 1,
          seccion: this.state.selectSeccion
        };

        axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + 'ws/obtenerZores'
          , Json,
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.user ? this.props.user.tknJWT : null
            }
          }
       ).then(res => {
        const zores = res.data.infoZonasResponsabilidad;
        this.setState({lstAsignarZore: zores,
                       mensajeAlerta : null,
                       cargando : false,
                       mostrarAlerta : {display: 'none'},
                    });

        }).catch(error => {
        console.error("Error al obtener los zores en registro",error);
        });

}

getTurnosEscuelas = () =>{

  axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + 'ws/turnosEscuelas'
    , {},
    {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': this.props.user ? this.props.user.tknJWT : null
        }
    }
  ).then(res => {
    const turnosEscuelas = res.data;
    this.setState({lstTurnosEscuelas: turnosEscuelas,
                   mensajeAlerta : null,
                   cargando : false,
                   mostrarAlerta : {display: 'none'},
                });

    }).catch(error => {
      console.error("Error al obtener los tipos de casilla en registro",error);
    });

}

getNivelesEducativos = () =>{

  axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + 'ws/nivelesEducativos'
    , {},
    {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': this.props.user ? this.props.user.tknJWT : null
        }
    }
  ).then(res => {
    const nivelesEducativos = res.data;
    this.setState({lstNivelesEducativos: nivelesEducativos,
                   mensajeAlerta : null,
                   cargando : false,
                   mostrarAlerta : {display: 'none'},
                });

    }).catch(error => {
      console.error("Error al obtener los tipos de casilla en registro",error);
    });

}

cambioText = (e) =>{
    this.setState({
        [e.target.name]: e.target.value
    })
}

onChangeFI = (dateString) => {
    this.setState({fechaFI: dateString},()=>console.log(this.state.fechaFI))
}

onChangeFC = (dateString) => {
    this.setState({fechaFC: dateString},()=>console.log(this.state.fechaFC))
}

onChangeTimeHI = (timeString) => {
    this.setState({horaHI: timeString},()=>console.log(this.state.horaHI))
}

onChangeTimeHC = (timeString) => {
    this.setState({horaHC: timeString},()=>console.log(this.state.horaHC))
}

handleClick = (ky, fechaI, fechaC, horaI, horaC) => {

    console.log("Entre a handle click")

    var items = this.state.items;

    this.state.items.push({
        key: ky,
        fechaFI: fechaI,
        horaHI: horaI,
        fechaFC: fechaC,
        horaHC: horaC,
    });

    console.log(items);

}

changeTab = activeKey => {
    console.log(activeKey);
    this.setState({
      activeTab: activeKey
    });
};

actualizarTabla = () =>{
    this.setState({
        items: this.state.items
    }, ()=>console.log(this.state.items))
}

changeCE = (e) =>{
    this.setState({
        computadoraEscritorio: e
    }, ()=>console.log(this.state.computadoraEscritorio))
}

changeTI = (e) =>{
    this.setState({
        telefonoInteligente: e
    }, ()=>console.log(this.state.telefonoInteligente))
}

changeT = (e) =>{
    this.setState({
        tablet: e
    }, ()=>console.log(this.state.tablet))
}

opcionSN = (e) =>{
    this.setState({
        tieneInternet: e.target.value
    })
    this.revisionSeleccion();
}

onChangeRoute =()=>{
    console.log("Entre a cambio de ruta")
    this.setState({abrirModalExitoRegistro: false});
    window.location.reload();
}

onChangeRouteModulo =()=>{
  console.log("Entre a cambio de ruta")
  this.setState({abrirModalExitoRegistro: false});
  this.props.history.push('/modulo/1');
}

handleDelete = (key) => {
    const items = [...this.state.items];
    this.setState({
      items: items.filter((item) => item.key !== key),
    });

    this.revisionFecha();
};

fechaHoraIn = (val) => {

  this.setState({
    fechasHorasIn: val
  }, ()=>console.log(true))

}

revisionSeleccion = () =>{

    var seleccion = this.state.tieneInternet;

    if(seleccion === ''){
        this.setState({
            activarBoton2: true
        }, () => console.log(this.state.activarBoton2))
    }else{
        this.setState({
            activarBoton2: false
        }, () => console.log(this.state.activarBoton2))
    }

}

revisionFecha = () =>{

    var cantidadFechas = this.state.items;

    console.log(cantidadFechas.length )

    if(cantidadFechas.length >= 1){
        this.state.botonGuardar = false;
    }else{
        this.state.botonGuardar = true;
    }
}

onChangeTabItinerante = activeKey => {
  this.setState({ activeKey });
};

onEditTabItinerante = (targetKey, action) => {
  this[action](targetKey);
};

/*addDireccionItinerante = (calle,num,codigoPostal,colonia,lugar,referencia,telC,
                nombreC) => {
  console.log("el formujlario",this.state);
  const { panes } = this.state;
  const activeKey = `newTab${this.newTabIndex++}`;
  
};*/

remove = targetKey => {
  let { activeKey } = this.state;
  let lastIndex;
  this.state.panes.forEach((pane, i) => {
    if (pane.key === targetKey) {
      lastIndex = i - 1;
    }
  });
  const panes = this.state.panes.filter(pane => pane.key !== targetKey);
  if (panes.length && activeKey === targetKey) {
    if (lastIndex >= 0) {
      activeKey = panes[lastIndex].key;
    } else {
      activeKey = panes[0].key;
    }
  }
  this.setState({ panes, activeKey });
};

datosCasillaitinerante = (listaUbicacion) =>{
  this.setState({
    casillaItinerante: listaUbicacion.casillaItinerante
  }, () => console.log(this.state.casillaItinerante));

  console.log(this.state.casillaItinerante);

}

  render(){
    return(
      <>
        <AuthenticatedComponent  >
          <CTemplate
            bloqueado = {false}
            contenido = {
                <VRegistroCasillas
                    municipios = {this.state.lstMunicipios}
                    selectMunicipio = {this.selectMuncipios}
                    secciones = {this.state.lstSecciones}
                    tipoCasillas = {this.state.lstTipoCasilla}
                    asignarZores = {this.state.lstAsignarZore}
                    selectSeccion = {this.selectSeccion}
                    selectTipoCasilla = {this.selectTipoCasilla}
                    selectZore = {this.selectZore}
                    onChangeFI = {this.onChangeFI}
                    onChangeFC = {this.onChangeFC}
                    onChangeTimeHI = {this.onChangeTimeHI}
                    onChangeTimeHC = {this.onChangeTimeHC}
                    items = {this.state.items}
                    handleClick = {this.handleClick}
                    changeTab = {this.changeTab}
                    activeTab = {this.state.activeTab}
                    actualizarTabla = {this.actualizarTabla}
                    abrirModalExitoRegistro = {this.state.abrirModalExitoRegistro}
                    onChangeRoute = {this.onChangeRoute}
                    enviarServidor = {this.enviarServidor}
                    cambioText = {this.cambioText}
                    changeCE = {this.changeCE}
                    changeTI = {this.changeTI}
                    changeT = {this.changeT}
                    opcionSN={this.opcionSN}
                    municipioSelect = {this.state.selectMunicipio}
                    seccionSelect = {this.state.selectSeccion}
                    tipoCasillaSelect = {this.state.selectTipoCasilla}
                    tieneInternet = {this.state.tieneInternet}
                    fila = {this.state.key}
                    fechaI = {this.state.fechaFI}
                    fechaC = {this.state.fechaFC}
                    horaI = {this.state.horaHI}
                    horaC = {this.state.horaHC}
                    handleDelete = {this.handleDelete}
                    onFinish = {this.onFinish}
                    disabledTabTwo = {this.state.activarBoton2}
                    botonGuardar = {this.state.botonGuardar}
                    dispCE = {this.state.computadoraEscritorio}
                    dispTI = {this.state.telefonoInteligente}
                    dispT = {this.state.tablet}
                    modifica = {this.state.modifica}
                    activarZonaEscolar = {this.state.activarZonaEscolar}
                    lstTurnosEscuelas = {this.state.lstTurnosEscuelas}
                    lstNivelesEducativos = {this.state.lstNivelesEducativos}
                    selectTurnoEscuela = {this.selectTurnoEscuela}
                    selectNivelEducativo = {this.selectNivelEducativo}
                    onChangeRouteModulo={this.onChangeRouteModulo}
                    ocultarModalSinGuardar = {this.ocultarModalSinGuardar}
                    mostrarSinGuardar = {this.state.mostrarSinGuardar}
                    salirSinGuardar = {this.salirsinGuardar}
                    rolCau = {this.state.usuarioCau}
                    fechaHoraIn = {this.fechaHoraIn}
                    banderaAgregar = {this.state.fechasHorasIn}
                    onChangeTabItinerante = {this.onChangeTabItinerante}
                    activeKey = {this.state.activeKey}
                    onEditTabItinerante = {this.onEditTabItinerante}
                    panes = {this.state.panes}
                    addDireccionItinerante = {this.addDireccionItinerante}
                    datosCasillaItinerante = {this.datosCasillaitinerante}
                    casillaItinerante = {this.state.casillaItinerante}
                    rolPermitido = {this.state.rolPermitido}
 
                />
            }
              guia={false}/>
            </AuthenticatedComponent>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
    estado: selectEstado,
    distrito: selectDistrito,
    user: selectCurrentUser
});

export default connect(mapStateToProps)(CRegistroCasillas);
