import React from 'react';

import {Layout, Tabs,Row, Col, Modal,Tooltip, Form,Alert } from 'antd';
import * as etiquetasVoluntarios from '../../constants/EtiquetasVoluntarios';
import CDocumentacion from "../../containers/voluntarios/CDocumentacion";
import VDatosPersonales from "../../components/voluntarios/registro/VDatosPersonales";
import VDomicilio from "../../components/voluntarios/registro/VDomicilio";
import * as etiquetaDocu from  '../../constants/EtiquetasDocumentacion';
import '../../css/voluntarios/voluntarios.css';

const VVoluntarios = (props) =>{
  const { TabPane } = Tabs;

  return(
    <Layout className='validar_modulo'>
      <Alert
        message={props.msjAlerta}
        type="warning"
        showIcon
        closable
        className ={!props.mostrarAdvertencia ? "showAdvRegistro" : "hideAdvRegistro"}/>
      <div id="formulario-registro">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {
              !props.editar
              ?<h1 id="titulo-componente">
                {etiquetasVoluntarios.titulo_componente}
              </h1>
              :<h1 id="titulo-componente">
                {etiquetasVoluntarios.titulo_componente_modifica}
              </h1>
            }
          </Col>
          {
            props.editar ?
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <h1 id="titulo-folio">
              Folio {props.folio}
            </h1>
          </Col> : ""
          }
        </Row>
        <Tabs defaultActiveKey="1" centered={true} size="large"
              className="pestanaRegistro" activeKey={props.pestanaActiva}
              onChange={props.cambioPestana}>

          <TabPane tab="Datos personales" key="1" disabled>
            <Row id="div-texto-obligatorio">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <p id="nota-requeridos">
                  Captura los datos requeridos. Los datos con (
                    <span id="obligatorio">*</span>) son obligatorios.
                </p>
              </Col>
            </Row>
            <VDatosPersonales
              lenguaIndigena = {props.lenguaIndigena}
              hablaLenguaIndigena = {props.hablaLenguaIndigena}
              revisaCampoPersonales = {props.revisaCampoPersonales}
              telefonoObli = {props.telefonoObli}
              celularObli = {props.celularObli}
              apellidoUnoObli = {props.apellidoUnoObli}
              apellidoDosObli = {props.apellidoDosObli}
              telefenoEsObligatorio = {props.telefenoEsObligatorio}
              celularEsObligatorio = {props.celularEsObligatorio}
              apellidoUnoEsObligatorio = {props.apellidoUnoEsObligatorio}
              apellidoDosEsObligatorio = {props.apellidoDosEsObligatorio}
              fechaNacimiento = {props.fechaNacimiento}
              edad = {props.edad}
              tipoVoluntario = {props.tipoVoluntario}
              claveElector = {props.claveElector}
              nombre = {props.nombre}
              apellidoP  = {props.apellidoP}
              apellidoM  = {props.apellidoM}
              genero = {props.genero}
              correo = {props.correo}
              telefono = {props.telefono}
              celular = {props.celular}
              hablaIndigena = {props.hablaIndigena}
              queLengua = {props.queLengua}
              avisoPrivacidad = {props.avisoPrivacidad}
              buscarClaveElector = {props.buscarClaveElector}
              claveEncontrada = {props.claveEncontrada}
              idVoluntario = {props.idVoluntario}
              editar = {props.editar}
              mostrarAdvertencia = {props.mostrarAdvertencia}
              refrescarAdvertencia = {props.refrescarAdvertencia}
              msjAlerta = {props.msjAlerta}
              claveElectorBuscada = {props.claveElectorBuscada}
              abrirAvisoPrivacidad = {props.abrirAvisoPrivacidad}
              mostrarAvisoModal = {props.mostrarAvisoModal}
              cerrarAviso = {props.cerrarAviso}
              curp = {props.curp}
              experiencia = {props.experiencia}
            />
          </TabPane>
          <TabPane tab="Domicilio" key="2" disabled>
            <Row id="div-texto-obligatorio">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <p id="nota-requeridos">
                  Captura los datos requeridos. Los datos con (
                    <span id="obligatorio">*</span>) son obligatorios.
                </p>
              </Col>
            </Row>
            <VDomicilio
              idVoluntario = {props.idVoluntario}
              revisaCampoDomicilio = {props.revisaCampoDomicilio}
              listaEstados = {props.listaEstados}
              listaMunicipios = {props.listaMunicipios}
              calle = {props.calle}
              numeroInt = {props.numeroInt}
              numeroExt = {props.numeroExt}
              colonia = {props.colonia}
              estado = {props.estado}
              municipio = {props.municipio}
              cp = {props.cp}
              regresar = {props.regresar}
              editar = {props.editar}
            />
          </TabPane>
          <TabPane tab="Documentos" key="3" disabled>
            <Row id="div-texto-obligatorio">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Tooltip title={etiquetaDocu.tooltip_ayuda_formato}>
                <p id="nota-requeridos">
                  Captura los datos requeridos. Los datos con (
                    <span id="obligatorio">*</span>) son obligatorios.
                </p>
                </Tooltip>
              </Col>
            </Row>
            <CDocumentacion
              idVoluntario = {props.idVoluntario}
              regresar = {props.regresar}
              idParticipacion = {props.idParticipacion}
              seccion = {props.seccion}
              idConvocatoria = {props.idConvocatoria}
              folio = {props.folio}
              estatusRegistro = {props.estatusRegistro}
              folioAcceso = {props.folioAcceso}
              idSede = {props.idSede}
              observaciones = {props.observaciones}
              fechaHoraCapacitacion = {props.fechaHoraCapacitacion}
              fechaRegistro = {props.fechaRegistro}
              idVoluntario = {props.idVoluntario}
              calle = {props.calle}
              numeroInt = {props.numeroInt}
              numeroExt = {props.numeroExt}
              colonia = {props.colonia}
              estado = {props.estado}
              municipio = {props.municipio}
              cp = {props.cp}
              lenguaIndigena = {props.lenguaIndigena}
              hablaLenguaIndigena = {props.hablaLenguaIndigena}
              fechaNacimiento = {props.fechaNacimiento}
              edad = {props.edad}
              tipoVoluntario = {props.tipoVoluntario}
              claveElector = {props.claveElector}
              nombre = {props.nombre}
              apellidoP  = {props.apellidoP}
              apellidoM  = {props.apellidoM}
              genero = {props.genero}
              correo = {props.correo}
              telefono = {props.telefono}
              celular = {props.celular}
              hablaIndigena = {props.hablaIndigena}
              queLengua = {props.queLengua}
              editar = {props.editar}
              curp = {props.curp}
              experiencia = {props.experiencia}
              />
          </TabPane>
        </Tabs>
      </div>
    </Layout>
  )
}
export default VVoluntarios;
