import React from 'react';
import { InfoCircleOutlined, RightOutlined } from '@ant-design/icons'
import *  as etiquetas from '../../constants/EtiquetasPublicador';

const VCotas = ({ cotas, isOpenCotas, handleChange }) => {
    return(
            <div className='reporte-cotas-container'>
                <div className='rcc-icon-close'
                    style={{display:`${isOpenCotas ? 'none': 'flex'}`}}
                    onClick={handleChange} >
                    <InfoCircleOutlined />
                </div>
                <div className='rcc-icon-open'
                    style={{display:`${isOpenCotas ? 'flex': 'none'}`}}
                    onClick={handleChange}>
                    <RightOutlined />
                </div>
                <div className='rcc-data'
                    style={{display:`${isOpenCotas ? 'flex': 'none'}`}}>
                    <div className='rcc-data-title'>
                        <InfoCircleOutlined />
                        <span className='rcc-data-title-text'>{etiquetas.COTAS_TITLE}</span>
                    </div>
                    {
                        cotas.map((cotaGroup, index) => {
                            return(
                                <div className='rcc-data-group' key={index}>
                                    <span className='rcc-data-group-title' >{cotaGroup.title}</span>
                                    {
                                    cotaGroup.content.map((cota, index) => {
                                        return(
                                            <div className='rcc-data-group-content' key={index}>
                                                <span>{cota.label}</span>
                                                <span>{cota.value}</span>
                                            </div>
                                        );
                                    })
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
}

export default VCotas;