import React, {useEffect} from 'react';
import {Layout, Row, Col, Menu, Radio, Space, Checkbox, Button, Tooltip, Select, Form} from 'antd';
import {RightOutlined} from '@ant-design/icons';


import VCard from '../homePortal/VCard'
import VGraficas from '../../components/Resultados/VGraficas.js';
import VComparaGraficas from '../../components/Resultados/VComparaGraficas.js';
import VEstados from '../Resultados/VEstados.js';
import VParticipantesXSexo from './VParticipantesXSexo';
import iconoQuestion from '../../assets/img/icono_question.png';
import iconoPDF from '../../assets/img/icono_pdf.png';
import iconoJPEG from '../../assets/img/icono_jpeg.png';
import * as etiquetasResultados from '../../constants/EtiquetasResultados';
import "../../css/resultados/resultados.css";
import "../../css/resultados/resultadosMediaScreen.css";
const { SubMenu } = Menu;
const {Content, Sider } = Layout;
const { Option } = Select;

/*** lista d eestados seleccionados ******/

/* Manejo de lista de estados seleccionados */
const VResultados = (props) => {

    const [filtrosForm] = Form.useForm();
    const [preguntaForm] = Form.useForm();

    useEffect(() => {
      console.log("effect",props);
    });

    const cambioEdad = (edad) =>{
      console.log("valor",filtrosForm.getFieldValue("estados"));
      filtrosForm.setFieldsValue({"seccion":undefined,
          "estados":undefined,"municipios":undefined});
      preguntaForm.setFieldsValue({"pregunta":undefined});
      if((!props.banderaJL && !props.banderaJD)){
        props.clearEstados();
      }
      props.clearMunicipios();
      props.changeRango(edad);
    };

    const cambioSeccion = (seccion) => {
      filtrosForm.setFieldsValue({"municipios":undefined});
      preguntaForm.setFieldsValue({"pregunta":undefined});
      if((!props.banderaJL && !props.banderaJD)){
        props.clearEstados();
      }
      props.clearMunicipios();
      props.changeSeccion(seccion);
    }

    const cambiarEntidad = (entidad) =>{
      console.log("pinche entidad",preguntaForm.getFieldValue("pregunta"));
      var idPregunta = preguntaForm.getFieldValue("pregunta")
      props.listaEstados(entidad)

      if(idPregunta > 0){
        console.log("voy a refrescar las preguntas");
        props.changePregunta(idPregunta)
      }

      //Se limpia la pregunta debido a que el filtro de estado ha cambiado
      preguntaForm.setFieldsValue({"pregunta":undefined});
      props.limpioCard();

    }

    const cambiarMunicipio = (mun) =>{
      //var municipiosIds = filtrosForm.getFieldValue("municipios");
      var municipiosIds = mun;
      var nombreMuns = [];
      var idMuns = [];
      var tamano = municipiosIds.length; //3;
      var idPregunta = preguntaForm.getFieldValue("pregunta");
      /*if(municipiosIds.length < 3){
        tamano = municipiosIds.length;
      }*/
      for (var i = 0; i < tamano; i++) {
        var separar  = municipiosIds[i].split("-");
        if(separar.length > 0){
          nombreMuns.push(separar[0]);
          idMuns.push(separar[1]);
        }
      }
      props.listaMunicipios(mun, nombreMuns,idMuns);
      if(idPregunta > 0){
        console.log("voy a refrescar las preguntas");
        props.changePregunta(idPregunta)
      }

      //Se limpia la pregunta debido a que el filtro de municipio ha cambiado
      preguntaForm.setFieldsValue({"pregunta":undefined});
      props.limpioCard();

    }

    return(
        <>
        <Layout  className='validar_modulo consulta'>
            <div id="seccion1">
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Layout>
                            <Sider width={240} className="site-layout-background">
                                <Row style={{backgroundColor:'#fff'}}>
                                    <Col sm={{offset:3, span:18}} md={{offset:3, span:18}} lg={{offset:3, span:18}}>
                                        <div style={{marginBottom:"15px", marginTop:"10px"}}>
                                            <span className="rText-navigation-style">Mostrar información</span>
                                        </div>
                                    </Col>
                                    <Col sm={1} md={1} lg={1}>
                                        <Tooltip title={etiquetasResultados.etiqueta_tooltip_informacion}>
                                            <img style={{width:17 , height:17}}
                                                 src={iconoQuestion}
                                                 alt="imagen"/>
                                        </Tooltip>
                                    </Col>
                                </Row>

                                <Form
                                   name="filtrosForm"
                                   form = {filtrosForm}
                                   layout = "vertical">
                                <Menu
                                    mode="inline"
                                    defaultSelectedKeys={['1']}
                                    style={{ height: 'auto', borderRight: 0 }}
                                    openKeys={props.menuAbierto}
                                    onOpenChange={props.onOpenChange}>
                                    <SubMenu key="sub1"
                                             title={etiquetasResultados.rangoEdad}
                                             className="rText-navigation-style">
                                         <Form.Item
                                           name="edad">
                                        <Radio.Group  className="estiloCuadrado"
                                              onChange={cambioEdad}>
                                            <Space direction="vertical">
                                                    <Radio className="radioEstilos" value={1}>
                                                      {etiquetasResultados.rango1}
                                                    </Radio>
                                                    <Radio className="radioEstilos" value={2}>
                                                      {etiquetasResultados.rango2}
                                                    </Radio>
                                                    <Radio className="radioEstilos"value={3}>
                                                      {etiquetasResultados.rango3}
                                                    </Radio>
                                                    <Radio className="radioEstilos"value={4}>
                                                      {etiquetasResultados.rango4}
                                                    </Radio>
                                            </Space>
                                        </Radio.Group>
                                        </Form.Item>
                                    </SubMenu>
                                    <SubMenu key="sub2"
                                             title={etiquetasResultados.seccion}
                                             className="rText-navigation-style">
                                       <Form.Item
                                         name="seccion">
                                        <Radio.Group  className="estiloCuadrado"
                                           onChange={cambioSeccion}>
                                            <Space direction="vertical"   >
                                                <Radio className="radioEstilos" value={1}>
                                                  {etiquetasResultados.seccion1}
                                                </Radio>
                                                <Radio className="radioEstilos" value={2}>
                                                  {etiquetasResultados.seccion2}
                                                </Radio>
                                                <Radio className="radioEstilos" value={3}>
                                                  {etiquetasResultados.seccion3}
                                                </Radio>
                                                <Radio className="radioEstilos" value={4}>
                                                  {etiquetasResultados.seccion4}
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                        </Form.Item>
                                    </SubMenu>
                                    <SubMenu key="sub3"
                                      title={
                                        <>
                                        <Row>
                                          <Col sm={{span:18}} md={{span:18}} lg={{span:18}}>
                                            <div>
                                              <span className="rText-navigation-style">{etiquetasResultados.estado}</span>
                                            </div>
                                          </Col>
                                          <Col sm={{offset:3, span:1}} md={{offset:3, span:1}} lg={{offset:3, span:1}}>
                                            <Tooltip title={etiquetasResultados.etiqueta_tooltip_estado}>
                                              <img style={{width:17 , height:17}}
                                                    src={iconoQuestion}
                                                    alt="imagen"/>
                                            </Tooltip>
                                          </Col>
                                        </Row>
                                        </>}
                                      className="rText-navigation-style" >
                                        <div className="scroll">
                                            <Checkbox.Group onChange={cambiarEntidad} value={props.estadosMapas}>
                                            {props.estados.map((value, index) =>{
                                                return(
                                                    <Row>
                                                        <Col>
                                                            <div style={{marginBottom:"15px"}}>
                                                              <Checkbox value={value.idEstado} className="rText-check"
                                                                        disabled={props.banderaJL || props.banderaJD}/>&nbsp;&nbsp;{value.nombreEstado}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )
                                            })}
                                            </Checkbox.Group>
                                        </div>
                                    </SubMenu>
                                    <SubMenu key="sub4"
                                      title={etiquetasResultados.municipio}
                                      className="rText-navigation-style"
                                      disabled={!props.banderaMunicipios}>
                                        <div className="scroll">

                                            <Checkbox.Group onChange={cambiarMunicipio} value={props.valoresMunicipios}>
                                            {props.municipios.map((value, index) =>{
                                                return(
                                                    <Row>
                                                        <Col>
                                                            <div style={{marginBottom:"15px"}}>
                                                              <Checkbox value={value.nombreMunicipio+"-"+value.idMunicipio}
                                                              className="rText-check"/>&nbsp;&nbsp;{value.nombreMunicipio}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )
                                            })}
                                            </Checkbox.Group>

                                        </div>
                                    </SubMenu>
                                </Menu>
                                </Form>
                            </Sider>
                            <Layout style={{ padding: '0 24px 24px' }}>
                                <br/>
                                <span className="titulo-resultados">Resultados de la Consulta Infantil y Juvenil 2021</span>
                                <Form
                                   name="preguntaForm"
                                   form = {preguntaForm}>
                                <Content
                                    className="sitio-layout-background"
                                    style={{
                                        padding: 24,
                                        margin: 0,
                                        minHeight: 280,
                                    }}>
                                    <Row>
                                      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                        <Row>
                                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                          <VCard gridArea={"preguntas"}>

                                              <div className="rPreguntas-div-style">
                                                  <div className="rDiv-style">
                                                      <Row>
                                                          <Col sm={20} md={20} lg={20}>
                                                              <span className="rFont-pregunta"><b><font color="red">*</font></b>&nbsp;Selecciona la pregunta que deseas consultar</span>
                                                          </Col>
                                                          <Col sm={4} md={4} lg={4}>
                                                              <Tooltip title={etiquetasResultados.etiqueta_tooltip_preguntas}>
                                                                  <img style={{width:17 , height:17}}
                                                                        src={iconoQuestion}
                                                                        alt="imagen"/>
                                                              </Tooltip>
                                                          </Col>
                                                      </Row>
                                                  </div>
                                                  {console.log(props,"props")
                                                  }
                                                  <div className="rDiv-style">
                                                    <Row>
                                                    <Col sm={20} md={20} lg={20}>
                                                    <Form.Item
                                                        name="pregunta" noStyle={true}>
                                                      <Select
                                                        disabled={props.rangoSelect == null
                                                           && props.seccionBoleta == null
                                                           && props.estadosMapas.length === 0}
                                                        className="rSelect-pregunta"
                                                        style= {{ width: '100%' }}
                                                        onChange={(e) => {props.changePregunta(e)}}>
                                                            {
                                                              props.jsonPreguntas &&
                                                              props.jsonPreguntas.map((elemento, index) => (
                                                                  <Option key={elemento.idPregunta}
                                                                        value={elemento.idPregunta}>
                                                                    {elemento.descripcionPregunta}
                                                                  </Option>
                                                              ))
                                                              }
                                                        </Select>
                                                      </Form.Item>
                                                    </Col>
                                                    </Row>

                                                  </div>
                                                  <Row>
                                                      <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                                                        <span id="ultimo-corte">
                                                          &Uacute;ltimo corte: {props.ultimoCorte}
                                                        </span>
                                                      </Col>
                                                      <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                                          <Button onClick={props.exportarPdf} id="boton-export-pdf">
                                                            <span className="rTexto-descargar" >
                                                              <img style={{width:17 , height:19}}
                                                                   src={iconoPDF}
                                                                   alt="imagen_pdf"/>&nbsp;PDF
                                                            </span>
                                                          </Button>
                                                      </Col>
                                                      <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                                          <Button onClick={props.exportarImg} id="boton-export-img">
                                                            <span className="rTexto-descargar" >
                                                              <img style={{width:17 , height:19}}
                                                                   src={iconoJPEG}
                                                                   alt="imagen_png"/>&nbsp;JPEG
                                                            </span>
                                                          </Button>
                                                      </Col>
                                                  </Row>
                                              </div>
                                              </VCard>
                                          </Col>
                                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <VCard gridArea={"graficas"}>
                                            {
                                              props.valorAgraficar != null ?
                                              <VGraficas
                                                valorAgraficar={props.valorAgraficar}
                                                tipoGrafica={props.tipoGrafica}
                                                preguntasDeRango={props.preguntasDeRango}
                                              />
                                              :
                                              <VComparaGraficas
                                                valoresAgraficar={props.valoresAgraficar}
                                                tipoGrafica={props.tipoGrafica}
                                                estadosAnalizar={props.estadosMapas}
                                                nomMunicipios={props.municipioSelect}
                                                preguntasDeRango={props.preguntasDeRango}
                                              />
                                            }
                                            </VCard>
                                          </Col>
                                        </Row>

                                      </Col>
                                      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                        <Row>
                                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <VCard gridArea={"estados"}>
                                                <VEstados
                                                    bandera={props.banderaMostrar}
                                                    mapas={props.estadosMapas}
                                                    nomMunicipios={props.municipioSelect}
                                                    total={props.participacionTotal}/>
                                            </VCard>
                                          </Col>
                                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <VCard gridArea={"participantesxSexo"}>
                                                <VParticipantesXSexo
                                                porcNina = {props.porcNina}
                                                porcNino = {props.porcNino}
                                                porcOtro = {props.porcOtro}
                                                porcNadie = {props.porcNadie}
                                                mostrarPorc = {props.mostrarPorc}
                                                />
                                            </VCard>
                                          </Col>
                                          </Row>
                                      </Col>
                                    </Row>
                                </Content>
                                </Form>
                            </Layout>
                        </Layout>
                    </Col>
                </Row>
            </div>
        </Layout>
        </>
    )
}

export default VResultados;
