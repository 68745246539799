import React, {useState, useForm, useEffect} from 'react';
import {Form, Layout, Tabs,Row, Col, Tooltip, Card, Input, Radio, InputNumber, TimePicker, DatePicker, Table, Button, Modal, Select, Space, Popconfirm, Popover} from 'antd';
import { ArrowRightOutlined, ArrowLeftOutlined, PlusOutlined, MinusCircleOutlined, CloseOutlined} from '@ant-design/icons';
import * as etiquetaCasilla from '../../../constants/EtiquetasCasillas';
import locale from 'antd/es/date-picker/locale/es_ES';
import iconoQuestion from '../../../assets/img/icono_question.png';
import eliminarFecha from '../../../assets/img/eliminarFecha.png';
import casillaRegistradaImg from '../../../assets/img/registro_casilla.png';
import eliminaCasilla from '../../../assets/img/eliminar_casilla.png';
import errorPop from '../../../assets/img/warning_icono.png';
import iconominus from '../../../assets/img/minus-icono.png';
import '../../../css/registraCasilla/registraCasilla.css';
import moment, { isMoment } from 'moment';
import 'moment/locale/es';


const { Option } = Select;

const { TabPane } = Tabs;

const siNoOptions = [
    { value: 'S', label: 'Si' },
    { value: 'N', label: 'No' },
];

const VRegistroCasillas = (props) =>{

    const[data, setData] = useState(props.items);
    const[valCE, setValCE] = useState();
    const[valTI, setValTI] = useState();
    const[valT, setValT] = useState();

    const[fechaIns, setFechaIns] = useState(moment('11/01/21').format("DD/MMMM/YY"));
    const[fechaCer, setFechaCer] = useState(moment('11/30/21').format("DD/MMMM/YY"));

    const[horaIns, setHoraIns] = useState(moment('09:00', "HH:mm").format("HH:mm"));
    const[horaCer, setHoraCer] = useState(moment('19:00', "HH:mm").format("HH:mm"));

    const [fechaIncorrecta, setFechaIncorrecta] = useState(false);
    const [horaIncorrecta, setHoraIncorrecta] = useState(false);

    const [conteoIFBD, setConteoIFBD] = useState(0);
    const [conteoIFBI, setConteoIFBI] = useState(0);
    
    const [message, setmessage] = useState('');

    const [ key, setKey] = useState(0);

    const [municipio, setMunicipio] = useState();
    const [seccion, setSeccion] = useState();
    const [zor, setZor] = useState();
    const [tipoCasilla, setTipoCasilla] = useState();
    const [calle, setCalle] = useState();
    const [num, setNum] = useState();
    const [codigoPostal, setCodigoPostal] = useState();
    const [colonia, setColonia] = useState();
    const [lugar, setLugar] = useState();
    const [referencia, setReferencia] = useState();
    const [telC, setTelC] = useState();
    const [nombreC, setNombreC] = useState();

    const [fvalNum, setFvalNum] = useState(false);
    const [fvalCp, setFvalCp] = useState(false);
    const [fvalTel, setFvalTel] = useState(false);
    const [fvalTelE, setFvalTelE] = useState(false);
    const [fvalCalle, setFvalCalle] = useState(false);
    const [fvalColonia, setFvalColonia] = useState(false);
    const [fvalReferencia, setFvalReferencia] = useState(false);
    const [fvalLugar, setFvalLugar] = useState(false);
    const [fvalClaveE, setFvalClaveE] = useState(false);
    const [fvalReferenciaE, setFvalReferenciaE] = useState(false);
    const [fvalLugarE, setFvalLugarE] = useState(false);
    const [fvalNombreC, setFvalNombreC] = useState(false);
    const [fvalNombreCE, setFvalNombreCE] = useState(false);
    const [valres, setValres] = useState(500);
    const [valresReferencia, setValresReferencia] = useState(500);
    const [valresReferenciaE, setValresReferenciaE] = useState(500);
    const [valresLugarE, setValresLugarE] = useState(500);


    const [turno, setTurno] = useState();
    const [nivelE, setNivelE] = useState();
    const [lugarE, setLugarE] = useState();
    const [claveE, setClaveE] = useState();
    const [referenciaE, setReferenciaE] = useState();
    const [nombreCE, setNombreCE] = useState();
    const [telefonoCE, setTelefonoCE] = useState();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [botonNext, setBotonNext] = useState(false);

    const [form] = Form.useForm();

    const clearSelected = () => {
        form.setFieldsValue({ seccion: undefined });
        setSeccion(undefined);
        form.setFieldsValue({ zore: undefined });
        setZor(undefined);
    }

    const clearSelectedZor = () => {
        form.setFieldsValue({ zore: undefined })
        setZor(undefined);
    }

    useEffect(() => {
		revisarfechas();
        revisarHorarios();
        revisarNumero();
        revisarCp();
        revisarTel();
        revisarTelE();
        revisarCalle();
        revisarCol();
        revisarReferencia();
        revisarLugar();
        revisarClaveE();
        revisarReferenciaE();
        revisarLugarE();
        revisarNombreC();
        revisarNombreCE();
	}, [fechaIns, fechaCer, horaIns, horaCer, num, codigoPostal, telC, calle, colonia, referencia, lugar, fvalColonia, fvalReferencia, fvalLugar,
        fvalCalle, fvalNum, fvalCp, fvalTel, fvalTelE, telefonoCE, claveE, fvalClaveE, referenciaE, fvalReferenciaE, lugarE, fvalLugarE, nombreC, fvalNombreC,
        fvalNombreCE, nombreCE, botonNext]);

    const revisarNumero = (e) => {

        const valor = num;
        const reg = /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i;

        if(reg.test(valor)){
            setFvalNum(true);
         }else if(!isNaN(valor) && valor.length >=5){
            setFvalNum(true);
         }else{
             setFvalNum(false);
         }
    }

    const revisarCp = (e) => {


        const valor = codigoPostal;
        const reg = /^\d*$/i;
        const reg2 = /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i; 

        if(!isNaN(valor) && reg.test(valor) && valor.length === 5 && reg2.test(valor)){
            setFvalCp(true);
        }else if(!isNaN(valor) && (valor.length >= 1 || valor.length < 5)){
            setFvalCp(false);
        }else{
            setFvalCp(false);
        }
    }

    const revisarTel = (e) => {

        const valor = telC;
        const reg = /^\d*$/i;
        const reg2 = /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i;

        if(!isNaN(valor) && reg.test(valor) && valor.length === 10 && reg2.test(valor)){
            setFvalTel(true);
        }else if (!isNaN(valor && (valor.length >= 1 || valor.length < 8))){
            setFvalTel(false);
        }
    }

    const revisarTelE = (e) => {

        const valor = telefonoCE;
        const reg = /^\d*$/i;
        const reg2 = /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i;

        if(!isNaN(valor) && reg.test(valor) && valor.length === 10 && reg2.test(valor)){
            setFvalTelE(true);
        }else{
            setFvalTelE(false);
        }
    }

    const revisarCalle = (e) => {

        const valor = calle;
        const reg = /[!¡¿´@#$%^&*_+=\\[\]{};"\\|<>\/?]+/; 

        if(reg.test(valor)){
           setFvalCalle(true);
        }else if(!isNaN(valor) && valor.length >=100){
           setFvalCalle(true);
        }else{
            setFvalCalle(false);
        }

    }

    const revisarCol = (e) => {

        const valor = colonia;
        const reg = /[!¡¿´@#$%^&*_+=\\[\]{};"\\|<>\/?]+/;

        if(reg.test(valor)){
           setFvalColonia(true);
        }else if(!isNaN(valor) && valor.length >=50){
           setFvalColonia(true);
        }else{
           setFvalColonia(false);
        }

    }

    const revisarReferencia = (e) => {

        const valor = referencia;
        const reg = /[!¡¿´@#$%^&*_+=\\[\]{}"\\|<>\/?]+/;

        if(valor!== undefined){
            setValresReferencia(500 - valor.length)
        }


        if(reg.test(valor)){
           setFvalReferencia(true);
        }else if(!isNaN(valor) && valor.length >=500){
           setFvalReferencia(true);
        }else{
           setFvalReferencia(false);
        }

    }

    const revisarLugar = (e) => {

        const valor = lugar;
        const reg = /[!¡¿´@#$%^&*_+=\\[\]{}"\\|<>\/?]+/;
        
        if(valor!== undefined){

            setValres(500 - valor.length)
        }

        if(reg.test(valor)){
           setFvalLugar(true);
        }else if(!isNaN(valor) && valor.length >=500){
           setFvalLugar(true);
        }else{
           setFvalLugar(false); 
        }

    }

    const revisarClaveE = (e) => {

        const valor = claveE;
        const reg = /[!¡¿´@#$%^&*_+=\\[\]{};':"\\|,.<>\/?]+/;

        if(reg.test(valor)){
           setFvalClaveE(true);
        }else if(!isNaN(valor) && valor.length >=20){
           setFvalClaveE(true);
        }else{
           setFvalClaveE(false);
        }

    }

    const revisarReferenciaE = (e) => {

        const valor = referenciaE;
        const reg = /[!¡¿´@#$%^&*_+=\\[\]{}"\\|<>\/?]+/;

        if(valor!== undefined){
            setValresReferenciaE(500 - valor.length)
        }

        if(reg.test(valor)){
           setFvalReferenciaE(true);
        }else if(!isNaN(valor) && valor.length >=500){
           setFvalReferenciaE(true);
        }else{
           setFvalReferenciaE(false);
        }

    }

    const revisarLugarE = (e) => {

        const valor = lugarE;
        const reg = /[!¡¿°´@#$%^&*_+=\\[\]{}"\\|<>\/?]+/;

        if(valor!== undefined){
            setValresLugarE(500 - valor.length)
        }

        if(reg.test(valor)){
           setFvalLugarE(true);
        }else if(!isNaN(valor) && valor.length >=500){
           setFvalLugarE(true);
        }else{
           setFvalLugarE(false);
        }

    }

    const revisarNombreC = (e) => {

        const valor = nombreC;
        const reg = new RegExp("^[üÑñíóáéúÁÉÍÓÚ a-zA-Z ]+$");
        const reg2 = /^\d*$/i; 

        if(reg.test(valor)){
           setFvalNombreC(true);
        }else if(valor === ''){
           setFvalNombreC(true);
        }else if (reg2.test(valor)){
            setFvalNombreC(false);
        }else{
            setFvalNombreC(false);
        }

    }

    const revisarNombreCE = (e) => {

        const valor = nombreCE;
        const reg = new RegExp("^[üÑñíóáéúÁÉÍÓÚ a-zA-Z ]+$");
        const reg2 = /^\d*$/i; 

        if(reg.test(valor)){
           setFvalNombreCE(true);
        }else if(valor === ''){
           setFvalNombreCE(true);
        }else if (reg2.test(valor)){
            setFvalNombreCE(false);
        }else{
            setFvalNombreCE(false);
        }

    }

    const columns=[
        {
          title:"Fecha de inicio",
          dataIndex: "fechaFI",
          key: "fechaFI",
        },
        {
          title:"Fecha de término",
          dataIndex: "fechaFC",
          key: "fechaFC"
        },
        {
          title:"Hora de inicio",
          dataIndex: "horaHI",
          key: "horaHI"
        },
        {
          title:"Hora de término",
          dataIndex: "horaHC",
          key: "horaHC"
        },
        {
          title:"Acciones",
          dataIndex: "acciones",
          key: "acciones",
          render: (_, record) =>
                data.length >= 1 ? (
                    <a onClick={()=> props.rolCau === true ? '': eliminar(record.key)}><img style={{width:19 , height:19}} src={eliminarFecha} id="edit-icon"/></a>
                ) : null,
        },
        
  ];

  

  function disabledDate(current) {
    return (current && current > moment(new Date('2022-01-01')).endOf('day')) || (current && current < moment(new Date('2021-11-01')).endOf('month'));
  }

  const eliminar = (key) => {
      try{

          console.log("entro en delete");
          props.handleDelete(key);
          const newData = [...data];
          setData(newData.filter((item) => item.key !== key));

      }catch(error){
        console.log("Error en eliminar valor a tabla", error)
      }
  }

  const revisarfechas = () =>{

    var fechaUno = fechaIns;
    var fechaDos = fechaCer;

    if(fechaUno !== '' || fechaDos !== ''){

        var conv1 = moment(fechaIns, 'DD/MMMM/YY');
        var conv2 = moment(fechaCer, 'DD/MMMM/YY');

        if((conv1 > conv2)){
            setFechaIncorrecta(true)
        }else{
            setFechaIncorrecta(false)
        }
    }
  }

  const revisarHorarios = () =>{

    var horaUno = horaIns;
    var horaDos = horaCer;

    if(horaUno !== '' || horaDos !== ''){

        var conv1 = moment(horaIns, 'HH:mm');
        var conv2 = moment(horaCer, 'HH:mm');

        if((conv1 >= conv2)){
            setHoraIncorrecta(true)
        }else{
            setHoraIncorrecta(false)
        }
    }
  }

  const addHorarios = () => {


      try{

        let banderaAgregar = false;
        let banderaAgregarFechas = false;
        let validador = false;
        let validadorDosMeses = false;

        //fechas y horas ingresadas y que se van a analizar
        var conv1 = moment(fechaIns, 'DD/MMMM/YY');
        var conv2 = moment(fechaCer, 'DD/MMMM/YY');
    
        var horaconv1 = moment(horaIns, 'HH:mm');
        var horaconv2 = moment(horaCer, 'HH:mm');


        //Validamos meses
        var mes1 = moment(fechaIns, 'DD/MMMM/YY').month();
        var mes2 = moment(fechaCer, 'DD/MMMM/YY').month();        


        if(mes1 === mes2){
            console.log("Son fechas del mismo mes");

            //Vamos a comparar los dias que no se encuentren dentro del rango 
            var dia1 = moment(fechaIns, 'DD/MMMM/YY').date(); //Día de inicio ingresada
            var dia2 = moment(fechaCer, 'DD/MMMM/YY').date(); //Día de término ingresada

            for(var j = 0 ; j < data.length; j++){

                let fechaR1 = data[j].fechaFI;
                let fechaR2 = data[j].fechaFC;

                let diaR1 = moment(fechaR1, 'DD/MMMM/YY').date(); //Día de inicio
                let diaR2 = moment(fechaR2, 'DD/MMMM/YY').date(); //Día de cierre
                let mesR1 = moment(fechaR1, 'DD/MMMM/YY').month();
                let mesR2 = moment(fechaR2, 'DD/MMMM/YY').month();
                let diafinmes11 = moment('30/noviembre/21', 'DD/MMMM/YY').date();
                let diainiciomes12 = moment('01/diciembre/21', 'DD/MMMM/YY').date();

                //Realizamos las 4 validaciones 
                if((mes1 === mesR1) && (mes2 === mesR2)){

                    if((diaR2 === dia1)){
                        validador = true; //Me va ayudar a validar 
                    }

                    if((diaR1 === dia2)){
                        validador = true;
                    }

                    if((diaR1 < dia1) && (dia1 < diaR2)){
                        validador = true;
                    }

                    if((diaR1 < dia2) && (dia2 < diaR2)){
                        validador = true;
                    }

                    if((dia1 < diaR1) && (diaR1 < dia2)){
                        validador = true;
                    }

                    if((dia1 < diaR2) && (diaR2 < dia2)){
                        validador = true;
                    }
                }

                //Revisar que el rango que quiero ingresar no este dentro del rango de 2 meses
                if(mesR1 !== mesR2) {

                    console.log("Tengo un rango entre dos meses");
                    
                    if((diaR2 === dia1)){
                        validador = true;
                    }

                    if((diaR1 === dia2)){
                        validador = true;
                    }

                    //Revisar los casos que se presentan

                    if((mesR1 === mes1 && mesR1 === mes2)){

                        console.log("entre a una fecha del mismo mes")

                        if((diaR1 < dia1) && (dia1 <= diafinmes11)){
                            validador = true;
                        }

                        if((diaR1 < dia2) && (dia2 <= diafinmes11)){
                            validador = true;
                        }

                    }

                    if((mesR2 === mes1 && mesR2 === mes2)){

                        if((diainiciomes12 <= dia1) && (dia1 < diaR2)){
                            validador = true;
                        }

                        if((diainiciomes12 <= dia2) && (dia2 < diaR2)){
                            validador = true;
                        }

                    }
                }

            }
        }else{
            console.log("es un rango de fechas con meses diferentes");

            //Vamos a comparar los dias que no se encuentren dentro del rango 
            var dia1 = moment(fechaIns, 'DD/MMMM/YY').date(); //Día de inicio ingresada
            var dia2 = moment(fechaCer, 'DD/MMMM/YY').date(); //Día de término ingresada

            for(var j = 0 ; j < data.length; j++){

                let fechaR1 = data[j].fechaFI;
                let fechaR2 = data[j].fechaFC;

                let diaR1 = moment(fechaR1, 'DD/MMMM/YY').date(); //Día de inicio
                let diaR2 = moment(fechaR2, 'DD/MMMM/YY').date(); //Día de cierre
                let mesR1 = moment(fechaR1, 'DD/MMMM/YY').month();
                let mesR2 = moment(fechaR2, 'DD/MMMM/YY').month();
                let diafinmes11 = moment('30/noviembre/21', 'DD/MMMM/YY').date();
                let diainiciomes12 = moment('01/diciembre/21', 'DD/MMMM/YY').date();

                //Realizamos las validaciones 
                //Fecha de inicio

                if((diaR2 === dia1)){
                    validador = true;
                }

                if((diaR1 === dia2)){
                    validador = true;
                }

                if((diaR1 < dia1) && (dia1 < diaR2)){ //Reviso que no se encuentre entre los rangos ya ingresados
                    validadorDosMeses = true;
                }

                //Fecha de término 
                if((diaR1 < dia2) && (dia2 < diaR2)){
                    validadorDosMeses = true;
                }
                
                //Hay que revisar que el rango que esta en tabla no este dentro del rango que vamos a ingresar 
                if((mes1 === mesR1) && (mes2 === mesR2)){

                    console.log("estas fechas estan dentro de un solo mes");

                    //Vamos a revisar los dias
                    if((diaR1 < dia1) && (dia1 < diafinmes11)){
                        
                        validadorDosMeses = true;

                    }

                    if((diaR1 < dia2) && (dia2 < diafinmes11)){

                        validadorDosMeses = true;
                    }

                    if((dia1 < diaR1) && (diaR1 < diafinmes11)){
                        validadorDosMeses = true;
                    }

                    if((diainiciomes12 < diaR2) && (diaR2 < dia2)){
                        validadorDosMeses = true;
                    }

                    if((diaR1 < dia1) && (dia1 < diafinmes11)){
                        validadorDosMeses = true;
                    }

                    if((diainiciomes12 < dia2) && (dia2 < diaR2)){
                        validadorDosMeses = true;
                    }


                }

            }

        }


        //Validamos las horas 
        //Vamos a realizar validaciones en las fechas 

        let diaI = moment(fechaIns, 'DD/MMMM/YY').date();
        let diaC = moment(fechaCer, 'DD/MMMM/YY').date(); 
        let mesI = moment(fechaIns, 'DD/MMMM/YY').month();
        let mesC = moment(fechaCer, 'DD/MMMM/YY').month();
        let anioI = moment(fechaIns, 'DD/MMMM/YY').year();
        let anioC = moment(fechaCer, 'DD/MMMM/YY').year();
        let diafinmes11 = moment('30/noviembre/21', 'DD/MMMM/YY').date();
        let diainiciomes12 = moment('01/diciembre/21', 'DD/MMMM/YY').date();

        for (var i = 0; i < data.length; i++){

            let fechaR1 = data[i].fechaFI;
            let fechaR2 = data[i].fechaFC;
            let horaR1 = data[i].horaHI;
            let horaR2 = data[i].horaHC;

            let diaR1 = moment(fechaR1, 'DD/MMMM/YY').date();
            let diaR2 = moment(fechaR2, 'DD/MMMM/YY').date(); 
            let mesR1 = moment(fechaR1, 'DD/MMMM/YY').month();
            let mesR2 = moment(fechaR2, 'DD/MMMM/YY').month();
            let anioR1 = moment(fechaR1, 'DD/MMMM/YY').year();
            let anioR2 = moment(fechaR2, 'DD/MMMM/YY').year();

            if((diaI === diaR1) && (mesI === mesR1) && (anioI === anioR1)) { //Reviso cuando son las mismas fechas 
                if((diaC === diaR2) && (mesC === mesR2) && (anioC === anioR2)){

                    console.log("fecha exactamente iguales")

                    //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                    if((horaIns >= horaR1) && (horaIns <= horaR2)){
                        banderaAgregar = true;
                    }

                    //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                    if((horaCer <= horaR2) && (horaCer >= horaR1)){
                        banderaAgregar = true;
                    }

                    //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                    if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                        banderaAgregar= true;
                    }

                    if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                        banderaAgregar= true;
                    }

                    if(horaR1 === horaIns){
                        if(horaR2 === horaCer){
                            banderaAgregar = true;
                        }
                    }
                }
            }

            if(validador === true){

                console.log("fechas que tienen que ser validadas sus horas")

                //Revisa Extremo
                if((diaR2 === diaC) && (mesR2 === mesC)){


                    //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                    if((horaIns >= horaR1) && (horaIns <= horaR2)){
                        banderaAgregar = true;
                    }

                    //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                    if((horaCer <= horaR2) && (horaCer >= horaR1)){
                        banderaAgregar = true;
                    }

                    //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                    if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                        banderaAgregar= true;
                    }

                    if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                        banderaAgregar= true;
                    }

                    if(horaR1 === horaIns){
                        if(horaR2 === horaCer){
                            banderaAgregar = true;
                        }
                    }   
                }

                if((mes1 === mesR1) && (mes2 === mesR1)){

                    //Fechas dentro de un rengo
                    if((diaR1 < dia1) && (dia1 <= diaR2) && (diaR1 < dia2) && (dia2 <= diaR2)){

                        //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                        if((horaIns >= horaR1) && (horaIns <= horaR2)){
                            banderaAgregar = true;
                        }

                        //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                        if((horaCer <= horaR2) && (horaCer >= horaR1)){
                            banderaAgregar = true;
                        }

                        //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                        if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                            banderaAgregar= true;
                        }

                        if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                            banderaAgregar= true;
                        }

                        if(horaR1 === horaIns){
                            if(horaR2 === horaCer){
                                banderaAgregar = true;
                            }
                        }

                    }

                    if((diaR1 < dia1) && (dia1 <= diaR2)){

                        //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                        if((horaIns >= horaR1) && (horaIns <= horaR2)){
                            banderaAgregar = true;
                        }

                        //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                        if((horaCer <= horaR2) && (horaCer >= horaR1)){
                            banderaAgregar = true;
                        }

                        //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                        if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                            banderaAgregar= true;
                        }

                        if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                            banderaAgregar= true;
                        }

                        if(horaR1 === horaIns){
                            if(horaR2 === horaCer){
                                banderaAgregar = true;
                            }
                        }

                    }

                    if((diaR1 < dia2) && (dia2 <= diaR2)){

                        //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                        if((horaIns >= horaR1) && (horaIns <= horaR2)){
                            banderaAgregar = true;
                        }

                        //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                        if((horaCer <= horaR2) && (horaCer >= horaR1)){
                            banderaAgregar = true;
                        }

                        //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                        if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                            banderaAgregar= true;
                        }

                        if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                            banderaAgregar= true;
                        }

                        if(horaR1 === horaIns){
                            if(horaR2 === horaCer){
                                banderaAgregar = true;
                            }
                        }

                    }
                
                }

                if((mes1 === mesR1) && (mes2 === mesR1)){
                    if((dia1 < diaR1) && (diaR1 <= dia2) && (dia1 < diaR2) && (diaR2 <= dia2)){

                        //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                        if((horaIns >= horaR1) && (horaIns <= horaR2)){
                            banderaAgregar = true;
                        }

                        //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                        if((horaCer <= horaR2) && (horaCer >= horaR1)){
                            banderaAgregar = true;
                        }

                        //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                        if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                            banderaAgregar= true;
                        }

                        if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                            banderaAgregar= true;
                        }

                        if(horaR1 === horaIns){
                            if(horaR2 === horaCer){
                                banderaAgregar = true;
                            }
                        }

                    }
                }


                if((diaR1 === dia2)){

                    //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                    if((horaIns >= horaR1) && (horaIns <= horaR2)){
                        banderaAgregar = true;
                    }

                    //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                    if((horaCer <= horaR2) && (horaCer >= horaR1)){
                        banderaAgregar = true;
                    }

                    //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                    if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                        banderaAgregar= true;
                    }

                    if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                        banderaAgregar= true;
                    }

                    if(horaR1 === horaIns){
                        if(horaR2 === horaCer){
                            banderaAgregar = true;
                        }
                    }

                }

                if((diaR1 === diaI) && (mesR1 === mesC)){

                    //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                    if((horaIns >= horaR1) && (horaIns <= horaR2)){
                        banderaAgregar = true;
                    }

                    //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                    if((horaCer <= horaR2) && (horaCer >= horaR1)){
                        banderaAgregar = true;
                    }

                    //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                    if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                        banderaAgregar= true;
                    }

                    if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                        banderaAgregar= true;
                    }

                    if(horaR1 === horaIns){
                        if(horaR2 === horaCer){
                            banderaAgregar = true;
                        }
                    }

                }

                //Rango entre meses
                if(mesR1 !== mesR2){
                    
                    if((dia1 < diaR1) && (diaR1 <= dia2) && (dia1 < diaR2) && (diaR2 <= dia2)){
                    //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                        if((horaIns >= horaR1) && (horaIns <= horaR2)){
                            banderaAgregar = true;
                        }

                        //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                        if((horaCer <= horaR2) && (horaCer >= horaR1)){
                            banderaAgregar = true;
                        }

                        //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                        if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                            banderaAgregar= true;
                        }

                        if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                            banderaAgregar= true;
                        }

                        if(horaR1 === horaIns){
                            if(horaR2 === horaCer){
                                banderaAgregar = true;
                            }
                        }

                    }

                    if((diaR1 < dia1) && (dia1 <= diaR2) && (diaR1 < dia2) && (dia2 <= diaR2)){
                        //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                            if((horaIns >= horaR1) && (horaIns <= horaR2)){
                                banderaAgregar = true;
                            }
    
                            //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                            if((horaCer <= horaR2) && (horaCer >= horaR1)){
                                banderaAgregar = true;
                            }
    
                            //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                            if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                                banderaAgregar= true;
                            }
    
                            if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                                banderaAgregar= true;
                            }
    
                            if(horaR1 === horaIns){
                                if(horaR2 === horaCer){
                                    banderaAgregar = true;
                                }
                            }
                    }

                    if((mes1 === mesR2) && (mes2 === mesR2)){

                        
                        if((diainiciomes12 < dia1) && (dia1 < diaR2)){
                            if((horaIns >= horaR1) && (horaIns <= horaR2)){
                                banderaAgregar = true;
                            }

                            //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                            if((horaCer <= horaR2) && (horaCer >= horaR1)){
                                banderaAgregar = true;
                            }

                            //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                            if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                                banderaAgregar= true;
                            }

                            if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                                banderaAgregar= true;
                            }

                            if(horaR1 === horaIns){
                                if(horaR2 === horaCer){
                                    banderaAgregar = true;
                                }
                            }

                    }} 

                    if((mes1 === mesR1) && (mes2 === mesR1)){

                        if((horaIns >= horaR1) && (horaIns <= horaR2)){
                            banderaAgregar = true;
                        }

                        //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                        if((horaCer <= horaR2) && (horaCer >= horaR1)){
                            banderaAgregar = true;
                        }

                        //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                        if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                            banderaAgregar= true;
                        }

                        if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                            banderaAgregar= true;
                        }

                        if(horaR1 === horaIns){
                            if(horaR2 === horaCer){
                                banderaAgregar = true;
                            }
                        }

                    } 
            }


            }

            if(validadorDosMeses === true){

                console.log("voy a validar horas entre dos meses");

                if(mesR1 !== mesR2){

                    //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                    if((horaIns >= horaR1) && (horaIns <= horaR2)){
                        banderaAgregar = true;
                    }

                    //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                    if((horaCer <= horaR2) && (horaCer >= horaR1)){
                        banderaAgregar = true;
                    }

                    //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                    if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                        banderaAgregar= true;
                    }

                    if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                        banderaAgregar= true;
                    }

                    if(horaR1 === horaIns){
                        if(horaR2 === horaCer){
                            banderaAgregar = true;
                        }
                    }



                }

                if((mes1 === mesR1) && (mes1 === mesR2)){

                    //Fechas dentro de un rengo
                    if((diaR1 < dia1) && (dia1 <= diaR2) && (diaR1 < dia2) && (dia2 <= diaR2)){


                        //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                        if((horaIns >= horaR1) && (horaIns <= horaR2)){
                            banderaAgregar = true;
                        }

                        //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                        if((horaCer <= horaR2) && (horaCer >= horaR1)){
                            banderaAgregar = true;
                        }

                        //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                        if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                            banderaAgregar= true;
                        }

                        if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                            banderaAgregar= true;
                        }

                        if(horaR1 === horaIns){
                            if(horaR2 === horaCer){
                                banderaAgregar = true;
                            }
                        }

                    }


                    if((mes1 === mesR1) && (mes2 === mesR1)){
                        if((diaR1 < dia1) && (dia1 <= diaR2)){

                        //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                            if((horaIns >= horaR1) && (horaIns <= horaR2)){
                                banderaAgregar = true;
                            }

                            //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                            if((horaCer <= horaR2) && (horaCer >= horaR1)){
                                banderaAgregar = true;
                            }

                            //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                            if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                                banderaAgregar= true;
                            }

                            if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                                banderaAgregar= true;
                            }

                            if(horaR1 === horaIns){
                                if(horaR2 === horaCer){
                                    banderaAgregar = true;
                                }
                            }

                        }
                    }

                    if((mes1 === mesR1) && (mes2 === mesR1)){
                        if((diaR1 < dia2) && (dia2 <= diaR2)){

                            //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                            if((horaIns >= horaR1) && (horaIns <= horaR2)){
                                banderaAgregar = true;
                            }

                            //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                            if((horaCer <= horaR2) && (horaCer >= horaR1)){
                                banderaAgregar = true;
                            }

                            //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                            if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                                banderaAgregar= true;
                            }

                            if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                                banderaAgregar= true;
                            }

                            if(horaR1 === horaIns){
                                if(horaR2 === horaCer){
                                    banderaAgregar = true;
                                }
                            }

                        }
                    }
                
                }


            }



        }
        
        //props.fechaHoraIn(banderaAgregar);

        if((conv1 > conv2)){
            setmessage('Revisar las fechas, fueron mal ingresadas');
        }else if(horaconv1 >= horaconv2 ){
            setmessage('Revisar los horarios, fueron mal ingresados');
        }else if (banderaAgregar === false && banderaAgregarFechas === false){
         
            const newData = [...data];

            console.log("Entre a añadir un elemento")

            newData.push({
                key: key,
                fechaFI: fechaIns,
                fechaFC: fechaCer,
                horaHI: horaIns,
                horaHC: horaCer,
            })

            setData(newData)

            //ordenarFechas(newData);

            setKey(key+1);
            props.handleClick(key, fechaIns, fechaCer, horaIns, horaCer);

        }else{
            setIsModalVisible(true);
        }


      }catch(error){
          console.log("Error en agregar valor a tabla", error)
      }
  }


  const ordenarFechas = (data) =>{

    console.log("vamos ordenar las fechas");

    const matrizR = [];
    const matrizN = [];

    //if(data.length >= 2){

        for(var m = 0; m < data.length; m++){

            console.log("entre a convertir las fechas");

            var fechaR1 = data[m].fechaFI;

            let diaR = moment(fechaR1, 'DD/MMMM/YY').date();
            let mesR = moment(fechaR1, 'DD/MMMM/YY').month();

            var fechaC = diaR +''+mesR;

            matrizR.push({
                key: m,
                nFecha: parseInt(fechaC,10),
            })
        //}

        matrizR.sort(function (a, b) {
            if (a.nFecha > b.nFecha) {
              return 1;
            }
            if (a.nFecha < b.nFecha) {
              return -1;
            }
            return 0;
          });

        var n = 0;

        for(var k = 0; k < matrizR.length; k++){

            var ky = matrizR[k].key;
            var info = [...data];

            console.log("info", info);

            const dataN = {key: n,
                            fechaFI: data[ky].fechaFI,
                            fechaFC: data[ky].fechaFC,
                            horaHI: data[ky].horaHI,
                            horaHC: data[ky].horaHC};

            console.log("datos que voy agregar", dataN);

            var removed = info.splice(k, 1, dataN);

            /*matrizN.push({
                key: n,
                fechaFI: data[ky].fechaFI,
                fechaFC: data[ky].fechaFC,
                horaHI: data[ky].horaHI,
                horaHC: data[ky].horaHC
            })*/
            
            n = n +1;
        }

        console.log(info);

        setData(info);
        console.log(data);


    }
  }


  const onFinish = values => {
    console.log('Valores para la casilla itinerante:', values);
    props.datosCasillaItinerante(values);

    if(values.casillaItinerante.length >= 1 && seccion !== undefined && tipoCasilla !== undefined && zor !== undefined){
        props.changeTab("2");
    }

  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const content = (
    <div className="cuerpo-content">
      <span >Las fechas y horarios no</span><br/>
      <span >deben sobreponerse.</span><br/><br/>
      <span className="confirm">Elige otra opción</span><br/>
    </div>
  );


  function onChange(pagination, filters, sorter, extra) {
    console.log('params', sorter, extra);
  }
  
  const { TabPane } = Tabs;
  return(

    <Layout className='validar_modulo'>
    <Row>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <h1 id="titulo-componente">Registrar nueva casilla</h1>
      </Col>
    </Row>
    <Tabs centered = {true} defaultActiveKey="1" activeKey={props.activeTab} onChange={props.changeTab} size="large" className="tab-registraCasilla">
      <TabPane disabled tab="Ubicación" key="1">
        <div>
            <h4>Captura los datos requeridos. Los datos con (<b><font color="red">*</font></b>) son obligatorios.</h4>
        </div>
        <br/>
      <Form form = {form}>
      <Card bordered={false}>
                <Row>
                    <Col sm={24} md={6} lg={6}>
                        <label><b><font color="red">*</font></b>Municipio/alcaldía</label>
                    </Col>
                    <Col sm={24} md={8} lg={8}>
                        <label><b><font color="red">*</font></b>Tipo de casilla</label>
                    </Col>
                    <Col sm={24} md={4} lg={4}>
                        <label><b><font color="red">*</font></b>Sección electoral</label>
                    </Col>
                    <Col sm={24} md={6} lg={6}>
                        <label><b><font color="red">*</font></b>Asignar ZOR</label>
                    </Col>
                </Row>
                
                <Row gutter={8}>
                                                                   
                    <Col sm={24} md={6} lg={6}>
                        <Form.Item form = {form} name="municipio" rules={[{required: true, message: 'Dato requerido'}]}>   
                            <Select defaultValue="" onChange={(e) =>{ setMunicipio(e)
                                                    props.selectMunicipio(e)
                                                    clearSelected()}} value={municipio}>
                                                {(props.rolPermitido === false) ? null :
                                                        props.municipios &&
                                                        props.municipios.map((municipio, index) => (
                                                        <Option key={index} value={municipio.idMunicipio}>
                                                            {municipio.nombreMunicipio}
                                                        </Option>
                                                    ))
                                                }
                            </Select>
                        </Form.Item>
                    </Col> 

                    <Col sm={23} md={7} lg={7}>
                        <Form.Item form = {form} name="tipoCasilla" rules={[{required: true, message: 'Dato requerido'}]}>
                            <Select disabled={props.municipioSelect === null ? true: false} onChange={(e) => { setTipoCasilla(e)
                                                                                                             props.selectTipoCasilla(e)}} value={tipoCasilla}>
                                    { props.tipoCasillas.map((value, index) => {
                                    return(                                          
                                            <Option value={value.idTipoCasilla} >                                
                                                <tr key={index} >                                                                                        
                                                    {value.tipoCasilla}                                            
                                                </tr>
                                            </Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                        <Col sm={1} md={1} lg={1}>
                            <Tooltip title={
                                <div style={{whiteSpace: 'pre-line'}}>
                                {etiquetaCasilla.etiqueta_tooltip_tipo_casilla}</div>} overlayClassName="tooltip-ubicaRegistra">
                                <img style={{width:17 , height:17}} src={iconoQuestion}/>
                            </Tooltip>
                        </Col>


                    <Col sm={23} md={3} lg={3}>
                        <Form.Item form = {form} name="seccion" rules={[{required: true, message: 'Dato requerido'}]} >
                            <Select disabled={props.municipioSelect === null ? true: false} defaultValue="" onChange={(e) => {setSeccion(e)
                                                                                                            props.selectSeccion(e)
                                                                                                            clearSelectedZor()}} value={seccion}>
                                    {props.secciones &&
                                     props.secciones.map((secc, index) => (
                                         <Option key={index} value={secc.seccion}>
                                            {secc.seccion}
                                         </Option>
                                     ))}
                            </Select>
                        </Form.Item>
                    </Col>

                        <Col sm={1} md={1} lg={1}>
                            <Tooltip title={etiquetaCasilla.etiqueta_tooltip_seccion}>
                                <img style={{width:17 , height:17}} src={iconoQuestion}/>
                            </Tooltip>
                        </Col>
                  
                    <Col sm={23} md={5} lg={5}>
                        <Form.Item form = {form} name="zore" rules={[{required: true, message: 'Dato requerido'}]}>
                            <Select disabled={props.seccionSelect === null ? true: false} onChange={(e) => { setZor(e)                           
                                                                                          props.selectZore(e)
                                                                                          }} value={zor} >
                                    {props.asignarZores &&
                                     props.asignarZores.map((zores, index) => (
                                         <Option key={index} value={zores.idZonaResponsabilidad}>
                                            {zores.numeroZonaResponsabilidad} - {zores.tipoZore}
                                         </Option>
                                     ))

                                    }
                            </Select>
                        </Form.Item>                    
                    </Col>

                        <Col sm={1} md={1} lg={1}>
                            <Tooltip title={etiquetaCasilla.etiqueta_tooltip_zore}>
                                <img style={{width:17 , height:17}} src={iconoQuestion}/>
                            </Tooltip>
                        </Col>

                    </Row>
                   
                    <br/>
                    <div style={{display: (tipoCasilla === 4 || tipoCasilla === 5) ? "none":"block"}}>
                    <div style={{display: ((tipoCasilla === 3) || (tipoCasilla === 4) || (tipoCasilla === 5)) ? "none" : "block"}}>
                    <Row>
                        <Col sm={24} md={8} lg={8}>
                            <label><b><font color="red">*</font></b>Calle</label>
                        </Col>
                        <Col sm={24} md={4} lg={4}>
                            <label><b><font color="red">*</font></b>Número</label>
                        </Col>
                        <Col sm={24} md={4} lg={4}>
                            <label><b><font color="red">*</font></b>Código Postal</label>
                        </Col>
                        <Col sm={24} md={8} lg={8}>
                            <label><b><font color="red">*</font></b>Colonia</label>
                        </Col>
                    </Row>
                    
                    <Row gutter = {[16,16]}>
                        <Col sm={24} md={8} lg={8}>
                            <Form.Item form = {form} name="calle" rules={[{required: true, message: 'Dato requerido'}, { pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}>
                            <Input
                            name = "calle"
                            onChange = {(e) => { setCalle(e.target.value)
                                                 props.cambioText(e)
                                                 revisarCalle(e)}}
                            maxLength={100}
                            rules={[{
                                     max: 100
                                     , pattern: etiquetaCasilla.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                           }]}></Input>
                        </Form.Item>
                        </Col>
                        <Col sm={24} md={4} lg={4}>
                            <Form.Item form = {form} name="numero" rules={[{required: true, message: 'Dato requerido'}, { pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}>
                            <Input
                                value={num}
                                name = "num"
                                onChange = {(e) => { setNum(e.target.value)
                                                      props.cambioText(e)
                                                      revisarNumero(e)}}
                                maxLength={5}></Input>
                            </Form.Item>
                        </Col>
                        <Col sm={24} md={4} lg={4}>
                            <Form.Item form = {form} name="codigoPostal" rules={[{required: true, message: 'Dato requerido'}, { pattern: /^\d*[1-9]+\d*$/, message: 'Solo números'}, { pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}
                                                      validateStatus={(codigoPostal === undefined) ? null : (fvalCp) ? null : "error"} help={(codigoPostal === undefined) ? null : (fvalCp) ? null : "Formato incorrecto"}>
                            <Input
                                name = "cp"
                                onChange = {(e) => { setCodigoPostal(e.target.value)
                                                    props.cambioText(e)
                                                    revisarCp(e)}}
                                maxLength={5}
                                rules={[{
                                     len: 5
                                     , pattern: /^\d{5,5}$/i
                            }]}></Input>
                            </Form.Item>
                        </Col>

                        <Col sm={24} md={8} lg={8}>
                            <Form.Item form = {form} name="colonia" rules={[{required: true, message: 'Dato requerido'}, { pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}>
                            <Input
                                name = "colonia"
                                onChange = {(e) => {setColonia(e.target.value)
                                                    props.cambioText(e)
                                                    revisarCol(e)}}
                                maxLength={50}
                                rules={[{
                                     max: 50
                                     , pattern: etiquetaCasilla.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                }]}></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                    </div>
                    <br/>
                    <div style={{display: ((tipoCasilla === 1) || (tipoCasilla === 2) || (tipoCasilla === 3)) ? 'none' : 'block'}}>
                    <Row>
                        <Col sm={24} md={12} lg={12}>
                            <label><b><font color="red">*</font></b>Lugar</label>
                        </Col>
                        <Col sm={24} md={12} lg={12}>
                            <label><b><font color="red">*</font></b>Referencia</label>
                        </Col>
                    </Row>

                    
                    <Row gutter={[8,8]}>
                        <Col sm={24} md={12} lg={12}>
                            <Form.Item form = {form} name="lugar" rules={[{required: true, message: 'Dato requerido'}, { max:500, pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}>
                            <Input 
                                name = "ubicacion"
                                onChange = {(e) => { setLugar(e.target.value)
                                                     props.cambioText(e)
                                                     revisarLugar(e)}}
                                maxLength={500}
                                rules={[{
                                     max: 500
                                     , pattern: etiquetaCasilla.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                    }]}></Input>
                            </Form.Item>
                        </Col>
                        
                        <Col sm={24} md={12} lg={12}>
                            <Form.Item form = {form} name="referencia" rules={[{required: true, message: 'Dato requerido'}, { pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}>
                            <Input 
                                name = "referencia"
                                onChange = {(e) => {setReferencia(e.target.value)
                                                    props.cambioText(e)
                                                    revisarReferencia(e)}}
                                maxLength={500}
                                rules={[{
                                            max: 500
                                            , pattern: etiquetaCasilla.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                }]}></Input>
                            </Form.Item>
                        </Col>
                        
                    </Row>
                    
                    <Row >
                        <Col offset={8} sm={24} md={4} lg={4}>
                            <span>{valres}&nbsp;caracteres restantes</span>
                        </Col>
                        <Col offset={8} sm={24} md={4} lg={4}>
                            <span>{valresReferencia}&nbsp;caracteres restantes</span>
                        </Col>
                    </Row>

                    <br/>
                    
                    <Row>
                        <Col sm={24} md={12} lg={12}>
                            <label>Nombre del contacto</label>
                        </Col>
                        <Col sm={24} md={12} lg={12}>
                            <label>Teléfono del contacto</label>
                        </Col>
                    </Row>
                    
                    <Row gutter = {[16,16]}>
                        <Col sm={24} md={12} lg={12}>
                        <Form.Item form = {form} name="nombreC" rules={[{ pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}, { pattern: new RegExp("^[ñíóáéúÁÉÍÓÚüÑ a-zA-Z ]+$"), message: 'Los números no son permitidos.'}]}>
                            <Input
                                name = "nombreContacto"
                                onChange = {(e) => {setNombreC(e.target.value)
                                                    props.cambioText(e)
                                                    revisarNombreC(e)}}
                                maxLength={250}
                                rules={[{
                                     max: 250
                                     , pattern: etiquetaCasilla.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                }]}></Input>
                        </Form.Item>
                        </Col>
                        <Col sm={24} md={12} lg={12}>
                            <Form.Item form = {form} name="telefonoC" rules={[{ pattern: /^\d*[1-9]+\d*$/, message: 'Solo números'}, { pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}
                                                     validateStatus={(telC === undefined || telC === '') ? null : (fvalTel) ? null : "error"} help={(telC === undefined || telC === '') ? null : (fvalTel) ? null : "Formato incorrecto"}>
                            <Input
                                name = "telefonoC"
                                onChange = {(e) =>  {setTelC(e.target.value)
                                                    props.cambioText(e)
                                                    revisarTel(e)}}
                                maxLength={10}
                                rules={[{
                                     min: 8
                                     , max: 10
                                     , pattern: etiquetaCasilla.REGEX_FORMATO_TELEFONO
                                }]}
                                custom={{
                                    getValueFromEvent: (event, previo) => {
                                        if(etiquetaCasilla.REGEX_FORMATO_SOLO_DIGITOS.test(event.target.value)){
                                            return	event.target.value;
                                        }
                                        return previo;
                                    }
                                }}></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                    </div>
                    <div style={{display: ((tipoCasilla === 1) || (tipoCasilla === 2) || (tipoCasilla === 3)) ? 'block':'none'}}>
                        <Row>
                            <Col sm={24} md={12} lg={12}>
                                <label><b><font color="red">*</font></b>Lugar</label>
                            </Col>
                            <Col sm={24} md={4} lg={4}>
                                <label><b><font color="red">*</font></b>Clave de la escuela</label>
                            </Col>
                            <Col sm={24} md={4} lg={4}>
                                <label><b><font color="red">*</font></b>Turno de la escuela</label>
                            </Col>
                            <Col sm={24} md={4} lg={4}>
                                <label><b><font color="red">*</font></b>Nivel educativo</label>
                            </Col>
                        </Row>

                        <Row gutter = {[16,16]}>
                            <Col sm={24} md={12} lg={12}>
                                <Form.Item form = {form} name="lugarEscolar" rules={[{required: true, message: 'Dato requerido'}, { pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: ' Los siguientes caracteres no son permitidos (/:*?”<>).'}]}>
                                <Input 
                                    name = "ubicacionEscolar"
                                    onChange = {(e) => { setLugarE(e.target.value)
                                                         props.cambioText(e)
                                                         revisarLugarE(e)}}
                                    maxLength={500}
                                    rules={[{
                                        max: 500
                                        , pattern: etiquetaCasilla.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                        }]}></Input>
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={4} lg={4}>
                                <Form.Item form = {form} name="claveEscuela" rules={[{required: true, message: 'Dato requerido'}, { pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}>
                                    <Input
                                        name = "claveEscuela"
                                        onChange = {(e) => { setClaveE(e.target.value)
                                                            props.cambioText(e)
                                                            revisarClaveE(e)}}
                                        maxLength={20}></Input>
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={4} lg={4}>
                                <Form.Item form = {form} name="turno">
                                    <Select onChange={(e) => { setTurno(e)
                                                                props.selectTurnoEscuela(e)}} value = {turno}>
                                    {props.lstTurnosEscuelas.map((value, index) => {
                                        return(                                          
                                            <Option value={value.idTurnoEscuela} >                                
                                                <tr key={index}>                                                                                        
                                                    {value.turno}                                            
                                                </tr>
                                            </Option>
                                        )
                                    })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={4} lg={4}>
                                <Form.Item form = {form} name="nivelEducativo" rules={[{required: true, message: 'Dato requerido'}]}>
                                    <Select onChange={(e) => { setNivelE(e)
                                                                props.selectNivelEducativo(e)}} value = {nivelE}>
                                    {props.lstNivelesEducativos.map((value, index) => {
                                        return(                                          
                                            <Option value={value.idNivelEducativo} >                                
                                                <tr key={index}>                                                                                        
                                                    {value.nivelEducativo}                                            
                                                </tr>
                                            </Option>
                                        )
                                    })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row >
                            <Col offset={8} sm={24} md={4} lg={4}>
                                <span>{valresLugarE}&nbsp;caracteres restantes</span>
                            </Col>
                        </Row>
                        <br/>

                        <Row>
                            <Col sm={24} md={12} lg={12}>
                                <label><b><font color="red">*</font></b>Nombre del contacto</label>
                            </Col>
                            <Col sm={24} md={8} lg={8}>
                                <label><b><font color="red">*</font></b>Teléfono del contacto</label>
                            </Col>
                        </Row>
                    
                        <Row gutter = {[16,16]}>
                            <Col sm={24} md={12} lg={12}>
                            <Form.Item form = {form} name="nombreCEscuela" rules={[{required: true, message: 'Dato requerido'}, { pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}, { pattern: new RegExp("^[ñíóáéúÁÉÍÓÚüÑ a-zA-Z ]+$"), message: 'Los números no son permitidos.'}]}>
                                <Input
                                    name = "nombreContactoEscuela"
                                    onChange = {(e) => { setNombreCE(e.target.value)
                                                         props.cambioText(e)
                                                         revisarNombreCE(e)}}
                                    maxLength={250}
                                    rules={[{
                                        max: 250
                                        , pattern: etiquetaCasilla.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                    }]}></Input>
                            </Form.Item>
                            </Col>
                            <Col sm={24} md={8} lg={8}>
                                <Form.Item form = {form} name="telefonoCEscuela" rules={[{required: true, message: 'Dato requerido'}, { pattern: /^\d*[1-9]+\d*$/, message: 'Solo números'}, { pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}
                                                         validateStatus={(telefonoCE === undefined) ? null : (fvalTelE) ? null : "error"} help={(telefonoCE === undefined) ? null : (fvalTelE) ? null : "Formato incorrecto"}>
                                    <Input
                                        name = "telefonoCEscuela"
                                        onChange = {(e) =>  {setTelefonoCE(e.target.value)
                                                             props.cambioText(e)
                                                             revisarTelE(e)}}
                                        maxLength={10}
                                        rules={[{
                                             min: 8
                                            , max: 10
                                            , pattern: etiquetaCasilla.REGEX_FORMATO_TELEFONO
                                        }]}
                                        custom={{
                                            getValueFromEvent: (event, previo) => {
                                                if(etiquetaCasilla.REGEX_FORMATO_SOLO_DIGITOS.test(event.target.value)){
                                                    return	event.target.value;
                                                }
                                                return previo;
                                        }
                                    }}></Input>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={24} md={6} lg={6}>
                                <label><b><font color="red">*</font></b>Referencias</label>
                            </Col>
                        </Row>
                        <Row gutter = {[16,16]}>
                            <Col sm={24} md={12} lg={12}>
                                <Form.Item form = {form} name="referenciaEscolar" rules={[{max:500, required: true, message: 'Dato requerido'}, { pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}>
                                <Input 
                                    name = "referenciaEscolar"
                                    onChange = {(e) => { setReferenciaE(e.target.value)
                                                         props.cambioText(e)
                                                         revisarReferenciaE(e)}}
                                    maxLength={500}
                                    rules={[{
                                        max: 500
                                        , pattern: etiquetaCasilla.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                        }]}></Input>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row >
                            <Col offset={8} sm={24} md={4} lg={4}>  
                                <span>{valresReferenciaE}&nbsp;caracteres restantes</span>
                            </Col>
                        </Row>

                    </div>
                    </div>
                    <div style={{display: (tipoCasilla === 4 ) ? "block": "none"}}>
                        <Form Form={form} onFinish={onFinish} autoComplete="off" layout="vertical">
                            <Form.List name="casillaItinerante">
                                {(fields, { add, remove }) => (
                                    <>
                                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                                        <Card key={key} bordered={false}>
                                        <Row gutter={[8,8]}>    
                                            <Col sm={24} md={8} lg={8}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'calle']}
                                                    label="Calle"
                                                    fieldKey={[fieldKey, 'calle']}
                                                    rules={[{ required: true, message: 'Dato requerido' }, { pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}
                                                >
                                                <Input
                                                    name = "calle"
                                                    maxLength={100}></Input>
                                                </Form.Item>
                                            </Col>
                                            <Col sm={24} md={4} lg={4}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'numero']}
                                                    label="Número"
                                                    fieldKey={[fieldKey, 'numero']}
                                                    rules={[{ required: true, message: 'Dato requerido' }, { pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}
                                                >
                                                <Input
                                                    name = "numero"
                                                    maxLength={5}></Input>
                                                </Form.Item>
                                                    </Col>
                                            <Col sm={24} md={4} lg={4}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'codigoPostal']}
                                                    label="Código Postal"
                                                    fieldKey={[fieldKey, 'codigoPostal']}
                                                    rules={[{ required: true, message: 'Dato requerido' }, { pattern: /^\d*[1-9]+\d*$/, message: 'Solo números'}, { pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}, {min:5, max: 5, message: 'Formato incorrecto'}]}
                                                >
                                                <Input
                                                    name = "codigoPostal"
                                                    maxLength={5}></Input>
                                                </Form.Item>
                                            </Col>
                                            <Col sm={24} md={7} lg={7}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'colonia']}
                                                    label="Colonia"
                                                    fieldKey={[fieldKey, 'colonia']}
                                                    rules={[{ required: true, message: 'Dato requerido' }, { pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}
                                                >
                                                <Input
                                                    name = "colonia"
                                                    maxLength={50}></Input>
                                                </Form.Item>
                                            </Col>
                                            <Col sm={1} md={1} lg={1}>
                                                <a onClick={() => {remove(name)
                                                                   setConteoIFBD(conteoIFBD - 1)}}><img style={{width:"22px", height:"22px"}} src={iconominus}/></a>  
                                            </Col>
                                        </Row>
                                        <Row gutter={[8,8]}>
                                            <Col sm={24} md={12} lg={12}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'ubicacion']}
                                                    label="Lugar"
                                                    fieldKey={[fieldKey, 'lugar']}
                                                    rules={[{ required: true, message: 'Dato requerido' },{pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}
                                                >
                                                <Input
                                                    name = "lugar"
                                                    maxLength={500}></Input>
                                                </Form.Item>
                                            </Col>
                                            <Col sm={24} md={11} lg={11}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'referencia']}
                                                    label="Referencia"
                                                    fieldKey={[fieldKey, 'referencia']}
                                                    rules={[{ required: true, message: 'Dato requerido' }, { pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}
                                                >
                                                <Input
                                                    name = "referencia"
                                                    maxLength={500}></Input>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={[8,8]}>
                                            <Col sm={24} md={12} lg={12}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'nombreContacto']}
                                                    label="Nombre del contacto"
                                                    fieldKey={[fieldKey, 'nombreContacto']}
                                                    rules={[{ pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}, { pattern: new RegExp("^[ñíóáéúÁÉÍÓÚÑü a-zA-Z ]+$"), message: 'Los números no son permitidos.'}]}
                                                >
                                                <Input
                                                    name = "nombreContacto"
                                                    maxLength={250}></Input>
                                                </Form.Item>
                                            </Col>
                                            <Col sm={24} md={11} lg={11}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'telefonoContacto']}
                                                    label="Télefono del contacto"
                                                    fieldKey={[fieldKey, 'telefonoContacto']}
                                                    rules={[{ pattern: /^\d*[1-9]+\d*$/, message: 'Solo números'}, {pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}, {min:10, max: 10, message: 'Formato incorrecto'}]}
                                                >
                                                <Input
                                                    name = "telefonoContacto"
                                                    maxLength={10}></Input>
                                                </Form.Item>
                                            </Col>
                                        </Row>                           
                                    </Card>
                                      ))}
                                      <Form.Item>
                                        
                                        <Button type="dashed" onClick={() => {add()
                                                                              setConteoIFBD(conteoIFBD + 1)}} block icon={<PlusOutlined />}>
                                          Agregar ubicación
                                        </Button>
                                        
                                      </Form.Item>
                                    </>
                                  )}
                            </Form.List>
                            <Form.Item>
                                <div className="div-boton-itineranteFisica">
                                    <Row gutter={2}>
                                        <Col offset={22} sm={1} md={1} lg={1} xl={1}>
                                            <Tooltip title={etiquetaCasilla.etiqueta_tooltip_botonIzq}>
                                                <Button htmlType="submit" shape="circle" style={{width: "47px", height: "46px", borderColor: "#ffffff"}} icon={<ArrowRightOutlined id="flechaDer"/>} 
                                                        disabled={conteoIFBD > 0 ? false:true}/>
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                </div>
                            </Form.Item>
                        </Form>
                    </div>
                    <div style={{display: (tipoCasilla === 5 ) ? "block": "none"}}>
                        <Form onFinish={onFinish} autoComplete="off" layout="vertical">
                            <Form.List name="casillaItinerante">
                                {(fields, { add, remove }) => (
                                    <>
                                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                                        <Card key={key} bordered={false}>
                                            <Row gutter={[8,8]}>    
                                                <Col sm={24} md={8} lg={8}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'calle']}
                                                        label="Calle"
                                                        fieldKey={[fieldKey, 'calle']}
                                                        rules={[{ required: true, message: 'Dato requerido' }, { pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}
                                                    >
                                                    <Input
                                                        name = "calle"
                                                        maxLength={100}></Input>
                                                    </Form.Item>
                                                </Col>
                                                <Col sm={24} md={4} lg={4}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'numero']}
                                                        label="Número"
                                                        fieldKey={[fieldKey, 'numero']}
                                                        rules={[{ required: true, message: 'Dato requerido' }, { pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}
                                                    >
                                                    <Input
                                                        name = "numero"
                                                        maxLength={5}></Input>
                                                    </Form.Item>
                                                        </Col>
                                                <Col sm={24} md={4} lg={4}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'codigoPostal']}
                                                        label="Código Postal"
                                                        fieldKey={[fieldKey, 'codigoPostal']}
                                                        rules={[{ required: true, message: 'Dato requerido' }, { pattern: /^\d*[1-9]+\d*$/, message: 'Solo números'}, { pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}, {min:5, max: 5, message: 'Formato incorrecto'}]}
                                                    >
                                                    <Input
                                                        name = "codigoPostal"
                                                        maxLength={5}></Input>
                                                    </Form.Item>
                                                </Col>
                                                <Col sm={24} md={7} lg={7}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'colonia']}
                                                        label="Colonia"
                                                        fieldKey={[fieldKey, 'colonia']}
                                                        rules={[{ required: true, message: 'Dato requerido' }, { pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}
                                                    >
                                                    <Input
                                                        name = "colonia"
                                                        maxLength={50}></Input>
                                                    </Form.Item>
                                                </Col>
                                                <Col sm={1} md={1} lg={1}>
                                                    <a onClick={() => {remove(name)
                                                                       setConteoIFBI(conteoIFBI - 1)}}><img style={{width:"22px", height:"22px"}} src={iconominus}/></a>  
                                                </Col>
                                            </Row>
                                            <Row gutter={[8,8]}>
                                                <Col sm={24} md={12} lg={12}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'ubicacion']}
                                                        label="Lugar"
                                                        fieldKey={[fieldKey, 'lugar']}
                                                        rules={[{ required: true, message: 'Dato requerido' },{pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}
                                                    >
                                                    <Input
                                                        name = "lugar"
                                                        maxLength={500}></Input>
                                                    </Form.Item>
                                                </Col>
                                                <Col sm={24} md={11} lg={11}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'referencia']}
                                                        label="Referencia"
                                                        fieldKey={[fieldKey, 'referencia']}
                                                        rules={[{ required: true, message: 'Dato requerido' }, { pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}
                                                    >
                                                    <Input
                                                        name = "referencia"
                                                        maxLength={500}></Input>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[8,8]}>
                                                <Col sm={24} md={12} lg={12}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'nombreContacto']}
                                                        label="Nombre del contacto"
                                                        fieldKey={[fieldKey, 'nombreContacto']}
                                                        rules={[{ pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}, { pattern: new RegExp("^[ñíóáéúÁÉÍÓÚÑü a-zA-Z ]+$"), message: 'Los números no son permitidos.'}]}
                                                    >
                                                    <Input
                                                        name = "nombreContacto"
                                                        maxLength={250}></Input>
                                                    </Form.Item>
                                                </Col>
                                                <Col sm={24} md={11} lg={11}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'telefonoContacto']}
                                                        label="Télefono del contacto"
                                                        fieldKey={[fieldKey, 'telefonoContacto']}
                                                        rules={[{ pattern: /^\d*[1-9]+\d*$/, message: 'Solo números'}, {pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}, {min:10, max: 10, message: 'Formato incorrecto'}]}
                                                    >
                                                    <Input
                                                        name = "telefonoContacto"
                                                        maxLength={10}></Input>
                                                    </Form.Item>
                                                </Col>
                                            </Row>                           
                                        </Card>
                                      ))}
                                      <Form.Item>
                                        
                                        <Button type="dashed" onClick={() => {add()
                                                                              setConteoIFBI(conteoIFBI + 1)}} block icon={<PlusOutlined />}>
                                          Agregar ubicación
                                        </Button>
                                        
                                      </Form.Item>
                                    </>
                                  )}
                            </Form.List>
                            <Form.Item>
                            <div className="div-boton-itineranteVirtual">
                                <Row gutter={2}>
                                    <Col offset={22} sm={1} md={1} lg={1} xl={1}>
                                        <Tooltip title={etiquetaCasilla.etiqueta_tooltip_botonIzq}>
                                            <Button type="primary" htmlType="submit" style={{width: "47px", height: "46px", borderColor: "#ffffff"}} shape="circle" icon={<ArrowRightOutlined id="flechaDer"/>}
                                                    disabled={conteoIFBI > 0 ? false:true} />
                                        </Tooltip>
                                    </Col>
                                </Row>
                                </div>
                            </Form.Item>
                        </Form>
                    </div>
                </Card>
                <Form.Item>
                <div className="div-boton-registra1" style={{display: (tipoCasilla === 4 || tipoCasilla === 5) ? "none":"block"}}>
                <Row gutter={2}>
                    <Col offset={22} sm={1} md={1} lg={1} xl={1}>
                        <Tooltip title={etiquetaCasilla.etiqueta_tooltip_botonIzq}><Button type="primary" style={{width: "47px", height: "46px", borderColor: "#ffffff"}}  htmlType="submit" 
                        disabled={(tipoCasilla === 1 || tipoCasilla === 2) ? ((municipio !== undefined) && (seccion !== undefined) && (tipoCasilla !== undefined) && (zor !== undefined)
                                    && (calle !== undefined) && (calle !== '') && (fvalCalle !== true)
                                    && (num !== undefined) && (num !== '')
                                    && (codigoPostal !== undefined) && (codigoPostal !== '')
                                    && (colonia !== undefined) && (colonia !== '') && (fvalColonia !== true)
                                    && (lugarE !== undefined) && (lugarE !== '') && (fvalLugarE !== true) && (nombreCE !== undefined ) && (nombreCE !== '') 
                                    && (telefonoCE !== undefined) && (telefonoCE !== '') && (claveE !== undefined) && (claveE !== '') && (fvalClaveE !== true)
                                    && (turno !== undefined) && (nivelE !== undefined) && (fvalNombreCE === true)
                                    && (referenciaE !== undefined) && (referenciaE !== '') && (fvalReferenciaE !== true ) && (fvalNum === true) && (fvalCp === true) && (fvalTelE === true) ? false : true):
                                    (tipoCasilla === 3) ? ((municipio !== undefined) && (seccion !== undefined) && (tipoCasilla !== undefined) && (zor !== undefined)
                                    && (lugarE !== undefined) && (lugarE !== '') && (fvalLugarE !== true) && (nombreCE !== undefined ) && (nombreCE !== '') 
                                    && (telefonoCE !== undefined) && (telefonoCE !== '') && (claveE !== undefined) && (claveE !== '') && (fvalClaveE !== true)
                                    && (turno !== undefined) && (nivelE !== undefined) && (fvalNombreCE === true)
                                    && (referenciaE !== undefined) && (referenciaE !== '') && (fvalReferenciaE !== true ) && (fvalTelE === true) ? false : true):
                                    (tipoCasilla === 4 || tipoCasilla === 5) ? 
                                    ((municipio !== undefined) && (seccion !== undefined) && (tipoCasilla !== undefined) && (zor !== undefined) ? true: true)
                                    /*&& (lugar !== undefined) && (lugar !== '') && (fvalLugar !== true) && (fvalNombreC === true)
                                    && (referencia !== undefined) && (referencia !== '') && (fvalReferencia !== true) && (fvalTel === true) || 
                                    ((municipio !== undefined) && (seccion !== undefined) && (tipoCasilla !== undefined) && (zor !== undefined)
                                    && (lugar !== undefined) && (lugar !== '') && (fvalLugar !== true) && (fvalNombreC === true)
                                    && (referencia !== undefined) && (referencia !== '') && (fvalReferencia !== true) && ((telC === undefined) || (telC === '')))  ? false : true)*/:
                                    ((municipio !== undefined) && (seccion !== undefined) && (tipoCasilla !== undefined) && (zor !== undefined)
                                    && (calle !== undefined) && (calle !== '') && (fvalCalle !== true)
                                    && (num !== undefined) && (num !== '')
                                    && (codigoPostal !== undefined) && (codigoPostal !== '')
                                    && (colonia !== undefined) && (colonia !== '') && (fvalColonia !== true)
                                    && (lugar !== undefined) && (lugar !== '') && (fvalLugar !== true) && (fvalNombreC === true)
                                    && (referencia !== undefined) && (referencia !== '') && (fvalReferencia !== true) && (fvalNum === true) && (fvalCp === true) && (fvalTel === true) ||
                                    ((municipio !== undefined) && (seccion !== undefined) && (tipoCasilla !== undefined) && (zor !== undefined)
                                    && (calle !== undefined) && (calle !== '') 
                                    && (num !== undefined) && (num !== '')
                                    && (codigoPostal !== undefined) && (codigoPostal !== '')
                                    && (colonia !== undefined) && (colonia !== '') && (fvalColonia !== true)
                                    && (lugar !== undefined) && (lugar !== '') && (fvalLugar !== true) && (fvalNombreC === true)
                                    && (referencia !== undefined) && (referencia !== '') && (fvalReferencia !== true) && (fvalCalle !== true) && (fvalNum === true) && (fvalCp === true) 
                                    && ((telC === undefined) || (telC === ''))) ? false : true)} 
                        onClick={()=>{props.changeTab("2")}} shape="circle" icon={<ArrowRightOutlined id="flechaDer"/>}/></Tooltip>
                    </Col>
                </Row>
                </div>
                
                </Form.Item>
                </Form>

      </TabPane>
      <TabPane  disabled tab="Conectividad" key="2">
        <>
                <div>
                <h4>Captura los datos requeridos. Los datos con (<b><font color="red">*</font></b>) son obligatorios.</h4>
                </div>
                <br/>
                
                <Row gutter={{ sm: 16, md: 24, lg: 32 }} justify="center" align="middle">
                    <Col sm={12} md={12} lg={12}>
                    <div>
                        <Card bordered={false} style={{textAlign:'center'}} title={<span>{etiquetaCasilla.datos_conectividad_lbl_titulo}<Tooltip title={etiquetaCasilla.etiqueta_tooltip_conectividad}>&nbsp;<img style={{width:17 , height:17}} src={iconoQuestion}/></Tooltip></span>}>
                            <table className="tablaDispositivos">
                                <thead className="tablaCabezal">
                                    <tr>
                                        <th>Tipo de dispositivo</th>
                                        <th>Cantidad</th>
                                    </tr>
                                </thead>
                                <tbody className="tablaCuerpo">
                                    <tr>
                                        <td>Computadoras de escritorio, laptop &nbsp;</td>
                                        <td>
                                            <Row>
                                                <Col offset={9}>{<InputNumber size="small"
                                                                              style={{width:"55px"}}
                                                                              name = "computadoraEscritorio"
                                                                              onChange = {(e) => { setValCE(Math.round(e))
                                                                                                   props.changeCE(Math.round(e))}}
                                                                              min={0} max={999}
                                                                              value={isNaN(valCE) ? '' : Math.round(valCE)}></InputNumber>}</Col>
                                            </Row>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Teléfono inteligente &nbsp;</td>
                                        <td><Row>
                                                <Col offset={9}>{<InputNumber size="small"
                                                                              style={{width:"55px"}}
                                                                              name = "telefonoInteligente"
                                                                              onChange = {(e) => { setValTI(Math.round(e))
                                                                                                   props.changeTI(Math.round(e))}}
                                                                              min={0} max={999}
                                                                              value={isNaN(valTI) ? '' : Math.round(valTI)}></InputNumber>}</Col>
                                            </Row>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Tablet, Ipad &nbsp;</td>
                                        <td>
                                            <Row>
                                                <Col offset={9}>{<InputNumber size="small"
                                                                              style={{width:"55px"}}
                                                                              name = "tablet"
                                                                              onChange = {(e) =>{ setValT(Math.round(e))
                                                                                                  props.changeT(Math.round(e))}}
                                                                              min={0} max={999}
                                                                              value={isNaN(valT) ? '' : Math.round(valT)}></InputNumber>}</Col>
                                            </Row>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br/>
                            <label>Total de dispositivos:&nbsp;{(valCE === undefined) && (valTI === undefined) && (valT === undefined) ? '' :
                                                                (valTI === undefined) && (valT === undefined) ? valCE : 
                                                                (valCE === undefined) && (valT === undefined) ? valTI :
                                                                (valCE === undefined) && (valTI === undefined) ? valT :
                                                                (valT === undefined) ? (valCE + valTI) :
                                                                (valTI === undefined) ? (valCE + valT) :
                                                                (valCE === undefined) ? (valTI + valT) :
                                                                (valCE + valTI + valT)}</label>
                        </Card>
                        </div>
                    </Col>
               
                    <Col sm={{span:8, offset:2}} md={{span:8, offset:2}} lg={{span:8, offset:2}} >
                        <div id="internet">
                            <label><b><font color="red">*</font></b>¿El lugar cuenta con acceso a internet?</label>&nbsp;&nbsp;
                                <Row>
                                    <Col offset={5} sm={11} md={11} lg={11}>
                                        <Radio.Group onChange={props.opcionSN}>
                                          <Radio value={"S"}>Sí</Radio>
                                          <Radio value={"N"}>No</Radio>
                                        </Radio.Group>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <div className="div-boton-registra2">                                                              
                <Row gutter={2}>
                    <Col sm={{span: 1, offset:1}} md={{span: 1, offset:1}} lg={{span: 1, offset:1}} xl={{span: 1, offset:1}}>
                        <Tooltip title={etiquetaCasilla.etiqueta_tooltip_botonDer}><Button style={{width: "47px", height: "46px"}} htmlType="submit" 
                        onClick={()=>{props.changeTab("1")}} shape="circle" icon={<ArrowLeftOutlined id="flechaIzq"/>}/></Tooltip>
                    </Col>
                    <Col offset={20} sm={1} md={1} lg={1} xl={1}>
                        <Tooltip title = {etiquetaCasilla.etiqueta_tooltip_botonIzq}><Button style={{width: "47px", height: "46px"}} htmlType="submit" 
                        disabled={(props.tieneInternet === "S" || props.tieneInternet === "N") ? false : true } 
                        onClick={()=>{props.changeTab("3")}} shape="circle" icon={<ArrowRightOutlined id="flechaDer"/>}/></Tooltip>
                    </Col>
                </Row>
                </div>
            </>
      </TabPane>
      <TabPane disabled tab="Horarios" key="3">
        <Card bordered={false}>
                <div>
                    <h4>Captura los datos requeridos. Los datos con (<b><font color="red">*</font></b>) son obligatorios.&nbsp;
                        <Tooltip title={etiquetaCasilla.etiqueta_tooltip_horarios}><img style={{width:17 , height:17}} src={iconoQuestion}/></Tooltip>
                    </h4>
                </div>
                <br/>
                <Row gutter={{ sm: 8, md: 8, lg: 8 }}>
                    
                    <Col sm={10} md={10} lg={10}>
                    <Form>
                        <Row gutter={{ sm: 8, md: 8, lg: 8 }}>
                            
                            <Col sm={12} md={12} lg={12}>
                                <h4><b><font color="red">*</font></b>{etiquetaCasilla.datos_horarios_lbl_fecha_instalacion}</h4>
                                <Form.Item form = {form} name="fechaInicio" rules={[{required: true, message: 'Dato requerido'}]}
                                            validateStatus={fechaIncorrecta ? "error" : null} help={fechaIncorrecta ? "Dato incorrecto" : null}>
                                    <DatePicker defaultValue={moment('11/01/21')} style={{width:"150px"}} onChange={(date, dateString) =>{ setFechaIns(dateString)
                                                                                 props.onChangeFI(dateString)
                                                                                 revisarfechas()}} format={'DD/MMMM/YY'} locale={locale} disabledDate={disabledDate}
                                                                                 value={fechaIns}
                                                                                 />
                                </Form.Item>
                            </Col>
                            <Col sm={12} md={12} lg={12}>
                                <h4><b><font color="red">*</font></b>{etiquetaCasilla.datos_horarios_lbl_fecha_cierre}</h4>
                                <Form.Item form = {form} name="fechaCierre" rules={[{required: true, message: 'Dato requerido'}]}
                                            validateStatus={fechaIncorrecta ? "error" : null} help={fechaIncorrecta ? "Dato incorrecto" : null}>
                                    <DatePicker  defaultValue={moment('11/30/21')} style={{width:"150px"}} onChange={(date, dateString) =>{ setFechaCer(dateString)
                                                                                  props.onChangeFC(dateString)
                                                                                  revisarfechas()}} format={'DD/MMMM/YY'} locale={locale} disabledDate={disabledDate}
                                                                                  />
                                </Form.Item>
                            </Col>
                        </Row>
                        <br/>
                        <Row gutter={{sm: 8, md: 8, lg: 8 }}>
                            <Col sm={12} md={12} lg={12}>
                                <h4><b><font color="red">*</font></b>{etiquetaCasilla.datos_horarios_lbl_horario_instalacion}</h4>
                                <Form.Item form = {form} name="horaInicio" rules={[{required: true, message: 'Dato requerido'}]}
                                                validateStatus={horaIncorrecta || props.banderaAgregar ? "error" : null} help={horaIncorrecta ? "Dato incorrecto" : null}> 
                                    <TimePicker defaultValue={moment('09:00','HH:mm')} onChange={(time, timeString) => { setHoraIns(timeString)
                                                                                  props.onChangeTimeHI(timeString)
                                                                                  revisarHorarios()}} format="HH:mm" />
                                </Form.Item>
                            </Col>
                            <Col sm={12} md={12} lg={12}>
                                <h4><b><font color="red">*</font></b>{etiquetaCasilla.datos_horarios_lbl_horarios_cierre}</h4>
                                <Form.Item form = {form} name="horaCierre" rules={[{required: true, message: 'Dato requerido'}]}
                                                validateStatus={horaIncorrecta || props.banderaAgregar ? "error" : null} help={horaIncorrecta ? "Dato incorrecto" : null}>
                                    <TimePicker defaultValue={moment('19:00','HH:mm')} onChange={(time, timeString) => { setHoraCer(timeString)
                                                                                  props.onChangeTimeHC(timeString)
                                                                                  revisarHorarios()}} format="HH:mm" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <br/>
                        <div className="boton-anadir">
                        <Row>
                            <Col offset={8} sm={5} md={5} lg={5}>
                                <Form.Item form = {form} name="anadir" rules={[{required: true, message: 'Dato requerido'}]}>
                                    <Button shape="round" style={{ background: "##D3097F", borderColor: "#ffffff" }} 
                                            onClick={()=> addHorarios()} 
                                            disabled={(fechaIns === undefined || fechaIns === '') || (fechaCer === undefined || fechaCer === '') 
                                            || (horaIns === undefined || horaIns === '') || (horaCer === undefined || horaCer === '') ? true: false}>Añadir</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                        </div>
                        </Form>
                    </Col>
                    

                    <Col sm={14} md={14} lg={14}>
                    <div>
                        <Row>
                            <Col span={24}>
                                <Card title="Horarios registrados de la casilla">
                                    <Popover title={<><img src={errorPop} id="img-popFechas"/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Button onClick={handleCancel} shape="circle" icon={<CloseOutlined />} style={{borderColor: "#ffffff" }}/></>} visible={isModalVisible} content={content} placement="left" className="cerrarFechas">
                                        <Table size="small" columns={columns} dataSource={data} onChange={onChange} pagination={{position: ['bottomCenter']},{pageSize : 5}} rowClassName={() => 'editable-row'}/>
                                    </Popover>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    </Col>
            </Row>
        </Card>
        <div className="div-boton-registra3">
        <Row>
            <Col sm={{span: 1, offset:1}} md={{span: 1, offset:1}} lg={{span: 1, offset:1}} xl={{span: 1, offset:1}}>
                <Tooltip title={etiquetaCasilla.etiqueta_tooltip_botonDer}><Button style={{width: "47px", height: "46px"}} htmlType="submit" 
                                                                                   onClick={()=>{props.changeTab("2")}} shape="circle" icon={<ArrowLeftOutlined id="flechaIzq"/>}/></Tooltip>
            </Col>
            <Col offset={9} sm={10} md={10} lg={10} xl={10}>
                <Tooltip title={(data.length >= 1) && (props.rolCau === false) ? null: etiquetaCasilla.etiqueta_tooltip_boton_guardar}>
                    <Button htmlType="submit" disabled={(data.length >= 1) && (props.rolCau === false) ? false : true } style={{borderColor: "#ffffff", width:"118px", borderRadius: "10px"}} onClick={props.enviarServidor}>Guardar</Button>
                </Tooltip>
            </Col>
        </Row>
        </div>
      </TabPane>
    </Tabs>
    <Modal
        className="modalExito"
        visible={props.abrirModalExitoRegistro}
        title={<img src={casillaRegistradaImg} id="img-exito-modal"/>}
        onCancel={props.onChangeRouteModulo}
        centered
        width={327}
        footer={[null]}>{
                <>
                    <div>
                        <span className="titulo-exito">Casilla registrada</span>
                    </div>
                    <div>
                        <span className="notificacion-exito">La información se guardó con éxito</span>
                    </div>
                    <br/>
                    <Row>
                        <Col offset={4} span={6}>
                            <Button type="primary" htmlType="submit" style={{ background: "#D4217F", borderColor: "#ffffff" }} onClick={props.onChangeRoute}>Registrar nueva casilla</Button>
                        </Col>
                    </Row>
                    </>
        }
    </Modal>
    <Modal visible={props.mostrarSinGuardar}
                   title={<img  src={eliminaCasilla} id="img-elimina-modal"/>}
                   centered 
                   width={400}
                   className="modalElimina"
                   onCancel={props.ocultarModalSinGuardar}
                   footer={[null]}>{
                    <>
                        <div>
                            <span className="notificacion-elimina">Los datos capturados se perderán. ¿Deseas salir del registro de casilla?</span>
                        </div>
                        <br/>
                        <Row>
                          <Col span={10}>
                              <Button type="primary" htmlType="submit" style={{ color: "#6D6D6D", background: "#ffffff", borderColor: "#707070", width: "125px"}} onClick={props.ocultarModalSinGuardar}>No</Button>
                          </Col>
                          <Col offset={3} span={10}>
                              <Button type="primary" htmlType="submit" style={{ background: "#D4217F", borderColor: "#ffffff", width:"125px"}} onClick={props.salirSinGuardar}>Sí</Button>
                          </Col>
                        </Row>
                    </>
                  }
            </Modal>
    </Layout>
  )
}
export default VRegistroCasillas;