import React, {Component} from 'react';
import axios from 'axios';
import {connect} from 'react-redux';
import VConsulta from '../../../components/casillas/registro/VConsultaCasilla';
import CTemplate from '../../homeInterno/CTemplate';
import AuthenticatedComponent from "../../../components/comunes/AuthenticatedComponent";
import * as info from '../../../constants/InfoSistema';
import * as etiquetas from '../../../constants/EtiquetasGenerales';
import { createStructuredSelector } from 'reselect';
import { selectEstado, selectDistrito} from '../../../redux/menu/menu-selectors';
import { selectCurrentUser, selectCurrentUserRol} from '../../../redux/user/user-selectors';
import { obtenerEstatusModulo } from '../../../redux/menu/menu-utils';
import { roles_permitidos_casillas } from '../../../constants/EtiquetasCasillas';



let msjError = "";
const jwt = localStorage.getItem('jwt-token');

class CConsultaCasilla extends Component{

  constructor(props){

    super(props);
      this.state = {
        casillas: [],
        total: 0,
        actual: 1,
        mostrar: false,
        casillaSeleccionadaModal:null,
        cargando:false,
        tipoAlerta : "warning",
        mensajeAlerta : "",
        mostrarAlerta : {display:'none'},
        searchFolio: null,
        searchMunicipio: null,
        searchSeccion: null,
        searchTipoCasilla: null,
        searchZore: null,
        tiposCasillas:[],
        activeTab: "1",
        modalVoluntarios: false,
        estatus: false,
        casillaModificar:[],
        modifica: false,
        activeTabModifica: "1",
        items: [],
        calle:'',
        num: '',
        cp: '',
        colonia: '',
        referencia: '',
        lugar: '',
        nombreContacto: '',
        telefonoC: '',
        dispCE: null,
        dispTI: null,
        dispT: null,
        tieneInternet:'',
        fechaFI: '',
        horaHI: '',
        fechaFC: '',
        horaHC: '',
        fila:0,
        idCasilla:0,
        idParticipacion:0,
        folio:'',
        idTipoCasilla:'',
        abrirModalExitoModifica: false,
        activarZonaEscolar:false,
        claveEscuela: '',
        idTurnoEscuela:'',
        idNivelEducativo:'',
        folio:'',
        lstTurnosEscuelas:[],
        lstNivelesEducativos:[],
        mostrarSinGuardar:false,
        usuarioCau:false,
        tipoCasillaItinerante: false,
        listDireccionesCI: [],
        rolPermitido:false,

  };

  console.info("el usuario con el rol ", this.props.user.rolUsuario);
}

componentDidMount(){

    console.log('Entra en componentDidMount');
    this.consultarEstatusModulo();
    this.consultarPermisoUsuario();
}

async componentDidUpdate(prevProps) {
  if (this.props.estado !== prevProps.estado
    || this.props.distrito !== prevProps.distrito) {


        this.enviarConsulta();
        this.consultaTipoCasillas();
  }
}

consultarEstatusModulo () {

  var idDetalleProceso = this.props.user.idDetalle;
  var idProcesoElectoral = this.props.user.idProcesoElectoral;
  var idEstado = this.props.estado.idEstado;
  var idDistrito = this.props.distrito.idDistrito;
  var idSistema = info.ID_SISTEMA;
  var idModulo = this.props.location.state !== undefined ?
                 this.props.location.state.idModulo : 0;
  var grupoSistema = this.props.user.rolUsuario;

  var index = grupoSistema.search("CAU.OC");

    if(index >= 0){
      this.setState({
        usuarioCau:true
      }, ()=>console.log(true))
    }else{
      console.log("tiene otro tipo de rol")
    }

  obtenerEstatusModulo(idProcesoElectoral, idDetalleProceso,idSistema,
    idEstado, idDistrito, idModulo, grupoSistema).then(respuesta =>{
    console.info("El estatus del módulo Consulta es: ",respuesta);
    if(respuesta === "A"){
      console.info("El Módulo Consulta está abierto ");
      this.enviarConsulta();
      this.consultaTipoCasillas();
      this.getNivelesEducativos();
      this.getTurnosEscuelas();
      this.setState({
        estatus:true
      }, ()=>console.log(true))
    }else{
      console.info("El Módulo Consulta está cerrado ");
    }
  })

}

consultarPermisoUsuario = () =>{
  this.setState({
    rolPermitido : roles_permitidos_casillas.includes(this.props.user.rolUsuario)
  }, ()=>console.log("rolpermitido", this.state.rolPermitido));
}

mostrarModal = (record) => {
  this.setState({
    mostrar: true,
    casillaSeleccionadaModal: record
  });
};

ocultarModal = () => {
  this.setState({
    mostrar: false
  })
};


mostrarModalElimina = (record) => {
  this.setState({
    casillaEliminar: record
  }, ()=>console.log(true))

};

ocultarModalElimina = () => {
  this.setState({
    mostrarElimina: false
  })
};

ocultarModalVoluntarios = () => {
  this.setState({
    modalVoluntarios: false
  })
};

ocultarModalSinGuardar = () => {
  this.setState({
    mostrarSinGuardar: false
  })
};

salirsinGuardar = () => {
  
    this.enviarConsulta();
    this.consultaTipoCasillas();
    this.state.mostrarSinGuardar = false;
    this.state.modifica = false;

}

searchFolio = (valor) => {
  if(valor != null){
    this.state.searchFolio = valor.toUpperCase();
  }else{
    this.state.searchFolio = null;
  }
  this.enviarConsulta();
}

searchMunicipio = (valor) => {
  if(valor != null){
    this.state.searchMunicipio = valor.toUpperCase();
  }else{
    this.state.searchMunicipio = null;
  }
  this.enviarConsulta();
}

searchTipoCasilla = (valor) => {
  if(valor != null){
    this.state.searchTipoCasilla = valor;
    if(valor === 1 || valor === 2){
      this.state.activarZonaEscolar = true;
    }else{
      this.state.activarZonaEscolar = false;
    }
  }else{
    this.state.searchTipoCasilla = null;
  }
  this.enviarConsulta();
}

searchZore = (valor) => {
  if(valor != null){
    this.state.searchZore = parseInt(valor,10);
  }else{
    this.state.searchZore = null;
  }
  this.enviarConsulta();
}

searchSeccion = (valor) => {
  if(valor != null){
    this.state.searchSeccion = parseInt(valor,10);
  }else{
    this.state.searchSeccion = null;
  }
  this.enviarConsulta();
}


enviarConsulta = (inicio=1,fin=5) => {

  var dataJson = {
        idDetalleProceso:info.ID_DETALLE_PROCESO,
        idProcesoElectoral:info.ID_PROCESO_ELECTORAL,
        idEstado: this.props.estado.idEstado,
        idDistrito: this.props.distrito.idDistrito,
        inicio: inicio,
        fin: fin,
        municipio: this.state.searchMunicipio,
        folio: this.state.searchFolio,
        idTipoCasilla: this.state.searchTipoCasilla,
        seccion: this.state.searchSeccion,
        numeroZr: this.state.searchZore
    }
  

    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + 'ws/consulta_casilla', dataJson,
    {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': this.props.user ? this.props.user.tknJWT : null
        }
    })
    .then(res => {
      const casilla = res.data.consultaCasilla;
      this.setState({casillas: casilla,
                     total: res.data.totalCasillas,
                     mensajeAlerta : null,
                     cargando : false,
                     mostrarAlerta : {display: 'none'},
                  });

  }).catch(error => {
      console.error("Error al obtener la consulta de Casillas",error);
  });
}

fill = (number, len) =>
     "0".repeat(len - number.toString().length) + number.toString();


modificaCasilla = (datosCasilla) => {

  console.log("Entre para buscar la informacion de la casilla");

  const dataJson = {
    idDetalleProceso:info.ID_DETALLE_PROCESO,
    idProcesoElectoral:info.ID_PROCESO_ELECTORAL,
    idEstado: this.props.estado.idEstado,
    idDistrito: this.props.distrito.idDistrito,
    idCasilla: datosCasilla.idCasilla,
    folio: datosCasilla.folio,
  }


    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + 'ws/buscarCasillaModificar', dataJson,
    {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': this.props.user ? this.props.user.tknJWT : null
        }
    })
    .then(res => {
      const casillaModificar = res.data;
      const horarios = res.data.horariosCasilla;
      const nomCalle = res.data.calle;
      const numero = res.data.numero;
      const codigoPostal = res.data.codigoPostal !== null ? this.fill(res.data.codigoPostal,5): null
      const colonia = res.data.colonia
      const referencia = res.data.referencia
      const lugar = res.data.lugar
      const nombreC = res.data.nombreContacto
      const telefonoC = res.data.telefonoContacto
      const dispCE = res.data.equiposComputo
      const dispTI = res.data.equiposCelular
      const dispT = res.data.equiposTabletas
      const tieneInternet = res.data.tieneInternet
      const idCasilla = res.data.idCasilla
      const idParticipacion = res.data.idParticipacion
      const folio = res.data.folio
      const idTipoCasilla = res.data.idTipoCasilla
      const claveEscuela = res.data.claveEscuela
      const idTurnoEscuela = res.data.idTurnoEscuela
      const idNivelEducativo = res.data.idNivelEducativo

      for(var i = 0; i < horarios.length; i++){

        this.setState({
          fechaFI: horarios[i].fechaFormatoA,
          fechaFC: horarios[i].fechaFormatoC,
          horaHI: horarios[i].horaFormatoA,
          horaHC: horarios[i].horaFormatoC
        }, ()=>console.log(this.state.fechaFI))

        this.state.items.push({
          key: i,
          fechaFI: this.state.fechaFI,
          horaHI: this.state.horaHI,
          fechaFC: this.state.fechaFC,
          horaHC: this.state.horaHC
        })

      }

      this.setState({casillaModificar: casillaModificar,
                     calle: nomCalle,
                     num: numero,
                     cp: codigoPostal,
                     colonia: colonia,
                     referencia: referencia,
                     lugar: lugar,
                     nombreContacto: nombreC,
                     telefonoC: telefonoC,
                     dispCE: dispCE,
                     dispTI: dispTI,
                     dispT: dispT,
                     tieneInternet: tieneInternet,
                     fila: i,
                     idCasilla: idCasilla,
                     folio: folio,
                     idParticipacion: idParticipacion,
                     idTipoCasilla: idTipoCasilla,
                     claveEscuela: claveEscuela,
                     idTurnoEscuela: idTurnoEscuela,
                     idNivelEducativo: idNivelEducativo,
                     modifica: true,
                     mensajeAlerta : null,
                     cargando : false,
                     mostrarAlerta : {display: 'none'},
      });

  }).catch(error => {
      console.error("Error al obtener la casilla a modificar de Casillas",error);
  });
}


consultaTipoCasillas = () =>{

    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + 'ws/tipo_casilla', {},
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': this.props.user ? this.props.user.tknJWT : null
          
        }
      })
      .then(res => {
        const casilla = res.data.consultaCasilla;
        this.setState({casillas: casilla,
                     tiposCasillas: res.data,
                     mensajeAlerta : null,
                     cargando : false,
                     mostrarAlerta : {display: 'none'},
                  });

    }).catch(error => {
        console.error("Error al obtener la consulta de Casillas",error);
    });

}

eliminarCasilla = datosCasilla => {

  if(datosCasilla.estatus === 'A'){
    console.log("Es una casilla que tiene voluntarios asignados");
    this.setState({
      modalVoluntarios: true,

    });
  }else if(datosCasilla.estatus === 'R'){
    console.log("Casilla que si se puede eliminar");
    
    console.log(datosCasilla);

    const dataJson = {
    idDetalleProceso:info.ID_DETALLE_PROCESO,
    idProcesoElectoral:info.ID_PROCESO_ELECTORAL,
    idEstado:parseInt(this.props.estado.idEstado),
    idDistrito: parseInt(this.props.distrito.idDistrito),
    idParticipacion: datosCasilla.idParticipacion,
    idCasilla: datosCasilla.idCasilla,
    folio: datosCasilla.folio,
    usuario:this.props.user.username,
  }

  console.log(dataJson);

  axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + 'ws/elimina_casilla', dataJson,
    {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': this.props.user ? this.props.user.tknJWT : null
        }
    })
    .then(res => {
      if(res.data.code === 200){
        console.log("Se elimino Correctamente la Casilla"); 
        this.state.mostrarElimina = false;
        var pagina = this.state.actual;
        this.paginado(pagina);
        
  }}).catch(error => {
      console.error("Error al eliminar la Casilla",error);
  });

}
}

enviarModificacion = () => {
  let casilla = {
      idDetalleProceso: info.ID_DETALLE_PROCESO,
      idProcesoElectoral: info.ID_PROCESO_ELECTORAL,
      usuario: this.props.user.username,
      idTipoCasilla: this.state.idTipoCasilla,
      idCasilla: this.state.idCasilla,
      idParticipacion: this.state.idParticipacion,
      folio: this.state.folio,
      calle: this.state.calle,
      numero: this.state.num,
      cp: this.state.cp,
      colonia: this.state.colonia,
      ubicacion: this.state.lugar,
      referencia: this.state.referencia,
      nombreContacto: this.state.nombreContacto,
      telefonoC: this.state.telefonoC,
      computadoraEscritorio: this.state.dispCE,
      telefonoInteligente: this.state.dispTI,
      tablet: this.state.dispT,
      tieneInternet: this.state.tieneInternet,
      claveEscuela: this.state.claveEscuela,
      idNivelEducativo: this.state.idNivelEducativo,
      idTurnoEscuela: this.state.idTurnoEscuela,
      fechasHorasCasilla: this.state.items,
  };

  console.log(casilla);

  const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': this.props.user ? this.props.user.tknJWT : null
  };

  axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL +
          "ws/modifica_casilla"
      , casilla
      , {
          headers
      }
  ).then(_response => {
      this.setState({abrirModalExitoModifica: true})
  }).catch(error => {
      this.setState({cargando: false});
      console.error("Error al guardar la casilla "+
          "enviarServidor()",error);
      if (error.response){
          if (error.response.status === 409){
              msjError = error.response.data.message;
          } else if (error.response.data && error.response.data.causa
              && error.response.data.causa.includes("Error de validación")){
              msjError = error.response.data.message;
          } else {
              msjError = etiquetas.gral_error_cau;
          }
      } else {
          msjError = etiquetas.gral_error_cau;
      }
      this.setState({
        msjError : msjError,
        abrirModalError : true
      });
  });
  }

  getTurnosEscuelas = () =>{

    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + 'ws/turnosEscuelas'
      , {},
      {
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': this.props.user ? this.props.user.tknJWT : null
          }
      }
    ).then(res => {
      const turnosEscuelas = res.data;
      this.setState({lstTurnosEscuelas: turnosEscuelas,
                     mensajeAlerta : null,
                     cargando : false,
                     mostrarAlerta : {display: 'none'},
                  });
  
      }).catch(error => {
        console.error("Error al obtener los tipos de casilla en consulta",error);
      });
  
  }
  
  getNivelesEducativos = () =>{
  
    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + 'ws/nivelesEducativos'
      , {},
      {
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': this.props.user ? this.props.user.tknJWT : null
          }
      }
    ).then(res => {
      const nivelesEducativos = res.data;
      this.setState({lstNivelesEducativos: nivelesEducativos,
                     mensajeAlerta : null,
                     cargando : false,
                     mostrarAlerta : {display: 'none'},
                  });
  
      }).catch(error => {
        console.error("Error al obtener los tipos de casilla en consulta",error);
      });
  
  }

paginado = pagina =>{
  console.log("la pagina es", pagina);
  var paginaAnterior = parseInt(pagina,10) - 1 === 0 ? 1 :
                                                      parseInt(pagina,10) - 1;
  this.setState({
    actual : pagina,
    cargando: true,
    casillas: []
  });

  var inicio = parseInt(pagina,10) === 1 ? 1 : (paginaAnterior * 5)+1;
  var fin = parseInt(pagina,10) * 5;

  this.enviarConsulta(inicio,fin);
}

selectTurnoEscuela = (valor) => {
  console.log("Entro para Turno Escuela");
  if(valor != null){
    this.state.idTurnoEscuela = valor;

  }else{
    this.state.idTurnoEscuela = null;
  }
}


selectNivelEducativo = (valor) => {
  console.log("Entro para asignar zores");
  if(valor != null){
    this.state.idNivelEducativo = valor;

  }else{
    this.state.idNivelEducativo = null;
}
}

regresarPrimeraPagina = () =>{
  this.setState({
    paginaActual : 1,
    actual: 1
  });
  this.enviarConsulta();
}

changeTab = activeKey => {
  console.log(activeKey);
  this.setState({
    activeTab: activeKey
  });
};

changeTabModifica = activeKey => {
  this.setState({
    activeTabModifica: activeKey
  });
};


cambioText = (e) =>{
  this.setState({
      [e.target.name]: e.target.value
  })
}

changeCE = (e) =>{
  this.setState({
      dispCE: e
  }, ()=>console.log(this.state.dispCE))
}

changeTI = (e) =>{
  this.setState({
      dispTI: e
  }, ()=>console.log(this.state.dispTI))
}

changeT = (e) =>{
  this.setState({
      dispT: e
  }, ()=>console.log(this.state.dispT))
}

opcionSN = (e) =>{
  this.setState({
      tieneInternet: e.target.value
  })
}

onChangeFI = (dateString) => {
  this.setState({fechaFI: dateString},()=>console.log(this.state.fechaFI))
}

onChangeFC = (dateString) => {
  this.setState({fechaFC: dateString},()=>console.log(this.state.fechaFC))
}

onChangeTimeHI = (timeString) => {
  this.setState({horaHI: timeString},()=>console.log(this.state.horaHI))
}

onChangeTimeHC = (timeString) => {
  this.setState({horaHC: timeString},()=>console.log(this.state.horaHC))
}

handleDelete = (key) => {
  const items = [...this.state.items];
  this.setState({
    items: items.filter((item) => item.key !== key),
  }, ()=>console.log(this.state.items));

  this.revisionFecha();
};

revisionFecha = () =>{

  var cantidadFechas = this.state.items;

  console.log(cantidadFechas.length )

  if(cantidadFechas.length >= 1){
      this.state.botonGuardar = false;
  }else{
      this.state.botonGuardar = true;
  }

}

handleClick = (ky, fechaI, fechaC, horaI, horaC) => {

  console.log("Entre a handle click")

  var items = this.state.items;

  this.state.items.push({
      key: ky,
      fechaFI: fechaI,
      horaHI: horaI,
      fechaFC: fechaC,
      horaHC: horaC
  });

}

onChangeRoute =()=>{
  console.log("Entre a cambio de ruta")
  this.setState({abrirModalExitoModifica: false});
  window.location.reload();
}



render(){
  return(

    <AuthenticatedComponent>
      <CTemplate contenido = {
        
        <VConsulta 
          mostrar={this.state.mostrar}
          data = {this.state.casillas}
          total = {this.state.total}
          actual = {this.state.actual}
          paginado = {this.paginado}
          mostrarModal = {this.mostrarModal}
          ocultarModal = {this.ocultarModal}
          casillaSeleccionadaModal = {this.state.casillaSeleccionadaModal}
          cargando = {this.state.cargando}
          tipoAlerta = {this.state.tipoAlerta}
          mensajeAlerta = {this.state.mensajeAlerta}
          mostrarAlerta = {this.state.mostrarAlerta}
          searchFolio = {this.searchFolio}
          searchMunicipio = {this.searchMunicipio}
          searchSeccion = {this.searchSeccion}
          searchTipoCasilla = {this.searchTipoCasilla}
          searchZore = {this.searchZore}
          tiposCasillas = {this.state.tiposCasillas}
          changeTab={this.changeTab}
          activeTab = {this.state.activeTab}
          regresarPrimeraPagina={this.regresarPrimeraPagina}
          eliminarCasilla = {this.eliminarCasilla}
          modalVoluntarios = {this.state.modalVoluntarios}
          ocultarModalVoluntarios = {this.ocultarModalVoluntarios}
          estatusModulo = {this.state.estatus}
          modificaCasilla = {this.modificaCasilla}
          modifica = {this.state.modifica}
          casillaModificar = {this.state.casillaModificar}
          cambioText ={this.cambioText}
          changeTabModifica = {this.changeTabModifica}
          activeTabModifica = {this.state.activeTabModifica}
          changeCE = {this.changeCE}
          changeTI = {this.changeTI}
          changeT = {this.changeT}
          opcionSN = {this.opcionSN}
          items = {this.state.items}
          onChangeFI = {this.onChangeFI}
          onChangeFC = {this.onChangeFC}
          onChangeTimeHI = {this.onChangeTimeHI}
          onChangeTimeHC = {this.onChangeTimeHC}
          calle = {this.state.calle}
          num = {this.state.num}
          cp = {this.state.cp}
          col = {this.state.colonia}
          referencia = {this.state.referencia}
          lug = {this.state.lugar}
          nombreC = {this.state.nombreContacto}
          telC = {this.state.telefonoC}
          computadorasE = {this.state.dispCE}
          telefonosI = {this.state.dispTI}
          tabletas = {this.state.dispT}
          tieneInternet = {this.state.tieneInternet}
          handleDelete = {this.handleDelete}
          fila = {this.state.key}
          fechaI = {this.state.fechaFI}
          fechaC = {this.state.fechaFC}
          horaI = {this.state.horaHI}
          horaC = {this.state.horaHC}
          handleClick = {this.handleClick}
          enviarModificacion = {this.enviarModificacion}
          abrirModalExitoModifica = {this.state.abrirModalExitoModifica}
          onChangeRouteModifica = {this.onChangeRoute}
          fila = {this.state.fila}
          activarZonaEscolar = {this.state.activarZonaEscolar}
          claveEscuela = {this.state.claveEscuela}
          idTipoCasilla = {this.state.idTipoCasilla}
          idNivelEducativo = {this.state.idNivelEducativo}
          idTurnoEscuela = {this.state.idTurnoEscuela}
          folio ={this.state.folio}
          mostrarModalElimina = {this.mostrarModalElimina}
          mostrarElimina = {this.state.mostrarElimina}
          ocultarModalElimina = {this.ocultarModalElimina}
          casillaEliminar = {this.state.casillaEliminar}
          lstTurnosEscuelas = {this.state.lstTurnosEscuelas}
          lstNivelesEducativos = {this.state.lstNivelesEducativos}
          selectTurnoEscuela = {this.selectTurnoEscuela}
          selectNivelEducativo = {this.selectNivelEducativo}
          ocultarModalSinGuardar = {this.ocultarModalSinGuardar}
          mostrarSinGuardar = {this.state.mostrarSinGuardar}
          salirSinGuardar = {this.salirsinGuardar}
          rolCau = {this.state.usuarioCau}
          direcciones = {this.state.listDireccionesCI}
          casillaItinerante = {this.state.tipoCasillaItinerante}
          rolPermitido = {this.state.rolPermitido}
        /> 
      }
      guia = {false}/>
    </AuthenticatedComponent>
  )
}


}

const mapStateToProps = createStructuredSelector({
  estado: selectEstado,
  distrito: selectDistrito,
  user: selectCurrentUser
});

export default connect(mapStateToProps)(CConsultaCasilla);