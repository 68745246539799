import React from 'react';
import { createStructuredSelector } from 'reselect';
import {connect} from 'react-redux';
import axios from 'axios';
import {Space, Button, Tooltip, message} from 'antd';
import { TeamOutlined, EditOutlined, SearchOutlined  } from '@ant-design/icons';
import { selectEstado, selectDistrito } from '../../../redux/menu/menu-selectors';
import { selectCurrentUser, selectCurrentUserRol } from '../../../redux/user/user-selectors';
import * as etiquetas from '../../../constants/EtiquetasGenerales';
import * as info from '../../../constants/InfoSistema';
import CTemplate from '../../homeInterno/CTemplate';
import AuthenticatedComponent from '../../../components/comunes/AuthenticatedComponent';
import VZoreVoluntarios from '../../../components/casillas/asignacion/VZoreVoluntarios';
import {obtenerEstatusModulo} from '../../../redux/menu/menu-utils';
import {roles_cap} from '../../../constants/EtiquetasAsigZores';

const jwt = localStorage.getItem('jwt-token');

class CZoresVoluntarios extends React.Component{
  
  constructor(props){
    super(props);
    this.state = {
      activarCasilla : true,
      page: 1,
      infoZonasResponsabilidadCasilla : [],
      paginas: 0,
      paginasVoluntarios: 0,
      paginado:0, 
      actual:0,
      actualVoluntarios:0,
      paginadoVoluntarios:0,    
      datosCasilla : [],
      infVountarioZores: [],
      infoVoluntariosAsignados: [],
      infoVoluntariosAgregados: [],
      infoVoluntarioZonaEliminar: [],
      ocultarCasillas: false,
      ocultarVoluntarios: true,
      mensajeNoExistenDatos: false,
      mensajeNoExisteCasilla: false,
      tipoAlerta : "warning",
      mensajeAlerta : "",
      mostrarAlerta : {display:'none'},
      recuperaDatos:[],
      idZonaResponsabilidad: 0,
      idParticipacion:0,
      idDetalleProceso:0,
      numeroZonaResponsabilidad: 0,
      mensajeAdvertencia: false,
      mensajeVolEliminado: false,
      isModalVisible: false,
      tipoZona: "",
      seccion: 0,
      distrito: 0,
      zore: 0,
      idZonaResponsabilidads:[],
      secciones:[],
      idMunicipios: [],
      busquedaPorNumeroZore: null,
      busquedaPorSeccion: null,
      busquedaPorMunicipio: null,
      busquedaPorVolAsignados: null,
      busquedaPorTipoZore: null,
      isModalVisibleDatosGuradados: false,
      isModalVisibleDatosGuradados2: false,
      folio: null,
      nombreVoluntario: null,
      banderaModificacion: false,
      banderaBotonGuardar: true,
      mensajeAnadido: false,
      infoMunicipios: [],
      idDistrito: null,
      infoTiposZona: [],
      puedeAcceder : false,
      respuestaApertura: "C"
    }
    console.info("el usuario con el rol ", this.props.user.rolUsuario,
    "está bloqueado ",!info.ROLES_CONSULTA[this.props.user.rolUsuario]);
  }

  componentDidMount(){
  	/*this.obtenerZoreVoluntarios();
  	this.setState({
      puedeAcceder : true,
      respuestaApertura: "A"
    })*/
   	this.consultarEstatusModulo();  
   	this.consultarPermisoUsuario();
  }
consultarEstatusModulo(){
    var idProcesoElectoral = this.props.user.idProcesoElectoral;
    var idDetalleProceso = this.props.user.idDetalle;
    var idEstado = this.props.estado.idEstado;
    var idDistrito = this.props.distrito.idDistrito;
    var idSistema = info.ID_SISTEMA;
    var idModulo = this.props.location.state.idModulo;
    var grupoSistema = this.props.user.rolUsuario;

    obtenerEstatusModulo(idProcesoElectoral, idDetalleProceso,idSistema,
      idEstado, idDistrito, idModulo, grupoSistema).then(respuesta =>{
      console.info("El estatus del módulo asignación de ZOR es: ",respuesta);
      this.setState({
	      respuestaApertura : respuesta
	    })
      if(respuesta === "A"){
        console.info("El Módulo asignación de ZOR está abierto ");
        this.obtenerZoreVoluntarios();
      }else{
        console.info("El Módulo asignación de zore está cerrado ");
      }
    })
  }
  consultarPermisoUsuario = () =>{
    this.setState({
      puedeAcceder : roles_cap.includes(this.props.user.rolUsuario)
    })
  }
  componentDidUpdate(){  
    if(this.state.idDistrito != this.props.distrito.idDistrito){
      this.setState({
        ocultarCasillas:false,
        ocultarVoluntarios:true
      });
      this.obtenerZoreVoluntarios();
    }
  }

  /**
  * @author arturo.gomez
  **/
    paginacionZore = (valor) =>{
      console.log(valor);
      const valorFinal = (valor -1)*5;
      this.state.paginado=valorFinal;      
      console.log(this.state.paginado);
      this.obtenerCasillasAsig();       
    }
   
    busquedaNumeroZore = (valor) =>{
      console.log("VALOR DEL FOLIO CASILLA **************************");
      if(valor!=""){
        this.state.busquedaPorNumeroZore = valor;
      }else{
        this.state.busquedaPorNumeroZore = null;
      }
              
      console.log(this.state.busquedaPorNumeroZore);
      this.obtenerZoreVoluntarios();
    }    
    busquedaSeccion = (valor) =>{
      console.log("VALOR DE LA SECCION**************************");
      if(valor!=""){
        this.state.busquedaPorSeccion = valor;
      }else{
        this.state.busquedaPorSeccion = null;
      }
              
      console.log(this.state.busquedaPorSeccion);
      this.obtenerZoreVoluntarios();
    }

    busquedaMunicipio = (valor) =>{
      console.log("VALOR DEL MUNICIPIO**************************");     
      if(valor!=""){
        this.state.busquedaPorMunicipio = valor;
      }else{
        this.state.busquedaPorMunicipio = null;
      }
              
      console.log(this.state.busquedaPorMunicipio);
      this.obtenerZoreVoluntarios();
    }

    busquedaTipoZore = (valor) =>{
      console.log("VALOR TIPOS CASILLAS**************************");     
      if(valor!=""){
        this.state.busquedaPorTipoZore = valor;
      }else{
        this.state.busquedaPorTipoZore = null;
      }
              
      console.log(this.state.busquedaPorTipoZore);
     this.obtenerZoreVoluntarios();
    }

    busquedaVolAsignados = (valor) =>{
      console.log("VALOR Voluntarios asignados**************************");     
      if(valor!=""){
        this.state.busquedaPorVolAsignados = valor;
      }else{
        this.state.busquedaPorVolAsignados = null;
      }
              
      console.log(this.state.busquedaPorVolAsignados);
      this.obtenerZoreVoluntarios();
    }

    busquedaFolioVol = (valor) =>{
      if(valor!=""){
        this.state.folio = valor.toUpperCase();
        this.state.nombreVoluntario =null;
      }else{
        this.state.folio = null;
        this.state.nombreVoluntario =null;
      }
              
      console.log(this.state.numeroZonaResponsabilidad);
      var values = {
      	nombreVoluntario: this.state.nombreVoluntario, //datosUsuario !== null ? datosUsuario.idEstado : 0,
      	idDistrito: this.props.distrito.idDistrito,
      	idEstado :this.props.estado.idEstado,
      	folio: this.state.folio,
      	seccion: this.state.seccion,
      	idZonaResponsabilidad: this.state.idZonaResponsabilidad
  	  };  
      this.obtenerVoluntariosAsig(values);
    }

    busquedaVoluntario = (valor) =>{
      if(valor!=""){
        this.state.nombreVoluntario = valor;
        this.state.folio = null;
      }else{
        this.state.nombreVoluntario =null;
        this.state.folio = null;        
      }
      var values = {
      	nombreVoluntario: this.state.nombreVoluntario, //datosUsuario !== null ? datosUsuario.idEstado : 0,
      	idDistrito: this.props.distrito.idDistrito,
      	idEstado :this.props.estado.idEstado,
      	folio: this.state.folio,
      	seccion: this.state.seccion,
      	idZonaResponsabilidad: this.state.idZonaResponsabilidad
  	  };  
      console.log(this.state.nombreVoluntario);
      this.obtenerVoluntariosAsig(values);
    }
   
  /**
  * Obtiener casillas con zores
  * 
  */
  obtenerZoreVoluntarios = (values) =>{
    this.state.infoZonasResponsabilidadCasilla = [];
    this.setState({
    	banderaBotonGuardar: true
    });
    this.state.banderaModificacion = false; 
    this.state.idDistrito = this.props.distrito.idDistrito; 
    this.state.infoTiposZona=[];
    var zona={idTipoZona:"URBANA",tipoZona:"URBANA"};
    this.state.infoTiposZona.push(zona);
    var zona={idTipoZona:"NO URBANA",tipoZona:"NO URBANA"};
    this.state.infoTiposZona.push(zona);
    var zona={idTipoZona:"MIXTA",tipoZona:"MIXTA"};
    this.state.infoTiposZona.push(zona);
    if(values != undefined){
        this.state.recuperaDatos.push({
          numeroZonaResponsabilidad: values.numeroZonaResponsabilidad,
          idCasilla: values.idCasilla,
          seccion: values.seccion,
          volunAsig: values.volunAsig,
          idParticipacionCas: values.idParticipacion
        });
        if(values.volunAsig>0){
          this.state.banderaModificacion = true;
        }
      }
    var datosUsuario = localStorage.getItem('datos-usuario') !== null ?
    JSON.parse(localStorage.getItem('datos-usuario')): null;   
    var postData = {
      idEstado: this.props.estado.idEstado, //datosUsuario !== null ? datosUsuario.idEstado : 0,
      idDistrito: this.props.distrito.idDistrito, //datosUsuario !== null ? datosUsuario.idDistrito : 0,
      idProcesoElectoral: info.ID_PROCESO_ELECTORAL,
      idDetalleProceso: info.ID_DETALLE_PROCESO,
      registro_inicio: 1,
      registro_fin: 5,
      numerozore: this.state.busquedaPorNumeroZore,
      seccion: this.state.busquedaPorSeccion,
      nombreMunicipio: this.state.busquedaPorMunicipio,
      volAsignados : this.state.busquedaPorVolAsignados,
      tipoZore : this.state.busquedaPorTipoZore,
    };  
    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL +
      "ws/obtenerzorevoluntarios", postData,
          {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.user ? this.props.user.tknJWT : null
              }
          }
       ).then(response => {        
        if(response.data.code === 200){       
         this.setState({
           infoZonasResponsabilidadCasilla:response.data.infoZonasResponsabilidadCasilla,
           infoMunicipios:response.data.infoMunicipios,
           paginas:response.data.totalCasillas,
           paginado:0
         });       
        }else{
          this.setState({
            infoZonasResponsabilidadCasilla: [],
            busquedaPorNumeroZore: null,
            busquedaPorSeccion: null,
            paginas:0
          });
        }
        console.log("PRIMERO DATOS PARA OBTENER CASILLAS");
        console.log(this.state.infoZonasResponsabilidadCasilla);

      }).catch(error => {
        console.error("Error al obtener las casillas",error);
    });
  }

  /**
  * @author arturo.gomez  * 
  **/
    paginacionVoluntarios = (valor) =>{
      console.log("VALOR-------------------------");
      console.log(valor);
      const valorFinal = (valor -1)*5;
      this.state.paginadoVoluntarios=valorFinal;      
      console.log(this.state.paginadoVoluntarios);        ;
      this.obtenerVoluntariosAsig();      
    }   

   /**
  * Obtiene los voluntarios.
  */
    obtenerVoluntariosAsig = (values) =>{
      console.log("OBTENER VOLUNTARIOS");
      console.log(values);
      this.setState.mensajeAdvertencia = false;   
      this.state.banderaBotonGuardar = true;   
      	if(values.inicio=== undefined){
      		values.inicio=1
      	}
      	if(values.fin=== undefined){
      		values.fin=5
      	}
      var idParticipacion=0;
      if(values != undefined){
        this.state.recuperaDatos.push({
          numeroZonaResponsabilidad: values.numeroZonaResponsabilidad,
          idZonaResponsabilidad: values.idZonaResponsabilidad,
          seccion: values.seccion,
          idMunicipio: values.idMunicipio,
          volunAsig: values.volunAsig,
          registro_inicio: values.inicio,
          registro_fin: values.fin,
        });
        if(values.idParticipacion!= undefined){
	        this.setState({
	          idParticipacion:values.idParticipacion
	        });
	        idParticipacion=values.idParticipacion;
    	}else{
			idParticipacion=this.state.idParticipacion;
    	}
        if(values.volunAsig>0){
          this.state.banderaModificacion = true;
        }
      }
      var datosUsuario = localStorage.getItem('datos-usuario') !== null ?
          JSON.parse(localStorage.getItem('datos-usuario')): null;
          this.state.infVountarioZores=[];
          this.state.infoVoluntariosAsignados=[];
          var postData = {};
          this.state.recuperaDatos?.map((dato)=>{
            var postData1 = {
              idEstado: this.props.estado.idEstado, 
              idDistrito: this.props.distrito.idDistrito,
              idProcesoElectoral : info.ID_PROCESO_ELECTORAL,
              idDetalleProceso : info.ID_DETALLE_PROCESO,
              idParticipacion:idParticipacion,
              numerozore: dato.numeroZonaResponsabilidad,
              idZonaResponsabilidad : dato.idZonaResponsabilidad,
              folioVoluntario: this.state.folio,
              nombreVoluntario: this.state.nombreVoluntario,
              registro_inicio: values.inicio,
          	  registro_fin: values.fin
            };

            postData = postData1;
            this.setState({
              idZonaResponsabilidad: dato.idZonaResponsabilidad,
              seccion : dato.seccion
          	});
          	if(dato.numeroZonaResponsabilidad != undefined){
	            this.setState({
	              numeroZonaResponsabilidad: dato.numeroZonaResponsabilidad
	            });
        	}
             console.log(this.state.numeroZonaResponsabilidad);
          });          
      axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL +
        "ws/obtenervoluntariosconzore", postData,
            {
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': this.props.user ? this.props.user.tknJWT : null
                }
            }
         ).then(response => { 
          if(response.data.code === 200){
                this.setState({
                infVountarioZores:response.data.infVountarioZores,
                infoVoluntariosAsignados:response.data.infoVoluntariosAsignados,
                paginasVoluntarios:response.data.totalVoluntarios,
                ocultarCasillas:true,
                ocultarVoluntarios:false,
                paginadoVoluntarios: 0
              });
              if(postData.nombreVoluntario!==null&& postData.folioVoluntario!==null){
				this.setState({
					infoVoluntarioZonaEliminar:[],
					infoVoluntariosAgregados:[],
					mensajeAlerta : "",
		            tipoAlerta : "info",
		            mostrarAlerta : {display: 'none',}
					});
				}    
            if(this.state.infoVoluntarioZonaEliminar!== null){
		        //eliminar voluntario
		        this.state.infoVoluntarioZonaEliminar.forEach( function(valor, indice, array) {
				    console.log("En el índice " + indice + " hay este valor: " + valor);
				    if(this.state.infoVoluntariosAgregados!== null)
				    this.state.infoVoluntariosAgregados.forEach( function(valor2, indice2, array2) {
						if(valor.idVoluntario===valor2.idVoluntario){
							this.state.infVountarioZores.push(valor);
						}
					});
				    var index = this.state.infoVoluntariosAsignados.findIndex(function(o){
				     return o.idVoluntario === valor.idVoluntario;});
					if (index !== -1) this.state.infoVoluntariosAsignados.splice(index, 1);
					
				});
				//agregar voluntario -FIN     
			}
        	if(this.state.infoVoluntariosAgregados!== null){
		        //agregar voluntario
		        this.state.infoVoluntariosAgregados.forEach( function(valor, indice, array) {
				    console.log("En el índice " + indice + " hay este valor: " + valor);
				    if(this.state.infoVoluntariosAsignados!== null)
				    this.state.infoVoluntariosAsignados.forEach( function(valor2, indice2, array2) {
						if(valor.idVoluntario===valor2.idVoluntario){
							this.state.infoVoluntariosAsignados.push(valor);
						}
					});
				    var index = this.state.infVountarioZores.findIndex(function(o){
				     return o.idVoluntario === valor.idVoluntario;
					})
					if (index !== -1) this.state.infVountarioZores.splice(index, 1);
				});
				
				//agregar voluntario -FIN     
			}
          }else if(response.data.code === 204){       
		         this.setState({mensajeAlerta : response.data.message,
		                     tipoAlerta : "warning",
		                     mostrarAlerta : {display: 'block'},
		                     banderaBotonGuardar:true,
		        });       
		        }else{
            this.setState({
              mensajeNoExistenDatos: true

            });
          }
          console.log("infVountarioZores");
          console.log(this.state.infVountarioZores);
          console.log("infoVoluntariosAsignados");
          console.log(this.state.infoVoluntariosAsignados);
          
        }).catch(error => {
        	console.log(error);
          console.error("Error al obtener las casillas",error);
      });
    }

  /**
  * Asignar los voluntarios.
  */
  asignarCasillas = (values) =>{
  	if(this.state.puedeAcceder){
	    if(this.state.infoVoluntariosAsignados.length<2){
	        this.state.folio = null;
	        this.state.nombreVoluntario =null;       
	        
	        var datosUsuario = localStorage.getItem('datos-usuario') !== null ?
	        JSON.parse(localStorage.getItem('datos-usuario')): null;
	        console.log("infVountarioZores");
	        console.log(this.state.infVountarioZores);
	        console.log("infoVoluntariosAsignados");
	        console.log(this.state.infoVoluntariosAsignados);
	        var voluntarioZore = {
	        		  idVoluntario : values.idVoluntario,
	                  folio: values.folio,
	                  nombreVoluntario: values.nombreVoluntario,
	                  idDetalleProceso : info.ID_DETALLE_PROCESO,
	                  idParticipacion:values.idParticipacion,
	                  numeroZonaResponsabilidad:this.state.numeroZonaResponsabilidad,
	                  idZonaResponsabilidad : this.state.idZonaResponsabilidad,
	                  usuario : this.props.user.username,
	                  ipUsuario: this.props.user.ip,
	                  seccion: values.seccion
	        };
	        console.log(voluntarioZore);
	        if(this.state.infoVoluntariosAsignados!==null)
	  		this.state.infoVoluntariosAsignados.push(voluntarioZore);
	  		if(this.state.infoVoluntariosAgregados!==null)
	  		this.state.infoVoluntariosAgregados.push(voluntarioZore);
	  		//eliminar voluntario
			var index = this.state.infVountarioZores.findIndex(function(o){
			     return o.idVoluntario === voluntarioZore.idVoluntario;
			})
			if (index !== -1) this.state.infVountarioZores.splice(index, 1);
			if (this.state.infoVoluntarioZonaEliminar !== null){
				var index = this.state.infoVoluntarioZonaEliminar.findIndex(function(o){
			     return o.idVoluntario === voluntarioZore.idVoluntario;
				})
				if (index !== -1) this.state.infoVoluntarioZonaEliminar.splice(index, 1);
			}
			//eliminar voluntario -FIN
	  		 this.setState({
		        infVountarioZores:this.state.infVountarioZores,
				infoVoluntariosAsignados:this.state.infoVoluntariosAsignados,
	        	infoVoluntariosAgregados:this.state.infoVoluntariosAgregados,
	        	infoVoluntarioZonaEliminar:this.state.infoVoluntarioZonaEliminar,
	        	mensajeAlerta : "",
	            tipoAlerta : "info",
	            mostrarAlerta : {display: 'none',},
	            banderaBotonGuardar:false
	        });

	    }else{
	    	this.setState({mensajeAlerta : "La zona de responsabilidad ya tiene el número máximo de usuarios MSPEN asignados.",
	                     tipoAlerta : "warning",
	                     mostrarAlerta : {display: 'block'}
	        });
	    }
		}else{
			this.setState({mensajeAlerta : "No cuentas con permisos para asignar.",
	                     tipoAlerta : "warning",
	                     mostrarAlerta : {display: 'block'},
	                     banderaBotonGuardar:true,
	        });
	}
  }
  
  errorEnFormulario = (errorInfo) => {
		console.info("Error en formulario, ver la parte de errores.");
		console.error('Failed:', errorInfo);
  };

   eliminarVoluntario = (values) =>{
	   	if(this.state.puedeAcceder){
	    	this.state.folio = null;
	    	this.state.nombreVoluntario =null;      
	    	var datosUsuario = localStorage.getItem('datos-usuario') !== null ?
	    	JSON.parse(localStorage.getItem('datos-usuario')): null;

	        var voluntarioZore = {
				idVoluntario : values.idVoluntario,
	            folio: values.folio,
	            nombreVoluntario: values.nombreVoluntario,
	            idDetalleProceso : info.ID_DETALLE_PROCESO,
	            idParticipacion:values.idParticipacion,
	            idZonaResponsabilidad : this.state.idZonaResponsabilidad,
	            usuario : this.props.user.username,
	            ipUsuario: this.props.user.ip,
	            seccion: values.seccion         
	          };  
	    	console.log(voluntarioZore);
	    	if (this.state.infVountarioZores !== null)
	  		this.state.infVountarioZores.push(voluntarioZore);
	  		if (this.state.infoVoluntarioZonaEliminar !== null)
	  		this.state.infoVoluntarioZonaEliminar.push(voluntarioZore);
	  		//eliminar voluntario
			var index = this.state.infoVoluntariosAsignados.findIndex(function(o){
			     return o.idVoluntario === voluntarioZore.idVoluntario;
			})
			if (index !== -1) this.state.infoVoluntariosAsignados.splice(index, 1);
			if (this.state.infoVoluntariosAgregados !== null){
				var index = this.state.infoVoluntariosAgregados.findIndex(function(o){
			     return o.idVoluntario === voluntarioZore.idVoluntario;
				})
				if (index !== -1) this.state.infoVoluntariosAgregados.splice(index, 1);
			}
			//eliminar voluntario -FIN
	  		 this.setState({
		        infVountarioZores:this.state.infVountarioZores,
				infoVoluntariosAsignados:this.state.infoVoluntariosAsignados,
				infoVoluntariosAgregados: this.state.infoVoluntariosAgregados,
	        	infoVoluntarioZonaEliminar:this.state.infoVoluntarioZonaEliminar,
	        	mensajeAlerta : "",
	            tipoAlerta : "info",
	            mostrarAlerta : {display: 'none'},
	            banderaBotonGuardar: false
	        });
	  		}else{
				this.setState({mensajeAlerta : "No cuentas con permisos para asignar.",
		                     tipoAlerta : "warning",
		                     mostrarAlerta : {display: 'block'},
		                     banderaBotonGuardar:true,
		        });
			}
  	}
    guardarDatos = ()  =>{
    	if(this.state.infoVoluntariosAsignados.length<3){
	      console.log("BOTON GUARDAR");
	      if(this.state.puedeAcceder){
	      if(!this.state.banderaBotonGuardar){
	        var postData = {
	        	infoVoluntarioZonaAsignar : this.state.infoVoluntariosAgregados,
	        	infoVoluntarioZonaEliminar: this.state.infoVoluntarioZonaEliminar,
	        };
	        console.log(postData);
			axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL +
	          "ws/asignarZoreVoluntarios", postData,
	              {
	                  headers: {
	                    'Accept': 'application/json',
	                    'Content-Type': 'application/json',
	                    'Authorization': this.props.user ? this.props.user.tknJWT : null
	                  }
	              }
	           ).then(response => {          
	            if(response.data.code === 200){
            if(this.state.infoVoluntariosAsignados.length>0){
              this.setState({
                isModalVisibleDatosGuradados2: true
              });
            }else{
              this.setState({
                isModalVisibleDatosGuradados: true
              });
            }
		        this.setState({
		        	infVountarioZores:[],
		        	infoVoluntariosAsignados:[],
		          infoVoluntarioZonaEliminar:[],
		          infoVoluntarioZonaAsignar:[],
		          busquedaPorFolioCasilla:null,
		          busquedaPorSeccion: null,
			    	  busquedaPorMunicipio:null,
		          banderaBotonGuardar: true
		        });
				}      
	          }).catch(error => {
	            console.error("Error ",error);
	        });
	      }
	      }else{
				this.setState({mensajeAlerta : "No tienes permisos de guardado",
		                     tipoAlerta : "warning",
		                     mostrarAlerta : {display: 'block'},
		                     banderaBotonGuardar:true,
		        });
			}
		}else{
			this.setState({mensajeAlerta : "No puedes asignar más de dos personas MSPEN para esta zona de responsabilidad, elimina algún elemento de la lista de asignados para continuar.",
		                     tipoAlerta : "warning",
		                     mostrarAlerta : {display: 'block'},
		                     banderaBotonGuardar:true,
		    });
		}
	}
errorEnFormulario = (errorInfo) => {
console.info("Error en formulario, ver la parte de errores.");
console.error('Failed:', errorInfo);
};
removeItem = (lista, values ) => {
	return lista.filter( e => e !== values );
};

modalInfo = (value) =>{

  var datosUsuario = localStorage.getItem('datos-usuario') !== null ?
        JSON.parse(localStorage.getItem('datos-usuario')): null;

  this.setState({
    isModalVisible: true,
    numeroZonaResponsabilidad: value.numeroZonaResponsabilidad,
    idZonaResponsabilidad: value.idZonaResponsabilidad,
    tipoCasilla: value.tipoCasilla,
    seccion: value.seccion,
    distrito: this.props.distrito.idDistrito,
    zore: value.idZonaResponsabilidad,
    ubicacion: value.ubicacion
  });
}

handleCancel = () =>{
  this.setState({
    isModalVisible: false,
    mensajeVolEliminado: false,
    mensajeAdvertencia: false,
    mensajeAnadido: false
  });
}

handleCancel2 = () =>{
  this.setState({
  	mensajeAlerta : "",
            tipoAlerta : "info",
            mostrarAlerta : {display: 'none'},
    isModalVisibleDatosGuradados: false,
    isModalVisibleDatosGuradados2: false,
    ocultarVoluntarios: true,
    ocultarCasillas: false ,
    mensajeVolEliminado: false,
    mensajeAdvertencia: false  
  });
  this.state.paginado= 0;
  this.state.busquedaPorMunicipio = null;
  this.state.busquedaPorNumeroZore =null;
  this.state.busquedaPorSeccion = null;
  this.obtenerZoreVoluntarios(); 
}
paginado = pagina =>{
  console.log("la pagina es", pagina);
  var paginaAnterior = parseInt(pagina,10) - 1 === 0 ? 1 :
                                                      parseInt(pagina,10) - 1;
  this.setState({
    actual : pagina,
    cargando: true,
    casillas: []
  });

  var inicio = parseInt(pagina,10) === 1 ? 1 : (paginaAnterior * 5)+1;
  var fin = parseInt(pagina,10) * 5;

  this.enviarConsulta(inicio,fin);
}
paginadoVoluntarios = pagina =>{
  console.log("la pagina es", pagina);
  var paginaAnterior = parseInt(pagina,10) - 1 === 0 ? 1 :
                                                      parseInt(pagina,10) - 1;
  this.setState({
    actualVoluntarios : pagina,
    cargando: true,
    casillas: []
  });

  var inicio = parseInt(pagina,10) === 1 ? 1 : (paginaAnterior * 5)+1;
  var fin = parseInt(pagina,10) * 5;
  var values = {
   	inicio:inicio,
   	fin:fin,
   	idZonaResponsabilidad: this.state.idZonaResponsabilidad,
    seccion: this.state.seccion
   }
  this.obtenerVoluntariosAsig(values);
}
regresarPrimeraPagina = () =>{
  this.setState({
    paginaActual : 1,
    actual: 1
  });
  this.enviarConsulta();
}
regresarPrimeraPaginaVoluntarios = () =>{
  this.setState({
    paginaActual : 1,
    actualVoluntarios: 1
  });
   var values = {
   	inicio:1,
   	fin:5
   }
  this.obtenerVoluntariosAsig(values);
}
enviarConsulta = (inicio=1,fin=5) => {

  var postData = {
      idEstado: this.props.estado.idEstado, //datosUsuario !== null ? datosUsuario.idEstado : 0,
      idDistrito: this.props.distrito.idDistrito, //datosUsuario !== null ? datosUsuario.idDistrito : 0,
      idProcesoElectoral: info.ID_PROCESO_ELECTORAL,
      idDetalleProceso: info.ID_DETALLE_PROCESO,
      registro_inicio: inicio,
      registro_fin: fin,
      numerozore: this.state.busquedaPorNumeroZore,
      seccion: this.state.busquedaPorSeccion,
      nombreMunicipio: this.state.busquedaPorMunicipio,
      volAsignados : this.state.busquedaPorVolAsignados,
      tipoZore : this.state.busquedaPorTipoZore,
    };  
    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + 'ws/obtenerzorevoluntarios', postData,
    {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': this.props.user ? this.props.user.tknJWT : null
        }
    })
    .then(response => {
      this.setState({
		      	infoZonasResponsabilidadCasilla:response.data.infoZonasResponsabilidadCasilla,
		        infoMunicipios:response.data.infoMunicipios,
		        paginas:response.data.totalCasillas,
                ocultarCasillas:false,
                ocultarVoluntarios:true,
                mensajeAlerta : null,
                cargando : false,
                mostrarAlerta : {display: 'none'},
    	});

  	}).catch(error => {
      console.error("Error al obtener la consulta de Casillas",error);
  });
}
revisarEstatus = (datos) => {

    if(this.state.puedeAcceder){
      if(this.state.respuestaApertura === 'A'){
      	this.setState({
      		mensajeAlerta : "",
            mostrarAlerta : {display: 'none'}
      	});
      	this.eliminaCasilla(datos);
      }else{
       this.setState({
      		mensajeAlerta : "Esta acción no está permitida para este usuario",
            tipoAlerta : "warning",
            mostrarAlerta : {display: 'block'},
      	});
      }
    }else{
    	this.setState({
      		mensajeAlerta : "Esta acción no está permitida para el rol de este usuario",
            tipoAlerta : "warning",
            mostrarAlerta : {display: 'block'},
      	});
    }

  }
eliminaCasilla = (datos) => {
    console.log(datos);
      this.setState({
        mensajeAdvertencia :true,
        idZonaResponsabilidad:datos.idZonaResponsabilidad,
    	idParticipacion: datos.idParticipacion,
    	idDetalleProceso : datos.idDetalleProceso
      });
}
procesoEliminacionCasilla = (datos) =>{
    const dataJson = {
	    idZonaResponsabilidad:this.state.idZonaResponsabilidad,
	    idParticipacion: this.state.idParticipacion,
	    idDetalleProceso : this.state.idDetalleProceso,
  	}
    console.log(dataJson);

  axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + 'ws/eliminarTodoVoluntarioZore', dataJson,
    {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': this.props.user ? this.props.user.tknJWT : null
        }
    })
    .then(res => {
      if(res.data.code === 200){
        console.log("Se elimino Correctamente"); 
        this.state.mostrarElimina = false;
        var pagina = this.state.actual;
        this.paginado(pagina);
        this.setState({
        	mensajeVolEliminado :true,
        });
  }}).catch(error => {
      console.error("Error al eliminar la Casilla",error);
  });
}
render(){
    return(
        <AuthenticatedComponent > 
          <CTemplate contenido={       
            <VZoreVoluntarios             
              paginacionZore = {this.paginacionZore}
              columns = {this.state.columns}
              datosCasilla = {this.state.datosCasilla}
              paginado = {this.paginado}
              paginadoVoluntarios = {this.paginadoVoluntarios}
              paginas = {this.state.paginas}
              paginasVoluntarios = {this.state.paginasVoluntarios}
              page = {this.state.page}        
              actual = {this.state.actual}
              actualVoluntarios = {this.state.actualVoluntarios}      
              infoZonasResponsabilidadCasilla = {this.state.infoZonasResponsabilidadCasilla}
              activarCasilla ={this.state.activarCasilla} 
              clicCasilla ={this.clicCasilla} 
              obtenerVoluntariosAsig = {this.obtenerVoluntariosAsig}
              ocultarCasillas = {this.state.ocultarCasillas}
              infVountarioZores = {this.state.infVountarioZores} 
              infoVoluntariosAsignados = {this.state.infoVoluntariosAsignados}
              ocultarVoluntarios = {this.state.ocultarVoluntarios}
              paginacionVoluntarios = {this.paginacionVoluntarios}
              volunAsig = {this.state.volunAsig}
              idMunicipio = {this.state.idMunicipio}
              seccion = {this.state.seccion}
              recuperaDatos = {this.state.recuperaDatos}
              mensajeNoExistenDatos = {this.state.mensajeNoExistenDatos}
              asignarCasillas = {this.asignarCasillas}
              mensajeAdvertencia = {this.state.mensajeAdvertencia}
              eliminarVoluntario= {this.eliminarVoluntario}
              mensajeVolEliminado = {this.state.mensajeVolEliminado}
              isModalVisible = {this.state.isModalVisible}
              modalInfo = {this.modalInfo}
              handleCancel = {this.handleCancel}
              handleCancel2 = {this.handleCancel2}
              tipoCasilla = {this.state.tipoCasilla}
              seccion = {this.state.seccion}
              distrito = {this.state.distrito}
              zore = {this.state.zore}
              ubicacion = {this.state.ubicacion}
              busquedaNumeroZore = {this.busquedaNumeroZore}
              busquedaSeccion = {this.busquedaSeccion}              
              busquedaMunicipio = {this.busquedaMunicipio}
              idZonaResponsabilidad = {this.state.idZonaResponsabilidad}
              numeroZonaResponsabilidad = {this.state.numeroZonaResponsabilidad}
              mensajeNoExisteCasilla = {this.state.mensajeNoExisteCasilla}
              isModalVisibleDatosGuradados = {this.state.isModalVisibleDatosGuradados}
              isModalVisibleDatosGuradados2 = {this.state.isModalVisibleDatosGuradados2}
              guardarDatos = {this.guardarDatos}
              busquedaFolioVol = {this.busquedaFolioVol}
              busquedaVoluntario = {this.busquedaVoluntario}
              banderaModificacion = {this.state.banderaModificacion}
              banderaBotonGuardar = {this.state.banderaBotonGuardar}
              mensajeAnadido = {this.state.mensajeAnadido}
              infoMunicipios = {this.state.infoMunicipios}
              busquedaVolAsignados = {this.busquedaVolAsignados}
              infoTiposZona = {this.state.infoTiposZona}
              busquedaPorTipoZore = {this.state.busquedaPorTipoZore}
              busquedaTipoZore = {this.busquedaTipoZore}
              tipoAlerta = {this.state.tipoAlerta}
	          mensajeAlerta = {this.state.mensajeAlerta}
	          mostrarAlerta = {this.state.mostrarAlerta}
	          regresarPrimeraPagina={this.regresarPrimeraPagina}
	          regresarPrimeraPaginaVoluntarios={this.regresarPrimeraPaginaVoluntarios}
	          revisarEstatus= {this.revisarEstatus}
	          procesoEliminacionCasilla= {this.procesoEliminacionCasilla}
            />
            }
              guia={false}/>         
          </AuthenticatedComponent>
    )
  }
}
const mapStateToProps = createStructuredSelector({
  estado: selectEstado,
  distrito: selectDistrito,
  user: selectCurrentUser
});

export default connect(mapStateToProps)(CZoresVoluntarios);
