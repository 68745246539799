export const etiqueta_tooltip_tabla_consulta                = "Utiliza el filtro para buscar la convocatoria y selecciona la acción que deseas realizar.";
export const etiqueta_tooltip_tabla_periodo                 = "Selecciona las fechas de operación y los carteles para la difusión de la convocatoria.";
export const etiqueta_tooltip_modificar                     = "Modificar";
export const etiqueta_tooltip_consultar                     = "Consultar";
export const etiqueta_titulo_tabla_consultar                = "Listado de convocatorias registradas";
export const etiqueta_tooltip_boton_guardar                 = "El botón de guardar se habilitará una vez que se completen los campos";


//Roles que tienen acceso a insertar, consultar y modificar convocatorias
export const roles_permitidos_convocatorias = [
    "CIJ.ADMIN.OC",
    "CIJ.CAPTURA.JD",
    "CIJ.CAPTURA.JL",
    "CIJ.CAPTURA.OC",
    "CIJ.CAPTURA_VE.JD",
    "CIJ.CAPTURA_VE.JL",
    "CIJ.CAPTURA_VCEYEC.JD",
    "CIJ.CAPTURA_VCEYEC.JL",
    "CIJ.CAU.OC"
];

export const roles_consulta_convocatorias = [
    "CIJ.ADMIN.OC",
    "CIJ.CAPTURA.JD",
    "CIJ.CAPTURA.JL",
    "CIJ.CAPTURA.OC",
    "CIJ.CAPTURA_VE.JD",
    "CIJ.CAPTURA_VE.JL",
    "CIJ.CAPTURA.VERFE.JD",
    "CIJ.CAPTURA_VERFE.JL",
    "CIJ.CAPTURA_VOE.JL",
    "CIJ.CAPTURA_VOE.JD",
    "CIJ.CAPTURA_VS.JD",
    "CIJ.CAPTURA_VS.JL",
    "CIJ.CAU.OC",
    "CIJ.CONSULTA.JD",
    "CIJ.CONSULTA.JL",
    "CIJ.CONSULTA.OC",
    "CIJ.CONSULTA_RESTRINGIDA.JD",
    "CIJ.CONSULTA_RESTRINGIDA.JL",
    "CIJ.CONSULTA_RESTRINGIDA.OC",
    "CIJ.CAPTURA_OPL.JL",
    "CIJ.CONSULTA_OPL.JL",
    "CIJ.CAPTURA_OPL.JD",
    "CIJ.CONSULTA_OPL.JD",
    "CIJ.JOSA.JD",
    "CIJ.CONSULTA_JOSA.JD"
];

export const roles_captura_convocatorias = [

    "CIJ.ADMIN.OC",
    "CIJ.CAPTURA.JD",
    "CIJ.CAPTURA.JL",
    "CIJ.CAPTURA.OC",
    "CIJ.CAPTURA_VE.JD",
    "CIJ.CAPTURA_VE.JL",
    "CIJ.CAU.OC"   

];