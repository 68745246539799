import React from 'react';

import axios from 'axios';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import VDocumentacion from
                    '../../components/voluntarios/documentacion/VDocumentacion';
import VRegistroExitoso from
                  '../../components/voluntarios/VRegistroExitoso';
import AuthenticatedComponent from "../../components/comunes/AuthenticatedComponent";
import * as etiquetas from '../../constants/EtiquetasGenerales';
import * as formatos from '../../constants/Formatos';
import { setCurrentVoluntario} from '../../redux/voluntario/voluntario-actions';
import { createStructuredSelector } from 'reselect';
import { selectEstado, selectDistrito} from '../../redux/menu/menu-selectors';
import { selectCurrentUser} from '../../redux/user/user-selectors';
import * as etiquetaDocu from  '../../constants/EtiquetasDocumentacion';
import registroExito from '../../assets/img/exito_registro_voluntario.png'
import edicionExitoso from '../../assets/img/registro_exito.png'
import { PATH_CONSULTA_VOLUNTARIO} from '../../constants/routers/paths';


var nombresArchivo = [];
var archivos = [];
class CDocumentacion extends React.Component {

  constructor(props){
    super(props)
    this.state={
      loader:false,
      cargando : false,
      imagenUrlIneFrente : "",
      imagenUrlIneReverso : "",
      imagenUrlDomicilio : "",
      imagenUrlFoto : "",
      nombreIneFrente : "",
      nombreIneReverso : "",
      nombreDomicilio : "",
      nombreFoto : "",
      ineFrente :[],
      ineReverso :[],
      domicilio :[],
      foto :[],
      listaArchivosObli : [],
      archivoCorrecto : false,
      abrirModal : false,
      tituloModal : "",
      fotoModal : "",
      idVoluntario : 0,
      idDocumento : null,
      nombreZipViejo : null,
      folio: null,
      mostarNotificacion:false,
      infoVoluntarioGuardado:null,
      msjError : ""
    }
  }

  componentDidMount = () => {
    this.obtenerListaDocSubir();

    if(this.props.editar === true){
      console.log("Se va aeditar....");
      this.obtenerZipVoluntario();
      this.obtenerArchivosSubidos();
    }
    console.info("infodelFormulario",this.props);
  }

  mandarAConsulta = () =>{
    this.props.history.push(PATH_CONSULTA_VOLUNTARIO);
  }

  obtenerListaDocSubir = () => {
    let infoPost = {
      idProcesoElectoral : this.props.user.idProcesoElectoral,
      idDetalleProceso : this.props.user.idDetalle
    }
    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL+
      "ws/obtenerTipoDocumentos", infoPost,
          {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.user ? this.props.user.tknJWT : null
              }
          }
       ).then(response => {
         console.info("lista archivos",response)
         this.setState({
           listaArchivosObli:response.data.tipoDocumentos
         })
    }).catch(error => {
        console.error("Error al obtener las casillas",error);
    });
  }

  obtenerZipVoluntario = () => {
    let infoPost = {
      idProcesoElectoral: this.props.user.idProcesoElectoral,
      idDetalleProceso: this.props.user.idDetalle,
      idEstado: this.props.estado.idEstado,
      idDistrito: this.props.distrito.idDistrito,
      idVoluntario : this.props.idVoluntario
    }
    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL+
      "ws/obtenerZipVoluntario", infoPost,
          {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.user ? this.props.user.tknJWT : null
              }
          }
       ).then(response => {
         console.info("info zip",response)
         if(response.data.zipVoluntario != null){
           this.obtenerNombreZip(response.data.zipVoluntario);
         }else{
           console.error("No se encontro zip, o no ha subido documentación");
         }
    }).catch(error => {
        console.error("Error al obtener el zip",error);
    });
  }

  obtenerNombreZip = (response) => {
    var nombres = response.urlDocumento.split("/");
    this.setState({
      folio : nombres[5],
      nombreZipViejo: nombres[nombres.length-1],
      idVoluntario:response.idVoluntario,
      idDocumento :response.idDocumento
    });
  }

  obtenerArchivosSubidos = () =>{
    const infoPost = {
      idProcesoElectoral: this.props.user.idProcesoElectoral,
      idDetalleProceso: this.props.user.idDetalle,
      idEstado: this.props.estado.idEstado,
      idDistrito: this.props.distrito.idDistrito,
      idVoluntario: this.props.idVoluntario
    }

    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL+
      "ws/obtenerArchivosVoluntario", infoPost, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': this.props.user ? this.props.user.tknJWT : null
          }
      }
       ).then(response => {
         console.info("Archivos en gluster",response)
         this.acomodarArchivo(response.data.archivosVoluntario);
    }).catch(error => {
        console.error("Error al obtener las casillas",error);
    });
  }

  obtenerAleatorio = () =>{
    var min = 1111111111111;
    var max = 9999999999999;
    var aleatorio = Math.floor(Math.random()*(max-min+1)+min);
    return String(aleatorio);
  }

  acomodarArchivo = (archivosVoluntario) =>{
    console.log("archivosVoluntarios",archivosVoluntario);
    var tipoArchivo = "";
    var numAleatorio = this.obtenerAleatorio();
    archivosVoluntario.forEach((archivo) => {
      console.log("archivo",archivo);
      var infoPost = {
        idProcesoElectoral: this.props.user.idProcesoElectoral,
        idDetalleProceso: this.props.user.idDetalle,
        idEstado: this.props.estado.idEstado,
        idDistrito: this.props.distrito.idDistrito,
        folio: archivo.folio,
        src : archivo.nombreArchivo,
        claveElectorSiap : archivo.fuarSiap
      }
      nombresArchivo.push(archivo.nombreArchivo);
      if (archivo.nombreArchivo.endsWith('.jpeg') ||
          archivo.nombreArchivo.endsWith('.jpg')){
          tipoArchivo = 'image/jpeg';
      }else if (archivo.nombreArchivo.endsWith('.png')){
          tipoArchivo= 'image/png';
      } else {
        tipoArchivo= 'application/pdf';
      }

      this.obtenerArchivo(infoPost,tipoArchivo,
        archivo.idTipoDocumento,archivo.nombreArchivo,numAleatorio);
    });

  }

  obtenerImg =(blobUrl,nombre,idTipoDocumento,numAleatorio) =>{
    var imagenChida;
    let _this=this;

    axios({
    method: 'get',
    url: blobUrl,
    responseType: 'blob'
    }).then(function(response){
         var reader = new FileReader();
         reader.readAsDataURL(response.data);
         reader.onloadend = function() {
             var the_file = null;
             var base64data = reader.result;
             var bloque = base64data.split(";");
             var tipoArchivo = bloque[0].split(":")[1];
             var img64 = bloque[1].split(",")[1];
             var archivo = new Blob([window.atob(img64)],
                  { type:tipoArchivo, encoding: 'utf-8' });
             var imagen = new File([archivo], nombre, { type: tipoArchivo})
             var dataUri = "data:"+tipoArchivo+";base64,"+img64
             console.log("dedo",imagen,idTipoDocumento,dataUri)
             imagenChida = imagen;
             imagen.uid = "rc-upload-"+numAleatorio+"-"+String(idTipoDocumento);
             switch (idTipoDocumento) {
               case 1:
               _this.getBase64(imagen, imageUrl =>
                 _this.setState({
                   imagenUrlIneFrente : dataUri,
                   cargando: false,
                   nombreIneFrente : nombre,
                   ineFrente : imagen}),
               );
               break;
               case 2:
                 _this.getBase64(imagen, imageUrl =>
                   _this.setState({
                     imagenUrlIneReverso : dataUri,
                     cargando: false,
                     nombreIneReverso : nombre,
                     ineReverso : imagen})
                 );
               break;
               case 3:
                 _this.getBase64(imagen, imageUrl =>
                   _this.setState({
                     imagenUrlDomicilio : dataUri,
                     cargando: false,
                     nombreDomicilio : nombre,
                     domicilio : imagen}),
                 );
               break;
               case 4:
               _this.getBase64(imagen, imageUrl =>
                 _this.setState({
                   imagenUrlFoto : dataUri,
                   cargando: false,
                   nombreFoto : nombre,
                   foto : imagen}),
               );
               break;
             }
         }

    });
    return imagenChida;
  }

  obtenerArchivo = (infoPost,tipoArchivo,idTipoDocumento,nombre,numAleatorio) =>{
    var tken = {
      'Authorization': this.props.user ? this.props.user.tknJWT : null
    }
    console.log("obteniendi archivos",infoPost, tipoArchivo);
    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL+
      "ws/obtenerArchivo", infoPost, {
            responseType: 'blob',
            transformResponse: [ data => {
                let blob = new window.Blob([data,tken], {type: tipoArchivo});
                this.srcURLObject = window.URL.createObjectURL(blob);
                return this.srcURLObject;
            }],
        }).then(rawData => {
          var pele = this.obtenerImg(rawData.data,nombre,
                                                idTipoDocumento,numAleatorio);
        });
  }

  otrasValidacionesArchivo = (archivo,formatoValido) => {
    const tamanioPermitido = archivo.size / 1024 / 1024 < 3.5; //15
    const archivoVacio = archivo.size === 0;
    const nombreLargo = archivo.name.length > 100;
    const archNombrePermitido = this.validaNombreArchivo(archivo.name);
    const nombreRepetido = nombresArchivo.includes(archivo.name);
    this.setState({
      archivoCorrecto: tamanioPermitido && !archivoVacio && !nombreLargo &&
                                          archNombrePermitido && !nombreRepetido
    });

    if (!tamanioPermitido) {
      this.setState({
        msjError : etiquetaDocu.gral_peso_archivo
      });
      console.error(archivo.name +"--"+etiquetaDocu.gral_peso_archivo);
      return false;
    }

    if(archivoVacio){
      this.setState({
        msjError : etiquetaDocu.gral_error_cargar
      });
      console.error(archivo.name +"--"+etiquetaDocu.gral_error_cargar);
      return false;
    }

    if(nombreLargo){
      this.setState({
        msjError : etiquetaDocu.gral_error_cargar
      });
      console.error(archivo.name +"--"+etiquetaDocu.gral_error_cargar);
      return false;
    }

    if(!archNombrePermitido){
      this.setState({
        msjError : etiquetaDocu.gral_error_nombre_archivo
      });
      console.error(archivo.name +"--"+etiquetaDocu.gral_error_nombre_archivo);
      return false;
    }

    if(nombreRepetido){
      this.setState({
        msjError : etiquetaDocu.gral_nombre_repetido
      });
      console.error(archivo.name +"--"+etiquetaDocu.gral_nombre_repetido);
      return false;
    }


  }

  validarFoto = (archivo) =>{
    this.setState({
      msjError : ""
    });
    const formatoValido = archivo.type === 'image/jpeg'
        || archivo.type === 'image/png';

    if (!formatoValido) {
      this.setState({
        msjError : etiquetaDocu.gral_archivos_permitidos_foto
      });
      console.error(archivo.name +"--"+etiquetaDocu.gral_archivos_permitidos_foto);
      return false;
    }
    this.otrasValidacionesArchivo(archivo,formatoValido );
  }

  validarArchivo = (archivo) =>{
      this.setState({
        msjError : ""
      });
      const formatoValido = archivo.type === 'image/jpeg'
        || archivo.type === 'image/png' || archivo.type === 'application/pdf';

      if (!formatoValido) {
        this.setState({
          msjError : etiquetaDocu.gral_archivos_permitidos
        });
        console.error(archivo.name +"--"+etiquetaDocu.gral_archivos_permitidos);
        return false;
      }
      this.otrasValidacionesArchivo(archivo,formatoValido );
  }

  validaNombreArchivo=(e) =>{
      var patron = /^[0-9a-zA-Z_-\s]+.[pdf/PDF/jpg/JPG/jpeg/JPEG/png/PNG]+$/;
      if(e.match(patron)){
          return true;
      }else{
          return false;
      }
  }

  getBase64 = (img, callback) => {
    archivos.push(img.name);
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    if(img !== undefined){
      reader.readAsDataURL(img);
    }
    for(var i = 0; i < archivos.length; i++) {
      const elemento = archivos[i];
      if (!nombresArchivo.includes(archivos[i])) {
        nombresArchivo.push(elemento);
      }
    }
    archivos = [];
    console.log("lista nombres",nombresArchivo);
  }

  cargarIneFrente = info => {
      if(this.state.archivoCorrecto){
        this.getBase64(info.file.originFileObj, imageUrl =>
          this.setState({
            imagenUrlIneFrente : imageUrl,
            cargando: false,
            nombreIneFrente : info.name,
            ineFrente : info.file.originFileObj
          }),
        )
      }
  }

  cargarIneReverso = info => {
     if(this.state.archivoCorrecto){
       this.getBase64(info.file.originFileObj, imageUrl =>
         this.setState({
           imagenUrlIneReverso : imageUrl,
           cargando: false,
           nombreIneReverso : info.name,
           ineReverso : info.file.originFileObj
         }),
       )
     }
  }

  cargarComprobante = info => {
     if(this.state.archivoCorrecto){
       this.getBase64(info.file.originFileObj, imageUrl =>
         this.setState({
           imagenUrlDomicilio : imageUrl,
           cargando: false,
           nombreDomicilio : info.name,
           domicilio : info.file.originFileObj
         }),
       )
     }
   }

  cargarFoto = info => {
     if(this.state.archivoCorrecto){
       this.getBase64(info.file.originFileObj, imageUrl =>

         this.setState({
           imagenUrlFoto : imageUrl,
           cargando: false,
           nombreFoto : info.name,
           foto : info.file.originFileObj
         }),
       )
     }
  }

  vistaPrevia = opcion =>{
      this.setState({abrirModal:true, fotoModal:opcion});
  }

  cerrarVistaPrevia = () => {
      this.setState({abrirModal:false, fotoModal:""});
  }

  eliminarArchivo = (opcion) =>{
    console.log("como llega el arreglo",nombresArchivo);
    var nombreArchivo ="";
    switch (opcion) {
      case 1:
        this.setState({ineFrente:[], imagenUrlIneFrente:""});
        nombreArchivo = this.state.ineFrente.name;
      break;
      case 2:
        this.setState({ineReverso:[], imagenUrlIneReverso:""});
        nombreArchivo = this.state.ineReverso.name;
      break;
      case 3:
        this.setState({domicilio:[], imagenUrlDomicilio:""});
        nombreArchivo = this.state.domicilio.name;
      break;
      case 4:
        nombreArchivo = this.state.foto.name;
        this.setState({foto:[], imagenUrlFoto:""});
      break;
    }

      for (var i = 0; i < nombresArchivo.length; i++) {
          console.log(nombresArchivo[i], nombreArchivo);
          if(nombresArchivo[i] === nombreArchivo){
            nombresArchivo.splice(i,i)
          }
      if (nombresArchivo.length === 1 &&
            nombresArchivo.includes(nombreArchivo)) {
        nombresArchivo = [];
      }
    }
    console.log("como quedo el arreglo",nombresArchivo);
  }

  crearInfoPost = () =>{
    console.log("archivos a subir",this.state);

    var archivosAsubir = [
      this.state.ineFrente,
      this.state.ineReverso,
      this.state.domicilio,
      this.state.foto
    ];

    console.log("archivos a subir",archivosAsubir);
    var arregloEnviar = [];
    var formData = new FormData();
    var i = 0;
    archivosAsubir.forEach((archivo)=>{
      if (archivo.uid !== undefined) {
        const metadatoArchivo = {
          idTipoDocumento:this.state.listaArchivosObli[i].idTipoDocumento,
          uid: archivo.uid,
          type: archivo.type,
          name: archivo.name,
          idProcesoElectoral: this.props.user.idProcesoElectoral,
          idDetalleProceso: this.props.user.idDetalle,
          idEstado: this.props.estado.idEstado,
          idDistrito: this.props.distrito.idDistrito,
          usuario: this.props.user.username,
          nombreViejoZip : this.state.nombreZipViejo,
          folio : this.state.infoVoluntarioGuardado.folio,
          folioAcceso: this.state.infoVoluntarioGuardado.folioAcceso,
          idVoluntario: this.state.infoVoluntarioGuardado.idVoluntario,
          claveElectorSiap : this.state.infoVoluntarioGuardado.claveElector,
          idDocumento : this.state.idDocumento,
          correo: this.state.infoVoluntarioGuardado.correoElectronico,
          editar: this.props.editar
         };
         arregloEnviar.push(metadatoArchivo);
         formData.append('files', archivo);
      }
      i++;
    });
    console.log("arregloEnviar",arregloEnviar);
    formData.append('metadatos',new Blob([JSON.stringify(arregloEnviar)],
                                                  {type:"application/json"}));
              console.log("formData",formData.getAll('files'));
    return formData;
  }

  guardar = () => {
    this.setState({loader:true});
    console.log("los props.", this.props);
    this.guardarVoluntario();
    console.info("Se pudo guardar el voluntario",
              this.state.infoVoluntarioGuardado);
  }

  guardarVoluntario = () =>{
    var infoVoluntario = this.acomodarInfoVoluntario();
    console.log("la información a guardar es:", infoVoluntario,this.state);
    var editarGuardar = "ws/registrarVoluntario";
    if( this.props.idVoluntario != null){
      editarGuardar = "ws/editarVoluntario";
    }
    console.info("voy a",editarGuardar,this.props.idVoluntario != null);
    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL+
              editarGuardar, infoVoluntario, {
        headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.props.user ? this.props.user.tknJWT : null
                }
      }).then(respuesta => {
        console.log("El aspirante se guardo, se prosigue subir documentación"
                                                                    ,respuesta);
        this.setState({
          infoVoluntarioGuardado:respuesta.data.voluntarioRegistrado
        });
        this.guardarDocumentos(respuesta.data.voluntarioRegistrado);

      }).catch(error => {
        console.error("No se pudo guardar el voluntario",error);

      });

  }

  guardarDocumentos = (voluntarioGuardado) =>{
    console.log("empiezo la subida de documentos");
    var postInfo = this.crearInfoPost();
    console.log("arregloa agurar",postInfo);
    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL+
              "ws/guardarDocumentos", postInfo, {
        headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': this.props.user ? this.props.user.tknJWT : null
                }

      }).then(respuesta => {
        console.log("al guardar",respuesta);
        this.props.setCurrentVoluntario(null);
        nombresArchivo = [];
        this.setState({
          loader:false,
          mostarNotificacion:true
        });
      }).catch(error => {
        console.error("al guardar error",error);
      });
  }

  acomodarInfoVoluntario = () =>{
    var infoVoluntario ={
        "idProcesoElectoral" : this.props.user.idProcesoElectoral,
      	"idDetalleProceso" : this.props.user.idDetalle,
      	"idEstado" : this.props.estado.idEstado,
      	"idDistrito" : this.props.distrito.idDistrito,
      	"idVoluntario" : this.props.idVoluntario,
      	"idParticipacion" : this.props.idParticipacion,
      	"nombre" : this.props.nombre,
        "curp" : this.props.curp,
        "experiencia" : this.props.experiencia,
      	"apellidoPaterno" : this.props.apellidoP !== undefined
                                            ? this.props.apellidoP : null,
      	"apellidoMaterno" : this.props.apellidoM !== undefined
                                            ? this.props.apellidoM : null,
      	"claveElectorSiap" : this.props.claveElector,
      	"genero" : this.props.genero,
      	"fechaNacimiento" : this.props.fechaNacimiento,
      	"edad" : this.props.edad,
      	"idMunicipio" : this.props.municipio,
      	"calle" : this.props.calle,
      	"numeroInt" : this.props.numeroInt,
      	"numerorExt" : this.props.numeroExt,
      	"colonia" : this.props.colonia,
      	"codigoPostal" : this.props.cp,
      	"telefonoMovil" : this.props.celular !== undefined
                                                  ? this.props.celular : null,
      	"telefonoCasa" : this.props.telefono !== undefined
                                                  ? this.props.telefono : null,
      	"correoElectronico" : this.props.correo,
      	"hablaLenguaIndigena" : this.props.hablaIndigena,
      	"cualLenguaIndigena" : this.props.queLengua !== undefined
                                      ? this.props.queLengua : null,
      	"tipoVoluntario" : this.props.tipoVoluntario,
      	"usuario" : this.props.user.username,
        "seccion" : this.props.seccion,
        "idConvocatoria" : this.props.idConvocatoria,
        "folio" : this.props.folio,
        "estatus" : this.props.estatusRegistro,
        "folioAcceso" : this.props.folioAcceso,
        "idSede" : this.props.idSede,
        "observaciones" : this.props.observaciones,
        "fechaHoraCapacitacion" : this.props.fechaHoraCapacitacion !== null ?
                moment(this.props.fechaHoraCapacitacion,
                                         formatos.FORMATO_FECHA_MOMENT) : null,
        "fechaHoraRegistro" : this.props.fechaRegistro !== null ?
                moment(this.props.fechaRegistro,
                                           formatos.FORMATO_FECHA_MOMENT) : null

    }
    return infoVoluntario;
  }

    render(){
    return(
      <>
        <VDocumentacion
          loader={this.state.loader}
          changeList={this.changeList}
          validarArchivo = {this.validarArchivo}
          imagenUrlIneFrente = {this.state.imagenUrlIneFrente}
          imagenUrlIneReverso = {this.state.imagenUrlIneReverso}
          imagenUrlDomicilio = {this.state.imagenUrlDomicilio}
          imagenUrlFoto = {this.state.imagenUrlFoto}
          cargarIneFrente = {this.cargarIneFrente}
          cargarIneReverso = {this.cargarIneReverso}
          cargarComprobante = {this.cargarComprobante}
          cargarFoto = {this.cargarFoto}
          guardar = {this.guardar}
          abrirModal = {this.state.abrirModal}
          tituloModal = {this.state.tituloModal}
          vistaPrevia = {this.vistaPrevia}
          eliminarArchivo = {this.eliminarArchivo}
          fotoModal = {this.state.fotoModal}
          cerrarVistaPrevia = {this.cerrarVistaPrevia}
          regresar = {this.props.regresar}
          validarFoto = {this.validarFoto}
          msjError = {this.state.msjError}
          tipoVoluntario = {this.props.tipoVoluntario}
        />
        <VRegistroExitoso
          mostarNotificacion = {this.state.mostarNotificacion}
          imagen = {this.props.idVoluntario > 0 ?
            edicionExitoso : registroExito }
          textoExito = {this.props.idVoluntario > 0 ?
            etiquetaDocu.texto_edicion : etiquetaDocu.texto_exito }
          textoInformativo = {etiquetaDocu.texto_informativo}
          mandarAConsulta = {this.mandarAConsulta}
          />
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
    estado: selectEstado,
    distrito: selectDistrito,
    user: selectCurrentUser
});
const mapDispatchToProps = dispatch => ({
  setCurrentVoluntario: voluntario => dispatch(setCurrentVoluntario(voluntario)),
});
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(CDocumentacion));
