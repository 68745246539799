import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import UserActionTypes from './user/user-types';
import VoluntarioActionTypes from './voluntario/voluntario-types';
import CasillaActionTypes from './casillas/casilla-types';
import storage from 'redux-persist/lib/storage';
import userReducer from './user/user-reducer';
import menuReducer from './menu/menu-reducer';
import voluntarioReducer from './voluntario/voluntario-reducer';
import casillaReducer from './casillas/casilla-reducer';
import bitacoraJsonReducer from './bitacoraJson/bitacoraJson-reducer';


const persistConfig = {
    key: 'root',
    storage,
    whiteList: []
}

const appReducer = combineReducers({
    user: userReducer,
    menu: menuReducer,
    voluntario: voluntarioReducer,
    casilla: casillaReducer,
    bitacoraJson: bitacoraJsonReducer
});

const rootReducer = (state, action) => {
  console.log("reducer", state, action)
    if (action.type === UserActionTypes.USER_LOGOUT) {
        storage.removeItem('persist:root');
        return appReducer(undefined, action);
    }
    if (action.type === UserActionTypes.VOLUNTARIO_LOGOUT) {
        storage.removeItem('persist:root');
        return appReducer(undefined, action);
    }
    if (action.type === CasillaActionTypes.CASILLA_LOGOUT) {
        storage.removeItem('persist:root');
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
}

export default persistReducer(persistConfig, rootReducer);
