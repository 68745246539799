export const URL_MENU_LATERAL       = 'ws/obtieneMenuLateral';
export const URL_MENU_ACCIONES      = 'ws/obtieneMenuAcciones';
export const URL_MENU_REPORTES      = 'ws/obtieneMenuReportes';
export const URL_ESTATUS_MODULO     = 'ws/obtieneEstatusModulo';
export const URL_OBTENER_ESTADOS    = 'ws/obtenerEstados';
export const URL_OBTENER_DISTRITOS  = 'ws/obtenerDistritos';

export const ID_MENU_PORTAL_INTERNO = 1;

export const MENU_FILTROS_ESTADO                = "Entidad";
export const MENU_FILTROS_ESTADO_LABEL_0        = "Todos";
export const MENU_FILTROS_DISTRITO              = "Junta Distrital";
export const MENU_FILTROS_DISTRITO_LABEL_0      = "Todos";
export const MENU_SUBMENU_INVALIDO              = "El menú seleccionado no existe";
export const MENU_SUBMENU_SIN_MODULOS           = "El menú seleccionado no tiene módulos disponibles";

