import React from 'react';
import AuthenticatedComponent from "../../components/comunes/AuthenticatedComponent";
import CTemplateExt from './CTemplateExt';
import VHome from  '../../components/homePortal/VHome';
import { defaultGraficaCasillas,
        defaultGraficaVoluntarios,
        defaultGraficaParticipantes,
        obtenerDatosGraficaHomePortal } from '../../utils/homePortal';

class CHome extends React.Component{

  constructor(props){
    super(props);
    this.state = {
        datosGraficaCasillas: defaultGraficaCasillas,
        datosGraficaVoluntarios: defaultGraficaVoluntarios,
        datosGraficaParticipantes: defaultGraficaParticipantes
    }
  }

  /*async componentDidMount(){
    const datosGraficaCasillas = await obtenerDatosGraficaHomePortal(1, 0, 0);
    const datosGraficaVoluntarios = await obtenerDatosGraficaHomePortal(2, 0, 0);
    const datosGraficaParticipantes = await obtenerDatosGraficaHomePortal(3, 0, 0);

    this.setState({
      datosGraficaCasillas: datosGraficaCasillas,
      datosGraficaVoluntarios: datosGraficaVoluntarios,
      datosGraficaParticipantes: datosGraficaParticipantes
    });

  }*/

  render(){
    return(
    	<AuthenticatedComponent privado={false}>
      <CTemplateExt isHome
                    contenido ={<VHome 
                                  datosCasillas={this.state.datosGraficaCasillas}
                                  datosVoluntarios={this.state.datosGraficaVoluntarios}
                                  datosParticipantes={this.state.datosGraficaParticipantes} />} />

		 </AuthenticatedComponent>
    )
  }
}

export default CHome;
