import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser, selectCurrentUserRol} from '../../redux/user/user-selectors';
import { selectListaEstados, selectListaDistritos, selectEstado, selectDistrito } from '../../redux/menu/menu-selectors';
import { updateMenu, updateEstado, updateDistrito, updateListaDistritos } from '../../redux/menu/menu-actions';
import { obtenerMenuLateral, obtenerDistritos } from '../../redux/menu/menu-utils';
import { Select } from 'antd';
import * as etiquetas from '../../constants/EtiquetasMenu';
import * as infoSistema from '../../constants/InfoSistema';
import '../../css/header/filtros.scss'; 

const { Option } = Select;

const VFiltros = (props) => { 

  const seleccionaEstado = async(estado) => {
      if (!estado) return;
      const arrayDtos = estado.split("-");
      props.updateEstado({idEstado: arrayDtos[0], nombreEstado: arrayDtos[1]});
      props.updateDistrito({idDistrito:0, nombreDistrito:''});
      if(arrayDtos[0] > 0){
          props.updateListaDistritos(await obtenerDistritos(arrayDtos[0],
                                                            0));
      } else{
          props.updateListaDistritos([]);
      }
      obtieneMenu(arrayDtos[0], 0);
    }

    const seleccionaDistrito = async(distrito) => {
      if (!distrito) return;
      const idDistrito = distrito.split("-")[0];
      props.updateDistrito({idDistrito: idDistrito, nombreDistrito: distrito});
      obtieneMenu(props.estado.idEstado, idDistrito);
    }

    const obtieneMenu = async(idEstado, idDistrito) => {
      props.updateMenu(await obtenerMenuLateral(infoSistema.ID_PROCESO_ELECTORAL,
                                            infoSistema.ID_DETALLE_PROCESO,
                                            infoSistema.ID_SISTEMA,
                                            idEstado, 
                                            idDistrito, 
                                            props.user.rolUsuario));
    }

    return(
      <div className='mc-filtros'>
        <div className='mc-filtros-combo mc-filtros-combo-estado'>
          <span className='mc-filtros-combo-text'>
            {props.rolUsuario === "JD"
              || props.rolUsuario === "JL" ?
              props.estado.nombreEstado
              : etiquetas.MENU_FILTROS_ESTADO
            }
          </span>
          {
            props.rolUsuario !== "JD"
            && props.rolUsuario !== "JL" ?
            <Select showArrow={true}
                value={props.estado.idEstado === 0
                        ? etiquetas.MENU_FILTROS_ESTADO_LABEL_0 : props.estado.nombreEstado}
                onSelect={seleccionaEstado}>
                {
                  props.listaEstados &&
                  props.listaEstados.map((estado) => (
                      <Option key={estado.idEstado}
                        value={estado.idEstado +'-'+ estado.nombreEstado}>
                        {estado.nombreEstado}
                      </Option>
                  ))
                }
            </Select>
            : ''
          }
        </div>
        <div className='mc-filtros-combo mc-filtros-combo-distrito'>
          <span className='mc-filtros-combo-text'>
            {props.rolUsuario === "JD" ?
              props.distrito.nombreDistrito
              :etiquetas.MENU_FILTROS_DISTRITO}
          </span>
          {
            props.rolUsuario !== "JD" ?
            <Select showArrow={true}
                  value={props.distrito.idDistrito === 0 ?
                        etiquetas.MENU_FILTROS_DISTRITO_LABEL_0 : props.distrito.nombreDistrito}
                  onChange={seleccionaDistrito}>
                  {
                      props.listaDistritos &&
                      props.listaDistritos.map((distrito) => (
                          <Option key={distrito.idDistrito}
                            value={distrito.idDistrito + '-' +  distrito.nombreDistrito}>
                            {distrito.idDistrito + '-' +  distrito.nombreDistrito}
                          </Option>
                      ))
                  }
            </Select>
            :''
          }
        </div>
      </div>
    );
}

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
  rolUsuario: selectCurrentUserRol,
  listaEstados: selectListaEstados,
  listaDistritos: selectListaDistritos,
  estado: selectEstado,
  distrito: selectDistrito
});

const mapDispatchToProps = dispatch => ({
	updateEstado: estado => dispatch(updateEstado(estado)),
	updateDistrito: distrito => dispatch(updateDistrito(distrito)),
  updateListaDistritos: listaDistritos => dispatch(updateListaDistritos(listaDistritos)),
	updateMenu: subMenus => dispatch(updateMenu(subMenus))
});

export default connect(mapStateToProps, mapDispatchToProps)(VFiltros);