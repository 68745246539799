import React, { useEffect } from 'react';
import { Layout, Row, Col, Button, Form, Spin, notification } from 'antd';

import * as EtiquetasBitacoraJson from '../../constants/EtiquetasBitacoraJson';
import { useDispatch, useSelector } from 'react-redux';
import { guardarBitacoraJson } from '../../redux/bitacoraJson/bitacoraJson-actions';

const VBitacoraJson = (props) => {

	const modelo = useSelector(state => state.bitacoraJson);
	const dispatch = useDispatch();

	const dispatchGuardarBitacora = () => {
		dispatch(guardarBitacoraJson());
	};

	const runNotification = () => {
		if(modelo.infoResponse !== null) {
			if(modelo.infoResponse.status === 1) {
				notification.success({
					message: null,
					description: modelo.infoResponse.message
				});
			} else if(modelo.infoResponse.status === 0) {
				notification.error({
					message: null,
					description: modelo.infoResponse.message
				});
			}
		}
	};

	useEffect(() => {
		if(modelo.infoResponse && modelo.infoResponse.status !== null) {
			runNotification();
		}
	}, [modelo.infoResponse]);

	return (
		<Spin spinning={modelo.loading}>
			<Layout className='validar_modulo'>
				<Row>
					<Col xs={24}>
						<h1 id="titulo-componente">{EtiquetasBitacoraJson.TITULO}</h1>
					</Col>
				</Row>
				<p style={{ marginBottom: '20px' }} />
				<Row style={{ textAlign: 'center' }}>
					<Col xs={24}>
						<Form
							onFinish={dispatchGuardarBitacora}
						>
							<Button
								type="primary"
								htmlType="submit"
							>
								{EtiquetasBitacoraJson.GENERAR_BTN}
							</Button>
						</Form>
					</Col>
				</Row>
			</Layout>
		</Spin>
	);
};

export default VBitacoraJson;
