import React from 'react';
import { FilePdfOutlined, FileExcelOutlined, FileTextOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import { obtieneNombreArchivo, generaTxt, generaPDF } from '../../utils/publicador';

const VExportar = ({ proceso, estado, distrito, municipio, reporte, nivel, cotas, headerCSV, headerPDF, datos }) => {
    
    return(<div className='reporte-exportar-container'>
                <CSVLink className='rec-option' 
                        headers={headerCSV} 
                        data={datos}
				        filename={`${obtieneNombreArchivo(reporte.key, nivel.label)}.csv`} >
                    <span className='rec-option-label'>CSV</span>
                    <FileExcelOutlined />
                </CSVLink>
                <div className='rec-option' 
                    onClick={() => generaPDF(proceso, estado, distrito, municipio, reporte, nivel, cotas, headerPDF, datos)}>
                    <span className='rec-option-label'>PDF</span>
                    <FilePdfOutlined />
                </div>
                <div className='rec-option' 
                    onClick={() => generaTxt(headerCSV, datos, reporte.key, nivel.label)}>
                    <span className='rec-option-label'>TXT</span>
                    <FileTextOutlined />
                </div>
           </div>);
}

export default VExportar;