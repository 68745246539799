import React from 'react';
import { VictoryChart, VictoryBar, VictoryTooltip,VictoryArea,VictoryPie,
        VictoryGroup,VictoryTheme,VictoryStack,VictoryPortal,
VictoryScatter  } from 'victory';
import {Layout, Row, Col} from 'antd';
import iconoGrafica from '../../assets/img/graficas_null.png';
import {obtenerEstado} from '../../utils/funciones'


const VComparaGraficas = (props) => {
  const colores = [
    "#52B2A9",
    "#FFCC1C",
    "#C4006C",
    "#80539C",
    "#9F8691",
    "#9AA42F",
    "#2CC9D6",
    "#EFD12C",
    "#FFBF03",
    "#4a8789",
    "#74418A",
    "#917684",
    "#663C99",
    "#D3097F",
    "#98e1b8",
    "#9a69ba"
  ];

    return(
        <div className="graficaDiv" id="grafica-div">
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className={props.valoresAgraficar === null ? "show rDiv-centrar-grafica" : "hide"}>
                <div>
                  <img src={iconoGrafica} className="rGrafica-null"
                       alt="imagen_grafica"/>
                  <br/><br/>
                  <span className="rTexto-null">No hay datos</span>
                </div>
              </div>
              </Col>
            </Row>

            <div className={props.valoresAgraficar !== null ? "show rDiv-centrar-grafica" : "hide"}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div id="rangos"
                    className={props.tipoGrafica === "bar"  && props.preguntasDeRango ? "show" : "hide"}>
                    {
                    props.valoresAgraficar &&
                    props.valoresAgraficar.map((elemento, index) => (
                      <>
                        <VictoryChart domainPadding={20}
                            minDomain={{ x: 0 }}
                            theme={VictoryTheme.material}
                            >
                          <VictoryGroup horizontal
                            offset={10}
                            style={{ data: { width: 5 } }}
                            colorScale={colores}>
                            {
                              elemento &&
                              elemento.map((element, i) => (
                                <VictoryBar horizontal
                                    barWidth={7}
                                    alignment="middle"
                                    cornerRadius={{top: 3.5}}
                                    barRatio={0.9}
                                    style={{ labels: { display: "none" } }}
                                    data={element.data}
                                    key={index}
                                    labelComponent={
                                      <VictoryTooltip
                                        style={{ fill: "tomato" }}
                                      />
                                    }
                                />
                              ))
                            }
                          </VictoryGroup>
                        </VictoryChart>
                        <Row>
                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <div>
                            <ul className="listaCota">

                                  <li key={index}>
                                    &nbsp;{props.nomMunicipios.length > 0 ? props.nomMunicipios[index] : obtenerEstado(props.estadosAnalizar[index])}
                                    <ul className="segundoUl">
                                      {elemento && elemento.map((element, i) => (
                                      <li>
                                      <div style={{'backgroundColor': colores[i],'width': '10px',
                                         'height': '10px','display': 'inline-block'}}></div>
                                         {element.label}
                                         <ul className="segundoUl">
                                           {element.data && element.data.map((elemen, j) => (
                                           <li>
                                              {elemen.x}.-&nbsp;{elemen.label}&nbsp;{elemen.y}
                                           </li>
                                           ))}
                                         </ul>
                                      </li>
                                      ))}
                                    </ul>
                                  </li>
                            </ul>
                          </div>
                          </Col>
                        </Row>
                        </>
                      ))
                      }

                </div>

                <div id="barras"
                    className={props.tipoGrafica === "bar" && !props.preguntasDeRango ? "show" : "hide"}>
                  <VictoryChart domainPadding={20}
                      minDomain={{ x: 0 }}
                      theme={VictoryTheme.material}
                      >
                    <VictoryGroup horizontal
                      offset={10}
                      style={{ data: { width: 5 } }}
                      colorScale={colores}>
                        {
                        props.valoresAgraficar &&
                        props.valoresAgraficar.map((elemento, index) => (
                          <VictoryBar horizontal
                              barWidth={7}
                              alignment="middle"
                              cornerRadius={{top: 3.5}}
                              barRatio={0.9}
                              style={{ labels: { display: "none" } }}
                              data={elemento}
                              key={index}
                              labelComponent={
                                <VictoryTooltip
                                  style={{ fill: "tomato" }}
                                />
                              }
                          />
                        ))
                        }
                    </VictoryGroup>
                  </VictoryChart>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div>
                      <ul className="listaCota">
                      {
                        props.valoresAgraficar &&
                        props.valoresAgraficar.map((elemento, index) => (
                            <li key={index}>
                              <div style={{'backgroundColor': colores[index],'width': '10px',
                                 'height': '10px','display': 'inline-block'}}></div>
                              &nbsp;{props.nomMunicipios.length > 0 ? props.nomMunicipios[index] : obtenerEstado(props.estadosAnalizar[index])}
                              <ul className="segundoUl">
                                {elemento.map((element, i) => (
                                <li>
                                {element.x}.-&nbsp;{element.label}&nbsp;{element.y}
                                </li>
                                ))}
                              </ul>
                            </li>
                        ))
                        }
                      </ul>
                    </div>
                    </Col>
                  </Row>
                </div>
                </Col>
              </Row>

              <div id="area" className={props.tipoGrafica === "area" ? "show" : "hide"}>
              <VictoryChart  theme={VictoryTheme.material}>
              <VictoryStack colorScale={colores}>
                  {
                  props.valoresAgraficar &&
                  props.valoresAgraficar.map((elemento, index) => (
                    <VictoryGroup
                      data={elemento} key={index}>
                    <VictoryArea style={{ labels: { display: "none" } }}/>
                    <VictoryPortal>
                    <VictoryScatter
                      style={{ data: { fill: 'black' },labels: { display: "none" } }}
                    />
                  </VictoryPortal>
                </VictoryGroup>
                  ))
                  }
                  </VictoryStack>
                </VictoryChart>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div>
                    <ul className="listaCota">
                    {
                      props.valoresAgraficar &&
                      props.valoresAgraficar.map((elemento, index) => (
                          <li key={index}>
                            <div style={{'backgroundColor': colores[index],'width': '10px',
                               'height': '10px','display': 'inline-block'}}></div>
                            &nbsp;{props.nomMunicipios.length > 0 ? props.nomMunicipios[index] : obtenerEstado(props.estadosAnalizar[index])}
                            <ul className="segundoUl">
                              {elemento.map((element, i) => (
                              <li>
                              {element.x}.-&nbsp;{element.label}&nbsp;{element.y}
                              </li>
                              ))}
                            </ul>
                          </li>
                      ))
                      }
                    </ul>
                  </div>
                  </Col>
                </Row>
              </div>
              <div id="circular" className={props.tipoGrafica === "pie" ? "show" : "hide"}>
              {
              props.valoresAgraficar &&
              props.valoresAgraficar.map((elemento, index) => (
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <svg style={{width:'200px', height:"120px"}}>
                          <circle cx={100} cy={100} r={80} fill='#FFFFFF'/>
                          <VictoryPie
                              standalone={false}
                              radius={55}
                              origin={{ x: 100, y: 100 }}
                              startAngle={90}
                              endAngle={-90}
                              innerRadius={100}
                              cornerRadius={0}
                              colorScale={colores}
                              data={elemento}
                          />
                      </svg>
                      <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div>
                          <ul className="listaCota">
                                <li key={index}>
                                  {props.nomMunicipios.length > 0 ? props.nomMunicipios[index] : obtenerEstado(props.estadosAnalizar[index])}
                                  <ul className="segundoUl">
                                    {elemento.map((element, i) => (
                                    <li>
                                    <div style={{'backgroundColor': colores[i],'width': '10px',
                                       'height': '10px','display': 'inline-block'}}></div>
                                    &nbsp;{element.label}&nbsp;{element.y}
                                    </li>
                                    ))}
                                  </ul>
                                </li>
                          </ul>
                        </div>
                        </Col>
                      </Row>
                  </Col>
                </Row>
              ))
              }
              </div>
            </div>
        </div>
    )

}

export default VComparaGraficas;
