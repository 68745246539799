import React from 'react';
import { createStructuredSelector } from 'reselect';
import {connect} from 'react-redux';
import axios from 'axios';
import { obtenerEstatusModulo } from '../../../redux/menu/menu-utils';
import { selectEstado, selectDistrito } from '../../../redux/menu/menu-selectors';
import { selectCurrentUser, selectCurrentUserRol } from '../../../redux/user/user-selectors';
import * as etiquetas from '../../../constants/EtiquetasGenerales';
import * as info from '../../../constants/InfoSistema';
import CTemplate from '../../homeInterno/CTemplate';
import AuthenticatedComponent from '../../../components/comunes/AuthenticatedComponent';
import VAsignacionVoluntarios from '../../../components/casillas/asignacion/VAsignacionVoluntarios';


//import * as infoSistema from '../../constants/InfoSistema';

const jwt = localStorage.getItem('jwt-token');

class CAsignacionVoluntarios extends React.Component{
  
  constructor(props){
    super(props);
    this.state = {
      activarCasilla : true,
      page: 1,
      infoCasillas : [],
      paginas: 0,
      paginado:0,     
      datosCasilla : [],
      infoVoluntarios: [],
      infoVoluntariosAsignados: [],
      infoVoluntariosAsignadosOriginal:[],
      eliminarVoluntariosAsignados: [],
      ocultarCasillas: false,
      ocultarVoluntarios: true,
      mensajeNoExistenDatos: false,
      mensajeNoExisteCasilla: false,
      recuperaDatos:[],
      idCasilla: 0,
      folioCasilla: "",
      mensajeVolMaximo: false,
      mensajeVolEliminado: false,
      isModalVisible: false,
      tipoCasilla: "",
      seccion: 0,
      distrito: 0,
      zore: 0,
      ubicacion: "",
      idCasillas:[],
      secciones:[],
      idMunicipios: [],
      busquedaPorFolioCasilla: null,
      busquedaPorSeccion: null,
      busquedaPorMunicipio: null,
      busquedaPorVolAsignados: null,
      busquedaPorTipoCasilla: null,
      isModalVisibleDatosGuradados: false,
      folioVoluntario: null,
      nombreVoluntario: null,
      banderaModificacion: false,
      banderaBotonGuardar: true,
      mensajeAnadido: false,
      infoMunicipios: [],
      idDistrito: null,
      infoTiposCasillas: [],
      voInfoAsignacionCasillas: [],
      voluntario:[],
      banderaVoluntario: true,
      voInfoQuitarVoluntariosCasillas:[],
      actualizaCasillas: false,
      idParticipacionCasilla: 0,
      filteredData :[],
      estatusModulo: false,
      fechaHora:"",
      banderaBorrarTodo: false,
      seccionCasilla:0,
      idMunicipio:0,
      idCasillaVol:null

    }
    console.log("los props en asignacion ",this.props);
  }

  componentDidMount(){
  this.obtenerCasillasAsig();     
  }

  componentDidUpdate(){  
    if(this.state.idDistrito != this.props.distrito.idDistrito){
      this.obtenerCasillasAsig();
    }    
    if(this.state.actualizaCasillas){
      this.obtenerCasillasAsig();      
    } 
  }

  /**
  * @author arturo.gomez
  **/
    paginacionCasillas = (valor) =>{
      console.log("VALOR-------------------------");
      console.log(valor);
      const valorFinal = (valor -1)*5;
      this.state.paginado=valorFinal;      
      console.log(this.state.paginado);
      this.obtenerCasillasAsig();      
    }
   
    busquedaFolioCasilla = (valor) =>{
      console.log("VALOR DEL FOLIO CASILLA **************************");
      if(valor!=""){
        this.state.busquedaPorFolioCasilla = valor.toUpperCase();
      }else{
        this.state.busquedaPorFolioCasilla = null;
      }
              
      console.log(this.state.busquedaPorFolioCasilla);
      this.obtenerCasillasAsig();
    }    
    busquedaSeccion = (valor) =>{
      console.log("VALOR DE LA SECCION**************************");
      if(valor!=""){
        this.state.busquedaPorSeccion = valor;
      }else{
        this.state.busquedaPorSeccion = null;
      }
              
      console.log(this.state.busquedaPorSeccion);
      this.obtenerCasillasAsig();
    }

    busquedaMunicipio = (valor) =>{
      console.log("VALOR DEL MUNICIPIO**************************");     
      if(valor!=""){
        this.state.busquedaPorMunicipio = valor;
      }else{
        this.state.busquedaPorMunicipio = null;
      }
              
      console.log(this.state.busquedaPorMunicipio);
      this.obtenerCasillasAsig();
    }

    busquedaTipoCasilla = (valor) =>{
      console.log("VALOR TIPOS CASILLAS**************************");     
      if(valor!=""){
        this.state.busquedaPorTipoCasilla = valor;
      }else{
        this.state.busquedaPorTipoCasilla = null;
      }
              
      console.log(this.state.busquedaPorTipoCasilla);
     this.obtenerCasillasAsig();
    }

    busquedaVolAsignados = (valor) =>{
      console.log("VALOR Voluntarios asignados**************************");     
      if(valor!=""){
        this.state.busquedaPorVolAsignados = valor;
      }else{
        this.state.busquedaPorVolAsignados = null;
      }
              
      console.log(this.state.busquedaPorVolAsignados);
      this.obtenerCasillasAsig();
    }

    busquedaFolioVol = (valor) =>{
      console.log("VALOR DEL FOLIO DE CASILLA (VOLUNTARIO)**************************");     
      if(valor!=""){
        this.state.folioVoluntario = valor.toUpperCase();
        this.state.nombreVoluntario =null;
      }else{
        this.state.folioVoluntario = null;
        this.state.nombreVoluntario =null;
      }
              
      console.log(this.state.folioVoluntario);
      this.obtenerVoluntariosAsig();
    }

    busquedaVoluntario = (valor) =>{
      console.log("VALOR DEL NOMBRE DE VOLUNTARIO(VOLUNTARIO)**************************");
      if(valor!=""){
        this.state.nombreVoluntario = valor.toUpperCase();
        this.state.folioVoluntario = null;
      }else{
        this.state.nombreVoluntario =null;
        this.state.folioVoluntario = null;        
      }
              
      console.log(this.state.nombreVoluntario);
      this.obtenerVoluntariosAsig();
    }
    
    guardarDatos = ()  =>{
      console.log("BOTON GUARDAR");
      this.state.voInfoAsignacionCasillas=[];
      this.state.voInfoQuitarVoluntariosCasillas=[];

      this.state.infoVoluntariosAsignados.map((asignar)=> {           
          const filteredData = this.state.infoVoluntariosAsignadosOriginal.filter((busqueda)=> {
            if(busqueda.idVoluntario == asignar.idVoluntario){
                return busqueda;
            }
        });
          if(filteredData.length>0){
            console.log("No incluye");           
          }else{            
            console.log("Entro asignar");
            this.state.voInfoAsignacionCasillas.push({
              idDetalleProceso : info.ID_DETALLE_PROCESO,
              idCasilla : this.state.idCasilla,
              idVoluntario : asignar.idVoluntario,
              usuario : this.props.user.username,
              ipUsuario: this.props.user.ip,
              idParticipacion: asignar.idParticipacion,
              correo: asignar.correo,
              folioVol:asignar.folio,
              nombreVol: asignar.nombreVoluntario,
              ubicacionCasilla: this.state.ubicacion,
              fechaHora :this.state.fechaHora,
              folioCasilla :this.state.folioCasilla
            });            
          }
      });    


    this.state.infoVoluntariosAsignadosOriginal.map((quitar)=> {
      const filteredData2 = this.state.infoVoluntariosAsignados.filter((busqueda)=> {
        if(busqueda.idVoluntario == quitar.idVoluntario){
            return busqueda;
        }
    });
    if(filteredData2.length==0){
        this.state.voInfoQuitarVoluntariosCasillas.push({
          idCasillaVoluntario: quitar.idCasillaVoluntario,
          idCasilla : this.state.idCasilla,
          idVoluntario: quitar.idVoluntario,
          correo: quitar.correo,
          folioVol:quitar.folio,
          folioCasilla :this.state.folioCasilla
        });           
      }
    });
    
    console.log("ASIGNAR!!"); 
    console.log(this.state.voInfoAsignacionCasillas);
    console.log("QUITAR!!"); 
    console.log(this.state.voInfoQuitarVoluntariosCasillas);
    this.asignarCasillas();     

    console.log("LO ULTIMO DEL BOTON GUARDAR");
    this.state.voInfoQuitarVoluntariosCasillas = [];
    this.state.voInfoAsignacionCasillas = [];
    this.state.busquedaPorFolioCasilla = null;
    this.state.busquedaPorSeccion = null;
    this.state.busquedaPorMunicipio = null;
    this.setState({
          isModalVisibleDatosGuradados: true      
        });     
  }

  quitarTodo = (values)  =>{
    console.log("MÉTODO DE QUITAR TODO");
    this.state.voInfoAsignacionCasillas=[];
    this.state.voInfoQuitarVoluntariosCasillas=[];
    this.state.banderaBorrarTodo =true;   
   this.obtenerVoluntariosAsig(values);
  
   }

  
  

  obtenerEstatusModulo1  = async () => {
    console.log("METODO DE OBTENER ESTATUS DEL MODULO Y VALIDAR ROLES");
    const ROLES_CAPTURA = {
      "CIJ.ADMIN.OC": true,      
      "CIJ.CAU.OC": true,
      "CIJ.CAPTURA_VCEYEC.JD": true,
      "CIJ.CAPTURA.JD": true,
      "CIJ.CAPTURA.JL": true,
      "CIJ.CAPTURA_VCEYEC.JL": true
  };
      
          try{
            console.log(this.props);
            var idModulo = this.props.location.state !== undefined ?
            this.props.location.state.idModulo : 0;

            const estatus =(await obtenerEstatusModulo(info.ID_PROCESO_ELECTORAL,
              info.ID_DETALLE_PROCESO,
              info.ID_SISTEMA,
              this.props.estado.idEstado, 
              this.props.distrito.idDistrito,
              idModulo, 
              this.props.user.rolUsuario)); 

            console.log(estatus);
            console.log(this.props.user.rolUsuario)
            console.log("RESULTADO DEL ESTATUS DEL MODULO");
            if(estatus=="A" && ROLES_CAPTURA[this.props.user.rolUsuario]){
              this.state.estatusModulo = true;
            }                      
            console.log(this.state.estatusModulo);

          }catch(error){
          console.error("Error CAsignacionVoluntarios: ", error);
          }                                        
  };


  /**
  * Obtiene las casillas
  * 
  */
  obtenerCasillasAsig = (value) =>{
    console.log("LLEGUE AL METODO DE OBTENER CASILLAS"); 
    this.obtenerEstatusModulo1();  
    this.state.infoCasillas = []; 
    this.state.recuperaDatos=[];    
    this.state.actualizaCasillas=false;
    this.state.banderaBotonGuardar = true;
    this.state.idDistrito = this.props.distrito.idDistrito;   
    
    var datosUsuario = localStorage.getItem('datos-usuario') !== null ?
        JSON.parse(localStorage.getItem('datos-usuario')): null;    
    
    console.log("DATOS QUE NECESITO");
   // console.log(this.props);
   // console.log(this.props.location.state.idModulo);
  
    var postData = {
      idEstado: this.props.estado.idEstado,
      idDistrito: this.props.distrito.idDistrito,
      idProcesoElectoral: info.ID_PROCESO_ELECTORAL,
      idDetalleProceso: info.ID_DETALLE_PROCESO,
      paginado: this.state.paginado,
      folioCasilla: this.state.busquedaPorFolioCasilla,
      seccion: this.state.busquedaPorSeccion,
      municipio: this.state.busquedaPorMunicipio,
      tipoCasilla : this.state.busquedaPorTipoCasilla      
    };  
    console.log(this.props.user);
    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL +
      "ws/obtenerCasillasAsig", postData,
          {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.user ? this.props.user.tknJWT : null
              }
          }
       ).then(response => {        
        if(response.data.code === 200){       
         this.setState({
           infoCasillas:response.data.infoCasillas,
           infoMunicipios:response.data.infoMunicipios,
           paginas:response.data.totalRegistros*2,
           infoTiposCasillas: response.data.infoTiposCasillas,
           mensajeNoExistenDatos: false,
           idCasillas: response.data.idCasillas,
           secciones: response.data.secciones,
           idMunicipios: response.data.idMunicipios,
           mensajeNoExisteCasilla: false,
           paginado:0                  
         });         
        }else{
          this.setState({
            infoCasillas: [],
            busquedaPorFolioCasilla: null,
            busquedaPorSeccion: null,
            paginas:0
          });
        } 
        console.log("INFO DE CASILLAS");
        console.log(this.state.infoCasillas);

      }).catch(error => {
        console.error("Error al obtener las casillas",error);
    });
  }

  /**
  * @author arturo.gomez  * 
  **/
    paginacionVoluntarios = (valor) =>{
      console.log("VALOR-------------------------");
      console.log(valor);
      const valorFinal = (valor -1)*5;
      this.state.paginado=valorFinal;      
      console.log(this.state.paginado);
      this.obtenerVoluntariosAsig();      
    }   


    /**
  * @author arturo.gomez  * 
  **/
     paginacionVoluntarios1 = () =>{      
      const valorFinal = (1 -1)*5;
      this.state.paginado=valorFinal;      
      console.log(this.state.paginado);
      this.obtenerVoluntariosAsig();      
    }

   /**
  * Obtiene los voluntarios.
  */
    obtenerVoluntariosAsig = (values) =>{
      console.log("LLEGUE AL METODO DE OBTENER VOLUNTARIOS");
      console.log(values);
      this.setState.mensajeVolMaximo = false;
           
      if(values != undefined){
        this.state.recuperaDatos.push({
          folioCasilla: values.folioCasilla,
          idCasilla: values.idCasilla,
          seccion: values.seccion,
          idMunicipio: values.idMunicipio,
          volunAsig: values.volunAsig,
          idParticipacionCas: values.idParticipacion,
          ubicacionCasilla:values.ubicacion,
          fecha_hora :values.fec_hrs_instalacion
        });
        if(values.volunAsig>0){
          this.state.banderaModificacion = true;
        }
      }
          this.state.infoVoluntarios=[];
          //this.state.infoVoluntariosAsignados=[];
          var postData = {};
        
          this.state.recuperaDatos?.map((dato)=>{
            var postData1 = {
              idEstado: this.props.estado.idEstado, 
              idDistrito: this.props.distrito.idDistrito,
              idProcesoElectoral : info.ID_PROCESO_ELECTORAL,
              idDetalleProceso : info.ID_DETALLE_PROCESO,
              paginado : this.state.paginado,
              idCasilla : dato.idCasilla,
              idMunicipio : dato.idMunicipio,
              seccion : dato.seccion,
              modificar : true,
              folioVoluntario: this.state.folioVoluntario,
              nombreVoluntario: this.state.nombreVoluntario
            };

            postData = postData1;
            this.setState({
              idCasilla: dato.idCasilla,
              folioCasilla: dato.folioCasilla,
              idParticipacionCasilla: dato.idParticipacionCas,
              ubicacion: dato.ubicacionCasilla,
              fechaHora: dato.fecha_hora,
              seccionCasilla: dato.seccion
            });
          });    
          console.log(postData);    
      axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL +
        "ws/obtenerVoluntariosAsig", postData,
            {
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': this.props.user ? this.props.user.tknJWT : null
                }
            }
         ).then(response => { 
          if(response.data.code === 200 && response.data.message ===""){
            if(this.state.banderaBorrarTodo){
              this.setState({
                infoVoluntarios:response.data.infoVoluntarios,
                infoVoluntariosAsignados:response.data.infoVoluntariosAsignados,
                infoVoluntariosAsignadosOriginal:response.data.infoVoluntariosAsignados               
              });
              this.state.infoVoluntariosAsignadosOriginal.map((quitar)=> {
                this.state.voInfoQuitarVoluntariosCasillas.push({
                  idCasillaVoluntario: quitar.idCasillaVoluntario,
                  idCasilla : this.state.idCasilla,
                  idVoluntario: quitar.idVoluntario,
                  correo: quitar.correo,
                  folioVol:quitar.folio
                });
              });
              this.asignarCasillas();
              this.state.banderaBorrarTodo =false;
              this.obtenerCasillasAsig();
            }else{
              console.log("infoVoluntariosAsignados**************@@@@@@@@@@@@@@@@@@@@@");
              console.log(this.state.infoVoluntariosAsignados);

             
              if(this.state.idCasilla === this.state.idCasillaVol){
                if(this.state.infoVoluntariosAsignados.length===0){
                  this.setState({
                    infoVoluntariosAsignados:response.data.infoVoluntariosAsignados
                  });
                }
              }else{
                this.state.idCasillaVol = this.state.idCasilla;
                this.setState({
                  infoVoluntariosAsignados:response.data.infoVoluntariosAsignados
                });                             
              }             
              
              this.setState({
                infoVoluntarios:response.data.infoVoluntarios,                
                infoVoluntariosAsignadosOriginal:response.data.infoVoluntariosAsignados,
                paginas:response.data.totalRegistros*2,
                ocultarCasillas:true,
                ocultarVoluntarios:false,
                paginado: 0
              });         

            }
                                    
              console.log(this.state.infoVoluntarios);
              }else{
            this.setState({
              mensajeNoExistenDatos: true

            });
          }
          console.log("infoVoluntariosAsignadosOriginal");
          console.log(this.state.infoVoluntariosAsignadosOriginal);
          
        }).catch(error => {
          console.error("Error al obtener las casillas",error);
      });
    }


agregarVoluntarios = (values) =>{
  this.state.banderaVoluntario= true;
  this.state.voluntario = [];
  let count = 0;
  this.state.infoVoluntariosAsignados?.map((dato)=>{   
    console.log("ENTRA ");
    count = count +1;
  });

if(count < 2){
      this.state.voluntario.push({
        correo:values.correo,
        folio:values.folio,
        idParticipacion:values.idParticipacion,
        nombreVoluntario:values.nombreVoluntario,
        idVoluntario : values.idVoluntario,
        nombreMunicipio: values.nombreMunicipio
    });
    this.listasVoluntarios();
  }else{
    console.log("maximo 2 vol");
  }

}

quitarVoluntarios = (values) =>{
  this.state.banderaVoluntario= false;
  this.state.voluntario = [];
  this.state.voluntario.push({
    correo:values.correo,
    folio:values.folio,
    idParticipacion:values.idParticipacion,
    nombreVoluntario:values.nombreVoluntario,
    idVoluntario : values.idVoluntario,
    nombreMunicipio: values.nombreMunicipio
  });
  this.listasVoluntarios();  
}

/**
 * Metodo de agregar voluntarios a la lista
 */
listasVoluntarios = () =>{
  console.log("LLEGUE AL METODO DE OBTENER LISTAS");
  if(this.props.user.rolUsuario != "CIJ.CAU.OC"){
    this.state.banderaBotonGuardar = false;
  }
      

    var postData= {
      banderaVoluntario:this.state.banderaVoluntario,
      voInfoVoluntarios: this.state.infoVoluntariosAsignados,
      voluntario: this.state.voluntario,
      infoVoluntariosDisponibles :this.state.infoVoluntarios
    };   
    console.log(postData);   
        axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL +
          "ws/manejoListaVol", postData,
              {
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.props.user ? this.props.user.tknJWT : null
                  }
              }
          ).then(response => { 
            if(response.data.code === 200){
                  this.setState({
                    infoVoluntariosAsignados:response.data.infoVoluntariosAsignados,
                    infoVoluntarios:response.data.infoVoluntarios
                });                
              }
              
              console.log("NUEVA LISTA DE disponibles");
              console.log(this.state.infoVoluntarios);
              console.log("NUEVA LISTA PARA ASIGNAR");
              console.log(this.state.infoVoluntariosAsignados);
          }).catch(error => {
            console.error("Error al actualizar las listas",error);      
        });         
}


  /**
  * Asignar los voluntarios.
  */
       asignarCasillas = () =>{
        console.log("LLEGUE AL METODO DE ASIGNAR VOLUNTARIOS");
              
        this.state.folioVoluntario = null;
        this.state.nombreVoluntario =null;
           // this.state.infoVoluntarios=[];
            //this.state.infoVoluntariosAsignados=[];
            var postData = {};           
              var postData = {
                voInfoAsignacionCasillas: this.state.voInfoAsignacionCasillas,
                voInfoQuitarVoluntariosCasillas: this.state.voInfoQuitarVoluntariosCasillas,
                idDetalleProceso : info.ID_DETALLE_PROCESO,
                idCasilla : this.state.idCasilla,
                idParticipacionCasilla : this.state.idParticipacionCasilla,
                usuario : this.props.user.username,
                ipUsuario: this.props.user.ip,
                idEstado: this.props.estado.idEstado, 
                idDistrito: this.props.distrito.idDistrito,
                seccionCasilla: this.state.seccionCasilla,
                folioCasilla :this.state.folioCasilla
              };              
              console.log("DATOS postData----------------------------");
              console.log(postData);
                      
        axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL +
          "ws/asignarCasillas", postData,
              {
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.props.user ? this.props.user.tknJWT : null
                  }
              }
           ).then(response => {          
            if(response.data.code === 200){                 
            }            
          }).catch(error => {
            console.error("Error al asignar las casillas",error);
        });
      }
  errorEnFormulario = (errorInfo) => {
		console.error('Failed:', errorInfo);
	};

errorEnFormulario = (errorInfo) => {
console.info("Error en formulario, ver la parte de errores.");
console.error('Failed:', errorInfo);
};

modalInfo = (value) =>{

  this.setState({
    isModalVisible: true,
    folioCasilla: value.folioCasilla,
    idCasilla: value.idCasilla,
    tipoCasilla: value.tipoCasilla,
    seccion: value.seccion,
    distrito: this.props.distrito.idDistrito,
    zore: value.idZonaResponsabilidad,
    ubicacion: value.ubicacion
  });
}
handleOk = () =>{
  this.setState({
    isModalVisibleDatosGuradados: false,
    ocultarVoluntarios: true,
    ocultarCasillas: false,     
  });
  this.state.actualizaCasillas =true;
  this.state.paginado= 0;
  this.state.busquedaPorMunicipio = null;
  this.state.busquedaPorFolioCasilla =null;
  this.state.busquedaPorSeccion = null;  
  this.state.banderaModificacion = false;
}

handleCancel = () =>{
  this.setState({
    isModalVisibleDatosGuradados: false,
    ocultarVoluntarios: true,
    ocultarCasillas: false,
    actualizaCasillas:true      
  });
  this.state.paginado= 0;
  this.state.busquedaPorMunicipio = null;
  this.state.busquedaPorFolioCasilla =null;
  this.state.busquedaPorSeccion = null;  
  this.state.banderaModificacion = false; 
}



  funcionListo = () => {
    
  }

  render(){
    return(    
      <>
        <AuthenticatedComponent > 
          <CTemplate contenido={       
            <VAsignacionVoluntarios             
              paginacionCasillas = {this.paginacionCasillas}
              columns = {this.state.columns}
              datosCasilla = {this.state.datosCasilla}
              paginas = {this.state.paginas}
              page = {this.state.page}              
              infoCasillas = {this.state.infoCasillas}
              activarCasilla ={this.state.activarCasilla} 
              clicCasilla ={this.clicCasilla} 
              obtenerVoluntariosAsig = {this.obtenerVoluntariosAsig}
              ocultarCasillas = {this.state.ocultarCasillas}
              infoVoluntarios = {this.state.infoVoluntarios} 
              infoVoluntariosAsignados = {this.state.infoVoluntariosAsignados}
              ocultarVoluntarios = {this.state.ocultarVoluntarios}
              paginacionVoluntarios = {this.paginacionVoluntarios}
              volunAsig = {this.state.volunAsig}
              idCasilla = {this.state.idCasilla}
              idMunicipio = {this.state.idMunicipio}
              seccion = {this.state.seccion}
              recuperaDatos = {this.state.recuperaDatos}
              mensajeNoExistenDatos = {this.state.mensajeNoExistenDatos}
              idCasilla = {this.state.idCasilla}
              asignarCasillas = {this.asignarCasillas}
              mensajeVolMaximo = {this.state.mensajeVolMaximo}
              eliminarVoluntario= {this.eliminarVoluntario}
              mensajeVolEliminado = {this.state.mensajeVolEliminado}
              isModalVisible = {this.state.isModalVisible}
              modalInfo = {this.modalInfo}
              handleCancel = {this.handleCancel}
              tipoCasilla = {this.state.tipoCasilla}
              seccion = {this.state.seccion}
              distrito = {this.state.distrito}
              zore = {this.state.zore}
              ubicacion = {this.state.ubicacion}
              busquedaFolioCasilla = {this.busquedaFolioCasilla}
              busquedaSeccion = {this.busquedaSeccion}              
              busquedaMunicipio = {this.busquedaMunicipio}
              folioCasilla = {this.state.folioCasilla}
              mensajeNoExisteCasilla = {this.state.mensajeNoExisteCasilla}
              isModalVisibleDatosGuradados = {this.state.isModalVisibleDatosGuradados}
              guardarDatos = {this.guardarDatos}
              busquedaFolioVol = {this.busquedaFolioVol}
              busquedaVoluntario = {this.busquedaVoluntario}
              banderaModificacion = {this.state.banderaModificacion}
              banderaBotonGuardar = {this.state.banderaBotonGuardar}
              mensajeAnadido = {this.state.mensajeAnadido}
              infoMunicipios = {this.state.infoMunicipios}
              busquedaVolAsignados = {this.busquedaVolAsignados}
              infoTiposCasillas = {this.state.infoTiposCasillas}
              busquedaPorTipoCasilla = {this.state.busquedaPorTipoCasilla}
              busquedaTipoCasilla = {this.busquedaTipoCasilla}
              agregarVoluntarios = {this.agregarVoluntarios}
              quitarVoluntarios = {this.quitarVoluntarios}
              listasVoluntarios = {this.listasVoluntarios}
              estatusModulo = {this.state.estatusModulo}
              handleOk = {this.handleOk}
              quitarTodo = {this.quitarTodo}
              obtenerCasillasAsig = {this.obtenerCasillasAsig}
              paginacionVoluntarios1= {this.paginacionVoluntarios1}
              paginado = {this.state.paginado}
              
              
            />
            }
              guia={false}/>         
          </AuthenticatedComponent>
      </>

    );
  }
}
const mapStateToProps = createStructuredSelector({
  estado: selectEstado,
  distrito: selectDistrito,
  user: selectCurrentUser
});

export default connect(mapStateToProps)(CAsignacionVoluntarios);
