import React from 'react';
import VLabelImage from './VLabelImage';
//import img_niña from '../../assets/img/home_niña.png';
//import img_niño from '../../assets/img/home_niño.png';
//import img_indefinido from '../../assets/img/home_indefinido.png';
import * as etiquetasHome from '../../constants/EtiquetasHomePortal';

const VCardParticipantesSexo = ({ participantesSexo }) => {

    const extraData = {
        [etiquetasHome.home_card_participantes_sexo_mujer]: {
            imageUrl: 1,
            color: 'var(--chart-bar1)',
        }, 
        [etiquetasHome.home_card_participantes_sexo_hombre]: {
            imageUrl: 2,
            color: 'var(--chart-bar4)'
        }, 
        [etiquetasHome.home_card_participantes_sexo_indefinido]: {
            imageUrl: 3,
            color: 'var(--chart-bar2)'
        }
    };
    
    return(
        <div className='home-card-participantes-sexo'>
            {
                participantesSexo.map((sexo) => {
                    return <VLabelImage key={sexo.labelDato}
                                        {...sexo}
                                        {...extraData[sexo.labelDato]}/>
                })
            }
        </div>
    );
}

export default VCardParticipantesSexo;