import React, {useState, useEffect} from 'react';
import {Form, Layout, Tabs,Row, Col, Card, Input, Button, Modal, Select,Tooltip, Divider} from 'antd';
import * as etiquetaSede from '../../constants/etiquetasSedes';
import sedesRegistradaImg from '../../assets/img/sedesFisicas.png';
import sedesRegistradaVirt from '../../assets/img/sedesVirtuales.png';
import iconoQuestion from '../../assets/img/icono_question.png';
import '../../css/sedes/sedes.scss';
import 'moment/locale/es';
const { TextArea } = Input;
const { Option } = Select;


const { TabPane } = Tabs;

const VCapturaSedes = (props) =>  {

    const [form] = Form.useForm();

    const [tipoSede, setTipSede] = useState();
    const [lugar, setLugarSede] = useState();
    const [nombreCapacitador, setNombre] = useState();
    const [cargo_Capacitador, setCargo] = useState();
    const [link, setLinkSede] = useState();
    const [calle, setCalle] = useState();
    const [numExt, setnumeroE] = useState();
    const [numI, setnumeroI] = useState();
    const [colonia, setColonia] = useState();
    const [seccion, setSeccion] = useState();
    const [municipio, setmunicipio] = useState();
    const [codigo, setCodigoPo] = useState();
    const [extencion, setExt] = useState();
    const [telefono, settelefono] = useState();
    const [referencia, setReferencia] = useState();
    const [obser, setObser] = useState();

    const [fvalLugar, setValLugar] = useState(false);
    const [fvalNombre, setValNombre] = useState(false);
    const [fvalCargo, setValCargo] = useState(false);
    const [fvalCalle, setValCalle] = useState(false);
    const [fvalNum, setValNumExt] = useState(false);
    const [fvalNumInt, setValNumInt] = useState(false);
    const [fvalColonia, setValColonia] = useState(false);
    const [fvalCp, setValCodigoP] = useState(false);
    const [fvalTel, setValTelefono] = useState(false);
    const [fvalExt, setValExtension] = useState(false);
    const [fvalReferencia, setValReferencia] = useState(false);
    const [fvalObserv, setValObserv] = useState(false);
    const [valres, setValres] = useState(500);
    const [valresReferencia, setValresReferencia] = useState(500);
    

    const clearSelected = () => {
        form.setFieldsValue({ seccion: undefined });
        setSeccion(undefined);
    }

function handleChange(value) {
    console.log(`selected ${value}`);
    if (value === 'V') {
        document.getElementById('link').style.display = 'block';
        document.getElementById('idObs').style.display = 'block';
        document.getElementById('nombre').style.display = 'block';
        document.getElementById('cargo').style.display = 'block';
        document.getElementById('lugar').style.display = 'none';
        document.getElementById('tit-Dom').style.display = 'none';
        document.getElementById('idcalle').style.display = 'none';
        document.getElementById('idNumE').style.display = 'none';
        document.getElementById('idNumI').style.display = 'none';
        document.getElementById('idCol').style.display = 'none';
        document.getElementById('idSeccion').style.display = 'none';
        document.getElementById('idMuni').style.display = 'none';
        document.getElementById('idCod').style.display = 'none';
        document.getElementById('idExt').style.display = 'none';
        document.getElementById('idTel').style.display = 'none';
        document.getElementById('idRef').style.display = 'none';
    }else if (value === 'P'){
        document.getElementById('link').style.display = 'none';
        document.getElementById('idObs').style.display = 'none';
        document.getElementById('nombre').style.display = 'block';
        document.getElementById('cargo').style.display = 'block';
        document.getElementById('lugar').style.display = 'block';
        document.getElementById('tit-Dom').style.display = 'block';
        document.getElementById('idcalle').style.display = 'block';
        document.getElementById('idNumE').style.display = 'block';
        document.getElementById('idNumI').style.display = 'block';
        document.getElementById('idCol').style.display = 'block';
        document.getElementById('idSeccion').style.display = 'block';
        document.getElementById('idMuni').style.display = 'block';
        document.getElementById('idCod').style.display = 'block';
        document.getElementById('idExt').style.display = 'block';
        document.getElementById('idTel').style.display = 'block';
        document.getElementById('idRef').style.display = 'block';        
    }
}
    
useEffect(() => {
    nomCapacitador();
    Lugar();
    Calle();
    numeroExterior();
    numeroInterior();
    Colonia();
    codigoPosta();  
    telefon();
    exten();
    Referencia();
    Observ();
    
}, [nombreCapacitador, cargo_Capacitador, lugar, calle, numExt, numI, codigo, colonia, referencia, telefono, extencion, obser, fvalNombre, fvalColonia, fvalReferencia, fvalLugar,
    fvalCalle, fvalNum, fvalCargo, fvalNumInt, fvalCp, fvalTel, fvalExt, fvalObserv]);

    const nomCapacitador = (e) => {

        const valor = nombreCapacitador;
        const reg = /[!¡¿°´@#$%^&*()_+\-=\[\]{}"\\|<>\/?]+/;
              
        if(reg.test(valor)){
            setValNombre(true);
        }else if(!isNaN(valor) && valor.length >=250){
            setValNombre(true);
        }else{
            setValNombre(false);
        }

    }

    const cargoCapacitador = (e) => {

        const valor = cargo_Capacitador;
        const reg = /[!¡¿°´@#$%^&*()_+\-=\[\]{}"\\|<>\/?]+/;
              
        if(reg.test(valor)){
            setValCargo(true);
        }else if(!isNaN(valor) && valor.length >=250){
            setValCargo(true);
        }else{
            setValCargo(false);
        }

    }
    const Lugar = (e) => {

        const valor = lugar;
        const reg = /[!¡¿´@#$%^&*_+=\\[\]{};"\\|<>\/?]+/;
        
        if(valor!== undefined){
    
            setValres(500 - valor.length)
        }
    
        if(reg.test(valor)){
            setValLugar(true);
        }else if(!isNaN(valor) && valor.length >=500){
            setValLugar(true);
        }else{
            setValLugar(false); 
        }
    }

    const Calle = (e) => {

        const valor = calle;
        const reg = /[!¡¿´@#$%^&*_+=\\[\]{};"\\|<>\/?]+/;
    
        if(reg.test(valor)){
            setValCalle(true);
        }else if(!isNaN(valor) && valor.length >=50){
            setValCalle(true);
        }else{
            setValCalle(false);
        }
    
    }
    const numeroExterior = (e) => {

        const valor = numExt;
        const reg = /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i;
    
        if(reg.test(valor)){
            setValNumExt(true);
        }else if(!isNaN(valor) && valor.length <=5){
            setValNumExt(true);
        }else{
            setValNumExt(false);
        }
    }

    const numeroInterior = (e) => {


        const valor = numI;
        const reg = /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i;
    
        if(reg.test(valor)){
            setValNumInt(true);
        }else if(!isNaN(valor) && (valor.length >=1 || valor.length <=5)){
            setValNumInt(true);
        }else{
            setValNumInt(false);
        }

    }

    const Colonia = (e) => {

        const valor = colonia;
        const reg = /[!¡¿°´@#$%^&*()_+\-=\[\]{};"\\|<>\/?]+/;
    
        if(reg.test(valor)){
            setValColonia(true);
        }else if(!isNaN(valor) && valor.length >=50){
            setValColonia(true);
        }else{
            setValColonia(false);
        }
    
    }

    const codigoPosta = (e) => {


        const valor = codigo;
        const reg = /^\d*$/i;
        const reg2 = /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i;

        if(!isNaN(valor) && reg.test(valor) && valor.length === 5 && reg2.test(valor)){
            setValCodigoP(true);
        }else if(!isNaN(valor) && (valor.length >= 1 || valor.length < 5)){
            setValCodigoP(false);
        }else{
            setValCodigoP(false);
        }
    }

    const telefon = (e) => {

        const valor = telefono;
        const reg = /^\d*$/i;
        const reg2 = /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i;

        if(!isNaN(valor) && reg.test(valor) && valor.length === 10 && reg2.test(valor)){
            setValTelefono(true);
        }else if (!isNaN(valor && (valor.length >= 1 || valor.length < 8))){
            setValTelefono(false);
        }
    }

    const exten = (e) => {

        const valor = extencion;
        const reg = /^\d*$/i;
        const reg2 = /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i;

        if(valor === undefined){
            setValExtension(true);
        }
        
        else if(reg.test(valor) && (valor.length <= 0 || valor.length >= 4) && reg2.test(valor)){
            setValExtension(true);
        }else{
            setValExtension(false);
        }
    }

    const Referencia = (e) => {

        const valor = referencia;
        const reg = /[!¡¿°´@#$%^&*()_+\-=\[\]{}"\\|<>\/?]+/;

        if(valor!== undefined){
            setValresReferencia(500 - valor.length)
        }

        if(reg.test(valor)){
            setValReferencia(true);
        }else if(!isNaN(valor) && valor.length >=500){
            setValReferencia(true);
        }else{
            setValReferencia(false);
        }

    }

    const Observ = (e) => {

        const valor = obser;
        const reg = /[!¡¿°´@#$%^&*()_+\-=\[\]{}"\\|<>\/?]+/;

        
        if(reg.test(valor)){
            setValObserv(true);
        }else if(!isNaN(valor) && valor.length >=500){
            setValObserv(true);
        }else{
            setValObserv(false);
        }

    }

    



console.log("los props de Crear sedes", props);
return(
    
<Layout className='validar_modulo'>
    <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div >
                <h1 id="titulo-componente" >Datos de la sede de capacitación</h1>
            </div>
        </Col>
    </Row>
    <Tabs centered = {true} defaultActiveKey="" activeKey={props.activeTab} onChange={props.changeTab} size="small" className="tab-registra-Sedes">
      <TabPane disabled>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div >
                <h4>Captura los datos requeridos. Los datos con (<b><font color="red">*</font></b>) son obligatorios.</h4>
            </div>
        </Col>
    </Row>
        <br/>
        <Form form = {form} >
            <Card bordered={false}>
                <Row gutter={[16,16]}>
                    <Col xs={12} sm={12} md={12} lg={4} className='compomente_Est'>
                        <div className='lbTipoSede' >
                            <h3><b><font color="red">*</font></b>Tipo de sede</h3>
                        </div>
                        <div>
                            <Form.Item form = {form} name="tipoSede" rules={[{required: true, message: 'Dato requerido'}]}
                                >                                                                            
                                    <Select id="Select" name="tipoSede" placeholder="Seleccionar..."  onChange={(e) => {setTipSede(e)
                                                                                                                        handleChange(e)
                                                                                                                        props.selectSede(e)}} value={tipoSede}>
                                        {
                                            props.infoSede &&
                                            props.infoSede.map((sedes, index) => (
                                                <Option key={index} value={sedes.idSede}>
                                                    {sedes.nombreTipo}
                                                </Option>
                                            ))
                                        }
                                     </Select>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6} >
                        <div id="lugar" style={{display:'none'}} >
                            <div  >
                                <h3><b><font color="red">*</font></b>Lugar de la sede</h3>
                            </div>
                            
                            <div >
                                <Form.Item form = {form} name="lugar" rules={[{max:250, pattern: /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i, message: 'Caracteres no permitidos (/:*?”<>).'},{required: true, message: 'Dato requerido'}]} >
                                    <TextArea 
                                        name = "lugar"
                                        autoSize
                                        onChange = {(e) => {setLugarSede(e.target.value)
                                                            props.cambioText(e)
                                                            Lugar(e)}}
                                        maxLength={250}
                                        showCount
                                        
                                        ></TextArea>
                                </Form.Item>
                            </div>
                        </div>
                        <div id="link" style={{display:'none'}} >
                            <div className='compomente_link'>
                                <h3 className='lblink' ><b><font color="red">*</font></b>URL de acceso
                                    <Tooltip title={etiquetaSede.etiqueta_tooltip_URL_ACCESO}>
                                        <img style={{width:17 , height:17}} src={iconoQuestion}/>
                                     </Tooltip>
                                </h3>
                            </div>
                            <div>
                                <Form.Item name="link" rules={[{required: true, message: 'Dato requerido'}]}>
                                    <Input 
                                        name = "link"
                                        onChange = {(e) => {setLinkSede(e.target.value)
                                                            props.cambioText(e)}}
                                        maxLength={250}
                                        rules={[{
                                            max: 250
                                            , pattern: etiquetaSede.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                            }]}></Input>
                                </Form.Item>
                            </div>
                        </div>
                    </Col>      

                    <Col xs={12} sm={12} md={12} lg={6} >
                        <div id="nombre" style={{display:'none'}} >
                            <div  >
                                <h3><b><font color="red">*</font></b>Persona capacitadora</h3>
                            </div>
                            
                            <div >
                                <Form.Item form = {form} name="nombreCapacitador"  rules={[{max:250, pattern: /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i, message: 'Caracteres no permitidos (/:*?”<>).'},{required: true, message: 'Dato requerido'}]}>
                                    <Input 
                                        name = "nombreCapacitador"
                                        onChange = {(e) => {setNombre(e.target.value)
                                                            props.cambioText(e)
                                                            nomCapacitador(e)}}
                                        maxLength={250}
                                        
                                        ></Input>
                                </Form.Item>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={8} >
                        <div id="cargo" style={{display:'none'}} >
                            <div  >
                                <h3><b><font color="red">*</font></b>Cargo de la persona capacitadora</h3>
                            </div>
                            
                            <div >
                                <Form.Item form = {form} name="cargo_Capacitador"  rules={[{max:250, pattern: /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i, message: 'Caracteres no permitidos (/:*?”<>).'},{required: true, message: 'Dato requerido'}]}>
                                    <Input 
                                        name = "cargo_Capacitador"
                                        onChange = {(e) => {setCargo(e.target.value)
                                                            props.cambioText(e)
                                                            cargoCapacitador(e)}}
                                        maxLength={250}
                                        
                                        ></Input>
                                </Form.Item>
                            </div>
                        </div>
                    </Col>                        
                </Row>         
                <br />   
                <Row>
                    <div id="tit-Dom" style={{display:'none'}}>
                        <Col xs={24} sm={24} md={24} lg={24} >
                            <div>
                                <h1 id="titulo-componente">Domicilio</h1>                              
                            </div>
                            <div>
                                <Divider id='divide'/>            
                            </div>                            
                       </Col>                        
                    </div>                                                  
                </Row>

                <Row gutter={[16,16]}>                   
                    <Col xs={24} sm={12} md={9} lg={12} >
                        <div id='idcalle' style={{display:'none'}}>
                            <div className='compomente_Calle'>
                                <h3 className='lbCalle' ><b><font color="red">*</font></b>Calle</h3>
                            </div>
                            <div>
                                <Form.Item form = {form} name="calle" rules={[{required: true, message: 'Dato requerido'}, { pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Caracteres no permitidos (/:*?”<>).'}]}>
                                    <Input 
                                        name = "calle"
                                        onChange = {(e) => { setCalle(e.target.value)
                                                            props.cambioText(e)
                                                            Calle(e)}}
                                        maxLength={80}
                                        rules={[{
                                            max: 80
                                            , pattern: etiquetaSede.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                        }]}
                                        ></Input>
                                </Form.Item>
                            </div>
                        </div>
                    </Col>           
                    <Col xs={24} sm={4} md={5} lg={4} >
                        <div id='idNumE' style={{display:'none'}}>
                            <div className='compomente_NumE'>
                                <h3 className='lbNumExt' ><b><font color="red">*</font></b>Número exterior</h3>
                            </div>
                            <div>
                                <Form.Item form = {form} name="numExt" rules={[{required: true, message: 'Dato requerido'},{ pattern:  /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Caracteres no permitidos (/:*?”<>).'}]}>
                                    <Input 
                                        name = "numExt"
                                        onChange = {(e) => {setnumeroE(e.target.value)
                                                            props.cambioText(e)
                                                            numeroExterior(e)}}   
                                        maxLength={5}                                    
                                       ></Input >
                                </Form.Item>
                            </div>
                        </div>
                    </Col>           
                    <Col xs={24} sm={4} md={5} lg={4}>
                        <div id='idNumI' style={{display:'none'}}>
                            <div className='compomente_NumI'>
                                <h3 className='lbNumI'>Número interior</h3>
                            </div>
                            <div>
                                <Form.Item form = {form} name="numI" rules={[{ pattern:  /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Caracteres no permitidos (/:*?”<>).'}]}>
                                    <Input 
                                        name = "numI"
                                        onChange = {(e) => { setnumeroI(e.target.value)
                                                            props.cambioText(e)
                                                            numeroInterior(e)}}
                                        maxLength={5}                     
                                        ></Input>
                                </Form.Item>
                            </div>
                        </div>
                    </Col>           
                    <Col xs={24} sm={4} md={5} lg={4}>
                        <div id='idCol' style={{display:'none'}}>
                            <div className='compomente_Col'>
                                <h3 className='lbTColonia' ><b><font color="red">*</font></b>Colonia</h3>
                            </div>
                            <div>
                                <Form.Item form = {form} name="colonia" rules={[{required: true, message: 'Dato requerido'},{ pattern: /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i, message: 'Caracteres no permitidos (/:*?”<>).'}]}>
                                    <Input 
                                        name = "colonia"
                                        onChange = {(e) => { setColonia(e.target.value)
                                                            props.cambioText(e)
                                                            Colonia(e)}}
                                        maxLength={50}
                                        rules={[{
                                            max: 50
                                            , pattern: etiquetaSede.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                            }]}></Input>
                                </Form.Item>
                            </div>
                        </div>
                    </Col>            
                </Row>

                <Row gutter={[16,16]}>
                    <Col xs={24} sm={12} md={12} lg={6}>
                        <div id='idSeccion' style={{display:'none'}}>
                            <div className='compomente_Seccion'>
                                <h3 className='lbSeccion' ><b><font color="red">*</font></b>Municipio/alcaldía</h3>
                            </div>
                            <div>
                                <Form.Item form = {form} name="municipio" rules={[{required: true, message: 'Dato requerido'}]}>   
                                    <Select placeholder="Seleccionar..." defaultValue=""  onChange={(e) =>{ setmunicipio(e)
                                                                                                            props.selectMunicipio(e)
                                                                                                            clearSelected()}} value={municipio}>
                                        {
                                            props.municipios &&
                                            props.municipios.map((municipios, index) => (
                                            <Option key={index} value={municipios.idMunicipio}>
                                                {municipios.nombreMunicipio}
                                            </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                    </Col>           
                    <Col xs={24} sm={12} md={12} lg={6}>
                        <div id='idMuni' style={{display:'none'}}>
                            <div className='compomente_Muni'>
                                <h3 className='lbMunicipio' ><b><font color="red">*</font></b>Sección electoral</h3>
                            </div>
                            <div>
                                <Form.Item form = {form} name="seccion" rules={[{required: true, message: 'Dato requerido'}]}>
                                    <Select placeholder="Seleccionar..." disabled={props.municipioSelect === null ? true: false} defaultValue="" 
                                    onChange={(e) => {setSeccion(e) 
                                                      props.selectSeccion(e)}} value={seccion}>
                                        {
                                            props.secciones &&
                                            props.secciones.map((seccion, index) => (
                                            <Option key={index} value={seccion.seccion}>
                                                {seccion.seccion}
                                            </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                    </Col>           
                    <Col xs={24} sm={12} md={12} lg={6}>
                        <div id='idCod' style={{display:'none'}}>
                            <div className='compomente_Cod'>
                                <h3 className='lbCodigo' ><b><font color="red">*</font></b>Código postal</h3>
                            </div>
                            <div>
                                <Form.Item form = {form} name="codigo" rules={[{required: true, message: 'Dato requerido'}, { pattern: /^\d*[1-9]+\d*$/, message: 'Solo números'}, { pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Caracteres no permitidos (/:*?”<>).'}]}
                                                                        validateStatus={(codigo === undefined) ? null : (fvalCp) ? null : "error"} help={(codigo === undefined) ? null : (fvalCp) ? null : "Formato incorrecto"}>
                                    <Input 
                                        name = "codigo"
                                        onChange = {(e) => { setCodigoPo(e.target.value)
                                                            props.cambioText(e)
                                                            codigoPosta(e)}}
                                        
                                        maxLength={5}
                                        rules={[{
                                            len: 5,
                                            pattern: /^\d{5,5}$/i}]}>
                                    </Input>
                                </Form.Item>
                            </div>
                        </div>
                    </Col>           
                </Row>
                <Row gutter={[16,16]}>
                    <Col xs={24} sm={12} md={12} lg={6}>
                    <div id='idTel' style={{display:'none'}}>
                            <div className='compomente_Tel'>
                                <h3 className='lbTelefono' ><b><font color="red">*</font></b>Teléfono</h3>
                            </div>
                            <div>
                                <Form.Item form = {form} name="telefono" rules={[{required: true, message: 'Dato requerido'},{ pattern: /^\d*[1-9]+\d*$/, message: 'Solo números'}, { pattern: /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i, message: ''}]}
                                                                        validateStatus={(telefono === undefined || telefono === '') ? null : (fvalTel) ? null : "error"} help={(telefono === undefined || telefono === '') ? null : (fvalTel) ? null : "Formato incorrecto"}>
                                    <Input 
                                        name = "telefono"
                                        onChange = {(e) => { settelefono(e.target.value)
                                                            props.cambioText(e)
                                                            telefon(e)}}
                                        maxLength={10}
                                        rules={[{
                                            min: 8
                                            , max: 10
                                            , pattern: etiquetaSede.REGEX_FORMATO_TELEFONO
                                        }]}
                                        
                                        ></Input>
                                </Form.Item>
                            </div>
                        </div>  
                    </Col>           
                    <Col xs={24} sm={12} md={12} lg={6}>
                        <div id='idExt' style={{display:'none'}}>
                            <div className='compomente_Ext'>
                                <h3 className='lbExtencion'>Extensión</h3>
                            </div>
                            <div>
                                <Form.Item form = {form} name="extencion" rules={[{ pattern: /^\d*[0-9]+\d*$/, message: 'Solo números'},{ pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Caracteres no permitidos (/:*?”<>).'}]}
                                                                        validateStatus={(extencion === undefined || extencion === '') ? null : (fvalExt) ? null : "error"} help={(extencion === undefined || extencion === '') ? null : (fvalExt) ? null : "Formato incorrecto"}>
                                    <Input 
                                        name = "extencion"
                                        onChange = {(e) => { setExt(e.target.value)
                                                            props.cambioText(e)
                                                            exten(e)
                                                            }}
                                        maxLength={4}
                                    ></Input>
                                </Form.Item>
                            </div>
                        </div>
                    </Col>           
                    <Col xs={24} sm={12} md={12} lg={12}>
                        <div id='idRef' style={{display:'none'}}>
                            <div className='compomente_Ref'>
                                <h3 className='lbRefencia' ><b><font color="red">*</font></b>Referencia de la sede</h3>
                            </div>
                            <div>
                                <Form.Item form = {form} name="referencia" rules={[{required: true, message: 'Dato requerido'}]} >
                                    <TextArea 
                                        autoSize
                                        name = "referencia"
                                        onChange = {(e) => { setReferencia(e.target.value)
                                                            props.cambioText(e)
                                                            //Referencia(e)
                                                        }}
                                        maxLength={250}
                                        showCount
                                        rules={[{
                                            max: 250
                                            , pattern: etiquetaSede.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                            }]}></TextArea>
                                </Form.Item>
                            </div>
                        </div>
                    </Col>           
                </Row>
                <Row>
                    <Col xs={24} sm={12} md={12} lg={12}>
                        <div id='idObs' style={{display:'none'}}>
                            <div className='compomente_Obs'>
                                <h3 className='lbObser' ><b><font color="red">*</font></b>Observaciones de la sede</h3>
                            </div>
                            <div>
                                <Form.Item form = {form} name="obser" rules={[{required: true, message: 'Dato requerido'}]}  >
                                    <TextArea 
                                        name = "obser"            
                                        onChange = {(e) => { setObser(e.target.value)
                                                            props.cambioText(e)
                                                            //Observ(e)
                                                        }}
                                        showCount
                                        maxLength={250}
                                        rules={[{
                                            max: 250
                                            , pattern: etiquetaSede.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                            }]}></TextArea>
                                </Form.Item>
                            </div>
                        </div>
                    </Col>
                </Row>               
            </Card>
            <Row>

                
                <Col offset={10} sm={10} md={10} lg={10} xl={10}>

                    <Tooltip title={
                        (tipoSede === "V") ? 
                            ((link !== undefined) && (link !== '')
                        && (nombreCapacitador !== undefined) && (nombreCapacitador !== '') && (fvalNombre !== true)
                        && (cargo_Capacitador !== undefined) && (cargo_Capacitador !== '') && (fvalCargo !== true)
                        && (obser !== undefined) && (obser !== '') 
                        &&(props.rolCau === false) ? null: etiquetaSede.etiqueta_boton_guardar):
                        (tipoSede === "P") ? 
                            ((lugar !== undefined) && (lugar !== '') && (fvalLugar !== true)
                        && (nombreCapacitador !== undefined) && (nombreCapacitador !== '') && (fvalNombre !== true)
                        && (cargo_Capacitador !== undefined) && (cargo_Capacitador !== '') && (fvalCargo !== true)
                        && (calle !== undefined) && (calle !== '') && (fvalCalle !== true)
                        && (numExt !== undefined) && (numExt !== '') && (fvalNum ===true)  && (fvalNumInt === true)
                        && (colonia !== undefined) && (colonia !== '') && (fvalColonia !== true)
                        && (municipio !== undefined)
                        && (seccion !== undefined)
                        && (codigo !== undefined) && (codigo !== '') && (fvalCp === true) 
                        && (telefono !== undefined) && (telefono !== '') && (fvalTel === true) 
                        && (fvalExt === true)  
                        && (referencia !== undefined) && (referencia !== '')
                        && (props.rolCau === false) ? null: etiquetaSede.etiqueta_boton_guardar): 

                        ((lugar !== undefined) && (lugar !== '') 
                        && (nombreCapacitador !== undefined) && (nombreCapacitador !== '')
                        && (cargo_Capacitador !== undefined) && (cargo_Capacitador !== '') 
                        && (calle !== undefined) && (calle !== '')
                        && (numExt !== undefined) && (numExt !== '')    
                        && (colonia !== undefined) && (colonia !== '')
                        && (municipio !== undefined)
                        && (seccion !== undefined)
                        && (codigo !== undefined) && (codigo !== '') && (fvalCp === true) 
                        && (fvalExt === true)
                        && (telefono !== undefined) && (telefono !== '') && (fvalTel === true)           
                        && (referencia !== undefined) && (referencia !== '')
                        && (props.rolCau === false) ? null: etiquetaSede.etiqueta_boton_guardar)}>                    

                        <Button type="primary" htmlType="submit" 
                        // style={{background: "#F4F3F4", borderColor: "#ffffff"}}    
                        disabled={
                        (tipoSede === "V") ? 
                            ((link !== undefined) && (link !== '')
                        && (nombreCapacitador !== undefined) && (nombreCapacitador !== '') && (fvalNombre !== true)
                        && (cargo_Capacitador !== undefined) && (cargo_Capacitador !== '') && (fvalCargo !== true)
                        && (obser !== undefined) && (obser !== '') 
                        &&(props.rolCau === false) ? false : true):
                        (tipoSede === "P") ? 
                            ((lugar !== undefined) && (lugar !== '') && (fvalLugar !== true)
                        && (nombreCapacitador !== undefined) && (nombreCapacitador !== '') && (fvalNombre !== true)
                        && (cargo_Capacitador !== undefined) && (cargo_Capacitador !== '') && (fvalCargo !== true)
                        && (calle !== undefined) && (calle !== '') && (fvalCalle !== true)
                        && (numExt !== undefined) && (numExt !== '') && (fvalNum ===true)  && (fvalNumInt === true)
                        && (colonia !== undefined) && (colonia !== '') && (fvalColonia !== true)
                        && (municipio !== undefined)
                        && (seccion !== undefined)
                        && (codigo !== undefined) && (codigo !== '') && (fvalCp === true) 
                        && (telefono !== undefined) && (telefono !== '') && (fvalTel === true) 
                        && (fvalExt === true)  
                        && (referencia !== undefined) && (referencia !== '')
                        && (props.rolCau === false) ? false : true): 

                        ((lugar !== undefined) && (lugar !== '') 
                        && (nombreCapacitador !== undefined) && (nombreCapacitador !== '') 
                        && (cargo_Capacitador !== undefined) && (cargo_Capacitador !== '')
                        && (calle !== undefined) && (calle !== '')
                        && (numExt !== undefined) && (numExt !== '')    
                        && (colonia !== undefined) && (colonia !== '')
                        && (municipio !== undefined)
                        && (seccion !== undefined)
                        && (codigo !== undefined) && (codigo !== '') && (fvalCp === true) 
                        && (fvalExt === true)
                        && (telefono !== undefined) && (telefono !== '') && (fvalTel === true)           
                        && (referencia !== undefined) && (referencia !== '')
                        && (props.rolCau === false) ? false : true)}                 
                        onClick={props.dataSedes}>Guardar</Button>

                    </Tooltip>  
                </Col>

            </Row>                
        </Form>

      </TabPane>

    </Tabs>
    <Modal
        className="modalExito"
        visible={props.abrirModalExitoVirtual}
        title={<img src={sedesRegistradaVirt} id="img-exito-modal"/>}
        onCancel={props.onChangeRouteModulo}
        centered
        width={327}
        footer={[null]}>{
                <>
                    <div>
                        <span className="titulo-exito">Sede registrada</span>
                    </div>
                    <div>
                        <span className="notificacion-exito">La información se guarda con éxito</span>
                    </div>
                    <br/>
                    <Row>
                        <Col offset={4} span={6}>
                            <Button type="primary" htmlType="submit" style={{ background: "#D4217F", borderColor: "#ffffff" }} onClick={props.onChangeRoute}>Registrar nueva sede</Button>
                        </Col>
                    </Row>
                    </>
        }
    </Modal>                                     

    <Modal
        className="modalExito"
        visible={props.abrirModalExitoRegistro}
        title={<img src={sedesRegistradaImg} id="img-exito-modal"/>}
        onCancel={props.onChangeRouteModulo}
        centered
        width={327}
        footer={[null]}>{
                <>
                    <div>
                        <span className="titulo-exito">Sede registrada</span>
                    </div>
                    <div>
                        <span className="notificacion-exito">La información se guarda con éxito</span>
                    </div>
                    <br/>
                    <Row>
                        <Col offset={4} span={6}>
                            <Button type="primary" htmlType="submit" style={{ background: "#D4217F", borderColor: "#ffffff" }} onClick={props.onChangeRoute}>Registrar nueva sede</Button>
                        </Col>
                    </Row>
                    </>
        }
    </Modal>         

    </Layout>
  );
}

export default VCapturaSedes;