import CLogin from '../../containers/login/CLogin.js';
import CHome from '../../containers/homeExterno/CHome';
import CHomeInt from '../../containers/homeInterno/CHomeInt';
import CLogout from '../../containers/login/CLogout';
import CModulo from '../../containers/comunes/CModulo';
import CAsignacionVoluntarios from '../../containers/casillas/asignacion/CAsignacionVoluntarios';
import CGafete from '../../containers/gafete/CGafete';
import CZoresVoluntarios from '../../containers/casillas/asignacion/CZoresVoluntarios';
import CPublicador from '../../containers/publicador/CPublicador';
import CConsultaCasilla from '../../containers/casillas/consulta/CConsultaCasilla';
import CSeguimientoCasilla from '../../containers/casillas/consulta/CSeguimientoCasilla';
import CInstalacionCasilla from '../../containers/casillas/consulta/CInstalacionCasilla';
import CVoluntarios from '../../containers/voluntarios/CVoluntarios';
import CRegistroCasillas from '../../containers/casillas/consulta/CRegistroCasillas';
import CCapturaSede from '../../containers/sedes/CCapturaSede.js';
import CConsultaSedes from '../../containers/sedes/CConsultaSedes.js';

import CConvocatorias from '../../containers/convocatorias/CConvocatorias.js';
import CSeguimientoVoluntarios from '../../containers/voluntarios/seguimiento/CSeguimientoVoluntarios';
import CConvocatoriasConsulta from '../../containers/convocatorias/CConvocatoriasConsulta.js';
import CConsultaVoluntarios from '../../containers/voluntarios/CConsultaVoluntarios';
import CAsistenciaCapacitacion from '../../containers/asistencias/consulta/CAsistenciaCapacitacion.js';
import CResultados from '../../containers/Resultados/CResultados.js';
import CBitacoraJson from '../../containers/bitacoraJson/CBitacoraJson';


export default{
  CLogin,
  CHome,
  CHomeInt,
  CLogout,
  CModulo,
  CAsignacionVoluntarios,
  CGafete,
  CZoresVoluntarios,
  CPublicador,
  CConsultaCasilla,
  CSeguimientoCasilla, 
  CInstalacionCasilla,
  CVoluntarios,
  CRegistroCasillas,
  CCapturaSede,
  CConsultaSedes,
  CConvocatorias,
  CSeguimientoVoluntarios,
  CConvocatoriasConsulta,
  CConsultaVoluntarios,
  CAsistenciaCapacitacion,
  CResultados,
  CBitacoraJson
}
