import React from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import CTemplate from '../../containers/homeInterno/CTemplate';
import AuthenticatedComponent from "../../components/comunes/AuthenticatedComponent";
import * as etiquetaCasilla from '../../constants/EtiquetasCasillas';
import * as etiquetas from '../../constants/EtiquetasGenerales';
import * as info from '../../constants/InfoSistema';
import VCapturaSedes from '../../components/sedes/VCapturaSedes';
import { createStructuredSelector } from 'reselect';
import { selectEstado, selectDistrito} from '../../redux/menu/menu-selectors';
import { selectCurrentUser} from '../../redux/user/user-selectors';
import { obtenerEstatusModulo } from '../../redux/menu/menu-utils';


let msjError = "";
const jwt = localStorage.getItem('jwt-token');
const sedes = [
  {
    idSede: "P",
    nombreTipo: "Presencial"
  },
  {
    idSede: "V",
    nombreTipo: "Virtual"
  },
];

class CCapturaSede extends React.Component{
  constructor(props) {
    super(props);

    this.state = { 
        abrirModalExitoRegistro: false,
        abrirModalExitoVirtual: false, 
        cargando: false,
        estatusModulo: false,        
        lstMunicipios: [],
        secciones: [],
        infoSecciones: null,
        selectMunicipio: null,
        selectSeccion: null,
        infoSede: null,
        lugar: null,
        link:"",
        nombreCapacitador: "",
        calle:"",
        numExt:"",
        numI:"",
        colonia:"",
        seccion:"",
        municipio:"",
        codigo:"",
        extencion:"",
        telefono:"",
        referencia:"",
        obser:"",
        infoSede:sedes,
        tipSede:"",
        selectSede: null,
        usuarioCau: false,
        cargo_Capacitador: null
    };
  }


  componentDidMount(){

    this.consultarEstatusModulo();
    //this.getMunicipios();

  }

  consultarEstatusModulo = async () => {

    var idDetalleProceso = this.props.user.idDetalle;
    var idProcesoElectoral = this.props.user.idProcesoElectoral;
    var idEstado = this.props.estado.idEstado;
    var idDistrito = this.props.distrito.idDistrito;
    var idSistema = info.ID_SISTEMA;
    var idModulo = this.props.location.state !== undefined ?
                   this.props.location.state.idModulo : 0;
    var grupoSistema = this.props.user.rolUsuario;

    var index = grupoSistema.search("CAU.OC");

    if(index >= 0){
      this.setState({
        usuarioCau:true
      }, ()=>console.log(true))
    }else{
      console.log("tiene otro tipo de rol")
    }
  
    try{
  
      const estatus = (await obtenerEstatusModulo(idProcesoElectoral, idDetalleProceso, idSistema, idEstado, idDistrito, idModulo, grupoSistema));
  
    
      if(estatus === "A"){
        console.info("Modulo registra casillas esta abierto");
        this.setState({
          estatusModulo: true
        });
        this.getMunicipios();

      }
    }catch(error){
        console.info("Modulo registra casillas esta cerrado",error);
    }
  
  };

  selectMuncipios = (valor) => {
    if(valor != null){

      this.state.selectMunicipio = valor;
      this.getSecciones();

    }else{
      this.state.selectMunicipio = null;
    }
    
  };

  selectSeccion = (valor) => {
      console.log("Entro para asignar seccion");
    if(valor != null){
      this.state.selectSeccion = valor;

    }else{
      this.state.selectSeccion = null;
    }
  };  
  selectSedes = (valor) => {
      console.log("Entro para asignar seccion");
    if(valor != null){
      this.state.selectSede = valor;

    }else{
      this.state.selectSede = null;
    }
    
};

  getMunicipios = () => {

    var postData = {
        idEstado: this.props.estado.idEstado,
        idDistrito: this.props.distrito.idDistrito,
    }
    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + 'ws/municipios'
        , postData,
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.user ? this.props.user.tknJWT : null
            }
        }
    )
    .then(res => {
        const municipios = res.data.infoMunicipios;
        if(res.data.code === 200){
        this.setState({lstMunicipios: municipios,
                       mensajeAlerta : null,
                       cargando : false,
                       mostrarAlerta : {display: 'none'},
                    });}
  
    }).catch(error => {
        console.error("Error al obtener los municipios de Casillas",error);
    });
};

getSecciones = () => {
    var JsonSecciones = {
        idEstado:parseInt(this.props.estado.idEstado),
        idDistrito: parseInt(this.props.distrito.idDistrito),
        idMunicipio: this.state.selectMunicipio
    };
    
    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + 
      'ws/secciones', JsonSecciones,
      {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': this.props.user ? this.props.user.tknJWT : null
        }
      }
   ).then(res => {  
    this.setState({
      secciones : res.data.infoSecciones,
                   mensajeAlerta : null,
                   cargando : false,
                   mostrarAlerta : {display: 'none'},
                });

    }).catch(error => {
    console.error("Error al obtener las secciones de Casillas",error);
    });

};

capturaSedes = () => {
  
  let datosJson = {};

  if(this.state.selectSede == 'P'){
    this.state.tipSede = this.state.lugar;

  }else if(this.state.selectSede == 'V'){
    this.state.tipSede = this.state.link;
  }
  
    datosJson = {
        idDetalleProceso: info.ID_DETALLE_PROCESO,
        idProcesoElectoral: info.ID_PROCESO_ELECTORAL,
        idEstado: this.props.estado.idEstado,
        usuario: this.props.user.username,
        idDistrito: this.props.distrito.idDistrito,
        idMunicipioDom: this.state.selectMunicipio,
        seccion: this.state.selectSeccion,
        lugar: this.state.tipSede,
        domicilioSede: this.state.calle,
        numExterior: this.state.numExt,
        numInterior: this.state.numI,
        colonia: this.state.colonia,
        codigoPostal: this.state.codigo,
        extension: this.state.extencion,
        telefonoSede: this.state.telefono,
        observaciones: this.state.obser,
        referencia: this.state.referencia,
        tipoSede: this.state.selectSede ,
        nombreCapacitador: this.state.nombreCapacitador,
        cargo_Capacitador: this.state.cargo_Capacitador
  } 
  console.log("Entro para crear sedea" , datosJson);

  axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL +"ws/capturarSedes", 
  datosJson,
          {
           headers : {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': this.props.user ? this.props.user.tknJWT : null
          }
      }
  ).then(_response => {
    if(this.state.selectSede === "P"){
      this.setState({abrirModalExitoRegistro: true})
    }else if(this.state.selectSede === "V"){
      this.setState({abrirModalExitoVirtual: true})
    }
      
  }).catch(error => {
      this.setState({cargando: false});
      console.error("Error al guardar la casilla "+
          "capturaSedes()",error);
      if (error.response){
          if (error.response.status === 409){
              msjError = error.response.data.message;
          } else if (error.response.data && error.response.data.causa
              && error.response.data.causa.includes("Error de validación")){
              msjError = error.response.data.message;
          } else {
              msjError = etiquetas.gral_error_cau;
          }
      } else {
          msjError = etiquetas.gral_error_cau;
      }
      this.setState({
        msjError : msjError,
        abrirModalError : true
      });
  });
}

  
 cambioText = (e) =>{
    this.setState({
        [e.target.name]: e.target.value
    })
}  

onChangeRouteModulo =()=>{
  console.log("Entre a cambio de ruta")
  this.setState({abrirModalExitoRegistro: false});
  this.props.history.push('/modulo/3');
}

onChangeRoute =()=>{
  console.log("Entre a cambio de ruta")
  this.setState({abrirModalExitoRegistro: false});
  window.location.reload();

}
  render(){
    return(
      <>
        <AuthenticatedComponent> 
          <CTemplate
            bloqueado = {false}
            contenido = {
                <VCapturaSedes 
                    abrirModalExitoRegistro = {this.state.abrirModalExitoRegistro}
                    abrirModalExitoVirtual = {this.state.abrirModalExitoVirtual}
                    municipios = {this.state.lstMunicipios}
                    selectMunicipio = {this.selectMuncipios}
                    secciones = {this.state.secciones}
                    selectSeccion = {this.selectSeccion}
                    infoSecciones={this.state.infoSecciones} 
                    municipioSelect = {this.state.selectMunicipio}
                    seccionSelect = {this.state.selectSeccion} 
                    cambioText = {this.cambioText}
                    dataSedes ={this.capturaSedes}
                    infoSede={this.state.infoSede}
                    selectSede={this.selectSedes}
                    onChangeRoute = {this.onChangeRoute}
                    onChangeRouteModulo={this.onChangeRouteModulo}
                    rolCau = {this.state.usuarioCau}
                />
            }
              guia={false}/>
            </AuthenticatedComponent> 
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
    estado: selectEstado,
    distrito: selectDistrito,
    user: selectCurrentUser
});

export default connect(mapStateToProps)(CCapturaSede);