import React from 'react';
import {Button, Row, Col, Input, Layout, Tooltip, Modal, Spin, Image} from 'antd';
import { cargandoOutlined, PlusOutlined,ArrowLeftOutlined } from '@ant-design/icons';
import VOpcionesDoc from '../../documentacion/VOpcionesDoc';
import VCargarArchivo from '../../documentacion/VCargarArchivo';
import iconoAyuda from '../../../../assets/img/icono_ayuda_fusia.png';
import * as etiquetaDocu from  '../../../../constants/EtiquetasDocumentacion';
import '../../../../css/voluntarios/docuConsulta.css';


const VDocumentosConsulta = (props) =>{


  return (
    <>
    <Row gutter={16}>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <div className="divSubidaConsulta">
          <div className="tituloConsulta">
            <label className="etiquetaCampoConsulta">
              Credencial para votar (frente)
            </label>
          </div>
          <div className="cuerpoConsulta">
            <Image
              width={150}
              height={100}
              src={props.cargarIneFrente}
            />
          </div>
        </div>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <div className="divSubidaConsulta">
          <div className="tituloConsulta">
            <label className="etiquetaCampoConsulta">
              Credencial para votar (reverso)
            </label>
          </div>
          <div className="cuerpoConsulta">
            <Image
              width={150}
              height={100}
              src={props.cargarIneReverso}
            />
           </div>
        </div>
      </Col>
    </Row>
    <Row>
    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
      <div className="divSubidaConsulta">
        <div className="tituloConsulta">
          <label className="etiquetaCampoConsulta">
            Comprobante de domicilio
          </label>
        </div>
        <div className="cuerpoConsulta">
          <Image
            width={150}
            height={100}
            src={props.cargarComprobante}
          />
         </div>
     </div>
    </Col>
    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
      <div className="divSubidaConsulta">
        <div className="tituloConsulta">
          <label className="etiquetaCampoConsulta">
            Foto
          </label>
        </div>
        <div className="cuerpoConsulta">
          <Image
            width={150}
            height={100}
            src={props.cargarFoto}
          />
         </div>
     </div>
    </Col>
    </Row>
    <Row gutter={16}>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Button type="primary" className="botonIzq"
            onClick={()=>props.navegar("2")}
            shape="circle"
            icon={<ArrowLeftOutlined id="flechaDer"/>}/>
      </Col>
    </Row>
    </>
  );
}

export default VDocumentosConsulta;
