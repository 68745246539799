import React from 'react';
import { ConfigProvider, Layout, Row, Col } from 'antd';
import esES from 'antd/es/locale/es_ES';
import BlockLoader from '../../components/comunes/Loader.js';
import VHeader from '../../components/comunes/VHeader';
import VMenu from '../../components/comunes/VMenu';
import '../../css/homeInterno/options_menu.css';

const { Content } = Layout;

const  CTemplate = ({ bloqueado, bloquearTodo, cargando, 
					isHome, contenido, contenidoExpandido }) => {

	const bloquear = bloqueado || cargando;

	return (
		<ConfigProvider locale={esES}>
			<BlockLoader blocking={bloquear && bloquearTodo}
				styleClass={'bloq_sobre_todo'}>
				<Layout className={`layout template ${isHome?'home':''}`}
						style={{'overflowX': 'hidden'}}>
					<Row>
						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<VHeader haySesion />
						</Col>
						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<VMenu />
						</Col>
					</Row>
					<Content className={`${contenidoExpandido? 'contenido_expandido' : ''}
									${isHome?'home':''}`}>
						<BlockLoader blocking={bloquear && !bloquearTodo}>
							{bloqueado ? 
							<div className='template_cargando'>Cargando...</div> 
							: contenido}
						</BlockLoader>
					</Content>
				</Layout>
			</BlockLoader>
		</ConfigProvider>
	);
}

CTemplate.defaultProps = {
	guia: true,
	bloqueado: false,
	cargando: false,
	contenidoExpandido: false,
	bloquearTodo: true,
}

export default CTemplate;
