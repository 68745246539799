import React, {Component} from 'react'
import {Input as _Input, Tooltip} from 'antd'

import {configuraComponente, configuraCompInterno} from '../../utils/funciones';

class InputText extends Component {
	constructor (props) {
		super(props);
		configuraComponente.bind(this)();
	}

	render () {
		const inputType = this.props.inputType || "text";
		var tooltip;

		if (this.props.customTooltip)
			tooltip = this.props.customTooltip;
		else {
			tooltip = (this.props.title ?
						<Tooltip title={this.props.title}>
						</Tooltip> : undefined);
		}

		const input = (<Input className="transicion_input"
							  prefix={this.props.type ?
								"": ""}
							  placeholder={this.props.realPlaceholder}
							  disabled={this.props.disabled}
							  type={inputType}
							  suffix={tooltip}
							  onPaste={this.props.onPaste}
							  _onChange={this.onChange}
							  allowClear={this.props.allowClear}
							  custom={this.props.custom}
							  onBlur={this.onBlurAndChange}/>);

		return this.funcComunComponent(input, 'text-input-container');
	}
}

class Input extends Component {
	constructor(props) {
		super(props);
		configuraCompInterno.bind(this)();
	}

	render() {
		return <_Input {...this.props}
					value={this.state.valor}
					onChange={this.onChange}/>
	}
}

export default InputText;
