import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Tabs } from 'antd';
import AuthenticatedComponent from "../../components/comunes/AuthenticatedComponent";
import CTemplate from '../homeInterno/CTemplate';
import BlockLoader from '../../components/comunes/Loader';
import VReporte from '../../components/publicador/VReporte';
import { selectEstado, selectDistrito } from '../../redux/menu/menu-selectors';
import { selectCurrentUser } from '../../redux/user/user-selectors';
import { obtieneCatalogosReporte, obtieneDatosTabla, consultaRestringida } from '../../utils/publicador.js';
import * as infoSistema from '../../constants/InfoSistema';
import * as etiquetas from '../../constants/EtiquetasPublicador';
import '../../css/publicador/publicador.scss';

const { TabPane } = Tabs;

const INITIAL_STATE_TABLE = {
    cargando: false,
    vistasTemporales: [],
    datosTabla: undefined,
    datosFiltrados: [],
    isOpenCotas: false,
    error: ''
}

const INITIAL_STATE = {
    catalogosListados: undefined,
    catalogosCedulas: undefined,
    tipoReporte: '',
    corte: '',
    listaReportes: [],
    reporteSeleccionado: undefined,
    nivelSeleccionado: undefined,
    ...INITIAL_STATE_TABLE
}

const proceso = {
    idProcesoElectoral: infoSistema.ID_PROCESO_ELECTORAL,
    descripcionDetalle: '', 
    idDetalleProceso: infoSistema.ID_DETALLE_PROCESO 
};

class CPublicador extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            ...INITIAL_STATE
        }
    }

    _isMounted = false;
    async componentDidMount(){
        this._isMounted = true;
        const { idProcesoElectoral } = proceso;
        const catalogosListados = await obtieneCatalogosReporte(idProcesoElectoral, etiquetas.FOLDER_LISTADOS);
        const catalogosCedulas = await obtieneCatalogosReporte(idProcesoElectoral, etiquetas.FOLDER_CEDULAS);
        this.setState({
            catalogosListados,
            catalogosCedulas
        }, () => this.seleccionaTipoReporte(etiquetas.FOLDER_LISTADOS));
    }

    componentDidUpdate(prevProps){
        if (this.props.estado !== prevProps.estado
            || this.props.distrito !== prevProps.distrito) {
            this.setState({
                ...INITIAL_STATE_TABLE,
                cargando: true
            }, this.obtieneDatosTabla);
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    seleccionaTipoReporte = (activeKey) => {
        const { catalogosListados, catalogosCedulas } = this.state;
        const catalogos = activeKey === etiquetas.FOLDER_LISTADOS ? catalogosListados : catalogosCedulas;
        this.setState({
            ...INITIAL_STATE,
            catalogosListados,
            catalogosCedulas,
            tipoReporte: activeKey,
            ...catalogos
        });
    }

    seleccionaReporte = (reporteSeleccionado) => {
        this.setState({
            reporteSeleccionado,
            nivelSeleccionado: undefined,
            ...INITIAL_STATE_TABLE,
            cargando: true
        }, this.obtieneDatosTabla);
    }

    seleccionaNivelReporte = (nivelSeleccionado) => {
        this.setState({
            nivelSeleccionado,
            ...INITIAL_STATE_TABLE,
            cargando: true
        }, this.obtieneDatosTabla);
    }

    obtieneDatosTabla = async() => {
        if(!this._isMounted) return;

        const { tipoReporte, corte, reporteSeleccionado, nivelSeleccionado, vistasTemporales } = this.state;
        const { estado: {idEstado}, 
                distrito: {idDistrito}, 
                user: {rolUsuario} } = this.props;

        if(!proceso){
            this.setState({
                cargando: false,
                error: etiquetas.SELECCIONA_PROCESO
            });
            return;
        }
        
        if(!reporteSeleccionado
            || (!nivelSeleccionado 
                && tipoReporte === etiquetas.FOLDER_CEDULAS)){
            this.setState({
                cargando: false
            });
            return;
        } 

        let nivelActual = nivelSeleccionado;
        let idDetalleActual = proceso.idDetalleProceso;
        let idEstadoActual = idEstado;
        let idDistritoActual = idDistrito;
        let idMunicipioActual = 0;
        if(vistasTemporales.length > 0){
            const vistaActual = vistasTemporales[vistasTemporales.length-1];
            nivelActual = vistaActual.nivel;
            idDetalleActual = vistaActual.proceso.idDetalleProceso;
            idEstadoActual = vistaActual.estado.idEstado;
            idDistritoActual = vistaActual.distrito.idDistrito;
            idMunicipioActual = vistaActual.municipio.idMunicipio;
        }

        const datosTabla = await obtieneDatosTabla(proceso.idProcesoElectoral,
                                            idDetalleActual, 
                                            tipoReporte,
                                            corte,
                                            reporteSeleccionado,
                                            nivelActual,
                                            idEstadoActual,
                                            idDistritoActual,
                                            idMunicipioActual,
                                            consultaRestringida(rolUsuario));
        if(datosTabla.msg){
            this.setState({
                cargando: false,
                error: datosTabla.msg
            });
        }else{
            this.setState({
                cargando: false,
                datosTabla: datosTabla.data
            });
        }
    }

    handleChangeTabla = (pagination, filters, sorter, extra) => {
        this.setState({
            datosFiltrados: extra.currentDataSource
        });
    };

    handleChangeCotas = () => {
        this.setState(prevState =>{
            return{
                 ...prevState,
                 isOpenCotas: !prevState.isOpenCotas
            }
         });
    }

    handleLinkToNivel = (nivelActual, procesoActual, estadoActual, distritoActual, municipio) => {
        const { vistasTemporales } = this.state;
        const { estado, distrito } = this.props;
        
        const vistasTemporalesNew = vistasTemporales.slice();

        vistasTemporalesNew.push({
            nivel: nivelActual,
            proceso: procesoActual ? procesoActual : proceso,
            estado: estadoActual ? estadoActual : estado,
            distrito: distritoActual ? distritoActual : distrito,
            municipio
        });

        this.setState({
            ...INITIAL_STATE_TABLE,
            vistasTemporales: vistasTemporalesNew,
            cargando: true
        }, this.obtieneDatosTabla);
    }

    handleReturn = () => {
        const { vistasTemporales } = this.state;
        const vistasTemporalesNew = vistasTemporales.slice();

        vistasTemporalesNew.pop();

        this.setState({
            ...INITIAL_STATE_TABLE,
            vistasTemporales: vistasTemporalesNew,
            cargando: true
        }, this.obtieneDatosTabla);
    }

    render(){
        const { estado, distrito } = this.props;
        const { tipoReporte, corte, listaReportes, reporteSeleccionado, 
                nivelSeleccionado, vistasTemporales, datosTabla, datosFiltrados,
                error, isOpenCotas, cargando } = this.state;
        const reporte = reporteSeleccionado ?
                        listaReportes[reporteSeleccionado]
                        : '';
        let isVistaTemporal = false;
        let nivelActual = nivelSeleccionado;
        let procesoActual = proceso;
        let estadoActual = estado;
        let distritoActual = distrito;
        let municipioActual = {idMunicipio: 0, nombreMunicipio: ''};
        if(vistasTemporales.length > 0){
            const vistaActual = vistasTemporales[vistasTemporales.length-1];
            isVistaTemporal = true;
            nivelActual = vistaActual.nivel;
            procesoActual = vistaActual.proceso;
            estadoActual = vistaActual.estado;
            distritoActual = vistaActual.distrito;
            municipioActual = vistaActual.municipio;
        }
        const nivel = reporteSeleccionado 
                    && nivelActual 
                    && listaReportes[reporteSeleccionado].niveles[nivelActual]?
                    listaReportes[reporteSeleccionado].niveles[nivelActual]
                    : { label : '', key : nivelActual};

        const vReporte = <VReporte tipoReporte = {tipoReporte}
                                    reporte = {reporte}
                                    nivelSeleccionado = {nivelSeleccionado}
                                    nivel = {nivel}
                                    proceso = {procesoActual}
                                    estado = {estadoActual}
                                    distrito = {distritoActual}
                                    municipio = {municipioActual}
                                    corte = {corte}
                                    listaReportes = {listaReportes}
                                    error = {error}
                                    datosTabla = {datosTabla}
                                    datosFiltrados = {datosFiltrados}
                                    handleChangeTabla = {this.handleChangeTabla}
                                    seleccionaReporte = {this.seleccionaReporte} 
                                    seleccionaNivelReporte = {this.seleccionaNivelReporte}
                                    isOpenCotas = {isOpenCotas}
                                    handleChangeCotas  = {this.handleChangeCotas}
                                    isVistaTemporal = {isVistaTemporal}
                                    handleLinkToNivel = {this.handleLinkToNivel}
                                    handleReturn = {this.handleReturn} />;

        return(
            <AuthenticatedComponent >
                <BlockLoader blocking={cargando}
						    styleClass={'bloq_sobre_todo'}>
                <CTemplate isHome
                        contenido={
                            <div className='publicador-container'>
                                <Tabs
                                    type="card" 
                                    size="small"
                                    onChange={this.seleccionaTipoReporte}>
                                    <TabPane tab={etiquetas.LABEL_LISTADOS} 
                                            key={etiquetas.FOLDER_LISTADOS}>
                                        {vReporte}
                                    </TabPane>
                                    <TabPane tab={etiquetas.LABEL_CEDULAS} 
                                            key={etiquetas.FOLDER_CEDULAS}>
                                       {vReporte}
                                    </TabPane>
                                </Tabs>
                            </div>} 
                        guia={false}/>
                </BlockLoader>
            </AuthenticatedComponent>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    estado: selectEstado,
    distrito: selectDistrito,
    user: selectCurrentUser
});

export default connect(mapStateToProps)(CPublicador);