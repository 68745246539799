export const URL_DATOS_CASILLAS                         = 'ws/datosGraficaCasillas';
export const URL_DATOS_VOLUNTARIOS                      = 'ws/datosGraficaVoluntarios'; 
export const URL_DATOS_PARTICIPACION                    = 'ws/datosGraficaParticipantes';

export const home_card_casillas_title                   = "Registro de casillas";
export const home_chart_casillas_lbl_registradas        = "Registradas";
export const home_chart_casillas_lbl_asignadas          = "Asignadas";

export const home_card_participantes_edad_title         = "Participación total";
export const home_card_participantes_edad_subtitle      = "Por rango de edad";
export const home_chart_participantes_tooltip_niños     = " niños";
export const home_chart_participantes_edad_3_a_5        = "3 a 5 años";
export const home_chart_participantes_edad_6_a_9        = "6 a 9 años";
export const home_chart_participantes_edad_10_a_13      = "10 a 13 años";
export const home_chart_participantes_edad_14_a_17      = "14 a 17 años";

export const home_card_voluntarios_title                = "Avance en el registro de personas voluntarias";
export const home_card_voluntarios_total                = "Total de personas voluntarias registradas: ";
export const home_chart_voluntarios_por_tipo_title      = "Registro";
export const home_chart_voluntarios_por_estatus_title   = "Asignación";
export const home_chart_voluntarios_tooltip             = " voluntarios";

export const home_card_participantes_sexo_title         = "Participación por sexo";
export const home_card_participantes_sexo_mujer         = "Niña/mujer";
export const home_card_participantes_sexo_hombre        = "Niño/hombre";
export const home_card_participantes_sexo_indefinido    = "No me identifico con ninguno de los dos";

export const home_card_participantes_resultados         = "Resultados de la";


