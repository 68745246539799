import React from 'react';
import {Button, Select, Row, Col, Input, Layout, Table,
        Card, Modal, Pagination, Tooltip} from 'antd';
import QRCode from 'qrcode.react';
import * as etiquetasGafete from '../../constants/EtiquetasGafete';
import { Alert } from 'antd';
import {FrownOutlined} from '@ant-design/icons';
import '../../css/gafetes/gafetes.css';
import voluntario from '../../assets/img/voluntario.png';
import iconoMostrarGafete from '../../assets/img/icono_mostrar_gafete.png';
import iconoAyuda from '../../assets/img/icono_ayuda.png';
import iconoQuitarVol from '../../assets/img/icono_quitar_vol.png';
import BlockLoader from '../comunes/Loader.js';

const { Search } = Input;
const icon = <FrownOutlined />;

const VGafete = (props) =>{
  const columnas = [

    {
      title: 'Folio',
      dataIndex: 'folio'
    },
    {
      title: 'Persona voluntaria asignada',
      dataIndex: 'nombreCompleto'
    },
    {
      title: 'Tipo de persona voluntaria',
      dataIndex: 'tipovoluntario',
      /*filters: [
        { text: 'Interno', value: 'Interno' },
        { text: 'Externo', value: 'Externo' },
      ],*/
      responsive: ['md'],
      onFilter: (value, record) => record.tipovoluntario.indexOf(value) === 0,
    },
    {
      title: 'Acciones',
      key: 'action',
      render: (text, record) => (
        <>
          <Tooltip title={etiquetasGafete.etiqueta_tooltip_consultar}>
            <a onClick={() =>{props.mostrarModal(record)}}>
               <img src={iconoMostrarGafete} id="eye-icon"/>
            </a>
          </Tooltip>
        </>
      ),
    }
  ];
  const columnasTablaDos = [
    {
      title: 'Folio',
      dataIndex: 'folio'
    },
    {
      title: 'Persona voluntaria asignada',
      dataIndex: 'nombreCompleto',
    },
    {
      title: 'Acciones',
      key: 'action',
      render: (text, record) => (
        <>
          <Tooltip title={etiquetasGafete.etiqueta_tooltip_quitar}>
          <a onClick={() =>{props.eliminarElemento(record.key)}}>
            <img src={iconoQuitarVol} id="printer-icon"/>
          </a>
          </Tooltip>
        </>
      ),
    }
  ];

  const rowSelection = {
      onChange: props.seleccionarVoluntario,
  };
  const acomodarNombre = (nombreVoluntario) => {
    var nombreArray = nombreVoluntario.toLowerCase().split(" ");
    var nombreBien = "";

    for (var i = 0; i < nombreArray.length; i++) {
      nombreBien += nombreArray[i].charAt(0).toUpperCase() +
                      nombreArray[i].slice(1)+"\n";
    }
    return nombreBien;
  }
  const acomodarApellidos = (nombreVoluntario, nombreApellidos) => {
    var totalNombres = nombreVoluntario.split(" ").length;
    var nombreDividido = nombreApellidos.toLowerCase().split(" ");
    var nombreBien = "";
    var apellidoM ="";
    var apellidoP ="";
		var diferencia = nombreDividido.length - totalNombres;

		if(diferencia == 1) {
			apellidoP = nombreDividido[nombreDividido.length-1].charAt(0).toUpperCase()
                              +nombreDividido[nombreDividido.length-1].slice(1);
		}else if(diferencia == 2) {
			apellidoP = nombreDividido[nombreDividido.length-2].charAt(0).toUpperCase()
                              +nombreDividido[nombreDividido.length-2].slice(1);
			apellidoM = nombreDividido[nombreDividido.length-1].charAt(0).toUpperCase()
                              +nombreDividido[nombreDividido.length-1].slice(1);
		}
		return apellidoP + " "+ apellidoM;
  }
  return(
    <BlockLoader blocking={props.cargandoLoader}
                 styleClass={'bloq_sobre_todo'}>
      <Layout className='validar_modulo gafetes'>
        <div id="seccion1">
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <h1 id="titulo-componente">{etiquetasGafete.titulo_componente}</h1>
            </Col>
          </Row>
          <Row id="buscador-div" gutter={16}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <span>{etiquetasGafete.etiqueta_campo_busqueda}</span>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} id="buscador">
              <Search
                  placeholder="Folio"
                  enterButton
                  size="middle"
                  onChange={props.obtenerVoluntario}
                  onSearch={props.obtenerVoluntario}
                  loading = {props.cargaBusquedaVolun}
                />
            </Col>
          </Row>
        </div>
        <div id="tabla-voluntarios">
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div id="resultado-voluntarios">
                <div className="titulotablasVol">
                  {etiquetasGafete.titulo_tabla_voluntarios}
                  <Tooltip title={etiquetasGafete.etiqueta_tooltip_resultado}>
                    <img src={iconoAyuda} id="icono-ayuda"/>
                  </Tooltip>
                </div>
                <Table
                   rowSelection={rowSelection}
                   columns={columnas}
                   pagination = {{
                     defaultPageSize : 5,
                     disabled : props.total <= 5 ? true : false,
                     responsive : true,
                     size : "small",
                     total :props.total,
                     showQuickJumper : false
                   }}
                   id="tabla-voluntarios-bd"
                   dataSource={props.data}
                 />
              </div>

            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
               <div id="tabla-volun-seleccionados">
                  <div className="titulotablasVol">
                    {etiquetasGafete.titulo_tabla_seleccionados}
                  </div>
                  <Table
                      columns={columnasTablaDos}
                      dataSource={props.voluntariosSeleccionados}
                      id="tabla-voluntarios-seleccionados"
                      pagination={{
                        defaultPageSize : 5,
                        disabled : props.voluntariosSeleccionados <= 5 ? true : false,
                        responsive : true,
                        size : "small",
                        total :props.voluntariosSeleccionados
                      }}/>
                  <div id="folios-seleccionados">
                    Folios seleccionados : {props.voluntariosSeleccionados.length}
                  </div>
               </div>
             </Col>
           </Row>
           <Row  gutter={16}>
             <Col xs={24} sm={24} md={24} lg={24} xl={24}
                  id="div-btn-descargar">
               <Button onClick={props.crearGafetes}
                       className={props.voluntariosSeleccionados.length > 0
                         && props.puedeGenerarGafete ?
                         "btnDescargarAct" : "btnDescargarDes"} >
                 {etiquetasGafete.btn_descargar}
               </Button>
             </Col>
           </Row>
        </div>
          <Modal
                 visible={props.mostrar}
                 onCancel={props.ocultarModal}
                 onOk = { () => {
                          props.crearGafetes(props.voluntariosSeleccionadoModal);
                         }}
                 cancelText ="Cerrar"
                 okText = "Imprmir"
                 width={335}
                 className="modalGafete"
                 centered>
            {
              props.voluntariosSeleccionadoModal != null ?
                <div>
                <div id="nombre-div">
                  <div id="img-foto">
                    <img src={props.imagenUrlFoto}/>
                  </div>
                    <p className="nombreVoluntario">
                       {acomodarNombre(props.voluntariosSeleccionadoModal.nombreVoluntario)}
                    </p>
                    <p className="nombreVoluntario">
                       {acomodarApellidos(
                            props.voluntariosSeleccionadoModal.nombreVoluntario,
                            props.voluntariosSeleccionadoModal.nombreCompleto)}
                    </p>
                    <p  id="entidad">
                        {props.entidad}
                    </p>
                    <p id="jde">
                      {props.jde} &nbsp; Junta Distrital Ejecutiva
                    </p>
                </div>

                <div id="div-qr">
                  <QRCode value={process.env.REACT_APP_API_URL+etiquetasGafete.PATH_VOLUNTARIO}
                      id="qr-voluntario"/>
                </div>
              </div>
            : ""
            }
          </Modal>

      </Layout>
      </BlockLoader>
  );

}



export default VGafete;
