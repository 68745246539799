export const NOMBRE_CORTO_SISTEMA = 'CIJ';
export const RUTA_SERVIDOR = '/2021RepoDECEYEC/CIJ';
export const FILE_CORTE_JSON ='carpetaCorte.json';
export const FOLDER_CORTE_RESTRINGIDO = '_REST'
export const FOLDER_CEDULAS = 'Cedulas';
export const FOLDER_LISTADOS = 'Listados';
export const FOLDER_CATALOGOS = 'Catalogos';
export const FOLDER_VOTO_NACIONAL = 'VN';
export const FOLDER_VOTO_EXTRANJERO = 'VMRE';
export const FOLDER_ESTADO = 'ESTADO_';
export const FOLDER_FEDERAL = 'FEDERAL';
export const FOLDER_DISTRITO = 'DF_';
export const FOLDER_LOCAL = 'LOCAL';
export const FOLDER_MUNICIPAL = 'MUNICIPAL';
export const NIVEL_PROCESO = 'nivelProceso';
export const NIVEL_DISTRITOS_TOTALES = 'nivelDistritosTotales';
export const NIVEL_DISTRITOS_SIN_TOTALES = 'nivelDistritosSinTotales';
export const NIVEL_ENTIDAD = 'nivelEntidad';
export const NIVEL_LOCAL_SECCION = 'nivelLocalSeccion';
export const NIVEL_DISTRITO_CONV = 'nivelDistritoConvocatoria';
export const NIVEL_DISTRITO_SEDE = 'nivelDistritoSede';
export const NIVEL_DISTRITO = 'nivelDistrito';
export const NIVEL_LOCAL = 'nivelLocal';
export const NIVEL_LOCAL_DESGLOSE = 'nivelLocalDesglose';
export const NIVEL_LOCAL_DESGLOSE_TITLE = 'Por Distrito local';
export const NIVEL_MUNICIPAL = 'nivelMunicipal';
export const NIVEL_MUNICIPAL_DESGLOSE = 'nivelMunicipalDesglose';
export const NIVEL_MUNICIPAL_DESGLOSE_TITLE = 'Por Municipio';
export const NIVELES_NACIONALES = {
    nivelProceso: true,
    nivelDistritosTotales: true,
    nivelDistritosSinTotales: true
};
export const FILE_EXTENSION_JSON = '.json';
export const FILE_CDETALLES = 'C_DETALLES.json';
export const FILE_CCEDULAS = 'C_CEDULAS.json';
export const FILE_CCEDULAS_NIVEL = 'C_CEDULAS_NIVEL.json';
export const FILE_CCLISTADOS = 'C_LISTADOS_JL.json';
export const FILE_CCLISTADOS_NIVEL = 'C_LISTADOS_NIVEL_JL.json';
export const FILE_CESTADOS = 'C_ESTADOS.json';
export const FILE_CDISTRITOS_FEDERALES = 'C_DISTRITOS_FEDERALES.json';
export const FILE_CDISTRITOS_LOCALES = 'C_DISTRITOS_LOCALES.json';
export const FILE_CMUNICIPIOS = 'C_MUNICIPIOS.json';
export const FILE_CARE = 'C_ARES_DTO_';
export const FILE_CZORE = 'C_ZORES_DTO_';
export const FILE_OC = 'OC.json';
export const FILE_OC300CT = 'OC300totales.json';
export const FILE_OC300ST = 'OC300.json';
export const FILE_JL = 'JL.json';
export const FILE_JL_DESGLOSE = 'JL_DESGLOSE.json';
export const FILE_JL_DESGLOSE_CONV = 'JL_DESGLOSE_CONVOCATORIA.json';
export const FILE_JL_DESGLOSE_SEDE = 'JL_DESGLOSE_SEDE.json';
export const FILE_JDF = 'JDF.json';
export const FILE_OPLE = 'OPLE.json';
export const FILE_JDL = 'JDL_DESGLOSE.json';
export const FILE_MUN = 'MUNICIPAL.json';
export const FILE_MUN_DESGLOSE = 'JM_DESGLOSE.json';
export const LABEL_CEDULAS = 'Cédulas';
export const LABEL_LISTADOS = 'Listados';
export const SELECCIONA_PROCESO = 'Seleccione un proceso electoral para continuar';
export const SELECCIONA_ESTADO = 'Seleccione una entidad para continuar';
export const SELECCIONA_ESTADO_DISTRITO = 'Seleccione una entidad y una junta distrital para continuar';
export const ERROR_REPORTE_NO_ENCONTRADO = 'La información del reporte no fue localizada. Comunícate al CAU.'
export const ERROR_OBTENER_REPORTE = 'Error al obtener la información del reporte. Comunícate al CAU.';
export const NO_HAY_DATOS = 'No hay datos';
export const PAGINA = 'Página';
export const COTAS_TITLE = 'Cotas';
export const ULTIMO_CORTE = 'Último corte: ';
export const SELECCIONE_REPORTE = 'Seleccione un reporte';
export const SELECCIONE_NIVEL = 'Seleccione un nivel';
export const ACCION_REGRESAR = 'Regresar';
export const ACCION_BUSCAR = 'Buscar';
export const ACCION_FILTRAR = 'Filtrar';
export const ACCION_LIMPIAR = 'Limpiar';
