import UserActionTypes from './user-types';

export const setCurrentUser = (user) => ({
  type: UserActionTypes.SET_CURRENT_USER,
  payload: user,
});

export const setCurrentUserRol = (userRol) => ({
  type: UserActionTypes.SET_CURRENT_USER_ROL,
  payload: userRol,
});

export const setCurrentUserNivel = (userNivel) => ({
  type: UserActionTypes.SET_CURRENT_USER_NIVEL,
  payload: userNivel,
});

export const userLogout = () => ({
  type: UserActionTypes.USER_LOGOUT
});
