import React from 'react';

import {connect} from 'react-redux';
import {Layout, Tabs,Row, Col, Modal,Tooltip, Form } from 'antd';
import * as etiquetasVoluntarios from '../../../../constants/EtiquetasVoluntarios';

import VDatosPConsulta from "../consulta/VDatosPConsulta";
import VDatosDConsulta from "../consulta/VDatosDConsulta";
import VDocumentosConsulta from "../consulta/VDocumentosConsulta";

import '../../../../css/voluntarios/consultaModalVoluntarios.css';

const VContenidoVoluntario = (props) =>{
  const { TabPane } = Tabs;

  return(
    <Layout className='validar_modulo'>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <h1 id="titulo-componente">
            {etiquetasVoluntarios.datos_voluntarios_lbl_consulta}
          </h1>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <h1 id="titulo-folio">
            Folio {props.voluntarioSeleccionado.folio}
          </h1>
        </Col>
      </Row>
      <div id="formulario-registro">
        <Tabs defaultActiveKey="1" centered={true} size="large"
              className="pestanaRegistro" activeKey={props.pestanaActiva}>

          <TabPane tab="Datos personales" key="1" disabled>
            <VDatosPConsulta
                voluntarioSeleccionado = {props.voluntarioSeleccionado}
                navegar  = {props.navegar}
            />
          </TabPane>
          <TabPane tab="Domicilio" key="2" disabled>
            <VDatosDConsulta
                voluntarioSeleccionado = {props.voluntarioSeleccionado}
                navegar  = {props.navegar}
                nombreMunicipio = {props.nombreMunicipio}
            />
          </TabPane>
          <TabPane tab="Documentos" key="3" disabled>
            <VDocumentosConsulta
              navegar  = {props.navegar}
              cargarIneFrente = {props.cargarIneFrente !== null ?
                props.cargarIneFrente.data:""}
              cargarIneReverso = {props.cargarIneReverso !== null ?
                props.cargarIneReverso.data:""}
              cargarComprobante = {props.cargarComprobante !== null ?
                props.cargarComprobante.data:""}
              cargarFoto = {props.cargarFoto !== null ?
                props.cargarFoto.data : ""}
            />
          </TabPane>
        </Tabs>
      </div>
    </Layout>
  )
}

export default VContenidoVoluntario;
