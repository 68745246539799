import React from 'react';

import { ArrowRightOutlined, ArrowLeftOutlined,QuestionCircleFilled} from '@ant-design/icons';
import {Row, Col, Form, Input,Button,Checkbox} from 'antd';
import * as etiquetas from '../../../../constants/EtiquetasVoluntarios';

const VDatosPConsulta = (props) =>{
  return (
    <div>
    <Row gutter={16}>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Form.Item
          label={etiquetas.datos_personales_lbl_tipoVoluntario}
          disabled={true}
          rules={[{
                    required: false,
                }]}
            className = "formularioInput">
            <label>{props.voluntarioSeleccionado.tipoVoluntario}</label>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Form.Item
          label={etiquetas.datos_personales_lbl_claveElector}
          disabled={true}
          rules={[{
                    required: false
                }]}
          className = "formularioInput">
          <label>{props.voluntarioSeleccionado.claveElector}</label>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Form.Item
          label={etiquetas.datos_personales_lbl_curp}
          disabled={true}
          rules={[{
                    required: false
                }]}
          className = "formularioInput">
          <label>{props.voluntarioSeleccionado.curp}</label>
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Form.Item
          label={etiquetas.datos_personales_lbl_nombre}
          disabled={true}
          rules={[{
                    required: false
                }]}
          className = "formularioInput">
          <label>{props.voluntarioSeleccionado.nombre}</label>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Form.Item
          label={etiquetas.datos_personales_lbl_apellidoPaterno}
          disabled={true}
          rules={[{
                    required: false
                }]}
          className = "formularioInput">
          <label>{props.voluntarioSeleccionado.apellidoP}</label>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Form.Item
          label={etiquetas.datos_personales_lbl_apellidoMaterno}
          disabled={true}
          rules={[{
                    required: false,
                }]}
          className = "formularioInput">
          <label>{props.voluntarioSeleccionado.apellidoM} </label>
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Form.Item
          label={etiquetas.datos_personales_lbl_fechaNacimiento}
          disabled={true}
          rules={[{
                    required: false,
                }]}
          className = "formularioInput">
          <label>{props.voluntarioSeleccionado.fechaNacimiento}</label>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Form.Item
          label={etiquetas.datos_personales_lbl_edad}
          disabled={true}
          rules={[{
                    required: false
                }]}
          className = "formularioInput">
          <label>{props.voluntarioSeleccionado.edad}</label>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Form.Item
          label={etiquetas.datos_personales_lbl_genero}
          disabled={true}
          rules={[{
                    required: false
                }]}
          className = "formularioInput">
          <label>{props.voluntarioSeleccionado.genero}</label>
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Form.Item
          label={etiquetas.datos_personales_lbl_correoElectronico}
          disabled={true}
          rules={[{
                    required: false
                }]}
          className = "formularioInput">
          <label>{props.voluntarioSeleccionado.correo}</label>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Form.Item
          label={etiquetas.datos_personales_lbl_celular}
          disabled={true}
          rules={[{
                    required: false
                }]}
          className = "formularioInput">
          <label>{props.voluntarioSeleccionado.celular}</label>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Form.Item
          label={etiquetas.datos_personales_lbl_telefono }
          disabled={true}
          rules={[{
                    required: false
                }]}
          className = "formularioInput">
          <label>{props.voluntarioSeleccionado.telefono}</label>
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Form.Item
          label={etiquetas.datos_personales_lbl_experiencia}
          name="experiencia"
          rules={[{
                    required: false
                }]}
          className = "formularioInput">
          <label>{props.voluntarioSeleccionado.experiencia}</label>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={8} lg={6} xl={6}>
        <Form.Item
          label={etiquetas.datos_personales_lbl_hablaLenguaIndigena}
          disabled={true}
          rules={[{
                    required: false
                }]}
          className = "formularioInput">
          <label>{props.voluntarioSeleccionado.hablaIndigena}</label>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={8} lg={6} xl={6}>
        <Form.Item
          label={etiquetas.datos_personales_lbl_cualLenguaIndigena}
          disabled={true}
          rules={[{
                    required:false
                }]}
          className = "formularioInput">
          <label>{props.voluntarioSeleccionado.queLengua}</label>
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col xs={2} sm={2} md={2} lg={2} xl={2}>
            <Checkbox checked={true} />
      </Col>
      <Col xs={22} sm={22} md={22} lg={22} xl={22}>
      <span id="texto-check">
         La persona declara que participa de manera voluntaria, que ha
         leído el  aviso de privacidad
         de sus datos personales y acepta ser contactada vía correo
         electrónico para algún seguimiento o notificación de información
          sobre el proceso en que se encuentra participando.
          Asimismo, que conoce que en su momento habrá de firmar una
          declaración de responsabilidad respecto a la custodia,
          el cuidado y buen uso de un dispositivo móvil propiedad del
          Instituto Nacional Electoral.
       </span>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Form.Item>
          <Button type="primary" className="botonDer"
            onClick={()=>props.navegar("2")}
            shape="circle"
            icon={<ArrowRightOutlined id="flechaDer"/>}/>
        </Form.Item>
      </Col>
    </Row>
    </div>
  );
}

export default VDatosPConsulta;
