import React from 'react';
import VChartVoluntarios from './VChartVoluntarios';
import * as etiquetas from '../../constants/EtiquetasHomePortal';
import img_voluntario from '../../assets/img/home_voluntario.png';

const VCardVoluntarios = ({ total, voluntarios, externosEstatus }) => {
    return(
        <div className='home-card-voluntarios'>
            <div className='hcdv-totales'>
                <span className='hcdv-titulo'>{etiquetas.home_card_voluntarios_total}</span>
                <span className='hcdv-value'>{total.toLocaleString('en-US')}</span>
                <img className='hcdv-img' src={img_voluntario} alt='Voluntario' />
            </div>
            <VChartVoluntarios title={etiquetas.home_chart_voluntarios_por_tipo_title} 
                voluntarios={voluntarios}/>
            <VChartVoluntarios title={etiquetas.home_chart_voluntarios_por_estatus_title} 
                voluntarios={externosEstatus}/>
        </div>
    );
}

export default VCardVoluntarios;