import React from 'react';
import VBitacoraJson from '../../components/bitacoraJson/VBitacoraJson';
import AuthenticatedComponent from '../../components/comunes/AuthenticatedComponent';
import CTemplate from '../homeInterno/CTemplate';

const CBitacoraJson = () => {
	return (
		<AuthenticatedComponent>
			<CTemplate
				contenido={
					<VBitacoraJson

					/>
				}
				guia={false}
			/>
		</AuthenticatedComponent>
	);
};

export default CBitacoraJson;