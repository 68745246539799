import React from 'react';
import {Card, Carousel} from 'antd';
import VCard from './VCard';
import VCardCasillas from './VCardCasillas';
import VCardParticipantesEdad from './VCardParticipantesEdad';
import VCardVoluntarios from './VCardVoluntarios';
import VCardParticipantesSexo from './VCardParticipantesSexo';
import * as etiquetas from '../../constants/EtiquetasHomePortal';
import logoResultados from '../../assets/img/logoResultados.png';
import './../../css/homeExterno/homePortal.scss';

const VHome = ({datosCasillas, datosVoluntarios, datosParticipantes }) => {

    function onChange(a, b, c) {
        console.log(a, b, c);
    }

    const link = () => {
        window.location.href = "/resultadosCij";
    }

    return(
        <div className='home-card-list'>
            <VCard gridArea={"casillas"}
                title={etiquetas.home_card_casillas_title}>
                <VCardCasillas casillas={datosCasillas.casillas} />
            </VCard>
            <VCard gridArea={"participantesEdad"}
                    title={etiquetas.home_card_participantes_edad_title}>
                <VCardParticipantesEdad total={datosParticipantes.total} 
                    participantesEdad={datosParticipantes.participantesEdad}/>
            </VCard>
            <VCard gridArea={"voluntarios"}
                title={etiquetas.home_card_voluntarios_title}>
                <VCardVoluntarios total={datosVoluntarios.total}
                    voluntarios={datosVoluntarios.voluntarios}
                    externosEstatus={datosVoluntarios.externosEstatus} />
            </VCard>
            <VCard gridArea={"participantesSexo"}>
                <Card className="hcard-borde" bordered={false}>
                    <Carousel afterChange={onChange} className="hcarousel-style">
                        <div>
                            <VCard title={etiquetas.home_card_participantes_resultados}>
                                <div className="centrar-img-home">
                                    <a onClick={link}><img src={logoResultados} className="img-logo-home"/></a>
                                </div>
                            </VCard>
                        </div>
                        <div>
                            <VCard title={etiquetas.home_card_participantes_sexo_title}>
                                <VCardParticipantesSexo 
                                    participantesSexo={datosParticipantes.participantesSexo}/>
                                <br/>
                            </VCard>
                        </div>
                    </Carousel>
                </Card>
            </VCard>
        </div>
    );

}

export default VHome;