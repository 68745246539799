export const WIZARD_DATOS_UBICACION                = 0;
export const WIZARD_DATOS_CONECTIVIDAD             = 1;
export const WIZARD_DATOS_HORARIOS                 = 2;

export const datos_asignar_casilla_lbl_titulo                  = "Asignar casilla";
export const datos_asignar_casilla_lbl_casilla                 = "Casilla";
export const datos_asignar_casilla_lbl_seccion                 = "Seccion";
export const datos_asignar_casilla_lbl_municipio               = "Municipio";
export const datos_asignar_casilla_lbl_tipo_casilla            = "Tipo de casilla";
export const datos_asignar_casilla_lbl_voluntarios_asignados   = "Voluntarios asignados";
export const datos_asignar_casilla_lbl_acciones                = "Acciones";

export const datos_modificar_casilla_lbl_titulo                = "Modificar casilla";
export const datos_modificar_casilla_lbl_voluntario_buscar     = "Ingrese el número de folio o nombre del voluntario para buscar al voluntario";
export const datos_modificar_casilla_lbl_no_folio_nombre       = "No. de folio o nombre:";

export const datos_ubicacion_lbl_titulo                 = "Ubicación de la casilla";
export const datos_ubicacion_lbl_municipio              = "Municipio o Alcaldia";
export const datos_ubicacion_lbl_seccion                = "Seccion";
export const datos_ubicacion_lbl_tipo_casilla           = "Tipo Casilla";
export const datos_ubicacion_lbl_zona_responsabilidad   = "Asignar ZOR"
export const datos_ubicacion_lbl_lugar                  = "Lugar";
export const datos_ubicacion_lbl_ubicacion              = "Ubicación";
export const datos_ubicacion_lbl_calle                  = "Calle";
export const datos_ubicacion_lbl_numero                 = "Número";
export const datos_ubicacion_lbl_colonia                = "Colonia";
export const datos_ubicacion_lbl_cp                     = "Código postal";
export const datos_ubicacion_lbl_referencia             = "Referencias";
export const datos_ubicacion_lbl_telefono               = "Telefono del sitio";
export const datos_ubicacion_lbl_nombre_contacto        = "Nombre de Contacto";
export const datos_ubicacion_lbl_telefono_contacto      = "Telefono de Contacto";

export const datos_zores_lbl_titulo                     = "Asigancion de ZOR en la casilla";

export const datos_conectividad_lbl_internet            ="¿Cuenta con acceso a internet?"
export const datos_conectividad_lbl_titulo              = "Conectividad de la casilla";
export const datos_conectividad_lbl_computadoras        = "Computadoras de escritorio, laptops,...";
export const datos_conectividad_lbl_telefonoi           = "Telefono inteligente";
export const datos_conectividad_lbl_tablet              = "Tablet, Ipad,...";

export const datos_horarios_lbl_titulo                  = "Horarios de la casilla";
export const datos_horarios_lbl_fecha_hora_instalacion  = "Selecciona la fecha y el horario en el que se va Instalar la casilla";
export const datos_horarios_lbl_fecha_hora_cierre       = "Selecciona la fecha y el horario en el que se va Cerrar la casilla";
export const datos_horarios_lbl_fecha_instalacion       = "Fecha de inicio";
export const datos_horarios_lbl_fecha_cierre            = "Fecha de término";
export const datos_horarios_lbl_horario_instalacion     = "Hora de inicio"
export const datos_horarios_lbl_horarios_cierre         = "Hora de término"

export const datos_instalacion_lbl_responsable          = "Nombre de la persona responsable";
export const datos_instalacion_lbl_fecha_acta           = "Fecha de generación de acta";
export const datos_instalacion_lbl_casilla_instalada    = "¿La casilla se instaló en la fecha, hora y ubicación señalada?";
export const datos_instalacion_lbl_causa_instalacion    = "Explique brevemente la causa";
export const datos_instalacion_lbl_agregar_ubicacion    = "Agregar ubicación";
export const datos_instalacion_lbl_incidencia_cierre    = "¿Hubo alguna incidencia durante el cierre?";
export const datos_instalacion_lbl_incidencia_cual      = "¿Cuál?";
export const datos_instalacion_lbl_incidencia_solucion  = "Explique brevemente como se solucionó";

export const etiqueta_tooltip_consultar                 = "Consultar";
export const etiqueta_tooltip_editar                    = "Editar";
export const etiqueta_tooltip_eliminar                  = "Eliminar";
export const etiqueta_tooltip_filtros                   = "Utiliza los filtros para buscar una casilla registrada";
export const etiqueta_tooltip_acciones                  = "Selecciona la acción que deseas realizar: consultar, editar o eliminar el registro"
export const etiqueta_tooltip_seccion                   = "Es la sección electoral donde se ubicará la casilla";
export const etiqueta_tooltip_tipo_casilla              = "ESCUELA: Casillas físicas o virtuales en escuelas públicas o privadas.\n ITINERANTES: Casillas móviles en lugares de difícil acceso.\n En el caso de las boletas digitales, con una o más ubicaciones.\n JDE, JLE Y OPLE FÍSICA: Casillas en sedes de órganos electorales.";
export const etiqueta_tooltip_conectividad              = "Ingresa la cantidad y los dispositivos con los que cuenta la casilla";
export const etiqueta_tooltip_horarios                  = "Selecciona las fechas y horarios en que operará la casilla registrada";
export const etiqueta_tooltip_zore                      = "Es la zona de responsabilidad de acuerdo a su sección";
export const etiqueta_tooltip_botonIzq                  = "Página siguiente";
export const etiqueta_tooltip_botonDer                  = "Página anterior";
export const etiqueta_tooltip_boton_guardar             = "El botón de guardar se habilitará una vez que se completen los campos de los submódulos de registro de casillas";

export const etiqueta_tooltip_genera_url                = "Generar URL: La URL se genera con base en la información de la casilla seleccionada y en el enlace de acceso a la consulta.";
export const etiqueta_tooltip_acciones_seguimiento      = "Selecciona la acción que deseas realizar: registrar, consultar, editar o descargar el acta de cierre.";
export const etiqueta_tooltip_registrar_casilla         = "Registrar instalación";
export const etiqueta_tooltip_cierre                    = "Cierre de casilla";
export const etiqueta_tooltip_acta                      = "Acta de cierre";

export const etiqueta_mensaje_exito_guardado            = "La información se guardó con éxito.";
export const etiqueta_mensaje_casilla_registrada        = " de casilla registrada";
export const etiqueta_mensaje_descarga_acta             = "Se descargó el acta ";
export const etiqueta_mensaje_descarga_url             = "Se descargó la URL ";

export const advertencia_edo_dto                        = "Selecciona estado y distrito para continuar.";

export const advertencia_responsable                    = "Ingresa el nombre de la persona responsable"
export const advertencia_fecha_acta                     = "Selecciona la fecha de generación de acta"
export const advertencia_fecha                          = "Selecciona la fecha de "
export const advertencia_hora                           = "Selecciona la hora de "
export const advertencia_seleccionar_opcion             = "Selecciona una opción"
export const advertencia_ingresar_lugar                 = "Ingresa la nueva dirección"
export const advertencia_ingresar_cual                  = "Ingresa la incidencia"

export const REGEX_FORMATO_CLAVE_ELECTOR_FUAR       = /^([A-Z]{5,6}\d{8}[H|M]\d{3})$|^([0-9]{13})$/i;
export const REGEX_FORMATO_CLAVE_ELECTOR            = /^([A-Z]{5,6}\d{8}[H|M]\d{3})$/i;
export const REGEX_FORMATO_SIAP                     = /^([0-9]{13})$/i;
export const REGEX_FORMATO_NOMBRES                  = /^[A-ZÁÉÍÓÚÜÑ\'\°\.\-\/\s]*$/i;
export const REGEX_FORMATO_RFC                      = /^[A-Z]{3,4}[0-9]{6}([A-Z0-9]{3})*$/i;
export const REGEX_FORMATO_CURP                     = /^[A-Z]{4}[0-9]{6}[H|M][A-Z]{5}[A-Z0-9][0-9]$/i;
export const REGEX_FORMATO_CORREO                   = /^[_A-Z0-9-\+]+(\.[_A-Z0-9-]+)*@[A-Z0-9-]+(\.[A-Z0-9]+)*(\.[A-Z]{2,})$/i;
export const REGEX_FORMATO_TELEFONO                 = /^\d{8,10}$/i;
export const REGEX_FORMATO_NO_CARACTERES_ESPECIALES = /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i;

export const REGEX_FORMATO_SOLO_DIGITOS             = /^\d*$/i;

export const FORMATO_FECHA_MOMENT                  = 'DD/MM/YYYY';
export const FORMATO_FECHA_COMPLETA_MOMENT         = 'DD/MM/YYYY HH:mm';
export const FORMATO_HORA_MOMENT                   = 'HH:mm';
export const FORMATO_FECHA_GUION_MOMENT            = 'YYYY-MM-DD';
export const FORMATO_FECHA_GUION_COMPLETA_MOMENT   = 'YYYY-MM-DD HH:mm';

export const caracteres_Restantes                   = 250;

export const roles_permitidos_casillas = [
    "CIJ.ADMIN.OC",
    "CIJ.CAPTURA.JD",
    "CIJ.CAPTURA.JL",
    "CIJ.CAPTURA.OC",
    "CIJ.CAPTURA_VE.JD",
    "CIJ.CAPTURA_VE.JL",
    "CIJ.CAPTURA_VCEYEC.JD",
    "CIJ.CAPTURA_VCEYEC.JL",
    "CIJ.CAU.OC",
    "CIJ.CAPTURA_OPL.JL",
    "CIJ.CAPTURA_OPL.JD"
];



