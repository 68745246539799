import React from 'react';
import {Grid} from '@material-ui/core'
import { useState } from "react";
import {Button, Row, Col, Input, Layout , Form, Modal, Pagination, Card, Select, Tooltip, Image} from 'antd';
import { TeamOutlined, EditOutlined, EyeOutlined, PlusCircleOutlined, CloseCircleOutlined, 
  SearchOutlined, QuestionCircleFilled, FilterOutlined, HomeFilled } from '@ant-design/icons';
import * as etiquetasAsigVoluntarios from '../../../constants/EtiquetasAsigVoluntarios';
import 'antd/dist/antd.css';
import imgAsignar from '../../../assets/img/asignar3.png';
import imgModificar from '../../../assets/img/modificar2.png';
import "../../../css/asignacionCasillas/asignacionCasillas.css";

import icono_editar from '../../../assets/img/icono_editar.svg';
import icono_anadir from '../../../assets/img/icono_anadir.svg';
import icono_borrar from '../../../assets/img/icono_borrar.svg';
import icono_mas from '../../../assets/img/icono_mas.svg';
import icono_ocultar from '../../../assets/img/icono_ocultar.svg';
const { Option } = Select;





const VAsignacionVoluntarios = (props) =>{
  //console.log("VAsignacionVoluntarios",props);
  //background-image: url('../../assets/img/fondo_gafete.png');
  const [formAsignacion] = Form.useForm();
  const [searCasilla, setSearchCasilla] = useState("");
  const [searSeccion, setSearchSeccion] = useState("");
  const [searFolioVol, setSearchFolioVol] = useState("");
  const [searVoluntario, setSearchVoluntario] = useState("");

  
       return(
        <Grid container>         
        <Grid item xs={12}>          
            <Layout className='validar_modulo'>              
              <Form name="asignacionForm" >                         
                <div  id="icon-pregunta" style={{display: props.ocultarCasillas ? "none" : "block"}}>
                    <span className="tituloCasillas">
                      {etiquetasAsigVoluntarios.titulo_componente}
                    </span>
                    <br></br>
                    <div className ="tituloTable" style={{	fontSize: "16px" }}>
                      <span 
                          >
                          {etiquetasAsigVoluntarios.subtitulo_componente}
                          <Tooltip title={etiquetasAsigVoluntarios.etiqueta_tooltip_resultado}>
                            <QuestionCircleFilled/>
                          </Tooltip>
                        </span>
                      </div>
                     </div>

                     <div style={{display: props.ocultarVoluntarios ? "none" : "block"}}>
                        <span className="tituloCasillas" >
                          {etiquetasAsigVoluntarios.casilla} {props.folioCasilla}
                        </span>
                        <br></br>
                        <span 
                            className ="tituloTable" style={{	fontSize: "16px" }}>
                              {props.banderaModificacion ? <p>{etiquetasAsigVoluntarios.modificar_voluntarios}  </p>
                              : <p>{etiquetasAsigVoluntarios.asignar_voluntarios}  </p>}                                                  
                        </span>
                      </div> 
                      <br></br>
                     <br></br>
                     
                     
                     <Grid item xs={12} className="paginationStyle">                                  
                      <div style={{display: props.ocultarCasillas ? "none" : "block"}}> 
                                   
                      <Grid item xs={12}>                    
                            <table id="icon-pregunta"  className="tableStyle"> 
                              <thead className="tableStyle1"> 
                                                                                     
                                        <th style={{ width: "18%" }}>{etiquetasAsigVoluntarios.folio}
                                        <br/>
                                        <div className="icon-preguntaTabla">
                                            <Tooltip title={etiquetasAsigVoluntarios.etiqueta_tooltip_info}>
                                               <QuestionCircleFilled />
                                           </Tooltip>
                                           &nbsp;
                                          <Input 
                                              value={searCasilla} 
                                              onChange={({target:{ value}}) => setSearchCasilla(value)}
                                              placeholder="Folio" style={{width: "60%"}}
                                              onKeyPress={(ev) => {if (ev.key === 'Enter') {props.busquedaFolioCasilla(searCasilla)}}}                                            
                                           /> 
                                        </div>
                                        </th>
                                        <th style={{ width: "15%" }}>{etiquetasAsigVoluntarios.seccion_casilla}
                                        <br/>
                                        <Input 
                                          value={searSeccion} 
                                          onChange={({target:{ value}}) => setSearchSeccion(value)}
                                          placeholder="Sección" style={{width: "80%"}}
                                          onKeyPress={(ev) => {if (ev.key === 'Enter') {props.busquedaSeccion(searSeccion)}}}   
                                        />
                                        
                                        </th>
                                        <th style={{ width: "20%" }}>{etiquetasAsigVoluntarios.municipio_casilla}
                                        <br/>
                                        <Select  defaultValue={etiquetasAsigVoluntarios.selecciona}  style={{ width: "100%", backgroundImage: icono_anadir.svg}} onChange={props.busquedaMunicipio} >                                  
                                            <Option   value="">{etiquetasAsigVoluntarios.selecciona} </Option>
                                            { props.infoMunicipios.map((value, index) =>{
                                              return(                                          
                                                <Option value={value.nombreMunicipio} >                                
                                                <tr key={index} >                                                                                        
                                                  {value.nombreMunicipio}                                            
                                                  </tr>
                                                  </Option>
                                                    )
                                                })}                                                                          
                                          </Select>
                                        </th>
                                        <th style={{ width: "20%" }}>{etiquetasAsigVoluntarios.tipo_casilla}
                                        <br/>
                                        <Select  defaultValue={etiquetasAsigVoluntarios.selecciona}  style={{ width: "80%" }} onChange={props.busquedaTipoCasilla}>                                  
                                            <Option value="">{etiquetasAsigVoluntarios.selecciona} </Option>
                                            { props.infoTiposCasillas.map((value, index) =>{
                                              return(                                          
                                                <Option value={value.idTipoCasilla} >                                
                                                <tr key={index} >                                                                                        
                                                  {value.tipoCasilla}                                            
                                                  </tr>
                                                  </Option>
                                                    )
                                                })}                                                                          
                                          </Select>
                                        </th>                                        
                                        <th style={{ width: "15%" }}>{etiquetasAsigVoluntarios.vol_asig}<div> <br/></div></th>                                       
                                        <th >{etiquetasAsigVoluntarios.acciones} &nbsp;&nbsp;
                                       
                                            <Tooltip title={etiquetasAsigVoluntarios.etiqueta_tooltip_info2}>
                                               <QuestionCircleFilled />
                                           </Tooltip>
                                         
                                       
                                        
                                        <div><br/></div></th>  
                                  </thead>
                              <tbody>  
                                                                        
                              {props.infoCasillas.map((value, index) =>{
                                  return(                                    
                                    <tr key={index}>
                                      <td >{value.folioCasilla}</td>
                                      <td>{value.seccion}</td>
                                      <td>{value.nombreMunicipio}</td>
                                      <td>{value.tipoCasilla}</td>
                                      <td>{value.volunAsig}</td>
                                      <td onClick={()=>
                                        props.estatusModulo ? 
                                        props.obtenerVoluntariosAsig(value): ""}>

                                      {value.volunAsig > 0 ? <p><Tooltip title={etiquetasAsigVoluntarios.editar}>
                                      <img src={icono_editar} /></Tooltip> </p> : <p>
                                        <Tooltip title={etiquetasAsigVoluntarios.anadir}><img src={icono_anadir} /></Tooltip> </p>}</td>

                                      <td onClick={()=>
                                      props.estatusModulo ? 
                                      props.quitarTodo(value): ""}>
                                      {value.volunAsig > 0 ? <p><Tooltip title={etiquetasAsigVoluntarios.borrar}>
                                      <img src={icono_borrar} /></Tooltip> </p> : ""}</td>
                                    </tr>
                                  )
                              })}
                              </tbody>                                            
                          </table> 
                          </Grid>                         
                          <Grid item xs={12}> 
                          <div id="icon-asignar" className="paginationStyle">
                          <HomeFilled onClick={props.obtenerCasillasAsig} /> 
                          <Pagination size="small" 
                          showSizeChanger={false}  
                          total={props.paginas} 
                          page={props.paginado} 
                          onChange={props.paginacionCasillas} />                        
                     

                          </div>
                          </Grid>                                              
                      </div>
                      </Grid> 






                      <Grid item xs={12}> 
                      <div  style={{display: props.ocultarVoluntarios ? "none" : "block"}}>
                      <Row>
                      <Grid item xs={1}/> 
                      <Grid item xs={5}> 
                      <div>                                         
                         <div id="icon-pregunta" className="table2Style"> 
                                {etiquetasAsigVoluntarios.resultados_voluntarios}
                                {props.banderaModificacion ? "" :<Tooltip  title={etiquetasAsigVoluntarios.etiqueta_tooltip_resultado}>
                                    <QuestionCircleFilled />
                                </Tooltip>}
                                
                           
                            </div>
                        <table className="table2Style">                       
                              
                                <thead className="tableStyle1">
                                  <th style={{width:"25%"}} scope="col">{etiquetasAsigVoluntarios.folio}
                                    <br/>
                                    <Input 
                                        value={searFolioVol} 
                                        onChange={({target:{ value}}) => setSearchFolioVol(value)}
                                        placeholder="Folio" style={{width: "80%"}}
                                        onKeyPress={(ev) => {if (ev.key === 'Enter') {props.busquedaFolioVol(searFolioVol)}}}   
                                        /> 
                                  </th>
                                  <th style={{width:"60%"}} scope="col">{etiquetasAsigVoluntarios.voluntario}
                                  <br/>
                                  <Input 
                                            value={searVoluntario} 
                                            onChange={({target:{ value}}) => setSearchVoluntario(value)}
                                            placeholder="Voluntario" style={{width: "80%"}}
                                            onKeyPress={(ev) => {if (ev.key === 'Enter') {props.busquedaVoluntario(searVoluntario)}}}   
                                      />  
                                  </th>
                                  <th style={{width:"15%"}} scope="col">{etiquetasAsigVoluntarios.acciones}<div><br/></div></th>
                                 
                                </thead> 

                                  
                                  <tbody>
                                    {!props.infoVoluntarios.length ? "" : props.infoVoluntarios.map((value, index) =>{
                                        return(                                    
                                          <tr key={index} >
                                            <td>{value.folio}</td>
                                            <td style={{textAlign: "justify"}}>{value.nombreVoluntario}</td> 
                                            <p></p>                                                                                
                                            <td className="content" onClick={()=>props.agregarVoluntarios(value)}>
                                            <Tooltip title={etiquetasAsigVoluntarios.anadir}><img src={icono_mas} /></Tooltip>
                                            </td>                                    
                                          </tr>                                    
                                        )
                                    })}                          
                                   </tbody>
                                 </table>
                                 <div id="icon-asignar" className="paginationStyle">
                                    <HomeFilled onClick={props.paginacionVoluntarios1} /> 
                                    <Pagination size="small" 
                                    showSizeChanger={false} 
                                    total={props.paginas} 
                                    page={props.paginado} 
                                    onChange={props.paginacionVoluntarios} />                      
                                </div>
                            </div>
                           </Grid>
                           
                                                       
                        
                         
                             
                      <div  style={{width:"15px"}}/>  
                      <Grid item xs={5}> 
                        <div id="icon-pregunta" className="table2Style" style={{color: "#494845"}}>
                          {etiquetasAsigVoluntarios.vountarios_asignados}
                          {props.banderaModificacion ? <Tooltip  title={etiquetasAsigVoluntarios.etiqueta_tooltip_asignado}>
                                    <QuestionCircleFilled id="icon-pregunta"/>
                                </Tooltip> : ""}
                         </div>
                        <table className="table2Style"> 
                              
                        <thead className="tableStyle1">
                                    <th style={{width:"25%"}} scope="col">{etiquetasAsigVoluntarios.folio}<div><br/></div> </th>
                                    <th style={{width:"60%"}} scope="col">{etiquetasAsigVoluntarios.voluntario}<div><br/></div></th>                                    
                                    <th style={{width:"15%"}} scope="col">{etiquetasAsigVoluntarios.acciones}<div><br/></div></th>
                                  </thead>
                                  <tbody>                            
                                    {!props.infoVoluntariosAsignados.length ? "" : props.infoVoluntariosAsignados.map((value, index) =>{
                                        return(
                                            <tr key={index} >
                                            <td>{value.folio}</td>
                                            <td style={{textAlign: "justify"}}>{value.nombreVoluntario}</td>
                                            <p></p>                                     
                                            <td className="content" onClick={()=>props.quitarVoluntarios(value)}>
                                            <Tooltip title={etiquetasAsigVoluntarios.quitar}><img src={icono_ocultar} /></Tooltip>
                                            </td>                                    
                                          </tr>                                    
                                        )
                                      })                             
                                    }                          
                                  </tbody>
                                  </table> 
                                  </Grid>                              
                             
                            </Row> 
                                    <br></br>
                            <div className="content" >
                             <Button type="button" id="guardar-seleccionados" 
                              onClick={props.guardarDatos} 
                               disabled={props.banderaBotonGuardar} >
                              <span>{etiquetasAsigVoluntarios.guardar}</span>
                            </Button>
                          </div>      
                        </div>
                        </Grid>
                        
                        

                        <Modal title=""
                            visible={props.isModalVisibleDatosGuradados} 
                            footer={null}
                            onCancel={props.handleCancel}
                            width={335}
                            className="modalCasillas"
                            centered
                             >
                              <div >
                                <br></br>
                                {props.banderaModificacion ? 
                                <div className="div_modal">
                                
                                  <div id='asg_img'>
                                    <img src={imgModificar} alt=""/>
                                  </div>

                                  <label className="texto_modal" >
                                    {etiquetasAsigVoluntarios.mensaje_casilla_modificada}</label>
                                    
                                    <label className="texto_modal">
                                    {etiquetasAsigVoluntarios.mensaje_casilla_modificada2}</label>
                                    

                                  <label className="texto_modal3">
                                    {etiquetasAsigVoluntarios.info_exito} </label>
                                    
                                    <div className="content" >
                                        <Button type="button" id="btn_aceptar" 
                                          onClick={props.handleCancel}                                          
                                          >
                                          <span>{etiquetasAsigVoluntarios.aceptar}</span>
                                        </Button>
                                   </div>   
                              </div>
                                : 
                              <div className="div_modal">
                                
                                <div id='asg_img'>
                                  <img src={imgAsignar} alt=""/>
                                </div>

                                <label className="texto_modal">
                                  {etiquetasAsigVoluntarios.mensaje_voluntarios_asignados}</label>
                                  
                                  <label className="texto_modal2">
                                  {etiquetasAsigVoluntarios.mensaje_voluntarios_asignados2}</label>
                                  

                                <label className="texto_modal3">
                                  {etiquetasAsigVoluntarios.info_exito} </label>
                                  
                                  <div className="content" >
                                      <Button type="button" id="btn_aceptar" 
                                        onClick={props.handleCancel} 
                                        >
                                        <span>{etiquetasAsigVoluntarios.aceptar}</span>
                                      </Button>
                                 </div>   
                            </div>                                
                                }                                  
                              </div>                            
                          </Modal>                   
                    <div>
                </div>
              
           </Form>
           
         </Layout>  
         </Grid>
           </Grid>               
        );
      }

export default VAsignacionVoluntarios;

