export const datos_asignacion_voluntarios_lbl_titulo             = "Asignación de usuarios MSPEN";
export const datos_voluntarios_asignados_lbl_titulo              = "Usuarios MSPEN asignadas";
export const datos_voluntarios_asignados_lbl_folio_casilla       = "Cargo";
export const datos_voluntarios_asignados_lbl_voluntario_asignado = "Usuario MSPEN asignada";
export const folio_casilla                                       = "Cargo";
export const numerozore                                          = "Número de ZOR";
export const seccion_casilla                                     = "Sección";
export const municipio_casilla                                   = "Municipio/Alcaldía";
export const tipo_zore                                           = "Tipo de zona";	
export const vol_asig                                            = "Usuarios MSPEN asignadas";
export const acciones                                            = "Acciones";
export const asignar                                             = "Asignar";
export const modificar                                           = "Modificar";
export const no_existen_voluntarios                              = "No existen Usuarios MSPEN en esta sección";
export const maximo_de_voluntarios                               = "La casilla ya tiene el número máximo de Usuarios MSPEN asignadas";
export const mensaje_casilla_modificada                          = "La información se guardó con éxito";
export const mensaje_voluntarios_asignados                       = "El comprobante se envió al correo electrónico registrado."; 
export const mensajeAdvertencia                       			     = "Se eliminará el registro y a las y los MSPEN asignados."; 
export const mensajeAdvertencia_sub                       		   = "¿Deseas continuar?"; 
export const mensaje_eliminado_asignados                       	 = "El registro se eliminó con éxito."; 
export const mensaje_eliminado_asignados_sub                     = "El comprobante se envió al correo electrónico registrado."; 
export const limpiar                                             = "Limpiar";
export const buscar                                              = "Buscar";
export const no_existe_casilla                                   = "El dato que ingreso no existe";
export const resultados_voluntarios                              = "Listado de usuarios MSPEN ";
export const tieneVoluntario                                     = "Nombre";
export const vountarios_asignados                                = "Lista de usuarios MSPEN asignados";
export const vountario_asignado                                  = "Nombre";
export const guardar                                             = "Guardar";
export const voluntario_removido                                 = "El usuario MSPEN se ha removido";
export const voluntario_aniadido                                 = "El usuario MSPEN se ha añadido";
export const distrito                                            = "Distrito";
export const zonaderesponsabilidad                               = "Zona de responsabilidad";
export const ubicacion                                           = "Ubicación";
export const selecciona                                          = "Selecciona";
export const con_asignacion                                      = "Con asignación";
export const sin_asignacion                                      = "Sin asignación";
export const titulo_componente                                   = "Asignar personas MSPEN";
export const titulo_componente_modifica                          = "Modificar información";
export const asignar_zonas                                		   = "Asignar zonas de responsablidad";
export const etiqueta_tooltip_res                                = "Presiona en Añadir para asignar a el usuario MSPEN.";
export const subtitulo_componente                                = "Listado de zona de responsabilidad registradas";
export const zore                                                = "Zona de responsabilidad";
export const editar                                              = "Editar";
export const anadir                                              = "Añadir";
export const folio                               				         = "Cargo";
export const etiqueta_tooltip_resultado          				         = "Elige la zona de responsabilidad a la que se asignarán los MSPEN. La ZOR que aun no tiene MSPEN asignados, se mostrarán con un cero.";
export const etiqueta_tooltip_filtros_resultado          		     = "Utiliza los filtros para realizar una búsqueda más específica de una ZOR.";
export const etiqueta_tooltip_filtros_acciones                   = "Selecciona la acción que deseas realizar: Asignar a un MSPEN, editar o eliminar una asignación.";
export const subtitulo_zore                      				         = "Asignar personas MSPEN";
export const etiqueta_tooltip_resultado_voluntarios				       = "Utiliza los filtros para localizar a las y los MSPEN y haz clic en “Añadir” para asignarlos/as.";

export const etiqueta_tooltip_consultar                 = "Consultar";
export const etiqueta_tooltip_asignar                   = "Asignar";
export const etiqueta_tooltip_editar                    = "Modificar";
export const etiqueta_tooltip_eliminar                  = "Eliminar";

//roles que solo pueden capturar
export const roles_cap = [
  "CIJ.ADMIN.OC",
  "CIJ.CAPTURA_VCEYEC.JL",
  "CIJ.CAPTURA_VCEYEC.JD",
  "CIJ.CAU.OC",
  "CIJ.CAPTURA.JL",
  "CIJ.CAPTURA.JD"
];