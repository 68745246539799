import React from 'react';
import { createStructuredSelector } from 'reselect';
import {connect} from 'react-redux';
import axios from 'axios';
import {Space, Button, Tooltip, message} from 'antd';
import { TeamOutlined, EditOutlined, SearchOutlined  } from '@ant-design/icons';
import { selectEstado, selectDistrito } from '../../../redux/menu/menu-selectors';
import { selectCurrentUser, selectCurrentUserRol } from '../../../redux/user/user-selectors';
import * as etiquetas from '../../../constants/EtiquetasGenerales';
import * as info from '../../../constants/InfoSistema';
import CTemplate from '../../homeInterno/CTemplate';
import AuthenticatedComponent from '../../../components/comunes/AuthenticatedComponent';
import VAsistenciaCapacitacion from '../../../components/asistencias/consulta/VAsistenciaCapacitacion';
import {obtenerEstatusModulo} from '../../../redux/menu/menu-utils';
import {roles_captura} from '../../../constants/EtiquetasAsistenciaCapacitacion';

const jwt = localStorage.getItem('jwt-token');

class CAsistenciaCapacitacion extends React.Component{
  
  constructor(props){
    super(props);
    this.state = {
      activarConsulta : true,
      page: 1,
      infoCapacitacionesVoluntarios : [],
      infoSedesByTipo:[],
      paginas: 0,
      paginado:0, 
      actual:0,
      paginadoVoluntarios:0,
      infoCapacitaciones: [],
      ocultarCapacitacion: false,
      ocultarVoluntarios: true,
      tipoAlerta : "warning",
      mensajeAlerta : "",
      mostrarAlerta : {display:'none'},
      idCapacitacion: 0,
      idVoluntario:0,
      idParticipacion:0,
      idSede:0,
      clave: null,
      folio: null,
      nombreVoluntario: null,
      nombreMunicipio: null,
      isModalVisible: false,
      observaciones: "",
      seccion: 0,
      distrito: 0,
      busquedaPorTipoSede:"",
      busquedaPorClave: null,
      busquedaPorFolio: null,
      busquedaPorMunicipio: null,
      busquedaPorVoluntario: null,
      isModalVisibleDatosGuradados: false,
      isModalVisibleAviso: false,
      banderaModificacion: false,
      banderaBotonGuardar: true,
      mensajeAnadido: false,
      idDistrito: null,
      folioSelect: null,
      nombreVoluntarioSelect: null,
      puedeAcceder : false,
      fechaNueva:null,
      horaNueva:null,
      respuestaApertura: "C"
    }
    console.info("el usuario con el rol ", this.props.user.rolUsuario,
    "está bloqueado ",!info.ROLES_CONSULTA[this.props.user.rolUsuario]);
  }

  componentDidMount(){
    /*this.obtenerinfoCapacitacionesVoluntarios();
    this.setState({
      puedeAcceder : true,
      respuestaApertura: "A"
    }) */
    this.consultarEstatusModulo();  
    this.consultarPermisoUsuario();
  }
consultarEstatusModulo(){
    var idProcesoElectoral = this.props.user.idProcesoElectoral;
    var idDetalleProceso = this.props.user.idDetalle;
    var idEstado = this.props.estado.idEstado;
    var idDistrito = this.props.distrito.idDistrito;
    var idSistema = info.ID_SISTEMA;
    var idModulo = this.props.location.state.idModulo;
    var grupoSistema = this.props.user.rolUsuario;

    obtenerEstatusModulo(idProcesoElectoral, idDetalleProceso,idSistema,
      idEstado, idDistrito, idModulo, grupoSistema).then(respuesta =>{
      console.info("El estatus del módulo es: ",respuesta);
      this.setState({
        respuestaApertura : respuesta
      })
      if(respuesta === "A"){
        console.info("El Módulo está abierto ");
        this.obtenerinfoCapacitacionesVoluntarios();
      }else{
        console.info("El Módulo está cerrado ");
      }
    })
  }
  consultarPermisoUsuario = () =>{
    this.setState({
      puedeAcceder : roles_captura.includes(this.props.user.rolUsuario)
    })
  }
  componentDidUpdate(){  
    if(this.state.idDistrito != this.props.distrito.idDistrito){
      this.obtenerinfoCapacitacionesVoluntarios();
      this.setState({
        ocultarCapacitacion: false,
        ocultarVoluntarios: true
      });
    }
  }

  /**
  * @author arturo.gomez
  **/
    paginacionCapacitaciones = (valor) =>{
      console.log(valor);
      const valorFinal = (valor -1)*5;
      this.state.paginado=valorFinal;      
      console.log(this.state.paginado);
      this.obtenerCasillasAsig();       
    }
   
    busquedaClave = (valor) =>{
      if(valor!=""){
        this.state.busquedaPorClave = valor;
      }else{
        this.state.busquedaPorClave = null;
      }
              
      console.log(this.state.busquedaPorClave);
      this.obtenerinfoCapacitacionesVoluntarios();
    }    
    busquedaFolio = (valor) =>{
      if(valor!=""){
        this.state.busquedaPorFolio = valor;
      }else{
        this.state.busquedaPorFolio = null;
      }
      console.log(this.state.busquedaPorFolio);
      this.obtenerinfoCapacitacionesVoluntarios();
    }

    busquedaMunicipio = (valor) =>{
      if(valor!=""){
        this.state.busquedaPorMunicipio = valor.toUpperCase();
      }else{
        this.state.busquedaPorMunicipio = null;
      }
              
      console.log(this.state.busquedaPorMunicipio);
      this.obtenerinfoCapacitacionesVoluntarios();
    }

    busquedaVoluntario = (valor) =>{
      if(valor!=""){
        this.state.busquedaPorVoluntario = valor.toUpperCase();
      }else{
        this.state.busquedaPorVoluntario = null;
      }
              
      console.log(this.state.busquedaPorVoluntario);
     this.obtenerinfoCapacitacionesVoluntarios();
    }
    busquedaTipoSede = (valor) =>{
       console.log("cambio de tipo sede");
      if(valor!=null){
        this.state.busquedaPorTipoSede = valor;
         this.setState({
          idSede: 0
        });
        console.log(this.state.busquedaPorTipoSede);
        this.obtenerinfoSedesByTipo();
      }else{
        this.state.busquedaPorTipoSede = null;
      }
    }   
    busquedaSede = (valor) =>{
       console.log("cambio de sede");
      if(valor!=null){
        this.state.idSede = valor;
        console.log(this.state.idSede);
      }else{
        this.state.idSede = null;
      }
    }
      recuperaDatos = (value) =>{
        console.log(value);
      /*if(value.idVoluntario!= this.state.idVoluntario){
        this.setState({
          banderaSede: true
        });
      }*/
      
      this.state.idParticipacion=value.idParticipacion;
      this.state.idVoluntario=value.idVoluntario;
      this.state.fechaCapacitacion= value.fechaCapacitacion;
      this.state.nombreVoluntario= value.nombreVoluntario;
      this.state.idProcesoElectoral=value.idProcesoElectoral;
      this.state.idDetalleProceso=value.idDetalleProceso;
    }
  obtenerinfoSedesByTipo = (values) =>{
    this.state.infoSedesByTipo = [];
    
    var datosUsuario = localStorage.getItem('datos-usuario') !== null ?
    JSON.parse(localStorage.getItem('datos-usuario')): null;   
    var postData = {
      idEstado: this.props.estado.idEstado, //datosUsuario !== null ? datosUsuario.idEstado : 0,
      idDistrito: this.props.distrito.idDistrito, //datosUsuario !== null ? datosUsuario.idDistrito : 0,
      tipoSede: this.state.busquedaPorTipoSede,
      idProcesoElectoral:this.state.idProcesoElectoral,
      idDetalleProceso:this.state.idDetalleProceso
    };  
    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL +
      "ws/infoSedesByTipo", postData,
          {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.user ? this.props.user.tknJWT : null
              }
          }
       ).then(response => {        
        if(response.data.code === 200){       
         this.setState({
           infoSedesByTipo:response.data.infoSedes,
           mensajeAlerta : "",
           tipoAlerta : "info",
           mostrarAlerta : {display: 'none',}
         });       
        }else if(response.data.code === 204){       
         this.setState({mensajeAlerta : response.data.message,
                     tipoAlerta : "warning",
                     mostrarAlerta : {display: 'block'},
                     banderaBotonGuardar:true,
        });       
        }else{
          this.setState({
            infoSedesByTipo: [],
            busquedaPorClave: null,
            busquedaPorFolio: null,
            busquedaPorMunicipio: null,
            busquedaPorVoluntario: null
          });
        }
      }).catch(error => {
         this.setState({mensajeAlerta : error,
                     tipoAlerta : "warning",
                     mostrarAlerta : {display: 'block'},
                     banderaBotonGuardar:true,
        });  
        console.error("Error al obtener las casillas",error);
    });
  }
  obtenerinfoCapacitacionesVoluntarios = (values) =>{
    this.state.infoCapacitacionesVoluntarios = [];
    this.setState({
      banderaBotonGuardar: true,
      isModalVisibleDatosGuradados:false,
    });
    this.state.banderaModificacion = false; 
    this.state.idDistrito = this.props.distrito.idDistrito;
    if(values !== undefined){
          console.log(values);
    }else{
      console.log("no se envian datos");
        var values = {
        inicio:1,
        fin:5
       }
    }
    var datosUsuario = localStorage.getItem('datos-usuario') !== null ?
    JSON.parse(localStorage.getItem('datos-usuario')): null;   
    var postData = {
      idEstado: this.props.estado.idEstado, //datosUsuario !== null ? datosUsuario.idEstado : 0,
      idDistrito: this.props.distrito.idDistrito, //datosUsuario !== null ? datosUsuario.idDistrito : 0,
      registro_inicio: values.inicio,
      registro_fin: values.fin,
      clave: this.state.busquedaPorClave,
      folio: this.state.busquedaPorFolio,
      nombreMunicipio: this.state.busquedaPorMunicipio,
      nombreVoluntario : this.state.busquedaPorVoluntario,
    };  
    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL +
      "ws/infoCapacitacionesVoluntarios", postData,
          {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.user ? this.props.user.tknJWT : null
              }
          }
       ).then(response => {        
        if(response.data.code === 200){
         this.setState({
           infoCapacitacionesVoluntarios:response.data.infoCapacitacionesVoluntarios,
           paginas:response.data.totalVoluntarios,
           paginado:0,
           ocultarCapacitacion:false,
           ocultarVoluntarios:true,
           mensajeAlerta : "",
           tipoAlerta : "info",
           mostrarAlerta : {display: 'none',}
         });       
        }else if(response.data.code === 204){       
         this.setState({
            mensajeAlerta : response.data.message,
            tipoAlerta : "warning",
            mostrarAlerta : {display: 'block'},
            banderaBotonGuardar:true,
        });       
        }else{
          this.setState({
            infoCapacitacionesVoluntarios: [],
            busquedaPorClave: null,
            busquedaPorFolio: null,
            busquedaPorMunicipio: null,
            busquedaPorVoluntario: null,
            paginas:0
          });
        }
      }).catch(error => {
         this.setState({mensajeAlerta : error,
                     tipoAlerta : "warning",
                     mostrarAlerta : {display: 'block'},
                     banderaBotonGuardar:true,
        });  
        console.error("Error al obtener las casillas",error);
    });
  }

    obtenerinfoCapacitaciones = (values) =>{
      console.log("OBTENER VOLUNTARIOS");
      console.log(values);
      var postData=null;
      if(values !== undefined){
        postData = {
              idVoluntario : values.idVoluntario
        };
        this.setState({
          idVoluntario : values.idVoluntario
        });
      }      
      axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL +
        "ws/infoCapacitaciones", postData,
            {
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': this.props.user ? this.props.user.tknJWT : null
                }
            }
         ).then(response => { 
          if(response.data.code === 200){
                this.setState({
                infoCapacitaciones:response.data.infoCapacitaciones,
                nombreVoluntarioSelect:response.data.nombreVoluntarioSelect,
                folioSelect:response.data.folioSelect,
                ocultarCapacitacion:true,
                ocultarVoluntarios:false,
                paginado: 0,
                mensajeAlerta:"",
                tipoAlerta : "warning",
                mostrarAlerta : {display: 'none'},
              });
        //agregar voluntario -FIN     
        }else if(response.data.code === 204){       
           this.setState({
            isModalVisibleAviso: true,
            banderaBotonGuardar:true,
          });       
        }else{
          this.setState({
            infoCapacitacionesVoluntarios: [],
            busquedaPorClave: null,
            busquedaPorFolio: null,
            busquedaPorMunicipio: null,
            busquedaPorVoluntario: null,
            paginas:0
          });
        }
        }).catch(error => {
          this.setState({mensajeAlerta : error,
                     tipoAlerta : "warning",
                     mostrarAlerta : {display: 'block'},
                     banderaBotonGuardar:true,
        });  
          console.error("Error al obtener las casillas",error);
      });
    }
  asignarVoluntarioCapacitacion = (values) =>{
      console.log("ASIGNAR VOLUNTARIOS");
      console.log(values);
      var postData=null;
      postData = {
        fechaCapacitacion : this.state.fechaNueva+" "+this.state.horaNueva,
        idSede:  this.state.idSede,
        idParticipacion: this.state.idParticipacion,
        idVoluntario: this.state.idVoluntario,
        idProcesoElectoral: this.state.idProcesoElectoral,
        idDetalleProceso: this.state.idDetalleProceso,
        usuario : this.props.user.username,
        ipUsuario: this.props.user.ip,
        tipoSede: this.state.busquedaPorTipoSede,
      };    
      axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL +
        "ws/asignarVoluntarioCapacitacion", postData,
            {
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': this.props.user ? this.props.user.tknJWT : null
                }
            }
         ).then(response => { 
          if(response.data.code === 200){
            if(response.data.modificado){
              this.setState({
                isModalVisibleDatosGuradados: true,
                banderaBotonGuardar:true,
                mensajeAlerta:"",
                tipoAlerta : "warning",
                mostrarAlerta : {display: 'none'},
              });
            }else{
              this.setState({
                mensajeAlerta:"",
                tipoAlerta : "warning",
                mostrarAlerta : {display: 'none'},
              });
            }
                
        //agregar voluntario -FIN     
        }else if(response.data.code === 204){       
           this.setState({mensajeAlerta : response.data.message,
                       tipoAlerta : "info",
                       mostrarAlerta : {display: 'block'},
                       banderaBotonGuardar:true,
          });       
        }else if(response.data.code === 400){       
           this.setState({mensajeAlerta : response.data.message,
                       tipoAlerta : "warning",
                       mostrarAlerta : {display: 'block'},
                       banderaBotonGuardar:true,
          });       
        }else{
          this.setState({
            infoSedesByTipo: [],
            idSede:null,
            busquedaPorClave: null,
            busquedaPorFolio: null,
            busquedaPorMunicipio: null,
            busquedaPorVoluntario: null,
            paginas:0
          });
        }
        }).catch(error => {
          this.setState({mensajeAlerta : error,
                     tipoAlerta : "warning",
                     mostrarAlerta : {display: 'block'},
                     banderaBotonGuardar:true,
        });  
          console.error("Error al obtener las casillas",error);
      });
  }
  cambiarFecha = (time, timeString) =>{
    if(timeString ===""){
      this.state.fechaNueva= null;
    }else{
      this.state.fechaNueva= timeString;
    }
    console.log(this.state.fechaNueva);
  }
  cambiarHora  = (time, timeString) =>{
    if(timeString ===""){
      this.state.horaNueva= null;
    }else{
      this.state.horaNueva= timeString;
    }
    console.log(this.state.horaNueva );
    if(this.state.horaNueva !== null
       && this.state.busquedaPorTipoSede !== null
       && this.state.idSede!== null
       && this.state.puedeAcceder){
      this.setState({
        banderaBotonGuardar :false
      });
    }else{
      this.setState({
        banderaBotonGuardar :true
      });
    }
  }

modalInfo = (value) =>{

  var datosUsuario = localStorage.getItem('datos-usuario') !== null ?
        JSON.parse(localStorage.getItem('datos-usuario')): null;

  this.setState({
    isModalVisible: true,
    numeroZonaResponsabilidad: value.numeroZonaResponsabilidad,
    idZonaResponsabilidad: value.idZonaResponsabilidad,
    tipoCasilla: value.tipoCasilla,
    seccion: value.seccion,
    distrito: this.props.distrito.idDistrito,
    zore: value.idZonaResponsabilidad,
    ubicacion: value.ubicacion
  });
}
handleCancel = () =>{
  this.setState({
    mensajeAlerta : "",
            tipoAlerta : "info",
            mostrarAlerta : {display: 'none'},
    isModalVisibleAviso: false,
    isModalVisibleDatosGuradados:false,
    ocultarVoluntarios: true,
    ocultarCapacitacion: false       
  });
  this.state.paginado= 0;
  this.state.busquedaPorMunicipio = null;
  this.state.busquedaPorClave =null;
  this.state.busquedaPorFolio = null;
  this.state.busquedaPorVoluntario = null;
  this.obtenerinfoCapacitacionesVoluntarios(); 
}
paginado = pagina =>{
  console.log("la pagina es", pagina);
  var paginaAnterior = parseInt(pagina,10) - 1 === 0 ? 1 :
                                                      parseInt(pagina,10) - 1;
  this.setState({
    actual : pagina,
    cargando: true,
    casillas: []
  });

  var inicio = parseInt(pagina,10) === 1 ? 1 : (paginaAnterior * 5)+1;
  var fin = parseInt(pagina,10) * 5;
  var values = {
    inicio:inicio,
    fin:fin
   }
  this.obtenerinfoCapacitacionesVoluntarios(values);
}
regresarPrimeraPagina = () =>{
  this.setState({
    paginaActual : 1,
    actual: 1
  });
  this.obtenerinfoCapacitacionesVoluntarios();
}
revisarEstatus = (datos) => {

    if(this.state.puedeAcceder){
      if(this.state.respuestaApertura === 'A'){
        this.setState({
          mensajeAlerta : "",
            mostrarAlerta : {display: 'none'}
        });
        this.eliminaCasilla(datos);
      }else{
       this.setState({
          mensajeAlerta : "Esta acción no está permitida para este usuario",
            tipoAlerta : "warning",
            mostrarAlerta : {display: 'block'},
        });
      }
    }else{
      this.setState({
          mensajeAlerta : "Esta acción no está permitida para el rol de este usuario",
            tipoAlerta : "warning",
            mostrarAlerta : {display: 'block'},
        });
    }

  }
render(){
    return(
        <AuthenticatedComponent > 
          <CTemplate contenido={       
            <VAsistenciaCapacitacion       
              activarConsulta = {this.activarConsulta}
              paginacionCapacitaciones = {this.paginacionCapacitaciones}
              columns = {this.state.columns}
              paginado = {this.paginado}
              paginas = {this.state.paginas}
              page = {this.state.page}      
              actual = {this.state.actual}
              ocultarCapacitacion = {this.state.ocultarCapacitacion}
              infoCapacitacionesVoluntarios = {this.state.infoCapacitacionesVoluntarios} 
              infoCapacitaciones = {this.state.infoCapacitaciones}
              infoSedesByTipo = {this.state.infoSedesByTipo}
              ocultarVoluntarios = {this.state.ocultarVoluntarios}
              idCapacitacion = {this.idCapacitacion}
              mensajeVolEliminado = {this.state.mensajeVolEliminado}
              isModalVisible = {this.state.isModalVisible}
              modalInfo = {this.modalInfo}
              handleCancel = {this.handleCancel}
              busquedaTipoSede = {this.busquedaTipoSede}
              folio = {this.state.folio}
              nombreVoluntario = {this.state.nombreVoluntario}
              distrito = {this.state.distrito}
              clave = {this.state.clave}
              busquedaPorTipoSede= {this.state.busquedaPorTipoSede}
              nombreMunicipio = {this.state.nombreMunicipio}
              observaciones = {this.state.observaciones}
              busquedaClave = {this.busquedaClave}
              busquedaPorClave = {this.state.busquedaPorClave}
              busquedaFolio = {this.busquedaFolio}
              busquedaPorFolio = {this.state.busquedaPorFolio}              
              busquedaMunicipio = {this.busquedaMunicipio}
              busquedaPorMunicipio = {this.state.busquedaPorMunicipio}
              busquedaVoluntario = {this.busquedaVoluntario}
              busquedaPorVoluntario = {this.state.busquedaPorVoluntario}
              banderaModificacion = {this.state.banderaModificacion}
              banderaBotonGuardar = {this.state.banderaBotonGuardar}
              mensajeAnadido = {this.state.mensajeAnadido}
              isModalVisibleDatosGuradados = {this.state.isModalVisibleDatosGuradados}
              isModalVisibleAviso= {this.state.isModalVisibleAviso}
              idDistrito = {this.state.idDistrito}
              tipoAlerta = {this.state.tipoAlerta}
              mensajeAlerta = {this.state.mensajeAlerta}
              mostrarAlerta = {this.state.mostrarAlerta}
              regresarPrimeraPagina={this.regresarPrimeraPagina}
              revisarEstatus= {this.revisarEstatus}
              respuestaApertura={this.state.respuestaApertura}
              obtenerinfoCapacitaciones={this.obtenerinfoCapacitaciones}
              folioSelect={this.state.folioSelect}
              nombreVoluntarioSelect={this.state.nombreVoluntarioSelect}
              cambiarFecha= {this.cambiarFecha}
              cambiarHora= {this.cambiarHora}
              recuperaDatos={this.recuperaDatos}
              busquedaSede={this.busquedaSede}
              asignarVoluntarioCapacitacion={this.asignarVoluntarioCapacitacion}
              obtenerinfoCapacitacionesVoluntarios={this.obtenerinfoCapacitacionesVoluntarios}
            />
            }
              guia={false}/>         
          </AuthenticatedComponent>
    )
  }
}     
const mapStateToProps = createStructuredSelector({
  estado: selectEstado,
  distrito: selectDistrito,
  user: selectCurrentUser
});

export default connect(mapStateToProps)(CAsistenciaCapacitacion);
