import React from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import axios from 'axios';
import { createStructuredSelector } from 'reselect';
import { selectCurrentVoluntario} from '../../redux/voluntario/voluntario-selectors';
import { setCurrentVoluntario} from '../../redux/voluntario/voluntario-actions';
import { selectEstado, selectDistrito} from '../../redux/menu/menu-selectors';
import { selectCurrentUser} from '../../redux/user/user-selectors';
import * as etiquetas from '../../constants/EtiquetasGenerales';
import VContenidoVoluntario
     from '../../components/voluntarios/registro/consulta/VContenidoVoluntario';
import { Input, Button, Space, Modal } from 'antd';

class CModalVoluntario extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      tipoVoluntario: "", claveElector: null,
      nombre: null,         apellidoP : null,
      apellidoM : null,     fechaNacimiento: null,
      edad: null,           genero: "",
      correo: null,         telefono: null,
      celular: null,        hablaIndigena: null,
      queLengua: null,      avisoPrivacidad: null,
      calle: null,          numeroInt: null,
      numeroExt: null,      colonia: null,
      estado: null,         municipio: null,
      cp: null, pestanaActiva: "1",
      aspirante:null
    }
  }

  componentDidMount(){
  }

  navegar = (pestana) =>{
    this.setState({
      pestanaActiva : pestana
    })
  }

  cerrarActualizar = () =>{
    this.props.cerrarModal();
    this.setState({
      pestanaActiva : "1"
    });
  }

  render(){

    return (
      <Modal visible={this.props.mostrarModal} width={980}
              onOk={this.props.cerrarModal}
              onCancel={this.cerrarActualizar}
              className="modalVoluntario">
        <VContenidoVoluntario
            voluntarioSeleccionado = {this.props.voluntario}
            navegar = {this.navegar}
            pestanaActiva = {this.state.pestanaActiva}
            cargarIneFrente = {this.props.cargarIneFrente}
            cargarIneReverso = {this.props.cargarIneReverso}
            cargarComprobante = {this.props.cargarComprobante}
            cargarFoto = {this.props.cargarFoto}
        />
      </Modal>
    );
  }
}

const mapStateToProps = createStructuredSelector({
    estado: selectEstado,
    distrito: selectDistrito,
    user: selectCurrentUser,
    voluntario : selectCurrentVoluntario
});
const mapDispatchToProps = dispatch => ({
  setCurrentVoluntario: voluntario => dispatch(setCurrentVoluntario(voluntario)),
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(CModalVoluntario));
