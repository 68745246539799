import React from 'react';
import {Row, Col} from 'antd';
import iconoEstado from '../../assets/img/estados_null.png';
import mapaAguscalientes from '../../assets/img/Mapa_Aguascalientes.svg';
import mapaBajaCalifornia from '../../assets/img/Mapa_Baja_California.svg';
import mapaBajaCaliforniaS from '../../assets/img/Mapa_Baja_California_Sur.svg';
import mapaCampeche from '../../assets/img/Mapa_Campeche.svg';
import mapaCoahuila from '../../assets/img/Mapa_Coahuila.svg';
import mapaColima from '../../assets/img/Mapa_Colima.svg';
import mapaChiapas from '../../assets/img/Mapa_Chiapas.svg';
import mapaChihuahua from '../../assets/img/Mapa_Chihuahua.svg';
import mapaCDMX from '../../assets/img/Mapa_Ciudad_de_Mexico.svg';
import mapaDurango from '../../assets/img/Mapa_Durango.svg';
import mapaGuanajuato from '../../assets/img/Mapa_Guanajuato.svg';
import mapaGuerrero from '../../assets/img/Mapa_Guerrero.svg';
import mapaHidalgo from '../../assets/img/Mapa_Hidalgo.svg';
import mapaJalisco from '../../assets/img/Mapa_Jalisco.svg';
import mapaMexico from '../../assets/img/Mapa_Mexico.svg';
import mapaMichoacan from '../../assets/img/Mapa_Michoacan.svg';
import mapaMorelos from '../../assets/img/Mapa_Morelos.svg';
import mapaNayarit from '../../assets/img/Mapa_Nayarit.svg';
import mapaNuevoLeon from '../../assets/img/Mapa_Nuevo_Leon.svg';
import mapaOaxaca from '../../assets/img/Mapa_Oaxaca.svg';
import mapaPuebla from '../../assets/img/Mapa_Puebla.svg';
import mapaQueretaro from '../../assets/img/Mapa_Queretaro.svg';
import mapaQuintanaRoo from '../../assets/img/Mapa_Quintana_Roo.svg';
import mapaSanLuisPotosi from '../../assets/img/Mapa_San_Luis_Potosi.svg';
import mapaSinaloa from '../../assets/img/Mapa_Sinaloa.svg';
import mapaSonora from '../../assets/img/Mapa_Sonora.svg';
import mapaTabasco from '../../assets/img/Mapa_Tabasco.svg';
import mapaTamaulipas from '../../assets/img/Mapa_Tamaulipas.svg';
import mapaTlaxcala from '../../assets/img/Mapa_Tlaxcala.svg';
import mapaVeracruz from '../../assets/img/Mapa_Veracruz.svg';
import mapaYucatan from '../../assets/img/Mapa_Yucatan.svg';
import mapaZacatecas from '../../assets/img/Mapa_Zacatecas.svg';


const VEstados = ({bandera, mapas, nomMunicipios, total}) => {

    const estados = [mapaAguscalientes, mapaBajaCalifornia, mapaBajaCaliforniaS, mapaCampeche, mapaCoahuila, mapaColima, mapaChiapas, mapaChihuahua, mapaCDMX, mapaDurango,
    mapaGuanajuato, mapaGuerrero, mapaHidalgo, mapaJalisco, mapaMexico, mapaMichoacan, mapaMorelos, mapaNayarit, mapaNuevoLeon, mapaOaxaca, mapaPuebla, mapaQueretaro,
    mapaQuintanaRoo, mapaSanLuisPotosi, mapaSinaloa, mapaSonora, mapaTabasco, mapaTamaulipas, mapaTlaxcala, mapaVeracruz, mapaYucatan, mapaZacatecas];

    const nombresEstados = ["Aguascalientes", "Baja California", "Baja California Sur", "Campeche", "Coahuila", "Colima", "Chiapas", "Chihuahua", "Ciudad de México",
    "Durango", "Guanajuato", "Guerrero", "Hidalgo", "Jalisco", "México", "Michoacán", "Morelos", "Nayarit", "Nuevo León", "Oaxaca", "Puebla", "Querétaro", "Quintana Roo",
    "San Luis Potosí", "Sinaloa", "Sonora", "Tabasco", "Tamaulipas", "Tlaxcala", "Veracruz", "Yucatán", "Zacatecas"];


    return(
        <>
            <div className="rPreguntas-div-style">
                {bandera && mapas.length === 1 && total !== null && nomMunicipios.includes("Todo") ?
                    <div>
                        <span className="rTitle-card">Participación en</span>
                        <br/><br/>
                        <Row>
                            <Col sm={13} md={13} lg={13}>
                                {
                                    mapas.map(element => {
                                        return(<img alt="img_mapa" src={estados.filter((estado,index) => index === element-1)}/>)
                                    })
                                }
                            </Col>
                            <Col sm={11} md={11} lg={11}>
                                {
                                    mapas.map(element => {
                                        return(<div className="rDiv-nombreEstado"><span className="rText-estado">{nombresEstados.filter((estado,index) => index === element-1)}</span></div>)
                                    })
                                }
                                <br/>
                                <div className="rDiv-nombreEstado">
                                    <span className="rNumero-style">{total}</span>
                                </div>
                            </Col>
                        </Row>
                    </div>: bandera && mapas.length === 1 && total !== null && mapas.includes(0) ?
                    <div>
                        <span className="rTitle-card">Participación en</span>
                        <br/><br/>
                            <div className="rDiv-nombreEstado"><span className="rText-estado">Nacional</span></div>
                        <div className="rDiv-nombreEstado">
                            <span className="rNumero-style">{total}</span>
                        </div>
                    </div>:bandera && mapas.length === 1 && total !== null && nomMunicipios.length > 0 ?
                    <div>
                        <span className="rTitle-card">Participación en</span>
                        <br/><br/>
                        {
                            nomMunicipios.map(element => {
                                return(<div className="rDiv-nombreEstado"><span className="rText-estado">{element}</span></div>)
                            })
                        }
                        <div className="rDiv-nombreEstado">
                            <span className="rNumero-style">{total}</span>
                        </div>
                    </div>: bandera && mapas.length === 1 && total !== null ?
                    <div>
                        <span className="rTitle-card">Participación en</span>
                        <br/><br/>
                        <Row>
                            <Col sm={13} md={13} lg={13}>
                                {
                                    mapas.map(element => {
                                        return(<img alt="img_mapa"src={estados.filter((estado,index) => index === element-1)}/>)
                                    })
                                }
                            </Col>
                            <Col sm={11} md={11} lg={11}>
                                {
                                    mapas.map(element => {
                                        return(<div className="rDiv-nombreEstado"><span className="rText-estado">{nombresEstados.filter((estado,index) => index === element-1)}</span></div>)
                                    })
                                }
                                <br/>
                                <div className="rDiv-nombreEstado">
                                    <span className="rNumero-style">{total}</span>
                                </div>
                            </Col>
                        </Row>
                    </div>:bandera && mapas.length > 1 && total !== null ?
                    <div>
                        <span className="rTitle-card">Participación en</span>
                        <br/><br/>
                        {
                            mapas.map(element => {
                                return(<div className="rDiv-nombreEstado"><span className="rText-estado">{nombresEstados.filter((estado,index) => index === element-1)}</span></div>)
                            })
                        }
                        <div className="rDiv-nombreEstado">
                            <span className="rNumero-style">{total}</span>
                        </div>

                    </div>:
                    <div className="rDiv-centrar-estado">
                        <img alt="img_mapa" src={iconoEstado} className="rEstado-null"/>
                        <br/><br/>
                        <span className="rTexto-null">No hay datos</span>
                    </div>
                }
            </div>
        </>
    )
}

export default VEstados;
