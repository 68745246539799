import CasillaActionTypes from './casilla-types';

const INITIAL_STATE = {
  currentCasilla: null,
  casillaId: null
};

const menuReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CasillaActionTypes.SET_CURRENT_CASILLA:
      return {
        ...state,
        currentCasilla: action.payload
      }
    case CasillaActionTypes.SET_CURRENT_CASILLA_ID:
      return {
        ...state,
        casillaId: action.payload
      }
    default:
      return state;
  }
};

export default menuReducer;
