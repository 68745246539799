import UserActionTypes from './user-types';

const INITIAL_STATE = {
  currentUser: null,
  userRol: null,
  userNivel: null
};

const menuReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload
      }
    case UserActionTypes.SET_CURRENT_USER_ROL:
      return {
        ...state,
        userRol: action.payload
      }
    case UserActionTypes.SET_CURRENT_USER_NIVEL:
      return {
        ...state,
        userNivel: action.payload
      }
    default:
      return state;
  }
};

export default menuReducer;