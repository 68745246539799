import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectEstado, selectDistrito } from '../../redux/menu/menu-selectors';
import { defaultGraficaCasillas, 
        defaultGraficaVoluntarios, 
        defaultGraficaParticipantes, 
        obtenerDatosGraficaHomePortal } from '../../utils/homePortal';
import VHome from  '../../components/homePortal/VHome';
import AuthenticatedComponent from '../../components/comunes/AuthenticatedComponent';
import CTemplate from '../homeInterno/CTemplate.js'

class CHomeInt extends React.Component{

  constructor(props){
    super(props);
    this.state = {
        datosGraficaCasillas: defaultGraficaCasillas,
        datosGraficaVoluntarios: defaultGraficaVoluntarios,
        datosGraficaParticipantes: defaultGraficaParticipantes
    }
  }

  _isMounted = false;

  /*async componentDidMount(){
    this._isMounted = true;
    this.reloadData();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.estado !== prevProps.estado
      || this.props.distrito !== prevProps.distrito) {
      this.reloadData();
    }
  }*/

  componentWillUnmount(){
    this._isMounted = false;
  }

  /*async reloadData() {
    const { estado: {idEstado}, distrito: {idDistrito} } = this.props;

    const datosGraficaCasillas = await obtenerDatosGraficaHomePortal(1, idEstado, idDistrito);
    const datosGraficaVoluntarios = await obtenerDatosGraficaHomePortal(2, idEstado, idDistrito);
    const datosGraficaParticipantes = await obtenerDatosGraficaHomePortal(3, idEstado, idDistrito);

    if(this._isMounted){
      this.setState({
        datosGraficaCasillas: datosGraficaCasillas,
        datosGraficaVoluntarios: datosGraficaVoluntarios,
        datosGraficaParticipantes: datosGraficaParticipantes
      });
    }
  }*/

  render(){
    return(
      <AuthenticatedComponent externo={false} privado={true}>
        <CTemplate isHome
                  contenido ={<VHome 
                                  datosCasillas={this.state.datosGraficaCasillas}
                                  datosVoluntarios={this.state.datosGraficaVoluntarios}
                                  datosParticipantes={this.state.datosGraficaParticipantes} />}
        />
      </AuthenticatedComponent>
    )
  }
}

const mapStateToProps = createStructuredSelector({
    estado: selectEstado,
    distrito: selectDistrito
});

export default connect(mapStateToProps)(CHomeInt);
