import React from 'react';
import { VictoryLabel, VictoryTooltip } from 'victory';
import * as etiquetas from '../../constants/EtiquetasHomePortal';

class VChartLabel extends React.Component {
    static defaultEvents = VictoryTooltip.defaultEvents;
    
    render(){
        
        return(
            <g style={{display: `${this.props.index===1?'none':''}` }}>
                <VictoryLabel 
                    {...this.props}
                    renderInPortal={false}
                    x={this.props.x} 
                    y={this.props.y-5}
                    textAnchor={`${this.props.porcentaje > 99 ?
                                    "middle" 
                                    : this.props.porcentaje >= 60 ? "end" 
                                    : "start"}`}
                    text={`${this.props.porcentaje}%`}
                    style={{ fontFamily: 'OpenSans-Bold',
                            fill: `${this.props.porcentaje > 60 ? "#FFFFFF" : this.props.color}`, 
                            fontSize: 11}}
                    />
                <VictoryTooltip
                    {...this.props}
                    renderInPortal={false}
                    textAnchor="middle"
                    style={{fontSize: '15px', 
                            color:'#000000'}}
                    orientation="top"
                    cornerRadius={10}
                    pointerLength={0}
                    text={`${this.props.value1.toLocaleString('en-US')} ${etiquetas.home_chart_participantes_tooltip_niños}`}
                    flyoutStyle={{
                        stroke: "none",
                        fill: 'var(--chart-back-ground)'
                    }}
                    flyoutPadding={{top: 5, bottom: 5, left: 10, right: 10 }}
                     />
            </g>
           
        );
    }
};

export default VChartLabel;