import React from 'react';
import { VictoryChart, VictoryBar, VictoryTooltip,VictoryArea,VictoryPie,
        VictoryGroup,VictoryTheme,VictoryStack,VictoryPortal,
VictoryScatter  } from 'victory';
import {Layout, Row, Col} from 'antd';
import iconoGrafica from '../../assets/img/graficas_null.png';



const VGraficas = (props) => {
  const colores = [
    "#52B2A9",
    "#FFCC1C",
    "#C4006C",
    "#80539C",
    "#9F8691",
    "#9AA42F",
    "#2CC9D6",
    "#EFD12C",
    "#FFBF03",
    "#4a8789",
    "#74418A",
    "#917684",
    "#663C99",
    "#D3097F",
    "#98e1b8",
    "#9a69ba"
  ];

    return(

              <div className="graficaDiv" id="grafica-div">
                  <div className={props.valorAgraficar === null ? "show rDiv-centrar-grafica" : "hide"}>
                    <div>
                      <img src={iconoGrafica} className="rGrafica-null"
                           alt="imagen_grafica"/>
                      <br/><br/>
                      <span className="rTexto-null">No hay datos</span>
                    </div>
                  </div>
                <div className={props.valorAgraficar !== null ? "show rDiv-centrar-grafica" : "hide"}>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div id="rangos"
                      className={props.tipoGrafica === "bar" && props.preguntasDeRango ? "show" : "hide"}>
                    <VictoryChart domainPadding={20} minDomain={{ x: 0 }}
                        theme={VictoryTheme.material}
                        >
                      <VictoryGroup horizontal
                        offset={10}
                        style={{ data: { width: 5 } }}
                        colorScale={colores}>
                          {
                            props.valorAgraficar &&
                            props.valorAgraficar.map((elemento, index) => (
                              <VictoryBar horizontal
                                  barWidth={7}
                                  alignment="middle"
                                  cornerRadius={{top: 3.5}}
                                  barRatio={0.9}
                                  style={{ labels: { display: "none" } }}
                                  data={elemento.data}
                                  key={index}
                                  labelComponent={
                                    <VictoryTooltip
                                      style={{ fill: "tomato" }}
                                    />
                                  }
                              />
                            ))
                          }
                      </VictoryGroup>
                    </VictoryChart>
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div>
                          <ul className="listaCota">
                          {
                            props.valorAgraficar &&
                            props.valorAgraficar.map((elemento, index) => (
                                <li key={index}>
                                  <div style={{'backgroundColor': colores[index],'width': '10px',
                                     'height': '10px','display': 'inline-block'}}></div>
                                  &nbsp;{elemento.label}
                                  <ul className="segundoUl">
                                    {elemento.data && elemento.data.map((element, i) => (
                                    <li>
                                    {element.x}.-&nbsp;{element.label}&nbsp;{element.y}
                                    </li>
                                    ))}
                                  </ul>
                                </li>
                            ))
                            }
                          </ul>
                        </div>
                      </Col>
                  </Row>
                  </div>
                  <div id="barras"
                      className={props.tipoGrafica === "bar" && !props.preguntasDeRango ? "show" : "hide"}>
                    <VictoryChart domainPadding={20} minDomain={{ x: 0 }}
                        theme={VictoryTheme.material}
                        >
                      <VictoryGroup horizontal
                        offset={0}
                        style={{ data: { width: 10 } }}
                        colorScale={colores}>
                          {
                           props.valorAgraficar &&
                          props.valorAgraficar.map((elemento, index) => (
                            <VictoryBar horizontal
                                barWidth={20}
                                alignment="middle"
                                cornerRadius={{top: 3.5}}
                                barRatio={0.9}
                                style={{ labels: { display: "none" } }}
                                data={[elemento]}
                                key={index}
                                labelComponent={
                                  <VictoryTooltip
                                    style={{ fill: "tomato" }}
                                  />
                                }
                            />
                          ))
                          }
                      </VictoryGroup>
                    </VictoryChart>
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div>
                          <ul className="listaCota">
                          {
                            props.valorAgraficar &&
                            props.valorAgraficar.map((elemento, index) => (
                                <li key={index}>
                                  <div style={{'backgroundColor': colores[index],'width': '10px',
                                     'height': '10px','display': 'inline-block'}}></div>
                                  &nbsp;{elemento.label} &nbsp;{elemento.y}
                                </li>
                            ))
                            }
                          </ul>
                        </div>
                      </Col>
                  </Row>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div id="area" className={props.tipoGrafica === "area" ? "show" : "hide"}>
                  <VictoryChart  theme={VictoryTheme.material}>
                  <VictoryStack colorScale={colores}>
                      {
                      props.valorAgraficar &&
                      props.valorAgraficar.map((elemento, index) => (
                        <VictoryGroup
                          data={[elemento]} key={index}>
                        <VictoryArea style={{ labels: { display: "none" } }}/>
                        <VictoryPortal>
                        <VictoryScatter
                          style={{ data: { fill: 'black' },labels: { display: "none" } }}
                        />
                      </VictoryPortal>
                    </VictoryGroup>
                      ))
                      }
                      </VictoryStack>
                    </VictoryChart>
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div>
                          <ul className="listaCota">
                          {
                            props.valorAgraficar &&
                            props.valorAgraficar.map((elemento, index) => (
                                <li key={index}>
                                  <div style={{'backgroundColor': colores[index],'width': '10px',
                                     'height': '10px','display': 'inline-block'}}></div>
                                  &nbsp;{elemento.label} &nbsp;{elemento.y}
                                </li>
                            ))
                            }
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div id="circular" className={props.tipoGrafica === "pie" ? "show" : "hide"}>
                  <svg style={{width:'200px', height:"120px"}}>
                          <circle cx={100} cy={100} r={80} fill='#FFFFFF'/>
                          <VictoryPie
                              standalone={false}
                              radius={55}
                              origin={{ x: 100, y: 100 }}
                              startAngle={90}
                              endAngle={-90}
                              innerRadius={100}
                              cornerRadius={0}
                              colorScale={colores}
                              data={props.valorAgraficar && props.valorAgraficar}
                          />
                      </svg>
                      <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <div>
                            <ul className="listaCota">
                            {
                              props.valorAgraficar &&
                              props.valorAgraficar.map((elemento, index) => (
                                  <li key={index}>
                                    <div style={{'backgroundColor': colores[index],'width': '10px',
                                       'height': '10px','display': 'inline-block'}}></div>
                                    &nbsp;{elemento.label} &nbsp;{elemento.y}
                                  </li>
                              ))
                              }
                            </ul>
                          </div>
                        </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

            </div>
        </div>
    )

}

export default VGraficas;
