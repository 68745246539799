import React, {useState, useEffect} from 'react';
import {Button, Table,Row, Col, Input, Layout , Form, Modal, Pagination, Card, Select, Tooltip,Alert,Tabs,Radio, Popover, message,Menu, Dropdown,Space,DatePicker,TimePicker} from 'antd';
import { TeamOutlined, EditOutlined, EyeOutlined, PlusCircleOutlined, CloseCircleOutlined, 
  SearchOutlined, QuestionCircleFilled, FilterOutlined,  FrownOutlined, HomeFilled,DownOutlined, UserOutlined} from '@ant-design/icons';
import * as etiquetasAsigZore from '../../../constants/EtiquetasAsigZores';
import 'antd/dist/antd.css';
import "../../../css/asistenciaCapacitacion/asistenciaCapacitacion.css";
import editAsignacionZore from '../../../assets/img/editAsignacionZore.svg';
import asignarAsignacionZore from '../../../assets/img/asignarAsignacionZore.svg';
import iconoHome from '../../../assets/img/iconoHome.svg';
import iconoAsignaZorePlus from '../../../assets/img/iconoAsignaZorePlus.svg';
import iconoAsignaZoreCancel from '../../../assets/img/iconoAsignaZoreCancel.svg';
import iconoFiltro from '../../../assets/img/icono_filter.png';
import errorModal from '../../../assets/img/errorModal.png';
import AsistenciaRegistrada from '../../../assets/img/AsistenciaRegistrada.svg';
import iconoMostrar from '../../../assets/img/icono_mostrar_gafete.png';
import iconoEditar from '../../../assets/img/asignarAsistencia.svg';
import iconoEliminar from '../../../assets/img/icono_eliminar.png'
import iconoBasura from '../../../assets/img/icono_basura.png';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';
import 'moment/locale/es';

const { Option } = Select;
const icon = <CloseCircleOutlined />;

const VAsistenciaCapacitacion = (props) =>{
  const [formAsignacion] = Form.useForm();
  const [searClave, setSearchClave] = useState("");
  const [searFolio, setSearchFolio] = useState("");
  const [searMunicipio, setSearchMunicipio] = useState("");
  const [searNombreVoluntario, setSearchNombreVoluntario] = useState("");
  const [searFolioVol, setSearchFolioVol] = useState("");
  const [searTipoSede, setSearchTipoSede] = useState("");
  
  const handleSearchClickClave= ()=>{setSearchClave("");}
  const handleSearchClickFolio = ()=>{setSearchFolio("");}
  const handleSearchClickMunicipio= ()=>{setSearchMunicipio("");}
  const handleSearchClickVoluntario = ()=>{setSearchNombreVoluntario("");}
  const handleSearchClickFolioVoluntario = ()=>{setSearchFolioVol("");}
const busquedaTipoSede = ()=>{setSearchTipoSede("");}

const columns = [
    {
      title: (
        <div style={{ textAlign: 'center' }}>
            <div>Folio </div>
            <br />
            <Input 
              className="InputFilter"
              value={searFolio} 
              onChange={({target:{ value}}) => setSearchFolio(value)}
              placeholder="Folio" style={{width: "80px"}}
              suffix = {<img style={{width:12 , height:12}} src={iconoFiltro}/>}
              onKeyPress={(ev) => {if (ev.key === 'Enter') {props.busquedaFolio(searFolio)}}}   
            />
        </div>
      ),
      align: 'center',
      dataIndex: 'folio',
      key: 'folio'
    },
    {
      title: (
        <div style={{ textAlign: 'center' }}>
            <div>Persona voluntaria</div>
            <br />
            <Input 
              className="InputFilter"
              value={searNombreVoluntario} 
              onChange={({target:{ value}}) => setSearchNombreVoluntario(value)}
              placeholder="Persona voluntaria" style={{width: "80px"}}
              suffix = {<img style={{width:12 , height:12}} src={iconoFiltro}/>}
              onKeyPress={(ev) => {if (ev.key === 'Enter') {props.busquedaVoluntario(searNombreVoluntario)}}}   
            />
        </div>
      ),
      align: 'center',
      dataIndex: 'nombreVoluntario',
      key: 'nombreVoluntario',
    },
    {
      title: (
        <div style={{ textAlign: 'center' }}>
            <div>Municipio/alcaldía</div>
            <br />
            <Input 
              className="InputFilter"
              value={searMunicipio} 
              onChange={({target:{ value}}) => setSearchMunicipio(value)}
              placeholder="Municipio" style={{width: "80px"}}
              suffix = {<img style={{width:12 , height:12}} src={iconoFiltro}/>}
              onKeyPress={(ev) => {if (ev.key === 'Enter') {props.busquedaMunicipio(searMunicipio)}}}   
            />
        </div>
      ),
      align: 'center',
      dataIndex: 'nombreMunicipio',
      key: 'nombreMunicipio',
    },
    {
      title: (
        <div style={{ textAlign: 'center' }}>
            <div>Clave de elector</div>
            <br />
            <Input 
              className="InputFilter"
              value={searClave} 
              onChange={({target:{ value}}) => setSearchClave(value)}
              placeholder="Clave" style={{width: "80px"}}
              suffix = {<img style={{width:12 , height:12}} src={iconoFiltro}/>}
              onKeyPress={(ev) => {if (ev.key === 'Enter') {props.busquedaClave(searClave)}}}   
            />
        </div>
      ),
      align: 'center',
      dataIndex: 'clave',
      key: 'clave',
    },
    {
      title: (
        <div style={{ textAlign: 'center' }}>
            <div>Nº de asistencias</div>
            <br/>
        </div>
      ),
      align: 'center',
      dataIndex: 'asistencias',
      key: 'asistencias'
    },
    {
      title: (
        <div style={{ textAlign: 'center' }}>
            <div>Acciones</div>
            <br/>
        </div>
      ),
      align: 'center',
      dataIndex: 'acciones',
      render: (fila, record) => (<p onClick={()=>props.recuperaDatos(record)}><Tooltip title='Consultar'><a onClick={()=>{props.obtenerinfoCapacitaciones(record)}}><img style={{width:21 , height:13}} src={iconoMostrar} id="edit-icon"/></a></Tooltip>&nbsp;
        <Tooltip title='Registrar asistencia'>
        <Popover
        placement="bottomLeft" 
        title="Registrar asistencia"
        content={
          <div style={{height:"300px", width:"300px"}}>
          <div Grid item span={24}  style={{height:"10px", width:"100%", textAlign: "center"}}>
            <div  style={{float: "left"}}>
              <th style={{textAlign:"left"}}>Tipo de sede
              <br/>
              <Select defaultValue="props.busquedaPorTipoSede"
              style={{ width:"300px", marginBottom:"30px"}} 
              value={props.busquedaPorTipoSede} 
              onChange={props.busquedaTipoSede}>
                <Option value="">Selecciona</Option>
                <Option value="V">Virtual</Option>
                <Option value="P">Presencial</Option>
              </Select>
              </th>
            </div><br/><br/>
          </div>
          <div Grid item span={24}  style={{height:"10px", width:"100%", textAlign: "center"}}>
            <div style={{float: "left"}}>
              <th style={{textAlign:"left"}} >Sede
              <br/>
              <Select defaultValue="" 
              onChange={props.busquedaSede} 
              value={props.idSede}  
              style={{ width:"300px", marginBottom:"30px"}}>
              <Option value="">Selecciona</Option>
              { props.infoSedesByTipo.map((value, index) =>{
                if(props.busquedaPorTipoSede==="P"){
                return(                           
                <Option value={value.idSede}>                                
                  <tr key={index} >                                                                                        
                    {value.lugar}                                            
                    </tr>
                </Option>)}
                if(props.busquedaPorTipoSede==="V"){
                return(                           
                <Option value={value.idSede}>                                
                  <tr key={index} >                                                                                        
                    Sede {value.idSede}                                            
                    </tr>
                </Option>)}
              })}                                                                          
              </Select>
              </th>
            </div><br/><br/>
            </div>
            <div Grid item xs={24}  style={{height:"20px", width:"305px", textAlign: "center"}}>
            <div style={{float: "left"}}>
              <th style={{textAlign:"left"}}>
                Fecha <br/>
              <DatePicker format="YYYY-MM-DD"
               disabledTime="false"
                onChange={props.cambiarFecha}
                style={{ width:"149px", marginBottom:"30px"}}/>
              </th>
            </div>
            <div style={{float:"right"}} >
              <th style={{textAlign:"left"}}>
                Hora <br/>
              <TimePicker format="HH:mm"
               disabledTime="false"
                onChange={props.cambiarHora}
                style={{ width:"149px", marginBottom:"30px"}}/>
                </th>
            </div>
          </div><br/><br/>
          <p style={{textAlign:"center"}}>
            <Button 
              type="button"
               style={{ marginBottom: 5,marginTop: 5}} 
               id="guardar-asistencia"
               onClick={props.asignarVoluntarioCapacitacion}
               disabled={props.banderaBotonGuardar}>Registrar</Button>
            </p> 
        </div> 
        }
        trigger="click"><a><img style={{width:16 , height:16}} src={iconoEditar} id="deleted-icon"/></a>
        </Popover>
        </Tooltip>
      </p>)
    }
  ];


        return(          
            <Layout className='validar_modulo'>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <span className="tituloTable">
                          Asistencia a capacitación
                  </span>
                  <br></br><br></br>
                </Col>
             </Row>
              <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Alert icon={icon}
                   message={props.mensajeAlerta}
                   type={props.tipoAlerta} showIcon
                   style ={props.mostrarAlerta}/> 
              </Col>
              </Row>
            <div className="" style={{marginTop:"30px"}}>
              <div id="icon-asignar" style={{display: props.ocultarCapacitacion ? "none" : "block"}}>
                
                <Row>
                  <Col span={24}>
                  <div style={{ textAlign: 'center' }}>
                  <span className="tituloTable">
                     Listado de personas voluntarias registradas 
                     <Tooltip title='Selecciona a una persona voluntaria para consultar y registrar las asistencias a las capacitaciones.'>&nbsp;
                      <QuestionCircleFilled/>
                    </Tooltip>
                  </span>
                  </div>
                 </Col>
                </Row>
                <br></br>
                <Table columns={columns} dataSource={props.infoCapacitacionesVoluntarios} pagination={false} size="large" />
                <div id="pagina">
                  <Row justify="center">
                    <Col xs={24} sm={24} md={{span:1, offset:4}} lg={{span:1, offset:4}} xl={{span:1, offset:4}}>
                        <HomeFilled onClick={props.regresarPrimeraPagina} />
                     </Col>
                     <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                     
                        <Pagination current={props.actual}
                                    onChange={props.paginado} 
                                    total={props.paginas}
                                    defaultPageSize={5}
                                    loading={props.cargando}
                                    size="small"
                                    showSizeChanger={false} />
                    </Col>
                  </Row>
                </div>                                              
                </div>
                <div style={{display: props.ocultarVoluntarios ? "none" : "block",width:"100%", height:"100%"}}>
                  <Row>
                    <Col span={24}>
                    <span className="subTitulo">
                           {props.nombreVoluntarioSelect}
                      </span>
                      <br></br>
                       <span 
                          className ="subTitulo">
                          <strong>Folio: </strong>{props.folioSelect}
                        </span>   
                        </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <span className="">
                           Nº de asistencias: {props.infoCapacitaciones.length}
                      </span>
                      <hr/>
                    </Col>
                  </Row>
                  <Row>  
                      <Col flex="auto">
                      {!props.infoCapacitaciones.length ? "No hay registros" : props.infoCapacitaciones.map((value, index) =>{
                                        return( 
                              <div className="table-responsive">
                              <table className="tableHeadCapacitacion">
                                <thead>
                                  <tr>
                                  <th scope="col">
                                    &nbsp;Capacitación {index+1}
                                  </th>
                                  </tr>
                                </thead>
                              </table>
                              <table className="tableStyleVoluntarios1">
                                <thead>
                                  <tr>
                                    <th scope="col">Tipo de capacitación</th>
                                    <th scope="col">{value.tipoSede==="V"?"URL":"Lugar de la sede"}</th>
                                    <th scope="col">Fecha</th>
                                    <th scope="col">Persona capacitadora</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                                                       
                                          <tr key={index} >
                                            <td>{value.tipoSede==="V"?"Virtual":"Presencial"}</td>
                                            <td>{value.lugar}</td>
                                            <td>{value.fechaCapacitacion}</td>
                                            <td>{value.capacitador}</td>
                                          </tr>                                    
                                                      
                                   </tbody>
                              </table>
                              </div>
                             )
                                    })}  
                          <div id="pagina">
                            <Row justify="center">
                              <Col xs={24} sm={24} md={{span:1, offset:4}} lg={{span:1, offset:4}} xl={{span:1, offset:4}}>
                                <br/><HomeFilled onClick={props.regresarPrimeraPagina} />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                    </Row>    
                  </div>
                    <div>
                </div>
              <Modal visible={props.isModalVisibleAviso}
                        width={335}
                        height={50}
                        onCancel={props.handleCancel}
                        footer={[null]}
                        centered>
                          <div id="folio-casilla"> 
                            <p className="titulo-modal">El voluntario no tiene asistencias registradas</p>
                            <Button type="button" id="guardar-zor" style={{ background: "#D4217F", borderColor: "#ffffff" }} onClick={props.handleCancel}>Aceptar</Button>
                          </div>           
                        </Modal>
              <Modal visible={props.isModalVisibleDatosGuradados}
                        width={335}
                        title={<img src={AsistenciaRegistrada} id="img-modal"/>}
                        onCancel={props.handleCancel}
                        footer={[null]}
                        centered>
                          <div id="folio-casilla"> 
                            <p className="titulo-modal">Asistencia registrada</p>  
                            <p className="notificacion-modal">El comprobante se envió al correo electrónico registrado.</p>
                            <Button type="button" id="guardar-zor" style={{ background: "#D4217F", borderColor: "#ffffff" }} onClick={props.regresarPrimeraPagina}>Aceptar</Button>
                          </div>           
                        </Modal>
              </div>
         </Layout>                 
        );
      }

export default VAsistenciaCapacitacion;