import React from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import AuthenticatedComponent from '../../components/comunes/AuthenticatedComponent';
import CTemplate from '../homeInterno/CTemplate';
import VConvocatoriasConsulta from '../../components/convocatorias/VConvocatoriasConsulta';
import { createStructuredSelector } from 'reselect';
import { selectDistrito, selectEstado } from '../../redux/menu/menu-selectors';
import { selectCurrentUser } from '../../redux/user/user-selectors';
import {obtenerEstatusModulo} from '../../redux/menu/menu-utils';
import * as etiquetas from '../../constants/EtiquetasGenerales';
import * as etiquetasConvocatorias from '../../constants/EtiquetasConvocatorias';
import * as info from '../../constants/InfoSistema';
import moment, { isMoment } from 'moment';
import 'moment/locale/es';
import { roles_permitidos_convocatorias } from '../../constants/EtiquetasConvocatorias';


let msjError = "";

class CConvocatoriasConsulta extends React.Component{

  

    constructor(props){
        super(props);

        this.state={

          listConvocatorias:[],
          selectConvocatoria: null,
          fechaInicio:null,
          fechaTermino:null,
          cantidadCarteles:null,
          observaciones:'',
          idParticipacion: null,
          idConvocatoria: null,
          cantidadCarteles: 0,
          numeroConvocatoria:null,
          observaciones: '',
          abrirModificaExito: false,
          estatusFecha:false,
          usuarioCau:false,
          searchNumeroConvocatoria:null,
          estatus:false,
          modifica:false,
          mostrar:false,
          convocatoriaSeleccionadaModal:null,
          actual:1,
          total:0,
          cargando: false,
          rolPermitido:false,

        }

    }

    componentDidMount(){

      this.consultarEstatusModulo();
      this.consultarPermisoUsuario();
  
    }

    async componentDidUpdate(prevProps) {
      if (this.props.estado !== prevProps.estado
        || this.props.distrito !== prevProps.distrito) {
          this.enviarConsulta();
      }
    }

    consultarPermisoUsuario = () =>{
      this.setState({
        rolPermitido : roles_permitidos_convocatorias.includes(this.props.user.rolUsuario)
      }, ()=>console.log(this.state.rolPermitido));
    }


    consultarEstatusModulo () {

      var idDetalleProceso = this.props.user.idDetalle;
      var idProcesoElectoral = this.props.user.idProcesoElectoral;
      var idEstado = this.props.estado.idEstado;
      var idDistrito = this.props.distrito.idDistrito;
      var idSistema = info.ID_SISTEMA;
      var idModulo = this.props.location.state !== undefined ?
                     this.props.location.state.idModulo : 0;
      var grupoSistema = this.props.user.rolUsuario;

      var index = grupoSistema.search("CAU.OC");

      if(index >= 0){
        this.setState({
          usuarioCau:true
        }, ()=>console.log(true))
      }else{
        console.log("tiene otro tipo de rol")
      }
    
      obtenerEstatusModulo(idProcesoElectoral, idDetalleProceso,idSistema,
        idEstado, idDistrito, idModulo, grupoSistema).then(respuesta =>{
        console.info("El estatus del módulo Consulta es: ",respuesta);
        if(respuesta === "A"){
          console.info("El Módulo consulta convocatoria está abierto ");
          this.enviarConsulta();
          this.setState({
            estatus:true
          }, ()=>console.log(true))
        }else{
          console.info("El Módulo consulta convocatoria está cerrado ");
        }
      })
    
    }

    mostrarModal = (record) => {
      this.setState({
        mostrar: true,
        convocatoriaSeleccionadaModal: record
      })
    };
    
    ocultarModal = () => {
      this.setState({
        mostrar: false
      })
    };

    searchNumeroConvocatoria = (valor) => {
      if(valor != null){
        this.state.searchNumeroConvocatoria = parseInt(valor,10);
      }else{
        this.state.searchNumeroConvocatoria = null;
      }
      this.enviarConsulta();
    }

    selectConvocatoria = (valor) => {
      if(valor != null){
  
        this.state.selectConvocatoria = valor;
  
      }else{
        this.state.selectConvocatoria = null;
      }
      
    }

    enviarConsulta = (inicio=1,fin=5) => {

      var dataJson = {
            idDetalleProceso:info.ID_DETALLE_PROCESO,
            idProcesoElectoral:info.ID_PROCESO_ELECTORAL,
            idEstado: this.props.estado.idEstado,
            idDistrito: this.props.distrito.idDistrito,
            inicio: inicio,
            fin: fin,
            numeroConvocatoria:this.state.searchNumeroConvocatoria
        }
      
        console.log(dataJson);

        axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + 'ws/consultaConvocatoria', dataJson,
        {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': this.props.user ? this.props.user.tknJWT : null
            }
        }).then(res => {
            const convocatorias = res.data.consultaConvocatoria;
            this.setState({
              listConvocatorias: convocatorias,
              total: res.data.totalConvocatorias,
              cargando: false,
            });
        }).catch(error => {
           console.log("Error al obtener las convocatorias", error);
        });
    }



    modificaConvocatoria = (convocatoria) => {

      console.log("Entre para obtener la informacion de la convocatoria que deseo modificar");

      var fechaI = convocatoria.fechaRegistroI
      var fechaT = convocatoria.fechaRegistroF
      var numCarteles = convocatoria.cantidadCarteles
      var observar = convocatoria.observaciones
      var idParticipacion = convocatoria.idParticipacion
      var idConvocatoria = convocatoria.idConvocatoria
      var numeroConvocatoria = convocatoria.numeroConvocatoria

      this.setState({
        fechaInicio: fechaI,
        fechaTermino: fechaT,
        cantidadCarteles: numCarteles,
        observaciones: observar,
        idParticipacion: idParticipacion,
        idConvocatoria: idConvocatoria,
        numeroConvocatoria: numeroConvocatoria,
        modifica:true
      },()=>console.log(this.state.modifica));

    }

    enviarModificacion = () => {
      let convocatoria = {

          idDetalleProceso:info.ID_DETALLE_PROCESO,
          idProcesoElectoral:info.ID_PROCESO_ELECTORAL,
          idParticipacion: this.state.idParticipacion,
          idConvocatoria: this.state.idConvocatoria,
          fechaInicio: this.state.fechaInicio,
          fechaTermino: this.state.fechaTermino,
          observaciones: this.state.observaciones,
          cantidadCarteles: this.state.cantidadCarteles,
          usuario: this.props.user.username,
      };
    
      console.log(convocatoria);
    
      const headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': this.props.user ? this.props.user.tknJWT : null
      };
    
      axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL +
              "ws/modificaConvocatoria"
          , convocatoria
          , {
              headers
          }
      ).then(_response => {
          this.setState({abrirModificaExito: true})
      }).catch(error => {
          this.setState({cargando: false});
          console.error("Error al modificar la convocatoria "+
              "enviarModificacion()",error);
          if (error.response){
              if (error.response.status === 409){
                  msjError = error.response.data.message;
              } else if (error.response.data && error.response.data.causa
                  && error.response.data.causa.includes("Error de validación")){
                  msjError = error.response.data.message;
              } else {
                  msjError = etiquetas.gral_error_cau;
              }
          } else {
              msjError = etiquetas.gral_error_cau;
          }
          this.setState({
            msjError : msjError,
            abrirModalError : true
          });
      });
    }

    onChangeFI = (dateString) => {
      this.setState({fechaInicio: dateString},()=>{console.log(this.state.fechaInicio)
        this.revisarFecha();})
      
    }

    onChangeFT = (dateString) => {
      this.setState({fechaTermino: dateString},()=>{console.log(this.state.fechaTermino)
        this.revisarFecha()})
  
    }

    changeC = (e) =>{
      this.setState({
          cantidadCarteles: e
      }, ()=>console.log(this.state.cantidadCarteles))
    }

    cambioText = (e) =>{
      this.setState({
          [e.target.name]: e.target.value
      })
    }

    onChangeRoute =()=>{
      console.log("Entre a cambio de ruta")
      this.setState({abrirModificaExito: false});
      this.props.history.push('/modulo/2');
      
    }

    revisarFecha = () =>{

      let var1 = this.state.fechaInicio;
      let var2 = this.state.fechaTermino;

        var mes1 = moment(var1, 'DD/MMMM/YYYY').month(); //Inicio
        var mes2 = moment(var2, 'DD/MMMM/YYYY').month(); //Termino
        var dia1 = moment(var1, 'DD/MMMM/YYYY').date();
        var dia2 = moment(var2, 'DD/MMMM/YYYY').date();


        if(mes1 <= mes2 ){
          if(mes1 === mes2){
            if(dia1 > dia2){
              this.setState({
                estatusFecha: true
              }, ()=>console.log(this.state.estatusFecha));
            }else{
              this.setState({
                estatusFecha: false
              }, ()=>console.log(this.state.estatusFecha));
            }
          }

          if(mes1 < mes2){
            this.setState({
              estatusFecha: false
            }, ()=>console.log(this.state.estatusFecha));
          }

        }else{
          this.setState({
            estatusFecha: true
          }, ()=>console.log(this.state.estatusFecha))
        }

    }

    paginado = pagina =>{
      console.log("la pagina es", pagina);
      var paginaAnterior = parseInt(pagina,10) - 1 === 0 ? 1 :
                                                          parseInt(pagina,10) - 1;
      this.setState({
        actual : pagina,
        cargando: true,
        listConvocatorias: []
      });
    
      var inicio = parseInt(pagina,10) === 1 ? 1 : (paginaAnterior * 5)+1;
      var fin = parseInt(pagina,10) * 5;
    
      this.enviarConsulta(inicio,fin);
    }

    regresarPrimeraPagina = () =>{
      this.setState({
        paginaActual : 1,
        actual: 1
      });
      this.enviarConsulta();
    }

            
    render(){
        return(
          <>
            <AuthenticatedComponent>
              <CTemplate
                bloqueado = {false}
                contenido ={
                  <>
                    <VConvocatoriasConsulta
                        convocatorias = {this.state.listConvocatorias}
                        selectConvocatoria = {this.selectConvocatoria}
                        modificaConvocatoria = {this.modificaConvocatoria}
                        fechaInicio = {this.state.fechaInicio}
                        fechaTermino = {this.state.fechaTermino}
                        numeroCarteles = {this.state.cantidadCarteles}
                        observaciones = {this.state.observaciones}
                        numeroConvocatoria = {this.state.numeroConvocatoria}
                        enviarModificacion = {this.enviarModificacion}
                        onChangeFI = {this.onChangeFI}
                        onChangeFT = {this.onChangeFT}
                        changeC = {this.changeC}
                        cambioText = {this.cambioText}
                        abrirModificaExito = {this.state.abrirModificaExito}
                        onChangeRoute = {this.onChangeRoute}
                        estatusFecha = {this.state.estatusFecha}
                        rolCau = {this.state.usuarioCau}
                        searchNumeroConvocatoria = {this.searchNumeroConvocatoria}
                        estatusModulo = {this.state.estatus}
                        modificar = {this.state.modifica}
                        mostrarConsulta = {this.state.mostrar}
                        ocultarModal = {this.ocultarModal}
                        mostrarModal = {this.mostrarModal}
                        convocatoriaSeleccionadaModal = {this.state.convocatoriaSeleccionadaModal}
                        regresarPrimeraPagina = {this.regresarPrimeraPagina}
                        actual = {this.state.actual}
                        paginado = {this.paginado}
                        total = {this.state.total}
                        cargando = {this.state.cargando}
                        rolPermitido ={this.state.rolPermitido}

                    />
                  </>
                }/>
            </AuthenticatedComponent>
          </>

        )
    }
                
}

const mapStateToProps = createStructuredSelector({

  estado: selectEstado,
  distrito: selectDistrito,
  user: selectCurrentUser

})

export default connect(mapStateToProps)(CConvocatoriasConsulta);