

export const WS_CENTRAL = "/ws-cij-central_v1.0/";
export const WS_CASILLAS = "/ws-cij-casillas_v1.0/";

export const general_btn_aceptar     = "Aceptar";
export const general_btn_cancelar    = "Cancelar";
export const general_dato_requerido  = "Dato requerido";
export const general_formato_invalido = "Formato inválido";
export const general_telefono_requerido = "Ingrese al menos un número telefónico";
export const general_experiencia_laboral = "*Tu experiencia profesional nos ayuda a conocer tu perfil,captura lo que consideres necesario.";
export const general_telefono_requerido_leyenda = "*Al menos uno de los dos teléfonos es requerido";
export const general_btn_guardar      = "Guardar";
export const general_btn_buscar       = "Buscar"

export const gral_no_convo_activa_seccion = "No existe convocatoria activa en la sección.";
export const gral_descarga_compulsa_inter = "La descarga de compulsa fue interrumpida. Por favor inténtalo más tarde.";
export const gral_serivicio_no_disponible = "Servicio no disponible. Por favor inténtalo más tarde.";
export const gral_no_existe_clave_elector = "Los datos ingresados no se encuentran actualizados en lista nominal acude a JD.";
export const gral_correos_diferentes = "Los correos no son iguales.";
export const gral_sin_resultados = "Sin resultados para mostrar. Prueba con diferentes palabras."
export const gral_sin_registro_aspirantes  = "Aún no se cuenta con registro de aspirantes."
export const gral_no_participacion = "Sin participación geográfica."
export const gral_no_existe_convocatoria = "No existe convocatoria activa en la sección.";
export const gral_observacion_documentos="Encontramos observaciones en tus documentos, cárgalos de nuevo."
export const gral_archivos_permitidos = "Solo se permiten los formatos PDF, JPG y  PNG.";
export const gral_peso_archivo = " debe ser menor a 1.5 MB";
export const gral_validando_documentos = "Validación de documentos en proceso.";
export const gral_documentos_validados = "Tus documentos fueron validados.";
export const gral_correo_confirmacion = "En breve recibirás un correo electrónico de confirmación.";
export const gral_solicitud_declinada = "Tu solicitud ha sido declinada, para mayor información comunícate a la Junta Distrital";

export const gral_error_cau = "El sistema no pudo realizar la acción solicitada, favor de comunicarse al CAU.";
export const gral_error_menor_edad = "El aspirante debe tener más de 18 años.";
export const gral_error_mayor_edad = "El aspirante debe tener menos de 60 años.";
export const gral_error_descargar_compulsa = "Ocurrió un error al descargar la compulsa.";
export const gral_error_nombre_archivo = "El nombre del documento no debe contener caracteres especiales.";
export const gral_error_descarga_acuse_capsula = "Ocurrió un error al descargar el acuse de la cápsula.";
export const gral_error_sede_examen = "Ocurrió un error al obtener la sede del examen.";
export const gral_error_guia_estudio = "Ocurrió un error al descargar la guia de estudio.";
export const gral_error_platicas = "Ocurrió un error al obtener la programación de pláticas.";
export const gral_error_no_hay_platicas = "Aún no hay pláticas programadas. Consulta el listado con la JDE.";
export const gral_error_reenvio = "Ocurrió un error al enviar las notificaciones.";
export const gral_error_no_hay_correo = "El aspirante no cuenta con correo electrónico registrado.";
export const gral_error_contacto = "Ocurrió un error al obtener los datos de contacto de la Junta Distrital.";
export const gral_error_fecha_fuera_rango = "Fecha fuera del periodo valido de la convocatoria.";
export const gral_error_administrador = "Ocurrio un error al actualizar el registro.";
