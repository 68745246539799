import axios from 'axios';
import {
	ERROR_BITACORA_JSON,
	GUARDAR_BITACORA_JSON,
	INICIAR_OPERACION_BITACORA_JSON,
	TERMINAR_OPERACION_BITACORA_JSON
} from './bitacoraJson-types';
import * as Etiquetas from '../../constants/EtiquetasGenerales';

export const guardarBitacoraJson = () => async (dispatch, store) => {
	try {
		dispatch({
			type: INICIAR_OPERACION_BITACORA_JSON
		});

		const response = await axios({
			url: `${process.env.REACT_APP_API_URL}${Etiquetas.WS_CENTRAL}ws/guardarBitacoraJson`,
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				Authorization: `${store().user.currentUser ? store().user.currentUser.tknJWT : null}`
			}
		});

		dispatch({
			type: GUARDAR_BITACORA_JSON,
			payload: {
				status: 1,
				message: 'Se cargaron los archivos correctamente.'
			}
		});
	} catch(error) {
		// console.error(error);
		dispatch({
			type: ERROR_BITACORA_JSON,
			payload: {
				status: 0,
				message: 'Ocurrió un error al procesar la información.'
			}
		});
	} finally {
		dispatch({
			type: TERMINAR_OPERACION_BITACORA_JSON
		});
	}
};