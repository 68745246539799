import MenuActionTypes from './menu-types';

const INITIAL_STATE = {
  subMenus: null,
  listaEstados: null,
  listaDistritos: null,
  estado: {
    idEstado: 0,
    nombreEstado: ''
  },
  distrito: {
    idDistrito: 0,
    nombreDistrito: ''
  }
};

const menuReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MenuActionTypes.UPDATE_MENU:
      return {
        ...state,
        subMenus: action.payload,
      };
    case MenuActionTypes.UPDATE_LISTA_ESTADOS:
      return {
        ...state,
        listaEstados: action.payload,
      };
      case MenuActionTypes.UPDATE_LISTA_DISTRITOS:
    return {
      ...state,
      listaDistritos: action.payload,
    };  
    case MenuActionTypes.UPDATE_ESTADO:
      return {
        ...state,
        estado: action.payload
      };
    case MenuActionTypes.UPDATE_DISTRITO:
      return {
        ...state,
        distrito: action.payload
      };
    default:
      return state;
  }
};

export default menuReducer;