import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons'
import VCotas from './VCotas';
import VExportar from './VExportar';
import VFiltros from './VFiltros';
import VTabla from './VTabla';
import * as etiquetas from '../../constants/EtiquetasPublicador';
import { obtieneEtiquetaNivel } from '../../utils/publicador';

const handleLinkToNivelAddProps = (proceso, estado, distrito, municipio, handleLinkToNivel, record, nivel) => {
    handleLinkToNivel(nivel, 
                    record.idDetalleProceso ? {
                                                descripcionDetalle: record.descripcionDetalle, 
                                                idDetalleProceso: record.idDetalleProceso 
                                            } : proceso,
                    record.idEstado ? { 
                                        nombreEstado: record.nombreEstado, 
                                        idEstado: record.idEstado 
                                        } : estado,
                    record.idDistrito ? { 
                                            nombreDistrito: `${record.idDistrito}- ${record.nombreDistrito}`, 
                                            idDistrito: record.idDistrito 
                                        } : distrito,
                    record.idMunicipio ? {
                                            nombreMunicipio : record.nombreMunicipio,
                                            idMunicipio: record.idMunicipio
                                        } : municipio);
}

const VReporte = ({ tipoReporte,  proceso, estado, distrito, municipio, reporte, nivelSeleccionado, nivel, 
    corte, listaReportes, error, datosTabla, datosFiltrados, handleChangeTabla, handleLinkToNivel,
    seleccionaReporte, seleccionaNivelReporte, isOpenCotas, handleChangeCotas, 
    isVistaTemporal, handleReturn }) => {

    return(<div className='reporte-container'>
            <div className='rc-filtros'>
                <div />
                <VFiltros  tipoReporte = {tipoReporte}
                        listaReportes = {listaReportes}
                        reporteSeleccionado = {reporte.key}
                        nivelSeleccionado = {nivelSeleccionado}
                        seleccionaReporte = {seleccionaReporte} 
                        seleccionaNivelReporte = {seleccionaNivelReporte} />
                {
                    datosTabla ?
                    <VExportar proceso = {proceso}
                                estado = {estado}
                                distrito = {distrito}
                                municipio = {municipio}
                                reporte = {reporte}
                                nivel = {nivel}
                                cotas = {datosTabla.cotas && datosTabla.cotas.pdf ? 
                                        datosTabla.cotas.pdf 
                                        : []}
                                headerCSV = {datosTabla.header.csv}
                                headerPDF = {datosTabla.header.pdf}
                                datos = {datosFiltrados && datosFiltrados.length > 0 ? 
                                        datosFiltrados 
                                        : datosTabla.datos } />
                    : 
                    <div />
                }
            </div>
            <span className='rc-corte'>{corte? `${etiquetas.ULTIMO_CORTE}${corte.fecha}`:''}</span>
            {
                datosTabla ? 
                <>
                    
                    {
                        datosTabla.cotas 
                        && datosTabla.cotas.html 
                        && datosTabla.cotas.html.length > 0 ?
                        <VCotas cotas={datosTabla.cotas.html}
                                isOpenCotas={isOpenCotas}
                                handleChange={handleChangeCotas}/>
                        : ''
                    }
                    {
                        isVistaTemporal ? 
                        <div className='rc-regresar'
                                onClick={handleReturn}>
                            <ArrowLeftOutlined />{etiquetas.ACCION_REGRESAR}
                        </div>
                        : ''
                    }
                    <div className='rc-reporte'>
                        <span className='rc-reporte-title'>{reporte.label}</span>
                        <span className='rc-reporte-nivel'>{obtieneEtiquetaNivel(estado, distrito, municipio, nivel)}</span>
                    </div>
                    <VTabla tipoReporte = {tipoReporte}
                            header = {datosTabla.header.web}
                            datos = {datosTabla.datos}
                            handleChange = {handleChangeTabla} 
                            handleLinkToNivelAddProps = {handleLinkToNivelAddProps.bind(null, 
                                                                        proceso,
                                                                        estado,
                                                                        distrito,
                                                                        municipio,
                                                                        handleLinkToNivel)} />
                </>
                : error ? 
                    <span className='rc-error'>{error}</span>
                    :
                    <span className='rc-sin-datos'>{etiquetas.NO_HAY_DATOS}</span>
            }
        </div>);
}

export default VReporte;