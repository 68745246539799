
export const tooltip_ayuda_formato = "La documentación escaneada o fotografiada "+
  "debe ser legible, en formato PDF, JPG o PNG. No debe exceder los 15 MB en total.";
export const tootltip_ayuda_ine_frente = "Adjuntar parte frontal de la credencial para votar.";
export const tooltip_ayuda_ine_reverso = "Adjuntar el reverso de la credencial para votar.";
export const tooltip_ayuda_comprobante = "Antigüedad no mayor a 3 meses";
export const tooltip_ayuda_foto = "Fotografía reciente, de frente, "+
                             "cara descubierta, sin accesorios y fondo blanco.";
export const tooltip_btn_consultar = "Consultar";
export const tooltip_btn_eliminar = "Eliminar";
export const tooltip_btn_guardar = "El botón de guardar se habilitará una vez que se completen todos los campos de los submódulos de registro de casillas.";

export const gral_archivos_permitidos = "Los documentos deben estar en formatos .png, .pdf, .jpg";
export const gral_archivos_permitidos_foto = "Los documentos deben estar en formato .png, .jpg.";
export const gral_peso_archivo = "El documento excede el límite de peso 3.5MB";
export const gral_nombre_repetido = "Ya existe un documento con este nombre";
export const gral_nombre_largo = "El nombre del documento es largo";
export const gral_error_cargar = "Error al cargar el documento";
export const gral_error_nombre_archivo = "El nombre del documento no debe"+
                                             " contener caracteres especiales.";

export const indicacion_carga_uno = "Arrastra o suelta tu archivo aquí";
export const indicacion_carga_dos = "Selecciona el archivo";

export const texto_exito = "Persona voluntaria registrada";
export const texto_edicion = "Persona voluntaria modificada";
export const texto_informativo = "El comprobante se envió al correo electrónico registrado";
