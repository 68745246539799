import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import jwtDecode from "jwt-decode";
import { selectCurrentUser } from '../../redux/user/user-selectors';
import { userLogout } from '../../redux/user/user-actions';
import CTemplate from '../../containers/homeInterno/CTemplate';
import CTemplateExt from '../../containers/homeExterno/CTemplateExt';
import {PATH_LOGIN} from '../../constants/routers/paths'

class AuthenticatedComponent extends React.Component {
	
	state = {
		cargado: false
	};

 	componentDidMount() {
		const jwt = this.props.user ? this.props.user.tknJWT : null;

		this.expiroToken(jwt);

 		if (!jwt && this.props.privado) {
			this.props.userLogout();
			 this.props.history.push(PATH_LOGIN);
			 return;
		} else {
			this.setState({
				cargado: true
			}, this.props.listo);
			return;
		}
 	}

	expiroToken = (token) => {
		if(!token) return;

		const seconds = 60;
		const metaToken = jwtDecode(token);//decodifica el token
		const { exp } = metaToken;//Se obtiene el tiempo de vida del token
		const now = (Date.now() + seconds) / 1000;
		if(now > exp) {//verifica que no hay expirado
			this.props.userLogout();
			this.props.history.push(PATH_LOGIN);
		}
	}

 	render() {
		if (!this.state.cargado && this.props.user)
			return <CTemplate guia={false} bloqueado={true}/>;
		else if (!this.state.cargado)
			return <CTemplateExt bloqueado={true} enlaces={false}/>;
 		return this.props.children;
 	}
 }

AuthenticatedComponent.defaultProps = {
	externo: true,
	privado: true,
}

const mapStateToProps = createStructuredSelector({
    user: selectCurrentUser
});

const mapDispatchToProps = dispatch => ({
	userLogout: () => dispatch(userLogout())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthenticatedComponent));
