import React from 'react';
import img_niña from '../../assets/img/home_niña.png';
import img_niño from '../../assets/img/home_niño.png';
import img_indefinido from '../../assets/img/home_indefinido.png';
import * as etiquetas from '../../constants/EtiquetasHomePortal';

const VLabelImage = ({ labelDato, value1, porcentaje, color, imageUrl, text }) => {
    return(
        <div className='home-label-image-container' 
            style={{color:`${color}`}}>
            <div className='hlic-image'>
            { labelDato === 'Niña/Mujer' || imageUrl === 1 ? 
                <img className='hlic-img' src={img_niña} alt='Imagen del tipo' />: 
                labelDato === 'Niño/Hombre' || imageUrl === 2 ? <img className='hlic-img' src={img_niño} alt='Imagen del tipo' /> 
                : labelDato === 'No me identifico' || imageUrl === 3 ? <img className='hlic-img' src={img_indefinido} alt='Imagen del tipo' /> :
                <span className='hlic-text'>{text}</span> } 
                : <span className='hlic-tooltip'>
                    {`${value1.toLocaleString('en-US')} ${etiquetas.home_chart_participantes_tooltip_niños}`}
                </span>
            </div>
            <span className='hlic-label'>{labelDato}</span>
            <span className='hlic-porcentaje'>{porcentaje}%</span>
            
        </div>
    );
}

export default VLabelImage;