import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import {notification,Row,Col} from 'antd';
import CTemplate from '../../homeInterno/CTemplate';
import AuthenticatedComponent from '../../../components/comunes/AuthenticatedComponent';
import VInstalacionCasilla from '../../../components/casillas/seguimiento/VInstalacionCasilla';
import { createStructuredSelector } from 'reselect';
import { selectEstado, selectDistrito } from '../../../redux/menu/menu-selectors';
import { obtenerEstatusModulo } from '../../../redux/menu/menu-utils';
import { selectCurrentUser } from '../../../redux/user/user-selectors';
import { selectCurrentCasilla } from '../../../redux/casillas/casilla-selectors';
import { setCurrentCasilla } from '../../../redux/casillas/casilla-actions';
import { obtenerEstado } from '../../../utils/funciones';
import * as etiquetas from '../../../constants/EtiquetasGenerales';
import * as etiquetasCasillas from '../../../constants/EtiquetasCasillas';
import * as info from '../../../constants/InfoSistema';
import { PATH_SEGUIMIENTO_CASILLA } from '../../../constants/routers/paths';
import iconoDescargaGafete from '../../../assets/img/icono_descarga_gafete.png';

let msjError = "";

class CInstalacionCasilla extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			rolPermitido: false,
			cargando: false,
			tiposCasillas: [],
			nombreEstado: null,
			abrirModalExitoRegistro: false,
			abrirModalError: false,
			msjError: null,
			puedeDescargarActa: false,
			datosCasillasItinerantes: [],
		}

		/* console.info("el usuario con el rol ", this.props.user.rolUsuario,
			"está bloqueado ", !etiquetasCasillas.roles_permitidos_casillas[this.props.user.rolUsuario]); */
	}

	componentDidMount() {
		console.log('Entra en componentDidMount');
		this.consultarEstatusModulo();
		this.consultarPermisoUsuario();
		
		if(this.props.casilla.idTipoCasilla === 4 || this.props.casilla.idTipoCasilla === 5){
			this.consultarCasilla();
		}
	}

	async componentDidUpdate(prevProps) {
		if (this.props.estado !== prevProps.estado || this.props.distrito !== prevProps.distrito) {
			//this.enviarConsulta();
			this.consultaTipoCasillas();
			if(this.props.casilla.idTipoCasilla === 4 || this.props.casilla.idTipoCasilla === 5){
				this.consultarCasilla();
			}
		}
	}

	componentWillUnmount(){
    this.props.setCurrentCasilla(null);
  }

	consultarEstatusModulo() {
		var idProcesoElectoral = this.props.user.idProcesoElectoral;
		var idDetalleProceso = this.props.user.idDetalle;
		var idEstado = this.props.estado.idEstado;
		var idDistrito = this.props.distrito.idDistrito;
		var idSistema = info.ID_SISTEMA;
		var idModulo = this.props.location.state !== undefined ? this.props.location.state.idModulo : 0;
		var grupoSistema = this.props.user.rolUsuario;

		obtenerEstatusModulo(idProcesoElectoral, idDetalleProceso, idSistema, idEstado, idDistrito, idModulo, grupoSistema)
			.then(respuesta => {
				console.info("El estatus del módulo Seguimiento de casillas es: ", respuesta);

				if (respuesta === "A") {
					console.info("El módulo Seguimiento de casillas está abierto ");
					var nombreEstado = obtenerEstado(this.props.estado.idEstado);
					this.setState({ nombreEstado: nombreEstado });
				} else {
					console.info("El módulo Seguimiento de casillas está cerrado ");
				}
			})
	}

	consultarPermisoUsuario = () => {
		this.setState({
			rolPermitido: etiquetasCasillas.roles_permitidos_casillas.includes(this.props.user.rolUsuario)
		})
	}

	consultaTipoCasillas = () => {
		axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + 'ws/tipo_casilla', {}, {
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.props.user ? this.props.user.tknJWT : null
			}
		}).then(res => {
			this.setState({
				tiposCasillas: res.data,
				cargando: false,
			});
		}).catch(error => {
			console.error("Error al obtener la consulta de Casillas", error);
		});
	}

	mostrarModal = (record) => {
		console.log("record", record);
		if (record.nombreFoto !== "") {
			this.obtenerArchivosSubidos(record);
		}
		this.setState({
			mostrar: true,
			voluntariosSeleccionadoModal: record
		})
	};

	ocultarModal = () => {
		this.setState({
			mostrar: false,
			imagenUrlFoto: ""
		})
	};

	abrirNotificacion = (mensaje) => {
		notification.open({
			description: <Row >
				<Col xs={5} sm={4} md={5} lg={5} xl={5} id="div-icono-exito">
					<img src={iconoDescargaGafete} alt="iconoDescarga" id="icono-descarga-exito" />
				</Col>
				<Col xs={19} sm={19} md={19} lg={19} xl={19} id="msj-exito">
					{mensaje}
				</Col>
			</Row>,
			placement: "bottomRight",
			duration: 5,
			style: {width: '300px'}
		});
	}

	consultarCasilla = () => {
		var dataJson = {
			idDetalleProceso: info.ID_DETALLE_PROCESO,
			idProcesoElectoral: info.ID_PROCESO_ELECTORAL,
			idEstado: this.props.estado.idEstado,
			idDistrito: this.props.distrito.idDistrito,
			folio: this.props.casilla.folio,
			moduloCasilla: 2
		}
		axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + 'ws/consulta_casilla', dataJson, {
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.props.user ? this.props.user.tknJWT : null
			}
		}).then(res => {
			const casilla = res.data.consultaCasilla;
			this.setState({ datosCasillasItinerantes: casilla	});
		}).catch(error => {
			console.error("Error al obtener la consulta de Casillas", error);
		});
	};

	datosGuardar = (datos) => {
		this.setState({ cargando: true });
		datos.idProcesoElectoral = this.props.user.idProcesoElectoral;
		datos.idDetalleProceso = this.props.user.idDetalle;
		datos.idEstado = this.props.estado.idEstado;
		datos.idDistrito = this.props.distrito.idDistrito;
		console.log("Imprime json..." + JSON.stringify(datos));
		axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + "ws/guardarSeguimientoCasilla", datos, { 
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': this.props.user ? this.props.user.tknJWT : null
		}).then(_response => {
			if(datos.fechaHoraTermino !== null || datos.fechaHoraTermino !== undefined) {
				this.setState({puedeDescargarActa: true});
			}
			this.setState({ abrirModalExitoRegistro: true });

		}).catch(error => {

			this.setState({ cargando: false });
			console.error("Error al guardar el seguimiento de casilla en datosGuardar()", error);
			
			if (error.response) {
				if (error.response.status === 409) {
					msjError = error.response.data.message;
				} else if (error.response.data && error.response.data.causa && error.response.data.causa.includes("Error de validación")) {
					msjError = error.response.data.message;
				} else {
					msjError = etiquetas.gral_error_cau;
				}
			} else {
				msjError = etiquetas.gral_error_cau;
			}
			this.setState({ msjError: msjError,
				              abrirModalError: true
										});
		});
	};

	irBandejaSeguimiento = () => {
		this.props.setCurrentCasilla(null);
    this.props.history.push(PATH_SEGUIMIENTO_CASILLA);
	};

	handleCancelModalExito = () => {
		this.setState({abrirModalExitoRegistro: false});
	};

	jsonActa = (casilla) => {
		var listaCasillas = [casilla];

		var json = {
			"idProcesoElectoral": casilla.idProcesoElectoral,
			"idDetalleProceso": casilla.idDetalleProceso,
			"idEstado": this.props.estado.idEstado,
			"idDistrito": casilla.idDistrito,
			"folio": casilla.folio,
			"listaCasillas": listaCasillas
		};

		console.log("Imprime json..." + JSON.stringify(json));
		return json;
	}

	handleDescargaActa = (datosCasilla) => {
		console.log("Entra descarga acta cierre...");
		var tken = { 'Authorization': this.props.user ? this.props.user.tknJWT : null,
									"Access-Control-Allow-Headers" : "Content-Type",
									"Access-Control-Allow-Origin": "http://localhost:3000",
									"Access-Control-Allow-Methods": "OPTIONS,POST,GET"
    						};
		const infoPost = this.jsonActa(datosCasilla);

		if(infoPost.fechaHoraTermino !== null || infoPost.fechaHoraTermino !== undefined) {
      this.setState({cargando:true});
			
      if(infoPost.listaCasillas.length > 0) {
        axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + "ws/descargarActaCierre", infoPost, {
					responseType: 'blob',
					"Access-Control-Allow-Headers" : "Content-Type",
					//"Access-Control-Allow-Origin": "http://localhost:3000",
					"Access-Control-Allow-Methods": "OPTIONS,POST,GET",
					transformResponse: [ data => {
						let blob = new window.Blob([data,tken], {type: "application/pdf"});
						var link = document.createElement('a');
						link.href = window.URL.createObjectURL(blob);
						link.setAttribute('download', `ActaCierre_${infoPost.folio}.pdf`);
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
					}]
				}).then(res => {
            this.abrirNotificacion(etiquetasCasillas.etiqueta_mensaje_descarga_acta + infoPost.folio);
            console.info("respuesta...",res);
            this.setState({ cargando:false });
        }).catch(error => {
            console.error("Error",error);
        });
      }else{
        this.setState({ cargando:false });
        console.error("Se debe concluir con el cierre de instalación de la casilla para poder crear el acta de cierre");
      }
    } else {
        console.info("el rol ", this.props.user.rolUsuario, " no tiene los permisos para generar el acta de cierre");
    }
	}

	render() {
		const {nombreEstado, tiposCasillas, abrirModalExitoRegistro, msjError, abrirModalError, datosCasillasItinerantes, cargando, puedeDescargarActa} = this.state;
		return (
			<>
				<AuthenticatedComponent>
					<CTemplate //bloqueado={!roles_permitidos.includes(this.props.user.rolUsuario)}
						contenido={
							<>
								<VInstalacionCasilla
									nombreEstado = {nombreEstado}
									datosCasilla = {this.props.casilla}
									datosCasillasItinerantes = {datosCasillasItinerantes}
									tiposCasillas = {tiposCasillas}
									datosGuardar = {this.datosGuardar}

									cargando = {cargando}
									abrirModalExitoRegistro = {abrirModalExitoRegistro}
									msjError = {msjError}
									abrirModalError = {abrirModalError}
									handleCancelModalExito = {this.handleCancelModalExito}
									irBandejaSeguimiento = {this.irBandejaSeguimiento}
									puedeDescargarActa = {puedeDescargarActa}
									handleDescargaActa = {this.handleDescargaActa}
								/>
							</>
						}
						guia={false} />
				</AuthenticatedComponent>
			</>

		);
	}
}

const mapStateToProps = createStructuredSelector({
	estado: selectEstado,
	distrito: selectDistrito,
	user: selectCurrentUser,
	casilla: selectCurrentCasilla
});

const mapDispatchToProps = dispatch => ({
	setCurrentCasilla: casilla => dispatch(setCurrentCasilla(casilla)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CInstalacionCasilla));