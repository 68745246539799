import React, { useState, useEffect }from 'react';
import {Row, Col, Form, Input,Radio,Checkbox,Button,Select} from 'antd';
import InputText from '../../comunes/InputText';
import * as formatos from '../../../constants/Formatos';
import * as etiquetas from '../../../constants/EtiquetasVoluntarios';
import * as formato from '../../../constants/Formatos';
import {valueUpperCase} from '../../../utils/funciones.js';

import { ArrowRightOutlined, ArrowLeftOutlined} from '@ant-design/icons';

const { Option } = Select;

const VDomicilio  = (props) =>{

  const [datosDomicilioForm] = Form.useForm();
  const onFinish = (values: any) => {
      console.log('Received values of form: ', values);
      props.revisaCampoDomicilio(values);
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    console.log("es edición",props.editar);
    if(props.editar){
      console.log("los props en domicilio", props);
      datosDomicilioForm.setFieldsValue({
        'calle' : props.calle,
        'numeroInt' : props.numeroInt,
        'numeroExt' : props.numeroExt,
        'colonia' : props.colonia,
        'municipio' : props.municipio,
        'cp' : props.cp
       });
      }
  });



  return(
    <Form
       name="datosDomicilioForm"
       onFinish={onFinish}
       form = {datosDomicilioForm}
       layout = "vertical"
       onFinishFailed={onFinishFailed}
       validateMessages={etiquetas.defaultValidateMessages}>
     <Row gutter={16}>
       <Col xs={24} sm={24} md={6} lg={6} xl={6}>
         <Form.Item
            label = {etiquetas.datos_direccion_lbl_calle}
            name="calle" className = "formularioInput"
            rules={[{
              required: true,
              pattern: formatos.REGEX_FORMATO_NO_CARACTERES_ESPECIALES,
              max: 100
             }]}>
             <Input/>
         </Form.Item>
       </Col>
       <Col xs={24} sm={24} md={5} lg={5} xl={5}>
         <Form.Item
           label = {etiquetas.datos_direccion_lbl_numero_exterior}
           name="numeroExt" className = "formularioInput"
           rules={[{
                     min: 1,
                     max: 10,
                     pattern: formatos.REGEX_FORMATO_NUMERO_DIRECCION,
                     required: true,
                 }]}>
           <Input maxLength={10}/>
         </Form.Item>
       </Col>
       <Col xs={24} sm={24} md={5} lg={5} xl={5}>
         <Form.Item
           label={etiquetas.datos_direccion_lbl_numero_interior}
           name="numeroInt" className = "formularioInput"
           rules={[{
                     min: 1,
                     max: 10,
                     pattern: formatos.REGEX_FORMATO_NUMERO_DIRECCION
                 }]}>
           <Input maxLength={10}/>
         </Form.Item>
       </Col>
       <Col xs={24} sm={24} md={5} lg={5} xl={5}>
         <Form.Item
           label={etiquetas.datos_direccion_lbl_colonia}
           name="colonia" className = "formularioInput"
           rules={[{
                     max: 50,
                     pattern: formatos.REGEX_FORMATO_NO_CARACTERES_ESPECIALES,
                     required: true
                 }]}>
           <Input/>
         </Form.Item>
       </Col>
     </Row>
     <Row gutter={16}>
       <Col xs={24} sm={24} md={8} lg={8} xl={8}>
         <Form.Item
           label={etiquetas.datos_direccion_lbl_municipio}
           name="municipio" className = "formularioInput"
           rules={[{
             required: true,
           }]}>
           <Select disabled={props.editar} defaultValue={props.municipio}>
             {
               props.listaMunicipios &&
               props.listaMunicipios.map((municipio, index) => (
                   <Option key={index} value={municipio.idMunicipio}>
                     {municipio.nombreMunicipio}
                   </Option>
               ))
             }
           </Select>
         </Form.Item>
       </Col>
       <Col xs={24} sm={24} md={8} lg={8} xl={8}>
         <Form.Item
           label={etiquetas.datos_direccion_lbl_cp}
           name="cp" className = "formularioInput"
           rules={[{
                     len: 5,
                     pattern: formatos.REGEX_FORMATO_CP,
                     required: true,
                 }]}>
           <Input maxLength={5}/>
         </Form.Item>
       </Col>
     </Row>
     <Row gutter={16}>
       <Col xs={12} sm={12} md={12} lg={12} xl={12}>
           <Button type="primary" className="botonIzqRegistro"
             onClick={()=>props.regresar("1")}
             shape="circle"
             icon={<ArrowLeftOutlined id="flechaDer"/>}/>
       </Col>
       <Col xs={12} sm={12} md={12} lg={12} xl={12}>
         <Form.Item>
           <Button type="primary" className="botonDerRegistro" htmlType="submit"
             shape="circle"
             icon={<ArrowRightOutlined id="flechaDer"/>}/>
         </Form.Item>
       </Col>
     </Row>
   </Form>
  )
}

export default VDomicilio;
