export const WIZARD_DATOS_UBICACION                = 0;
export const WIZARD_DATOS_CONECTIVIDAD             = 1;
export const WIZARD_DATOS_HORARIOS                 = 2;

export const datos_ubicacion_lbl_municipio              = "Municipio o Alcaldia";
export const datos_ubicacion_lbl_seccion                = "Seccion";
export const datos_ubicacion_lbl_tipo_casilla           = "Tipo Casilla";
export const datos_ubicacion_lbl_lugar                  = "Lugar";
export const datos_ubicacion_lbl_ubicacion              = "Ubicación";
export const datos_ubicacion_lbl_calle                  = "Calle";
export const datos_ubicacion_lbl_numero                 = "Número";
export const datos_ubicacion_lbl_colonia                = "Colonia";
export const datos_ubicacion_lbl_cp                     = "Código postal";
export const datos_ubicacion_lbl_referencia             = "Referencias";
export const datos_ubicacion_lbl_telefono_contacto      = "Telefono de Contacto";

export const datos_tipo_sede_lbl_fisica                 = "Fisica";
export const datos_tipo_sede_lbl_virtual                = "Virual";

export const etiqueta_tooltip_consultar                 = "Consultar";
export const etiqueta_tooltip_editar                    = "Editar";
export const etiqueta_tooltip_eliminar                  = "Eliminar";
export const etiqueta_tooltip_filtros                   = "Utiliza los filtros para buscar una sede  y selecciona la acción que deseas realizar";
export const etiqueta_tooltip_seccion                   = "Es la sección electoral donde se ubicará la casilla";
export const etiqueta_tooltip_tipo_casilla              = "ESCOLARES: Casillas en escuelas públicas y privadas ITINERANTE: Casillas móviles en lugares de dificil acceso MÓDULOS DE ATENCIÓN CIUDADANA: Casillas en sedes de órganos electorales";
export const etiqueta_tooltip_conectividad              = "Ingresa la cantidad y los dispositivos con los que cuenta tu casilla";
export const etiqueta_tooltip_horarios                  = "Selecciona las fechas y horarios en que operará la casilla registrada";
export const etiqueta_tooltip_zore                      = "Es la ZOR que se asignará a la casilla de acuerdo a su sección";
export const etiqueta_tooltip_botonIzq                  = "Página siguiente";
export const etiqueta_tooltip_botonDer                  = "Página anterior";

export const advertencia_edo_dto                        = "Selecciona estado y distrito para continuar.";

export const REGEX_FORMATO_CLAVE_ELECTOR_FUAR       = /^([A-Z]{5,6}\d{8}[H|M]\d{3})$|^([0-9]{13})$/i;
export const REGEX_FORMATO_CLAVE_ELECTOR            = /^([A-Z]{5,6}\d{8}[H|M]\d{3})$/i;
export const REGEX_FORMATO_SIAP                     = /^([0-9]{13})$/i;
export const REGEX_FORMATO_NOMBRES                  = /^[A-ZÁÉÍÓÚÜÑ\'\°\.\-\/\s]*$/i;
export const REGEX_FORMATO_RFC                      = /^[A-Z]{3,4}[0-9]{6}([A-Z0-9]{3})*$/i;
export const REGEX_FORMATO_CURP                     = /^[A-Z]{4}[0-9]{6}[H|M][A-Z]{5}[A-Z0-9][0-9]$/i;
export const REGEX_FORMATO_CORREO                   = /^[_A-Z0-9-\+]+(\.[_A-Z0-9-]+)*@[A-Z0-9-]+(\.[A-Z0-9]+)*(\.[A-Z]{2,})$/i;
export const REGEX_FORMATO_TELEFONO                 = /^\d{8,10}$/i;
export const REGEX_FORMATO_NO_CARACTERES_ESPECIALES = /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i;

export const REGEX_FORMATO_SOLO_DIGITOS             = /^\d*$/i;

export const etiqueta_tooltip_URL_ACCESO                  = "Ingresa la URL de acceso a la sede de capacitacion";
export const etiqueta_tooltip_listado_sedes               = "Selecciona la acción que deseas realizar: consultar o modificar la información";
export const etiqueta_boton_guardar                       = "El botón de guardar se habilitará una vez que se completen los campos";