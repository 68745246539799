
export const rangoEdad                                  = "Rango de edad";
export const seccion                                    = "Sección";
export const estado                                     = "Estado";
export const municipio                                  = "Municipio";
export const rango1                                     = "3 a 5 años";
export const rango2                                     = "6 a 9 años";
export const rango3                                     = "10 a 13 años";
export const rango4                                     = "14 a 16 años";
export const seccion1                                   = "Acerca de ti";
export const seccion2                                   = "Cuidado del planeta";
export const seccion3                                   = "Cuidado y bienestar";
export const seccion4                                   = "Derechos humanos";
export const tipo_casilla                               = "Tipo de casilla";
export const tipo_casilla_uno                           = "Escuela física (boletas digitales)";
export const tipo_casilla_dos                           = "Escuela física (boletas impresas)";
export const tipo_casilla_tres                          = "Escuela virtual";
export const tipo_casilla_cuatro                        = "Itinerante física (boletas digitales)";
export const tipo_casilla_cinco                         = "Itinerante física (boletas impresas)";
export const tipo_casilla_seis                          = "JDE física";
export const tipo_casilla_siete                         = "JLE física";
export const tipo_casilla_ocho                          = "OPLE física";

export const etiqueta_tooltip_preguntas                 = "Elige una pregunta y consulta o descarga los resultados.";
export const etiqueta_tooltip_informacion               = "Selecciona los siguientes indicadores para consultar los resultados.";
export const etiqueta_tooltip_estado                    = "Selecciona el estado y municipio que deseas consultar los resultados.";

export const etiqueta_mujer                             = "Niña/mujer";
export const etiqueta_hombre                            = "Niño/hombre";
export const etiqueta_ninguno                           = "No me identifico con ninguno de los dos";
export const etiqueta_participacion                     = "Participación por sexo";
