import React from 'react';
import {Button, Row, Col, Input, Layout, Tooltip, Modal, Spin} from 'antd';
import { cargandoOutlined, PlusOutlined,ArrowLeftOutlined } from '@ant-design/icons';
import BlockLoader from '../../comunes/Loader.js';
import VOpcionesDoc from '../../../components/voluntarios/documentacion/VOpcionesDoc';
import VCargarArchivo from '../../../components/voluntarios/documentacion/VCargarArchivo';
import iconoAyuda from '../../../assets/img/icono_ayuda_fusia.png';
import * as etiquetaDocu from  '../../../constants/EtiquetasDocumentacion';
import '../../../css/voluntarios/documentacion.css';

const VDocumentacion = (props) =>{
  return(
    <BlockLoader blocking={props.loader}
                 styleClass={'bloq_sobre_todo'}>
      <Layout className='validar_modulo'>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <div className="divSubida">
                <div className="titulo">
                  {props.tipoVoluntario === "I" ?
                    "" : <label className="obligatorio">*</label>
                  }
                  <label className="etiquetaCampo">Credencial para votar (frente)</label>
                </div>
                <div className="cuerpo">
                  <VCargarArchivo
                     nombre="ineFrente"
                     validacion={props.validarArchivo}
                     cargarDocumento={props.cargarIneFrente}
                     imagenUrlDoc ={props.imagenUrlIneFrente}
                     disabled = {props.imagenUrlIneFrente ? true : false}
                   />
                  <VOpcionesDoc
                        vistaPrevia = {props.vistaPrevia}
                        eliminarArchivo = {props.eliminarArchivo}
                        opcion = {1}
                        archivo = {props.imagenUrlIneFrente}
                  />
                </div>
                <div className={props.imagenUrlIneFrente?"pieOp2" :"pie"}>
                  <Tooltip title={etiquetaDocu.tootltip_ayuda_ine_frente}>
                  <img src={iconoAyuda}/>
                  </Tooltip>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <div className="divSubida">
                <div className="titulo">
                  {props.tipoVoluntario === "I" ?
                    "" : <label className="obligatorio">*</label>
                  }
                  <label className="etiquetaCampo">Credencial para votar (reverso)</label>
                </div>
                <div className="cuerpo">
                  <VCargarArchivo
                     nombre="ineReverso"
                     validacion={props.validarArchivo}
                     cargarDocumento={props.cargarIneReverso}
                     imagenUrlDoc ={props.imagenUrlIneReverso}
                     disabled = {props.imagenUrlIneReverso ? true : false}
                   />
                   <VOpcionesDoc
                         vistaPrevia = {props.vistaPrevia}
                         eliminarArchivo = {props.eliminarArchivo}
                         opcion = {2}
                         archivo = {props.imagenUrlIneReverso}
                   />
                 </div>
                 <div className={props.imagenUrlIneReverso?"pieOp2" :"pie"}>
                   <Tooltip title={etiquetaDocu.tooltip_ayuda_ine_reverso}>
                     <img src={iconoAyuda}/>
                   </Tooltip>
                 </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <div className="divSubida">
                <div className="titulo">
                  {props.tipoVoluntario === "I" ?
                    "" : <label className="obligatorio">*</label>
                  }
                  <label className="etiquetaCampo">Comprobante de domicilio</label>
                </div>
                <div className="cuerpo">
                  <VCargarArchivo
                     nombre="comprobanteDom"
                     validacion={props.validarArchivo}
                     cargarDocumento={props.cargarComprobante}
                     imagenUrlDoc ={props.imagenUrlDomicilio}
                     disabled = {props.imagenUrlDomicilio ? true : false}
                   />
                   <VOpcionesDoc
                         vistaPrevia = {props.vistaPrevia}
                         eliminarArchivo = {props.eliminarArchivo}
                         opcion = {3}
                         archivo = {props.imagenUrlDomicilio}
                   />
                 </div>
                 <div className={props.imagenUrlDomicilio?"pieOp2" :"pie"}>
                   <Tooltip title={etiquetaDocu.tooltip_ayuda_comprobante}>
                     <img src={iconoAyuda}/>
                   </Tooltip>
                 </div>
             </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <div className="divSubida">
                <div className="titulo">
                  <label className="obligatorio">*</label>
                  <label className="etiquetaCampo">Foto</label>
                </div>
                <div className="cuerpo">
                  <VCargarArchivo
                     nombre="foto"
                     validacion={props.validarFoto}
                     cargarDocumento={props.cargarFoto}
                     imagenUrlDoc ={props.imagenUrlFoto}
                     disabled = {props.imagenUrlFoto ? true : false}
                   />
                   <VOpcionesDoc
                         vistaPrevia = {props.vistaPrevia}
                         eliminarArchivo = {props.eliminarArchivo}
                         opcion = {4}
                         archivo = {props.imagenUrlFoto}
                   />
                 </div>
                 <div className={props.imagenUrlFoto?"pieOp2" :"pie"}>
                   <Tooltip title={etiquetaDocu.tooltip_ayuda_foto}>
                     <img src={iconoAyuda}/>
                   </Tooltip>
                 </div>
             </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} id="errores">
              <label id="msj-error">{props.msjError}</label>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div id="btn-cargar">
                <Tooltip title ={
                  (props.imagenUrlIneFrente &&
                        props.imagenUrlIneReverso &&
                        props.imagenUrlDomicilio &&
                        props.imagenUrlFoto) ||
                        (props.tipoVoluntario === "I" && props.imagenUrlFoto) ?
                        "":
                        etiquetaDocu.tooltip_btn_guardar

                  }>
                  <Button onClick={props.guardar}
                    htmlType="submit"
                    className ={(props.imagenUrlIneFrente &&
                          props.imagenUrlIneReverso &&
                          props.imagenUrlDomicilio &&
                          props.imagenUrlFoto) ||
                          (props.tipoVoluntario === "I" && props.imagenUrlFoto) ?
                          "botonActivado":
                          "botonDesactivado"}
                    disabled ={(props.imagenUrlIneFrente &&
                          props.imagenUrlIneReverso &&
                          props.imagenUrlDomicilio &&
                          props.imagenUrlFoto) ||
                          (props.tipoVoluntario === "I" && props.imagenUrlFoto) ?
                          false:
                          true}>Guardar</Button>
                </Tooltip>
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Button type="primary" className="botonIzqRegistro"
                  onClick={()=>props.regresar("2")}
                  shape="circle"
                  icon={<ArrowLeftOutlined id="flechaDer"/>}/>
            </Col>
          </Row>
          <Modal
            visible={props.abrirModal}
            title={props.tituloModal}
            footer={null}
            onCancel={props.cerrarVistaPrevia}
          >
          <img alt="example" style={{ width: '100%' }} src={props.fotoModal} />
        </Modal>
      </Layout>
    </BlockLoader>
  );
}

export default VDocumentacion;
