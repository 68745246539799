import React from 'react';
import userIcon from '../../assets/img/user_icon.png';
import cauIcon from '../../assets/img/cau_icon.png';
import * as etiquetas from '../../constants/EtiquetasLogin';
import '../../css/header/iniciaSesion.scss';

const VIniciaSesion = ({ mostrarLogin }) => {
  return(
    <div className='inicia-sesion-container'>
        <img className="isc-image"
            src={cauIcon} 
            alt="Icono"/>
        <img className="isc-image"
            src={userIcon} 
            alt="Icono"/>
        <a onClick={mostrarLogin} className="isc-title" >
            {etiquetas.login_accion_iniciarSesion}
        </a>
    </div>
  );
}

export default VIniciaSesion;
