import React from 'react';
import { createStructuredSelector } from 'reselect';
import {connect} from 'react-redux';
import axios from 'axios';
import { obtenerEstatusModulo } from '../../../redux/menu/menu-utils';
import { selectEstado, selectDistrito } from '../../../redux/menu/menu-selectors';
import { selectCurrentUser, selectCurrentUserRol } from '../../../redux/user/user-selectors';
import * as etiquetas from '../../../constants/EtiquetasGenerales';
import * as info from '../../../constants/InfoSistema';
import CTemplate from '../../homeInterno/CTemplate';
import AuthenticatedComponent from '../../../components/comunes/AuthenticatedComponent';
import VSeguimientoVoluntarios from '../../../components/voluntarios/seguimiento/VSeguimientoVoluntarios';


const jwt = localStorage.getItem('jwt-token');

class CSeguimientoVoluntarios extends React.Component{
  
  constructor(props){
    super(props);
    this.state = {
      activarCasilla : true,
      page: 1,
      infoCasillas : [],
      
          
      datosCasilla : [],
      
      infoVoluntariosAsignados: [],
      infoVoluntariosAsignadosOriginal:[],
      eliminarVoluntariosAsignados: [],
      ocultarCasillas: false,
      ocultarVoluntarios: true,
      mensajeNoExistenDatos: false,
      mensajeNoExisteCasilla: false,
      recuperaDatos:[],
      idCasilla: 0,
      folioCasilla: "",
      mensajeVolMaximo: false,
      mensajeVolEliminado: false,
      isModalVisible: false,
      tipoCasilla: "",
      seccion: 0,
      distrito: 0,
      zore: 0,
      ubicacion: "",
      idCasillas:[],
      secciones:[],
      idMunicipios: [],
      busquedaPorFolioCasilla: null,
      
      
      busquedaPorVolAsignados: null,
      busquedaPorTipoCasilla: null,
      isModalVisibleDatosGuradados: false,
      
      
      banderaModificacion: false,
      
      mensajeAnadido: false,
      
      
      infoTiposCasillas: [],
      voInfoAsignacionCasillas: [],
      voluntario:[],
      banderaVoluntario: true,
      voInfoQuitarVoluntariosCasillas:[],
      
      idParticipacionCasilla: 0,
      filteredData :[],
      
      fechaHora:"",
      banderaBorrarTodo: false,
//Seguimiento --------------------
      paginado:0,      
      folioVoluntario: null,
      nombreVoluntario: null,
      busquedaPorMunicipio: null,
      busquedaPorClaveElector: null,
      actualizaVoluntarios: false,
      infoSedes: [],
      infoMunicipios: [],
      paginas: 0,
      idDistrito: null,
      infoVoluntarios: [],
      idVoluntario:0,
      correo:"",
      folio:"",
      estatus:"",
      idParticipacion:0,
      banderaModalDescartar:false,
      banderaModalObservaciones:false,
      observaciones:"",
      tipoSedeSelect:[],
      sedesSelect:[],
      banderaSede:true,
      defaultValueSede:"",
      tipoSede: null,
      idSede:null,
      fechaParticipacion:null,
      sedeNueva:null,
      nuevaTipoSede:null,
      banderaBotonGuardar: true,
      fechaNwe:null,
      horaNew:null,
      fechaCapacitacion:"",
      banderaFinal:false,
      estatusModulo: false,
      domicilioSede : "",
      lugar:"",
      observacionesSede:"",
      fechaNew:null,
      infoVoluntarios2:[],
      linkVirtual:null,
      banderaPresencial:false,
      banderaGuardar:true,
      banderaTipoSede:false,
      nombreCapacitador:null,
      banderaUserCau:true
    }
    console.log("los props en asignacion ",this.props);
  }

  componentDidMount(){
  this.obtenerVoluntarios();     
  }

  componentDidUpdate(){ 
    if(this.state.idDistrito != this.props.distrito.idDistrito){
      this.obtenerVoluntarios();
    }    
    if(this.state.actualizaVoluntarios){
      this.obtenerVoluntarios();      
    }  
  }

  /**
  * @author arturo.gomez
  **/
    paginacionCasillas = (valor) =>{
      console.log("VALOR-------------PAGINACION------------");
      console.log(valor);
      const valorFinal = (valor -1)*5;
      this.state.paginado=valorFinal;      
      console.log(this.state.paginado);
      this.obtenerVoluntarios();      
    }
   
    busquedaFolioVol = (valor) =>{
      console.log("VALOR DEL FOLIO DE CASILLA (VOLUNTARIO)**************************");     
      if(valor!=""){
        this.state.folioVoluntario = valor.toUpperCase();
      }else{
        this.state.folioVoluntario = null;
      }
              
      console.log(this.state.folioVoluntario);
      this.obtenerVoluntarios();
    }

    busquedaVoluntario = (valor) =>{
      console.log("VALOR DEL NOMBRE DE VOLUNTARIO(VOLUNTARIO)**************************");
      if(valor!=""){
        this.state.nombreVoluntario = valor.toUpperCase();
      }else{
        this.state.nombreVoluntario =null;        
      }
              
      console.log(this.state.nombreVoluntario);
      this.obtenerVoluntarios();
    }

    busquedaMunicipio = (valor) =>{
      console.log("VALOR DEL MUNICIPIO**************************");     
      if(valor!=""){
        this.state.busquedaPorMunicipio = valor;
      }else{
        this.state.busquedaPorMunicipio = null;
      }
              
      console.log(this.state.busquedaPorMunicipio);
      this.obtenerVoluntarios();
    } 


    busquedaClaveElector = (valor) =>{
      console.log("VALOR DE LA CLAVE**************************");
      if(valor!=""){
        this.state.busquedaPorClaveElector = valor;
      }else{
        this.state.busquedaPorClaveElector = null;
      }
        
      console.log(this.state.busquedaPorClaveElector);
      this.obtenerVoluntarios();
    }
   
    
  


  

  obtenerEstatusModulo1  = async () => {
    console.log("METODO DE OBTENER ESTATUS DEL MODULO Y VALIDAR ROLES");
     const ROLES_CAPTURA = {
      "CIJ.ADMIN.OC": true,
      "CIJ.CAPTURA.JD": true,
      "CIJ.CAPTURA.JL": true,
      "CIJ.CAPTURA.OC": true,
      "CIJ.CAPTURA_VE.JD": true,
      "CIJ.CAPTURA_VE.JL": true,
      "CIJ.CAPTURA_VCEYEC.JD": true,
      "CIJ.CAPTURA_VCEYEC.JL": true,
      "CIJ.CAU.OC": true,   
  };
    
     
          try{
           
            console.log(this.props);
            var idModulo = this.props.location.state !== undefined ?
            this.props.location.state.idModulo : 0;

            const estatus =(await obtenerEstatusModulo(info.ID_PROCESO_ELECTORAL,
              info.ID_DETALLE_PROCESO,
              info.ID_SISTEMA,
              this.props.estado.idEstado, 
              this.props.distrito.idDistrito,
              idModulo, 
              this.props.user.rolUsuario));
              

            console.log(estatus);
            console.log(this.props.user.rolUsuario)
            console.log("RESULTADO DEL ESTATUS DEL MODULO");
            if(estatus==="A" && ROLES_CAPTURA[this.props.user.rolUsuario]){
              this.state.estatusModulo = true;
            }                      
            console.log(this.state.estatusModulo);

            console.log("///////////////////////////////////////////");
            console.log(this.props.user.rolUsuario);
              
            if(this.props.user.rolUsuario === "CIJ.CAU.OC"){
              console.log("entra");
              this.state.banderaUserCau = false;
            }

          }catch(error){
          console.error("Error CSeguimientoVoluntarios: ", error);
          }                                        
  };

  actualizaVoluntarios = () =>{
    this.obtenerVoluntarios();
  this.setState({
    banderaModalDescartar:false
  });
}

  /**
  * Obtiene lis voluntarios
  * 
  */
  obtenerVoluntarios = (value) =>{
    console.log("LLEGUE AL METODO DE OBTENER VOLUNTARIOS"); 
    this.obtenerEstatusModulo1();  
    this.state.infoVoluntarios = [];
    this.state.infoVoluntarios2 = [];    
    this.state.actualizaVoluntarios=false;
    this.state.idDistrito = this.props.distrito.idDistrito;
   

    var postData = {
      idEstado: this.props.estado.idEstado,
      idDistrito: this.props.distrito.idDistrito,
      idProcesoElectoral: info.ID_PROCESO_ELECTORAL,
      idDetalleProceso: info.ID_DETALLE_PROCESO,
      paginado: this.state.paginado,
      folioVoluntario: this.state.folioVoluntario,
      nombreVoluntario: this.state.nombreVoluntario,
      municipio: this.state.busquedaPorMunicipio,      
      claveElector: this.state.busquedaPorClaveElector     
    };  
    console.log("DATOS ENVIADOS-------ONBTENER VOLUNTARIOS");
          console.log(postData); 
    console.log(this.props.user);
    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL +
      "ws/obtenerVoluntariosSeguimiento", postData,
          {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.user ? this.props.user.tknJWT : null
              }
          }
       ).then(response => {        
        if(response.data.code === 200){               
         this.setState({ 
          infoVoluntarios:response.data.infoVoluntarios,         
          infoSedes:response.data.infoSedes,
          infoMunicipios:response.data.infoMunicipios,
          tipoSedeSelect:response.data.infoTipoSedes,
          paginas:response.data.totalRegistros*2,
          paginado:0                  
         });
        
        }else{
          this.setState({
            infoVoluntarios: [],
            folioVoluntario: null,
            nombreVoluntario: null,
            claveElector: null,
            paginas:0
          });
        }
        console.log("INFO DE VOLUNTARIOS");
        console.log(this.state.infoVoluntarios);
        console.log("INFO DE SEDES");
        console.log(this.state.infoSedes);
        console.log("INFO DE MUNICIPIOS");
        console.log(this.state.infoMunicipios);
        console.log("TOTAL REGISTROS");
        console.log(this.state.paginas);

      }).catch(error => {
        console.error("Error al obtener los voluntarios",error);
    });
  }


  metodoFinal = () =>{
    this.obtenerVoluntarios();
    this.setState({
      banderaFinal:false,
      banderaGuardar:true,
      banderaTipoSede:false
    });
  }


/**
  * Actualiza SEDE
  * 
  */
 actualizaSedeVoluntario = (value) =>{
  console.log("LLEGUE AL METODO DE ASIGNAR SEDE"); 

  var postData = {
    idDetalleProceso: info.ID_DETALLE_PROCESO,
    usuario : this.props.user.username,
    ipUsuario: this.props.user.ip,
    idParticipacion: this.state.idParticipacion,
    idVoluntario: this.state.idVoluntario,
    idSede: this.state.idSede,
    fechaCapacitacion: this.state.fechaNew +"T"+this.state.horaNew,
    nombreVoluntario: this.state.nombreVoluntario,
    correo: this.state.correo,
    folio: this.state.folio,
    tipoSede: this.state.nuevaTipoSede,
    domicilioSede: this.state.domicilioSede,
    fechaNew:this.state.fechaNew,
    horaNew: this.state.horaNew,
    lugar: this.state.lugar,
    observacionesSede:this.state.observacionesSede,
    nombreCapacitador:this.state.nombreCapacitador
  };  
  console.log("DATOS ENVIADOS-------Actualiza SEDE");
        console.log(postData); 
  console.log(this.props.user);
  axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL +
    "ws/actualizaSedeVoluntario", postData,
        {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': this.props.user ? this.props.user.tknJWT : null
            }
        }
     ).then(response => {        
      if(response.data.code === 200){
         
            this.state.folioVoluntario= null;
            this.state.nombreVoluntario= null;
            this.state.claveElector= null;
            this.state.busquedaPorMunicipio = null;
            this.state.fechaNew = null;
            this.state.horaNew = null;
            this.state.idSede = null;
            this.state.nuevaTipoSede =null;
            this.setState({
              banderaBotonGuardar :true
            });
          this.setState({
            banderaFinal:true
          });
        this.obtenerVoluntarios();     
      }else{
        this.setState({
          infoVoluntarios: [],
          folioVoluntario: null,
          nombreVoluntario: null,
          claveElector: null,
          paginas:0
        });
      }
    }).catch(error => {
      console.error("Error al obtener los voluntarios",error);
  });
}

  vistaModalDescartar = (value) =>{    
      this.state.idVoluntario=value.idVoluntario;
      this.state.correo =value.correo;
      this.state.folio=value.folio;
      this.state.idParticipacion=value.idParticipacion;
      this.state.estatus=value.estatus;
    if(value.estatus === 4 && this.state.banderaUserCau){
      console.log("Entra");
      this.actualizaEstatusVoluntario();
    }
    this.setState({
      banderaModalDescartar : true
    });    
  }
 

  guardarDatos = ()  =>{
    console.log("ENTRA AL GUARDAR");
    console.log("TIPO SEDE");
    console.log(this.state.nuevaTipoSede);
    console.log("LUGAR");
    console.log(this.state.sedeNueva);

    this.state.infoSedes.filter((busqueda)=> {
      console.log("idSEDE");
      console.log(this.state.idSede );
      console.log("tiposede");
      console.log(this.state.nuevaTipoSede);
      if(busqueda.idSede === this.state.idSede && busqueda.tipoSede === this.state.nuevaTipoSede){
        this.setState({
          banderaGuardar:false,
          banderaTipoSede:true,
          banderaSede:true
        });     

        this.actualizaSedeVoluntario();
        console.log("SE ACTUALIZA-----------------------@@@@@@@@@@@@@@@@@@@@@@@@@@@qqq");

      }else{        
        console.log("NO COINDICE LOS DATOS DE LA SEDE");
      }
    });
  }

  guardaSede = (value) =>{
    console.log(value);
    this.state.domicilioSede ="";
    this.state.lugar ="";
    if(value===""){
      this.state.idSede = null;
    }else{      
      this.state.idSede = value;
      this.state.infoSedes.filter((busqueda)=> {
        if(busqueda.idSede === value){
          this.state.domicilioSede = busqueda.domicilioSede;
          this.state.lugar = busqueda.lugar 
          this.state.observacionesSede = busqueda.observacionesSede;
          this.state.nombreCapacitador = busqueda.nombreCapacitador;         
        }
      });
      
    }
    console.log(this.state.domicilioSede);
    
    if(this.state.fechaNew != null && this.state.horaNew != null 
      && this.state.idSede != null && this.state.nuevaTipoSede !=null && this.state.estatusModulo 
      && this.state.banderaGuardar && this.state.banderaUserCau){
      
        this.setState({
        banderaBotonGuardar :false
      });      
    }else{
      this.setState({
        banderaBotonGuardar :true
      });
    }
  }

  recuperaDatos = (value) =>{
    
    if(value.idVoluntario!= this.state.idVoluntario){
      console.log("ENTRA IDDIFERENTE")
      this.setState({
        banderaSede: true,
        banderaBotonGuardar :true
      });
      this.state.fechaNew = null;
      this.state.horaNew = null;
      this.state.nuevaTipoSede = null;
      this.state.idSede = null;
    }    
    console.log("DATOS RECUPERADOOOOOOOOOOOOSSSSSSSSSS");
    console.log(value);   
    
    console.log(this.state.lugar);
    
    this.state.idParticipacion=value.idParticipacion;
    this.state.idVoluntario=value.idVoluntario;
   // this.state.idSede=value.idSede;
    this.state.fechaCapacitacion= value.fechaCapacitacion;
    this.state.nombreVoluntario= value.nombreVoluntario;
    this.state.correo=value.correo;
    this.state.folio=value.folio;
    this.state.tipoSede=value.tipoSede;
    //Nuevas seleccionadas
    if(this.state.fechaNew=== null){
      this.state.fechaNew = value.fecha;
    }
    if(this.state.horaNew=== null){
      this.state.horaNew = value.hora;
    }
    if(this.state.nuevaTipoSede != null){
      if(value.tipoSede!= this.state.nuevaTipoSede){  
              
      }
    }else{
      this.state.nuevaTipoSede = value.tipoSede;
      this.state.infoSedes.filter((busqueda)=> {
        if(busqueda.idSede === value.idSede){
          this.state.domicilioSede = busqueda.domicilioSede;
          this.state.lugar = busqueda.lugar;
          this.state.observacionesSede = busqueda.observacionesSede;
          this.state.nombreCapacitador = busqueda.nombreCapacitador;
        }
      });
    }
    if(this.state.idSede != null){
      if(value.idSede!= this.state.idSede){        
      }
    }else{
      this.state.idSede = value.idSede;
    } 
       
  }

  fechaNueva = (date, dateString) =>{
    console.log("veamos");
    
    if(dateString ===""){
      this.state.fechaNew= null;
    }else{
      this.state.fechaNew= dateString;
    }
    console.log(this.state.fechaNew);
    
    if(this.state.fechaNew != null && this.state.horaNew != null 
      && this.state.idSede != null && this.state.nuevaTipoSede !=null && this.state.estatusModulo
      && this.state.banderaGuardar && this.state.banderaUserCau){
      
      this.setState({
        banderaBotonGuardar :false
      });
    }else{
      this.setState({
        banderaBotonGuardar :true
      });
    }
  }

  horaNueva  = (time, timeString) =>{
    console.log("veamos");
    if(timeString ===""){
      this.state.horaNew= null;
    }else{
      console.log(timeString);
      this.state.horaNew= timeString + ":00";
    }
    console.log(this.state.horaNew );
    console.log("FECHA" );
    console.log(this.state.fechaNew );
    if(this.state.fechaNew != null && this.state.horaNew != null
       && this.state.idSede != null && this.state.nuevaTipoSede !=null && this.state.estatusModulo
       && this.state.banderaGuardar && this.state.banderaUserCau){
      
      
      this.setState({
        banderaBotonGuardar :false
      });
    }else{
      this.setState({
        banderaBotonGuardar :true
      });
    }
  }

  seleccionarSede = (value) =>{ 
    this.state.sedesSelect=[];
    this.state.nuevaTipoSede = "";   
    if(value != ""){
      this.setState({
        banderaSede: false
      });

      this.state.nuevaTipoSede = value;

      console.log("ENTRE SI TRAE");
      this.state.infoSedes.filter((busqueda)=> {
        if(busqueda.tipoSede == value){
          if(value === 'Virtual'){
            this.state.sedesSelect.push({
              idSede: busqueda.idSede,
              lugar: "Sede " + busqueda.idSede,
              tipoSede: busqueda.tipoSede,
              domicilioSede : busqueda.domicilioSede
            });
            this.setState({
              banderaPresencial: false
            });            
          }else{            
          this.state.sedesSelect.push({
            idSede: busqueda.idSede,
            lugar: busqueda.lugar,
            tipoSede: busqueda.tipoSede,
            domicilioSede : busqueda.domicilioSede
          });
          this.setState({
            banderaPresencial: true
          }); 
         }     
        }
      });
    }else{
      this.state.sedesSelect=[];
      this.state.nuevaTipoSede=null;
    }
    console.log(this.state.nuevaTipoSede);
    if(this.state.fechaNew != null && this.state.horaNew != null 
      && this.state.idSede != null && this.state.nuevaTipoSede !=null && this.state.estatusModulo
      && this.state.banderaGuardar && this.state.banderaUserCau){
      
      this.setState({
        banderaBotonGuardar :false
      });
    }else{
      this.setState({
        banderaBotonGuardar :true
      });
    }
  }

  /**
  * Cambia el estatus del voluntario
  * 
  */
   actualizaEstatusVoluntario = () =>{
    console.log("LLEGUE AL METODO DE CAMBIAR ESTATUS");      
    
    
    let estatusNuevo;
    if(this.state.estatus === 4){
      estatusNuevo=3;
    }else{
      estatusNuevo=4;
    }

    var postData = {
      idDetalleProceso: info.ID_DETALLE_PROCESO,
      idParticipacion:this.state.idParticipacion,
      usuario : this.props.user.username,
      ipUsuario: this.props.user.ip,
      idVoluntario:this.state.idVoluntario,
      correo:this.state.correo,
      folio:this.state.folio,
      estatus:estatusNuevo,
      observaciones:this.state.observaciones 
    };  
    console.log("DATOS ENVIADOS-------");
          console.log(postData); 
    console.log(this.props.user);
    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL +
      "ws/actualizaEstatusVoluntario", postData,
          {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.user ? this.props.user.tknJWT : null
              }
          }
       ).then(response => {        
        if(response.data.code === 200){               
          console.log("ESTATUS CAMBIEADO CON EXITO");
          this.obtenerVoluntarios();
          
        }
      }).catch(error => {
        console.error("Error al obtener los voluntarios",error);
    });
  }

 

  handleCancel = () =>{    
    this.setState({
      banderaModalDescartar:false
    }); 
  }
  handleCancel2 = () =>{    
    this.setState({
      banderaModalObservaciones:false
    }); 
  }
  
  handleOk = () =>{
    this.setState({
      banderaModalDescartar:false,
      banderaModalObservaciones:true
    });
  }

  handleOk2 = (value) =>{
    console.log("LLEGUEEEEEEEEEEEEEEEE");   
      this.state.observaciones=value;
      this.actualizaEstatusVoluntario();    
    this.setState({
      banderaModalObservaciones:false
    });
  }

  funcionListo = () => {
    
  }

  render(){
    return(    
      <>
        <AuthenticatedComponent > 
          <CTemplate contenido={       
            <VSeguimientoVoluntarios             
              paginacionCasillas = {this.paginacionCasillas}
              columns = {this.state.columns}
              datosCasilla = {this.state.datosCasilla}
              
              page = {this.state.page}              
              infoCasillas = {this.state.infoCasillas}
              activarCasilla ={this.state.activarCasilla} 
              clicCasilla ={this.clicCasilla} 
              obtenerVoluntariosAsig = {this.obtenerVoluntariosAsig}
              ocultarCasillas = {this.state.ocultarCasillas}
              
              infoVoluntariosAsignados = {this.state.infoVoluntariosAsignados}
              ocultarVoluntarios = {this.state.ocultarVoluntarios}
              paginacionVoluntarios = {this.paginacionVoluntarios}
              volunAsig = {this.state.volunAsig}
              idCasilla = {this.state.idCasilla}
              idMunicipio = {this.state.idMunicipio}
              seccion = {this.state.seccion}
              recuperaDatos = {this.state.recuperaDatos}
              mensajeNoExistenDatos = {this.state.mensajeNoExistenDatos}
              idCasilla = {this.state.idCasilla}
              asignarCasillas = {this.asignarCasillas}
              mensajeVolMaximo = {this.state.mensajeVolMaximo}
              eliminarVoluntario= {this.eliminarVoluntario}
              mensajeVolEliminado = {this.state.mensajeVolEliminado}
              isModalVisible = {this.state.isModalVisible}
              modalInfo = {this.modalInfo}
              
              tipoCasilla = {this.state.tipoCasilla}
              seccion = {this.state.seccion}
              distrito = {this.state.distrito}
              zore = {this.state.zore}
              ubicacion = {this.state.ubicacion}
              busquedaFolioCasilla = {this.busquedaFolioCasilla}
                          
              
              folioCasilla = {this.state.folioCasilla}
              mensajeNoExisteCasilla = {this.state.mensajeNoExisteCasilla}
              isModalVisibleDatosGuradados = {this.state.isModalVisibleDatosGuradados}
              
              
              banderaModificacion = {this.state.banderaModificacion}
              banderaBotonGuardar = {this.state.banderaBotonGuardar}
              mensajeAnadido = {this.state.mensajeAnadido}
              infoMunicipios = {this.state.infoMunicipios}
              busquedaVolAsignados = {this.busquedaVolAsignados}
              infoTiposCasillas = {this.state.infoTiposCasillas}
              busquedaPorTipoCasilla = {this.state.busquedaPorTipoCasilla}
              busquedaTipoCasilla = {this.busquedaTipoCasilla}
              agregarVoluntarios = {this.agregarVoluntarios}
              quitarVoluntarios = {this.quitarVoluntarios}
              listasVoluntarios = {this.listasVoluntarios}
              
              
              quitarTodo = {this.quitarTodo}

              //Seguimiento
              busquedaClave = {this.busquedaClave}
              busquedaFolioVol = {this.busquedaFolioVol}
              busquedaVoluntario = {this.busquedaVoluntario}
              busquedaMunicipio = {this.busquedaMunicipio} 
              busquedaClaveElector = {this.busquedaClaveElector}
              actualizaEstatusVoluntario = {this.actualizaEstatusVoluntario}
              infoSedes = {this.state.infoSedes}
              infoMunicipios = {this.state.infoMunicipios}
              infoVoluntarios = {this.state.infoVoluntarios}
              paginas = {this.state.paginas}
              vistaModalDescartar = {this.vistaModalDescartar}
              banderaModalDescartar = {this.state.banderaModalDescartar}
              handleOk = {this.handleOk}
              handleOk2 = {this.handleOk2}
              handleCancel = {this.handleCancel}
              handleCancel2 = {this.handleCancel2}
              estatus = {this.state.estatus}
              banderaModalObservaciones = {this.state.banderaModalObservaciones}
              tipoSedeSelect = {this.state.tipoSedeSelect}
              seleccionarSede = {this.seleccionarSede}
              sedesSelect = {this.state.sedesSelect}
              banderaSede = {this.state.banderaSede}
              banderaTipoSede = {this.state.banderaTipoSede}
              guardaSede = {this.guardaSede}
              recuperaDatos = {this.recuperaDatos}
              fechaNueva = {this.fechaNueva} 
              horaNueva = {this.horaNueva}
              guardarDatos = {this.guardarDatos}
              banderaFinal = {this.state.banderaFinal}
              metodoFinal= {this.metodoFinal}
              estatusModulo = {this.state.estatusModulo}
              infoVoluntarios2 = {this.state.infoVoluntarios2}
              obtenerVoluntarios = {this.obtenerVoluntarios}
              banderaPresencial = {this.state.banderaPresencial}
              banderaUserCau = {this.state.banderaUserCau}
              actualizaVoluntarios = {this.actualizaVoluntarios} 
              paginado = {this.state.paginado}     
              
            />
            }
              guia={false}/>         
          </AuthenticatedComponent>
      </>

    );
  }
}
const mapStateToProps = createStructuredSelector({
  estado: selectEstado,
  distrito: selectDistrito,
  user: selectCurrentUser
});

export default connect(mapStateToProps)(CSeguimientoVoluntarios);
