
import React from 'react';
import {Button, Layout, Form, Input, Row, Col} from 'antd';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import {UserOutlined, LockOutlined } from '@ant-design/icons';
import InputText from './../comunes/InputText.js';
import AuthenticatedComponent from '../comunes/AuthenticatedComponent';
import CTemplateExt from '../../containers/homeExterno/CTemplateExt';
import {Link} from "react-router-dom";
import imgLogo from '../../assets/img/cij_login.png';
import * as etiquetas from '../../constants/EtiquetasGenerales';
import * as infoSistema from '../../constants/InfoSistema';
import * as etiquetaLogin from '../../constants/EtiquetasLogin';
import BlockLoader from '../comunes/Loader.js';

import  '../../css/login/login.css';

const {Sider, Content} = Layout;

const VLogin = (props) => {
	const [formLogin] = Form.useForm();
		return (
			<AuthenticatedComponent privado={false}>
				<BlockLoader blocking={props.cargando}
										 styleClass={'bloq_sobre_todo'}>
				<GoogleReCaptchaProvider
						reCaptchaKey={infoSistema.SECRET_CAPTCHA_KEY}
						language="es-419" >
					<React.Fragment>
						<Layout className='layout_home'>
							<Row className="div_login_m">
								<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
									<div id='log_img'>
										<img src={imgLogo} alt=""/>
									</div>
								</Col>
								<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}
									className="alineacionInerith">
									<p id="etiqueta-bienvenida">
										¡Te damos la bienvenida!
									</p>
								</Col>
								<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
									<p id="etiqueta-ingresar">
										Ingresa tus datos para comenzar
									</p>
								</Col>
								<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
									<Form id="form-login" name="login_form"
										form={formLogin}
										onFinish={props.onFinish}
      							onFinishFailed={props.onFinishFailed}
										initialValues={{ usuario: "",password:"" }}>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} id='usr-div'>
											<Form.Item name="usuario"
														style={{width: '100%'}}
														rules={[{ required: true, message: etiquetas.general_dato_requerido}]}
														>
														<Input placeholder={etiquetaLogin.login_lbl_usuario}
															prefix={<UserOutlined className="site-form-item-icon" />}/>
											</Form.Item>

										</Col>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} id='pws-div'>
											<Form.Item name="password"
														style={{width: '100%'}}
														rules={[{ required: true, message: etiquetas.general_dato_requerido}]}
														>
														<Input type="password" id=""
															prefix={<LockOutlined className="site-form-item-icon" />}
															placeholder={etiquetaLogin.login_lbl_constrasenia}/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}
											  id='registrate-login'>
											<div>
												<a id="link-aqui" href='https://cua.ine.mx/claveacceso/'
													target="_blank">
													¿Olvidaste tu contraseña?
												</a>
											</div>
										</Col>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}
											id="btn-submit-div">
											<Form.Item>
												<Button type="primary" htmlType="submit"
														className="button_style"
														id="btn-sesion">
														{etiquetaLogin.login_btn_inicioSesion}
												</Button>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<div id="error">
												<span>{props.mensajeError}</span>
											</div>
										</Col>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<p id="derechos">
												Derechos reservados INE 2021.
											</p>
										</Col>
									</Form>
								</Col>
							</Row>
						</Layout>
					</React.Fragment>
				</GoogleReCaptchaProvider>
				</BlockLoader>
			</AuthenticatedComponent>
		);
}



export default VLogin;
