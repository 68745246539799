import React from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import * as etiquetas from '../../constants/EtiquetasGenerales';
import * as info from '../../constants/InfoSistema';
import {advertencia_edo_dto, advertencia_voluntario, id_tipo_documento,
        etiqueta_gafetes_descargados, roles_captura, roles_permitidos}
      from '../../constants/EtiquetasGafete';
import CTemplate from '../homeInterno/CTemplate';
import AuthenticatedComponent from "../../components/comunes/AuthenticatedComponent";
import VGafete from '../../components/gafete/VGafete';
import { createStructuredSelector } from 'reselect';
import { selectEstado, selectDistrito} from '../../redux/menu/menu-selectors';
import { selectCurrentUser} from '../../redux/user/user-selectors';
import exitoGafete from '../../assets/img/exito_gafete.png';
import {darFormatoFecha} from '../../utils/funciones';
import {notification,Row,Col} from 'antd';
import iconoDescargaGafete from '../../assets/img/icono_descarga_gafete.png';
import {obtenerEstatusModulo} from '../../redux/menu/menu-utils';
import {obtenerFormatoDistrito,obtenerEstado} from '../../utils/funciones'

var nombresArchivo = [];

class CGafete extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      nombreArchivo: '',
      nombreCreacion:'',
      vigenciaInicial : "",
      vigenciaFinal : "",
      voluntariosSeleccionados: [],
      voluntarios : [],
      cargando: false,
      cargaBusquedaVolun : false,
      mostrar : false,
      paginaActual : 1,
      voluntariosSeleccionadoModal : null,
      cargandoLoader: false,
      puedeGenerarGafete : false,
      entidad : "",
      jde : 0,
      imagenUrlFoto : ""
    }

    console.info("el usuario con el rol ", this.props.user.rolUsuario,
    "está bloqueado ",!roles_permitidos[this.props.user.rolUsuario]);
  }

  componentDidMount(){
    this.consultarEstatusModulo();
    this.consultarPermisoUsuario();
    this.obtenerEstadoDistrito();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.estado !== prevProps.estado
      || this.props.distrito !== prevProps.distrito) {
      this.obtenerVoluntarios();
    }
  }

  obtenerEstadoDistrito = () =>{
    this.setState({
      entidad: obtenerEstado(this.props.estado.idEstado),
      jde: obtenerFormatoDistrito(this.props.distrito.idDistrito)
    })
  }

  consultarEstatusModulo(){
    var idProcesoElectoral = this.props.user.idProcesoElectoral;
    var idDetalleProceso = this.props.user.idDetalle;
    var idEstado = this.props.estado.idEstado;
    var idDistrito = this.props.distrito.idDistrito;
    var idSistema = info.ID_SISTEMA;
    var idModulo = this.props.location.state !== undefined ?
                    this.props.location.state.idModulo : 0;
    var grupoSistema = this.props.user.rolUsuario;

    obtenerEstatusModulo(idProcesoElectoral, idDetalleProceso,idSistema,
      idEstado, idDistrito, idModulo, grupoSistema).then(respuesta =>{
      console.info("El estatus del módulo gafete es: ",respuesta);
      if(respuesta === "A"){
        console.info("El Módulo gafete está abierto ");
        this.obtenerVoluntarios();
      }else{
        console.info("El Módulo gafete está cerrado ");
      }
    })
  }

  consultarPermisoUsuario = () =>{
    this.setState({
      puedeGenerarGafete : roles_captura.includes(this.props.user.rolUsuario)
    })
  }

  mostrarModal = (record) => {
    console.log("record",record);
    if (record.nombreFoto !== "") {
      this.obtenerArchivosSubidos(record);
    }
    this.setState({
      mostrar: true,
      voluntariosSeleccionadoModal : record
    })
  };

  obtenerArchivosSubidos = (record) =>{
    const infoPost = {
      idProcesoElectoral: this.props.user.idProcesoElectoral,
      idDetalleProceso: this.props.user.idDetalle,
      idEstado: this.props.estado.idEstado,
      idDistrito: this.props.distrito.idDistrito,
      idVoluntario: record.idVoluntario
    }

    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL+
      "ws/obtenerArchivosVoluntario", infoPost, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': this.props.user ? this.props.user.tknJWT : null
          }
      }
       ).then(response => {
         console.info("Archivos en gluster",response)
         this.acomodarArchivo(response.data.archivosVoluntario);
    }).catch(error => {
        console.error("Error al obtener las casillas",error);
    });
  }

  acomodarArchivo = (archivosVoluntario) =>{
    console.log("archivosVoluntarios",archivosVoluntario);
    var tipoArchivo = "";
    archivosVoluntario.forEach((archivo) => {
      console.log("archivo",archivo);
      var infoPost = {
        idProcesoElectoral: this.props.user.idProcesoElectoral,
        idDetalleProceso: this.props.user.idDetalle,
        idEstado: this.props.estado.idEstado,
        idDistrito: this.props.distrito.idDistrito,
        folio: archivo.folio,
        src : archivo.nombreArchivo,
        claveElectorSiap : archivo.fuarSiap
      }
      nombresArchivo.push(archivo.nombreArchivo);
      if (archivo.nombreArchivo.endsWith('.jpeg') ||
          archivo.nombreArchivo.endsWith('.jpg')){
          tipoArchivo = 'image/jpeg';
      }else if (archivo.nombreArchivo.endsWith('.png')){
          tipoArchivo= 'image/png';
      } else {
        tipoArchivo= 'application/pdf';
      }

      this.obtenerArchivo(infoPost,tipoArchivo,
        archivo.idTipoDocumento,archivo.nombreArchivo);
    });

  }

  obtenerImg =(blobUrl,nombre,idTipoDocumento) =>{
    var dataUri;
    let _this=this;

    axios({
    method: 'get',
    url: blobUrl,
    responseType: 'blob'
    }).then(function(response){
         var reader = new FileReader();
         reader.readAsDataURL(response.data);
         reader.onloadend = function() {
             var the_file = null;
             var base64data = reader.result;
             var bloque = base64data.split(";");
             var tipoArchivo = bloque[0].split(":")[1];
             var img64 = bloque[1].split(",")[1];
             var archivo = new Blob([window.atob(img64)],
                  { type:tipoArchivo, encoding: 'utf-8' });
             var imagen = new File([archivo], nombre, { type: tipoArchivo})
             dataUri = "data:"+tipoArchivo+";base64,"+img64;
             if (idTipoDocumento === "4" || idTipoDocumento === 4 ) {
               _this.setState({
                 imagenUrlFoto:dataUri
               })
             }
         }

    });
    return dataUri;
  }

  obtenerArchivo = (infoPost,tipoArchivo,idTipoDocumento,nombre,numAleatorio) =>{
    var tken = {
      'Authorization': this.props.user ? this.props.user.tknJWT : null
    }
    console.log("obteniendi archivos",infoPost, tipoArchivo);
    axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL+
      "ws/obtenerArchivo", infoPost, {
            responseType: 'blob',
            transformResponse: [ data => {
                let blob = new window.Blob([data,tken], {type: tipoArchivo});
                this.srcURLObject = window.URL.createObjectURL(blob);
                return this.srcURLObject;
            }],
        }).then(rawData => {
          var pele = this.obtenerImg(rawData.data,nombre,
                                                idTipoDocumento,numAleatorio);
        });
  }

  ocultarModal = () => {
    this.setState({
      mostrar: false,
      imagenUrlFoto:""
    })
  };

  obtenerVoluntario = (folio) =>{
    console.info("voluntario a buscar: ",folio);
    if(folio.length > 0){
      this.setState({
        cargaBusquedaVolun : true
      });
      this.obtenerVoluntarios(folio.toUpperCase());
    }else{
      this.obtenerVoluntarios();
    }

  }

  obtenerVoluntarios = (folioVoluntario = null, inicio = 1, fin =5) =>{
    /*if(this.props.estado.idestado === 0 ||
            this.props.distrito.idDistrito === 0){
      console.error("No ha seleccionado un estado y distrito");
    }else{*/
      this.setState({cargandoLoader:true})
      let infoPost = {
        idProcesoElectoral : this.props.user.idProcesoElectoral,
        idDetalleProceso : this.props.user.idDetalle,
        idEstado: this.props.estado.idEstado,
        idDistrito: this.props.distrito.idDistrito,
        folioNombreVoluntario: folioVoluntario,
        /*inicio:  inicio,
        fin:  fin,*/
        idTipoDocumento : id_tipo_documento
      }

      axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL+
        "ws/obtenerListaVoluntarios", infoPost,
            {
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': this.props.user ? this.props.user.tknJWT : null
                }
            }
         ).then(response => {
           console.log("obteniendo voluntarios",response);
           this.setState({
             voluntarios:response.data.voluntarios,
             total: response.data.totalVoluntarios,
             cargando : false,
             cargandoLoader:false,
             cargaBusquedaVolun : false,
           });
      }).catch(error => {
          console.error("Error al obtener la lista de voluntarios",error);
      });
    //}
  }

  obtenerInfoPost = (seleccionado) => {
    var aspiranteSeleccionado = new Array();
    if (seleccionado.hasOwnProperty("nombreVoluntario")){
      aspiranteSeleccionado.push(seleccionado);
    }
    const nombreArchivo ="gafetes_"+ moment().format('YYYYMMDD_HHmmss');
    console.log("nombreArchivo",nombreArchivo);
    const infoPost = {
      idProcesoElectoral : this.props.user.idProcesoElectoral,
      idDetalleProceso : this.props.user.idDetalle,
      idEstado: this.props.estado.idEstado,
      idDistrito: this.props.distrito.idDistrito,
      nombreArchivo : nombreArchivo,
      listaAspirantes : seleccionado.hasOwnProperty("nombreVoluntario") ?
                         Object.values(aspiranteSeleccionado) : this.state.voluntariosSeleccionados
    };
    return infoPost;
  }

  crearGafetes = (aspiranteSeleccionado) =>{
    var tken = {
      'Authorization': this.props.user ? this.props.user.tknJWT : null,
      "Access-Control-Allow-Headers" : "Content-Type",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "Access-Control-Allow-Methods": "OPTIONS,POST,GET"
    }
    if(this.state.puedeGenerarGafete){
      this.setState({cargandoLoader:true})
      const infoPost = this.obtenerInfoPost(aspiranteSeleccionado);
      console.info("info par gafete ",infoPost)
      if(infoPost["listaAspirantes"].length > 0){
        axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL +
             "ws/crearGafetes", infoPost,
             {
                responseType: 'blob',
                "Access-Control-Allow-Headers" : "Content-Type",
                      "Access-Control-Allow-Origin": "http://localhost:3000",
                      "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
                transformResponse: [ data => {
                  let blob = new window.Blob([data,tken], {type: "application/pdf"});
                  var link = document.createElement('a');
                  link.href = window.URL.createObjectURL(blob);
                  link.setAttribute('download', `${infoPost.nombreArchivo}.pdf`);
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }]
             }
        ).then(res => {
            this.abrirNotificacion(etiqueta_gafetes_descargados);
            console.info("respuesta...",res);
            this.setState({
              cargandoLoader:false
            });

        }).catch(error => {
            console.error("Error",error);
        });
      }else{
        this.setState({
          cargandoLoader:false
        });
        console.error("Se debe seleccionar un aspirante para poder crear un gafete");
      }
    }else{
        console.info("el rol ", this.props.user.rolUsuario ,
                              " no tiene los permisos para generar el gafete");
    }
  }

  abrirNotificacion = (mensaje) => {
    notification.open({
      description:<Row >
        <Col xs={5} sm={4} md={5} lg={5} xl={5} id="div-icono-exito">
          <img src={iconoDescargaGafete} id="icono-descarga-exito"/>
        </Col>
        <Col xs={19} sm={19} md={19} lg={19} xl={19} id="msj-exito">
          {mensaje}
        </Col>
      </Row>,
      placement : "bottomRight",
      duration : 5
    });
  }

  eliminarElemento = (key: React.Key) =>{
    console.log(key);
    const voluntariosSeleccionados = [...this.state.voluntariosSeleccionados];
    this.setState({
      voluntariosSeleccionados:
      voluntariosSeleccionados.filter(item => item.key !== key)
    });
    console.log("pp", this.state.voluntariosSeleccionados);
  }

  seleccionarVoluntario = (voluntariosSeleccionados: React.Key[],
                                                  selectedRows: DataType[]) => {
     console.info(`keys seleccionados: ${voluntariosSeleccionados.length}`,
        'filas seleccionadas: ', selectedRows);
     this.setState({
        voluntariosSeleccionados: selectedRows
      });
  }

  regresarPrimeraPagina = () =>{
    this.setState({
      paginaActual : 1,
    });
    this.obtenerVoluntarios();
  }

  render(){
    return(
      <>
        <AuthenticatedComponent  >
          <CTemplate
            bloqueado = {!roles_permitidos.includes(this.props.user.rolUsuario)}
            contenido = {
              <>
            <VGafete
              vigenciaInicial = {this.state.vigenciaInicial}
              vigenciaFinal = {this.state.vigenciaFinal}
              crearGafetes = {this.crearGafetes}
              data = {this.state.voluntarios}
              cargando={this.state.cargando}
              seleccionarVoluntario={this.seleccionarVoluntario}
              voluntariosSeleccionados =  {this.state.voluntariosSeleccionados}
              obtenerVoluntario = {this.obtenerVoluntario}
              obtenerVoluntarios = {this.obtenerVoluntarios}
              mostrarModal = {this.mostrarModal}
              mostrar = {this.state.mostrar}
              ocultarModal = {this.ocultarModal}
              voluntariosSeleccionadoModal = {this.state.voluntariosSeleccionadoModal}
              paginaActual = {this.state.paginaActual}
              total = {this.state.total}
              cargaBusquedaVolun = {this.state.cargaBusquedaVolun}
              eliminarElemento = {this.eliminarElemento}
              regresarPrimeraPagina = {this.regresarPrimeraPagina}
              cargandoLoader = {this.state.cargandoLoader}
              puedeGenerarGafete = {this.state.puedeGenerarGafete}
              entidad = {this.state.entidad}
              jde = {this.state.jde}
              imagenUrlFoto = {this.state.imagenUrlFoto}
            />

          </>
            }
              guia={false}/>

            </AuthenticatedComponent>
      </>

    );
  }
}

const mapStateToProps = createStructuredSelector({
    estado: selectEstado,
    distrito: selectDistrito,
    user: selectCurrentUser
});

export default connect(mapStateToProps)(CGafete);
