import { createSelector } from 'reselect';

const selectMenu = (state) => state.menu;

export const selectSubMenus = createSelector(
  [selectMenu],
  (menu) => menu.subMenus
);

export const selectListaEstados = createSelector(
  [selectMenu],
  (menu) => menu.listaEstados
);

export const selectListaDistritos = createSelector(
  [selectMenu],
  (menu) => menu.listaDistritos
);

export const selectEstado = createSelector(
  [selectMenu],
  (menu) => menu.estado
);

export const selectDistrito = createSelector(
  [selectMenu],
  (menu) => menu.distrito
);