import React, {useState, useEffect} from 'react';
import { Button, Table, Pagination, Modal, Row, Col, Layout, Alert, Input, Card, Select, Tabs, Tooltip, Radio, Form, InputNumber, TimePicker, DatePicker, Popconfirm, Popover} from 'antd';
import { ArrowRightOutlined, ArrowLeftOutlined, CloseOutlined} from '@ant-design/icons';
import { FrownOutlined, HomeFilled} from '@ant-design/icons';
import * as etiquetasCasillas from '../../../constants/EtiquetasCasillas';
import iconoMostrar from '../../../assets/img/icono_mostrar_gafete.png';
import iconoEditar from '../../../assets/img/icono-editar.png';
import iconoEliminar from '../../../assets/img/icono_eliminar.png'
import iconoQuestion from '../../../assets/img/icono_question.png';
import iconoFiltro from '../../../assets/img/icono_filter.png';
import iconoSiguiente from '../../../assets/img/icono_next.png';
import iconoAnterior from '../../../assets/img/icono_back.png';
import errorModal from '../../../assets/img/errorModal.png';
import iconoBasura from '../../../assets/img/icono_basura.png';
import eliminarFecha from '../../../assets/img/eliminarFecha.png';
import casillaModificaImg from '../../../assets/img/modifica_casilla.png'
import eliminaCasilla from '../../../assets/img/eliminar_casilla.png';
import errorPop from '../../../assets/img/warning_icono.png';
import '../../../css/consultaCasillas/consultaCasillas.css';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';
import 'moment/locale/es';

const { Option } = Select;
const { TabPane } = Tabs;

const icon = <FrownOutlined />;

const ConsultarV = (props) =>{

  const[data, setData] = useState(props.items);

  const [searchFolioCasilla, setSearchFolioCasilla] = useState("");
  const [searchMunicipioCasilla, setSearchMunicipioCasilla] = useState("");
  const [searchSeccionCasilla, setSearchSeccionCasilla] = useState("");
  const [searchZoreCasilla, setSearchZoreCasilla] = useState("");
  const [form] = Form.useForm();

  const[valCE, setValCE] = useState();
  const[valTI, setValTI] = useState();
  const[valT, setValT] = useState();

  const[fechaIns, setFechaIns] = useState(moment('11/01/21').format("DD/MMMM/YY"));
  const[fechaCer, setFechaCer] = useState(moment('11/30/21').format("DD/MMMM/YY"));
  const[horaIns, setHoraIns] = useState(moment('09:00', "HH:mm").format("HH:mm"));
  const[horaCer, setHoraCer] = useState(moment('19:00', "HH:mm").format("HH:mm"));

  const [ ky, setKy] = useState();

  const [message, setmessage] = useState('');

  const[opSN, setOpSN] = useState(props.tieneInternet);

  const [info,setInfo] = useState();
  const [calle, setCalle] = useState(props.calle);
  const [num, setNum] = useState(props.num);
  const [codigoPostal, setCodigoPostal] = useState(0);
  const [colonia, setColonia] = useState(props.col);
  const [lugar, setLugar] = useState(props.lug);
  const [referencia, setReferencia] = useState(props.referencia);
  const [nombreC, setNombreC] = useState(props.nombreC);
  const [telC, setTelC] = useState(props.telC);
  const [claveE, setClaveE] = useState(props.claveEscuela);
  const [tipoCasilla, setTipoCasilla] = useState(props.idTipoCasilla);
  const [turno, setTurno] = useState();
  const [nivelE, setNivelE] = useState();

  const [valres, setValres] = useState(500);
  const [valresReferencia, setValresReferencia] = useState(500);
  const [valresReferenciaE, setValresReferenciaE] = useState(500);
  const [valresLugarE, setValresLugarE] = useState(500);
  const [lonTel, setLonTel] = useState(false);

  const [fechaIncorrecta, setFechaIncorrecta] = useState(false);
  const [horaIncorrecta, setHoraIncorrecta] = useState(false);
  

  const [fvalNum, setFvalNum] = useState(false);
  const [fvalCp, setFvalCp] = useState(false);
  const [fvalTel, setFvalTel] = useState(false);
  const [fvalCalle, setFvalCalle] = useState(false);
  const [fvalColonia, setFvalColonia] = useState(false);
  const [fvalReferencia, setFvalReferencia] = useState(false);
  const [fvalLugar, setFvalLugar] = useState(false);
  const [fvalClaveE, setFvalClaveE] = useState(false);
  const [fvalNombreC, setFvalNombreC] = useState(false);

  const [modalE, setModalE] = useState(false);

  const loadProfile = () =>{

    setInfo(props.casillaModificar);

    var nomCalle = props.calle;
    var num = props.num;
    var codigoPostal = props.cp;
    var colonia = props.col;
    var referencia = props.referencia;
    var lugar = props.lug;
    var nombreC = props.nombreC;
    var telC = props.telC;
    var dispCE = props.computadorasE;
    var dispTI = props.telefonosI;
    var dispT = props.tabletas;
    var tieneInternet = props.tieneInternet;
    var claveEscuela = props.claveEscuela;
    var tipoCasilla = props.idTipoCasilla;
    var nivelEducativo = props.idNivelEducativo;
    var turnoEscuela = props.idTurnoEscuela;
    var fila = props.fila;

    setCalle(nomCalle);
    setNum(num);
    setCodigoPostal(codigoPostal);
    setColonia(colonia);
    setLugar(lugar);
    setReferencia(referencia);
    setNombreC(nombreC);
    setTelC(telC);
    setValCE(dispCE);
    setValTI(dispTI);
    setValT(dispT);
    setOpSN(tieneInternet)
    setClaveE(claveEscuela);
    setTipoCasilla(tipoCasilla);
    setNivelE(nivelEducativo);
    setTurno(turnoEscuela);
    setKy(fila);


    form.setFieldsValue({"calle" : nomCalle});
    form.setFieldsValue({"numero" : num});
    form.setFieldsValue({"codigoPostal" : codigoPostal});
    form.setFieldsValue({"colonia" : colonia});
    form.setFieldsValue({"referencia" : referencia});
    form.setFieldsValue({"lugar" : lugar});
    form.setFieldsValue({"nombreContacto" : nombreC});
    form.setFieldsValue({"telefonoContacto" : telC});
    form.setFieldsValue({"computadoraEscritorio" : dispCE});
    form.setFieldsValue({"telefonoInteligente": dispTI});
    form.setFieldsValue({"tableta": dispT});
    form.setFieldsValue({"opSN": tieneInternet});
    form.setFieldsValue({"claveEscuela": claveEscuela});
    form.setFieldsValue({"nivelEducativo": nivelEducativo});
    form.setFieldsValue({"turno": turnoEscuela});

  }

  useEffect(() => {
    loadProfile();
    revisarfechas();
    revisarHorarios();
    revisarNumero();
    revisarCp();
    revisarTel();
    revisarCalle();
    revisarCol();
    revisarLugar();
    revisarReferencia();
    revisarClaveE();
    revisarNombreC();
	},[props.casillaModificar, fechaIns, fechaCer, horaIns, horaCer, num, codigoPostal, telC, fvalNum, fvalCp, fvalTel, fvalCalle, calle, fvalColonia, colonia,
     lugar, fvalLugar, referencia, fvalReferencia, nombreC, fvalNombreC, claveE, fvalClaveE, ky, data, valCE, valTI, valT]);

  const fill = (number, len) =>
     "0".repeat(len - number.toString().length) + number.toString();

  const revisarNumero = (e) => {

      const valor = num;
      const reg = /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i;

      if(valor !== null){
        if(valor.length !== undefined){
          if(reg.test(valor)){
            setFvalNum(true);
          }else if(!isNaN(valor) && valor.length >=5){
            setFvalNum(true);
          }else{
            setFvalNum(false);
          }
        }
      }else{
        if(reg.test(valor)){
          setFvalNum(true);
        }else if(!isNaN(valor)){
          setFvalNum(true);
        }else{
          setFvalNum(false);
        }
      }
  }

  const revisarCp = (e) => {

      const valor = codigoPostal;
      const reg = /^\d*$/i;
      const reg2 = /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i;

    if(valor !== null){
      if(valor.length !== undefined){
        if(!isNaN(valor) && reg.test(valor) && valor.length === 5 && reg2.test(valor)){
          setFvalCp(true);
        }else{
          setFvalCp(false);
        }
      }else{
        if(!isNaN(valor) && reg.test(valor) && reg2.test(valor)){
          setFvalCp(true);
        }else{
          setFvalCp(false);
      }
    }}

  }

  const revisarTel = (e) => {

      const valor = telC;
      const reg = /^\d*$/i;
      const reg2 = /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i;

      if(valor !== null){
        if(valor.length !== undefined && valor.length > 0){
          if(!isNaN(valor) && reg.test(valor) && valor.length === 10 && reg2.test(valor)){
            setFvalTel(true);
            setLonTel(true);
          }else{
            setFvalTel(false);
          }
        }else{
          if(!isNaN(valor) && reg.test(valor) && reg2.test(valor)){
            setFvalTel(true);
          }else{
            setFvalTel(false);
          }
      }}

      if(valor === null){
        setFvalTel(true);
      }
  }


  const revisarCalle = (e) => {

    const valor = calle;
    const reg = /[!¡¿´@#$%^&*_+=\\[\]{};"\\|<>\/?]+/;

    if(valor !== null){
      if(valor.length !== undefined){
        if(reg.test(valor)){
          setFvalCalle(true);
        }else if(!isNaN(valor) && valor.length >=100){
          setFvalCalle(true);
        }else{
          setFvalCalle(false);
        }
      }
    }else{
      if(reg.test(valor)){
        setFvalCalle(true);
      }else if(!isNaN(valor)){
        setFvalCalle(true);
      }else{
        setFvalCalle(false);
      }
    }

}

const revisarCol = (e) => {

    const valor = colonia;
    const reg = /[!¡¿´@#$%^&*_+=\\[\]{};"\\|<>\/?]+/;

    if( valor !== null){
      if( valor.length !== undefined){
        if(reg.test(valor)){
          setFvalColonia(true);
        }else if(!isNaN(valor) && valor.length >=50){
          setFvalColonia(true);
        }else{
          setFvalColonia(false);
        }
      }else{
        if(reg.test(valor)){
          setFvalColonia(true);
        }else if(!isNaN(valor)){
          setFvalColonia(true);
        }else{
          setFvalColonia(false);
      }
    }}

}

const revisarReferencia = (e) => {

    const valor = referencia;
    const reg = /[!¡¿´@#$%^&*_+=\\[\]{};"\\|<>\/?]+/;

    if(valor.length !== undefined){

      setValresReferencia(500 - valor.length);

      if(reg.test(valor)){
        setFvalReferencia(true);
      }else if(!isNaN(valor) && valor.length <=500){
        setFvalReferencia(true);
      }else{
        setFvalReferencia(false);
      }
    }else{
      if(reg.test(valor)){
        setFvalReferencia(true);
      }else if(!isNaN(valor)){
        setFvalReferencia(true);
      }else{
        setFvalReferencia(false);
      }
    }

}

const revisarLugar = (e) => {

    const valor = lugar;
    const reg = /[!¡¿´@#$%^&*_+=\\[\]{};'\\|<>\/?]+/;

    if(valor.length !== undefined){

      setValres(500 - valor.length);

      if(reg.test(valor)){
       setFvalLugar(true);
      }else if(!isNaN(valor) && valor.length >=500){
       setFvalLugar(true);
      }else{
       setFvalLugar(false); 
      }
    }else{
      if(reg.test(valor)){
        setFvalLugar(true);
      }else if(!isNaN(valor)){
        setFvalLugar(true);
      }else{
        setFvalLugar(false); 
      }
    }

}

const revisarClaveE = (e) => {

    const valor = claveE;
    const reg = /[!¡¿´@#$%^&*_+=\\[\]{};':"\\|,.<>\/?]+/;
    const reg2 = /^\d*$/i;

    if( valor !== null){
      if( valor.length !== undefined){
        if(reg.test(valor)){
          setFvalClaveE(true);
        }else if(!isNaN(valor) && reg2.test(valor)){
          setFvalClaveE(false);
        }else{
          setFvalClaveE(false);
        }
      }
    }else{
      console.log("entre a revisar clave en 2")
      if(reg.test(valor)){
        setFvalClaveE(true);
      }else if(!isNaN(valor)){
        setFvalClaveE(true);
      }else{
        setFvalClaveE(false);
      }
    }

}

const revisarNombreC = (e) => {

  const valor = nombreC;
  const reg = new RegExp("^[üÑñíóáéúÁÉÍÓÚ a-zA-Z ]+$");
  const reg2 = /^\d*$/i; 

  if(reg.test(valor)){
     setFvalNombreC(true);
  }else if(valor === ''){
     setFvalNombreC(true);
  }else if (reg2.test(valor)){
      setFvalNombreC(false);
  }else{
      setFvalNombreC(false);
  }

}

  const hideModal = () => {
    setModalE(false);
  };



  const eliminarCasilla = () =>{

    var datos = props.casillaEliminar;

    if(datos.estatus === 'A'){
      setModalE(false);
    }else{
      props.eliminarCasilla(datos);
    }

  }

  const revisarEstatus = (datos) => {

    if(props.rolCau === false){
      if(datos.estatus === 'A'){
        setModalE(true);
      }else{
        props.mostrarModalElimina(datos);
      }
    }

  }

  const columns = [
    {
      title:(
        <div style={{ textAlign: 'center' }}>
            <div>
              <Tooltip title={etiquetasCasillas.etiqueta_tooltip_filtros}>
                <img style={{width:17 , height:17}} src={iconoQuestion}/>
              </Tooltip>
            </div>
            <br />
          </div>),
      dataIndex:'info',
      key:'info'
    },
    {
      title: (
        <div style={{ textAlign: 'center'}}>
            <div>Folio </div>
            <br/>
            <Input
                  value={searchFolioCasilla}
                  onChange={({target:{ value}}) => setSearchFolioCasilla(value)}
                  placeholder='Folio'  
                  suffix = {<img style={{width:12 , height:12}} src={iconoFiltro}/>}
                  onKeyPress={(e) => {if (e.key === 'Enter') {props.searchFolio(searchFolioCasilla)}}}   
                  />
        </div>
      ),
      align: 'center',
      dataIndex: 'folio',
      key: 'folio'
    },
    {
      title: (
        <div style={{ textAlign: 'center'}}>
            <div>Municipio/alcaldía</div>
            <br/>
            <Input
                  value={searchMunicipioCasilla}
                  onChange={({target:{ value}}) => setSearchMunicipioCasilla(value)}
                  placeholder='Municipio/alcaldía'
                  suffix = {<img style={{width:12 , height:12}} src={iconoFiltro}/>}
                  onKeyPress={(e) => {if (e.key === 'Enter') {props.searchMunicipio(searchMunicipioCasilla)}}}  
                  />
        </div>
      ),
      align: 'center',
      dataIndex: 'nombreMunicipio',
      key: 'nombreMunicipio',
    },
    {
      title:(
        <div style={{ textAlign: 'center'}}>
            <div>Lugar </div>
        </div>),
      align:'center',
      dataIndex:'lugar',
      key:'lugar'
    },
    {
      title: (
        <div style={{ textAlign: 'center', width:"250px"}}>
            <div>Tipo de casilla</div>
            <br/>
            <Select defaultValue="Tipo de casilla" onChange={props.searchTipoCasilla} >
                <Option value="">Tipo de casilla</Option>
                  { props.tiposCasillas.map((value, index) =>{
                        return(                                          
                            <Option value={value.idTipoCasilla}>                                
                              <tr key={index} >                                                                                        
                                  {value.tipoCasilla}                                            
                              </tr>
                            </Option>
                        )
                  })}                                                                          
            </Select>
        </div>
      ),
      align: 'center',
      dataIndex: 'tipoCasilla',
      key: 'tipoCasilla',
    },
    {
      title: (
        <div style={{ textAlign: 'center'}}>
            <div>N° de ZOR</div>
            <br/>
            <Input
                  value={searchZoreCasilla}
                  onChange={({target:{ value}}) => setSearchZoreCasilla(value)}
                  placeholder='N° de ZOR'
                  suffix = {<img style={{width:12 , height:12}} src={iconoFiltro}/>}
                  onKeyPress={(e) => {if (e.key === 'Enter') {props.searchZore(searchZoreCasilla)}}}  
                  />
        </div>
      ),
      align: 'center',
      dataIndex: 'numeroZore',
      key: 'numeroZore',
    },
    {
      title:  (
        <div style={{ textAlign: 'center'}}>
            <div>Sección electoral</div>
            <br/>
            <Input
                  value={searchSeccionCasilla}
                  onChange={({target:{ value}}) => setSearchSeccionCasilla(value)}
                  placeholder='Sección electoral'
                  suffix = {<img style={{width:12 , height:12}} src={iconoFiltro}/>}
                  onKeyPress={(e) => {if (e.key === 'Enter') {props.searchSeccion(searchSeccionCasilla)}}} 
                  />
        </div>
      ),
      align: 'center',
      dataIndex: 'seccion',
      key: 'seccion',
    },
    {
      title: (
        <div style={{ textAlign: 'center' }}>
            <div>Acciones</div>
            <br/>
        </div>
      ),
      align: 'center',
      dataIndex: 'acciones',
      key: 'acciones',
      render: (fila, record) => 
      props.estatusModulo ? (<><Tooltip title={etiquetasCasillas.etiqueta_tooltip_consultar}><a onClick={()=>{props.mostrarModal(record)}}><img style={{width:23 , height:14 }} src={iconoMostrar} id="eyecc-icon"/></a></Tooltip>&nbsp;
                               <Tooltip title={etiquetasCasillas.etiqueta_tooltip_editar}><a onClick={()=> (props.rolPermitido === false) ? '' : props.modificaCasilla(record)}><img style={{width:13 , height:15}} src={iconoEditar} id="edit-icon"/></a></Tooltip>&nbsp;
                               <Tooltip title={etiquetasCasillas.etiqueta_tooltip_eliminar}><Popconfirm
                                                                                              title={<div className="content-popC"><span>La casilla&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br/><b>{record.folio}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b><br/>se eliminará&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                              <br/>¿Desea continuar?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></div>}
                                                                                              onConfirm={eliminarCasilla}
                                                                                              placement="bottom"
                                                                                              disabled = {(record.estatus === 'A') || (props.rolCau === true) || (props.rolPermitido === false) ? true: false}
                                                                                              okText="Sí"
                                                                                              cancelText="No"
                                                                                              icon={null}
                                                                                              ><a onClick={()=>{revisarEstatus(record)}}><img style={{width:16 , height:18}} src={iconoEliminar} id="deleted-icon"/></a></Popconfirm></Tooltip>&nbsp;</>):
                               (<><Tooltip title={etiquetasCasillas.etiqueta_tooltip_consultar}><a><img style={{width:23 , height:14 }} src={iconoMostrar} id="eyecc-icon"/></a></Tooltip>&nbsp;
                               <Tooltip title={etiquetasCasillas.etiqueta_tooltip_editar}><a><img style={{width:13 , height:15}} src={iconoEditar} id="edit-icon"/></a></Tooltip>&nbsp;
                               <Tooltip title={etiquetasCasillas.etiqueta_tooltip_eliminar}><a><img style={{width:16 , height:18}} src={iconoBasura} id="deleted-icon"/></a></Tooltip>&nbsp;</>)
                                
    },
    {
      title:(
        <div style={{ textAlign: 'center' }}>
            <div>
              <Tooltip title={etiquetasCasillas.etiqueta_tooltip_acciones}>
                <img style={{width:17 , height:17}} src={iconoQuestion}/>
              </Tooltip>
            </div>
            <br />
          </div>),
      dataIndex:'info',
      key:'info'
    },
  ];


  const columnsConsulta =[
    {
      title:'Fecha de inicio',
      align: 'center',
      dataIndex: 'fechaFormatoA',
      key:'fechaFormatoA'
    },
    {
      title:'Fecha de término',
      align: 'center',
      dataIndex: 'fechaFormatoC',
      key:'fechaFormatoC'
    },
    {
      title:'Hora de inicio',
      align: 'center',
      dataIndex: 'horaFormatoA',
      key:'horaFormatoA'
    },
    {
      title:'Hora de término',
      align: 'center',
      dataIndex: 'horaFormatoC',
      key:'horaFormatoC'
    },
]

const columnsFechas=[
  {
    title:"Fecha de inicio",
    dataIndex: "fechaFI",
    key: "fechaFI"
  },
  {
    title:"Fecha de término",
    dataIndex: "fechaFC",
    key: "fechaFC"
  },
  {
    title:"Hora de inicio",
    dataIndex: "horaHI",
    key: "horaHI"
  },
  {
    title:"Hora de término",
    dataIndex: "horaHC",
    key: "horaHC"
  },
  {
    title:"Acciones",
    dataIndex: "acciones",
    key: "acciones",
    render: (_, record) =>
          data.length >= 1 ? (
              <a onClick={()=> props.rolCau === true ? '': eliminar(record.key)}><img style={{width:19 , height:19}} src={eliminarFecha} id="edit-icon"/></a>
          ) : null,
  },
  
];

function disabledDate(current) {
  return (current && current > moment(new Date('2021-12-31')).endOf('day')) || (current && current < moment(new Date('2021-11-01')).endOf('month'));
}

const eliminar = (key) => {
    try{

        console.log("entro en delete");
        props.handleDelete(key);
        const newData = [...data];
        setData(newData.filter((item) => item.key !== key));

    }catch(error){
      console.log("Error en eliminar valor a tabla", error)
    }
}

const revisarfechas = () =>{

  var fechaUno = fechaIns;
  var fechaDos = fechaCer;

  if(fechaUno !== '' || fechaDos !== ''){


      var conv1 = moment(fechaIns, 'DD/MMMM/YY');
      var conv2 = moment(fechaCer, 'DD/MMMM/YY');

      if((conv1 > conv2)){
          setFechaIncorrecta(true)
      }else{
          setFechaIncorrecta(false)
      }
  }
}



const revisarHorarios = () =>{

  var horaUno = horaIns;
  var horaDos = horaCer;

  if(horaUno !== '' || horaDos !== ''){

      var conv1 = moment(horaIns, 'HH:mm');
      var conv2 = moment(horaCer, 'HH:mm');

      if((conv1 >= conv2)){
          setHoraIncorrecta(true)
      }else{
          setHoraIncorrecta(false)
      }
  }
}


const add = () => {
  
  try{

    let banderaAgregar = false;
    let banderaAgregarFechas = false;
    let validador = false;
    let validadorDosMeses = false;

    //fechas y horas ingresadas y que se van a analizar
    var conv1 = moment(fechaIns, 'DD/MMMM/YY');
    var conv2 = moment(fechaCer, 'DD/MMMM/YY');

    var horaconv1 = moment(horaIns, 'HH:mm');
    var horaconv2 = moment(horaCer, 'HH:mm');


    //Validamos meses
    var mes1 = moment(fechaIns, 'DD/MMMM/YY').month();
    var mes2 = moment(fechaCer, 'DD/MMMM/YY').month();        


    if(mes1 === mes2){
        console.log("Son fechas del mismo mes");

        //Vamos a comparar los dias que no se encuentren dentro del rango 
        var dia1 = moment(fechaIns, 'DD/MMMM/YY').date(); //Día de inicio ingresada
        var dia2 = moment(fechaCer, 'DD/MMMM/YY').date(); //Día de término ingresada

        for(var j = 0 ; j < data.length; j++){

            let fechaR1 = data[j].fechaFI;
            let fechaR2 = data[j].fechaFC;

            let diaR1 = moment(fechaR1, 'DD/MMMM/YY').date(); //Día de inicio
            let diaR2 = moment(fechaR2, 'DD/MMMM/YY').date(); //Día de cierre
            let mesR1 = moment(fechaR1, 'DD/MMMM/YY').month();
            let mesR2 = moment(fechaR2, 'DD/MMMM/YY').month();
            let diafinmes11 = moment('30/noviembre/21', 'DD/MMMM/YY').date();
            let diainiciomes12 = moment('01/diciembre/21', 'DD/MMMM/YY').date();

            //Realizamos las 4 validaciones 
            if((mes1 === mesR1) && (mes2 === mesR2)){

                if((diaR2 === dia1)){
                    validador = true; //Me va ayudar a validar 
                }

                if((diaR1 === dia2)){
                    validador = true;
                }

                if((diaR1 < dia1) && (dia1 < diaR2)){
                    validador = true;
                }

                if((diaR1 < dia2) && (dia2 < diaR2)){
                    validador = true;
                }

                if((dia1 < diaR1) && (diaR1 < dia2)){
                    validador = true;
                }

                if((dia1 < diaR2) && (diaR2 < dia2)){
                    validador = true;
                }
            }

            //Revisar que el rango que quiero ingresar no este dentro del rango de 2 meses
            if(mesR1 !== mesR2) {

                console.log("Tengo un rango entre dos meses");
                
                if((diaR2 === dia1)){
                    validador = true;
                }

                if((diaR1 === dia2)){
                    validador = true;
                }

                //Revisar los casos que se presentan

                if((mesR1 === mes1 && mesR1 === mes2)){

                    console.log("entre a una fecha del mismo mes")

                    if((diaR1 < dia1) && (dia1 <= diafinmes11)){
                        validador = true;
                    }

                    if((diaR1 < dia2) && (dia2 <= diafinmes11)){
                        validador = true;
                    }

                }

                if((mesR2 === mes1 && mesR2 === mes2)){

                    if((diainiciomes12 <= dia1) && (dia1 < diaR2)){
                        validador = true;
                    }

                    if((diainiciomes12 <= dia2) && (dia2 < diaR2)){
                        validador = true;
                    }

                }
            }

        }
    }else{
        console.log("es un rango de fechas con meses diferentes");

        //Vamos a comparar los dias que no se encuentren dentro del rango 
        var dia1 = moment(fechaIns, 'DD/MMMM/YY').date(); //Día de inicio ingresada
        var dia2 = moment(fechaCer, 'DD/MMMM/YY').date(); //Día de término ingresada

        for(var j = 0 ; j < data.length; j++){

            let fechaR1 = data[j].fechaFI;
            let fechaR2 = data[j].fechaFC;

            let diaR1 = moment(fechaR1, 'DD/MMMM/YY').date(); //Día de inicio
            let diaR2 = moment(fechaR2, 'DD/MMMM/YY').date(); //Día de cierre
            let mesR1 = moment(fechaR1, 'DD/MMMM/YY').month();
            let mesR2 = moment(fechaR2, 'DD/MMMM/YY').month();
            let diafinmes11 = moment('30/noviembre/21', 'DD/MMMM/YY').date();
            let diainiciomes12 = moment('01/diciembre/21', 'DD/MMMM/YY').date();

            //Realizamos las validaciones 
            //Fecha de inicio

            if((diaR2 === dia1)){
                validador = true;
            }

            if((diaR1 === dia2)){
                validador = true;
            }

            if((diaR1 < dia1) && (dia1 < diaR2)){ //Reviso que no se encuentre entre los rangos ya ingresados
                validadorDosMeses = true;
            }

            //Fecha de término 
            if((diaR1 < dia2) && (dia2 < diaR2)){
                validadorDosMeses = true;
            }
            
            //Hay que revisar que el rango que esta en tabla no este dentro del rango que vamos a ingresar 
            if((mes1 === mesR1) && (mes2 === mesR2)){

                console.log("estas fechas estan dentro de un solo mes");

                //Vamos a revisar los dias
                if((diaR1 < dia1) && (dia1 < diafinmes11)){
                    
                    validadorDosMeses = true;

                }

                if((diaR1 < dia2) && (dia2 < diafinmes11)){

                    validadorDosMeses = true;
                }

                if((dia1 < diaR1) && (diaR1 < diafinmes11)){
                    validadorDosMeses = true;
                }

                if((diainiciomes12 < diaR2) && (diaR2 < dia2)){
                    validadorDosMeses = true;
                }

                if((diaR1 < dia1) && (dia1 < diafinmes11)){
                    validadorDosMeses = true;
                }

                if((diainiciomes12 < dia2) && (dia2 < diaR2)){
                    validadorDosMeses = true;
                }


            }

        }

    }


    //Validamos las horas 
    //Vamos a realizar validaciones en las fechas 

    let diaI = moment(fechaIns, 'DD/MMMM/YY').date();
    let diaC = moment(fechaCer, 'DD/MMMM/YY').date(); 
    let mesI = moment(fechaIns, 'DD/MMMM/YY').month();
    let mesC = moment(fechaCer, 'DD/MMMM/YY').month();
    let anioI = moment(fechaIns, 'DD/MMMM/YY').year();
    let anioC = moment(fechaCer, 'DD/MMMM/YY').year();
    let diafinmes11 = moment('30/noviembre/21', 'DD/MMMM/YY').date();
    let diainiciomes12 = moment('01/diciembre/21', 'DD/MMMM/YY').date();

    for (var i = 0; i < data.length; i++){

        let fechaR1 = data[i].fechaFI;
        let fechaR2 = data[i].fechaFC;
        let horaR1 = data[i].horaHI;
        let horaR2 = data[i].horaHC;

        let diaR1 = moment(fechaR1, 'DD/MMMM/YY').date();
        let diaR2 = moment(fechaR2, 'DD/MMMM/YY').date(); 
        let mesR1 = moment(fechaR1, 'DD/MMMM/YY').month();
        let mesR2 = moment(fechaR2, 'DD/MMMM/YY').month();
        let anioR1 = moment(fechaR1, 'DD/MMMM/YY').year();
        let anioR2 = moment(fechaR2, 'DD/MMMM/YY').year();

        if((diaI === diaR1) && (mesI === mesR1) && (anioI === anioR1)) { //Reviso cuando son las mismas fechas 
            if((diaC === diaR2) && (mesC === mesR2) && (anioC === anioR2)){

                console.log("fecha exactamente iguales")

                //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                if((horaIns >= horaR1) && (horaIns <= horaR2)){
                    banderaAgregar = true;
                }

                //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                if((horaCer <= horaR2) && (horaCer >= horaR1)){
                    banderaAgregar = true;
                }

                //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                    banderaAgregar= true;
                }

                if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                    banderaAgregar= true;
                }

                if(horaR1 === horaIns){
                    if(horaR2 === horaCer){
                        banderaAgregar = true;
                    }
                }
            }
        }

        if(validador === true){

            console.log("fechas que tienen que ser validadas sus horas")

            //Revisa Extremo
            if((diaR2 === diaC) && (mesR2 === mesC)){


                //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                if((horaIns >= horaR1) && (horaIns <= horaR2)){
                    banderaAgregar = true;
                }

                //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                if((horaCer <= horaR2) && (horaCer >= horaR1)){
                    banderaAgregar = true;
                }

                //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                    banderaAgregar= true;
                }

                if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                    banderaAgregar= true;
                }

                if(horaR1 === horaIns){
                    if(horaR2 === horaCer){
                        banderaAgregar = true;
                    }
                }   
            }

            if((mes1 === mesR1) && (mes2 === mesR1)){

                //Fechas dentro de un rengo
                if((diaR1 < dia1) && (dia1 <= diaR2) && (diaR1 < dia2) && (dia2 <= diaR2)){

                    //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                    if((horaIns >= horaR1) && (horaIns <= horaR2)){
                        banderaAgregar = true;
                    }

                    //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                    if((horaCer <= horaR2) && (horaCer >= horaR1)){
                        banderaAgregar = true;
                    }

                    //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                    if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                        banderaAgregar= true;
                    }

                    if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                        banderaAgregar= true;
                    }

                    if(horaR1 === horaIns){
                        if(horaR2 === horaCer){
                            banderaAgregar = true;
                        }
                    }

                }

                if((diaR1 < dia1) && (dia1 <= diaR2)){

                    //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                    if((horaIns >= horaR1) && (horaIns <= horaR2)){
                        banderaAgregar = true;
                    }

                    //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                    if((horaCer <= horaR2) && (horaCer >= horaR1)){
                        banderaAgregar = true;
                    }

                    //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                    if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                        banderaAgregar= true;
                    }

                    if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                        banderaAgregar= true;
                    }

                    if(horaR1 === horaIns){
                        if(horaR2 === horaCer){
                            banderaAgregar = true;
                        }
                    }

                }

                if((diaR1 < dia2) && (dia2 <= diaR2)){

                    //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                    if((horaIns >= horaR1) && (horaIns <= horaR2)){
                        banderaAgregar = true;
                    }

                    //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                    if((horaCer <= horaR2) && (horaCer >= horaR1)){
                        banderaAgregar = true;
                    }

                    //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                    if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                        banderaAgregar= true;
                    }

                    if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                        banderaAgregar= true;
                    }

                    if(horaR1 === horaIns){
                        if(horaR2 === horaCer){
                            banderaAgregar = true;
                        }
                    }

                }
            
            }

            if((mes1 === mesR1) && (mes2 === mesR1)){
                if((dia1 < diaR1) && (diaR1 <= dia2) && (dia1 < diaR2) && (diaR2 <= dia2)){

                    //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                    if((horaIns >= horaR1) && (horaIns <= horaR2)){
                        banderaAgregar = true;
                    }

                    //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                    if((horaCer <= horaR2) && (horaCer >= horaR1)){
                        banderaAgregar = true;
                    }

                    //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                    if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                        banderaAgregar= true;
                    }

                    if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                        banderaAgregar= true;
                    }

                    if(horaR1 === horaIns){
                        if(horaR2 === horaCer){
                            banderaAgregar = true;
                        }
                    }

                }
            }


            if((diaR1 === dia2)){

                //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                if((horaIns >= horaR1) && (horaIns <= horaR2)){
                    banderaAgregar = true;
                }

                //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                if((horaCer <= horaR2) && (horaCer >= horaR1)){
                    banderaAgregar = true;
                }

                //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                    banderaAgregar= true;
                }

                if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                    banderaAgregar= true;
                }

                if(horaR1 === horaIns){
                    if(horaR2 === horaCer){
                        banderaAgregar = true;
                    }
                }

            }

            if((diaR1 === diaI) && (mesR1 === mesC)){

                //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                if((horaIns >= horaR1) && (horaIns <= horaR2)){
                    banderaAgregar = true;
                }

                //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                if((horaCer <= horaR2) && (horaCer >= horaR1)){
                    banderaAgregar = true;
                }

                //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                    banderaAgregar= true;
                }

                if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                    banderaAgregar= true;
                }

                if(horaR1 === horaIns){
                    if(horaR2 === horaCer){
                        banderaAgregar = true;
                    }
                }

            }

            //Rango entre meses
            if(mesR1 !== mesR2){
                
                if((dia1 < diaR1) && (diaR1 <= dia2) && (dia1 < diaR2) && (diaR2 <= dia2)){
                //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                    if((horaIns >= horaR1) && (horaIns <= horaR2)){
                        banderaAgregar = true;
                    }

                    //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                    if((horaCer <= horaR2) && (horaCer >= horaR1)){
                        banderaAgregar = true;
                    }

                    //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                    if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                        banderaAgregar= true;
                    }

                    if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                        banderaAgregar= true;
                    }

                    if(horaR1 === horaIns){
                        if(horaR2 === horaCer){
                            banderaAgregar = true;
                        }
                    }

                }

                if((diaR1 < dia1) && (dia1 <= diaR2) && (diaR1 < dia2) && (dia2 <= diaR2)){
                    //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                        if((horaIns >= horaR1) && (horaIns <= horaR2)){
                            banderaAgregar = true;
                        }

                        //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                        if((horaCer <= horaR2) && (horaCer >= horaR1)){
                            banderaAgregar = true;
                        }

                        //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                        if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                            banderaAgregar= true;
                        }

                        if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                            banderaAgregar= true;
                        }

                        if(horaR1 === horaIns){
                            if(horaR2 === horaCer){
                                banderaAgregar = true;
                            }
                        }
                }

                if((mes1 === mesR2) && (mes2 === mesR2)){

                    
                    if((diainiciomes12 < dia1) && (dia1 < diaR2)){
                        if((horaIns >= horaR1) && (horaIns <= horaR2)){
                            banderaAgregar = true;
                        }

                        //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                        if((horaCer <= horaR2) && (horaCer >= horaR1)){
                            banderaAgregar = true;
                        }

                        //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                        if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                            banderaAgregar= true;
                        }

                        if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                            banderaAgregar= true;
                        }

                        if(horaR1 === horaIns){
                            if(horaR2 === horaCer){
                                banderaAgregar = true;
                            }
                        }

                }} 

                if((mes1 === mesR1) && (mes2 === mesR1)){

                    if((horaIns >= horaR1) && (horaIns <= horaR2)){
                        banderaAgregar = true;
                    }

                    //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                    if((horaCer <= horaR2) && (horaCer >= horaR1)){
                        banderaAgregar = true;
                    }

                    //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                    if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                        banderaAgregar= true;
                    }

                    if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                        banderaAgregar= true;
                    }

                    if(horaR1 === horaIns){
                        if(horaR2 === horaCer){
                            banderaAgregar = true;
                        }
                    }

                } 
        }


        }

        if(validadorDosMeses === true){

            console.log("voy a validar horas entre dos meses");

            if(mesR1 !== mesR2){

                //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                if((horaIns >= horaR1) && (horaIns <= horaR2)){
                    banderaAgregar = true;
                }

                //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                if((horaCer <= horaR2) && (horaCer >= horaR1)){
                    banderaAgregar = true;
                }

                //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                    banderaAgregar= true;
                }

                if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                    banderaAgregar= true;
                }

                if(horaR1 === horaIns){
                    if(horaR2 === horaCer){
                        banderaAgregar = true;
                    }
                }



            }

            if((mes1 === mesR1) && (mes1 === mesR2)){

                //Fechas dentro de un rengo
                if((diaR1 < dia1) && (dia1 <= diaR2) && (diaR1 < dia2) && (dia2 <= diaR2)){


                    //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                    if((horaIns >= horaR1) && (horaIns <= horaR2)){
                        banderaAgregar = true;
                    }

                    //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                    if((horaCer <= horaR2) && (horaCer >= horaR1)){
                        banderaAgregar = true;
                    }

                    //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                    if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                        banderaAgregar= true;
                    }

                    if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                        banderaAgregar= true;
                    }

                    if(horaR1 === horaIns){
                        if(horaR2 === horaCer){
                            banderaAgregar = true;
                        }
                    }

                }


                if((mes1 === mesR1) && (mes2 === mesR1)){
                    if((diaR1 < dia1) && (dia1 <= diaR2)){

                    //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                        if((horaIns >= horaR1) && (horaIns <= horaR2)){
                            banderaAgregar = true;
                        }

                        //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                        if((horaCer <= horaR2) && (horaCer >= horaR1)){
                            banderaAgregar = true;
                        }

                        //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                        if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                            banderaAgregar= true;
                        }

                        if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                            banderaAgregar= true;
                        }

                        if(horaR1 === horaIns){
                            if(horaR2 === horaCer){
                                banderaAgregar = true;
                            }
                        }

                    }
                }

                if((mes1 === mesR1) && (mes2 === mesR1)){
                    if((diaR1 < dia2) && (dia2 <= diaR2)){

                        //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                        if((horaIns >= horaR1) && (horaIns <= horaR2)){
                            banderaAgregar = true;
                        }

                        //No se encuentre la hora de Inicio dentro del rango de alguna otra fecha
                        if((horaCer <= horaR2) && (horaCer >= horaR1)){
                            banderaAgregar = true;
                        }

                        //Revisar que los horarios de la tabla no esten dentro del rango de los horarios ingresados 
                        if((horaIns <= horaR1) && (horaR1 <= horaCer)){
                            banderaAgregar= true;
                        }

                        if((horaIns <= horaR2) && (horaR2 <= horaCer)){
                            banderaAgregar= true;
                        }

                        if(horaR1 === horaIns){
                            if(horaR2 === horaCer){
                                banderaAgregar = true;
                            }
                        }

                    }
                }
            
            }
        }
    }

    if((conv1 > conv2)){
      setmessage('Revisar las fechas, fueron mal ingresadas');
    }else if(horaconv1 >= horaconv2 ){
      setmessage('Revisar los horarios, fueron mal ingresados');
    }else if(banderaAgregar === false && banderaAgregarFechas === false){
      
      const newk = data.length;
      const newData = [...data];

      newData.push({
          key: newk,
          fechaFI: fechaIns,
          fechaFC: fechaCer,
          horaHI: horaIns,
          horaHC: horaCer,
      })

      setData(newData)
      props.handleClick(newk, fechaIns, fechaCer, horaIns, horaCer);

    }else{
      setIsModalVisible(true);
    }


  }catch(error){
    console.log("Error en agregar valor a tabla", error)
  }
}


  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const content = (
    <div className="cuerpo-contentModifica">
      <span >Las fechas y horarios no</span><br/>
      <span >deben sobreponerse.</span><br/><br/>
      <span className="confirmModifica">Elige otra opción</span><br/>
    </div>
  );

  return(

          <>
          <Layout className='validar_modulo consulta'>
            <Alert icon={icon}
                 message={props.mensajeAlerta}
                 type={props.tipoAlerta} showIcon
                 style ={props.mostrarAlerta}/>
            <div style={{display: props.modifica ? "none" : "block"}} id="casillasConsultaTabla">
              <div id="seccion1">
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <h1 id="titulo-componente">Consultar y modificar</h1>
                  </Col>
                </Row>
              </div>
                <Card title={<span className="tituloTablaCasillas">Casillas registradas</span>} bordered={true} style={{ marginBottom: '24px', textAlign: 'center'}}  size="small">
                  <div id="tabla-consulta-casillas">
                        <Table columns={columns} dataSource={props.data} pagination={false} size="small" className="tabla-casillas"/>
                      <br/>
                  </div>
                </Card>
                <br/>
                <div id="paginado">
                <Row justify="center">
                  <Col xs={24} sm={24} md={{span:1, offset:2}} lg={{span:1, offset:2}} xl={{span:1, offset:2}}>
                    <HomeFilled onClick={props.regresarPrimeraPagina} />
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                 
                    <Pagination current={props.actual}
                                onChange={props.paginado} 
                                total={props.total}
                                defaultPageSize={5}
                                loading={props.cargando}
                                size="small"
                                showSizeChanger={false}
                                />
                  </Col>
                </Row>
              </div>
            </div>

            <div  style={{display:props.modifica ? "block" : "none"}}>
            <div id="seccion1">
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <h1 id="titulo-componente">Modificar información<br/>
                    <span className="casillaConsulta">Casilla: {props.casillaModificar.folio}</span></h1>
                  </Col>
                </Row>
              </div>
              <Tabs centered = {true} defaultActiveKey="1" activeKey={props.activeTabModifica} onChange={props.changeTabModifica} size="large" className="tab-modal">
                <TabPane disabled tab="Ubicación" key="1">
                  <div>
                    <h4>Captura los datos requeridos. Los datos con (<b><font color="red">*</font></b>) son obligatorios.</h4>
                  </div>
                <br/>
                <Form form = {form}>
                  <Card bordered={false}>
                    <Row>
                      <Col sm={24} md={6} lg={6}>
                        <label><b><font color="red">*</font></b>Municipio/alcaldía:&nbsp;{props.casillaModificar.nombreMunicipio}</label>
                      </Col>
                      <Col sm={24} md={6} lg={6}>
                        <label><b><font color="red">*</font></b>Sección electoral:&nbsp;{props.casillaModificar.seccion}</label>
                      </Col>
                      <Col sm={24} md={6} lg={6}>
                        <label><b><font color="red">*</font></b>Tipo de casilla:&nbsp;{props.casillaModificar.tipoCasilla}</label>
                      </Col>
                      <Col sm={24} md={6} lg={6}>
                        <label><b><font color="red">*</font></b>ZOR asignada:&nbsp;{props.casillaModificar.numeroZore} - {props.casillaModificar.tipoZore}</label>
                      </Col>
                    </Row>
                    <br/>
                    <br/>
                    <div style={{display: ((tipoCasilla === 3)) ? 'none':'block'}}>
                    <Row>
                        <Col sm={24} md={8} lg={8}>
                            <label><b><font color="red">*</font></b>Calle</label>
                        </Col>
                        <Col sm={24} md={4} lg={4}>
                            <label><b><font color="red">*</font></b>Número</label>
                        </Col>
                        <Col sm={24} md={4} lg={4}>
                            <label><b><font color="red">*</font></b>Código Postal</label>
                        </Col>
                        <Col sm={24} md={8} lg={8}>
                            <label><b><font color="red">*</font></b>Colonia</label>
                        </Col>
                    </Row>
                    <Row gutter = {[16,16]}>
                        <Col sm={24} md={8} lg={8}>
                            <Form.Item form = {form} name="calle" rules={[{required: true, message: 'Dato requerido'}, { pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}>
                            <Input
                              name="calle"
                              onChange = {(e) => {setCalle(e.target.value)
                                                  props.cambioText(e)
                                                  revisarCalle(e)}}
                              maxLength={100}
                              rules={[{
                                     max: 100
                                     , pattern: etiquetasCasillas.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                           }]}></Input>
                        </Form.Item>
                        </Col>
                        <Col sm={24} md={4} lg={4}>
                            <Form.Item form = {form} name="numero" rules={[{required: true, message: 'Dato requerido'}]} rules={[{pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}>
                            <Input
                                defaultValue={num}
                                name = "num"
                                maxLength={5}
                                onChange = {(e) => {setNum(e.target.value)
                                                    props.cambioText(e)
                                                    revisarNumero(e)}}
                                rules={[{
                                min: 1
                                , max: 5,
                                pattern: "^[0-9]+"
                                }]}></Input>
                            </Form.Item>
                        </Col>
                        <Col sm={24} md={4} lg={4}>
                            <Form.Item form = {form} name="codigoPostal" rules={[{required: true, message: 'Dato requerido'}]} rules={[{ pattern: /^\d*[1-9]+\d*$/, message: 'Solo números'}, {pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}
                                                                         validateStatus={(codigoPostal === undefined || codigoPostal === null) ? null: (codigoPostal.toString().length === 5) ? null:'error'} help={(codigoPostal === undefined || codigoPostal === null) ? null: (codigoPostal.toString().length === 5) ? null:'Formato incorrecto'} >
                            <Input
                                defaultValue={codigoPostal}
                                name = "cp"
                                onChange = {(e) => {setCodigoPostal(e.target.value)
                                                    props.cambioText(e)
                                                    revisarCp(e)}}
                                maxLength={5}
                                rules={[{
                                     len: 5
                                     , pattern: /^\d{5,5}$/i
                            }]}></Input>
                            </Form.Item>
                        </Col>

                        <Col sm={24} md={8} lg={8}>
                            <Form.Item form = {form} name="colonia" rules={[{required: true, message: 'Dato requerido'}, {pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}>
                            <Input
                                defaultValue={colonia}
                                name = "colonia"
                                onChange = {(e) => {setColonia(e.target.value)
                                                    props.cambioText(e)
                                                    revisarCol(e)}}
                                maxLength={50}
                                rules={[{
                                     max: 50
                                     , pattern: etiquetasCasillas.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                }]}></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                    <br/>
                    </div>
                    <div style={{display: ((tipoCasilla === 1) || (tipoCasilla === 2) || (tipoCasilla === 3)) ? 'none':'block'}}>
                    <Row>
                        <Col sm={24} md={12} lg={12}>
                            <label><b><font color="red">*</font></b>Lugar</label>
                        </Col>
                        <Col sm={24} md={12} lg={12}>
                            <label><b><font color="red">*</font></b>Referencia</label>
                        </Col>
                    </Row>
                    <Row gutter={[16,16]}>
                        <Col sm={24} md={12} lg={12}>
                            <Form.Item form = {form} name="lugar" rules={[{required: true, message: 'Dato requerido'}, {pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}>
                            <Input 
                                defaultValue={lugar}
                                name = "lugar"
                                onChange = {(e) => {setLugar(e.target.value)
                                                    props.cambioText(e)
                                                    revisarLugar(e)}}
                                maxLength={500}
                                rules={[{
                                     max: 500
                                     , pattern: etiquetasCasillas.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                    }]}></Input>
                            </Form.Item>
                        </Col>
                        <Col sm={24} md={12} lg={12}>
                            <Form.Item form = {form} name="referencia" rules={[{required: true, message: 'Dato requerido'}, {pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}>
                            <Input
                                defaultValue={referencia}
                                name = "referencia"
                                onChange = {(e) => {setReferencia(e.target.value)
                                                    props.cambioText(e)
                                                    revisarReferencia(e)}}
                                maxLength={500}
                                rules={[{
                                            max: 500
                                            , pattern: etiquetasCasillas.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                }]}></Input>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col offset={8} sm={24} md={4} lg={4}>
                            <span>{valres}&nbsp;caracteres restantes</span>
                        </Col>
                        <Col offset={8} sm={24} md={4} lg={4}>
                            <span>{valresReferencia}&nbsp;caracteres restantes</span>
                        </Col>
                    </Row>
                    <br/>

                    <Row>
                        <Col sm={24} md={12} lg={12}>
                            <label>Nombre del contacto</label>
                        </Col>
                        <Col sm={24} md={12} lg={12}>
                            <label>Teléfono del contacto</label>
                        </Col>
                    </Row>
                    <Row gutter = {[16,16]}>
                        <Col sm={24} md={12} lg={12}>
                          <Form.Item form = {form} name="nombreContacto" rules={[{ pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}, { pattern: new RegExp("^[ñíóáéúÁÉÍÓÚÑü a-zA-Z ]+$"), message: 'Los números no son permitidos.'}]}>
                            <Input
                                defaultValue={nombreC}
                                name = "nombreContacto"
                                onChange = {(e) => {setNombreC(e.target.value)
                                                    props.cambioText(e)
                                                    revisarNombreC(e)}}
                                maxLength={250}
                                rules={[{
                                     max: 250
                                     , pattern: etiquetasCasillas.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                }]}></Input>
                          </Form.Item>
                        </Col>
                        <Col sm={24} md={12} lg={12}>
                          <Form.Item form = {form} name="telefonoContacto" rules={[{ pattern: /^\d*[1-9]+\d*$/, message: 'Solo números'}, {pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}
                                                                           validateStatus={(telC === undefined || telC === '' || telC === null) ? null : telC.toString().length === 10 ? null:'error'} help={(telC === undefined || telC === '' || telC === null) ? null : telC.toString().length === 10 ? null:'Formato incorrecto'}>
                            <Input
                                defaultValue={telC}
                                name = "telefonoC"
                                onChange = {(e) => {setTelC(e.target.value)
                                                    props.cambioText(e)
                                                    revisarTel(e)}}
                                maxLength={10}
                                rules={[{
                                     min: 8
                                     , max: 10
                                     , pattern: etiquetasCasillas.REGEX_FORMATO_TELEFONO
                                }]}
                                custom={{
                                    getValueFromEvent: (event, previo) => {
                                        if(etiquetasCasillas.REGEX_FORMATO_SOLO_DIGITOS.test(event.target.value)){
                                            return	event.target.value;
                                        }
                                        return previo;
                                    }
                                }}></Input>
                          </Form.Item>
                        </Col>
                    </Row>
                  </div>
                  <div style={{display: ((tipoCasilla === 1) || (tipoCasilla === 2) || (tipoCasilla === 3)) ? 'block':'none'}}>
                  <Row>
                        <Col sm={24} md={12} lg={12}>
                            <label><b><font color="red">*</font></b>Lugar</label>
                        </Col>
                        <Col sm={24} md={4} lg={4}>
                            <label><b><font color="red">*</font></b>Clave de la escuela</label>
                        </Col>
                        <Col sm={24} md={4} lg={4}>
                              <label><b><font color="red">*</font></b>Turno de la escuela</label>
                        </Col>
                        <Col sm={24} md={4} lg={4}>
                            <label><b><font color="red">*</font></b>Nivel educativo</label>
                        </Col>
                    </Row>
                    <Row gutter = {[16,16]}>
                        <Col sm={24} md={12} lg={12}>
                            <Form.Item form = {form} name="lugar" rules={[{required: true, message: 'Dato requerido'}, {pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}>
                            <Input 
                                name = "lugar"
                                onChange = {(e) => {setLugar(e.target.value)
                                                    props.cambioText(e)
                                                    revisarLugar(e)}}
                                maxLength={500}
                                rules={[{
                                     max: 500
                                     , pattern: etiquetasCasillas.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                    }]}></Input>
                            </Form.Item>
                        </Col>
                        <Col sm={24} md={4} lg={4}>
                            <Form.Item form = {form} name="claveEscuela" rules={[{required: true, message: 'Dato requerido'}, {pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}>
                            <Input
                                name = "claveEscuela"
                                onChange = {(e) => {setClaveE(e.target.value)
                                                    props.cambioText(e)
                                                    revisarClaveE(e)}}
                                maxLength={500}
                                rules={[{
                                            max: 500
                                            , pattern: etiquetasCasillas.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                }]}></Input>
                            </Form.Item>
                        </Col>
                        <Col sm={24} md={4} lg={4}>
                                <Form.Item form = {form} name="turno">
                                    <Select onChange={(e) => { setTurno(e)
                                                               props.selectTurnoEscuela(e)}} value = {turno}>
                                      {props.lstTurnosEscuelas.map((value, index) => {
                                        return(                                          
                                            <Option value={value.idTurnoEscuela} >                                
                                                <tr key={index}>                                                                                        
                                                    {value.turno}                                            
                                                </tr>
                                            </Option>
                                        )
                                      })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={4} lg={4}>
                                <Form.Item form = {form} name="nivelEducativo" rules={[{required: true, message: 'Dato requerido'}]}>
                                    <Select onChange={(e) => { setNivelE(e)
                                                               props.selectNivelEducativo(e)}} value = {nivelE}>
                                      {props.lstNivelesEducativos.map((value, index) => {
                                        return(                                          
                                            <Option value={value.idNivelEducativo} >                                
                                                <tr key={index}>                                                                                        
                                                    {value.nivelEducativo}                                            
                                                </tr>
                                            </Option>
                                        )
                                      })}
                                    </Select>
                                </Form.Item>
                            </Col>
                      </Row>

                      <Row>
                          <Col offset={8}sm={24} md={4} lg={4}>
                              <span>{valres}&nbsp;caracteres restantes</span>
                          </Col>
                      </Row>
                      <br/>


                    <Row>
                        <Col sm={24} md={12} lg={12}>
                            <label><b><font color="red">*</font></b>Nombre del contacto</label>
                        </Col>
                        <Col sm={24} md={8} lg={8}>
                            <label><b><font color="red">*</font></b>Teléfono del contacto</label>
                        </Col>
                    </Row>
                    <Row gutter = {[16,16]}>
                        <Col sm={24} md={12} lg={12}>
                          <Form.Item form = {form} name="nombreContacto" rules={[{required: true, message: 'Dato requerido'}, { pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}, { pattern: new RegExp("^[ñíóáéúÁÉÓÚÍÑü a-zA-Z ]+$"), message: 'Los números no son permitidos.'}]}>
                            <Input
                                defaultValue={nombreC}
                                name = "nombreContacto"
                                onChange = {(e) => {setNombreC(e.target.value)
                                                    props.cambioText(e)
                                                    revisarNombreC(e)}}
                                maxLength={250}
                                rules={[{
                                     max: 250
                                     , pattern: etiquetasCasillas.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                }]}></Input>
                          </Form.Item>
                        </Col>
                        <Col sm={24} md={8} lg={8}>
                          <Form.Item form = {form} name="telefonoContacto" rules={[{ pattern: /^\d*[1-9]+\d*$/, message: 'Solo números'}, {pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}
                                                                           validateStatus={(telC === undefined || telC === null) ? null : telC.toString().length === 10 ? null:'error'} help={(telC === undefined ||  telC === null) ? null : telC.toString().length === 10 ? null:'Formato incorrecto'}>
                            <Input
                                defaultValue={telC}
                                name = "telefonoC"
                                onChange = {(e) => {setTelC(e.target.value)
                                                    props.cambioText(e)
                                                    revisarTel(e)}}
                                maxLength={10}
                                rules={[{
                                     min: 8
                                     , max: 10
                                     , pattern: etiquetasCasillas.REGEX_FORMATO_TELEFONO
                                }]}
                                custom={{
                                    getValueFromEvent: (event, previo) => {
                                        if(etiquetasCasillas.REGEX_FORMATO_SOLO_DIGITOS.test(event.target.value)){
                                            return	event.target.value;
                                        }
                                        return previo;
                                    }
                                }}></Input>
                          </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={24} md={6} lg={6}>
                            <label><b><font color="red">*</font></b>Referencias</label>
                        </Col>
                    </Row>
                    <Row gutter = {[16,16]}>
                        <Col sm={24} md={12} lg={12}>
                            <Form.Item form = {form} name="referencia" rules={[{required: true, message: 'Dato requerido'}, {pattern: /^[^\|\\\u00B4\"\<\>\@\&\=]*$/i, message: 'Los siguientes caracteres no son permitidos (/:*?”<>).'}]}>
                            <Input 
                                name = "referencia"
                                onChange = {(e) => {setReferencia(e.target.value)
                                                    props.cambioText(e)
                                                    revisarReferencia(e)}}
                                maxLength={500}
                                rules={[{
                                     max: 500
                                     , pattern: etiquetasCasillas.REGEX_FORMATO_NO_CARACTERES_ESPECIALES
                                    }]}></Input>
                            </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                          <Col offset={8}sm={24} md={4} lg={4}>
                              <span>{valresReferencia}&nbsp;caracteres restantes</span>
                          </Col>
                      </Row>



                    </div>

                </Card>
                <Form.Item>
                <div className="div-boton-modifica1">
                <Row gutter={2}>
                    <Col offset={22} sm={1} md={1} lg={1} xl={1}>
                        <Tooltip title={etiquetasCasillas.etiqueta_tooltip_botonIzq}><Button type="primary" htmlType="submit" style={{width: "47px", height: "46px", borderColor: "#ffffff"}} 
                        disabled={(tipoCasilla === 1 || tipoCasilla === 2) ? ((calle !== undefined) && (calle !== '') && (fvalCalle !== true)
                        && (num !== undefined) && (num !== '') && (fvalNum === true)
                        && (codigoPostal !== undefined) && (codigoPostal !== '') && (fvalCp === true)
                        && (colonia !== undefined) && (colonia !== '') && (fvalColonia !== true)
                        && (lugar !== undefined) && (lugar !== '') && (fvalLugar !== true) && (nombreC !== undefined ) && (nombreC !== '') && (fvalNombreC === true)
                        && (telC !== undefined) && (telC !== '') && (claveE !== undefined) && (claveE !== '') && (fvalClaveE !== true)
                        && (referencia !== undefined) && (referencia !== '') && (fvalReferencia !== true) && (fvalNum === true) && (fvalCp === true) && (fvalTel === true) ? false : true):
                        (tipoCasilla === 3) ? ((lugar !== undefined) && (lugar !== '') && (fvalLugar !== true) && (nombreC !== undefined ) && (nombreC !== '') && (fvalNombreC === true)
                        && (telC !== undefined) && (telC !== '') && (fvalTel === true) && (claveE !== undefined) && (claveE !== '') && (fvalClaveE !== true)
                        && (turno !== undefined) && (nivelE !== undefined)
                        && (referencia !== undefined) && (referencia !== '') && (fvalTel === true) && (fvalReferencia !== true) ? false : true):
                        ((calle !== undefined) && (calle !== '') && (fvalCalle !== true) &&
                        (num !== undefined) && (num !== '') && (fvalNum === true) &&
                        (codigoPostal !== undefined) && (codigoPostal !== '') && (fvalCp === true) &&
                        (colonia !== undefined) && (colonia !== '') && (fvalColonia !== true) &&
                        (lugar !== undefined) && (lugar !== '') && (fvalLugar !== true) &&
                        (referencia !== undefined) && (referencia !== '') && (fvalReferencia !== true) && (fvalTel === true) && (fvalNombreC === true) ||
                        (calle !== undefined) && (calle !== '') && (fvalCalle !== true) &&
                        (num !== undefined) && (num !== '') && (fvalNum === true) &&
                        (codigoPostal !== undefined) && (codigoPostal !== '') && (fvalCp === true) &&
                        (colonia !== undefined) && (colonia !== '') && (fvalColonia !== true) &&
                        (lugar !== undefined) && (lugar !== '') && (fvalLugar !== true) &&
                        (referencia !== undefined) && (referencia !== '') && (fvalReferencia !== true) && (fvalTel === true) && (fvalNombreC === true) 
                        && ((telC === null) || (telC === '')) ? false : true)} 
                        onClick={()=>{props.changeTabModifica("2")}} shape="circle" icon={<ArrowRightOutlined />}/></Tooltip>
                    </Col>
                </Row>
                </div>
                </Form.Item>
                </Form>
                </TabPane>
                <TabPane disabled tab="Conectividad" key="2">
                <>
                  <div>
                    <h4>Captura los datos requeridos. Los datos con (<b><font color="red">*</font></b>) son obligatorios.</h4>
                  </div>
                  <br/>
                
                  <Row gutter={{ sm: 16, md: 24, lg: 32 }} justify="center" align="middle">
                    <Col sm={12} md={12} lg={12}>
                      <div>
                      <Form form = {form}>
                        <Card bordered={false} style={{textAlign:'center'}} title={<span>{etiquetasCasillas.datos_conectividad_lbl_titulo}<Tooltip title={etiquetasCasillas.etiqueta_tooltip_conectividad}>&nbsp;<img style={{width:17 , height:17}} src={iconoQuestion}/></Tooltip></span>}>
                            <table className="tablaDispositivos">
                                <thead className="tablaCabezal">
                                    <tr>
                                        <th>Tipo de dispositivo</th>
                                        <th>Cantidad</th>
                                    </tr>
                                </thead>
                                <tbody className="tablaCuerpo">
                                    <tr>
                                        <td>Computadoras de escritorio, laptop&nbsp;</td>
                                        <td>
                                            <Row>
                                                <Col offset={9}>{
                                                  <Form.Item form = {form} name="computadoraEscritorio" noStyle={true}>
                                                    <InputNumber size="small"
                                                                 style={{width:"55px"}}
                                                                 name = "computadoraEscritorio"
                                                                 onChange = {(e) => { setValCE(Math.round(e))
                                                                                      props.changeCE(Math.round(e))}}
                                                                 min={0} max={999}
                                                                 precision={0}>
                                                    </InputNumber>
                                                  </Form.Item>
                                                }</Col>
                                            </Row>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Teléfono inteligente &nbsp;</td>
                                        <td>
                                            <Row>
                                                <Col offset={9}>{
                                                  <Form.Item form = {form} name="telefonoInteligente" noStyle={true}>
                                                    <InputNumber size="small"
                                                                 style={{width:"55px"}}
                                                                 name = "telefonoInteligente"
                                                                 onChange = {(e) => { setValTI(Math.round(e))
                                                                                      props.changeTI(Math.round(e))}}
                                                                 min={0} max={999}
                                                                 precision={0}>
                                                      </InputNumber>
                                                  </Form.Item>
                                              }</Col>
                                            </Row>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Tablet, Ipad &nbsp;</td>
                                        <td>
                                            <Row>
                                                <Col offset={9}>{<Form.Item form = {form} name="tableta" noStyle={true}>
                                                  <InputNumber size="small"
                                                               style={{width:"55px"}}
                                                               name = "tablet"
                                                               onChange = {(e) =>{ setValT(Math.round(e))
                                                                                   props.changeT(Math.round(e))}}
                                                               min={0} max={999}
                                                               precision={0}
                                                               ></InputNumber></Form.Item>}</Col>
                                            </Row>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br/>
                            <label>Total de dispositivos:&nbsp;{(isNaN(valCE + valTI + valT) || (valCE === '') || (valTI === '') || (valT === '')) ? '' : (valCE + valTI + valT)}</label>
                          </Card>
                          </Form>
                          </div>
                      </Col>
               
                    <Col sm={{span:8, offset:2}} md={{span:8, offset:2}} lg={{span:8, offset:2}} >
                        <div id="internet">
                            <label><b><font color="red">*</font></b>¿El lugar cuenta con acceso a internet?</label>&nbsp;&nbsp;
                                <Row>
                                <Form.Item form = {form} name="tieneInternet" noStyle={true}>
                                    <Col offset={5} sm={11} md={11} lg={11}>
                                        <Radio.Group onChange={(e) => {setOpSN(e.target.value)
                                                                       props.opcionSN(e)}} value={opSN}>
                                          <Radio value={"S"}>Sí</Radio>
                                          <Radio value={"N"}>No</Radio>
                                        </Radio.Group>
                                </Col>
                                </Form.Item>
                            </Row>
                        </div>
                    </Col>
                </Row>

                <div className="div-boton-modifica2">               
                <Row gutter={2}>
                    <Col sm={{span: 1, offset:1}} md={{span: 1, offset:1}} lg={{span: 1, offset:1}} xl={{span: 1, offset:1}}>
                        <Tooltip title={etiquetasCasillas.etiqueta_tooltip_botonDer}><Button type="primary" htmlType="submit" style={{width: "47px", height: "46px", borderColor: "#ffffff"}} onClick={()=>{props.changeTabModifica("1")}} shape="circle" icon={<ArrowLeftOutlined />}/></Tooltip>
                    </Col>
                    <Col offset={20} sm={1} md={1} lg={1} xl={1}>
                        <Tooltip title = {etiquetasCasillas.etiqueta_tooltip_botonIzq}><Button type="primary" htmlType="submit" style={{width: "47px", height: "46px", borderColor: "#ffffff"}} 
                        disabled={(props.tieneInternet === "S" || props.tieneInternet === "N") && ((valCE >= 0) && (valCE !== '') && (valCE !== null) && (valTI >= 0) && (valTI !== '') && (valTI !== null) && (valT >=0) && (valT !== '') && (valT !== null)) ? false : true } 
                        onClick={()=>{props.changeTabModifica("3")}} shape="circle" icon={<ArrowRightOutlined />}/></Tooltip>
                    </Col>
                </Row>
                </div> 
                </>
                </TabPane>
                <TabPane disabled tab="Horarios" key="3">
                <Card bordered={false}>
                <div>
                    <h4>Captura los datos requeridos. Los datos con (<b><font color="red">*</font></b>) son obligatorios.&nbsp;
                        <Tooltip title={etiquetasCasillas.etiqueta_tooltip_horarios}><img style={{width:17 , height:17}} src={iconoQuestion}/></Tooltip>
                    </h4>
                </div>
                <br/>
                <Row gutter={{ sm: 8, md: 16, lg: 24 }}>
                    
                    <Col sm={10} md={10} lg={10}>
                    <Form>
                        <Row gutter={{ sm: 8, md: 16, lg: 24 }}>
                            
                            <Col sm={12} md={12} lg={12}>
                                <h4><b><font color="red">*</font></b>{etiquetasCasillas.datos_horarios_lbl_fecha_instalacion}</h4>
                                <Form.Item form = {form} name="fechaInicio" rules={[{required: true, message: 'Dato requerido'}]}
                                              validateStatus={fechaIncorrecta ? "error" : null} help={fechaIncorrecta ? "Dato incorrecto" : null}>
                                  <DatePicker defaultValue={moment('11/01/21')} onChange={(date, dateString) =>{ setFechaIns(dateString)
                                                                                 props.onChangeFI(dateString)
                                                                                 revisarfechas()}} format={'DD/MMMM/YY'} locale={locale} disabledDate={disabledDate}
                                                                                 />
                                </Form.Item>
                            </Col>
                            <Col sm={12} md={12} lg={12}>
                                <h4><b><font color="red">*</font></b>{etiquetasCasillas.datos_horarios_lbl_fecha_cierre}</h4>
                                <Form.Item form = {form} name="fechaCierre" rules={[{required: true, message: 'Dato requerido'}]}
                                                         validateStatus={fechaIncorrecta ? "error" : null} help={fechaIncorrecta ? "Dato incorrecto" : null}>
                                    <DatePicker  defaultValue={moment('11/30/21')} onChange={(date, dateString) =>{ setFechaCer(dateString)
                                                                                  props.onChangeFC(dateString)
                                                                                  revisarfechas()}} format={'DD/MMMM/YY'} locale={locale} disabledDate={disabledDate}
                                                                                  />
                                </Form.Item>
                            </Col>
                        </Row>
                        <br/>
                        <Row gutter={{sm: 8, md: 16, lg: 24 }}>
                            <Col sm={12} md={12} lg={12}>
                                <h4><b><font color="red">*</font></b>{etiquetasCasillas.datos_horarios_lbl_horario_instalacion}</h4>
                                <Form.Item form = {form} name="horaInicio" rules={[{required: true, message: 'Dato requerido'}]}
                                                         validateStatus={horaIncorrecta ? "error" : null} help={horaIncorrecta ? "Dato incorrecto" : null}> 
                                    <TimePicker defaultValue={moment('09:00','HH:mm')} onChange={(time, timeString) => { setHoraIns(timeString)
                                                                                  props.onChangeTimeHI(timeString)
                                                                                  revisarHorarios()}} format="HH:mm" />
                                </Form.Item>
                            </Col>
                            <Col sm={12} md={12} lg={12}>
                                <h4><b><font color="red">*</font></b>{etiquetasCasillas.datos_horarios_lbl_horarios_cierre}</h4>
                                <Form.Item form = {form} name="horaCierre" rules={[{required: true, message: 'Dato requerido'}]}
                                                         validateStatus={horaIncorrecta ? "error" : null} help={horaIncorrecta ? "Dato incorrecto" : null}>
                                    <TimePicker defaultValue={moment('19:00','HH:mm')} onChange={(time, timeString) => { setHoraCer(timeString)
                                                                                  props.onChangeTimeHC(timeString)
                                                                                  revisarHorarios()}} format="HH:mm" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <br/>
                        <div className="boton-anadirModifica">
                        <Row>
                            <Col offset={8} sm={5} md={5} lg={5}>
                                <Form.Item form = {form} name="anadir" rules={[{required: true, message: 'Dato requerido'}]}>
                                    <Button shape="round" style={{ background: "##D3097F", borderColor: "#ffffff" }} 
                                            onClick={()=>add()} 
                                            disabled={(fechaIns === undefined || fechaIns === '') || (fechaCer === undefined || fechaCer === '') 
                                            || (horaIns === undefined || horaIns === '') || (horaCer === undefined || horaCer === '') ? true: false}>Añadir</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                        </div>
                        </Form>
                    </Col>
                    

                    <Col sm={14} md={14} lg={14}>
                    <div>
                        <Row>
                            <Col span={24}>
                                <Card title="Horarios registrados de la casilla" >
                                  <Popover title={<><img src={errorPop} id="img-popFechasModifica"/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Button onClick={handleCancel} shape="circle" icon={<CloseOutlined />} style={{borderColor: "#ffffff" }}/></>} visible={isModalVisible} content={content} placement="left" className="cerrarFechasConsulta">
                                    <Table size="small" columns={columnsFechas} dataSource={data}  pagination={{position: ['bottomCenter']},{pageSize : 5}} rowClassName={() => 'editable-row'}/>
                                  </Popover>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    </Col>
                </Row>
                </Card>
            <div className="div-boton-modifica3">
            <Row>
            <Col sm={{span: 1, offset:1}} md={{span: 1, offset:1}} lg={{span: 1, offset:1}} xl={{span: 1, offset:1}}>
                <Tooltip title={etiquetasCasillas.etiqueta_tooltip_botonDer}><Button  htmlType="submit" style={{width: "47px", height: "46px", borderColor: "#ffffff"}}  onClick={()=>{props.changeTabModifica("2")}} shape="circle" icon={<ArrowLeftOutlined />}/></Tooltip>
            </Col>
            <Col offset={9} sm={10} md={10} lg={10} xl={10}>
              <Tooltip title={(data.length >= 1) && (props.rolCau === false) ? null:etiquetasCasillas.etiqueta_tooltip_boton_guardar}>
                <Button htmlType="submit"  disabled={(data.length >= 1) && (props.rolCau === false) ? false : true } style={{ borderColor: "#ffffff", width:"138px", borderRadius: "10px"}} onClick={props.enviarModificacion}>Guardar cambios</Button>
              </Tooltip>                       
            </Col>
            </Row>
            </div>                            
            </TabPane>
            </Tabs>

            </div>
            <Modal visible={modalE}
                   onCancel={hideModal}
                   title={<img  src={errorModal} id="img-error-modal"/>}
                   centered 
                   width={400}
                   className="modal-voluntarios"
                   footer={false}>{
                    <>
                        <div>
                            <span className="titulo-error">Casilla con voluntarios</span>
                        </div>
                        <br/>
                        <div>
                            <span className="notificacion-error">La casilla no se puede eliminar porque tiene voluntarios asignados</span>
                        </div>
                        <br/>
                    </>
                  }
            </Modal>
            <Modal visible={props.mostrar}
                   onCancel={props.ocultarModal}
                   cancelText="Cancelar"
                   centered
                   width={1000}
                   className="Modal-consulta"
                   bodyStyle={{height:620, background:'#ffffff'}}
                   footer={false}>
            {
            props.casillaSeleccionadaModal != null ?
            <>
              <Row id="modal-header">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <h1>&nbsp;&nbsp;Consultar información<br/>
                      &nbsp;&nbsp;<span className="casillaConsulta">Casilla&nbsp;{props.casillaSeleccionadaModal.folio}</span>
                  </h1>
                </Col>
              </Row>
              <Tabs centered = {true} defaultActiveKey="1" activeKey={props.activeTab} onChange={props.changeTab} size="large" className="tab-modal">
                    <TabPane disabled tab="Ubicación" key="1">
                      <Card bordered={false} style={{width:960, height:310, padding:20}}>
                        <Row gutter={8}>
                          <Col sm={24} md={6} lg={6}>
                              <div id="municipio">
                                  <label> Municipio/alcaldía:&nbsp;</label>{props.casillaSeleccionadaModal.nombreMunicipio}
                              </div>
                          </Col>
                          <Col sm={24} md={6} lg={6}>
                              <div id="seccion">
                                  <label> Sección electoral:&nbsp;</label>{props.casillaSeleccionadaModal.seccion}
                              </div>
                          </Col>
                          <Col sm={24} md={6} lg={6}>
                              <div id="tipo-casilla">
                                  <label> Tipo de casilla:&nbsp;</label>{props.casillaSeleccionadaModal.tipoCasilla}
                              </div>
                          </Col>
                          <Col sm={24} md={6} lg={6}>
                              <div id="zore">
                                  <label> ZOR asignada:&nbsp;</label> {props.casillaSeleccionadaModal.numeroZore} - {props.casillaSeleccionadaModal.tipoZore}
                              </div>
                          </Col>
                        </Row>
                        <div style={{display: ((props.casillaSeleccionadaModal.tipoCasilla === "Escuela Virtual") || (props.casillaSeleccionadaModal.tipoCasilla === "Escuela virtual") || props.casillaItinerante === true) ? 'none': 'block'}}>
                        <Row gutter={8}>
                          <Col sm={24} md={6} lg={6}>
                              <div id="calle">
                                  <label> Calle:&nbsp;</label>
                              </div>{props.casillaSeleccionadaModal.calle}
                          </Col>
                          <Col sm={24} md={6} lg={6}>
                              <div id="numero">
                                  <label> Número:&nbsp;</label>
                              </div>{props.casillaSeleccionadaModal.numero}
                          </Col>
                          <Col sm={24} md={6} lg={6}>
                              <div id="codigo-postal">
                                  <label> Código Postal:&nbsp;</label>
                              </div>{props.casillaSeleccionadaModal.codigoPostal !== null ? fill(props.casillaSeleccionadaModal.codigoPostal,5): null}
                          </Col>
                          <Col sm={24} md={6} lg={6}>
                              <div id="colonia">
                                  <label> Colonia:&nbsp;</label>
                              </div>{props.casillaSeleccionadaModal.colonia}
                          </Col>
                        </Row>
                        </div>
                        <br/>

                        <div style = {{display: ((props.casillaSeleccionadaModal.tipoCasilla === "Escuela Física (boletas digitales)") || 
                                                 (props.casillaSeleccionadaModal.tipoCasilla === "Escuela física (boletas digitales)") ||
                                                 (props.casillaSeleccionadaModal.tipoCasilla === "Escuela Física (boletas impresas)") || 
                                                 (props.casillaSeleccionadaModal.tipoCasilla === "Escuela física (boletas impresas)") ||
                                                 (props.casillaSeleccionadaModal.tipoCasilla === "Escuela Virtual") || (props.casillaSeleccionadaModal.tipoCasilla === "Escuela virtual")
                                                 || props.casillaItinerante === true) ? 'none' : 'block'}}>
                        
                        <Row gutter={8}>
                          <Col sm={24} md={12} lg={12}>
                              <div id="lugar">
                                  <label> Lugar:&nbsp;</label>
                              </div>{props.casillaSeleccionadaModal.lugar}
                          </Col>
                          <Col sm={24} md={12} lg={12}>
                              <div id="referencia">
                                  <label> Referencia:&nbsp;</label>
                              </div>{props.casillaSeleccionadaModal.referencia}
                          </Col>
                        </Row>
                        <br/>
                        <Row gutter={8}>
                          <Col sm={24} md={12} lg={12}>
                              <div id="nombre-contacto">
                                  <label> Nombre del contacto:&nbsp;</label>
                              </div>{props.casillaSeleccionadaModal.nombreContacto}
                          </Col>
                          <Col sm={24} md={12} lg={12}>
                              <div id="telefono-contacto">
                                  <label> Teléfono del contacto:&nbsp;</label>
                              </div>{props.casillaSeleccionadaModal.telefonoContacto}
                          </Col>
                        </Row>
                        </div>
                        
                        <div style = {{display: ((props.casillaSeleccionadaModal.tipoCasilla === "Escuela Física (boletas digitales)") || 
                                                 (props.casillaSeleccionadaModal.tipoCasilla === "Escuela física (boletas digitales)") ||
                                                 (props.casillaSeleccionadaModal.tipoCasilla === "Escuela Física (boletas impresas)") ||
                                                 (props.casillaSeleccionadaModal.tipoCasilla === "Escuela física (boletas impresas)") ||
                                                 (props.casillaSeleccionadaModal.tipoCasilla === "Escuela Virtual") || (props.casillaSeleccionadaModal.tipoCasilla === "Escuela virtual")) ? 'block' : 'none'}}>
                            <Row gutter={8}>
                              <Col sm={24} md={12} lg={12}>
                                <div id="lugar">
                                  <label> Lugar:&nbsp;</label>
                                </div>{props.casillaSeleccionadaModal.lugar}
                              </Col>
                              <Col sm={24} md={4} lg={4}>
                                <div id="claveEscuela">
                                  <label> Clave de la escuela:&nbsp;</label>
                                </div>{props.casillaSeleccionadaModal.claveEscuela}
                              </Col>
                              <Col sm={24} md={4} lg={4}>
                                <div id="turnoEscuela">
                                  <label> Turno de la escuela:&nbsp;</label>
                                </div>{props.casillaSeleccionadaModal.turnoEscuela}
                              </Col>
                              <Col sm={24} md={4} lg={4}>
                                <div id="nivelEducativo">
                                  <label> Nivel educativo:&nbsp;</label>
                                </div>{props.casillaSeleccionadaModal.nivelEducativo}
                              </Col>
                            </Row>
                            <br/>
                            <Row gutter={8}>
                              <Col sm={24} md={12} lg={12}>
                                <div id="nombre-contacto">
                                  <label> Nombre del contacto:&nbsp;</label>
                                </div>{props.casillaSeleccionadaModal.nombreContacto}
                              </Col>
                              <Col sm={24} md={8} lg={8}>
                                <div id="telefono-contacto">
                                  <label> Teléfono del contacto:&nbsp;</label>
                                </div>{props.casillaSeleccionadaModal.telefonoContacto}
                              </Col>
                            </Row>
                            <br/>
                            <Row gutter={8}>
                              <Col sm={24} md={12} lg={12}>
                                <div id="referencia">
                                  <label> Referencias:&nbsp;</label>
                                </div>{props.casillaSeleccionadaModal.referencia}
                              </Col>
                            </Row>

                        </div>
                        <div style={{display: props.casillaItinerante === true ? 'block':'none'}}>
                          <Tabs
                              defaultActiveKey='1'
                              type='card'
                              tabPosition='left'
                              size={'small'}
                          >
                            {props.direcciones.map(pane => (
                                <TabPane tab={<span>#{pane.key}</span>} key={pane.key} closable={false}>
                                  <Row gutter={8}>
                                    <Col sm={24} md={6} lg={6}>
                                      <div>
                                        <label>Calle:&nbsp;</label>
                                      </div>{pane.calle}
                                    </Col>
                                    <Col sm={24} md={6} lg={6}>
                                      <div>
                                        <label>Número:&nbsp;</label>
                                      </div>{pane.numero}
                                    </Col>
                                    <Col sm={24} md={6} lg={6}>
                                      <div>
                                        <label>Código Postal:&nbsp;</label>
                                      </div>{pane.codigoPostal}
                                    </Col>
                                    <Col sm={24} md={6} lg={6}>
                                      <div>
                                        <label>Colonia:&nbsp;</label>
                                      </div>{pane.colonia}
                                    </Col>
                                  </Row>
                                  <br/>
                                  <Row gutter={8}>
                                    <Col sm={24} md={12} lg={12}>
                                      <div>
                                        <label>Lugar:&nbsp;</label>
                                      </div>{pane.lugar}
                                    </Col>
                                    <Col sm={24} md={12} lg={12}>
                                      <div>
                                        <label>Referencias:&nbsp;</label>
                                      </div>{pane.referencia}
                                    </Col>
                                  </Row>
                                  <br/>
                                  <Row gutter={8}>
                                    <Col sm={24} md={12} lg={12}>
                                      <div>
                                        <label>Nombre de contacto:&nbsp;</label>
                                      </div>{pane.nombreContacto}
                                    </Col>
                                    <Col sm={24} md={12} lg={12}>
                                      <div>
                                        <label>Teléfono de contacto:&nbsp;</label>
                                      </div>{pane.telefonoContacto}
                                    </Col>
                                  </Row>

                                </TabPane>
                            ))}
                          </Tabs>

                        </div>
                        </Card>
                        <br/>
                        <Row gutter={2}>
                          <Col offset={22} sm={1} md={1} lg={1} xl={1}>
                            <a onClick={()=>{props.changeTab("2")}}><img style={{width:47 , height:46, opacity:1}} src={iconoSiguiente}/></a>
                        </Col>
                      </Row>
                    </TabPane>
                    
                    <TabPane className="Tab-modal" disabled tab="Conectividad" key="2">
                      <Card bordered={false} style={{width:960, height:310}}>
                        <Row gutter={8}>
                          <Col sm={24} md={24} lg={24}>
                              <div id="dispositivos">
                                  <label>Cantidad de dispositivos con los que cuenta el lugar:</label>
                              </div>
                          </Col>
                        </Row>
                        <br/>
                        <Row gutter={8} justify="center" align="middle">
                          <Col sm={12} md={12} lg={12}>
                          <Card title={<span>{etiquetasCasillas.datos_conectividad_lbl_titulo}<Tooltip title={etiquetasCasillas.etiqueta_tooltip_conectividad}>&nbsp;<img style={{width:17 , height:17}} src={iconoQuestion}/></Tooltip></span>} style={{textAlign: 'center'}} size="small" bordered={false}>
                          <table className="tablaDispositivos">
                            <thead className="tablaCabezal">
                              <tr>
                                <th>Tipo de dispositivo</th>
                                <th>Cantidad</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Computadoras de escritorio, laptop &nbsp;</td>
                                <td>{(props.casillaSeleccionadaModal.equiposComputo === null || 0) ? 0 : props.casillaSeleccionadaModal.equiposComputo}</td>
                              </tr>
                              <tr>
                                <td>Teléfono inteligente &nbsp;</td>
                                <td>{(props.casillaSeleccionadaModal.equiposCelular === null || 0) ? 0 : props.casillaSeleccionadaModal.equiposCelular}</td>
                              </tr>
                              <tr>
                                <td>Tablet, Ipad &nbsp;</td>
                                <td>{(props.casillaSeleccionadaModal.equiposTabletas === null || 0) ? 0 : props.casillaSeleccionadaModal.equiposTabletas}</td>
                              </tr>
                              <tr>
                                <td><b>Total de dispositivos &nbsp;</b></td>
                                <td>{props.casillaSeleccionadaModal.equiposComputo + props.casillaSeleccionadaModal.equiposCelular + props.casillaSeleccionadaModal.equiposTabletas}</td>
                              </tr>
                            </tbody>
                          </table>
                          </Card>
                          </Col>
                          <Col sm={{span:8, offset:2}} md={{span:8, offset:2}} lg={{span:8, offset:2}} >
                              <div id="internet">
                                  <label>¿El lugar cuenta con acceso a internet?</label>&nbsp;&nbsp;
                                  <Row>
                                    <Col offset={5} sm={11} md={11} lg={11}>
                                      <Radio.Group value={props.casillaSeleccionadaModal.tieneInternet === 'S' ? "S" : "N"}>
                                          <Radio value={"S"} disabled>Sí</Radio>
                                          <Radio value={"N"} disabled>No</Radio>
                                      </Radio.Group>
                                    </Col>
                                  </Row>
                              </div>
                          </Col>
                        </Row>
                        <br/>
                      </Card>
                        <br/>
                        <Row>
                          <Col sm={{span:12, offset:1}} md={{span:12, offset:1}} lg={{span:12, offset:1}} xl={{span:12, offset:1}}>
                            <a onClick={()=>{props.changeTab("1")}}><img style={{width:47 , height:46, opacity:1}} src={iconoAnterior}/></a>
                        </Col>
                        <Col sm={{span: 1, offset:9}} md={{span: 1, offset:9}} lg={{span: 1, offset:9}} xl={{span: 1, offset:9}}>
                          <a onClick={()=>{props.changeTab("3")}}><img style={{width:47 , height:46, opacity:1}} src={iconoSiguiente}/></a>
                        </Col>
                      </Row>
                    </TabPane>
                    
                    <TabPane disabled tab="Horarios" key="3">
                      <Card bordered={false} style={{width:960, height:310}}>
                        <Row gutter={8}>
                          <Col sm={24} md={24} lg={24}>
                              <div id="dispositivos">
                                  <label>Horarios registrados de la casilla</label>
                              </div>
                          </Col>
                        </Row>
                        <br/>
                        <Row gutter={8}>
                          <Col sm={24} md={24} lg={24}>
                            <Table columns={columnsConsulta} dataSource={props.casillaSeleccionadaModal.horariosCasilla} pagination={{position: ['bottomCenter']},{pageSize : 5}} size="small"/>
                          </Col>
                        </Row>
                      </Card>
                        <br/>
                        <Row>
                          <Col sm={{span:12, offset:1}} md={{span:12, offset:1}} lg={{span:12, offset:1}} xl={{span:12, offset:1}}>
                            <a onClick={()=>{props.changeTab("2")}}><img style={{width:47 , height:46, opacity:1}} src={iconoAnterior}/></a>
                        </Col>
                      </Row>
                    </TabPane>
                    
              </Tabs>
              
            </>
          : ""
          }
          </Modal>
          <Modal
            className="modalModificaConsulta"
            visible={props.abrirModalExitoModifica}
            title={<img  src={casillaModificaImg} id="img-modifica-modal"/>}
            onCancel={props.onChangeRouteModifica}
            centered
            footer={[null]}>{
              <>
                <span className="titulo-modifica">Casilla modificada</span>
                <Card size="small" bordered={false}>
                  <span className="notificacion-modifica">La información se actualizó con éxito</span>
                </Card>
                <Row>
                  <Col offset={6} span={6}>
                    <Button type="primary" htmlType="submit" style={{ background: "#D4217F", borderColor: "#ffffff", width:"125px"}} size={"large"} onClick={props.onChangeRouteModifica}>Aceptar</Button>
                  </Col>
                </Row>
                <br/>
              </>
            }
            </Modal>
            <Modal visible={props.mostrarElimina}
                   title={<img  src={eliminaCasilla} id="img-elimina-modal"/>}
                   centered 
                   width={400}
                   className="modalElimina"
                   onCancel={props.ocultarModalElimina}
                   footer={[null]}>{
                    <>
                        <div>
                            <span className="notificacion-elimina">¿Estás seguro de que deseas eliminar la casilla?</span>
                        </div>
                        <br/>
                        <Row>
                          <Col span={10}>
                              <Button type="primary" htmlType="submit" style={{ color: "#6D6D6D", background: "#ffffff", borderColor: "#707070", width: "125px"}} onClick={props.ocultarModalElimina}>No</Button>
                          </Col>
                          <Col offset={3} span={10}>
                              <Button type="primary" htmlType="submit" style={{ background: "#D4217F", borderColor: "#ffffff", width:"125px"}} onClick={eliminarCasilla}>Sí</Button>
                          </Col>
                        </Row>
                    </>
                  }
            </Modal>
            <Modal visible={props.mostrarSinGuardar}
                   title={<img  src={eliminaCasilla} id="img-elimina-modal"/>}
                   centered 
                   width={400}
                   className="modalElimina"
                   onCancel={props.ocultarModalSinGuardar}
                   footer={[null]}>{
                    <>
                        <div>
                            <span className="notificacion-elimina">Los datos capturados se perderán. ¿Deseas salir de modifica casilla?</span>
                        </div>
                        <br/>
                        <Row>
                          <Col span={10}>
                              <Button type="primary" htmlType="submit" style={{ color: "#6D6D6D", background: "#ffffff", borderColor: "#707070", width: "125px"}} onClick={props.ocultarModalSinGuardar}>No</Button>
                          </Col>
                          <Col offset={3} span={10}>
                              <Button type="primary" htmlType="submit" style={{ background: "#D4217F", borderColor: "#ffffff", width:"125px"}} onClick={props.salirSinGuardar}>Sí</Button>
                          </Col>
                        </Row>
                    </>
                  }
            </Modal>
          </Layout>
          </>


  )
}

export default ConsultarV
