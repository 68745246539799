import React, { Component } from 'react';
import logo from '../../assets/img/loader.gif';
import BlockUI from 'react-block-ui';

class BlockLoader extends Component {
	componentDidMount() {
		if (this.props.id && this.props.bloquearTodo) {
			window.addEventListener('resize', this.redimension);
		}
	}

	componentDidUpdate() {
		if (this.props.id && this.props.bloquearTodo && !this.haBloqueado) {
			this.haBloqueado = true;
			this.redimension();
		}
	}

	componentWillUnmount() {
		if (this.props.id && this.props.bloquearTodo) {
			window.removeEventListener('resize', this.redimension);
		}
	}

	redimension = () => {
		const divContent = document.getElementById(this.props.id);
		divContent.style.height = '0px';

		setTimeout(() => {
			const height = document.documentElement.scrollHeight;
			divContent.style.height = height + 'px';
		}, 0);
	}

	render() {
		return (
			<BlockUI id={this.props.id}
					tag='div'
			        blocking={this.props.blocking}
					loader={<img src={logo} style={{width: '200px'}} alt=""/>}
					className={this.props.className}>
				{this.props.children}
			</BlockUI>
		);
	}
}

export default BlockLoader;
