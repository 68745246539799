import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser, selectCurrentUserNivel } from '../../redux/user/user-selectors';
import { Modal } from 'antd';
import CLogin from '../../containers/login/CLogin';
import VFiltros from '../comunes/VFiltros';
import userIcon from '../../assets/img/user_icon_blanco.png';
import cauIcon from '../../assets/img/cau_icon.png';
import cerrarSesionIcon from '../../assets/img/cerrar_sesion_icon.png';
import idSistema from '../../assets/img/logo_cij.png';
import { PATH_HOME_INT, PATH_LOGIN,PATH_LOGOUT } from '../../constants/routers/paths';
import * as etiquetas from '../../constants/EtiquetasLogin';
import * as infoSistema from '../../constants/InfoSistema';
import '../../css/header/header.scss';
import '../../css/login/login.css';

const VHeader = ({ haySesion, nivelUsuario, user, ocultarLogin, loginEsVisible }) => {

  return(
    <>
    <div className='header-container'
        style={{height: haySesion ? '50px' : ''}}>
        <div className='hc-sistema'>
          <a className='hc-sistema-link'
            href={haySesion ? PATH_HOME_INT : PATH_LOGIN}>
            <img className="hc-sistema-link-image"
                style={{width: haySesion ? '42px' : ''}}
                src={idSistema}
                alt="Imagen del sistema"/>
          </a>
          <span className='hc-sistema-title'
                style={{fontSize: haySesion ? '21px' : '',
                       fontFamily: haySesion ? 'Roboto-Medium': ''}}>
              {infoSistema.NOMBRE_SISTEMA}
          </span>
        </div>
        {
          haySesion ?
          <div className='hc-datos-usuario'>
            <img className='hc-datos-usuario-image'
                src={cauIcon}
                alt="Icono cau"/>
            <img className='hc-datos-usuario-image'
                src={userIcon}
                alt="Icono usuario"/>
            <div className='hc-datos-usuario-title'>
              <span className='hc-datos-usuario-title-name'>
                {user.username}
              </span>
              <span className='hc-datos-usuario-title-nivel'>
                {nivelUsuario}
              </span>
            </div>
            <Link to={PATH_LOGOUT} className='hc-datos-usuario-logout'>
                <img className='hc-datos-usuario-image'
                    src={cerrarSesionIcon}
                    alt="Icono cerrar sesión"/>
                {etiquetas.login_accion_cierraSesion}
            </Link>
          </div>
          : ''
        }
    </div>
    {
      haySesion?
      <VFiltros />
      : ''
    }
      <Modal onCancel={ocultarLogin}
             visible={loginEsVisible}
             className="modalLogin">
        <CLogin />
      </Modal>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
  nivelUsuario: selectCurrentUserNivel
});

export default connect(mapStateToProps)(VHeader);
