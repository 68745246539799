export const PATH_LOGIN     = "/login_cij";
export const PATH_HOME_INT  = "/inicio";
export const PATH_LOGOUT    = "/logout";
export const PATH_MODULO    = "/modulo";

export const PATH_REGISTRO_CASILLA          = "/registroCasilla";
export const PATH_CONSULTA_CASILLA          = "/consultaCasilla";
export const PATH_SEGUIMIENTO_CASILLA       = "/seguimientoCasilla";
export const PATH_INSTALACION_CASILLA       = "/instalacionCasilla";
export const PATH_REGISTRO_VOLUNTARIO       = "/registroVoluntario";
export const PATH_CONSULTA_VOLUNTARIO       = "/consultaVoluntario";
export const PATH_SEGUIMIENTO_VOLUNTARIO     = "/seguimientoVoluntario";
export const PATH_ASIGNACION_ZORE           = "/asignacionZORE";
export const PATH_ASIGNACION_CASILLA        = "/asignacionCasilla";
export const PATH_GAFETES                   = "/gafetes";
export const PATH_RESULTADOS                = "/resultados";
export const PATH_CAPTURA_SEDES             = "/capturaSedes";
export const PATH_CONSULTA_SEDES            = "/consultaSedes";
export const PATH_REGISTRO_CONVOCATORIA     = "/registroConvocatoria";
export const PATH_CONSULTA_CONVOCATORIA     = "/consultaConvocatoria";
export const PATH_CONSULTA_ASISTENCIA       = "/asistenciaCapacitacion";
export const PATH_RESULTADOS_CIJ            = "/resultadosCij";
export const PATH_BITACORA_JSON             = "/bitacoraJson";
