
export const datos_asignacion_voluntarios_lbl_titulo             = "Asignación de voluntarios";
export const datos_voluntarios_asignados_lbl_titulo              = "Voluntarios asignados";
export const datos_voluntarios_asignados_lbl_folio_casilla       = "Folio de casilla";
export const datos_voluntarios_asignados_lbl_voluntario_asignado = "Voluntario asignado";
export const folio_casilla                                       = "Folio de casilla";
export const folio                                               = "Folio";
export const seccion_casilla                                     = "Sección";
export const municipio_casilla                                   = "Municipio/alcaldía";
export const tipo_casilla                                        = "Tipo de casilla";	
export const vol_asig                                            = "Personas voluntarias asignadas";
export const acciones                                            = "Acciones";
export const asignar                                             = "Asignar";
export const modificar                                           = "Modificar";
export const no_existen_voluntarios                              = "No existen Voluntarios en esta sección";
export const maximo_de_voluntarios                               = "La casilla ya tiene el número máximo de voluntarios asignados";
export const mensaje_casilla_modificada                          ="Asignación a ";
export const mensaje_casilla_modificada2                         ="casilla modificada"
export const mensaje_voluntarios_asignados                       ="Asignación a "; 
export const mensaje_voluntarios_asignados2                      ="casilla realizada"
export const limpiar                                             = "Limpiar";
export const buscar                                              = "Buscar";
export const no_existe_casilla                                   = "El dato que ingreso no existe";
export const resultados_voluntarios                              = "Listado de personas voluntarias ";
export const voluntario                                          = "Persona voluntaria asignada";
export const vountarios_asignados                                = "Listado de personas voluntarias asignadas ";
export const vountario_asignado                                  = "Voluntario asignado";
export const guardar                                             = "Guardar";
export const voluntario_removido                                 = "El voluntario se ha removido";
export const voluntario_aniadido                                 = "El voluntario se ha añadido";
export const distrito                                            = "Distrito";
export const zore                                                = "ZOR";
export const ubicacion                                           = "Ubicación";
export const selecciona                                          = "Selecciona";
export const con_asignacion                                      = "Con asignación";
export const sin_asignacion                                      = "Sin asignación";
export const titulo_componente                                   = "Asignación a casilla";
export const subtitulo_componente                                = "Listado de casillas registradas ";
export const etiqueta_tooltip_resultado                          ="Selecciona la casilla a la que se asignarán las personas voluntarias.";
export const etiqueta_tooltip_acciones                           ="Selecciona la acción que deseas realizar: asignar, editar o eliminar el registro..";
export const etiqueta_tooltip_asignado                           ="Presiona en 'Quitar' para modificar a la persona voluntaria que se asignó.";
export const etiqueta_tooltip_info                               ="Utiliza los filtros para realizar una búsqueda más especifica de la casilla y selecciona si deseas editar la información o asignar a un persona voluntaria.";                                                                
export const etiqueta_tooltip_info2                              ="Selecciona la acción que deseas realizas: Asignar, editar o eliminar el registro.";                                                                
export const borrar                                              = "Eliminar el registro";
export const editar                                              = "Editar";
export const anadir                                              = "Añadir";
export const quitar                                              = "Quitar";
export const casilla                                             = "Casilla ";
export const asignar_voluntarios                                 = "Asignar voluntarios ";
export const modificar_voluntarios                               = "Modificar voluntarios";
export const info_exito                                          ="La información se guardó con éxito"
export const aceptar                                             = "Aceptar";
