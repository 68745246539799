import React from 'react';
import {Modal,Row,Col,Button} from 'antd';
import '../../css/voluntarios/notificacion.css';

const  VRegistroExitoso = (props) => {
  const ocultarModal = () =>{
    window.location.reload();
  }
  return(

    <>
    <Modal
           visible={props.mostarNotificacion}
           onOk={ocultarModal}
           onCancel={props.mandarAConsulta}
           okText = "Cerrar"
           closeable = {true}
           width={480}
           className="modalExito">

        <Row id="modal-body-notificacion">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div id="imagen">
                <img src={props.imagen}></img>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div id="texto-exito">
                {props.textoExito}
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div id="texto-informativo">
                {props.textoInformativo}
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Button onClick={ocultarModal} id="boton-aceptar">
                Nuevo registro
              </Button>
            </Col>
        </Row>
    </Modal>
    </>
  );


}


export default VRegistroExitoso;
