import casillasIcon from '../../assets/img/casillas_icon.svg';
import convocatoriaIcon from '../../assets/img/convocatoria_icon.svg';
import sedesIcon from '../../assets/img/sedes_icon.svg';
import voluntariosIcon from '../../assets/img/voluntarios_icon.svg';
import asignacionIcon from '../../assets/img/asignacion_icon.svg';
import gafetesIcon from '../../assets/img/gafetes_icon.svg';
import resultadosIcon from '../../assets/img/resultados_icon.svg';
import voluntariosRegistrar from '../../assets/img/menu_voluntarios_registrar.svg';
import voluntariosConsultar from '../../assets/img/menu_voluntarios_consultar.svg';
import voluntariosFinalizar from '../../assets/img/menu_voluntarios_finalizar.svg';
import asignacionZORE from '../../assets/img/menu_asignacion_zore.svg';
import asignacionCasilla from '../../assets/img/menu_asignacion_casilla.svg';
import casillaRegistrar from  '../../assets/img/menu_casillas_registrar.svg';
import casillaConsultar from  '../../assets/img/menu_casillas_consultar.svg';
import casillaSeguimiento from  '../../assets/img/menu_casillas_seguimiento.svg';
import convocatoriaRegistrar from  '../../assets/img/menu_convocatoria_registrar.svg';
import convocatoriaConsultar from  '../../assets/img/menu_convocatoria_consultar.svg';
import sedesRegistrar from  '../../assets/img/menu_sedes_registrar.svg';
import sedesConsultar from  '../../assets/img/menu_sedes_consultar.svg';
import asistenciaCapa from  '../../assets/img/menu_asistencia_capa.svg';

const EXTRA_DATA_MENU = {
    1: {
        image: casillasIcon, 
        isEnabled: true,
        extraDataModulo: {
            1: {
                image: casillaRegistrar,
                isEnabled: true
            },
            2: {
                image: casillaConsultar,
                isEnabled: true
            },
            20: {
                image: casillaSeguimiento,
                isEnabled: true
            },
        }
    },
    2: {
        image: convocatoriaIcon,
        isEnabled: true,
        extraDataModulo: {
            3: {
                image: convocatoriaRegistrar,
                isEnabled: true
            },
            4: {
                image: convocatoriaConsultar,
                isEnabled: true
            },
        }
    },
    3: {
        image: sedesIcon,
        isEnabled: true,
        extraDataModulo: {
            5: {
                image: sedesRegistrar,
                isEnabled: true
            },
            6: {
                image: sedesConsultar,
                isEnabled: true
            },
        }
    },
    4: {
        image: voluntariosIcon,
        isEnabled: true,
        extraDataModulo: {
            7: {
                image: voluntariosRegistrar,
                isEnabled: true
            },
            8: {
                image: voluntariosConsultar,
                isEnabled: true
            },
            9: {
                image: voluntariosFinalizar,
                isEnabled: true
            },
            10: {
                image: asistenciaCapa,
                isEnabled: true
            },
        }
    },
    5: {
        image: asignacionIcon,
        isEnabled: true,
        extraDataModulo: {
            11: {
                image: asignacionZORE,
                isEnabled: true
            },
            12: {
                image: asignacionCasilla,
                isEnabled: true
            }
        }
    },
    6: {
        image: gafetesIcon,
        isEnabled: true,
        extraDataModulo: {
            13: {
                image: casillaConsultar,
                isEnabled: true
            }
        }
    },
    7: {
        image: resultadosIcon,
        isEnabled: true,
        extraDataModulo: {
            14: {
                image: casillaConsultar,
                isEnabled: true
            }
        }
    }
};

export default EXTRA_DATA_MENU;