
export const ID_DETALLE_PROCESO = 141;
export const ID_PROCESO_ELECTORAL = 18;
export const VERSION_SISTEMA = "1.0";
export const ID_SISTEMA = 251;
export const SECRET_CAPTCHA_KEY = "6LdMKD0bAAAAAO5rlhRu416mACvqtUNA06orrczI";
export const HORA_FECHA_VERSION = "V1.0 24122021 14:21";
export const NOMBRE_SISTEMA = "Consulta Infantil y Juvenil";
export const VERSION_SISTEMA_FOOTER = `Versión ${VERSION_SISTEMA}`;
export const UNICOM = "Unidad Técnica de Servicios de Informática";
export const INE_COPYRIGHT = "©INE México 2021";
export const DECEYEC = "Dirección Ejecutiva de Capacitación Electoral y Educación Cívica";
export const DECE = "Dirección de Educación Cívica";
export const ROLES_CAPTURA = {
    "CIJ.ADMIN.OC": true,
    "CIJ.CAPTURA.JD": true,
    "CIJ.CAPTURA.JL": true,
    "CIJ.CAPTURA.OC": true,
    "CIJ.CAPTURA_VE.JD": true,
    "CIJ.CAPTURA_VE.JL": true,
    "CIJ.CAPTURA_VERFE.JD": true,
    "CIJ.CAPTURA_VERFE.JL": true,
    "CIJ.CAPTURA_VOE.JD": true,
    "CIJ.CAPTURA_VOE.JL": true,
    "CIJ.CAPTURA_VS.JD": true,
    "CIJ.CAPTURA_VS.JL": true,
    "CIJ.CAU.OC": true,
    "CIJ.CAPTURA_VCEYEC.JD": true,
    "CIJ.CAPTURA_VCEYEC.JL": true,
    "CIJ.CAPTURA_EXT_OPL.JL": true,
    "CIJ.CAPTURA_EXT_OPL.JD": true
};
export const ROLES_CONSULTA = {
    "CIJ.CONSULTA.JD": true,
    "CIJ.CONSULTA.JL": true,
    "CIJ.CONSULTA.OC": true,
    "CIJ.CONSULTA_EXT_OPL.JL": true,
    "CIJ.CONSULTA_EXT_OPL.JD": true,
    "CIJ.CONSULTA_JOSA.JD": true
};
export const ROLES_CONSULTA_RESTRINGIDA = {
    "CIJ.CONSULTA_RESTRINGIDA.JD": true,
    "CIJ.CONSULTA_RESTRINGIDA.JL": true,
    "CIJ.CONSULTA_RESTRINGIDA.OC": true
}