import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import VFooter from './comunes/VFooter';
import VSinResultados from './VSinResultados';
import router from "../router/router";
import { visitas } from "../utils/scripts";
import { PATH_REGISTRO_VOLUNTARIO, PATH_LOGIN, PATH_MODULO } from '../constants/routers/paths';

import 'antd/dist/antd.css'
import 'react-block-ui/style.css'
import '../css/styles.css';
import '../css/comunes/checkbox.css';
import '../css/comunes/guia_estatus.css';
import '../css/comunes/autocomplete.css';
import '../css/comunes/select.css';
import '../css/comunes/radio.css';
import '../css/comunes/mensaje.css';
import '../css/app/modal.css';
import '../css/comunes/calendar.css';
import '../css/app/exitoInfos.css';
import CModulo from '../containers/comunes/CModulo'; 
import CInstalacionCasilla from '../containers/casillas/consulta/CInstalacionCasilla';

class App extends React.Component {
	render() {
		return (
      <>
			<div className="App"
        onChange={visitas}
        onLoad={visitas}
        style={{height:"100%"}}>
          <Switch >
            <Redirect exact from="/" to={PATH_LOGIN}/>
            <Redirect exact from="/login_cij.html" to={PATH_LOGIN}/>
              {
                router.map(route =>(
                  <Route key={route.path}
                    exact
                    path={route.path}
                    component={route.component}
                  />
                ))
              }
              <Route path={`${PATH_MODULO}/:idSubMenu`}
                    component={CModulo} />
              <Route component={CInstalacionCasilla}/>
              <Route component={VSinResultados}/>
          </Switch>
			</div>
      <VFooter/>
      </>
		);
	}
}

export default App;
