

export const titulo_componente = "Registrar personas voluntarias";
export const titulo_componente_modifica = "Modificar registro";

export const datos_voluntarios_lbl_accion = "Selecciona la acción que deseas realizar:";
export const datos_voluntarios_lbl_registrar = "Registrar";
export const datos_voluntarios_lbl_registrar_voluntario ="Registrar voluntario";
export const datos_voluntarios_lbl_consulta_modificacion = "Consultar y modificar";
export const datos_voluntarios_lbl_validacion_seguimiento = "Validar y dar seguimiento";
export const datos_voluntarios_lbl_consulta = "Consulta de información";

export const datos_personales_lbl_titulo          = "Datos personales";
export const datos_personales_lbl_tipoVoluntario  = "Tipo de persona voluntaria:"
export const datos_personales_lbl_claveElector    = "Clave de elector:"
export const datos_personales_lbl_curp            = "CURP";
export const datos_personales_lbl_experiencia     = "¿Cuenta con experiencia como voluntaria/o en el INE u OPL?";
export const datos_personales_lbl_nombre           = "Nombre:";
export const datos_personales_lbl_apellidoPaterno  = "Apellido paterno:";
export const datos_personales_lbl_apellidoMaterno  = "Apellido materno:";
export const datos_personales_lbl_fechaNacimiento  = "Fecha de nacimiento:";
export const datos_personales_lbl_edad             = "Edad:";
export const datos_personales_lbl_genero           = "Sexo:";
export const datos_personales_lbl_genero_f  = "Mujer" ;
export const datos_personales_lbl_genero_m = "Hombre";
export const datos_personales_lbl_genero_i = "No me identifico con ninguno de los dos" ;
export const datos_personales_lbl_correoElectronico = "Correo electrónico:";
export const datos_personales_lbl_celular        = "Teléfono móvil:";
export const datos_personales_lbl_telefono        = "Teléfono casa:";
export const datos_personales_lbl_hablaLenguaIndigena         = "¿Habla una lengua indígena?";
export const datos_personales_lbl_cualLenguaIndigena          = "¿Cuál?";

export const datos_direccion_lbl_titulo            = "Domicilio";
export const datos_direccion_lbl_calle             = "Calle:";
export const datos_direccion_lbl_numero_exterior   = "Número exterior:";
export const datos_direccion_lbl_numero_interior   = "Número interior:";
export const datos_direccion_lbl_colonia           = "Colonia:";
export const datos_direccion_lbl_entidad            = "Entidad:";
export const datos_direccion_lbl_municipio         = "Municipio/Alcaldía:";
export const datos_direccion_lbl_cp                = "Código postal:";

export const ayuda_tipo_voluntario = "INTERNO INE: Voluntariado perteneciente al INE.  INTERNO OPL: Voluntariado perteneciente a los Organismos Públicos Locales. EXTERNO: Ciudadanía en general que desee participar.";
export const ayuda_genero = "Al usar el término 'género' estamos siendo incluyentes con cada individuo y su identidad con la que se identifican e interactuan con el mundo.";
export const ayuda_modifica = "Modifica";
export const ayuda_consulta = "Consulta";
export const ayuda_notifica = "Notifica";
export const ayuda_filtros = "Utiliza los filtros para buscar una persona voluntaria y selecciona la acción que deseas realizar.";
export const aviso_privacidad = "La persona voluntaria ha leído el aviso de privacidad y acepta ser contactada vía correo electrónico para algún seguimiento o notificación de información sobre el proceso en que se encuentra participando. De igual manera, otorga su consentimiento para que sus datos personales sean tratados conforme al mismo."

export const titutlo_tabla_consulta = "Listado de personas voluntarias";
export const error_obligatorio = "Dato requerido";
export const error_aviso_privacidad = "Acepta el aviso de privacidad";
export const error_clave_repetida = "La clave de elector ya se ha registrado anteriormente, vuelve a intentarlo.";
export const error_no_convocatoria = "En este momento no hay ninguna convocatoria registrada. Inténtalo más tarde.";

export const REGEX_FORMATO_CLAVE_ELECTOR_FUAR       = /^([A-Z]{5,6}\d{8}[H|M]\d{3})$|^([0-9]{13})$/i;
export const REGEX_FORMATO_CLAVE_ELECTOR            = /^([A-Z]{5,6}\d{8}[H|M]\d{3})$/i;
export const REGEX_FORMATO_SIAP                     = /^([0-9]{13})$/i;
export const REGEX_FORMATO_NOMBRES                  = /^[A-ZÁÉÍÓÚÜÑ\'\°\.\-\/\s]*$/i;
export const REGEX_FORMATO_RFC                      = /^[A-Z]{3,4}[0-9]{6}([A-Z0-9]{3})*$/i;
export const REGEX_FORMATO_CURP                     = /^[A-Z]{4}[0-9]{6}[H|M][A-Z]{5}[A-Z0-9][0-9]$/i;
export const REGEX_FORMATO_CORREO                   = /^[_A-Z0-9-\+]+(\.[_A-Z0-9-]+)*@[A-Z0-9-]+(\.[A-Z0-9]+)*(\.[A-Z]{2,})$/i;
export const REGEX_FORMATO_TELEFONO                 = /^\d{8,10}$/i;
export const REGEX_FORMATO_NO_CARACTERES_ESPECIALES = /^[^\|\\\u00B4\"\<\>\@\&\=\(\)]*$/i;

const typeTemplate = "'${name}' no es un  ${type}";
export const defaultValidateMessages = {
  default: "Formato incorrecto",
  required: "Dato requerido",
  types: {
    string: typeTemplate,
    number: typeTemplate,
    date: typeTemplate,
    email: typeTemplate,
  },
  string: {
    len: "debe contener ${len} carácteres"
  },
  number: {
    len: "debe contener ${len} dígitos"
  },
  pattern: {
    mismatch: "Formato Incorrecto ",//${pattern}
  },
};


//roles captira
export const roles_captura_modifica = [
  "CIJ.ADMIN.OC",
  "CIJ.CAPTURA.OC",
  "CIJ.CAPTURA.JL",
  "CIJ.CAPTURA.JD",
  "CIJ.CAPTURA_VE.JD",
  "CIJ.CAPTURA_VE.JL",
  "CIJ_CAPTURA_CONSEJERO.JL",
  "CIJ_CAPTURA_CONSEJERO.JD",
  "CIJ.CAPTURA_VCEYEC.JL",
  "CIJ.CAPTURA_VCEYEC.JD",
  "CIJ.CAU.OC",
  "CIJ.CAPTURA_OPL.JL",
  "CIJ.CAPTURA_OPL.JD"
];

export const roles_consulta = [
  "CIJ.CAU.OC",
  "CIJ_CAPTURA_VE.JD",
  "CIJ_CAPTURA_VE.JL"	,
  "CIJ.CAPTURA_VERFE.JL",
  "CIJ.CAPTURA_VERFE.JD",
  "CIJ.CAPTURA_VOE.JL",
  "CIJ.CAPTURA_VOE.JD",
  "CIJ.CAPTURA_VS.JL",
  "CIJ.CAPTURA_VS.JD",
  "CIJ.CONSULTA.OC",
  "CIJ.CONSULTA.JL",
  "CIJ.CONSULTA.JD",
  "CIJ.CONSULTA_RESTRINGIDA.JD",
  "CIJ.CONSULTA_RESTRINGIDA.Jl",
  "CIJ.CONSULTA_RESTRINGIDA.OC",
  "CIJ_CONSULTA_OPL.JD",
  "CIJ_CONSULTA_OPL.JL",
  "CIJ.ADMIN.OC",
  "CIJ.CAPTURA.JL",
  "CIJ.CAPTURA.JD",
  "CIJ.CAPTURA.OC",
  "CIJ_CAPTURA_CONSEJERO.JL",
  "CIJ_CAPTURA_CONSEJERO.JD",
  "CIJ.CAPTURA_VCEYEC.JL",
  "CIJ.CAPTURA_VCEYEC.JD",
  "CIJ.CAU.OC",
  "CIJ.CAPTURA_OPL.JL",
  "CIJ.CAPTURA_OPL.JD",
  "CIJ.CAPTURA_VE.JD",
  "CIJ.CAPTURA_VE.JL",
];

export const roles_permitidos = [
  "CIJ.ADMIN.OC",
  "CIJ.CAPTURA.OC",
  "CIJ.CONSULTA.OC",
  "CIJ.CAU.OC",
  "CIJ.CAPTURA.JL",
  "CIJ.CONSULTA.JL",
  "CIJ.CAPTURA.JD",
  "CIJ.CONSULTA.JD",
  "CIJ.CAPTURA_VE.JL",
  "CIJ.CAPTURA_VE.JD",
  "CIJ.CAPTURA_VERFE.JL",
  "CIJ.CAPTURA_VERFE.JD",
  "CIJ.CAPTURA_VOE.JL",
  "CIJ.CAPTURA_VOE.JD",
  "CIJ.CAPTURA_VS.JL",
  "CIJ.CAPTURA_VS.JD",
  "CIJ.CAPTURA_VCEYEC.JL",
  "CIJ.CAPTURA_VCEYEC.JD",
  "CIJ.CAPTURA_EXT_OPL.JL",
  "CIJ.CONSULTA_EXT_OPL.JL",
  "CIJ.CAPTURA_EXT_OPL.JD",
  "CIJ.CONSULTA_EXT_OPL.JD",
  "CIJ.CONSULTA_JOSA.JD"
];
