import { createSelector } from 'reselect';

const selectVoluntario = (state) => state.voluntario;

export const selectCurrentVoluntario = createSelector(
  [selectVoluntario],
  (voluntario) => voluntario.currentVoluntario
);

export const selectCurrentVoluntarioRol = createSelector(
  [selectVoluntario],
  (voluntario) => voluntario.VoluntarioId
);
