import React from 'react';
import VChartParticipantesEdad from './VChartParticipantesEdad';
import * as etiquetas from '../../constants/EtiquetasHomePortal';

const VCardParticipantesEdad = ({ total, participantesEdad }) => {

    const extraData = [
        {
            color: 'var(--chart-bar1)',
            x: 60,
            y: 75,
            radius: 27,
            outerRadius: 37,
            labelStart: true
        },
        {
            color: 'var(--chart-bar3)',
            x: 155,
            y: 110,
            radius: 30,
            outerRadius: 45,
            labelStart: false
        },
        {
            color: 'var(--chart-bar4)',
            x: 275,
            y: 100,
            radius: 45,
            outerRadius: 55,
            labelStart: true
        },
        {
            color: 'var(--chart-bar2)',
            x: 430,
            y: 100,
            radius: 65,
            outerRadius: 80,
            labelStart: false
        }
    ]

    return(
        <div className='home-card-participantes-edad'>
            <div className='hcdpe-header'>
                <span className='hcdpe-subtitle'>{etiquetas.home_card_participantes_edad_subtitle}</span>
                <span className='hcdpe-total'>{total.toLocaleString('en-US')}</span>
            </div>
            <div className='hcdpe-grafica-container'>
                <svg className='hcdpe-grafica'
                    viewBox="0 0 600 250"
                    preserveAspectRatio="xMidYMin meet">

                {
                    participantesEdad.map((edad, indx) => {
                        return <VChartParticipantesEdad key={edad.labelDato}
                                    total={total}
                                    {...edad}
                                    {...extraData[indx]}/>
                    })
                }    

                </svg>
            </div>
        </div>
    );
}

export default VCardParticipantesEdad;