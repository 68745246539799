import axios from 'axios';
import * as etiquetas from '../constants/EtiquetasGenerales';
import * as etiquetasHome from '../constants/EtiquetasHomePortal';

export const defaultGraficaCasillas = {
    "metaCasillas": 0,
    "casillasMetaPorcentaje": 0.0,
    "casillas": []
}

export const defaultGraficaVoluntarios = {
    "total": 0,
    "voluntarios": [],
    "externosEstatus": []
}

export const defaultGraficaParticipantes = {
    "total" : 0,
    "participantesSexo" : [ {
      "labelDato" : etiquetasHome.home_card_participantes_sexo_mujer,
      "value1" : 0,
      "value2" : 0,
      "porcentaje" : 0.0
    }, {
      "labelDato" : etiquetasHome.home_card_participantes_sexo_hombre,
      "value1" : 0,
      "value2" : 0,
      "porcentaje" : 0.0
    }, {
      "labelDato" : etiquetasHome.home_card_participantes_sexo_indefinido,
      "value1" : 0,
      "value2" : 0,
      "porcentaje" : 0.0
    } ],
    "participantesEdad" : [ {
      "labelDato" : etiquetasHome.home_chart_participantes_edad_3_a_5,
      "value1" : 0,
      "value2" : 0,
      "porcentaje" : 0.0
    }, {
      "labelDato" : etiquetasHome.home_chart_participantes_edad_6_a_9,
      "value1" : 0,
      "value2" : 0,
      "porcentaje" : 0.0
    }, {
      "labelDato" : etiquetasHome.home_chart_participantes_edad_10_a_13,
      "value1" : 0,
      "value2" : 0,
      "porcentaje" : 0.0
    }, {
      "labelDato" : etiquetasHome.home_chart_participantes_edad_14_a_17,
      "value1" : 0,
      "value2" : 0,
      "porcentaje" : 0.0
    } ]
}

export const obtenerDatosGraficaHomePortal = async (tipo, idEstado, idDistrito) => {
    let url;
    let defaultData;

    switch(tipo){
        case 1:
            url = etiquetasHome.URL_DATOS_CASILLAS;
            defaultData = defaultGraficaCasillas;
            break;
        case 2:
            url = etiquetasHome.URL_DATOS_VOLUNTARIOS;
            defaultData = defaultGraficaVoluntarios;
            break;
        case 3:
            url = etiquetasHome.URL_DATOS_PARTICIPACION;
            defaultData = defaultGraficaParticipantes;
            break;
        default:
            console.error("Gráfica inválida");
            return null;
    }
    
    try{
        const response = await axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + url,
                                        {
                                            idEstado: idEstado, 
                                            idDistrito: idDistrito
                                        });
        if(response.data != null 
            && response.data.code === 200
            && response.data.data !== null) {
                if(tipo === 3
                    && response.data.data.total === 0){
                    return defaultData;
                }
                return response.data.data;
        }
    }catch(error){
        console.error("Error al cargar gráfica en home: ", error);
    }

    return defaultData;
    
}