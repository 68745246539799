import React from 'react';
import {Row, Col} from 'antd';
import iconoParticipante from '../../assets/img/participantes_null.png';
import img_niñaR from '../../assets/img/home_niñaR.png';
import img_niñoR from '../../assets/img/home_niñoR.png';
import img_indefinidoR from '../../assets/img/home_indefinidoR.png';
import * as etiquetasResultados from '../../constants/EtiquetasResultados';

const VParticipantesXSexo = (props) => {

    const dato1 = 20;
    const dato2 = 50;
    const dato3 = 1.2;
    const dato4 = 10;

    return(
        <>
            <div id="participantes-sexo">
                {props.mostrarPorc ?
                    <div style={{marginTop:"-10px"}}>
                        <span className="tituloCard">{etiquetasResultados.etiqueta_participacion}</span>
                        <div className="rDiv-participantes">
                            <Row>
                                <Col  xs={24} sm={24} md={24} lg={7} xl={7}>
                                  <Row>
                                    <Col  xs={24} sm={24} md={24} lg={24} xl={24}>
                                      <img src={img_niñaR}
                                            className="rImg-style"
                                            alt="imagen_niña"/>
                                    </Col>
                                    <Col  xs={24} sm={24} md={24} lg={24} xl={24}>
                                      <span className="styleDescMujer">
                                        {etiquetasResultados.etiqueta_mujer}
                                      </span>
                                    </Col>
                                    <Col  xs={24} sm={24} md={24} lg={24} xl={24}>
                                      <span className="styleDescMujerPorcentaje">
                                        {props.porcNina}%
                                      </span>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                                  <Row>
                                    <Col  xs={24} sm={24} md={24} lg={24} xl={24}>
                                      <img src={img_niñoR}
                                           className="rImg-style-dos"
                                           alt="imagen_niño"/>
                                    </Col>
                                    <Col  xs={24} sm={24} md={24} lg={24} xl={24}>
                                      <span className="styleDescHombre">
                                        {etiquetasResultados.etiqueta_hombre}
                                      </span>
                                    </Col>
                                    <Col  xs={24} sm={24} md={24} lg={24} xl={24}>
                                      <span className="styleDescHombrePorcentaje">
                                        {props.porcNino}%
                                      </span>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={7} xl={7}>
                                  <Row>
                                    <Col  xs={24} sm={24} md={24} lg={24} xl={24}>
                                      <img src={img_indefinidoR}
                                           className="rImg-style-dos"
                                           alt="imagen_otro"/>
                                    </Col>
                                    <Col  xs={24} sm={24} md={24} lg={24} xl={24}>
                                      <span className="styleDescNinguno">
                                        {etiquetasResultados.etiqueta_ninguno}
                                      </span>
                                    </Col>
                                    <Col  xs={24} sm={24} md={24} lg={24} xl={24}>
                                      <span className="styleDescNingunoPorcentaje">
                                        {props.porcOtro}%
                                      </span>
                                    </Col>
                                  </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className="rDiv-participantes-label">
                            <span className="rText-styleSinResponder">
                            *Porcentaje de participantes que prefirieron no responder esta pregunta:{props.porcNadie}%</span>
                        </div>
                    </div>
                    :
                    <Row>
                      <Col  xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div style={{"textAlign":"center"}}>
                        <img src={iconoParticipante}
                             className="rParticipante-null"
                             alt ="imagen_participantes"/>
                        <br/><br/>
                        <span className="rTexto-null">No hay datos</span>
                    </div>
                  </Col>
              </Row>
                }
            </div>
        </>
    )
}

export default VParticipantesXSexo;
