import React from 'react';
import { VictoryPie, VictoryLabel } from 'victory';
import VLabel from './VLabel';
import * as etiquetas from '../../constants/EtiquetasHomePortal';
import img_casilla_escuela from '../../assets/img/home_casilla_escuela.png';

const VChartCasilla = ({ casilla: {labelDato, value1, value2, porcentaje} }) => {
    
    return(
        <div className='home-chart-casilla'>
            <div className='hchc-etiqueta'>
                <img className='hchc-imagen' 
                    src={img_casilla_escuela} 
                    alt='Tipo de casilla' />
                <span className='hchc-label'>{labelDato}</span>
            </div>
            <div className='hchc-data'>
                <div className='hchc-grafica-container'>
                    <svg className='hchc-grafica'
                        viewBox="0 0 400 400"
                        preserveAspectRatio="xMidYMin meet">
                        <circle cx={200} cy={200} r={200} fill='var(--chart-bar1)'/>
                        <circle cx={200} cy={200} r={170} fill='#FFFFFF'/>
                        <VictoryPie
                            standalone={false}
                            width={400} 
                            height={400}
                            data={[{ x: 1, y: value2 }, { x: 2, y: value1 - value2 }]}
                            colorScale={['var(--chart-bar4)', '#FFFFFF']}
                            innerRadius={120} 
                            cornerRadius={25}
                            labels = {[]}/>
                        <VictoryLabel
                            textAnchor="middle"
                            style={{ fontFamily: 'RobotoCondensed-Bold', 
                                    fontSize: 70, 
                                    fill: `${'var(--chart-bar4)'}` }}
                            x={200} 
                            y={200}
                            text={`${porcentaje}%`}/>
                    </svg>
                </div>
                <div className='hchc-estatusCasillaValue'>
                    <span style={{color:`${'var(--chart-bar1)'}`}}>{value1.toLocaleString('en-US')}</span>
                    <span style={{color:`${'var(--chart-bar4)'}`}} >{value2.toLocaleString('en-US')}</span>
                </div>
                <div className='hchc-estatusCasillaLabel'>
                    <VLabel label={etiquetas.home_chart_casillas_lbl_registradas}
                            color='var(--chart-bar1)'/>
                    <VLabel label={etiquetas.home_chart_casillas_lbl_asignadas}
                            color='var(--chart-bar4)'/>
                </div>
            </div>
        </div>
    );
}

export default VChartCasilla;