import {
	ERROR_BITACORA_JSON,
	GUARDAR_BITACORA_JSON,
	INICIAR_OPERACION_BITACORA_JSON,
	TERMINAR_OPERACION_BITACORA_JSON
} from "./bitacoraJson-types";

const INITIAL_STATE = {
	loading: false,
	infoResponse: {
		status: null,
		message: null
	}
};

const bitacoraJsonReducer = (state = INITIAL_STATE, action) => {
	switch(action.type) {
		case GUARDAR_BITACORA_JSON:
			return {
				...state,
				infoResponse: action.payload
			};
		case INICIAR_OPERACION_BITACORA_JSON:
			return {
				...state,
				loading: true,
			};
		case TERMINAR_OPERACION_BITACORA_JSON:
			return {
				...state,
				loading: false,
				infoResponse: {
					status: null,
					message: null
				}
			};
		case ERROR_BITACORA_JSON:
			return {
				...state,
				infoResponse: action.payload
			};
		default:
			return state;
	}
};

export default bitacoraJsonReducer;