import React from 'react';
import { Select } from 'antd';
import {SearchOutlined, UpOutlined } from '@ant-design/icons'; 
import * as etiquetas from '../../constants/EtiquetasPublicador';

const { Option } = Select;

const VFiltros = ({ tipoReporte, listaReportes, reporteSeleccionado, 
                    nivelSeleccionado, seleccionaReporte, seleccionaNivelReporte }) => {
    return(<div className='reporte-filtros-container'>
            <div className='rfc-combo-reportes'>
                <Select showArrow={true} 
                    optionLabelProp="label"
                    placeholder={
                        <>
                          <SearchOutlined />
                          &nbsp; {etiquetas.SELECCIONE_REPORTE}
                        </>
                      }
                    value={reporteSeleccionado}
                    onSelect={seleccionaReporte}>
                    {
                        listaReportes
                        && Object.values(listaReportes).map((reporte) => (
                            <Option key={reporte.key}
                                value={reporte.key}
                                label={
                                    <>
                                        <SearchOutlined />
                                        &nbsp;
                                        {reporte.label}
                                    </>
                                } >
                                {reporte.label}
                            </Option>
                        ))
                    }
                </Select>
            </div>
            {
                tipoReporte === etiquetas.FOLDER_CEDULAS ?
                <div className='rfc-combo-niveles'>
                    <Select showArrow={true}
                        suffixIcon={<UpOutlined />}
                        placeholder={etiquetas.SELECCIONE_NIVEL}
                        value={nivelSeleccionado}
                        onSelect={seleccionaNivelReporte}>
                        {
                            listaReportes 
                            && listaReportes[reporteSeleccionado]
                            && listaReportes[reporteSeleccionado].niveles
                            && Object.values(listaReportes[reporteSeleccionado].niveles).map((nivel) => (
                                <Option key={nivel.key}
                                    value={nivel.key}>
                                    {nivel.label}
                                </Option>
                            ))
                        }
                    </Select>
                </div>
                : ''
            }
            
        </div>);
}

export default VFiltros;