import React from 'react';

import {Button, Select, Row, Col, Input, Layout, Table,
        Card, Modal, Pagination, Tooltip, Spin, Empty} from 'antd';
import { EditOutlined,EyeOutlined,MailOutlined,FrownOutlined,
         CloseOutlined, HomeFilled,QuestionCircleFilled,
         SearchOutlined } from '@ant-design/icons';
import * as etiquetas from '../../../constants/EtiquetasVoluntarios';
import CModalVoluntario from '../../../containers/voluntarios/CModalVoluntario';
import iconoFiltro from '../../../assets/img/icono_filter.png';

const VConsultaVoluntarios = (props) =>{
  const { Search } = Input;
  const { Option } = Select;

  return(
    <Layout className='validar_modulo'>
    <Row>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <h1 id="titulo-componente">
          {etiquetas.datos_voluntarios_lbl_consulta_modificacion}
        </h1>
      </Col>
    </Row>
    <div id="tabla-voluntarios">
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div id="tabla-titulo" className="table2Style">
              <Tooltip  title="">
                   <label>{etiquetas.titutlo_tabla_consulta}</label>
                   <label><QuestionCircleFilled /></label>
               </Tooltip>
          </div>
          <table className="tableStyle">
           <thead className="tableStyle">
             <th>
               <Tooltip title={etiquetas.ayuda_filtros}>
                 <QuestionCircleFilled />
               </Tooltip>
             </th>
             <th style={{ width: "18%" }}>
               Folio
               <br/>
               <div className="icon-preguntaTabla">
                 <Input
                   ref={props.refFolioInput}
                     enterButton
                     size="middle"
                     suffix = {
                       <img style={{width:12 , height:12}}
                            src={iconoFiltro}
                            onClick={props.obtenerVoluntariosPorfolio}/>
                     }
                     onKeyPress={
                       (e) => {if (e.key === 'Enter')
                       {props.obtenerVoluntariosPorfolio()}}
                     }
                   />
               </div>
             </th>
             <th style={{ width: "15%" }}>Persona voluntaria
               <br/>
                 <Input
                   ref={props.refNombreInput}
                     enterButton
                     size="middle"
                     suffix = {
                       <img style={{width:12 , height:12}}
                           src={iconoFiltro}
                           onClick={props.obtenerVoluntariosPorNombre}/>
                     }
                     onKeyPress={
                       (e) => {if (e.key === 'Enter')
                       {props.obtenerVoluntariosPorNombre()}}
                     }
                   />
             </th>
             <th style={{ width: "20%" }}>Municipio/Alcald&iacute;a
               <br/>
               <Select
                   ref={props.refMuniciInput}
                  style={{ width: "100%"}}
                  onChange={props.obtenerVoluntariosPorMunicio} >
                  <Option key={1000} value="">
                    Todos
                  </Option>
                 { props.listaMunicipios &&
                   props.listaMunicipios.map((value, index) =>(
                     <Option key={index}  value={value.idMunicipio}>
                       {value.nombreMunicipio}
                     </Option>
                   ))
                }
               </Select>
             </th>
             <th style={{ width: "20%" }}>Clave de elector
               <br/>
                 <Input
                   ref={props.refClaveInput}
                     enterButton
                     size="middle"
                     suffix = {
                       <img style={{width:12 , height:12}}
                            src={iconoFiltro}
                            onClick={props.obtenerVoluntariosPorClave}/>
                     }
                     onKeyPress={
                       (e) => {if (e.key === 'Enter')
                       {props.obtenerVoluntariosPorClave()}}
                     }
                 />
             </th>
             <th style={{ width: "15%" }}>Registro<div> <br/></div></th>
             <th >Acciones &nbsp;
             <div><br/></div></th>
           </thead>
           <tbody>

           {props.listaVoluntarios !== null ? props.listaVoluntarios &&
             props.listaVoluntarios.map((value, index) =>{
               return(
                 <tr key={index}>
                   <td></td>
                   <td >{value.folio}</td>
                   <td>{value.nombre}</td>
                   <td>{value.municipio}</td>
                   <td>{value.claveElector}</td>
                   <td>{value.fechaRegistro}</td>
                   <td>
                     <Tooltip title={etiquetas.ayuda_consulta}>
                       <a id="ver-voluntario"onClick={() =>{props.mostrarInformacion(value)}}>
                         <EyeOutlined/>
                       </a>
                     </Tooltip>
                     </td>
                     <td>
                     <Tooltip title={etiquetas.ayuda_modifica}>
                       <a id="editar-voluntario" onClick={() =>{props.enviarAmodificar(value)}}>
                         <EditOutlined />
                       </a>
                     </Tooltip>
                     </td>
                 </tr>
               )
           }) :
           <tr key={100}>
              <td></td><td></td><td></td>
              <td>
                <Empty
                  id="sindatos-voluntarios"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    <span>
                      Sin datos
                    </span>
                  }
                >
                </Empty>
              </td>
              <td></td><td></td><td></td>
              </tr>
              }
           </tbody>
          </table>
         </Col>
      </Row>
    </div>
    <div id="paginado">
      <Row>
        <Col xs={24} sm={24} md={10} lg={10} xl={10} id="inicio-paginado">
          <HomeFilled
            onClick={props.regresarPrimeraPagina} />
        </Col>
        <Col xs={24} sm={24} md={14} lg={14} xl={14}>
          <Pagination
            current={props.paginaActual}
            size="small"
            onChange={props.paginado}
            defaultPageSize = {5}
            showSizeChanger={false}
            total={props.total}
            disabled={props.total <= 5 ? true : false}
            responsive={true}/>
        </Col>
      </Row>
    </div>

    <CModalVoluntario
      cerrarModal = {props.cerrarModal}
      mostrarModal = {props.mostrarModal}
      cargarIneFrente = {props.cargarIneFrente}
      cargarIneReverso = {props.cargarIneReverso}
      cargarComprobante = {props.cargarComprobante}
      cargarFoto = {props.cargarFoto}
    />
     </Layout>
  )
}

export default VConsultaVoluntarios;
