
export const datos_asignacion_voluntarios_lbl_titulo             = "Asignación de voluntarios";
export const datos_voluntarios_asignados_lbl_titulo              = "Voluntarios asignados";
export const datos_voluntarios_asignados_lbl_folio_casilla       = "Folio de casilla";
export const datos_voluntarios_asignados_lbl_voluntario_asignado = "Voluntario asignado";
export const folio_casilla                                       = "Folio de casilla";
export const folio                                               = "Folio";


	



export const modificar                                           = "Modificar";
export const no_existen_voluntarios                              = "No existen Voluntarios en esta sección";
export const maximo_de_voluntarios                               = "La casilla ya tiene el número máximo de voluntarios asignados";

export const mensaje_casilla_modificada2                         ="casilla modificada"
export const mensaje_voluntarios_asignados                       ="Asignación a "; 
export const mensaje_voluntarios_asignados2                      ="casilla realizada"
export const limpiar                                             = "Limpiar";
export const buscar                                              = "Buscar";
export const no_existe_casilla                                   = "El dato que ingreso no existe";
export const resultados_voluntarios                              = "Resultados de voluntarios ";

export const vountarios_asignados                                = "Voluntarios asignados ";
export const vountario_asignado                                  = "Voluntario asignado";
export const guardar                                             = "Guardar";
export const voluntario_removido                                 = "El voluntario se ha removido";
export const voluntario_aniadido                                 = "El voluntario se ha añadido";
export const distrito                                            = "Distrito";
export const zore                                                = "ZOR";
export const ubicacion                                           = "Ubicación";

export const con_asignacion                                      = "Con asignación";
export const sin_asignacion                                      = "Sin asignación";

export const subtitulo_componente                                = "Listado de casillas registradas ";
export const etiqueta_tooltip_resultado                          ="Presiona en Añadir para asignar a la persona voluntaria.";
export const etiqueta_tooltip_acciones                           ="Selecciona la acción que deseas realizar: asignar, editar o eliminar el registro..";
export const etiqueta_tooltip_asignado                           ="Presiona en Quitar para modificar a la persona voluntaria que se asignó.";
export const etiqueta_tooltip_info                               ="Apóyate de los filtros para localizar la casilla a la que se asignarán las personas voluntarias.";
export const borrar                                              = "Eliminar el registro";

export const anadir                                              = "Añadir";
export const quitar                                              = "Quitar";
export const casilla                                             = "Casilla ";
export const asignar_voluntarios                                 = "Asignar voluntarios ";
export const modificar_voluntarios                               = "Modificar voluntarios";



//Seguimiento Voluntarios
export const titulo_componente                                   = "Seguimiento";
export const voluntario                                          = "Persona voluntaria";
export const municipio                                           = "Municipio/Alcaldía:";
export const clave                                               = "Clave de elector";
export const registro                                            = "Registro";
export const acciones                                            = "Acciones";
export const descartar                                           = "Descartar";
export const reasignar                                           = "Reasignar";
export const asignarCapacitacion                                 ="Asignar sede de capacitación";
export const descartarPersona                                    ="La persona voluntaria seleccionada se descartará del proceso"
export const pregunta                                            ="¿Deseas continuar?";
export const continuar                                           = "Continuar";
export const cancelar                                            = "Cancelar";
export const aceptar                                             = "Aceptar";
export const motivo                                              ="Agregue los motivos por los que se descarta al voluntario";
export const enviar                                              ="Enviar";
export const tituloBox                                           ="Asignar sede de capacitación";
export const tipoSede                                            ="Tipo de sede";
export const sede                                                ="Sede";
export const selecciona                                          = "Selecciona";
export const fecha                                               = "Fecha";
export const hora                                                = "Hora";
export const actualizacionexitosa                                = "La sede a sido asignada con éxito";
export const titulatio                                           = "Listado de personas voluntarias registradas  ";
export const toltip2                                             ="Selecciona a una persona voluntaria para descartarla del proceso.";
export const asignar                                             = "Asignar capacitación.";
export const mensaje_sede_presencial                             ="Sede presencial";
export const mensaje_sede_virtual                                ="Sede virtual";
export const mensaje_sede_presencial2                            ="asignada";
export const mensaje_sede_presencial3                            = "El comprobante se envió al correo";
export const mensaje_sede_presencial4                            = "electrónico registrado.";
export const registro_nuevo                                      = "Registro nuevo";
export const reasignarPersona                                    ="Persona voluntaria";
export const reasignarPersona2                                   ="reasignada";
export const boton_reasignar                                     ="Reasignación nueva";
