import React from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import VConvocatorias from '../../components/convocatorias/VConvocatorias';
import AuthenticatedComponent from '../../components/comunes/AuthenticatedComponent';
import CTemplate from '../homeInterno/CTemplate';
import { createStructuredSelector } from 'reselect';
import { selectDistrito, selectEstado } from '../../redux/menu/menu-selectors';
import { selectCurrentUser } from '../../redux/user/user-selectors';
import {obtenerEstatusModulo} from '../../redux/menu/menu-utils';
import * as etiquetas from '../../constants/EtiquetasGenerales';
import * as etiquetasConvocatorias from '../../constants/EtiquetasConvocatorias';
import * as info from '../../constants/InfoSistema';
import { roles_permitidos_convocatorias } from '../../constants/EtiquetasConvocatorias';



let msjError = "";

class CConvocatorias extends React.Component{

    constructor(props){
        super(props);

        this.state ={
          fechaInicio:'',
          fechaTermino: '',
          cantidadCarteles: 0,
          observaciones: '',
          estatus:false,
          listConvocatorias:[],
          numeroConvocatoria:0,
          usuarioCau:false,
          abrirModal:false,
          limpiar: false,
          rolPermitido:false,
        }

    }


    async componentDidUpdate(prevProps) {
      if (this.props.estado !== prevProps.estado
        || this.props.distrito !== prevProps.distrito) {

        console.log("estado",this.props.estado.idEstado);
        console.log("distrito",this.props.distrito.idDistrito);
        

        if(this.props.estado.idEstado !== 0 && this.props.distrito.idDistrito !== 0){

            console.log("estado",this.props.estado.idEstado);
            console.log("distrito",this.props.distrito.idDistrito);
            this.enviarConsulta();
            this.setState({
              limpiar:true
            });
            
        }
    
      }
    }

    componentDidMount(){

      this.consultarEstatusModulo();
      this.consultarPermisoUsuario();
  
    }

    consultarPermisoUsuario = () =>{
      this.setState({
        rolPermitido : roles_permitidos_convocatorias.includes(this.props.user.rolUsuario)
      }, ()=>console.log(this.state.rolPermitido));
    }

    consultarEstatusModulo () {

      var idDetalleProceso = this.props.user.idDetalle;
      var idProcesoElectoral = this.props.user.idProcesoElectoral;
      var idEstado = this.props.estado.idEstado;
      var idDistrito = this.props.distrito.idDistrito;
      var idSistema = info.ID_SISTEMA;
      var idModulo = this.props.location.state !== undefined ?
                     this.props.location.state.idModulo : 0;
      var grupoSistema = this.props.user.rolUsuario;

      var index = grupoSistema.search("CAU.OC");

      if(index >= 0){
        this.setState({
          usuarioCau:true
        }, ()=>console.log(true))
      }else{
        console.log("tiene otro tipo de rol")
      }
    
      obtenerEstatusModulo(idProcesoElectoral, idDetalleProceso,idSistema,
        idEstado, idDistrito, idModulo, grupoSistema).then(respuesta =>{
        console.info("El estatus del módulo Consulta es: ",respuesta);
        if(respuesta === "A"){
          console.info("El Módulo registra convocatoria está abierto ");
          this.enviarConsulta();
          this.setState({
            estatus:true
          }, ()=>console.log(true))
        }else{
          console.info("El Módulo registra convocatoria está cerrado ");
        }
      })
    
    }

    enviarRegistro = () => {

      var dataJson = {
            idDetalleProceso:info.ID_DETALLE_PROCESO,
            idProcesoElectoral:info.ID_PROCESO_ELECTORAL,
            idEstado: this.props.estado.idEstado,
            idDistrito: this.props.distrito.idDistrito,
            numeroConvocatoria: this.state.numeroConvocatoria,
            fechaInicio: this.state.fechaInicio,
            fechaTermino: this.state.fechaTermino,
            observaciones: this.state.observaciones,
            cantidadCarteles: this.state.cantidadCarteles,
            usuario:this.props.user.username,
        }
      
        //console.log(dataJson);

        axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + 'ws/registraConvocatoria', dataJson,
        {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': this.props.user ? this.props.user.tknJWT : null
            }
        }).then(_response => {
          this.setState({abrirModal: true})
          console.log("Se registro con exito la convocatoria");
      }).catch(error => {
          this.setState({cargando: false});
          console.error("Error al guardar la convocatoria "+
              "enviarServidor()",error);
          if (error.response){
              if (error.response.status === 409){
                  msjError = error.response.data.message;
              } else if (error.response.data && error.response.data.causa
                  && error.response.data.causa.includes("Error de validación")){
                  msjError = error.response.data.message;
              } else {
                  msjError = etiquetas.gral_error_cau;
              }
          } else {
              msjError = etiquetas.gral_error_cau;
          }
          this.setState({
            msjError : msjError,
            abrirModalError : true
          });
      });
    }

    enviarConsulta = () => {

      var dataJson = {
            idDetalleProceso:info.ID_DETALLE_PROCESO,
            idProcesoElectoral:info.ID_PROCESO_ELECTORAL,
            idEstado: this.props.estado.idEstado,
            idDistrito: this.props.distrito.idDistrito,
            inicio:1,
            fin:2,
            numeroConvocatoria:null
        }
      
        console.log(dataJson);

        axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + 'ws/consultaConvocatoria', dataJson,
        {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': this.props.user ? this.props.user.tknJWT : null
            }
        }).then(res => {
            const convocatorias = res.data.totalConvocatorias;

            let num = 0;

            if( convocatorias !== null){
              num = convocatorias;
            }

            this.setState({
              numeroConvocatoria: num + 1
            }, ()=>console.log(this.state.numeroConvocatoria));

            if(this.state.limpiar === true){
              window.location.reload();
            }

            //console.log("numeroC", this.state.numeroConvocatoria);

        }).catch(error => {
           console.log("Error al obtener las convocatorias", error);
        });
    }

    onChangeFI = (dateString) => {
      this.setState({fechaInicio: dateString},()=>console.log(this.state.fechaInicio))
    }

    onChangeFT = (dateString) => {
      this.setState({fechaTermino: dateString},()=>console.log(this.state.fechaTermino))
    }

    changeC = (e) =>{
      this.setState({
          cantidadCarteles: e
      }, ()=>console.log(this.state.cantidadCarteles))
    }

    cambioText = (e) =>{
      this.setState({
          [e.target.name]: e.target.value
      })
    }

    onChangeRoute =()=>{
      console.log("Entre a cambio de ruta")
      this.setState({abrirModalExitoRegistro: false});
      window.location.reload();
    }
  
    onChangeRouteModulo =()=>{
      console.log("Entre a cambio de ruta")
      this.setState({abrirModalExitoRegistro: false});
      this.props.history.push('/modulo/2');
    }


    render(){
        return(
          <>
          <AuthenticatedComponent>
            <CTemplate 
              bloqueado = {this.state.numeroConvocatoria !== 0 ? false : true}
              contenido ={
                <>
                  <VConvocatorias
                      onChangeFI = {this.onChangeFI}
                      onChangeFT = {this.onChangeFT}
                      changeC = {this.changeC}
                      cambioText = {this.cambioText}
                      enviarRegistro = {this.enviarRegistro}
                      numeroConvocatoria = {this.state.numeroConvocatoria}
                      rolCau = {this.state.usuarioCau}
                      abrirModal = {this.state.abrirModal}
                      onChangeRoute ={this.onChangeRoute}
                      onChangeRouteModulo = {this.onChangeRouteModulo}
                      limpiar = {this.state.limpiar}
                      rolPermitido = {this.state.rolPermitido}

                  />
                </>
              }/>
          </AuthenticatedComponent>
            
          </>
        )
    }
                
}

const mapStateToProps = createStructuredSelector({

  estado: selectEstado,
  distrito: selectDistrito,
  user: selectCurrentUser

});

export default connect(mapStateToProps)(CConvocatorias);