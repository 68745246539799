import React from 'react'
import axios from 'axios';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../redux/user/user-selectors';
import { userLogout } from '../../redux/user/user-actions';
import CTemplateExt from '../homeExterno/CTemplateExt';
import * as etiquetas from '../../constants/EtiquetasGenerales';
import {PATH_LOGIN} from '../../constants/routers/paths'

class CLogout extends React.Component {

	componentDidMount() {
		const jwt = this.props.user ? this.props.user.tknJWT : null;

		if (jwt) { 
			axios.post(process.env.REACT_APP_API_URL + etiquetas.WS_CENTRAL + 'ws/cierraSesion', 
					{},
					{headers: { Authorization: jwt}})
				.finally(() => {
						this.props.userLogout();
						this.props.history.push(PATH_LOGIN);
					});
		} else {
			this.props.userLogout();
			this.props.history.push(PATH_LOGIN);
		}
	}
	
	render() {
		return <CTemplateExt guia={false} 
					bloqueado={true}/>;
	}
}

const mapStateToProps = createStructuredSelector({
    user: selectCurrentUser
});

const mapDispatchToProps = dispatch => ({
	userLogout: () => dispatch(userLogout())
});

export default connect(mapStateToProps, mapDispatchToProps)(CLogout);
