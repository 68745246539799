import React from 'react';
import CTemplate from '../homeInterno/CTemplate';
import AuthenticatedComponent from "../../components/comunes/AuthenticatedComponent";
import VModulo from  '../../components/comunes/VModulo';

class CModulo extends React.Component{
  constructor(props){
    super(props);
  }

  render(){
    return(
        <AuthenticatedComponent externo={false} privado={true}>
            <CTemplate isHome contenido=
                    {<VModulo idSubMenu={this.props.match.params.idSubMenu}/>}/>
        </AuthenticatedComponent>
      
    );
  }
}

export default CModulo;
