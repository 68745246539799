import React from 'react';
import { VictoryChart, VictoryAxis, VictoryBar, VictoryTooltip } from 'victory';
import VLabel from './VLabel';
import * as etiquetas from '../../constants/EtiquetasHomePortal';

const VChartVoluntarios = ({ title, voluntarios }) => {
    const colors = ['var(--chart-bar1)', 
                    'var(--chart-bar2)', 
                    'var(--chart-bar3)', 
                    'var(--chart-bar4)'];

    const data = voluntarios.map((voluntario, index) => { 
        return {
            x: voluntario.labelDato, 
            y: voluntario.value1, 
            fill: colors[index],
            label: voluntario.value1.toLocaleString('en-US') + etiquetas.home_chart_voluntarios_tooltip
        }
    });
    
    return(
        <div className='home-chart-voluntario'>
            <span className='hchv-title'>{title}</span>
            <div className='hchv-grafica'>
                <VictoryChart domainPadding={20}
                            width={900} 
                            height={145}
                            padding={{ top: 0, bottom: 25, left: 20, right: 20 }}>
                    <VictoryBar
                        horizontal
                        cornerRadius={{ top: 7 }}
                        data={data}
                        style={{ data: { fill: (d) => { return d.datum.fill; } } }} 
                        sortKey="x"
                        sortOrder="descending"
                        barRatio={0.9}
                        labelComponent={
                            <VictoryTooltip
                                style={{fontSize: '25px', color:'#000000'}}
                                cornerRadius={20}
                                pointerLength={0}
                                flyoutStyle={{
                                    stroke: "none",
                                    fill: 'var(--chart-back-ground)'
                                }}
                                flyoutPadding={{top: 10, bottom: 10, left: 20, right: 20 }}
                            />
                        } />
                    <VictoryAxis dependentAxis/>
                </VictoryChart>
            </div>
            <div className='hchv-estatusVoluntario'>
                {
                    data.map(voluntario => {
                        return <VLabel key = {voluntario.x}
                                    label = {voluntario.x}
                                    value = {voluntario.y}
                                    color = {voluntario.fill}/>
                        })
                }
            </div>
        </div>
    );
}

export default VChartVoluntarios;