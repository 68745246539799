import React, {useState, useEffect} from 'react';
import {Layout, Row, Col, Card, Input, InputNumber, DatePicker, Button, Modal, Form, Tooltip} from 'antd';
import * as etiquetasConvocatorias from '../../constants/EtiquetasConvocatorias';
import exitoConvocatoriaImg from '../../assets/img/exitoConvocatoria.png';
import iconoQuestion from '../../assets/img/icono_question.png';
import '../../css/convocatorias/convocatoriaRegistra.css'; 
import locale from 'antd/es/date-picker/locale/es_ES';
import moment, { isMoment } from 'moment';
import 'moment/locale/es';


const VConvocatorias = (props) =>{

    const { TextArea } = Input;
    const [form] = Form.useForm();

    const [fechaI, setFechaI] = useState();
    const [fechaT, setFechaT] = useState();

    const [fechaIncorrecta, setFechaIncorrecta] = useState(false);
    const [numCartel, setNumCartel] = useState();
    const [fvalObservar, setFvalObservar] = useState(false);
    const [valres, setValres] = useState(500);
    const [deshabilitar, setDeshabilitar] = useState(false);


    const [observacion, setObservacion] = useState();
    
    useEffect(()=>{
        revisarfechas();
        revisarObservacion();
    },[fechaI, fechaT, numCartel, observacion, fvalObservar]);


    const revisarObservacion = (e) => {

        const valor = observacion;
        const reg = /[!¡¿°´@#$%^&*_+=\\[\]{}"\\|<>\/?]+/;
        
        if(valor!== undefined){
            setValres(500 - valor.length)
        }

        /*if(reg.test(valor)){
           setFvalObservar(true);
        }else if(!isNaN(valor) && valor.length >=500){
           setFvalObservar(true);
        }else{
           setFvalObservar(false); 
        }*/

    }

    function disabledDate (current) {
        return (current && current > moment(new Date('2022-01-01')).endOf('day')) || (current && current < moment(new Date('2021-01-01')).endOf('month'));
    }

    const revisarfechas = () =>{

        var fechaUno = fechaI;
        var fechaDos = fechaT;
    
        if(fechaUno !== '' || fechaDos !== ''){
    
            var conv1 = moment(fechaI, 'DD/MMMM/YYYY');
            var conv2 = moment(fechaT, 'DD/MMMM/YYYY');
    
            if((conv1 > conv2)){
                setFechaIncorrecta(true);
            }else{
                setFechaIncorrecta(false);
            }
        }
    }


    return(

        <>
            <Layout className='validar_modulo'>
                <div>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <h1 id="titulo-componente">Registro de la convocatoria voluntarias/os<br/>
                        <span className="convocatoriaStyle">No. de convocatoria: {props.numeroConvocatoria}</span></h1>
                    </Col>
                </Row>
                <div>
                    <h4>Captura los datos requeridos. Los datos con (<b><font color="red">*</font></b>) son obligatorios.</h4>
                </div>
                <br/>
                <Row>
                    <Col offset={1} sm={22} md={22} lg={22}>
                    <Form form ={form} layout="vertical">
                    <Card bordered={false}>
                        <div>
                            <label>Periodo de difusión de la convocatoria.&nbsp;<Tooltip title={etiquetasConvocatorias.etiqueta_tooltip_tabla_periodo}>
                                                                                    <img style={{width:17 , height:17}} src={iconoQuestion}/>
                                                                                </Tooltip>
                            </label>
                        </div>
                        <br/>
                        <Row>
                            <Col sm={24} md={6} lg={6}>
                                <Form.Item form = {form} label="Fecha de inicio" name="fechaInicio" rules={[{required: true, message: 'Dato requerido'}]}
                                            validateStatus={fechaIncorrecta ? "error" : null} help={fechaIncorrecta ? "Dato incorrecto" : null}>
                                    <DatePicker style={{width: "175px"}} 
                                                onChange={(date, dateString) =>{ setFechaI(dateString)
                                                                                 props.onChangeFI(dateString)
                                                                                 revisarfechas()}}
                                                format={'DD/MMMM/YYYY'} locale={locale} disabledDate={disabledDate}/>
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={6} lg={6}>
                                <Form.Item form = {form} label="Fecha de término" name="fechaTermino" rules={[{required: true, message: 'Dato requerido'}]}
                                           validateStatus={fechaIncorrecta ? "error" : null} help={fechaIncorrecta ? "Dato incorrecto" : null}>
                                    <DatePicker style={{width: "175px"}} 
                                                onChange={(date, dateString) =>{ setFechaT(dateString)
                                                                                 props.onChangeFT(dateString)
                                                                                 revisarfechas()}}
                                                format={'DD/MMMM/YYYY'} locale={locale} disabledDate={disabledDate}/>
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={6} lg={6}>
                                <Form.Item form = {form} label="Cantidad de carteles" name="cantidadCarteles" rules={[{required: true, message: 'Dato requerido'}]}>
                                    <InputNumber name = "cantidadCarteles"
                                                 onChange = {(e)=>{ setNumCartel(Math.round(e))
                                                                    props.changeC(Math.round(e))}}
                                                 style={{width: "146px"}}
                                                 min={0} max={9999}
                                                 precision={0}
                                                 value={numCartel}></InputNumber>
                                </Form.Item>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col sm={24} md={24} lg={24}>
                                <Form.Item form = {form} label="Observaciones" name="observaciones">
                                    <TextArea name = "observaciones"
                                              onChange = {(e)=>{ setObservacion(e.target.value)
                                                                 props.cambioText(e)
                                                                 revisarObservacion(e)}}
                                              rows={5}
                                              />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row >
                            <Col offset={20} sm={24} md={4} lg={4}>
                                <span>{valres}&nbsp;caracteres restantes</span>
                            </Col>
                        </Row>
                        <br/>
                        <br/>
                        <div className="div-boton">
                        <Row>
                            <Col offset={10} sm={6} md={6} lg={6}>
                                <Tooltip title={(fechaI !== undefined) && (fechaI !== '') &&
                                                (fechaT !== undefined) && (fechaT !== '') && 
                                                (numCartel !== undefined) && (numCartel !== '') && (numCartel > 0)  && (fechaIncorrecta === false) && (fvalObservar !== true ) && (props.rolCau === false) ? null: etiquetasConvocatorias.etiqueta_tooltip_boton_guardar}>
                                    
                                        <Button htmlType="submit" disabled={(fechaI !== undefined) && (fechaI !== '') &&
                                                                            (fechaT !== undefined) && (fechaT !== '') && 
                                                                            (numCartel !== undefined) && (numCartel !== '') && (numCartel > 0)  && (fechaIncorrecta === false) && (fvalObservar !== true ) && (props.rolCau === false) && (props.rolPermitido === true) ? false : true } id="botonGuardarConvocatoria" style={{borderColor: "#ffffff", width:"118px", borderRadius: "10px"}} onClick={props.enviarRegistro}>Guardar</Button>
                                    
                                </Tooltip>                                                   
                            </Col>
                        </Row>
                        </div>
                    </Card>
                    </Form>
                    </Col>
                </Row>
                </div>
                <Modal visible={props.abrirModal}
                   title={<img  src={exitoConvocatoriaImg} id="img-exitoConvocatoria-modal"/>}
                   centered 
                   className="modalConvocatoria"
                   onCancel={props.onChangeRouteModulo}
                   footer={[null]}>{
                    <>
                        <div>
                            <span className="titulo-exitoConvocatoria">Convocatoria registrada</span>
                        </div>
                        <div>
                            <span className="notificacion-exitoConvocatoria">La información se guardó con éxito</span>
                        </div>
                        <br/>
                        <Row>
                            <Col offset={6} span={6}>
                                <Button type="primary" htmlType="submit" style={{ background: "#D4217F", borderColor: "#ffffff" }} onClick={props.onChangeRoute}>Registro nuevo</Button>
                            </Col>
                        </Row>
                    </>
                  }
            </Modal>
            </Layout>

        </>

    )


}

export default VConvocatorias;