import React from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectSubMenus } from '../../redux/menu/menu-selectors';
import * as etiquetas from '../../constants/EtiquetasMenu';
import '../../css/header/modulo.scss';

const VModulo = ({ idSubMenu, subMenus }) => { 
    const submenu = subMenus[idSubMenu];

    return(
        <div className='modulos-container'>
            {
                submenu && submenu.modulos ? 
                <div className='mdc-modulos'>
                {
                    Object.entries(submenu.modulos).map(([key, value])  => {
                        return  <Link to={{
                                    pathname: value.urlModulo,
                                    state: {
                                        idModulo: key,
                                    },
                                    }}
                                    className={`mdc-modulo-link 
                                                ${!value.isEnabled ?'mdc-modulo-link-disabled':''}`}
                                    key={key}>
                                    <div className='mdc-modulo-data'>
                                        <img className='mdc-modulo-data-image' 
                                            alt='Imagen del módulo'
                                            src={value.image}/>
                                        <span className='mdc-modulo-data-title'>
                                            {value.nombreModulo}
                                        </span>
                                    </div>
                                </Link>
                    })
                }
                </div> 
                : 
                <div className='mdc-title'>
                    {!submenu?etiquetas.MENU_SUBMENU_INVALIDO
                    :etiquetas.MENU_SUBMENU_SIN_MODULOS}          
                </div>
            } 
           
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    subMenus: selectSubMenus
});

export default connect(mapStateToProps)(VModulo);