
export const datos_asignacion_voluntarios_lbl_titulo             = "Asignación de voluntarios";
export const datos_voluntarios_asignados_lbl_titulo              = "Voluntarios asignados";
export const datos_voluntarios_asignados_lbl_folio_casilla       = "Folio de casilla";
export const datos_voluntarios_asignados_lbl_voluntario_asignado = "Voluntario asignado";
export const numero_zona                                         = "Número de Zona de responsabilidad";
export const numero_zore                                         = "No. de ZORE";
export const seccion_casilla                                     = "Sección";
export const municipio_casilla                                   = "Municipio";
export const tipo_casilla                                        = "Tipo de zona";	
export const vol_asig                                            = "Voluntarios asignados";
export const acciones                                            = "Acciones";
export const asignar                                             = "Asignar";
export const modificar                                           = "Modificar";
export const no_existen_voluntarios                              = "No existen Voluntarios en esta sección";
export const maximo_de_voluntarios                               = "La casilla ya tiene el número máximo de voluntarios asignados";
export const mensaje_casilla_modificada                          = "La asignación del voluntario a la casilla se modificó con éxito.";
export const mensaje_voluntarios_asignados                       = "La asignación del voluntario a la casilla se realizó con éxito."; 
export const limpiar                                             = "Limpiar";
export const buscar                                              = "Buscar";
export const no_existe_casilla                                   = "El dato que ingreso no existe";
export const resultados_voluntarios                              = "Resultados de voluntarios";
export const voluntario                                          = "Voluntario";
export const vountarios_asignados                                = "Voluntarios asignados";
export const vountario_asignado                                  = "Voluntario asignado";
export const guardar                                             = "Guardar";
export const voluntario_removido                                 = "El voluntario se ha removido";
export const voluntario_aniadido                                 = "El voluntario se ha añadido";
export const distrito                                            = "Distrito";
export const zonaderesponsabilidad                               = "ZORE";
export const ubicacion                                           = "Ubicación";
export const selecciona                                          = "Selecciona";
export const etiqueta_tooltip_res                                = "Presiona en Añadir para asignar a la persona voluntaria.";

//roles que solo pueden capturar
export const roles_captura = [
  "CIJ.ADMIN.OC",
  "CIJ.CAPTURA.JD",
  "CIJ.CAPTURA.JL",
  "CIJ.CAPTURA.OC",
  "CIJ.CAU.OC",
  "CIJ.CAPTURA_VE.JD",
  "CIJ.CAPTURA_VE.JL",
  "CIJ.CAPTURA_VCEYEC.JL",
  "CIJ.CAPTURA_VCEYEC.JD",
];
//roles que pueden ingresar al módulo pero no reservan
export const roles_permitidos = [
  "CIJ.ADMIN.OC",
  "CIJ.CAPTURA.OC",
  "CIJ.CONSULTA.OC",
  "CIJ.CAU.OC",
  "CIJ.CAPTURA.JL",
  "CIJ.CONSULTA.JL",
  "CIJ.CAPTURA.JD",
  "CIJ.CONSULTA.JD",
  "CIJ.CAPTURA_VE.JL",
  "CIJ.CAPTURA_VE.JD",
  "CIJ.CAPTURA_VERFE.JL",
  "CIJ.CAPTURA_VERFE.JD",
  "CIJ.CAPTURA_VOE.JL",
  "CIJ.CAPTURA_VOE.JD",
  "CIJ.CAPTURA_VS.JL",
  "CIJ.CAPTURA_VS.JD",
  "CIJ.CAPTURA_VCEYEC.JL",
  "CIJ.CAPTURA_VCEYEC.JD",
  "CIJ.CAPTURA_EXT_OPL.JL",
  "CIJ.CONSULTA_EXT_OPL.JL",
  "CIJ.CAPTURA_EXT_OPL.JD",
  "CIJ.CONSULTA_EXT_OPL.JD",
  "CIJ.CONSULTA_JOSA.JD"
];