import React from 'react';
import VChartCasilla from './VChartCasilla';
import { Carousel } from 'react-responsive-carousel';

import "react-responsive-carousel/lib/styles/carousel.min.css"; 

export const VCardCasillas = ({ casillas }) => {
    
    return(
        <div className='home-card-casillas'>
            <Carousel showArrows={false} 
                    showStatus={false}
                    showIndicators={true}
                    infiniteLoop={true}
                    showThumbs={false}
                    useKeyboardArrows={true}
                    autoPlay={true}
                    stopOnHover={true}
                    swipeable={true}
                    thumbWidth={100}
                    selectedItem={0}
                    interval={4000}
                    transitionTime={500}
                    width={400}
                    dynamicHeight={false}>
            {
                casillas.map(casilla => {
                    return <VChartCasilla key = {casilla.labelDato}
                                casilla = {casilla}/>
                })
            }
            </Carousel>
        </div>
    );
}

export default VCardCasillas;