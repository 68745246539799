import React from  'react';
import {Tooltip} from 'antd';
import { EyeOutlined,DeleteOutlined } from '@ant-design/icons';
import iconoMostrar from '../../../assets/img/ojo_rosado.png';
import iconoEliminar from '../../../assets/img/basura_rosado.png';

const VOpcionesDoc = (props) => {
  return(
    <div className="opcionesDoc">
    {props.archivo ?
      <>
      <Tooltip title="Consultar">
        <EyeOutlined id="mostrar-doc"
          onClick={() => props.vistaPrevia(props.archivo)} />
      </Tooltip >
      <DeleteOutlined id="eliminar-doc"
        onClick={() => props.eliminarArchivo(props.opcion)}/>
      </>
      :
      ""}
    </div>
  )
}

export default VOpcionesDoc;
