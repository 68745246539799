import VoluntarioActionTypes from './voluntario-types';

const INITIAL_STATE = {
  currentVoluntario: null,
  voluntarioId: null
};

const menuReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VoluntarioActionTypes.SET_CURRENT_VOLUNTARIO:
      return {
        ...state,
        currentVoluntario: action.payload
      }
    case VoluntarioActionTypes.SET_CURRENT_VOLUNTARIO_ID:
      return {
        ...state,
        VoluntarioId: action.payload
      }
    default:
      return state;
  }
};

export default menuReducer;
