import React from 'react';
import {Upload} from 'antd';
import iconoCargar from '../../../assets/img/icono_cargar_doc.png';
import {indicacion_carga_uno,
        indicacion_carga_dos} from  '../../../constants/EtiquetasDocumentacion';

const VCargarArchivo = (props) =>{
  const uploadButton = (
     <div className="areaCarga">
       {props.cargando ? <cargandoOutlined /> :
         <img src={iconoCargar}/>}
       <div className="indicaciones">
        <p className="parrafoUno">{indicacion_carga_uno}</p>
        <p className="parrafoUno">o</p>
        <p className="parrafoDos">{indicacion_carga_dos}</p>
       </div>
     </div>
   );

  return (
    <Upload
       name={props.nombre}
       listType="picture-card"
       className="avatar-uploader"
       showUploadList={false}
       beforeUpload={props.validacion}
       onChange={props.cargarDocumento}
       multiple={false}
       disabled={props.disabled}
     >
       {props.imagenUrlDoc ?
         <img src={props.imagenUrlDoc} alt="avatar" style={{ width: '100%' }} />
         : uploadButton}
     </Upload>
  );
}

export default VCargarArchivo;
