import MenuActionTypes from './menu-types';

export const updateMenu = (subMenus) => ({
  type: MenuActionTypes.UPDATE_MENU,
  payload: subMenus,
});

export const updateListaEstados = (estados) => ({
  type: MenuActionTypes.UPDATE_LISTA_ESTADOS,
  payload: estados
});

export const updateListaDistritos = (distritos) => ({
  type: MenuActionTypes.UPDATE_LISTA_DISTRITOS,
  payload: distritos
});

export const updateEstado = (estado) => ({
  type: MenuActionTypes.UPDATE_ESTADO,
  payload: estado
});

export const updateDistrito = (distrito) => ({
  type: MenuActionTypes.UPDATE_DISTRITO,
  payload: distrito
});