import React from 'react';
import {Link} from 'react-router-dom';
import { Result, Button } from 'antd';
import {PATH_HOME_INT} from '../constants/routers/paths';

import CTemplateExt from '../containers/homeExterno/CTemplateExt'

export default function VSinResultados() {
    return (
            <Result
              status="404"
              title="404"
              subTitle="Lo sentimos, La página a la que estás intentando acceder no existe."
              extra={
                <Link
                  style={{
                    color:'#6d6ab5',
                    fontSize: '18px'
                  }}
                  to={PATH_HOME_INT}>
                  Regresar
                </Link>
              }
            />
        );
}
